import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './shared/common.service';
import {Command} from './main/view-device/current-device-new/current-device-new.component';

interface Message {
    command: string;
    sn: string;
}

@Injectable({
    providedIn: 'root'
})

export class WebsocketService {
    public socketAudio$: WebSocket;
    public socketImageMap$: Map<string, WebSocket> = new Map();

    constructor(private commonService: CommonService) { }

    public connect(url: string, message: Message, type: 'screencast' | 'audio'): Observable<Blob> {
        const webSocketUrl = url.replace('http', 'ws') + `SnapBox/${type}?authorization=Bearer ${this.commonService.getStorage('token')}`;

        const socket = new WebSocket(webSocketUrl);
        if (type === 'audio') {
            socket.binaryType = 'arraybuffer';
            this.socketAudio$ = socket;
        }
        if (type === 'screencast') {
            this.socketImageMap$.set(message.sn, socket);
        }

        return new Observable<Blob>((observer) => {
            socket.onopen = () => {
                this.sendMessage(message, type);
            };

            socket.onmessage = (event) => {
                observer.next(event.data);
            };

            socket.onclose = (event) => {
                if (event.code === 1003) {
                    observer.error(`Device ${message} not found.`);
                } else if (event.code === 1001 || event.code === 1006) {
                    observer.error('WebSocket session idle timeout expired.');
                }
                observer.complete();
            };

            return () => {
                socket.close();
                this.socketImageMap$.delete(message.sn);
            };
        });
    }
    public sendMessage(message: Message, type: 'screencast' | 'audio'): void {
        let socket;

        switch (type) {
            case 'screencast':
                socket = this.socketImageMap$.get(message.sn);
                break;
            case 'audio':
               socket = this.socketAudio$;
        }
        if (socket && socket.readyState === 1) {
            socket.send(JSON.stringify(message));
        }
    }

    createCommand(action: 'START' | 'STOP', sn: string): Command {
        return {
            command: action,
            sn: sn
        };
    }

    public isWebSocketScreencastConnected(sn: string): number {
        const socket = this.socketImageMap$.get(sn);

        if (socket) {
            return socket.readyState;
        } else {
            return -1;
        }
    }
}

