import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren, } from "@angular/core";
import { TooltipService } from "../../tooltip.service";

@Component({
    selector: 'feature-list',
    templateUrl: './feature-list.component.html',
    styleUrls: ['./feature-list.component.scss']
})
export class FeatureListComponent implements OnInit {
    @Input() features: string[];

    @ViewChildren('feature') featureList: QueryList<ElementRef>;
    @ViewChild('showMoreBtn') showMoreBtn: ElementRef;
    @ViewChild('listWrapper') listWrapper: ElementRef;

    tooltipComponent = null;
    hiddenFeatures = [];
    featuresSeen:any = [];

    constructor(
        private tooltipService: TooltipService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent()
    }

    // @HostListener('window:resize', ['$event'])
    // onResize() {
    //     if (this.featureList && this.featureList.length) {
    //         setTimeout(() => { this.adjustFeatureList(); }, 300);
    //     }
    // }

    ngOnInit(): void {
        this.getFeatureList()
    }

    ngAfterViewInit(): void {
        // setTimeout(() => { this.adjustFeatureList(); }, 300);
    }

    adjustFeatureList(): void {
        this.hiddenFeatures = [];
        this.featureList.forEach((item) => {
            item.nativeElement?.classList.remove('hidden');
        });
        // let stopWidth = this.showMoreBtn?.nativeElement.offsetWidth;
        let stopWidth = 1000;
        // const primaryWidth = this.listWrapper?.nativeElement.offsetWidth;
        const primaryWidth = 1100;
        this.featureList.forEach((item, i) => {
            if (primaryWidth >= stopWidth + item?.nativeElement.offsetWidth + 8) {
                stopWidth += item?.nativeElement.offsetWidth + 8;
            } else {
                item?.nativeElement.classList.add('hidden');
                this.hiddenFeatures.push(this.features[i]);
            }
        });

        if (this.hiddenFeatures.length > 0) {
            this.showMoreBtn?.nativeElement.classList.remove('hidden');
        }
    }

    getFeatureList(){
        if(this.features && this.features.length > 0){
            this.featuresSeen = this.features[0]
            this.hiddenFeatures = this.features.slice(1,this.features.length)
        }
        
    }
}