import { Injectable } from '@angular/core';
import { NetworkService } from '../../shared/network.service';
import { AlreadyBookedResponse, BestSlot, Booking, CreateBookingForm, DeviceBooking, FindBestSlotForm, UpdateBookingForm } from './interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponseDto } from '../../shared/dto';
import { CommonService } from '../../shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class BookingManagerService {
  constructor(private networkService: NetworkService, private commonService: CommonService) { }

  private bookings = new BehaviorSubject<DeviceBooking[]>([]);
  bookings$ = this.bookings.asObservable();

  private selectedDate = new BehaviorSubject<Date>(new Date());
  selectedDate$ = this.selectedDate.asObservable();

  private selectedDevices = new BehaviorSubject<[]>([]);
  selectedDevices$ = this.selectedDevices.asObservable();
  currentDate = new Date();
  maxDate = new Date(this.currentDate.getTime() + 2592000000) // 30 days
  isLoading = 0;
  selectedTimeSlot = { hour: -1, device: -1, half: -1, booking: null };
  isOwnBookingShown = false;

  startLoading() {
    this.isLoading++;
  }

  stopLoading() {
    this.isLoading--;
  }

  setSelectedDevices(devices) {
    this.selectedDevices.next(devices);
  }

  setSelectedDate(date: Date) {
    this.selectedDate.next(date);
  }

  toggleOwnBooking(value: boolean) {
    this.isOwnBookingShown = value;
    this.updateBookingList();
  }

  createBooking(body: CreateBookingForm): Observable<ApiResponseDto<DeviceBooking | AlreadyBookedResponse>> {
    return this.networkService.post('api/v4/devicebooking/book', body, null, 'basic');
  }

  updateBookingList() {
    if (this.selectedDevices.value.length > 0) {
      const params = {
        startDate: this.selectedDate.value,
        endDate: this.selectedDate.value,
        deviceIds: this.selectedDevices.value,
        userIds: this.isOwnBookingShown ? [this.commonService.getUser().userId] : null
      };
  
      this.isLoading++;
      this.getBookings(params).subscribe((res) => {
        if (res.statusCode === 200) {
          this.isLoading--;
          this.bookings.next(res.data as DeviceBooking[]);
        }
      }, () => this.isLoading--);
    }
  }

  getBookings(params): Observable<ApiResponseDto<DeviceBooking[]>> {
    const { enterpriseId, userIds, deviceIds, startDate, endDate } = params;
    let query = `startDate=${startDate.getTime()}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    if (enterpriseId) {
      query += `&enterpriseId=${enterpriseId}`;
    }
    if (userIds) {
      query += `&userIds=${userIds.join(',')}`;
    }
    if (deviceIds) {
      query += `&deviceIds=${deviceIds.map((d) => d._id).join(',')}`;
    }
    if (endDate) {
      query += `&endDate=${endDate.getTime()}`;
    }
    return this.networkService.get('api/v4/devicebooking?' + query, null, null, 'basic');
  }

  updateBooking(body: UpdateBookingForm): Observable<ApiResponseDto<Booking | AlreadyBookedResponse>> {
    return this.networkService.post('api/v4/devicebooking/update', body, null, 'basic');
  }

  deleteBookings(bookingIdList: string[]): Observable<ApiResponseDto<Booking>> {
    return this.networkService.post('api/v4/devicebooking/delete', { bookingIdList }, null, 'basic');
  }

  findBestSlot(body: FindBestSlotForm): Observable<ApiResponseDto<BestSlot>> {
    return this.networkService.post('api/v4/devicebooking/slot', body, null, 'basic');
  }
}
