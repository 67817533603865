import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AdminService} from '../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GetLabListDto} from '../../../shared/dto';
import {TokenData} from '../../../shared/interfaces';
import {CommonService} from '../../../shared/common.service';
import { SnackbarComponent } from '../../../shared/components/snackbar/snackbar-comp.component';
import { TooltipService } from '../../../shared/tooltip.service';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss']
})
export class AlertListComponent implements OnInit {
  @ViewChild('scrollContent') scrollContent!: ElementRef;
  @ViewChild('scrollThumb') scrollThumb!: ElementRef;

  locationListDropdown = [];
  deviceList = [];
  selectDeviceList = [];
  cardList = [];
  isLoading = false;
  searchType = '';
  searchDevice = '';
  searchLocation = '';
  searchEmei = '';
  searchStartDate: any;
  searchEndDate: any;
  searchLocatin = '';
  labType = '';
  stripeForm: FormGroup;

  minDateCal: Date;
  userData: TokenData;
  alertsList = [];
  labId = '';
  lType = '';
  validSearch = false;
  isLoading1 = false;
  deviceId = '';
  totalDevice = 0;
  totalprice = 0;
  totalDay = 0;
  totalAmount = 0;
  total = 0;
  pagelimit = 10;
  totalPage: number;
  currentPage = 0;
  labList = [];
  isFilterOpen: boolean = false
  hasVerticalScroll:boolean = false
  paginationText = "0-0 of 0";
  configureDrop: boolean = false
  tooltipComponent = null;

  configurations = [
    { name: 'CREATED ON', isSelected: true,  },
    { name: 'ALERT TYPE', isSelected: true,  },
    { name: 'LAB NAME', isSelected: true,  },
    { name: 'DEVICE NAME', isSelected: true,  },
    { name: 'STATUS', isSelected: true,  },
    { name: 'MESSAGE', isSelected: true},
  ]

  constructor(
    private DeviceService: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private tooltipService: TooltipService,
  ) { 
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit() {
    this.userData = this.commonService.getUser();
    this.minDateCal = new Date();
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.getLabsList();
    this.getDeviceList();
    this.alertList();
  }

  onSelectLabChange(): void {
    this.currentPage = 0;
    this.getDeviceList();
    this.alertList();
    this.validateSearchChange();
  }

  getDeviceList() {
    this.isLoading = true;
    let query = '';
    if (this.labId) {
      query = query + '&labId=' + this.labId;
    }

    this.DeviceService.smartDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.deviceList = res.data.deviceList;
      }
    }, err => {
      this.isLoading = false;
    });

  }

  alertList() {
    this.isLoading = true;
    const query = this.queryParam();
    this.DeviceService.AlertApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.alertsList = res.data.thresholdAlertLogs;
        this.total = res.data.totalCount;
        this.SetPage();
        this.hasVerticalScroll = false
        setTimeout(() => {
        this.scrollUpdate()
        }, 1000);
        if (res.data.thresholdAlertLogs.length === 0) {
         return 
        }
      }
    }, err => {
      this.isLoading = false;
    });
  }

  SetPage() {
    this.totalPage = 0;
    this.totalPage = this.total / this.pagelimit;
    this.totalPage = Math.ceil(this.totalPage);
    if (this.totalPage === 1 || this.total <= this.pagelimit) {
      this.totalPage = 1;
    }
    const max = (this.currentPage + 1) * this.pagelimit;
    this.paginationText = `${(this.currentPage * this.pagelimit) + 1}-${this.total < max ? this.total : max} of ${this.total}`;
  }

  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.alertList();
  }

  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.alertList();
  }

  validatePrice() {
    this.totalDevice = 0;
    this.totalprice = 10;
    this.totalDay = 0;
    this.selectDeviceList = [];
    this.totalAmount = 0;
    this.deviceList.forEach(element => {
      if (element['selectDevice'] === true) {
        this.selectDeviceList.push(element['deviceId']);
        this.totalDevice = this.totalDevice + 1;
        // this.totalprice = this.totalprice + element['price'];
        this.totalDay = this.getNumberOfDays(this.searchStartDate, this.searchEndDate);
        if (this.totalDay === 0) {
          this.totalDay = 1;
        } else {
          this.totalDay = this.totalDay + 1;
        }
      }
    });
    this.totalAmount = this.totalprice * this.totalDay * this.totalDevice;
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    return Math.round(diffInTime / oneDay);
  }

  getLabsList() {
    this.DeviceService.getLabList('').subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        const data = res.data as GetLabListDto;
        this.labList = data.labList;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });
  }

  queryParam() {
    let query = 'limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    if (this.searchStartDate && this.searchStartDate != '') {
      // convert to epoch time
      query = query + '&startDate=' + this.getEpocStart(this.searchStartDate);
    }
    if (this.searchEndDate && this.searchEndDate != '') {
      // convert to epoch time
      query = query + '&endDate=' + this.getEpocEnd(this.searchEndDate);
    }
    if (this.labId) {
      query = query + '&labId=' + this.labId;
    }
    if (this.deviceId) {
      query = query + '&deviceId=' + this.deviceId;
    }
    if (this.lType) {
      query = query + '&type=' + this.lType;
    }
    return query;
  }

  clearFilter() {
    this.labType = '';
    this.lType = '';
    this.searchType = '';
    this.searchDevice = '';
    this.searchLocation = '';
    this.labId = '';
    this.searchStartDate = '';
    this.searchEndDate = '';
    this.searchLocatin = '';
    this.searchEmei = '';
    this.deviceId = '';
    this.deviceList = [];
    this.getDeviceList();
    this.alertList();
  }

  getEpocStart(date): any {
    let travelDay = date.getDate();
    if (travelDay < 10) { travelDay = '0' + travelDay; }
    let travelMonth = date.getMonth();
    if (travelMonth < 10) { travelMonth = '0' + travelMonth; }
    const travelYear = date.getFullYear();
    const firstDay = new Date(travelYear, travelMonth, travelDay);
    return new Date(firstDay).setHours(0, 0, 0, 0) / 1000;
  }

  getEpocEnd(date): any {
    let travelDay = date.getDate();
    if (travelDay < 10) { travelDay = '0' + travelDay; }
    let travelMonth = date.getMonth();
    if (travelMonth < 10) { travelMonth = '0' + travelMonth; }
    const travelYear = date.getFullYear();
    const lastDay = new Date(travelYear, travelMonth, travelDay);
    return new Date(lastDay).setHours(23, 59, 59, 0) / 1000;
  }

  selectDevice(index) {
    this.deviceList[index]['selectDevice'] = true;
    this.validatePrice();
  }

  validateSearchChange() {
    this.validSearch = this.searchStartDate !== '' && this.searchEndDate !== '';
  }

  changePageLimit() {
    this.currentPage = 0;
    this.alertList();
  }

  setDefault() {
    this.currentPage = 0;
    this.alertList();
  }

  DateChange() {
    this.currentPage = 0;
  }

  clearAll(){
    this.isFilterOpen = false
    }

    getFilterData(event){
      this.isFilterOpen = !this.isFilterOpen;
      this.configureDrop = false
    }

    scrollUpdate(){
      this.hasVerticalScroll = this.scrollContent.nativeElement.scrollHeight > this.scrollContent.nativeElement.clientHeight;
      const content = this.scrollContent.nativeElement;
      const thumb = this.scrollThumb.nativeElement;
      const track = thumb.parentElement;
 
      // Update thumb position based on content scroll
      content.addEventListener('scroll', () => {
        const scrollRatio = content.scrollTop / (content.scrollHeight - content.clientHeight);
        thumb.style.top = scrollRatio * (track.clientHeight - thumb.clientHeight) + 'px';
      });
 
      // Handle thumb dragging
      thumb.addEventListener('mousedown', (e: MouseEvent) => {
        const thumbInitialY = e.clientY;
        const thumbInitialTop = thumb.offsetTop;
 
        const onMouseMove = (e: MouseEvent) => {
          const deltaY = e.clientY - thumbInitialY;
          const newTop = Math.min(Math.max(thumbInitialTop + deltaY, 0), track.clientHeight - thumb.clientHeight);
          thumb.style.top = newTop + 'px';
 
          const scrollRatio = newTop / (track.clientHeight - thumb.clientHeight);
          content.scrollTop = scrollRatio * (content.scrollHeight - content.clientHeight);
        };
 
        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        };
 
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      });
    }
 
    getFiltersValue(){
      if( this.labId != '' ||
    this.deviceId != '' ||
    this.lType != '' ||
    (this.searchStartDate != '' &&
    this.searchStartDate != undefined ) ||
    (this.searchEndDate != '' &&
    this.searchEndDate != undefined )){
        return true
      }else false
    }

    changeConfigure(){
      this.configureDrop = !this.configureDrop
      this.isFilterOpen = false;
    }

    restoreDefault(){
      this.configurations.forEach(element => {
        element['isSelected'] = true
      });
  }

  getSelectedConfig(){
    let filterData =this.configurations.filter(config => config.isSelected)
    return filterData.length
}
}

