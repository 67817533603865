<span class="left_part">Procal Technologies, inc.  <span class="appVersion">SNAP version {{appVersion}}</span></span>
<div class="right_part">
    <span>
        Support code: <span class="highlighted_text">{{supportCode}}</span>
       
    </span>
    <span class="footer_links">
        <!-- TODO navigation urls -->
        <a >Procal HQ</a>
        <a >SNAP Blog</a>
        <a >Privacy Policy</a>
        <a >Get Help</a>
    </span>
</div>
