import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  alertFilter: Subject<any> = new Subject();
  alertFilterSubscription$ = this.alertFilter.asObservable();

  streamFilterMessage(data: any) {
    this.alertFilter.next(data);
  }

  getFilterSubscription(): Observable<any> {
    return this.alertFilterSubscription$;
  }
}
