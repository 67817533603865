<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>
    Enterprise
</h1>
<div class="view-container" *ngIf="enterprise">
    <section class="left-part">
        <div class="logo-group">
            <img class="logo" *ngIf="enterprise.logo" [src]="enterprise.logo" alt="" />
            <div class="logo empty-logo" *ngIf="!enterprise.logo">
                <img alt="" src="../../../../../assets/images/NewUI/menu_enterprises_large.svg">
            </div>
            <div class="status-group">
                <span>
                    <div class="enterprise-status"
                        [ngClass]="{ active: getEnterpriseStatus() === 'ACTIVE', disabled: getEnterpriseStatus() === 'DISABLED' }"
                        [tp]="tooltipComponent" [tpData]="(getEnterpriseStatus() | titlecase) | tooltipData">
                    </div>
                    {{getEnterpriseStatus() | titlecase}}
                </span>
                <div class="group">
                    <label>Enterprise Name</label>
                    <input type="text" disabled class="form-control" [value]="enterprise.name" />
                </div>
            </div>
        </div>
        <div class="group">
            <label>Location</label>
            <input type="text" disabled class="form-control" [value]="enterprise.location || ''" />
        </div>
        <div class="double-input">
            <div class="group">
                <label>Snap Support Contact</label>
                <input type="text" disabled class="form-control" [value]="snapSupport?.name" />
            </div>
            <div class="group">
                <label>Snap Support Email</label>
                <input type="text" disabled class="form-control" [value]="snapSupport?.email" />
            </div>
        </div>
        <div class="double-input">
            <div class="group">
                <label>Enterprise Support Contact</label>
                <input type="text" disabled class="form-control" [value]="enterpriseSupport?.name" />
            </div>
            <div class="group">
                <label>Enterprise Support Email</label>
                <input type="text" disabled class="form-control" [value]="enterpriseSupport?.email" />
            </div>
        </div>
    </section>
    <section class="right-part">
        <div class="limit-group">
            <div class="limit">
                <span>Labs</span>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.labCount || 0) + ' labs added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/labs', { enterpriseId: enterprise.id })">
                        {{enterprise.labCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="enterprise.maxLabCount">
                        {{enterprise.maxLabCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="!enterprise.maxLabCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="limit">
                <span>Devices</span>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.deviceCount || 0) + ' devices added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/device', { enterpriseId: enterprise.id })">
                        {{enterprise.deviceCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData"
                        *ngIf="enterprise.maxDeviceCount">
                        {{enterprise.maxDeviceCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData"
                        *ngIf="!enterprise.maxDeviceCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="limit">
                <span>Users</span>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.userCount || 0) + ' users added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/users', { enterpriseId: enterprise.id })">
                        {{enterprise.userCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData" *ngIf="enterprise.maxUserCount">
                        {{enterprise.maxUserCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData"
                        *ngIf="!enterprise.maxUserCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </div>
            <div class="limit">
                <span>Invoices</span>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((invoiceCount || 0) + ' invoices added for enterprise') | tooltipData"
                        (click)="navigatePage('/admin/invoices', { enterpriseId: enterprise.id })">
                        {{invoiceCount || 0}}
                    </span>
                </div>
            </div>
        </div>
        <div class="group">
            <label>Subscription</label>
            <div class="feature-container">
                <feature-list [features]="enterprise.subscription?.features"></feature-list>
            </div>
        </div>
        <div class="double-input">
            <span>
                Subscription Start Date: {{enterprise.subscription?.expiration?.startAt * 1000 | date: "MM.dd.yyyy" }}
            </span>
            <span
                *ngIf="!enterprise.subscription?.expiration.endAt || enterprise.subscription?.expiration?.endAt >= unlimited / 1000">
                Subscription End Date: <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
            </span>
            <span
                *ngIf="enterprise.subscription?.expiration.endAt && enterprise.subscription?.expiration?.endAt < unlimited / 1000">
                Subscription End Date: {{enterprise.subscription.expiration.endAt * 1000 | date: "MM.dd.yyyy" }}
            </span>
        </div>
    </section>
</div>
