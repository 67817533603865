import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface AppConfig {
    backendApiURL: string;
    logcatBufferSize: number;
    terminalBufferSize: number;
    chatbotURL: string;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private configuration: AppConfig;

    constructor(private httpClient: HttpClient) { }

    setConfig(): Promise<AppConfig> {
        return this.httpClient
            .get<AppConfig>('assets/config/config.json')
            .toPromise()
            .then(config => this.configuration = config);
    }

    readConfig(): AppConfig {
        return this.configuration;
    }
}
