import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { RecordAndPlayService } from '../record-and-play.service';
import { ReplayStatus } from '../models/record-and-play.model';
import { FeatureTypeEnum } from '../../../shared/enum';
import { CommonService } from '../../../shared/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-rnp-test-report',
  templateUrl: './rnp-test-report.component.html',
  styleUrls: ['./rnp-test-report.component.scss']
})
export class RnpTestReportComponent implements OnInit {
  isLoading = false;
  testInfo: any;
  isRnpAvailable = false;
  isCloudAvailable = false;
  tooltipComponent = null;
  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private dialogService: DialogModalService,
    private tooltipService: TooltipService,
    private rnpService: RecordAndPlayService,
    private commonService: CommonService,
    private _snackBar: MatSnackBar) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.isRnpAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.RNP]);
    this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    if (this.isRnpAvailable) {
      const id = this.route.snapshot.queryParamMap.get('id');
      if (id) {
        this.isLoading = true;
        this.rnpService.getAllTestReplayList('', id, '', '').subscribe((list) => {
          this.isLoading = false;
          if (list && list.data && list.data.recordReplaytList.length) {
            this.testInfo = list.data.recordReplaytList[0];
          }
        }, (error) => { this.isLoading = false; });
      } else {
        this.router.navigate(['snap', 'record-and-play']);
      }
    }
  }

  openModal(content) {
    this.modalRef = this.modalService.open(content, this.modalOptions);
  }

  calcPassRate(result: any): string {
    const res = '--';
    if (result) {
      if (result.status !== ReplayStatus.COMPLETED) {
        return res;
      } else {
        if (result.iterations && result.iterations.length) {
          const i = result.iterations.filter((r) => r.status === 'Pass').length;
          return Math.ceil(i / result.iterations.length * 100) + '%';
        } else {
          return res;
        }
      }
    }
    return res;
  }

  checkDownloadAll(): boolean {
    return this.isCloudAvailable && this.testInfo
      && (this.testInfo.artifacts?.log?.link
        || this.testInfo.artifacts?.userAction?.link
        || this.testInfo.artifacts?.video?.link
        || this.testInfo.artifacts?.kpis?.link);
  }

  downloadAllArtifacts() {
    this.rnpService.downloadAllArtifacts(this.testInfo.recordReplayId).subscribe((res) => {
      const blob = new Blob([res.body], { type: res.headers.get('content-type') });
      const fileName = res.headers
        .get('content-disposition')
        .split('filename=')
        .at(-1);

      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  downloadArtifact(type: string) {
    this.rnpService.getLogsAndVideoLinksForReplay(this.testInfo.recordReplayId, type).subscribe((elem) => {
      if (elem.message === 'Success') {
        if (elem.data && elem.data.message === 'File downloaded Successfully') {
          this.downloadData(elem.data.url);
        }
      }
    }, (error) => { });
  }

  downloadData(url: string): void {
    if (url.length) {
      window.open(url, '_blank');
    }
  }

  getActionIconName(type: string, status: string): string {
    return this.rnpService.getActionIconName(type, status);
  }

  calcHoursToDisplay(timestamp: number): string {
    return this.rnpService.calcHoursToDisplay(timestamp);
  }

  getActionName(type: string): string {
    return this.rnpService.getActionName(type);
  }

  displayDetails(action: any): string {
    return this.rnpService.displayDetails(action);
  }

  openConfirmDeleteArtifact(type: string): void {
    this.dialogService.openConfirmationDialog(
      'deleteArtifactsModal',
      () => this.deleteArtifact(type)
    );
  }

  deleteArtifact(type: string) {
    const body = {
      recordReplayId: this.testInfo.recordReplayId,
      isLogsKept: !(type === 'logs' || type === 'all'),
      isVideoKept: !(type === 'video' || type === 'all'),
      isUserActionKept: !(type === 'userAction' || type === 'all'),
      isKPIsKept: !(type === 'kpis' || type === 'all'),
    };
    this.rnpService.handleArtifactsAfterStop(body).subscribe((resp) => {
      if (resp && resp.statusCode === 200) {
        this._snackBar.open('Test artifacts are deleted successfully.', '', {
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['success'],
        });
        this.rnpService.getAllTestReplayList('', this.testInfo.recordReplayId, '', '').subscribe((list) => {
          this.isLoading = false;
          if (list && list.data && list.data.recordReplaytList.length) {
            this.testInfo = list.data.recordReplaytList[0];
          }
        }, (error) => { this.isLoading = false; });
      }
    }, (error) => { });
  }

  testResults() {
    let res = 0;
    if (this.testInfo?.isLogsCollecting && !this.testInfo.artifacts.log.isDeleted) {
      res += 1;
    }
    if (this.testInfo?.isUserActionCollecting && !this.testInfo.artifacts.userAction.isDeleted) {
      res += 1;
    }
    if (this.testInfo?.isVideoCollecting && !this.testInfo.artifacts.video.isDeleted) {
      res += 1;
    }
    if (this.testInfo?.isKPIsCollecting && !this.testInfo.artifacts.kpis.isDeleted) {
      res += 1;
    }
    return res === 0 ? '--' : res;
  }
}
