<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>Create a new invoice</h1>
<form class="lab-form" [formGroup]="invoiceForm" (ngSubmit)="createInvoice()">
    <section class="left-part">
        <div class="group" *ngIf="userData.role === 'ADMIN'">
            <label for="selectEnterprise">Select Enterprise <span>*</span></label>
            <select class="form-control" id="selectEnterprise" formControlName="selectEnterprise" required>
                <option value="">Select Enterprise</option>
                <option *ngFor="let enterprise of enterpriseOptions" [value]="enterprise.id" class="selected-option">
                    {{enterprise.name}}
                </option>
            </select>
            <div *ngIf="invoiceForm.controls.selectEnterprise.invalid && (invoiceForm.controls.selectEnterprise.dirty || invoiceForm.controls.selectEnterprise.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.selectEnterprise.errors.required">*Select Enterprise is required</div>
            </div>
        </div>
        <div class="double-input">
            <div class="group">
                <label for="firstNameSalesPerson">First name - Snap sales person</label>
                <input type="text" formControlName="firstNameSalesPerson" maxlength="50" id="firstNameSalesPerson"
                    class="form-control" placeholder="Enter First name" />
            </div>
            <div class="group">
                <label for="lastNameSalesPerson">Last name - Snap sales person</label>
                <input type="text" formControlName="lastNameSalesPerson" maxlength="50" id="lastNameSalesPerson"
                    class="form-control" placeholder="Enter Last name" />
            </div>
        </div>
        <div class="double-input">
            <div class="group">
                <label for="firstNameEnterpriseSupport">First name - Enterprise Support</label>
                <input type="text" formControlName="firstNameEnterpriseSupport" maxlength="50"
                    id="firstNameEnterpriseSupport" class="form-control" placeholder="Enter First name" />
            </div>
            <div class="group">
                <label for="lastNameEnterpriseSupport">Last name - Enterprise Support</label>
                <input type="text" formControlName="lastNameEnterpriseSupport" maxlength="50"
                    id="lastNameEnterpriseSupport" class="form-control" placeholder="Enter Last name" />
            </div>
        </div>
        <div class="group">
            <label for="labLimit">Lab Limit</label>
            <input type="number" formControlName="labLimit" id="labLimit" class="form-control" placeholder="Unlimited"
                (change)="replaceLimit('labLimit')" />
            <div *ngIf="invoiceForm.controls.labLimit.invalid && (invoiceForm.controls.labLimit.dirty || invoiceForm.controls.labLimit.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.labLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="deviceLimit">Device Limit</label>
            <input type="number" formControlName="deviceLimit" id="deviceLimit" class="form-control"
                placeholder="Unlimited" (change)="replaceLimit('deviceLimit')" />
            <div *ngIf="invoiceForm.controls.deviceLimit.invalid && (invoiceForm.controls.deviceLimit.dirty || invoiceForm.controls.deviceLimit.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.deviceLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="userLimit">User Limit</label>
            <input type="number" formControlName="userLimit" id="userLimit" class="form-control" placeholder="Unlimited"
                (change)="replaceLimit('userLimit')" />
            <div *ngIf="invoiceForm.controls.userLimit.invalid && (invoiceForm.controls.userLimit.dirty || invoiceForm.controls.userLimit.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.userLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="cost">Cost</label>
            <input type="number" formControlName="cost" id="cost" class="form-control"
                (change)="replaceLimit('cost')" />
            <div *ngIf="invoiceForm.controls.cost.invalid && (invoiceForm.controls.cost.dirty || invoiceForm.controls.cost.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.cost.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <mat-checkbox class="checkbox" formControlName="onGoingEngagement">
            Ongoing engagement
        </mat-checkbox>
    </section>
    <section class="right-part">
        <div class="group">
            <label for="subscription">Select Subscription</label>
            <select class="form-control" id="subscription" formControlName="subscriptionId"
                (change)="onSubscriptionChange($event)" [ngClass]="{ placeholder: !invoiceForm.value.subscriptionId }">
                <option value="">Select Subscription</option>
                <option *ngFor="let option of subscriptionOptions" [value]="option.id">{{option.name}}</option>
            </select>
        </div>
        <div class="group">
            <label for="subscriptionStart">
                Select Subscription Start Date
                <span *ngIf="invoiceForm.value.subscriptionId">*</span>
            </label>
            <input readonly id="subscriptionStart" placeholder="Select Subscription Start Date" class="form-control"
                formControlName="subscriptionStart" [matDatepicker]="subscriptionStartPicker" [min]="minStartDate"
                (focus)="subscriptionStartPicker.open()" (dateChange)="updateExpirationMin()" />
            <div class="datepicker-toggle">
                <mat-datepicker-toggle [for]="subscriptionStartPicker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #subscriptionStartPicker></mat-datepicker>
            <div *ngIf="invoiceForm.controls.subscriptionStart.invalid && (invoiceForm.controls.subscriptionStart.dirty || invoiceForm.controls.subscriptionStart.touched)"
                class="error">
                <div *ngIf="invoiceForm.controls.subscriptionStart.errors.required">*Subscription Start Date is
                    required.
                </div>
            </div>
        </div>
        <div class="group">
            <label for="subscriptionEnd">Select Subscription End Date</label>
            <input readonly id="subscriptionEnd" placeholder="Unlimited" class="form-control"
                formControlName="subscriptionEnd" [matDatepicker]="subscriptionPicker" [min]="minEndDate"
                (focus)="subscriptionPicker.open()" />
            <div class="datepicker-toggle">
                <mat-datepicker-toggle [for]="subscriptionPicker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #subscriptionPicker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel (click)="resetExpiration()">Clear</button>
                    <button mat-button matDatepickerApply>Apply</button>
                </mat-datepicker-actions>
            </mat-datepicker>
        </div>
        <div class="comments-textarea">
            <label for="comments">Comments</label>
            <textarea max="150" rows="3" class="form-control" id="comments" formControlName="comments"></textarea>
        </div>
        <div class="form-actions">
            <button class="snap-button secondary" type="button" routerLink="/admin/invoices">Cancel</button>
            <button class="snap-button primary" type="submit" [disabled]="!invoiceForm.valid">Create</button>
        </div>
    </section>
</form>