export enum ArtifactTypeEnum {
    VIDEO = 'VIDEO',
    LOG = 'LOG',
    USER_ACTION = 'USER_ACTION',
    KPIS = 'KPIS',
    DUMP = 'DUMP',
    OTHER = 'OTHER',
    CALL_LOG = 'CALL_LOG'
}

export const ArtifactTypeDisplayMap = new Map<ArtifactTypeEnum, string>([
    [ArtifactTypeEnum.VIDEO, 'Video'],
    [ArtifactTypeEnum.LOG, 'Device Logs'],
    [ArtifactTypeEnum.USER_ACTION, 'User Actions'],
    [ArtifactTypeEnum.DUMP, 'Dump'],
    [ArtifactTypeEnum.OTHER, 'Other'],
    [ArtifactTypeEnum.CALL_LOG, 'Call Log'],
    [ArtifactTypeEnum.KPIS, 'Device KPIs'],
]);