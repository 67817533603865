import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { MainModule } from './main/main.module';
import { NetworkService } from './shared/network.service';
import { CommonService } from './shared/common.service';
import { AuthGuardMain } from './guards/auth-guard';
import { AppRoutingModule } from './app.routing';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { ConfigurationService } from './shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { RxStompService } from './rx-stomp.service';
import { rxStompServiceFactory } from './rx-stomp-service-factory';
import { provideTippyConfig } from '@ngneat/helipopper';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { UserManagementGuard } from './guards/user-management-guard';
import { AdminGuard } from './guards/admin-guard';
import { LabManagementGuard } from './guards/lab-management-guard';
import { EnterpriseManagementGuard } from './guards/enterprise-admin-guard';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' }
];

export const tooltipVariationCustom = {
  theme: 'light',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  interactive: false
};

const appInitializerFn = (configService: ConfigurationService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MainModule,
    NgbModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  providers: [
    NetworkService,
    CommonService,
    AuthGuardMain,
    AngularFireAuth,
    AngularFireAuthModule,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationService]
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariationCustom
      }
    }),
    PendingChangesGuard,
    UserManagementGuard,
    LabManagementGuard,
    AdminGuard,
    EnterpriseManagementGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
