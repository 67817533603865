import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";

@Directive({
    selector: '[appTextareaAutoResize]',
})
export class TextareaAutoResizeDirective implements OnInit {
    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        if (this.elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize());
        }
    }

    resize() {
        this.elementRef.nativeElement.style.height = '0';
        this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
    }

    @HostListener(':input')
    onInput() {
        this.resize();
    }
}
