import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { stateTooltip } from "../../../device-homepage/device-homepage-management-new/device-homepage-management.component-new";
import { TokenData } from "../../../../shared/interfaces";
import { CommonService } from "../../../../shared/common.service";
import { TooltipService } from "../../../../shared/tooltip.service";
import { CustomTestService } from "../../custom-test.service";
import { FeatureTypeEnum } from "../../../../shared/enum";
import { DevicehomeService } from "../../../device-homepage/devicehome.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomDeviceInfo } from "../../interfaces";

@Component({
    selector: 'app-device-selection',
    templateUrl: './device-selection.component.html',
    styleUrls: ['./device-selection.component.scss']
})
export class DeviceSelectionComponent implements OnInit {
    isLoading = 0;
    userData: TokenData;
    deviceState = '';
    CurrentDeviceIos = true;
    CurrentDeviceAnd = true;
    AllDeviceIos = true;
    AllDeviceAnd = true;
    currentIos = 0;
    currentAndroid = 0;
    availableIos = 0;
    availableAndroid = 0;
    searchText = '';
    searchText1 = '';
    bookingFilterList = [];
    deviceListTemp = [];
    selectedBookedFilter = 'available';
    searchLab = '';
    searchLocation = '';
    deviceList = [];
    currentDeviceList = [];
    currentDeviceListTemp = [];
    filterList = [];
    labListDropdown = [];
    locationListDropdown = [];
    isGridView = true;
    selectedDevice: any;
    tooltipComponent = null;

    allDeviceList = [];

    @ViewChild('drawer') drawer: MatDrawer;

    @Input() device: CustomDeviceInfo;
    @Output() closeSelection = new EventEmitter<boolean>();
    @Output() confirmDeviceSelection = new EventEmitter<boolean>();

    constructor(
        public common: CommonService,
        private tooltipService: TooltipService,
        private customTestService: CustomTestService,
        private deviceHome: DevicehomeService,
        private _snackBar: MatSnackBar,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit() {
        this.userData = this.common.getUser();
        const isBookingAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
        this.userData = this.common.getUser();

        if (isBookingAvailable) {
            this.SearchDevice();
            this.DeviceLocation();
        }
    }

    close() {
        this.closeSelection.emit(false)
    }

    tabFilterAll(value) {
        if (value === 'AllDeviceIos') {
            this.AllDeviceIos = !this.AllDeviceIos;
            if (!this.AllDeviceIos && !this.AllDeviceAnd) {
                this.AllDeviceAnd = true;
                this.AllDeviceIos = false;
            }
        }
        if (value === 'AllDeviceAnd') {
            this.AllDeviceAnd = !this.AllDeviceAnd;
            if (!this.AllDeviceIos && !this.AllDeviceAnd) {
                this.AllDeviceAnd = false;
                this.AllDeviceIos = true;
            }
        }
        this.SearchDeviceLocal();
    }

    SearchDeviceLocal(): void {
        this.searchByText(this.deviceListTemp);
        if (this.searchLab) {
            this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
        }
        if (this.searchLocation) {
            this.deviceList = this.deviceList.filter(device => device.labLocation === this.searchLocation);
        }

        if (!this.AllDeviceAnd && this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem === 'Apple';
            });
        }
        if (this.AllDeviceAnd && !this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem !== 'Apple';
            });
        }
        if (this.selectedBookedFilter === 'booked') {
            this.deviceList = this.deviceList.filter(device => {
                return device.isBooked;
            });
        }
        if (this.selectedBookedFilter === 'available') {
            this.deviceList = this.deviceList.filter(device => {
                return !device.isBooked && device.deviceStateCode === 'ONLINE';
            });
        }
        this.countAmountDevices();
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
    }

    searchByText(list) {
        const keys = ['serialNumber', 'modelName', 'osVersion'];
        const values = [];
        if (this.searchText) {
            values.push(this.searchText.toLowerCase());
        }
        const regex = new RegExp(values.join('|'));
        this.deviceList = list.filter(e => keys.some(k => regex.test(e[k]?.toLowerCase())));
    }

    countAmountDevices() {
        this.availableAndroid = 0;
        this.availableIos = 0;
        this.deviceList.forEach(element => {
            if (element['oem'] === 'Apple') {
                this.availableIos = this.availableIos + 1;
            } else {
                this.availableAndroid = this.availableAndroid + 1;
            }
        });
        if (this.searchText) {
            this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
        } else {
            if (this.searchLab) {
                const filteredByLabDeviceList = this.deviceListTemp.filter(device => device.labName === this.searchLab);
                this.bookingFilterList = this.groupBy(filteredByLabDeviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
            } else {
                this.bookingFilterList = this.groupBy(this.deviceListTemp, 'isBooked', 'deviceStateCode', 'ONLINE');
            }
        }
    }

    groupBy(objectArray, property, filterProperty?: string, filterValue?: string): any {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];

            if (filterProperty && filterValue) {
                if (obj[filterProperty] !== filterValue) {
                    return acc;
                }
            }

            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for the given key's value
            acc[key].push(obj);
            return acc;
        }, {});
    }

    toggleView() {
        this.isGridView = !this.isGridView;
    }

    filterByBooked(type: boolean): void {
        if (this.selectedBookedFilter.length
            && ((this.selectedBookedFilter === 'available' && !type) || (this.selectedBookedFilter === 'booked' && type))) {
            this.selectedBookedFilter = '';
            if (this.searchLab.length || this.searchLocation.length) {
                this.SearchDeviceLocal();
            } else if (this.searchText.length) {
                this.SearchDeviceLocal1();
            } else {
                this.deviceList = this.deviceListTemp;
                this.localFilter();
            }
        } else {
            this.selectedBookedFilter = type ? 'booked' : 'available';
            this.localFilter();
        }
    }


    SearchDeviceLocal1(): void {
        const values = [];
        if (this.searchText1) {
            values.push(this.searchText1.toLowerCase());
        }
        const keys = ['serialNumber', 'modelName1', 'osVersion1', 'oem1'];
        const regex = new RegExp(values.join('|'));
        this.currentDeviceList = this.currentDeviceListTemp.filter(e => keys.some(k => regex.test(e[k].toLowerCase())));

        if (!this.CurrentDeviceAnd && this.CurrentDeviceIos) {
            this.currentDeviceList = this.currentDeviceList.filter(element => {
                return element.oem1 === 'Apple';
            });
        }
        if (this.CurrentDeviceAnd && !this.CurrentDeviceIos) {
            this.currentDeviceList = this.currentDeviceList.filter(element => {
                return element.oem1 !== 'Apple';
            });
        }
        this.currentAndroid = 0;
        this.currentIos = 0;
        this.currentDeviceList.forEach(element => {
            if (element['oem1'] === 'Apple') {
                this.currentIos = this.currentIos + 1;
            } else {
                this.currentAndroid = this.currentAndroid + 1;
            }
        });
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
        this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
    }

    localFilter(): void {
        this.deviceList = this.deviceListTemp.filter(device => {
            if ((this.deviceState && device.deviceStateCode === this.deviceState || !this.deviceState.length) &&
                ((this.selectedBookedFilter === 'booked' && device.isBooked)
                    ||
                    (this.selectedBookedFilter === 'available' && !device.isBooked && device.deviceStateCode === 'ONLINE')
                    || !this.selectedBookedFilter.length)) {
                return device;
            }
        });
        if (this.searchText) {
            this.searchByText(this.deviceList);
        }
        if (this.searchLab) {
            this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
        }
        this.countAmountDevices();
    }


    applyFilter(value): void {
        if (value === this.deviceState) {
            this.deviceState = '';
            if (this.searchLab.length || this.searchLocation.length) {
                this.SearchDeviceLocal();
            } else if (this.searchText.length) {
                this.SearchDeviceLocal1();
            } else {
                this.deviceList = this.deviceListTemp;
                this.localFilter();
            }
        } else {
            this.deviceState = value;
            this.localFilter();
        }
    }

    toggleSidePanelAction(): void {
        this.drawer.toggle();
    }

    closeReset(index, type) {
        if (type === 'ALL') {
            this.deviceList[index]['resetCount'] = 0;
            this.deviceList[index]['isResetting'] = false;
        } else {
            this.currentDeviceList[index]['resetCount'] = 0;
            this.currentDeviceList[index]['isResetting'] = false;
        }
    }

    showOfflineTooltip(data: any): string {
        const state = this.getDeviceStatus(data);
        if (state) {
            return stateTooltip[state] ? stateTooltip[state] : '';
        } else {
            return '';
        }
    }

    checkSelfBooked(item): boolean {
        return item.isBooked && item.bookedByUserId === this.userData.userId;
    }

    getStatusTooltip(data): string {
        let status = this.getDeviceStatus(data);
        if (status === 'online') {
            status = 'available';
        }
        if (status === 'testing') {
            status = 'under test';
        }
        return status.charAt(0).toUpperCase() + status.slice(1);
    }


    selectDevice(item) {
        this.selectedDevice = item;
    }

    getDeviceStatus(data) {
        return this.customTestService.getDeviceStatus(data);
    }


    checkIfDeviceUnavailable() {
        if (!this.selectedDevice) {
            return false;
        }
        const status = this.getDeviceStatus(this.selectedDevice);
        const notOnline = status !== 'online' && status !== 'testing';
        const isBooked = this.selectedDevice.isBooked && this.selectedDevice.bookedByUserId !== this.userData.userId;
        const phone = this.getPhone(this.selectedDevice);
        const hasPhone = phone?.value?.length > 0;

        return notOnline || isBooked || !hasPhone;
    }

    getPhone(device) {
        return device.deviceProperties ? device.deviceProperties.find((prop) => prop.name === 'phone') : '';
    }

    confirmDevice() {
        this.confirmDeviceSelection.emit({ ...this.selectedDevice, ...this.device });
    }

    DeviceLocation() {
        const query = '';
        this.deviceHome.getLocationAPI(query).subscribe((res) => {
            if (res['message'] === 'Success') {
                this.locationListDropdown = res.data.locationList;
            }
        });
    }

    SearchDevice(): void {
        this.isLoading++;
        this.deviceHome.availableDeviceMomt().subscribe((res) => {
            this.isLoading--;
            if (res['message'] === 'Success') {
                this.allDeviceList = res.data.deviceList;
                const unusedDeviceList = res.data.deviceList.filter((device) =>
                    !device.testingType && device.deviceCommunicationStatus.toLowerCase() !== 'remote-testing');
                this.deviceList = unusedDeviceList;
                this.availableAndroid = 0;
                this.availableIos = 0;
                this.AllDeviceAnd = true;
                this.AllDeviceIos = true;
                this.labListDropdown = [];
                let labUrl = [];
                this.deviceList.forEach((element) => {
                    element['showId'] = false;
                    if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
                        element['deviceStateCode'] = 'TESTING';
                    }
                    if (element['labName']) {
                        this.labListDropdown.push(element['labName']);
                        labUrl.push(element['labDomain']);
                    }
                    if (element['oem'] === 'Apple') {
                        this.availableIos = this.availableIos + 1;
                    } else {
                        this.availableAndroid = this.availableAndroid + 1;
                    }
                    if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
                        element['deviceStateCode'] = 'DISCONNECTED';
                    }
                    if (!element['deviceStateCode'] || (element['deviceStateCode']
                        && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
                        element['deviceStateCode'] = 'UNKNOWN';
                    }
                    if (element['deviceState'].toLowerCase() === 'offline') {
                        element['isResetBtn'] = true;
                        if (element['isBooked'] && element['bookedByUserId'] === this.userData.userId) {
                            element['isResetBtn'] = true;
                        }
                        if (element['isBooked'] && element['bookedByUserId'] !== this.userData.userId) {
                            element['isResetBtn'] = false;
                        }
                    }
                    element['isResetting'] = false;
                    element['resetCount'] = 0;
                    element['isBook'] = false;
                    element['searchEndDate'] = '';
                    element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
                });
                localStorage.setItem('AllDevice', JSON.stringify(this.deviceList));
                this.deviceListTemp = unusedDeviceList;
                this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
                this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
                this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
                labUrl = labUrl.filter(this.onlyUnique);
                if (labUrl.length > 0) {
                    this.common.setStorage('_domin', labUrl[0]);
                }
                if (res.data.deviceList.length === 0) {
                    this.deviceList = [];
                    return this._snackBar.open('No device available!', '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['failure']
                    });
                }
                this.SearchDeviceLocal();
            }
        });
    }


    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
}