import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StreamService } from '../../../shared/stream.service';
import { DevicehomeService } from '../devicehome.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../shared/common.service';
import { MatDrawer } from '@angular/material/sidenav';
import { TooltipService } from '../../../shared/tooltip.service';
import { FeatureTypeEnum, UserRoleEnum } from '../../../shared/enum';
import { TokenData } from '../../../shared/interfaces';

export enum stateTooltip {
  online = 'Online',
  testing = 'Testing',
  initializing = 'Device initialization in progress.',
  uninitialized = 'Device initialization is failed. Try to reset the device.',
  unauthorized = 'USB authorization is required. Contact admin. Manually unplug, then plug the device and follow the instructions on the device screen.',
  offline = 'Communication error. Contact admin. Manually unplug, then plug the device and follow the instructions on the device screen. In some cases, device reboot may be needed.',
  unconfigured = 'Developer mode and/or usb debugging is disabled on the device, or device is in fastboot. Manually turn on developer mode and "usb debugging" in developer options on device.',
  appeared = 'Developer mode and/or usb debugging is disabled on the device, or device is in fastboot. Manually turn on developer mode and "usb debugging" in developer options on device.',
  removed = 'The device is physically detached from the lab. Contact admin.',
  unknown = 'The state of the device is unknown. Contact admin for further instructions.',
  detached = 'The device has been detached. Try to attach the device.',
  disconnected = 'The device is physically detached from the lab. Contact admin.',
  blocked = '',
  pending = 'Device is waiting for an available slot in the lab.'
}

interface CreateRequestBody {
  deviceId: string;
  type: string;
  endDate?: string;
}

interface ExtendRequestBody {
  bookingId: string;
  type: string;
  endDate?: string;
  'timeZone': string;
  cardId: string;
}

@Component({
  selector: 'app-device-homepage-management-new',
  templateUrl: './device-homepage-management.component-new.html',
  styleUrls: ['./device-homepage-management.component-new.scss']
})
export class DeviceHomepageManagementNewComponent implements OnInit, OnDestroy {
  deviceList = [];
  deviceHistory = [];
  isLoading = false;
  currentDeviceList = [];
  currentDeviceListTemp = [];
  currentTime: any;
  userData: TokenData;
  cardList = [];
  cardId = '';
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  private modalRef: NgbModalRef;
  total = 0;
  pagelimit = 10;
  totalPage: number;
  currentPage = 0;

  currentIos = 0;
  currentAndroid = 0;
  availableIos = 0;
  availableAndroid = 0;

  deviceBookingDetail: any;
  locationListDropdown = [];
  labListDropdown = [];
  searchLab = '';
  searchLocation = '';
  searchText = '';
  isGridView = true;
  deviceListTemp = [];
  isAvailable = false;
  searchText1 = '';
  AllDeviceIos = true;
  AllDeviceAnd = true;
  CurrentDeviceIos = true;
  CurrentDeviceAnd = true;

  relaseBtntooltip = 'If you are not using this device, you can release it earlier for other to use this.';
  LaunchBtntooltip = 'To access the device, press this launch button';
  ExtendBtntooltip = 'In case you still want to test more, you can always extend your booking date';
  InuseBtntooltip = 'This device is reserved by ';
  AvailbleBtntooltip = 'Great, you can reserve this device';
  ResetbleBtntooltip  = 'Click here to reinitialize the device';
  ResetingbleBtntooltip  = 'Device resetting.';
  expiretooltip  = 'Expiring Soon.';
  requestForm: FormGroup;
  closeResult: any;
  minDateCal: Date;
  searchEndDate = '';
  isLoading1 = false;
  @ViewChild('resetModal') resetModal;
  @ViewChild('drawer') drawer: MatDrawer;
  devicePollingList = [];
  pollingEventId: any;
  filterList = [];
  deviceState = '';
  selectedBookedFilter = '';
  bookingFilterList = [];

  optionsListForReservingDevice = [ 'Today', 'Tomorrow', 'This week', 'Custom'];
  currentOptionForReservingDevice = 'Today';
  typeReservation = 'TODAY';
  timeTillToday = this.formatDate(new Date());
  timeTillTomorrow = '';
  timeTillThisWeek = '';
  timeTillCustomDate = '';
  optionsListForExtendingDevice = [ 'Day', 'This week', 'Custom'];
  currentOptionForExtendingDevice = 'Day';
  typeExtend = 'DAY';
  timeExtendTillDay = '';
  timeExtendTillWeek = '';
  timeExtendTillCustomDate = '';
  isDeviceBookedForAWeek = false;
  dateDeviceBooked = '';
  tooltipComponent = null;

  isBookingAvailable = false;

  constructor(private router: Router,
              private deviceHome: DevicehomeService,
              private _streamService: StreamService,
              private modalService: NgbModal,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              public common: CommonService,
              private tooltipService: TooltipService,
              ) {
                this.tooltipComponent = this.tooltipService.getTooltipComponent();
              }

  ngOnInit(): void {
    this.userData = this.common.getUser();
    this.isBookingAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
    const currentTime = new Date();
    this.currentTime = this.getEpoc(currentTime);
    this.router.navigate(['/snap/homepage']);

    if(this.isBookingAvailable) {
      this.searchDeviceList();
      this.SearchDevice();
      this.DeviceLocation();
      this.initialiseForms();

      if (this.userData.role === UserRoleEnum.USER) {
        this.getCard();
      }
    }
  }

  initialiseForms(): void {
    this.requestForm = this.fb.group({
      oem: ['', [Validators.required]],
      modelName: ['', [Validators.required]],
      deviceType: ['', [Validators.required]],
      osVersion: ['']
    });
  }

  getCard() {
    this.deviceHome.getCardApi().subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.cardList = res.data.cardList;
        if (res.data.cardList.length > 0) {

          res.data.cardList.forEach(element => {
            if (element['isDefault'] == true) {
              this.cardId = element['paymentMethodId'];
            }
            if (this.cardId) {
              localStorage.setItem('isCard', 'true');
            } else {
              localStorage.setItem('isCard', 'false');
            }
          });
        }
      }
    }, err => {
      this.isLoading = false;
    });
  }
  navigateCard() {
    this.closeModalEnd();
    this._snackBar.open('Please add card to extend reservation time !', '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['failure']
    });
    this.router.navigate(['snap/profile/']);
  }
  closeModalEnd() {
    this.currentOptionForReservingDevice = 'Today';
    this.currentOptionForExtendingDevice = 'Day';
    this.typeReservation = 'TODAY';
    this.modalRef.close();
  }

  openCalModal1(content, data) {
    this.searchEndDate = '';
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    if (this.userData.role === UserRoleEnum.USER && this.cardId === '') {
      this._snackBar.open('Please add card details to access service !', '', {
       duration: 3000,
       horizontalPosition: 'right',
       verticalPosition: 'top',
       panelClass: ['danger']
     });
     this.router.navigate(['/snap/profile/billing']);
     return;
   }
    if (data.minDateCal) {
      this.minDateCal = data.minDateCal;
    } else {
      this.minDateCal = new Date(Date.now() + ( 3600 * 1000 * 24));
    }
    this.deviceBookingDetail = data;
    const extendDay = new Date();
    this.timeExtendTillDay = this.formatDate(this.minDateCal);
    extendDay.setDate(new Date().getDate() + (7 - new Date().getDay()));
    this.timeExtendTillWeek = this.formatDate(extendDay);
    const timeStamp = this.deviceBookingDetail.bookedUntil * 1000;
    this.dateDeviceBooked = this.formatDate(new Date(timeStamp));
    this.isDeviceBookedForAWeek = this.dateDeviceBooked >= this.timeExtendTillWeek;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  toggleView() {
    this.isGridView = !this.isGridView;
  }
  tabFilterAll(value) {
    if (value === 'AllDeviceIos') {
      this.AllDeviceIos = !this.AllDeviceIos;
      if (this.AllDeviceIos === false && this.AllDeviceAnd === false) {
        this.AllDeviceAnd = true;
        this.AllDeviceIos = false;
      }
    }
    if (value === 'AllDeviceAnd') {
      this.AllDeviceAnd = !this.AllDeviceAnd;
      if (this.AllDeviceIos === false && this.AllDeviceAnd === false) {
        this.AllDeviceAnd = false;
        this.AllDeviceIos = true;
      }
    }
    this.SearchDeviceLocal();
  }

tabFilterCurrent(value) {
  if (value == 'CurrentDeviceIos') {
    this.CurrentDeviceIos = !this.CurrentDeviceIos;

    if (this.CurrentDeviceIos == false && this.CurrentDeviceAnd == false) {
      this.CurrentDeviceAnd = true;
      this.CurrentDeviceIos = false;
    }
 }
 if (value == 'CurrentDeviceAnd') {
  this.CurrentDeviceAnd = !this.CurrentDeviceAnd;
  if (this.CurrentDeviceIos == false && this.CurrentDeviceAnd == false) {
    this.CurrentDeviceAnd = false;
    this.CurrentDeviceIos = true;
  }
 }
 this.SearchDeviceLocal1();
}

  DeviceLocation() {
    this.isLoading = true;
    const query = '';

    this.deviceHome.getLocationAPI(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.locationListDropdown = res.data.locationList;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  SearchDevice(): void {
    const query = 'showAll=true';
    this.isLoading = true;
    this.deviceHome.availableDevice(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.deviceList = res.data.deviceList;
        this.availableAndroid = 0;
        this.availableIos = 0;
        this.AllDeviceAnd = true;
        this.AllDeviceIos = true;
        this.labListDropdown = [];
        let labUrl = [];
        this.deviceList.forEach((element, i) => {
          element['showId'] = false;
          if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
            element['deviceStateCode'] = 'TESTING';
          }
          if (element['labName']) {
            this.labListDropdown.push(element['labName']);
            labUrl.push(element['labDomain']);
          }
          if (element['oem'] == 'Apple') {
            this.availableIos = this.availableIos + 1;
          } else {
            this.availableAndroid = this.availableAndroid + 1;
          }
          if (element['deviceCommunicationStatus'].toLowerCase() == 'remote-testing') {
            element['deviceStateCode'] = 'TESTING';
          }
          if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
            element['deviceStateCode'] = 'DISCONNECTED';
          }
          if (!element['deviceStateCode'] || (element['deviceStateCode']
            && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
            element['deviceStateCode'] = 'UNKNOWN';
          }
          if (element['deviceState'].toLowerCase() === 'offline') {
            element['isResetBtn'] = true;
            if (element['isBooked'] == true && element['bookedByUserId'] == this.userData.userId) {
              element['isResetBtn'] = true;
            }
            if (element['isBooked'] == true && element['bookedByUserId'] !== this.userData.userId) {
              element['isResetBtn'] = false;
            }
          }
          element['isResetting'] = false;
          element['resetCount'] = 0;
          element['isBook'] = false;
          element['searchEndDate'] = '';
          element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
          element.isDisabled = element.deviceStateCode !== 'ONLINE';
        });
        localStorage.setItem('AllDevice', JSON.stringify(this.deviceList));
        this.deviceListTemp = res.data.deviceList;
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
        this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
        this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
        labUrl = labUrl.filter(this.onlyUnique);
        if (labUrl.length > 0) {
          this.common.setStorage('_domin', labUrl[0]);
        }
        if (res.data.deviceList.length === 0) {
          this.deviceList = [];
          return this._snackBar.open('No device available!', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['failure']
          });
        }
        this.SearchDeviceLocal();
      }
    }, err => {
      this.isLoading = false;
    });

  }

  enableResetBtn(device): boolean {
    return device.usbHubType && device.usbHubType !== 'GENERAL' && device.isSmartHubEnabled && device.deviceState.toLowerCase() === 'offline'
      && device.deviceCommunicationStatus === 'remote-management' && device.deviceStateCode === 'UNINITIALIZED';
  }

  filterByMultipleColumns(users, columnDataToFilter) {
    return users.filter(row => {
      return Object.keys(columnDataToFilter)
          .every(propertyName => row[propertyName].toLowerCase().indexOf(columnDataToFilter[propertyName].toLowerCase()) > -1);
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  SearchDeviceLocal(): void {
    this.searchByText(this.deviceListTemp);
      if (this.searchLab) {
        this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
      }
      if (this.searchLocation) {
        this.deviceList = this.deviceList.filter(device => device.labLocation === this.searchLocation);
      }

    if (!this.AllDeviceAnd && this.AllDeviceIos) {
      this.deviceList = this.deviceList.filter(element => {
            return element.oem === 'Apple';
        });
    }
    if (this.AllDeviceAnd && !this.AllDeviceIos) {
      this.deviceList = this.deviceList.filter(element => {
        return element.oem !== 'Apple';
    });
    }
    if (this.selectedBookedFilter === 'booked') {
      this.deviceList = this.deviceList.filter(device => {
        return device.isBooked;
      });
    }
    if (this.selectedBookedFilter === 'available') {
      this.deviceList = this.deviceList.filter(device => {
        return !device.isBooked && device.deviceStateCode === 'ONLINE';
      });
    }
    this.countAmountDevices();
    this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
  }

  SearchDeviceLocal1(): void {
    const values = [];
    if (this.searchText1) {
      values.push(this.searchText1.toLowerCase());
    }
    const keys = ['serialNumber1', 'modelName1', 'osVersion1', 'oem1'];
    const regex = new RegExp(values.join('|'));
    this.currentDeviceList =  this.currentDeviceListTemp.filter(e =>  keys.some(k => regex.test(e[k].toLowerCase())) );

    if (this.CurrentDeviceAnd == false && this.CurrentDeviceIos == true) {
      this.currentDeviceList = this.currentDeviceList.filter(element => {
            return element.oem1 == 'Apple';
        });
    }
    if (this.CurrentDeviceAnd == true && this.CurrentDeviceIos == false) {
      this.currentDeviceList = this.currentDeviceList.filter(element => {
        return element.oem1 !== 'Apple';
    });
    }
    this.currentAndroid = 0;
    this.currentIos = 0;
    this.currentDeviceList.forEach(element => {
      if (element['oem1'] == 'Apple') {
        this.currentIos = this.currentIos + 1;
      } else {
        this.currentAndroid = this.currentAndroid + 1;
      }
    });
    this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
    this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  searchDeviceList(): void {
    this.isLoading = true;
    const query = 'filter=current';

    this.deviceHome.getDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.currentDeviceList = res.data.deviceList;
        this.currentIos = 0;
        this.currentAndroid = 0;
        this.CurrentDeviceIos = true;
        this.CurrentDeviceAnd = true;
        this.currentDeviceList.forEach((element, i) => {
          element['serialNumber1'] = element['deviceData'][0]['serialNumber'];
          element['modelName1'] = element['deviceData'][0]['modelName'];
          element['oem1'] = element['deviceData'][0]['oem'];
          element['osVersion1'] = element['deviceData'][0]['osVersion'];
          element['isExtent'] = false;
          element['showId'] = false;
          element['isResetting'] = false;
          element['searchEndDate'] = '';
          element['isDisabled'] = false;
          element['minDateCal'] = new Date(element['bookedUntil'] * 1000 + (3600 * 1000 * 24));

          const timeleft = element['bookedUntil'] * 1000 - (new Date()).getTime();
          element['dayLeft'] = Math.ceil((((timeleft / 1000) / 60) / 60) / 24);

          if (element.deviceData[0]['oem'] === 'Apple') {
            this.currentIos = this.currentIos + 1;
          } else {
            this.currentAndroid = this.currentAndroid + 1;
          }

          // condition for disable button
          if (element['deviceData'][0].deviceStateCode === 'PENDING'
            || element['deviceData'][0]['deviceState']
            && element['deviceData'][0].deviceState.toLowerCase() === 'offline'
            && element['deviceData'][0].deviceCommunicationStatus === 'remote-management'
            || element['deviceData'][0].deviceStateCode === 'UNAUTHORIZED'
            || element['deviceData'][0].testingType
          ) {
            element['isDisabled'] = true;
          }
          if (element['deviceData'][0]['deviceStateCode'] === 'DETACHED') {
            element['isDisabled'] = false;
          }
        });
        this.currentDeviceListTemp = this.currentDeviceList;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  openConfirmationModal(content, btn, data) {
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    this.deviceBookingDetail = data;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  LeaveDevice() {
    this.isLoading = true;
    const request = {
      deviceBookingId: this.deviceBookingDetail['deviceBookingId']
    };
    this.deviceHome.releaseDevice(request).subscribe(
      (res) => {
        this.isLoading = false;
        this.modalRef.close();
        this.searchDeviceList();
        this.SearchDevice();
      },

      err => {
        this.isLoading1 = false;
      }
    );
  }
  closeModal() {
    this.modalRef.close();
  }
  toggleExtent(index) {
    this.currentDeviceList[index]['isExtent'] = !this.currentDeviceList[index]['isExtent'];
  }

  bookedDevice(): void {
    if (this.deviceBookingDetail) {
      if (!this.searchEndDate && this.typeReservation === 'TODAY') {
        this.searchEndDate = this.timeTillToday;
      }
      if (this.searchEndDate === '' ) {
        this._snackBar.open('Please choose reservation end date!', '', {
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['danger']
        });
        this.currentOptionForReservingDevice = 'Today';
        this.typeReservation = 'TODAY';
        this.currentOptionForExtendingDevice = 'Day';
        this.typeExtend = 'DAY';
        return;
      }
      if (this.deviceBookingDetail.bookedUntil) {
        const request: ExtendRequestBody = {
          'bookingId': this.deviceBookingDetail.deviceBookingId,
          'type': this.typeExtend.toUpperCase(),
          'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          cardId: this.cardId,
        };
        if (this.typeExtend === 'DATE') {
          request.endDate = this.getEpoc1(this.searchEndDate);
        }
        this.isLoading = true;
        this.deviceHome.ExtendReservationApiV3(request).subscribe((res) => {
          this.isLoading = false;
          if (res['message'] === 'Success') {
            this.searchDeviceList();
            this.SearchDevice();
            this.modalRef.close();
            return this._snackBar.open('Reservation extended successfully !', '', {
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['failure']
            });
          }
        }, err => {
          this.isLoading = false;
          this.modalRef.close();
        }, () => {
          this.currentOptionForReservingDevice = 'Today';
          this.typeReservation = 'TODAY';
          this.currentOptionForExtendingDevice = 'Day';
          this.typeExtend = 'Day';
            });
      } else {
        const deviceList = [];
        let isCardAdded = false;
        if (this.cardList.length > 0) {
          isCardAdded = true;
        }
        this.isLoading1 = true;
        this.isLoading = true;
        const data = this.deviceBookingDetail;
        const requestObj: CreateRequestBody = {
          'deviceId': data['deviceId'],
          'type': this.typeReservation,
        };
        if (this.typeReservation === 'DATE') {
          requestObj.endDate = this.getEpoc1(this.searchEndDate);
        }
        deviceList.push(requestObj);

        const request = {
          deviceList: deviceList,
          'paymentMethodId': this.cardId,
          'isCardAdded': isCardAdded,
          'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        this.deviceHome.createReservationApiV3(request).subscribe(res => {
          if ((res['message'] === 'Success')) {
            this.isLoading = false;
            this.isLoading1 = false;
            this.modalRef.close();
            this.searchDeviceList();
            this.SearchDevice();
            this.searchEndDate = '';
            return this._snackBar.open('Device booked successfully !', '', {
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['failure']
            });
          }
        }, err => {
          this.isLoading = false;
          this.isLoading1 = false;
        }, () => {
          this.currentOptionForReservingDevice = 'Today';
          this.typeReservation = 'TODAY';
          this.currentOptionForExtendingDevice = 'Day';
          this.typeExtend = 'Day';
            });
      }
    }
  }
  getEpoc(date) {
    const epocDate = new Date(date);
    return Math.floor(epocDate.getTime() / 1000);
  }

  DeviceListHistory() {
    this.isLoading = true;
    const query = 'limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    this.deviceHome.getDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.deviceHistory = res.data.deviceList;
        this.total = res.data.totalCount;
        this.SetPage();
      } else {
      }
    }, err => {
      this.isLoading = false;
    });
  }

  navigateDevice(data) {
    if (data.deviceData[0].deviceState && data.deviceData[0].deviceState.toLowerCase() !== 'offline' || data['deviceData'][0]['deviceStateCode'] == 'DETACHED') {
      localStorage.setItem('_dID', data.deviceData[0].deviceId);
      setTimeout(() => {
        this.router.navigate(['/snap/current']);
      }, 1000);
    }
  }

  getEpoc1(date) {
    let travelDay = date.getDate();
    if (travelDay < 10) { travelDay = '0' + travelDay; }
    let travelMonth = date.getMonth() + 1;
    if (travelMonth < 10) { travelMonth = '0' + travelMonth; }
    const travelYear = date.getFullYear();
    return travelMonth + '/' + travelDay + '/' + travelYear;
  }

  changePageLimit() {
    this.DeviceListHistory();
  }
  SetPage(): void {
    this.totalPage = 0;
    this.totalPage = this.total / this.pagelimit;
    this.totalPage = Math.ceil(this.totalPage);
    if (this.totalPage === 1 || this.total <= this.pagelimit) {
      this.totalPage = 1;
    }
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.DeviceListHistory();
  }
  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.DeviceListHistory();
  }

  openRequestModal(content, btn) {
    this.searchEndDate = '';
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    this.initialiseForms();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  submitRequest() {
    if (this.requestForm.valid) {
      const request = this.requestForm.value;
      if (this.requestForm.controls['deviceType'].value == 'apple') {
        request['oem'] = 'Apple';
      }
      this.isLoading1 = true;
      this.deviceHome.requestNewDevice(request).subscribe((res) => {
       this.isLoading1 = false;
       if (res['message'] === 'Success') {
        this.router.navigate(['/snap/profile/requested-device']);
         this._snackBar.open('Thank you for submitting your device request. Snap team will shortly update you.', '', {
           duration: 5000,
           horizontalPosition: 'right',
           verticalPosition: 'top',
           panelClass: ['failure']
         });
          this.closeModal();
       } else {
       }
     }, err => {
       this.isLoading1 = false;
     });
    }
  }
  ChangeDeviceType(): void {
    if (this.requestForm.controls['deviceType'].value !== 'apple') {
      this.requestForm.controls['oem'].setValidators([ Validators.required]);
      this.requestForm.controls['oem'].updateValueAndValidity();
    } else {
      this.requestForm.controls['oem'].setValidators([Validators.nullValidator]);
      this.requestForm.controls['oem'].updateValueAndValidity();
    }
  }

  resetCambrionix(serialNumber: any, labUrl) {
    this.deviceHome.resetCambrionix(serialNumber, labUrl).subscribe((res) => {
      this.checkdevicePulling(serialNumber);
      this.devicePull();
      this._snackBar.open('Device reset request sent ', '', {
        duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
         panelClass: ['success'],
       });
    }, err => { });
  }
  checkdevicePulling(serialNumber: string) {
    if (this.devicePollingList.length === 0) {
       this.pollingEventId = setInterval(() => {
        this.devicePull();
      }, 10000);
    }
    const result  = this.devicePollingList.filter(x => x.serialNumber.toLowerCase() == serialNumber.toLowerCase());
    if (result.length === 0) {
      this.devicePollingList.push({serialNumber: serialNumber, count: 0});
    }
  }
  devicePull(): void {
    const query = 'showAll=true';
    let deviceList = [];

    this.deviceHome.availableDevice(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        deviceList = res.data.deviceList;
          deviceList.forEach(element => {
            if (element['deviceCommunicationStatus'].toLowerCase() == 'remote-testing') {
              element['deviceStateCode'] = 'TESTING';
          }
          if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
            element['deviceStateCode'] = 'DISCONNECTED';
          }
          if (!element['deviceStateCode'] || (element['deviceStateCode']
              && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
            element['deviceStateCode'] = 'UNKNOWN';
          }
          });
           // Update All device Section
           this.deviceList.map((e, i) => {
            const temp = deviceList.find(element => element.serialNumber === e.serialNumber);
            if (temp) {
              e.deviceState = temp.deviceState;
              e.deviceStateCode = temp.deviceStateCode;
              // e.isResetting = false;
            }
            if (e.deviceState === 'Online') {
              e.isResetting  = false;
            }
            const data =  this.devicePollingList.find(pulldevice => pulldevice.serialNumber === e.serialNumber);
            if (data) {
              e.resetCount = data.count;
            }
            this.currentDeviceList.forEach(elementdevie => {
              if (elementdevie['deviceData'][0].serialNumber == e.serialNumber) {
                elementdevie['resetCount'] = e.resetCount;
              }
            });
          });
          // Update booked Device Section
          this.currentDeviceList.map((e, i) => {
            const temp = deviceList.find(element => element.serialNumber === e['deviceData'][0].serialNumber);
            if (temp) {
              e['deviceData'][0].deviceState = temp.deviceState;
              e['deviceData'][0].deviceStateCode = temp.deviceStateCode;
              // e['isResetting']= false;
            }
            if (e['deviceData'][0].deviceState === 'Online') {
              e.isResetting  = false;
            }
            const data =  this.devicePollingList.find(pulldevice => pulldevice.serialNumber === e.serialNumber);
            if (data) {
              e.resetCount = data.count;
            }
            this.deviceList.forEach(elementdevie => {
              if (elementdevie.serialNumber === e['deviceData'][0].serialNumber) {
                elementdevie['resetCount'] = e.resetCount;
              }
            });
            let pendingDevices = this.devicePollingList.filter(e => e.count < 5);
            this.devicePollingList = pendingDevices;
            if (this.devicePollingList.length === 0) {
              if (this.pollingEventId) {
                clearInterval(this.pollingEventId);
              }
            }
          });

          let pendingDevices = this.devicePollingList.filter(e => e.count < 5);
          this.devicePollingList = pendingDevices;
          if (this.devicePollingList.length === 0) {
            if (this.pollingEventId) {
              clearInterval(this.pollingEventId);
            }
          }

        this.devicePollingList.forEach((resetDevice, index) => {
          const temp = deviceList.find(element => element.serialNumber === resetDevice.serialNumber);
          if (temp.deviceState === 'Online') {
            this.devicePollingList[index].count = 5;
            this._snackBar.open('Device is online', '', {
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['failure']
            });
          } else {
              this.deviceList.find(element => element.serialNumber === resetDevice.serialNumber).isResetting = true;
                this.currentDeviceList.forEach(element => {
                  if (element.deviceData[0].serialNumber === resetDevice.serialNumber) {
                    element['isResetting'] = true;
                  }
                });
                // this.currentDeviceList.find(element =>element.deviceData[0].serialNumber === resetDevice.serialNumber).isResetting = true;
              this.devicePollingList[index].count += 1;
          }
        });
      }
    }, err => {
      this.isLoading = false;
    });
  }
  closeReset (index, type) {
    if (type == 'ALL') {
      this.deviceList[index]['resetCount'] = 0;
      this.deviceList[index]['isResetting'] = false;
    } else {
      this.currentDeviceList[index]['resetCount'] = 0;
      this.currentDeviceList[index]['isResetting'] = false;
    }
  }

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.pollingEventId) {
      clearInterval(this.pollingEventId);
    }
  }

  getDisplayedStatus(status: string): string {
    let res = status;
    switch (status) {
      case 'testing':
        res = 'under test';
        break;
      case 'online':
        res = 'available';
        break;
    }
    return res;
  }

  getDeviceStatus(data: any): string {
    const state = data.deviceState;
    const stateCode = data.deviceStateCode;
    const commStatus = data.deviceCommunicationStatus;
    let displayed = 'unknown';
    if (state) {
      if (state.toLowerCase() === 'online') {
        if (stateCode.toLowerCase() === 'online') {
          if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
            displayed = 'testing';
          } else {
            displayed = 'online';
          }
        } else {
          if (stateTooltip[stateCode.toLowerCase()]) {
            displayed = stateCode.toLowerCase();
          }
        }
      } else if (state.toLowerCase() === 'offline') {
        if (stateCode && stateCode !== '') {
          if (stateCode.toLowerCase() === 'removed') {
            displayed = 'disconnected';
          } else {
            if (stateTooltip[stateCode.toLowerCase()]) {
              displayed = stateCode.toLowerCase();
            }
          }
        }
      } else if (state.toLowerCase() === 'blocked') {
        displayed = state.toLowerCase();
      }
    }
    return displayed;
  }

  showOfflineTooltip(data: any): string {
    const state = this.getDeviceStatus(data);
    if (state) {
      return stateTooltip[state] ? stateTooltip[state] : '';
    } else {
      return '';
    }
  }

  applyFilter(value): void {
    if (value === this.deviceState) {
      this.deviceState = '';
      if (this.searchLab.length || this.searchLocation.length) {
        this.SearchDeviceLocal();
      } else if (this.searchText.length) {
        this.SearchDeviceLocal1();
      } else {
        this.deviceList = this.deviceListTemp;
        this.localFilter();
      }
    } else {
      this.deviceState = value;
      this.localFilter();
    }
  }

  localFilter(): void {
    this.deviceList =  this.deviceListTemp.filter(device => {
      if ((this.deviceState && device.deviceStateCode === this.deviceState || !this.deviceState.length) &&
          ((this.selectedBookedFilter === 'booked' && device.isBooked)
          ||
          (this.selectedBookedFilter === 'available' && !device.isBooked && device.deviceStateCode === 'ONLINE')
          || !this.selectedBookedFilter.length)) {
        return device;
      }
    });
    if (this.searchText) {
      this.searchByText(this.deviceList);
    }
    if (this.searchLab) {
      this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
    }
    this.countAmountDevices();
  }

  groupBy(objectArray, property, filterProperty?: string, filterValue?: string): any {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];

      if (filterProperty && filterValue) {
        if (obj[filterProperty] !== filterValue) {
          return acc;
        }
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for the given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  filterByBooked(type: boolean): void {
    if (this.selectedBookedFilter.length
        && ((this.selectedBookedFilter === 'available' && !type) || (this.selectedBookedFilter === 'booked' && type))) {
      this.selectedBookedFilter = '';
      if (this.searchLab.length || this.searchLocation.length) {
        this.SearchDeviceLocal();
      } else if (this.searchText.length) {
        this.SearchDeviceLocal1();
      } else {
        this.deviceList = this.deviceListTemp;
        this.localFilter();
      }
    } else {
      this.selectedBookedFilter = type ? 'booked' : 'available';
      this.localFilter();
    }
  }

  toggleSidepanelAction(): void {
    this.drawer.toggle();
  }

  countAmountDevices() {
    this.availableAndroid = 0;
    this.availableIos = 0;
    this.deviceList.forEach(element => {
      if (element['oem'] === 'Apple') {
        this.availableIos = this.availableIos + 1;
      } else {
        this.availableAndroid = this.availableAndroid + 1;
      }
    });
    if (this.searchText) {
      this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
    } else {
      if (this.searchLab) {
        const filteredByLabDeviceList = this.deviceListTemp.filter(device => device.labName === this.searchLab);
        this.bookingFilterList = this.groupBy(filteredByLabDeviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
      } else {
        this.bookingFilterList = this.groupBy(this.deviceListTemp, 'isBooked', 'deviceStateCode', 'ONLINE');
      }
    }
  }
  searchByText(list) {
    const keys = ['serialNumber', 'modelName', 'osVersion'];
    const values = [];
    if (this.searchText) {
      values.push(this.searchText.toLowerCase());
    }
    const regex = new RegExp(values.join('|'));
    this.deviceList =  list.filter(e =>  keys.some(k => regex.test(e[k]?.toLowerCase())) );
  }

  updateCurrentOption(option: string) {
    const today = new Date();
    switch (option) {
      case 'Today':
        this.typeReservation = 'TODAY';
        this.timeTillToday = this.formatDate(today);
        this.searchEndDate = this.timeTillToday;
        break;
      case 'Tomorrow':
        this.typeReservation = 'TOMORROW';
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        this.timeTillTomorrow = this.formatDate(tomorrow);
        this.searchEndDate = this.timeTillTomorrow;
        break;
      case 'This week':
       this.typeReservation = 'END_WEEK';
        const thisSunday = new Date();
        thisSunday.setDate(today.getDate() + (7 - today.getDay()));
        this.timeTillThisWeek = this.formatDate(thisSunday);
        this.searchEndDate = this.timeTillThisWeek;
        break;
      case 'Custom':
        this.typeReservation = 'DATE';
        this.timeTillCustomDate = this.formatDate(new Date(this.searchEndDate));
        this.searchEndDate = '';
        break;
    }
  }

  updateCurrentExtendOption(option: string) {
    const today = new Date();
    switch (option) {
      case 'Day':
        this.typeExtend = 'DAY';
        today.setDate(new Date().getDate() + 1);
        this.timeExtendTillDay = this.formatDate(this.minDateCal);
        break;
      case 'This week':
        this.typeExtend = 'END_WEEK';
        const thisSunday = new Date();
        thisSunday.setDate(today.getDate() + (7 - today.getDay()));
        this.timeExtendTillWeek = this.formatDate(thisSunday);
        break;
      case 'Custom':
        this.typeExtend = 'DATE';
        this.timeExtendTillCustomDate = this.formatDate(new Date(this.searchEndDate));
        break;
    }
  }

  formatDate(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${month}/${day}/${year}`;
  }
}
