<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title" *ngIf="isRnpAvailable">
    <h1> Record and Play</h1>
    <div class="filters">
        <input *ngIf="activeTab === TypeEnum.CASE && backupTests.length" (input)="testListSearchChange()" [(ngModel)]="testListSearch"
               [ngModelOptions]="{standalone: true}" type="text" maxlength="50" class="form-control search-field"
               placeholder="Search"/>
        <input *ngIf="activeTab === TypeEnum.RESULT && backupResults.length" (input)="filterList()" [(ngModel)]="resultListSearch"
               [ngModelOptions]="{standalone: true}" type="text" maxlength="50" class="form-control search-field"
               placeholder="Search"/>
        <button class="snap-button primary create-new-button" routerLink="/snap/rnp-new-test">
            Record a new test
        </button>
    </div>
</div>
<div class="tabs" *ngIf="isRnpAvailable">
    <span [ngClass]="{ active: activeTab === TypeEnum.CASE}" (click)="openTab(TypeEnum.CASE)">
        Test Cases
        <div class="total-subscriptions">
            {{tests.length || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === TypeEnum.RESULT}" (click)="openTab(TypeEnum.RESULT)">
        Test Results
        <div class="total-subscriptions">
            {{results.length || 0}}
        </div>
    </span>
</div>

<div class="no-access" *ngIf="!isRnpAvailable">
    <p>
        Record and Play is not available with your current subscription plan. <br />
        To get access to Record and Play, you must upgrade your account to a plan that includes Record and Play feature.
    </p>
</div>

<div class="empty-result" *ngIf="!tests.length && activeTab === TypeEnum.CASE && isRnpAvailable">
    <img alt="" src="../../../../assets/images/empty_folder.svg">
    <span class="empty-search-title">
        {{backupTests.length ? 'No tests were found' : 'No tests were found'}}
    </span>
    <span>
        {{backupTests.length
        ? 'No result matched to your criteria. Try adjusting your search or filter options to find what you are looking for'
        : 'Generate Snap actions by interacting with the device. Then, save these actions to test and replay the test to check if the flow is working'}}
    </span>
    <span *ngIf="backupTests.length" (click)="clearFiltersForTests()" class="empty-search-action">Clear filters</span>
</div>

<div class="test-list" *ngIf="(tests?.length && backupTests.length) && activeTab === TypeEnum.CASE && isRnpAvailable">
    <div class="tests-list-header"></div>
    <div class="scroll-table-wrapper tests-replays-wrapper">
        <table class="table test-list-table">
            <thead class="tableheading">
            <tr>
                <th scope="col" class="th_momt">Test Name</th>
                <th scope="col" class="th_momt">Device Model</th>
                <th scope="col" class="th_momt">Screen Resolution</th>
                <th scope="col" class="th_momt">Created At</th>
                <th scope="col" class="th_momt"></th>
            </tr>
            </thead>
            <tr *ngFor="let test of tests; index as i" [ngClass]="{active_prop: selectedIndex === i}">
                <td class="test-name-col">
                    <div>
                        <div class="name-container"
                             [tp]="tooltipComponent"
                             tpPlacement="top"
                             [tpOnlyTextOverflow]="true"
                             [tpData]="test.name | tooltipData"
                             [tpMaxWidth]="450">
                            {{test.name}}
                        </div>
                        <div class="description-container"
                             [tp]="tooltipComponent"
                             tpPlacement="top"
                             [tpOnlyTextOverflow]="true"
                             [tpData]="test.description | tooltipData"
                             [tpMaxWidth]="450">
                            {{test.description}}
                        </div>
                    </div>
                </td>
                <td class="table-device-info">{{test.modelName ? test.modelName : 'N/A'}}</td>
                <td class="table-device-info">{{formatScreenResolution(test.screenResolution)}}</td>
                <td class="test-timing">
                    <span>{{test.insertDate * 1000 | date:'MM/dd/yy h:mm a'}}</span>
                </td>
                <td class="test-actions">
                    <div class="actions-wrapper">
                        <span class="test-action" [routerLink]="'/snap/rnp-new-test'" [queryParams]="{id: test.recordPlayId}" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Configure replay and start' | tooltipData">
                            <img alt="" src="../../../../assets/images/run.svg"/>
                        </span>
                        <span class="test-action" (click)="showTestDetails(i)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'View details' | tooltipData">
                            <img alt="" src="../../../../assets/images/eye-new.svg"/>
                        </span>
                        <span class="test-action" (click)="openRenameTest(renameTest, i)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Rename' | tooltipData">
                            <img alt="" src="../../../../assets/images/edit-new.svg"/>
                        </span>
                        <span class="test-action" (click)="deleteTest(test.recordPlayId)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Delete' | tooltipData">
                            <img alt="" src="../../../../assets/images/trash-new.svg"/>
                        </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="empty-result" *ngIf="!results.length && activeTab === TypeEnum.RESULT && isRnpAvailable">
    <img alt="" src="../../../../assets/images/empty_folder.svg">
    <span class="empty-search-title">
        {{backupResults.length ? 'No test results were found' : 'No test results were found'}}
    </span>
    <span>
        {{backupResults.length
        ? 'No result matched to your criteria. Try adjusting your search or filter options to find what you are looking for'
        : 'Replay any test from the list. After that results of this replay will be available here'}}
    </span>
    <span *ngIf="backupResults.length" (click)="clearFiltersForResults()" class="empty-search-action">Clear filters</span>
</div>

<div class="test-list" *ngIf="(results?.length && backupResults.length) && activeTab === TypeEnum.RESULT">
    <div class="tests-list-header" *ngIf="(results?.length || backupResults.length) && activeTab === TypeEnum.RESULT">
        <div class="status-filter">
            <ng-multiselect-dropdown
                    [placeholder]="'Select'"
                    [settings]="getDropDownSettings(1)"
                    [data]="statusList"
                    [(ngModel)]="selectedStatus"
                    (onDeSelect)="filterList()"
                    (onSelect)="filterList()">
            </ng-multiselect-dropdown>
        </div>
        <div class="filter-by-date">
            <img (click)="filterpicker.open()" src="../../../../assets/images/NewUI/calendar_color.png" alt="Selected Icon" class="custom-icon" />
            <span class="displayed-date" [ngClass]="{'datepicker-placeholder': endDateFilter === 0}">{{displayDate(startDateFilter)}}</span>
            <mat-date-range-input [rangePicker]="filterpicker">
                <input matStartDate placeholder="Start date" (dateChange)="updateDisplayedDate('start', $event)" [(ngModel)]="startDate">
                <input matEndDate placeholder="End date" (dateChange)="updateDisplayedDate('end', $event)" [(ngModel)]="endDate">
            </mat-date-range-input>
            <span class="displayed-date" *ngIf="endDateFilter > 0">{{displayDate(endDateFilter)}}</span>
            <mat-date-range-picker #filterpicker>
                <mat-date-range-picker-actions>
                    <button mat-raised-button (click)="clearDateFilter()">Clear</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </div>
    </div>
    <div class="scroll-table-wrapper tests-replays-wrapper">
        <table class="table test-list-table">
            <thead class="tableheading">
            <tr>
                <td>Test name</td>
                <td>Executed at</td>
                <td>Device Model</td>
                <td>Screen Resolution</td>
                <td>Status</td>
                <td>Progress</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let result of results; index as i">
                <td class="test-name-col">
                    <div class="link-to-test"
                         [ngClass]="{'disabled': !checkIfTestExists(result)}"
                         (click)="navigateToOriginalTest(result)"
                         [tp]="tooltipComponent"
                         tpPlacement="top"
                         [tpOnlyTextOverflow]="true"
                         [tpMaxWidth]="450"
                         [tpData]="result.recordDetails.name | tooltipData"
                         [tpInteractive]="false">
                        {{result.recordDetails.name ? result.recordDetails.name : 'N/A'}}
                    </div >
                </td>
                <td class="test-timing">
                    <span>{{result.insertDate * 1000 | date:'MM/dd/yy h:mm a'}}</span>
                </td>
                <td>{{result.modelName}}</td>
                <td>{{formatScreenResolution(result.screenResolution)}}</td>
                <td class="status-col">
                    <span class="_box_ remove_bg" [ngClass]="{
                        'completed': result.status === 'Completed' || result.status === 'Finalizing',
                        'stopped': result.status === 'Stopped',
                        'failed': result.status === 'Failed',
                        'in_progress': result.status === 'Running'
                          }">
                    </span>
                    <span class="replay-status">{{result.status | titlecase}}</span>
                </td>
                <td class="status-col">
                    <div class="results-status" *ngIf="result.iterations" [ngClass]="{failed: result.status === 'Failed'}">
                        <span class="success" *ngIf="showStatusBar(result.status)" tpPlacement="top" [tp]="tooltipComponent"
                          [tpData]="(getNoOfIterationsForStatus(result.iterations, 'Pass') + ' Passed') | tooltipData"
                          [style.width]="getIterationWidth(result.iterations, 'Pass')"></span>
                        <span class="failed" *ngIf="showStatusBar(result.status)" tpPlacement="top" [tp]="tooltipComponent"
                              [tpData]="(getNoOfIterationsForStatus(result.iterations, 'Failed') + ' Failed') | tooltipData"
                              [style.width]="getIterationWidth(result.iterations, 'Failed')"></span>
                        <span class="pending" *ngIf="showStatusBar(result.status)" tpPlacement="top" [tp]="tooltipComponent"
                              [tpData]="(getNoOfIterationsForStatus(result.iterations, 'NotExecuted') + ' Not executed') | tooltipData"
                              [style.width]="getIterationWidth(result.iterations, 'NotExecuted')"></span>
                    </div>
                </td>
                <td class="test-actions">
                    <div class="actions-wrapper">
                        <div class="spinner_wrapper" *ngIf="result.status === 'Running'">
                            <mat-spinner diameter="25"></mat-spinner>
                        </div>
                        <span class="test-action"  *ngIf="result.status !== 'Running'" routerLink="/snap/rnp-test-report" [queryParams]="{id: result.recordReplayId}" [disabled]="result.status === 'Scheduled'"
                              [tp]="tooltipComponent" tpPlacement="left" [tpData]="'View details' | tooltipData">
                            <img alt="" src="../../../../assets/images/eye-new.svg"/>
                        </span>
                        <span class="test-action" *ngIf="result.status === 'Running'" routerLink="/snap/rnp-new-test" [queryParams]="{replay: result.recordReplayId}" [disabled]="result.status === 'Scheduled'"
                              [tp]="tooltipComponent" tpPlacement="left" [tpData]="'View details' | tooltipData">
                            <img alt="" src="../../../../assets/images/eye-new.svg"/>
                        </span>
                        <span class="test-action" [disabled]="result.status === 'Running'" (click)="deleteRecord(result.recordReplayId)"
                              [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Delete' | tooltipData">
                            <img alt="" src="../../../../assets/images/trash-new.svg"/>
                        </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<mat-drawer #drawer mode="over" position="end">
    <app-test-record-sidepanel (toggleSidepanelAction)="toggleSidepanelAction()"
            [testInfo]="sidePanelData" (updateTable)="updateTable($event)"></app-test-record-sidepanel>
</mat-drawer>

<!--Rename test modal-->
<ng-template #renameTest let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>Rename</span>
            <svg (click)="closeConfigModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-content">
            <div class="input-section">
                <input type="text" class="rename-input text-input" id="rename-test" [(ngModel)]="newTestName" placeholder="Enter new name for the test"
                       [ngClass]="{'error': !newTestName.length || !checkForUniqueName(newTestName)}"/>
                <div *ngIf="!checkForUniqueName(newTestName)" class="input-error">The test with this name already exists</div>
                <div *ngIf="!newTestName.length" class="input-error">Test name can't be empty</div>
            </div>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeConfigModal()">Cancel</button>
            <button class="btn control_btn_new"
                    [ngClass]="{disabled: !newTestName.length || !checkForUniqueName(newTestName) || selectedTest.name === newTestName}" (click)="saveNewTestName()">
                Rename
            </button>
        </div>
    </div>
</ng-template>

