<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title">
    <h1>
        Enterprises
        <div class="total-enterprises">
            {{total || 0}}
        </div>
    </h1>
    <div class="filters">
        <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field"
            placeholder="Search" (change)="searchChange()" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: isFilterOn }" (click)="openFilters()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <select class="filter-select form-control" [(ngModel)]="searchStatus" required>
                    <option value="">Select Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="EXPIRED">Expired</option>
                    <option value="INACTIVE">Inactive</option>
                    <option value="DISABLED">Disabled</option>
                </select>
                <ng-multiselect-dropdown [placeholder]="'Select Features'" [settings]="getDropDownSettings(1)"
                    [data]="featureList" [(ngModel)]="searchFeatures" class="multiselect">
                </ng-multiselect-dropdown>
                <select class="filter-select form-control" [(ngModel)]="searchSnapSupport" required>
                    <option value="">Select Snap Support</option>
                    <option *ngFor="let option of snapSupportOptions" [value]="option.email">{{option.name}}</option>
                </select>
                <select class="filter-select form-control" [(ngModel)]="searchEnterpriseSupport" required>
                    <option value="">Select Enterprise Support</option>
                    <option *ngFor="let option of enterpriseSupportOptions" [value]="option.email">
                        {{option.name}}
                    </option>
                </select>
                <div class="filter-buttons">
                    <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
                    <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
                    <button class="snap-button primary" (click)="applyFilters()">Filter</button>
                </div>
            </div>
        </div>
        <button class="snap-button primary create-new-button" routerLink="/admin/enterprises/create-enterprise">
            Create a new enterprise
        </button>
    </div>
</div>
<div class="empty-result" *ngIf="isFiltered && enterpriseList?.length === 0 && !isLoading">
    <img alt="" src="../../../../../assets/images/NewUI/menu_enterprises_large.svg">
    <span class="empty-search-title">No enterprises were found</span>
    <span>
        No results matched your criteria. Try adjusting your search or filter options to find what you are looking
        for.
    </span>
    <span (click)="clearFilters()" class="empty-search-action">Clear filters</span>
</div>
<div class="empty-result" *ngIf="!isFiltered && enterpriseList?.length === 0 && !isLoading">
    <img alt="" src="../../../../../assets/images/NewUI/menu_enteprises_large.svg">
    <span class="empty-search-title">No enterprises were found</span>
    <span>
        Create a new enterprise. Set up and configure enterprises at ease.
    </span>
    <button class="snap-button primary create-new-button" routerLink="/admin/enterprises/create-enterprise">
        Create a new enterprise
    </button>
</div>
<table *ngIf="!isLoading && enterpriseList?.length > 0" class="enterprise-table">
    <thead>
        <tr>
            <th></th>
            <th>Name</th>
            <th>Location</th>
            <th>Status</th>
            <th>Snap support</th>
            <th>Enterprise support</th>
            <th>Labs</th>
            <th>Devices</th>
            <th>Users</th>
            <th>Subscriptions</th>
            <th>Subscription start date</th>
            <th>Subscription end date</th>
            <th>Log Period Limit</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let enterprise of enterpriseList; index as i">
            <td>
                <img class="logo" [src]="enterprise.logo" *ngIf="enterprise.logo" alt="" />
                <div class="logo empty-logo" *ngIf="!enterprise.logo">
                    <img alt="" src="../../../../../assets/images/main-header/menu_enterprises_selected.svg">
                </div>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="enterprise.name | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{enterprise.name}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="enterprise.location | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{enterprise.location}}
                </span>
            </td>
            <td>
                <div class="enterprise-status"
                    [ngClass]="{ active: getEnterpriseStatus(enterprise) === 'ACTIVE', disabled: getEnterpriseStatus(enterprise) === 'DISABLED' }"
                    [tp]="tooltipComponent" [tpData]="(getEnterpriseStatus(enterprise) | titlecase) | tooltipData"></div>
                {{getEnterpriseStatus(enterprise) | titlecase}}
            </td>
            <td>
                <span class="user-icon" [tp]="tooltipComponent"
                    [tpData]="displaySupport(enterprise, 'SNAP') | tooltipData">
                    <user-profile-icon [user]="getSupportUser(enterprise, 'SNAP')"></user-profile-icon>
                </span>
            </td>
            <td>
                <span class="user-icon" [tp]="tooltipComponent"
                    [tpData]="displaySupport(enterprise, 'ENTERPRISE') | tooltipData">
                    <user-profile-icon [user]="getSupportUser(enterprise, 'ENTERPRISE')"></user-profile-icon>
                </span>
            </td>
            <td>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.labCount || 0) + ' labs added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/labs', { enterpriseId: enterprise.id })">
                        {{enterprise.labCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="enterprise.maxLabCount">
                        {{enterprise.maxLabCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="!enterprise.maxLabCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </td>
            <td>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.deviceCount || 0) + ' devices added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/device', { enterpriseId: enterprise.id })">
                        {{enterprise.deviceCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData"
                        *ngIf="enterprise.maxDeviceCount">
                        {{enterprise.maxDeviceCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData"
                        *ngIf="!enterprise.maxDeviceCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </td>
            <td>
                <div class="count-container">
                    <span class="count" [tp]="tooltipComponent"
                        [tpData]="((enterprise.userCount || 0) + ' users added to enterprise') | tooltipData"
                        (click)="navigatePage('/admin/users', { enterpriseId: enterprise.id })">
                        {{enterprise.userCount || 0}}
                    </span>
                    <div class="separator"></div>
                    <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData" *ngIf="enterprise.maxUserCount">
                        {{enterprise.maxUserCount || 0}}
                    </span>
                    <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData"
                        *ngIf="!enterprise.maxUserCount">
                        <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                    </span>
                </div>
            </td>
            <td>
                <feature-list [features]="enterprise.subscription?.features"></feature-list>
            </td>
            <td class="centered">
                <span *ngIf="enterprise.subscription?.expiration.startAt">
                    {{enterprise.subscription.expiration.startAt * 1000 | date: "MM.dd.yyyy" }}
                </span>
            </td>
            <td class="centered">
                <span *ngIf="enterprise?.subscription?.expiration.endAt === '' || enterprise?.subscription?.expiration.endAt >= unlimited / 1000">
                    <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                </span>
                <span *ngIf="enterprise.subscription?.expiration.endAt && enterprise.subscription?.expiration.endAt < unlimited / 1000">
                    {{enterprise.subscription.expiration.endAt * 1000 | date: "MM.dd.yyyy" }}
                </span>
            </td>
            <td class="centered">
                <span *ngIf="!enterprise.logsDeletePeriod">
                    <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
                </span>
                <span *ngIf="enterprise.logsDeletePeriod">
                    {{enterprise.logsDeletePeriod}}
                </span>
            </td>
            <td class="menu-cell">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <span class="menu-icon">
                        <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="menu_btn_list" (click)="changeEnterpriseStatus(enterprise)">
                        <span>{{enterprise.status === 'ACTIVE' ? 'Disable' : 'Activate'}}</span>
                    </button>
                    <button mat-menu-item class="menu_btn_list" (click)="openEdit(enterprise.id)">
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item class="menu_btn_list" (click)="openConfirmDelete(enterprise.id)">
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </tr>
    </tbody>
</table>
<div class="pagination-container" *ngIf="enterpriseList?.length > 0">
    <span class="limit">
        <label for="limit">Rows per page: </label>
        <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </span>
    <span>{{paginationText}}</span>
    <div class="pagination-buttons">
        <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
            <img alt="" src="../../../../assets/images/arrow_back.svg">
        </div>
        <div [ngClass]="{ disabled: currentPage + 1 >= totalPages }" class="arrow-button" (click)="changePage(1)">
            <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
        </div>
    </div>
</div>
