import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationDialogProps {
  header: string;
  content: string;
  accept: string;
  cancel: string;
  decline?: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  dialogHeader = '';
  dialogText = '';
  dialogActionAccept = '';
  dialogActionCancel = '';
  dialogActionDecline = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogProps,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit(): void {
    this.dialogHeader = this.data.header;
    this.dialogText = this.data.content;
    this.dialogActionAccept = this.data.accept;
    this.dialogActionCancel = this.data.cancel;
    this.dialogActionDecline = this.data.decline;
  }

  accept(): void {
    this.close('accept');
  }

  cancel(): void {
    this.close('cancel');
  }

  decline(): void {
    this.close('decline');
  }

  close(msg: string): void {
    this.dialogRef.close(msg);
  }
}
