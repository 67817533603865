<div class="container_test" *ngIf="isMTAvailable">
    <div class="empty-folder-container"
        *ngIf="testList.length === 0 && !manualTestService.isFiltered && manualTestService.mtSessionStatus !== 3">
        <app-empty-folder [title]="'No manual tests were found'"
            [description]="'Interact with the device. Then save or discard the test'"
            [actions]="emptyActions"></app-empty-folder>
    </div>
    <div class="container_new_test" [ngClass]="{'flex': manualTestService.mtSessionStatus !== 2 }"
         *ngIf="(manualTestService.mtSessionStatus === 0 || manualTestService.mtSessionStatus === 2 || manualTestService.mtSessionStatus === 3) && !manualTestService.isSelectDeviceMode">
        <app-spinner *ngIf="isLoading"></app-spinner>
            <div class="sub_container left_container" id="left-side-main-split" *ngIf="manualTestService.mtSessionStatus !== 2">
                <app-screencast [deviceInfo]="manualTestService.selectedManualTestDevice"
                                [isTestRunning]="manualTestService.isTestRunning"
                                [isTestRecording]="manualTestService.isTestRecording"
                                [replayInProgress]="replayInProgress"
                                [actionToReplay]="actionToReplay"
                                [ignoreTyping]="manualTestService.ignoreTyping"
                                [isManualTest]="true"
                                (selectDeviceUpdate)="updateDeviceSelect($event)"
                ></app-screencast>
            </div>
            <div class="sub_container right_container" id="right-side-main-split">
                <div class="container_form" *ngIf="manualTestService.mtSessionStatus === 3">
                    <app-new-manual-test-form></app-new-manual-test-form>
                </div>
                <app-manual-test-list *ngIf="
        manualTestService.mtSessionStatus === 2
        && (testList.length > 0 || manualTestService.isFiltered)"></app-manual-test-list>
                <app-tabs *ngIf="manualTestService.mtSessionStatus === 0"
                          [releaseDevice]="releaseDevice"
                ></app-tabs>
            </div>
    </div>
    <app-manual-test-view *ngIf="manualTestService.mtSessionStatus === 3 && manualTestService.isSelectDeviceMode"></app-manual-test-view>
    <app-finalize-manual-test *ngIf="manualTestService.mtSessionStatus === 1" [releaseDevice]="releaseDevice"></app-finalize-manual-test>
</div>

<div class="no-access" *ngIf="!isMTAvailable">
    <p>
        Manual testing is not available with your current subscription plan. <br />
        To get access to Manual testing, you must upgrade your account to a plan that includes Manual tests feature.
    </p>
</div>