import { Injectable } from "@angular/core";
import { ManualTest } from "../../shared/interfaces";
import { BehaviorSubject, Observable, Subscription, interval } from "rxjs";
import { NetworkService } from "../../shared/network.service";
import { ApiResponseDto, GetManualTestDto, NewManualTestDto, UpdateManualTestDto } from "../../shared/dto";
import { ArtifactTypeEnum, MtSessionStatusEnum, TestResultEnum, TestStatusEnum } from "../../shared/enum";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogModalService } from "../../shared/dialog-modal.service";

@Injectable({
    providedIn: 'root'
})
export class ManualTestService {
    constructor(
        private networkService: NetworkService,
        private _snackBar: MatSnackBar,
        private dialogService: DialogModalService,
    ) { }

    private testList = new BehaviorSubject<ManualTest[]>([]);
    testList$ = this.testList.asObservable();

    totalPages = 1;
    currentPage = 0;
    pageSize = 10;
    searchValue = '';
    startDate: number = null;
    endDate: number = null;

    mtSessionStatus = MtSessionStatusEnum.DEFAULT;
    isFiltered = false;
    ongoingTestId: string;

    pingInterval: Subscription = null;
    pollingInterval: Subscription = null;

    selectedStatuses: { item_id: TestStatusEnum | TestResultEnum, item_text: string }[] = [];
    selectedManualTestDevice: any;
    isSelectDeviceMode = false;
    isTestRunning = false;
    isTestRecording = false;
    ignoreTyping = false;
    testName = '';
    testDescription = '';
    allAvailableArtifacts = {
        [ArtifactTypeEnum.LOG]: true,
        [ArtifactTypeEnum.VIDEO]: true,
        [ArtifactTypeEnum.USER_ACTION]: true,
        [ArtifactTypeEnum.KPIS]: true,
    }
    artifactSelection = { ...this.allAvailableArtifacts };
    totalElements = 0;

    changeDeviceDetail = (v) => {
        this.selectedManualTestDevice = v;
    }

    changeSelectDeviceMode = () => {
        this.isSelectDeviceMode = !this.isSelectDeviceMode;
    }

    setTestList(value: ManualTest[]) {
        this.testList.next(value);
    }

    createNewTest(): void {
        this.mtSessionStatus = MtSessionStatusEnum.NEW;
    }

    removeNewTestForm(): void {
        this.mtSessionStatus = MtSessionStatusEnum.DEFAULT;
    }

    getManualTests(callback?: () => void, sn?: string, sort = 'createdAt,desc'): void {
        const statuses = this.selectedStatuses.map(item => item.item_id);
        this.networkService.get(`api/automation/mt?page=${this.currentPage}&size=${this.pageSize}${sn ? `&serialNumbers=${sn}` : ''}${this.startDate ? `&startAt=${this.startDate}` : ''}${this.endDate ? `&endAt=${this.endDate}` : ''}${this.searchValue ? `&search=${this.searchValue}` : ''}${sort ? `&sort=${sort}` : ''}${statuses.length ? `&statuses=${statuses}` : ''}`, null, null, 'basic')
            .subscribe((res: ApiResponseDto<GetManualTestDto>) => {
                if (res.statusCode === 200) {
                    const response = res.data as GetManualTestDto;
                    this.setTestList(response.content);
                    this.totalPages = response.totalPages;
                    this.totalElements = response.totalElements;
                    this.isFiltered = this.searchValue.length > 0 || !!this.endDate || !!this.startDate || this.selectedStatuses.length > 0;
                    const hasFinalizing = response.content.some((test) => test.status === TestStatusEnum.FINALIZING);
                    if (hasFinalizing) {
                        if (!this.pollingInterval || this.pollingInterval.closed) {
                            this.pollingInterval = interval(20000).subscribe(() => {
                                this.getManualTests();
                            });
                        }
                    } else {
                        this.stopPolling();
                    }
                    callback && callback();
                }
            });
    }

    deleteTest(id: string, clearArtifacts = true): void {
        this.networkService.delete(`api/automation/mt?mtIds=${id}&clearArtifacts=${clearArtifacts}`, null, null, 'basic')
            .subscribe((res: ApiResponseDto<any>) => {
                if (res.statusCode === 200) {
                    this.removeFromTestList(id);
                } else {
                    this._snackBar.open(res.data.message, '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['failure'],
                    });
                }
            });
    }

    deleteArtifact(testId: string, id: string) {
        this.networkService.post(`api/automation/mt/${testId}/artifacts/remove?artifactId=${id}`, null, null, 'basic')
            .subscribe((res: ApiResponseDto<ManualTest>) => {
                if (res.statusCode === 200) {
                    this.updateTestList(res.data as ManualTest);
                }
            });
    }

    cancelArtifact(testId: string, id: string) {
        this.networkService.post(`api/automation/mt/${testId}/artifacts/cancel?artifactId=${id}`, null, null, 'basic')
            .subscribe((res: ApiResponseDto<ManualTest>) => {
                if (res.statusCode === 200) {
                    this.updateTestList(res.data as ManualTest);
                }
            });
    }

    startManualTestSession(form: NewManualTestDto): Observable<ApiResponseDto<ManualTest>> {
        const { name, description, serialNumber, artifactSelection } = form;
        const body = {
            sn: serialNumber,
            name,
            description,
            artifacts: artifactSelection.map((artifact) => ({
                type: 'FILE',
                content: artifact,
                parameters: [],
            }))
        };

        return this.networkService.post('api/automation/mt/start', body, null, 'basic');
    }

    handleTestStart(test: ManualTest): void {
        this.searchValue = '';
        this.currentPage = 0;
        this.startDate = null;
        this.endDate = null;
        this.getManualTests(() => {
            this.mtSessionStatus = MtSessionStatusEnum.ONGOING;
            this.ongoingTestId = test._id;
            this.isTestRunning = true;
            this.pingInterval = interval(10000).subscribe(() => {
                this.networkService.post(`api/automation/mt/${test._id}/ping`, null, null, 'basic').subscribe((res) => {
                    if (res.statusCode === 404) {
                        this.pingInterval.unsubscribe();
                        this.mtSessionStatus = MtSessionStatusEnum.DEFAULT;
                        this.removeFromTestList(this.ongoingTestId);
                        this.ongoingTestId = null;
                        this.isTestRunning = false;
                        this.dialogService.openNotificationDialog('manualTestDiscarded');
                    }
                });
            });
        });
    }

    stopManualTestSession(): void {
        this.stopPinging();
        this.networkService.post(`api/automation/mt/${this.ongoingTestId}/stop`, null, null, 'basic').subscribe(() => {
            this.mtSessionStatus = MtSessionStatusEnum.FINALIZING;
            this.isTestRunning = false;
            this.getManualTests();
        });
    }

    saveOngoingTest() {
        this.networkService.post(`api/automation/mt/${this.ongoingTestId}/stop`, null, null, 'basic').subscribe(() => {
            this.completeTest();
        });
    }

    updateTestList(newTest: ManualTest): void {
        const newTestList = this.testList.getValue().map((test) => {
            if (test._id === newTest._id) {
                return newTest;
            }
            return test;
        });
        this.setTestList(newTestList);
    }

    removeFromTestList(id: string) {
        const newTestList = this.testList.getValue().filter((test) => test._id !== id);
        this.setTestList(newTestList);
    }

    completeTest(): void {
        this.mtSessionStatus = MtSessionStatusEnum.DEFAULT;
        this.ongoingTestId = null;
        this.isTestRunning = false;
        this.stopPinging();
    }

    discardOngoingTest() {
        this.stopPinging();
        this.networkService.post(`api/automation/mt/${this.ongoingTestId}/cancel`, null, null, 'basic').subscribe(() => {
            this.removeFromTestList(this.ongoingTestId);
            this.mtSessionStatus = MtSessionStatusEnum.DEFAULT;
            this.isTestRunning = false;
            this.ongoingTestId = null;
        });
    }

    resetFilters(): void {
        this.searchValue = '';
        this.currentPage = 0;
        this.startDate = null;
        this.endDate = null;
        this.getManualTests();
    }

    stopPinging(): void {
        if (this.pingInterval && !this.pingInterval.closed) {
            this.pingInterval.unsubscribe();
        }
    }

    stopPolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }

    uploadArtifact(testId, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.networkService.postWithProgress(`api/automation/mt/${testId}/artifacts/upload`, formData);
    }

    resetDateFilter(): void {
        this.startDate = null;
        this.endDate = null;
        this.getManualTests();
    }

    setTodayFilter(): void {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
        this.getManualTests();
    }

    setLastWeekFilter(): void {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setDate(now.getDate() - 7);
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
        this.getManualTests();
    }

    setCustomDateFilter(type: string, date: Date): void {
        if (type === 'start') {
            this.startDate = new Date(date).getTime() / 1000;
        }
        if (type === 'end') {
            this.endDate = new Date(date).setHours(23, 59, 59) / 1000;
        }
        if (this.startDate && this.endDate) {
            this.getManualTests();
        }
    }

    changePageSize(value: number): void {
        this.currentPage = 0;
        this.pageSize = value;
        this.getManualTests();
    }

    changePage(index: number): void {
        const nextPage = this.currentPage + index;
        if (nextPage >= 0 && nextPage < this.totalPages) {
            this.currentPage = nextPage;
            this.getManualTests();
        }
    }

    updateTest(testId: string, form: UpdateManualTestDto): Observable<ApiResponseDto<ManualTest>> {
        return this.networkService.post(`api/automation/mt/${testId}`, form, null, 'basic');
    }

    deleteTestViaCloud(testName: string): void {
        this.networkService.delete(`api/automation/mt/${testName}`, null, null, 'basic').subscribe(() => {
            this.getManualTests();
        });
    }

    deleteArtifactViaCloud(testName: string, artifactName: string): void {
        this.networkService.post(`api/automation/mt/${testName}/artifacts/remove/cloud?artifactName=${artifactName}`, null, null, 'basic')
            .subscribe((res: ApiResponseDto<ManualTest>) => {
                if (res.statusCode === 200) {
                    this.updateTestList(res.data as ManualTest);
                }
            });
    }

    renameTestViaCloud(testName: string, newName: string): Observable<ApiResponseDto<ManualTest>> {
        return this.networkService.post(`api/automation/mt/${testName}/rename`, { newName }, null, 'basic');
    }

    renameArtifactViaCloud(testName: string, artifactName: string, newName: string): Observable<ApiResponseDto<ManualTest>> {
        return this.networkService.post(`api/automation/mt/${testName}/artifacts/rename/cloud?artifactName=${artifactName}`, { newName }, null, 'basic');
    }


    downloadAllArtifacts(testName: string): Observable<any> {
        return this.networkService.getZip(`api/automation/mt/${testName}/download-zip`, null, null, null);
    }

    checkOngoing(): void {
        if (this.ongoingTestId) {
            this.discardOngoingTest();
        }
    }

    getFileDownloadApi(queryPram: any) {
        return this.networkService.get('api/file-storage/download?' + queryPram, null, null, 'basic');
    }

    updateIgnoreTyping = (v: boolean): void => {
        this.ignoreTyping = v;
    }
}
