import { CustomTestStepAction } from "./custom-test-action.interface";
import { CustomTestReplayStatusEnum } from "./custom-test-replay.interface";

export interface CustomTestStepParameter {
    key: string;
    changeable: boolean;
    initPlace: "CREATE_TEST" | "RUN_TEST";
    type: string;
    defaultValue: string | number;
    name: string;
    description: string;
    value: string | number;
    constraints?: {
        name: string;
        value: number;
        description: string;
    }[]
}

export interface CustomTestFormStep {
    action: CustomTestStepAction;
    validators: {
        category: CustomTestValidatorType;
        target: {
            type: string;
            parameters: {
                key: string;
                value: string | number;
            }[];
        },
        property: {
            type: string;
            value: string;
            typeName?: string;
            valueName?: string;
        },
        maxTimeout?: number;
        status?: string;
    }[];
    startedAt?: number;
    endedAt?: number;
    status?: string;
    error?: string;
}

export interface CreateCustomTestForm {
    name: string;
    description: string;
    devices: { index: number, noVerifications: boolean }[];
    steps: CustomTestFormStep[];
}

export enum CustomTestValidatorType {
    CHANGE_TO = "CHANGE_TO",
    STAY_IN = 'STAY_IN' 
}

export const CustomTestValidatorTypeMap = new Map<CustomTestValidatorType, string>([
    [CustomTestValidatorType.CHANGE_TO, 'Change to'],
    [CustomTestValidatorType.STAY_IN, 'Stay in'],
]);
