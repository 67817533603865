<div class="containes-fluid p-0">
  <div class="heightfull">
    <div class="col-md-12 overflow_hidden">
      <!-- STEP ONE SECTION STARTS -->
      <div class="row contentbox_new m-0">
        <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
        <div class="col-md-6 leftimg_background pt-3 pl-md-5">
          <div class="row ">
            <div class="p-0 m-0 col-md-9 col-lg-8  mx-auto">
              <p class="welcome_ " >Welcome to Snap!</p>
              <p class="signin_new" [ngClass]="{ visible_none: stepChanged == true}">How are you planning to use Snap Automation?</p>
            </div>
            <div class="col-md-8 col-lg-8 mx-auto b-4" *ngIf="stepChanged == false">
              <div
                class="row justify-content-center align-items-center mt-4"
              >
                <span class="circle1 active_circle" >1</span>
                <span class="line_grey"></span>
                <span class="circle1">2</span>
              </div>
            </div>
            <div class="col-md-8 col-lg-8 mx-auto b-4" *ngIf="stepChanged == true">
              <div
                class="row justify-content-center align-items-center mt-4"
              >
                <span class="circle1 active_circle" (click)="isStepChange()" >1</span>
                <span class="line_grey"></span>
                <span class="circle1 active_circle">2</span>
              </div>
            </div>
          </div>
          <div class="row  sign-up-content align-items-center">
            <div class="form_outer col-lg-8 col-md-9  mx-auto col-sm-12" *ngIf="stepChanged == false">
             
                      <div class="li_outer">
                        <ul>
                          <div class="row">
                            <div class="col-md-6 mt-2">
                              <li
                                class="card_out pointer"
                                (click)="tabSelectFn('1')"
                                [ngClass]="{ selectedTab: isTab1Selected == true }"
                              >
                                <div class="li_card">
                                  <img
                                  *ngIf="isTab1Selected == true"
                                    class="user_img img-fluid"
                                    src="../../../../assets/images/NewUI/user_on.svg"
                                  />
                                  <img
                                  *ngIf="isTab1Selected == false"
                                    class="user_img img-fluid"
                                    src="../../../../assets/images/NewUI/user_off.svg"
                                  />
                                  <p class="li_card_title mt-2"  [ngClass]="{ li_card_title_active: isTab1Selected == true }">For Myself</p>
                                  <p class="title color_grey"  [ngClass]="{ color_active: isTab1Selected == true }">For individual use.</p>
                                </div>
                              </li>
                            </div>
          
                            <div class="col-md-6 mt-2" >
                              <li
                                class="card_out pointer"
                                (click)="tabSelectFn('2')"
                                [ngClass]="{ selectedTab: isTab2Selected == true }"
                              >
                                <div class="li_card">
                                  <img
                                  *ngIf="isTab2Selected == true"
                                    class="user_img img-fluid"
                                    src="../../../../assets/images/NewUI/team_on.svg"
                                  />
                                  <img
                                  *ngIf="isTab2Selected == false"
                                    class="user_img img-fluid"
                                    src="../../../../assets/images/NewUI/team_off.svg"
                                  />
                                  <p class="li_card_title  mt-2"  [ngClass]="{ li_card_title_active: isTab2Selected == true }">With My Team</p>
                                  <p class="title color_grey"  [ngClass]="{ color_active: isTab2Selected == true }">Weather its a team of ten or a team of ten thousand. Start here and our team will work with your team.</p>
                                  
                                </div>
                              </li>
                            </div>
                          </div>
                        </ul>
                      </div>
                      <div class="text-left">
                        <button class="btn mt-4 loginBtn" (click)="isStepChange()">
                          CONTINUE
                        </button>
                       
                        <!-- <div (click)="tryGoogleLogin()">
                          <p class=" title bold pointer d-inline">Sign up with Google</p><span> <img class="google_icon pointer" src="../../../../assets/images/Bitmap.png"></span>
                        </div> -->
                      </div>
                    </div>
                    <div class="form_outer  col-md-9 col-lg-8  mx-auto col-sm-12" *ngIf="stepChanged == true">
                      <form [formGroup]="signupForm" class=" pr-4 pb-4 signin_new_form">
                        <p class="signup_new mt-2">Enter Your Details</p>
                        <div class="inputs">
                          <div class="form-group mt-3">
                            <div
                              *ngIf="isTab1Selected == true"
                              class="position-relative mt-2 mb-4"
                            >
                            <div class="signin_label" for="">First Name</div>
                              <input
                                type="text"
                                formControlName="firstName"
                                maxlength="50"
                                class="form-control login_input_signIn position-relative"
                                placeholder="First Name"
                              />
          
                              <div *ngIf="signupForm.controls['firstName'].invalid && (signupForm.controls['firstName'].dirty || signupForm.controls['firstName'].touched)" class="errorinput text-left">
                                <div *ngIf="signupForm.controls['firstName'].errors.required">
                                  *First Name is required
                                </div>
                                
                            </div>
                            </div>
                            <div
                            *ngIf="isTab1Selected == true"
                            class="position-relative mt-2 mb-4"
                          >
                          <div class="signin_label" for="">Last Name</div>
                            <input
                              type="text"
                              formControlName="lastName"
                              maxlength="50"
                              class="form-control login_input_signIn position-relative"
                              placeholder="Last Name"
                            />
        
                            <div *ngIf="signupForm.controls['lastName'].invalid && (signupForm.controls['lastName'].dirty || signupForm.controls['lastName'].touched)" class="errorinput text-left">
                              <div *ngIf="signupForm.controls['lastName'].errors.required">
                                *Last Name is required
                              </div>
                              
                          </div>
                          </div>
          
                            <div
                              *ngIf="isTab2Selected == true"
                              class="position-relative mt-2 mb-4"
                            >
                            <div class="signin_label" for="">Name</div>
                              <input
                                type="text"
                                formControlName="name"
                                class="form-control login_input_signIn position-relative"
                                placeholder="Company name"
                              />
                              <div *ngIf="signupForm.controls['name'].invalid && (signupForm.controls['name'].dirty || signupForm.controls['name'].touched)" class="errorinput text-left">
                                <div *ngIf="signupForm.controls['name'].errors.required">
                                  *Company name is required
                                </div>
                                
                            </div>
                            </div>
          
                            <div class="position-relative mt-2 mb-4">
                              <div class="signin_label" for="">Email</div>
                              <input
                                type="text"
                                class="form-control login_input_signIn position-relative"
                                formControlName="email"
                                placeholder="Email"
                              />
                              <div *ngIf="signupForm.controls['email'].invalid && (signupForm.controls['email'].dirty || signupForm.controls['email'].touched)" class="errorinput text-left">
                                <div *ngIf="signupForm.controls['email'].errors.required">
                                  *Email is required
                                </div>
                                <div *ngIf="signupForm.controls['email'].errors.pattern">
                                    *Invalid email
                                  </div>
                            </div>
                            </div>
          
                            <div class="position-relative mt-2 mb-4">
                              <div class="signin_label" for="">Password</div>
                              <input
                                type="text"
                                [type]="show ? 'text' : 'password'"
                                formControlName="password"
                                autocomplete="off"
                                class="form-control login_input_signIn position-relative passoword_signup password_inupt_signup"
                                (input)="getPassword()" 
                                placeholder="Password"
                              />
                              <p
                                
                                (click)="generatePassword()"
                                class="show_password pointer"
                              >
                                Generate password
                              </p>
          
                              <p
                              *ngIf="show == false"
                              (click)="password()"
                              class="show_password eye pointer"
                            >
                              <img class="eye_icon" src="../../../../assets/images/eye-slash.svg">
                            </p>
                            <p
                              *ngIf="show == true"
                              (click)="password()"
                              class="show_password eye pointer"
                            >
                            <img  class="eye_icon" src="../../../../assets/images/eye.svg">
                            </p>
          
                              <div *ngIf="signupForm.controls['password'].invalid && (signupForm.controls['password'].dirty || signupForm.controls['password'].touched)" class="errorinput text-left">
                                                      
                                <div *ngIf="signupForm.controls['password'].errors.required">
                                  *Password is required
                                </div>
                            </div>
                            <span class="letter_password" *ngIf="isSpecial == false ">*Include special letter </span>
                                <span class="letter_password" *ngIf="isUpper == false ">*Include upper letter </span>
                                <!-- <span  class="letter_password" *ngIf="isLower == false" >*Include lower letter</span> -->
                                <span  class="letter_password" *ngIf="isLength == false" >*Minimum password length is 8.</span>
          
                            </div>
          
                            <div
                              *ngIf="isTab2Selected == true"
                              class="form-group mt-2 mb-4"
                              for="selectpicker"
                            >
                             
                            </div>
          
                          </div>
                          <div class="text-left">
                            <button class="btn mt-2 loginBtn" [disabled]="!signupForm.valid || isSecure == false" (click)="OpenVerifyMOdal()">
                              SIGN UP
                            </button>
                            <p
                          class="title_account_new font_w_5 mt-3"
                          (click)="naviageSignIn('IN')"
                        >
                          Already have an account?
                          <span class="bold pointer signup_text">LOGIN</span>
                        </p>
                        
                          </div>
                        </div>
                      </form>
                    </div>
          </div>
          
          
        </div>

        <div class="col-md-6 leftimg  m-0 p-0">
          <div class="row text-center align-items-center" style="height:100vh;">
           
            <div class="col-md-10 col-lg-9  mx-auto text-center">
              <div style="margin:0 auto;">
                <img *ngIf="activeSlider == 1"  [@enterAnimation] class="slider_img img-fluid"  src="../../../../assets/images/NewUI/file1.gif" alt="">
                <img *ngIf="activeSlider == 2"  [@enterAnimation] class="slider_img img-fluid"  src="../../../../assets/images/NewUI/file2.gif" alt="">
                <img *ngIf="activeSlider == 3"  [@enterAnimation] class="slider_img img-fluid"  src="../../../../assets/images/NewUI/image3.gif" alt="">
              </div>
              <div class="inner_text">
                <div class="text-center pt-4 " [@enterAnimation] *ngIf="activeSlider == 1">
                  <p class="slide_title">Live Testing</p> 
                  <p class="slide_desc">
                   Live Testing is a great way to show clients what work is in progress.<b> Snap Automation </b> places your application in a temporary online destination with restricted access.
                  </p>
      
                </div>
                <div class="text-center pt-4" [@enterAnimation] *ngIf="activeSlider == 2">
                  <p class="slide_title">Continuous, Automation Testing</p>
                  <p class="slide_desc">
                    Break away from the traditional software development lifecycle and test your applications continuously, providing critical feedback
                    earlier and enabling faster higher-quality delivery and eliminating the hassle of manual quality assurance to validate your application.
                  </p>
      
                </div>
                <div class="text-center pt-4" [@enterAnimation] *ngIf="activeSlider == 3">
                  <p class="slide_title">API Platform Access</p>
                  <p class="slide_desc">
                   <b> Snap Automation </b> provides access to devices and processes through API platform. The APIs are organized using REST and use the standard HTTP response codes. <span class="Url_" (click)="openwebView()">Click Here.</span>
                </div>

              </div>
              <!-- <div class="row justify-content-between icon-padding text-center mt-4">
                <div class="col-md-4 text-center">
                  <img class="arrow_icon" (click)="navigateleft()" src="../../../../assets/images/NewUI/left_arrow.svg" alt="">
                </div>
                <div class="col-md-4 text-center">
                  <span class="arrowCircle" [ngClass]="{ arrowActive: activeSlider == 1 }" (click)="navigateImage('1')"></span>
                  <span class="arrowCircle" [ngClass]="{ arrowActive: activeSlider == 2 }" (click)="navigateImage('2')"></span>
                  <span class="arrowCircle" [ngClass]="{ arrowActive: activeSlider == 3 }" (click)="navigateImage('3')"></span>
                </div>
                <div class="col-md-4 text-center">
                  <img class="arrow_icon" (click)="navigateright()" src="../../../../assets/images/NewUI/right_arrow.svg" alt="">
                </div>
              </div> -->
            </div>
           </div>
          
        </div>
      </div>
      <!-- STEP ONE SECTION Ends -->
    </div>
  </div>
</div>
<app-main-footer></app-main-footer>
<!-- <app-main-footer></app-main-footer> -->