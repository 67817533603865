export interface CustomTestArtifact {
    deviceIndex: number;
    artifacts: CustomArtifactInfo[]
}

export interface CustomArtifactInfo { 
    type: CustomArtifactTypeEnum,
    bucketName: string;
    key: string;
    size: number;
    status: string;
}


export enum CustomArtifactTypeEnum {
    VIDEO = 'DEVICE_VIDEO',
    LOG = 'DEVICE_LOGS',
    USER_ACTION = 'DEVICE_USER_ACTIONS',
    KPIS = 'DEVICE_KPIS',
    AUDIO = 'DEVICE_AUDIO',
    DUMP = 'DUMP',
    OTHER = 'OTHER',
}

export const CustomArtifactTypeDisplayMap = new Map<CustomArtifactTypeEnum, string>([
    [CustomArtifactTypeEnum.VIDEO, 'Video'],
    [CustomArtifactTypeEnum.LOG, 'Device Logs'],
    [CustomArtifactTypeEnum.USER_ACTION, 'User Actions'],
    [CustomArtifactTypeEnum.DUMP, 'Dump'],
    [CustomArtifactTypeEnum.OTHER, 'Other'],
    [CustomArtifactTypeEnum.KPIS, 'Device KPIs'],
    [CustomArtifactTypeEnum.AUDIO, 'Audio'],
]);