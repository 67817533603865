import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent, ConfirmationDialogProps } from "./confirmation-dialog/confirmation-dialog.component";
import { NotificationDialogComponent, NotificationDialogProps } from "./notification-dialog/notification-dialog.component";

const confirmationModalContents: Record<string, ConfirmationDialogProps> = {
    switchModal: {
        header: 'Switch to other device?',
        content: `Are you sure you want to switch to other device?`,
        accept: 'Switch',
        cancel: 'Cancel',
    },
    rebootModal: {
        header: 'Reboot this device?',
        content: `Are you sure you want to reboot this device? The device won't be available
        for approximately 2 minutes.`,
        accept: 'Reboot',
        cancel: 'Cancel',
    },
    extendModal: {
        header: 'Extend booking?',
        content: `Extend device booking for one more day?`,
        accept: 'Extend',
        cancel: 'Cancel',
    },
    releaseModal: {
        header: 'Release this device?',
        content: `Are you sure you want to Release this device?`,
        accept: 'Release',
        cancel: 'Cancel',
    },
    resetModal: {
        header: 'Reset Password',
        content: `Are you sure you want to Reset the password for this user?`,
        accept: 'Reset',
        cancel: 'Cancel',
    },
    deleteModal: {
        header: 'Delete this File?',
        content: `Are you sure you want to Delete this File?`,
        accept: 'Delete',
        cancel: 'Cancel',
    },
    adaptTestModal: {
        header: 'Adapt this test to selected device?',
        content: `The test was created on device with different resolution.\n Test can't be replayed without this adaptation.`,
        accept: 'Adapt',
        cancel: 'Cancel'
    },
    deleteTestArtifactsModal: {
        header: 'Delete the current test artifacts?',
        content: `The test run has been stopped. The current test artifacts (device logs/video) will be deleted permanently. The deletion could not be cancelled.`,
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteTestModal: {
        header: 'Delete the test?',
        content: `The test will be deleted permanently. The deletion can't be cancelled.`,
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteTestReplayModal: {
        header: 'Delete the test replay?',
        content: `The test replay will be deleted permanently. The deletion can't be cancelled.`,
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteArtifactsModal: {
        header: 'Delete artifacts?',
        content: `The artifacts will be deleted permanently. The deletion can't be cancelled.`,
        accept: 'Delete',
        cancel: 'Cancel'
    },
    stopTestReplay: {
        header: 'Stop the current test replay?',
        content: `The test replay will be stopped. After that there will be a possibility to discard artifacts for this replay.`,
        accept: 'Stop',
        cancel: 'Cancel'
    },
    iosAirplaneWarning: {
        header: 'Warning',
        content: `Note your device will become irresponsive if you reboot it after disabling Network connectivity. Confirm explicitly and remember to restore WiFi connection before reboot.`,
        accept: 'Confirm',
        cancel: 'Cancel'
    },
    deleteManualTest: {
        header: 'Delete Manual Test',
        content: `Test will be deleted permanently. This can't be canceled.`,
        accept: 'Delete',
        cancel: 'Cancel'
    },
    cancelArtifactFinalization: {
        header: 'Break artifact finalization?',
        content: `The artifact will not be uploaded to Snap Cloud. This can't be cancelled.`,
        accept: 'Confirm',
        cancel: 'Cancel'
    },
    saveManualTest: {
        header: 'Save manual test before leaving the page',
        content: 'Leaving the page without saving will result in loosing all recorded data for manual test permanently.',
        accept: 'Save & Leave',
        cancel: 'Cancel',
        decline: 'Discard & Leave'
    },
    deleteDynamicProperty: {
        header: 'Delete device property',
        content: 'Property will be deleted permanently. Deletion can\'t be cancelled',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    discardMoMt: {
        header: 'Discard MO/MT call',
        content: 'Record will be deleted permanently. This operation can\'t be canceled.',
        accept: 'Discard',
        cancel: 'Cancel'
    },
    deleteMoMt: {
        header: 'Delete MO/MT call recording',
        content: 'Record will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    discardCustom: {
        header: 'Discard custom test',
        content: 'Record will be deleted permanently. This operation can\'t be canceled.',
        accept: 'Discard',
        cancel: 'Cancel'
    },
    deleteCustom: {
        header: 'Delete custom test recording',
        content: 'Record will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteLab: {
        header: 'Delete Lab',
        content: 'Lab will be deleted permanently and all devices connected will become offboarded. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteSubscription: {
        header: 'Delete Subscription',
        content: 'Subscription will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteUser: {
        header: 'Delete User',
        content: 'This user will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteDevice: {
        header: 'Delete Device',
        content: 'Are you sure you want to delete this device?',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    archiveUser: {
        header: 'Archive',
        content: 'Are you sure you want to Archive this User?',
        accept: 'Archive',
        cancel: 'Cancel'
    },
    archiveLab: {
        header: 'Archive',
        content: 'Are you sure you want to Archive this Lab?',
        accept: 'Archive',
        cancel: 'Cancel'
    },
    archiveDevice: {
        header: 'Archive',
        content: 'Are you sure you want to Archive this Device?',
        accept: 'Archive',
        cancel: 'Cancel'
    },
    archiveEnter: {
        header: 'Archive',
        content: 'Are you sure you want to Archive this Enterprise?',
        accept: 'Archive',
        cancel: 'Cancel'
    },
    discardRnpRecord: {
        header: 'Discard Record and Play recording',
        content: 'Record will be deleted permanently. This operation can\'t be canceled.',
        accept: 'Discard',
        cancel: 'Cancel'
    },
    discardRnpReplay: {
        header: 'Discard Record and Play recording',
        content: 'Record will be deleted permanently. This operation can\'t be canceled.',
        accept: 'Discard',
        cancel: 'Cancel'
    },
    discardManualTest: {
        header: 'Discard Manual Test',
        content: 'Test will be deleted permanently. This operation can\'t be canceled.',
        accept: 'Discard',
        cancel: 'Cancel'
    },
    deleteEnterprise: {
        header: 'Delete Enterprise',
        content: 'This enterprise will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteBooking: {
        header: 'Delete',
        content: 'Are you sure you want to delete this time slot from your booking manager?',
        accept: 'Delete',
        cancel: 'Cancel'
    },
    deleteCustomTestCase: {
        header: 'Delete custom test case',
        content: 'This custom test case will be deleted permanently. The deletion can\'t be canceled.',
        accept: 'Delete',
        cancel: 'Cancel'
    }
};

export type ConfirmationModals = keyof typeof confirmationModalContents;

const notificationModalContents: Record<string, NotificationDialogProps> = {
    manualTestDiscarded: {
        header: 'Manual test has been discarded',
        content: 'Your manual test in progress has been discarded due to inactivity',
        accept: 'Confirm',
    },
};

export type NotificationModals = keyof typeof notificationModalContents;

@Injectable()
export class DialogModalService {
    constructor(public dialog: MatDialog) { }

    openConfirmationDialog(content: ConfirmationModals, accept?: () => void, cancel?: () => void, decline?: () => void) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: confirmationModalContents[content],
        });

        dialogRef.afterClosed().subscribe(result => {
            switch (result) {
                case 'accept':
                    accept && accept();
                    break;
                case 'cancel':
                    cancel && cancel();
                    break;
                case 'decline':
                    decline && decline();
                    break;
            }
        });
    }

    openNotificationDialog(content: NotificationModals, accept?: () => void) {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
            data: notificationModalContents[content],
        });

        dialogRef.afterClosed().subscribe(result => {
            if(accept && result === 'accept') {
                accept();
            }
        });
    }
}
