import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TooltipService} from '../../../shared/tooltip.service';
import {CommonService} from '../../../shared/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FeatureTypeEnum} from '../../../shared/enum';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import {RecordAndPlayService} from '../record-and-play.service';
import {SafeResourceUrl} from '@angular/platform-browser';
import {DeviceService} from '../../view-device/device.service';
import {stateTooltip} from '../../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
import {DevicehomeService} from '../../device-homepage/devicehome.service';
import {MatAccordion} from '@angular/material/expansion';
import {interval} from 'rxjs/internal/observable/interval';
import {ActionStatus, IterationStatus, ReplayStatus} from '../models/record-and-play.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rnp-new-test',
  templateUrl: './rnp-new-test.component.html',
  styleUrls: ['./rnp-new-test.component.scss']
})
export class RnpNewTestComponent implements OnInit, OnDestroy {
  isTestRunning = false;
  isTestRecording = false;
  recordInProgress = false;
  replayInProgress = false;
  isSelectDeviceMode = false;
  isLoading = false;
  selectedDevice: any;
  testName = '';
  testDescription = '';
  testRepeatCount = '1';
  iterationDelay = '3';
  isRnpAvailable = false;
  isCloudAvailable = false;
  isManipulationAvailable = false;
  isBookingAvailable = false;
  tooltipComponent = null;
  uniqueNameError = 'Test with this name already exists';
  isNamePristine = true;
  testInfo: any;
  shouldSaveLogs = false;
  shouldSaveVideo = false;
  shouldSaveUserActions = false;
  shouldSaveKPIs = false;
  shouldAdaptCoords = false;
  currentReplayId = '';
  deviceId = '';
  iterations: any;
  userData: any;
  CurrentDeviceIos = true;
  CurrentDeviceAnd = true;
  AllDeviceIos = true;
  AllDeviceAnd = true;
  currentIos = 0;
  currentAndroid = 0;
  availableIos = 0;
  availableAndroid = 0;
  deviceList = [];
  currentDeviceList = [];
  currentDeviceListTemp = [];
  searchText = '';
  searchText1 = '';
  filterList = [];
  deviceState = '';
  selectedBookedFilter = 'available';
  bookingFilterList = [];
  deviceListTemp = [];
  searchLab = '';
  searchLocation = '';
  labListDropdown = [];
  locationListDropdown = [];
  isGridView = true;
  allDeviceList = [];
  selectedRnpDevice: any;
  deviceDetail: any;
  replayUpdateSub: Subscription;
  iterationsList = [];
  iterationsState = [];
  actionResultList = [];
  startTime = 0;
  shouldAddHome = true;
  runningDeviceId: string;
  tests = [];
  actionList = [];
  execTime = 0;
  runFailed = false;
  actionToReplay: any;
  testId = '';

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  isLandscapeMode = false;
  imageContent: SafeResourceUrl;
  showImage = false;
  originalHeight = 0;
  originalWidth = 0;
  stopSwipePropagation = false;
  pressTimer: any;
  reqQueueWatcher: any;
  inReq = false;
  ignoreTyping = false;

  constructor(private _snackBar: MatSnackBar,
              private tooltipService: TooltipService,
              private commonService: CommonService,
              private deviceService: DeviceService,
              private route: ActivatedRoute,
              private dialogModalService: DialogModalService,
              private router: Router,
              private rnpService: RecordAndPlayService,
              private deviceHome: DevicehomeService) {
    this.testId = this.route.snapshot.queryParamMap.get('id');
    this.currentReplayId = this.route.snapshot.queryParamMap.get('replay');
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.isRnpAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.RNP]);
    this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    this.isManipulationAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.DEVICE_MANIPULATION]);
    this.isBookingAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
    this.userData = this.commonService.getUser();

    if (this.isRnpAvailable) {
      if (this.testId && this.testId.length) {
        this.isLoading = true;
        this.rnpService.getRecordAndPlayList('').subscribe((result) => {
          this.isLoading = false;
          if (result.data) {
            result.data.recordPlaytList.forEach((test, i) => {
              if (test.recordPlayId === this.testId) {
                this.testInfo = test;
                this.testName = this.testInfo.name;
                this.testDescription = this.testInfo.description;
              }
            });
          }
        }, () => this.isLoading = false);
      }

      if (!this.testId) {
        // get list of tests to check for unique name for the new test
        this.getTestsList();
        if (this.currentReplayId) {
          // load running in background replay
          this.loadRunningTest();
        }
      }
    }

    if (this.isBookingAvailable) {
      this.SearchDevice();
      this.DeviceLocation();
    }
  }

  ngOnDestroy(): void {
    if (this.replayUpdateSub) {
      this.replayUpdateSub.unsubscribe();
      this.replayUpdateSub = null;
    }
    if (this.selectedRnpDevice && !this.isTestRunning) {
      this.releaseDevice();
    }
  }

  updateIgnoreTyping(v: boolean): void {
    this.ignoreTyping = v;
  }

  loadRunningTest(): void {
    this.isLoading = true;
    this.rnpService.getAllTestReplayList('', this.currentReplayId, '', ReplayStatus.RUNNING)
        .subscribe((result) => {
          this.isLoading = false;
          if (result && result.data && result.data.recordReplaytList.length) {
            const currentReplay = result.data.recordReplaytList[0];
            this.getTestsList(currentReplay.recordDetails.recordId);
            this.deviceHome.getDeviceApi('filter=current').subscribe((res) => {
              this.isLoading = false;
              if (res['message'] === 'Success') {
                const usedDevice = res.data.deviceList.filter((d) => d.deviceData[0].serialNumber === currentReplay.serialNumber);
                if (usedDevice.length) {
                  this.selectedRnpDevice = usedDevice[0].deviceData[0];
                  this.getAllDevices(currentReplay.serialNumber);
                  this.runningDeviceId = currentReplay.deviceId;
                  this.isTestRunning = true;
                  this.startTime = currentReplay.insertDate * 1000;
                  this.testRepeatCount = currentReplay.repeat.toString();
                  this.iterationDelay = currentReplay.delay.toString();
                  this.shouldSaveVideo = currentReplay.isVideoCollecting;
                  this.shouldSaveLogs = currentReplay.isLogsCollecting;
                  this.shouldSaveUserActions = currentReplay.isUserActionCollecting;
                  this.shouldSaveKPIs = currentReplay.isKPIsCollecting;

                  if (currentReplay.iterations && currentReplay.iterations.length) {
                    this.actionResultList = [];
                    let actionIndex = 0;
                    currentReplay.iterations.forEach((iteration) => {
                      iteration.actions.forEach((a) => {
                        this.actionResultList.push('');
                        if (a.status !== ActionStatus.NOT_EXECUTED) {
                          this.actionResultList[actionIndex] = a.status;
                        }
                        actionIndex++;
                      });
                    });
                  }
                  for (let y = 0; y < currentReplay.repeat; y++) {
                    this.iterationsList.push([]);
                    this.iterationsState.push(IterationStatus.NOT_EXECUTED);
                  }

                  if (currentReplay.iterations && currentReplay.iterations.length) {
                    currentReplay.iterations.forEach((iteration, y) => {
                      this.iterationsList[y] = iteration.actions.map((a) => a);
                      this.iterationsState[y] = iteration.status;
                    });
                  }
                  this.pollReplayUpdate(this.currentReplayId);
                }
              }
            });
          }
        }, (err) => { this.isLoading = true; });
  }

  navigateBack(s: string) {
    this.router.navigate(['/snap/record-and-play']);
  }

  toggleSidepanelAction(): void {
    this.drawer.toggle();
  }

  onNameInput() {
    this.isNamePristine = false;
    this.uniqueNameError = '';
  }

  openConfirmCancel() {
    this.dialogModalService.openConfirmationDialog('discardRnpReplay', () => this.cancelTestCreation());
  }

  cancelTestCreation() {
    if (!this.selectedDevice) {
      this.navigateMoMt('/snap/record-and-play');
    }
    this.releaseDevicesAndLeave();
  }

  navigateMoMt(path: string, id?: string) {
    // if (this.selectedRnpDevice) {
    //   this.releaseDevice();
    // }
    if (id) {
      this.router.navigate(['/snap/rnp-test-report'], { queryParams: { id: id } });
    } else {
      this.router.navigate([path]);
    }
  }

  releaseDevicesAndLeave() {
    this.isNamePristine = true;
    this.testName = '';
    if (this.selectedRnpDevice) {
      this.isLoading = true;
      this.deviceHome.releaseDevice({ deviceBookingId: this.selectedRnpDevice.bookingId }).subscribe((res) => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          this.selectedRnpDevice = null;
        }
      }, () => this.isLoading = false);
    }

    if (!this.selectedRnpDevice) {
      this.navigateMoMt('/snap/record-and-play');
    }
  }

  checkLogs(checked: boolean) {
    this.shouldSaveLogs = checked;
  }

  checkVideo(checked: boolean) {
    this.shouldSaveVideo = checked;
  }

  checkUserActions(checked: boolean) {
    this.shouldSaveUserActions = checked;
  }

  checkKPIs(checked: boolean) {
    this.shouldSaveKPIs = checked;
  }

  checkAddHome(checked: boolean) {
    this.shouldAddHome = checked;
  }

  startReplay(): void {
    this.startTime = new Date().getTime();
    this.iterationsList = [];
    this.iterationsState = [];
    for (let y = 0; y < Number(this.testRepeatCount); y++) {
      this.iterationsList.push(JSON.parse(JSON.stringify(this.testInfo.data)));
      this.iterationsState.push(IterationStatus.NOT_EXECUTED);
      this.iterationsList[y].forEach((t) => {
        t.status = '';
      });
    }
    const reqBody = {
      serialNumber: this.selectedRnpDevice.serialNumber,
      recordId: this.testId,
      repeat: this.testRepeatCount,
      isLogsCollecting: this.shouldSaveLogs,
      isVideoCollecting: this.shouldSaveVideo,
      isUserActionCollecting: this.shouldSaveUserActions,
      isKPIsCollecting: this.shouldSaveKPIs,
      isAdopt: this.shouldAdaptCoords,
      delay: this.iterationDelay
    };
    this.isLoading = true;
    this.rnpService.startTestReplay(reqBody).subscribe((res) => {
      this.isLoading = false;
      if (res && res.data) {
        this.currentReplayId = res.data.recordReplayId;
        this.isTestRunning = true;
        this.pollReplayUpdate(res.data.recordReplayId);
        //this.accordion.openAll();
      }
    }, () => { this.isLoading = false; });
  }

  startRecording(): void {
    this.startTime = new Date().getTime();
    this.isTestRecording = true;
    this.recordInProgress = true;
  }

  cancelTest() {
    this.dialogModalService.openConfirmationDialog('stopTestReplay', () => {
      const reqBody = {
        recordReplayId: this.currentReplayId,
      };
      this.stopReplayRequest(reqBody);
    });
  }

  stopReplayRequest(body: any): void {
    this.rnpService.stopTestReplay(body).subscribe((res) => {
      if (res && res.message === 'Success') {
        // ask user if they want to keep artifacts
        if (this.shouldSaveLogs || this.shouldSaveVideo || this.shouldSaveUserActions || this.shouldSaveKPIs) {
          this.dialogModalService.openConfirmationDialog('deleteTestArtifactsModal', () => {
            const reqBody = {
              recordReplayId: this.currentReplayId,
              isLogsKept: false,
              isVideoKept: false,
              isUserActionKept: false,
              isKPIsKept: false
            };
            this.rnpService.handleArtifactsAfterStop(reqBody).subscribe((resp) => {
              if (resp && resp.statusCode === 200) {
                this.navigateMoMt('', this.currentReplayId);
                this._snackBar.open('Links for artifacts are deleted successfully.', '', {
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  panelClass: ['success'],
                });
              }
            }, (error) => { });
          });
        } else {
          this.navigateMoMt('', this.currentReplayId);
        }
      }
    }, (error) => { });
  }

  releaseDevice() {
    this.isLoading = true;
    this.deviceHome.releaseDevice({ deviceBookingId: this.selectedRnpDevice.bookingId }).subscribe(() => {
      this.selectedRnpDevice = null;
      this.SearchDevice();
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  changeSelectDeviceMode() {
    this.isSelectDeviceMode = !this.isSelectDeviceMode;
  }

  checkIfDeviceUnavailable() {
    if (!this.selectedDevice) {
      return false;
    }
    const status = this.deviceService.getDeviceStatus(this.selectedDevice);
    const notOnline = status !== 'online' && status !== 'testing';
    const isBooked = this.selectedDevice.isBooked && this.selectedDevice.bookedByUserId !== this.userData.userId;

    return notOnline || isBooked;
  }

  showOfflineTooltip(selectedDevice: any) {
    return this.deviceService.showOfflineTooltip(selectedDevice);
  }

  cancelSelectMode() {
    this.isSelectDeviceMode = false;
    this.selectedDevice = null;
  }

  selectDevice(item) {
    this.selectedDevice = item;
    if (this.testId) {
      this.checkForAdaptCoords();
    }
  }

  selectDevice1() {
    if (this.selectedRnpDevice) {
      this.isLoading = true;
      this.deviceHome.releaseDevice({ deviceBookingId: this.selectedRnpDevice.bookingId }).subscribe((res) => {
        this.isLoading = false;
        this.bookDevice();
      }, () => {
        this.isLoading = false;
        this.isSelectDeviceMode = false;
        this.selectedDevice = null;
      });
      return;
    }
    this.bookDevice();
  }

  bookDevice() {
    if (this.selectedDevice.bookedByUserId === this.userData.userId
        && this.deviceService.getDeviceStatus(this.selectedDevice) === 'testing') {
      // if (this.moMtMode === 'MO') {
      //   if (this.selectedMoDevice?._id === this.selectedDevice._id) {
      //     this.selectedDevice = null;
      //     this.isSelectDeviceMode = false;
      //     return;
      //   }
      //   if (this.selectedMtDevice?._id === this.selectedDevice._id) {
      //     this.websocketService.sendMessage(this.createCommand('STOP', 'MT'), 'screencast');
      //     this.selectedMtDevice = null;
      //   }
      //   this.selectedMoDevice = this.selectedDevice;
      // } else {
      //   if (this.selectedMtDevice?._id === this.selectedDevice._id) {
      //     this.selectedDevice = null;
      //     this.isSelectDeviceMode = false;
      //     return;
      //   }
      //   if (this.selectedMoDevice?._id === this.selectedDevice._id) {
      //     this.websocketService.sendMessage(this.createCommand('STOP', 'MO'), 'screencast');
      //     this.selectedMoDevice = null;
      //   }
      //   this.selectedMtDevice = this.selectedDevice;
      // }
      // this.selectedDevice = null;
      // this.isSelectDeviceMode = false;
      // this.getAllDevices(this.moMtMode);
    } else {
      this.isLoading = true;
      this.deviceHome.bookDeviceForTesting([{ deviceId: this.selectedDevice._id }], '', false, 'rnp').subscribe((res) => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          const bookingId = res.data.bookingIds[0];
          this.selectedRnpDevice = this.selectedDevice;
          this.selectedRnpDevice.bookingId = bookingId;
          this.selectedDevice = null;
          this.getAllDevices(this.selectedRnpDevice.serialNumber);
          this.SearchDevice();
          this.isSelectDeviceMode = false;
        } else {
          this.isLoading = false;
          this.isSelectDeviceMode = false;
        }
      }, () => {
        this.isLoading = false;
        this.isSelectDeviceMode = false;
        this.selectedDevice = null;
      });
    }
  }

  tabFilterAll(value) {
    if (value === 'AllDeviceIos') {
      this.AllDeviceIos = !this.AllDeviceIos;
      if (!this.AllDeviceIos && !this.AllDeviceAnd) {
        this.AllDeviceAnd = true;
        this.AllDeviceIos = false;
      }
    }
    if (value === 'AllDeviceAnd') {
      this.AllDeviceAnd = !this.AllDeviceAnd;
      if (!this.AllDeviceIos && !this.AllDeviceAnd) {
        this.AllDeviceAnd = false;
        this.AllDeviceIos = true;
      }
    }
    this.SearchDeviceLocal();
  }

  SearchDeviceLocal(): void {
    this.searchByText(this.deviceListTemp);
    if (this.searchLab) {
      this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
    }
    if (this.searchLocation) {
      this.deviceList = this.deviceList.filter(device => device.labLocation === this.searchLocation);
    }

    if (!this.AllDeviceAnd && this.AllDeviceIos) {
      this.deviceList = this.deviceList.filter(element => {
        return element.oem === 'Apple';
      });
    }
    if (this.AllDeviceAnd && !this.AllDeviceIos) {
      this.deviceList = this.deviceList.filter(element => {
        return element.oem !== 'Apple';
      });
    }
    if (this.selectedBookedFilter === 'booked') {
      this.deviceList = this.deviceList.filter(device => {
        return device.isBooked;
      });
    }
    if (this.selectedBookedFilter === 'available') {
      this.deviceList = this.deviceList.filter(device => {
        return !device.isBooked && device.deviceStateCode === 'ONLINE';
      });
    }
    this.countAmountDevices();
    this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
  }

  searchByText(list) {
    const keys = ['serialNumber', 'modelName', 'osVersion'];
    const values = [];
    if (this.searchText) {
      values.push(this.searchText.toLowerCase());
    }
    const regex = new RegExp(values.join('|'));
    this.deviceList = list.filter(e => keys.some(k => regex.test(e[k]?.toLowerCase())));
  }

  SearchDeviceLocal1(): void {
    const values = [];
    if (this.searchText1) {
      values.push(this.searchText1.toLowerCase());
    }
    const keys = ['serialNumber', 'modelName1', 'osVersion1', 'oem1'];
    const regex = new RegExp(values.join('|'));
    this.currentDeviceList = this.currentDeviceListTemp.filter(e => keys.some(k => regex.test(e[k].toLowerCase())));

    if (!this.CurrentDeviceAnd && this.CurrentDeviceIos) {
      this.currentDeviceList = this.currentDeviceList.filter(element => {
        return element.oem1 === 'Apple';
      });
    }
    if (this.CurrentDeviceAnd && !this.CurrentDeviceIos) {
      this.currentDeviceList = this.currentDeviceList.filter(element => {
        return element.oem1 !== 'Apple';
      });
    }
    this.currentAndroid = 0;
    this.currentIos = 0;
    this.currentDeviceList.forEach(element => {
      if (element['oem1'] === 'Apple') {
        this.currentIos = this.currentIos + 1;
      } else {
        this.currentAndroid = this.currentAndroid + 1;
      }
    });
    this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
    this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
  }

  countAmountDevices() {
    this.availableAndroid = 0;
    this.availableIos = 0;
    this.deviceList.forEach(element => {
      if (element['oem'] === 'Apple') {
        this.availableIos = this.availableIos + 1;
      } else {
        this.availableAndroid = this.availableAndroid + 1;
      }
    });
    if (this.searchText) {
      this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
    } else {
      if (this.searchLab) {
        const filteredByLabDeviceList = this.deviceListTemp.filter(device => device.labName === this.searchLab);
        this.bookingFilterList = this.groupBy(filteredByLabDeviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
      } else {
        this.bookingFilterList = this.groupBy(this.deviceListTemp, 'isBooked', 'deviceStateCode', 'ONLINE');
      }
    }
  }

  toggleView() {
    this.isGridView = !this.isGridView;
  }

  filterByBooked(type: boolean): void {
    if (this.selectedBookedFilter.length
        && ((this.selectedBookedFilter === 'available' && !type) || (this.selectedBookedFilter === 'booked' && type))) {
      this.selectedBookedFilter = '';
      if (this.searchLab.length || this.searchLocation.length) {
        this.SearchDeviceLocal();
      } else if (this.searchText.length) {
        this.SearchDeviceLocal1();
      } else {
        this.deviceList = this.deviceListTemp;
        this.localFilter();
      }
    } else {
      this.selectedBookedFilter = type ? 'booked' : 'available';
      this.localFilter();
    }
  }

  localFilter(): void {
    this.deviceList = this.deviceListTemp.filter(device => {
      if ((this.deviceState && device.deviceStateCode === this.deviceState || !this.deviceState.length) &&
          ((this.selectedBookedFilter === 'booked' && device.isBooked)
              ||
              (this.selectedBookedFilter === 'available' && !device.isBooked && device.deviceStateCode === 'ONLINE')
              || !this.selectedBookedFilter.length)) {
        return device;
      }
    });
    if (this.searchText) {
      this.searchByText(this.deviceList);
    }
    if (this.searchLab) {
      this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
    }
    this.countAmountDevices();
  }

  applyFilter(value): void {
    if (value === this.deviceState) {
      this.deviceState = '';
      if (this.searchLab.length || this.searchLocation.length) {
        this.SearchDeviceLocal();
      } else if (this.searchText.length) {
        this.SearchDeviceLocal1();
      } else {
        this.deviceList = this.deviceListTemp;
        this.localFilter();
      }
    } else {
      this.deviceState = value;
      this.localFilter();
    }
  }

  SearchDevice(): void {
    this.deviceHome.availableDevice('showAll=true').subscribe((res) => {
      if (res['message'] === 'Success') {
        this.allDeviceList = res.data.deviceList;
        const unusedDeviceList = res.data.deviceList.filter((device) => !device.testingType
            && device.deviceCommunicationStatus.toLowerCase() !== 'remote-testing');
        this.deviceList = unusedDeviceList;
        this.availableAndroid = 0;
        this.availableIos = 0;
        this.AllDeviceAnd = true;
        this.AllDeviceIos = true;
        this.labListDropdown = [];
        let labUrl = [];
        this.deviceList.forEach((element) => {
          element['showId'] = false;
          if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
            element['deviceStateCode'] = 'TESTING';
          }
          if (element['labName']) {
            this.labListDropdown.push(element['labName']);
            labUrl.push(element['labDomain']);
          }
          if (element['oem'] === 'Apple') {
            this.availableIos = this.availableIos + 1;
          } else {
            this.availableAndroid = this.availableAndroid + 1;
          }
          if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
            element['deviceStateCode'] = 'DISCONNECTED';
          }
          if (!element['deviceStateCode'] || (element['deviceStateCode']
              && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
            element['deviceStateCode'] = 'UNKNOWN';
          }
          if (element['deviceState'].toLowerCase() === 'offline') {
            element['isResetBtn'] = true;
            if (element['isBooked'] && element['bookedByUserId'] === this.userData.userId) {
              element['isResetBtn'] = true;
            }
            if (element['isBooked'] && element['bookedByUserId'] !== this.userData.userId) {
              element['isResetBtn'] = false;
            }
          }
          element['isResetting'] = false;
          element['resetCount'] = 0;
          element['isBook'] = false;
          element['searchEndDate'] = '';
          element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
        });
        localStorage.setItem('AllDevice', JSON.stringify(this.deviceList));
        this.deviceListTemp = unusedDeviceList;
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
        this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
        this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
        labUrl = labUrl.filter(this.onlyUnique);
        if (labUrl.length > 0) {
          this.commonService.setStorage('_domin', labUrl[0]);
        }
        if (res.data.deviceList.length === 0) {
          this.deviceList = [];
          return this._snackBar.open('No device available!', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['failure']
          });
        }
        this.SearchDeviceLocal();
      }
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  DeviceLocation() {
    const query = '';
    this.deviceHome.getLocationAPI(query).subscribe((res) => {
      if (res['message'] === 'Success') {
        this.locationListDropdown = res.data.locationList;
      }
    });
  }

  groupBy(objectArray, property, filterProperty?: string, filterValue?: string): any {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];

      if (filterProperty && filterValue) {
        if (obj[filterProperty] !== filterValue) {
          return acc;
        }
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for the given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  getDeviceStatus(item: any) {
    return this.deviceService.getDeviceStatus(item);
  }

  getStatusTooltip(data): string {
    let status = this.getDeviceStatus(data);
    if (status === 'online') {
      status = 'available';
    }
    if (status === 'testing') {
      status = 'under test';
    }
    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  checkSelfBooked(item): boolean {
    return item.isBooked && item.bookedByUserId === this.userData.userId;
  }

  closeReset(index, type) {
    if (type === 'ALL') {
      this.deviceList[index]['resetCount'] = 0;
      this.deviceList[index]['isResetting'] = false;
    } else {
      this.currentDeviceList[index]['resetCount'] = 0;
      this.currentDeviceList[index]['isResetting'] = false;
    }
  }

  getAllDevices(sn: string): void {
    this.isLoading = true;
    const request = {
      'serialNumber': sn,
      'screenShot': false
    };
    this.deviceService.getDeviceDetails(request).subscribe((res) => {
      this.isLoading = false;
      if (res.body['data']['tool_Result'].toUpperCase() === 'PASS') {
        const detail = res.body.data.tool_Extra;
        if (this.selectedRnpDevice) {
          if (this.selectedRnpDevice.labDomain) {
            detail.labDomain = this.selectedRnpDevice.labDomain;
          }
          if (this.selectedRnpDevice.serialNumber) {
            detail.serialNumber = this.selectedRnpDevice.serialNumber;
          }
          if (this.selectedRnpDevice.oem) {
            detail.oem = this.selectedRnpDevice.oem;
          }
        }
        // value assignment is transferred to screencast component
        this.deviceDetail = detail;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  calcHoursToDisplay(timestamp: number) {
    return this.rnpService.calcHoursToDisplay(timestamp);
  }

  displayDetails(action: any) {
    return this.rnpService.displayDetails(action);
  }

  pollReplayUpdate(replayId: string): void {
    if (this.replayUpdateSub) {
      this.replayUpdateSub.unsubscribe();
    }
    this.replayUpdateSub = interval(1000).subscribe(() => {
      this.rnpService.getLastAction(replayId).subscribe((res) => {
        if (res && res.data && res.data.action) {
          if (res.data.action.status !== ActionStatus.NOT_EXECUTED) {
            this.actionResultList[res.data.action.index] = res.data.action.status;
            this.iterationsList[res.data.action.iteration][res.data.action.index].status = res.data.action.status;
            if (res.data.action.status === ActionStatus.PASS) {
              for (let i = 0; i < res.data.action.index; i++) {
                this.actionResultList[i] = ActionStatus.PASS;
                this.iterationsList[res.data.action.iteration][i].status = ActionStatus.PASS;
              }
            }
          }

          if (res.data.action.status === ActionStatus.FAILED) {
            // mark iteration as failed
            this.iterationsState[res.data.action.iteration] = IterationStatus.FAILED;
            // if action failed check if replay is failed
            this.rnpService.getAllTestReplayList(this.testId, this.currentReplayId, '', '')
                .subscribe((result) => {
                  if (result.data.recordReplaytList[0].status === ReplayStatus.COMPLETED
                      || result.data.recordReplaytList[0].status === ReplayStatus.FAILED) {
                    if (this.replayUpdateSub) {
                      this.replayUpdateSub.unsubscribe();
                    }
                    this.navigateMoMt('', this.currentReplayId);
                  }
                }, (error) => {
                });
          }

          // mark iteration as successful
          if (res.data.action.status === ActionStatus.PASS
              && (res.data.action.index === this.iterationsList[res.data.action.iteration].length - 1)) {
            this.iterationsState[res.data.action.iteration] = IterationStatus.PASS;
          }

          if ((res.data.action.index === this.testInfo.data.length - 1)
              && res.data.action.status !== ActionStatus.NOT_EXECUTED
              && res.data.action.iteration === (Number(this.testRepeatCount) - 1)) {
            if (this.replayUpdateSub) {
              this.replayUpdateSub.unsubscribe();
            }
            this.rnpService.getAllTestReplayList(this.testId, this.currentReplayId, '', '').subscribe((result) => {
              if (result.data.recordReplaytList.length) {
                if (result.data.recordReplaytList[0].status === ReplayStatus.COMPLETED
                    || result.data.recordReplaytList[0].status === ReplayStatus.FINALIZING) {
                  this.navigateMoMt('', this.currentReplayId);
                }
              }
            }, (error) => { });
          }
        } else {
          this.replayUpdateSub.unsubscribe();
        }
      }, (error) => { });
    });
  }

  getActionIconName(type: string, status: string) {
    return this.rnpService.getActionIconName(type, status);
  }

  getActionName(type: string) {
    return this.rnpService.getActionName(type);
  }

  isIterationStarted(i: number): boolean {
    return !i || (i > 0 && this.iterationsList[i - 1][this.iterationsList[i - 1].length - 1].status !== '');
  }

  showSpinnerStart(i: number): boolean {
    const duration = this.iterationsList[i][this.iterationsList[i].length - 1].timestamp;
    const start = this.startTime + (duration + Number(this.iterationDelay) * 1000) * i;
    return i > 0 && this.iterationsState[i - 1] === IterationStatus.PASS && (new Date().getTime() < start);
  }

  calcSpinnerShowtimeIterations(prevIndex: number, i: number): boolean {
    const duration = this.iterationsList[i][this.iterationsList[i].length - 1].timestamp;
    // shift start time for all iterations after the first one
    const start = this.startTime + (duration + Number(this.iterationDelay) * 1000) * i;
    if (prevIndex < 0) {
      // first action in the list
      return (new Date().getTime() > start)
          && (this.iterationsList[i][prevIndex + 1].status === ''
              || this.iterationsList[i][prevIndex + 1].status === ActionStatus.NOT_EXECUTED);
    } else {
      return (new Date().getTime() - start) > this.iterationsList[i][prevIndex].timestamp
          && (this.iterationsList[i][prevIndex + 1].status === ''
              || this.iterationsList[i][prevIndex + 1].status === ActionStatus.NOT_EXECUTED);
    }
  }

  checkForUniqueName(newTestName: string): boolean {
    let res = true;
    if (newTestName.length) {
      this.tests.forEach((t) => {
        if (t.name === newTestName) {
          res = false;
        }
      });
    }
    return res;
  }

  getTestsList(testId?: string): void {
    this.rnpService.getRecordAndPlayList('').subscribe((res) => {
      if (res.data) {
        this.tests = res.data.recordPlaytList;
        if (testId) {
          this.testInfo = this.tests.filter((t) => t.recordPlayId === testId)[0];
        }
      }
    });
  }

  updateDeviceSelect($event: string) {
    if ($event === 'select') {
      this.changeSelectDeviceMode();
    }
    if ($event === 'release') {
      this.releaseDevice();
    }
  }

  checkForAdaptCoords(): void {
    this.shouldAdaptCoords = false;
      if (this.selectedDevice
          && (this.concatScreenResolution(this.selectedDevice)
              !== this.rnpService.formatScreenResolution(this.testInfo.screenResolution))) {
        this.dialogModalService.openConfirmationDialog('adaptTestModal', () => {
          this.shouldAdaptCoords = true;
        });
      }
  }

  concatScreenResolution(device): string {
    return device.screenWidth + 'x' + device.screenHeight;
  }

  calcSpinnerShowtime(prevIndex: number): boolean {
    if (prevIndex < 0) {
      return this.replayInProgress && this.actionResultList[prevIndex + 1] === '';
    } else {
      return (new Date().getTime() - this.startTime) > this.actionList[prevIndex].timestamp
          && this.actionResultList[prevIndex + 1] === ''
          && this.replayInProgress;
    }
  }

  addLatestAction($event: any) {
    this.actionList.push($event);
    this.actionResultList.push('');
    this.actionList[this.actionList.length - 1].timestamp = new Date().getTime() - this.startTime;
  }

  controlRecording(): void {
    if (this.recordInProgress) {
      // add home action if checkbox was checked
      if (this.shouldAddHome && this.actionList.length) {
        this.actionResultList.push('');
        this.actionToReplay = {
            type: 'home',
            data: { serialNumber: this.selectedRnpDevice.serialNumber },
            timestamp: this.actionList[this.actionList.length - 1].timestamp + 1500
          };
      }
      this.recordInProgress = false;
    }
  }

  openConfirmCancelRecording(): void {
    this.dialogModalService.openConfirmationDialog('discardRnpRecord', () => this.cancelTestCreation());
  }

  saveTest(): void {
    if (this.actionList.length && !this.recordInProgress) {
      const resTest = {
        name: this.testName,
        deviceId: this.selectedRnpDevice.deviceId,
        description: this.testDescription,
        data: this.actionList
      };
      this.rnpService.saveRecordAndPlayTest(resTest).subscribe((res) => {
        if (res.statusCode === 200) {
          this.navigateMoMt('/snap/record-and-play');
        }
      });
    }
  }

  runSimpleReplay() {
    if (this.actionList.length && !this.recordInProgress) {
      if (this.replayInProgress) {
        this.replayInProgress = false;
        this.execTime = -1;
        this.startTime = new Date().getTime();
        this.runFailed = false;
      } else {
        this.replayInProgress = true;
        this.startTime = new Date().getTime();
        if (this.actionList.length) {
          this.actionResultList = this.actionList.map(() => '');
          this.actionList.forEach((action, i) => {
            setTimeout(() => {
              if (this.replayInProgress) {
                this.actionToReplay = this.actionList[i];
              } else {
                return;
              }
            }, action.timestamp);
            if (i === this.actionList.length - 1) {
              setTimeout(() => {
              }, action.timestamp + 600);
            }
          });
        }
      }
    }
  }

  replayActionResult(actionResult: any) {
    if (!this.runFailed) {
      const index = this.actionResultList.indexOf('');
      this.actionResultList[index] =
          actionResult.includes('Pass') ? ActionStatus.PASS : ActionStatus.FAILED;
      if (actionResult.includes(ActionStatus.FAILED)) {
        this.runFailed = true;
        this.execTime = new Date().getTime();
        this.replayInProgress = false;
      } else if (this.actionResultList.indexOf('') < 0) {
        this.execTime = new Date().getTime();
        this.replayInProgress = false;
      }
    }
  }
}
