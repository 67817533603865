import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {animate, style, transition, trigger, state} from '@angular/animations';
import {ChatbotApiService} from '../chatbot-api.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger('hideHeader', [
      state('closed', style({
        opacity : 0, overflow: 'hidden'
      })),
      state('open', style({
        opacity : 1, overflow: 'hidden'
      })),
      transition('* => *', animate(70))
    ]),
    trigger('toggle', [
      state('closed', style({
        height: 0, padding: 0, overflow: 'hidden', marginBottom: '0', width: 0, color: '#fff'
      })),
      state('open', style({
        overflow: 'hidden', width: '600px', display: 'block'
      })),
      transition('* => *', animate(200))
    ])
  ]
})

export class ChatbotComponent implements OnInit, OnChanges {
  @Output() stateEmitted = new EventEmitter<string>();
  @Input() changeState = false;
  messageRecieved: any = '';
  answer: any = '';
  typing = false;
  apiCall: any;
  title = 'procal_chat_project';
  articles: any = [];
  loader = false;
  messageArray = [];
  userMessage = '';
  state = 'closed';
  isClose = true;

  constructor(private apiService: ChatbotApiService,
              private elementRef: ElementRef) { }

  ngOnInit() {
    this.apiService.getChat().subscribe((data)=> {
     this.articles = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.changeState && changes.changeState.currentValue) {
      this.toggleChatBox();
    }
  }
  send(quest?: any ){
    if (quest) {
      this.userMessage = quest;

    }
    if (this.userMessage !== '') {
      this.messageArray.push({content: this.userMessage , role: 'user'});
      this.submitQuestion();
    }
    this.userMessage = '';
    this.userMessage = this.userMessage.replace(/(\r\n|\n|\r)/gm, '');
  }
  submitQuestion() {
    this.loader = true;
    const body = {
      'messages': this.messageArray,
      'stream': false,
      'context': {
        'overrides': {
          'top': 3,
          'retrieval_mode': 'text',
          'semantic_ranker': true,
          'semantic_captions': false,
          'suggest_followup_questions': false,
          'use_oid_security_filter': false,
          'use_groups_security_filter': false
        }
      },
      'session_state': null
    };
    this.typing = true;
    this.apiCall = this.apiService.sendMessage(body).subscribe((data) => {
      this.loader = false;
      this.messageRecieved = data.choices[0].message.content;
      this.messageRecieved = this.messageRecieved.replaceAll('\n', '<br>');
      this.messageRecieved = this.messageRecieved.replace(/[.*]/, '');
      this.messageRecieved = this.messageRecieved.replace(/\[[^\]]*\]/g, '');
      for (let i = 0; i < this.messageRecieved.length; i++) {
        setTimeout(() => {
          this.answer += this.messageRecieved[i];
          this.elementRef.nativeElement
              .querySelector('#chat').scrollIntoView({ behavior: 'smooth' });
        }, 70);
      }
      this.answer = '';
      this.messageArray.push({content: this.messageRecieved , role: 'assistant'});
    });
  }

  toggleChatBox() {
    this.isClose = !this.isClose;
    if (this.isClose) {
      this.messageArray = [];
      if (this.apiCall) { this.apiCall.unsubscribe(); }
      this.loader = false;
    }
    this.isClose ? this.state = 'closed' : this.state = 'open';
    this.stateEmitted.emit(this.state);
  }
}
