
import { MediaMatcher } from '@angular/cdk/layout';
import {NavigationEnd, Router} from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  HostListener,
  OnInit
} from '@angular/core';
import { CommonService } from '../../shared/common.service';


@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss'],
})
export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  activeIndex: any;
  message: number;
  public screenWidth: any;
  public screenHeight: any;
  isMobile = false;
  detail: any;
  Name: string;
  logo: string;
  userData: any;
  isLoading = false;
  currentScreenWidth: any;
  private _mobileQueryListener: () => void;
  year: number;
  chatbotState = 'closed';
  mouseDownTime = 0;
  openChatBox = false;
  showNewBackground = false;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public common: CommonService,
              public router: Router) {
    const date = new Date();
    this.year = date.getFullYear();
     this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.showNewBackground = this.router.url.includes('booking-manager');
      }
    });
  }

  ngOnInit(): void {
    this.activeIndex = localStorage.getItem('activeIndex');
    this.currentScreenWidth = screen.width;
    if (this.currentScreenWidth <= 780) {
       this.isMobile = true;
      localStorage.setItem('isMobile', 'true');
    } else {
      localStorage.setItem('isMobile', 'false');
      this.isMobile = false;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isMobile = this.screenWidth <= 780;
  }

  updateState($event: string) {
    this.chatbotState = $event;
    this.openChatBox = $event === 'open';
  }

  saveMouseDownTime($event: MouseEvent) {
    this.mouseDownTime = $event.timeStamp;
  }

  checkForLongPress($event: MouseEvent) {
    if ($event.timeStamp - this.mouseDownTime < 200) {
      this.openChatBox = true;
    }
  }
}
