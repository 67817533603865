import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TooltipService } from '../../../../shared/tooltip.service';
import { FeatureTypeEnum } from '../../../../shared/enum';
import { CommonService } from '../../../../shared/common.service';
import { WebsocketService } from '../../../../websocket.service';
import { DeviceService } from '../../../view-device/device.service';
import { Command, ControlStatus } from '../../../view-device/current-device-new/current-device-new.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs/internal/observable/interval';
import { DialogModalService } from '../../../../shared/dialog-modal.service';
import { Router } from '@angular/router';
import { MoMtDeviceInfo, TelephonyDevice } from '../../interfaces';
import { DevicehomeService } from '../../../device-homepage/devicehome.service';
import { TokenData } from '../../../../shared/interfaces';
import { Subject, Subscription } from 'rxjs';

interface DeviceSelectionCommand {
  shouldDiscard?: boolean;
  shouldBook?: boolean;
  devices?: MoMtDeviceInfo[];
  subject?: Subject<boolean>
}

@Component({
  selector: 'app-multi-device-select',
  templateUrl: './multi-device-select.component.html',
  styleUrls: ['./multi-device-select.component.scss']
})
export class MultiDeviceSelectComponent implements OnInit, OnDestroy {
  isLoading = 0;
  userData: TokenData;
  isPlaying = false;
  bufferQueue = [];
  audioBuffer;
  sampleRate = 44100;
  bitDepth = 16;
  channels = 2;
  source = null;
  isLandscapeModes: Record<string, boolean> = {};
  originalHeights: Record<string, number> = {};
  originalWidths: Record<string, number> = {};
  stopSwipePropagation = false;
  tooltipComponent = null;
  isBookingAvailable = false;
  frameWidths: Record<string, number> = {};
  frameHeights: Record<string, number> = {};
  downX = -1;
  downY = -1;
  upX = -1;
  upY = -1;
  forcedLandscape = false;
  checkOrientationAfterClick = false;
  isDragAndDropMode = false;
  durationDnd = 0;
  holdDuration = 0;
  startDrag = 0;
  endDrag = 0;
  scale = 1;
  lastClickTime = 0;
  clicksCount = 0;
  startDragAndDropTime = 0;
  lastScrollTime = 0;
  typeEventClick = 'click';
  pressDuration = 10;
  isFilechooseOpen = false;
  bluetoothStatus: ControlStatus = 'NA';
  wifiStatus: ControlStatus = 'NA';
  airPlanModeStatus: ControlStatus = 'NA';
  volumeStatus: ControlStatus = 'NA';
  mobileDataStatus: ControlStatus = 'NA';
  wifiConnect = '--';
  battery = '--';
  storage = '--';
  wifiPolling: any;
  previouswifiStatus: ControlStatus = 'NA';
  isWiFiLoading = true;
  isBluetoothLoading = true;
  isMobileDataLoading = true;
  isAirplaneLoading = true;
  isVolumeLoading = true;
  audioContext: AudioContext | null;
  AudioStreamingStatus = false;
  isManipulationAvailable = false;
  dcMenuOpened = false;
  latestActionResult: any;
  pressTimer: any;
  requestQueue = [];
  totalByte = 0;
  inputAddress: any;
  lat = 40.730610;
  lng = - 73.935242;
  actionResultCount = 0;
  isLocationChoose = false;
  selectedLocation = '';
  showSearch = true;
  code: number;
  reqQueueWatcher: any;
  inReq = false;
  selectedDevices: Record<string, any> = {};
  selectingDevice: TelephonyDevice;
  selectedDeviceToControl = -1;
  imageContents: Record<string, SafeResourceUrl> = {};
  isImageShown: Record<string, boolean> = {};
  getImageSub: Record<number, Subscription> = {}

  @ViewChild('imgWrapper') imgWrapper: ElementRef;
  @Input() isTestRunning: boolean;
  @Input() ignoreTyping: boolean;
  @Input() devices: TelephonyDevice[] = [];
  @Input() deviceListener: Subject<DeviceSelectionCommand>;

  @Output() switchCustomizeMode = new EventEmitter<boolean>();
  @Output() deviceSelected = new EventEmitter<any>();

  constructor(private tooltipService: TooltipService,
    private commonService: CommonService,
    private websocketService: WebsocketService,
    private deviceService: DeviceService,
    private _snackBar: MatSnackBar,
    private dialogModalService: DialogModalService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private deviceHome: DevicehomeService) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
    this.isBookingAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
    this.isManipulationAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.DEVICE_MANIPULATION]);

    if (this.isBookingAvailable) {
      this.reqQueueWatcher = setInterval(() => {
        if (this.requestQueue.length > 0 && !this.inReq) {
          this.sendInputRequest(this.requestQueue[0]);
          this.requestQueue.shift();
        }
      }, 100);
    }

    // loading saved/in progress test or discarding
    this.deviceListener.subscribe((command) => {

      if (command.shouldDiscard) {
        Object.values(this.selectedDevices).forEach((device) => {
          this.releaseDevice(device.index, command.subject);
        });
      }

      if (command.devices) {
        this.isLoading++;
        this.deviceHome.availableDeviceMomt().subscribe((res) => {
          this.isLoading--;

          if (res['message'] === 'Success') {
            const allDeviceList = res.data.deviceList;
            command.devices.forEach((device) => {
              const deviceInfo = allDeviceList?.find((d) => d.serialNumber === device.sn)
              if (deviceInfo) {
                if (command.shouldBook) {
                  this.bookDevice({ ...deviceInfo, index: device.index, type: device.type });
                } else {
                  this.selectedDevices[device.index] = { ...deviceInfo, ...device };
                  this.deviceSelected.emit(this.selectedDevices);
                  this.originalHeights[device.index] = parseInt(deviceInfo.screenHeight);
                  this.originalWidths[device.index] = parseInt(deviceInfo.screenWidth);
                  this.getImageCallWebsocket(device.index);
                }
              }
            });
          }
        });
      }
    }, () => this.isLoading--);
  }

  ngOnDestroy(): void {
    const devices = Object.values(this.selectedDevices);
    devices.forEach((device: any) => {
      if (device) {
        if (this.websocketService.socketAudio$) {
          this.audioContext.close();
          this.websocketService.sendMessage(this.websocketService.createCommand('STOP', device.serialNumber),
            'audio');
          this.websocketService.socketAudio$ = undefined;
          this.AudioStreamingStatus = false;
          this.isPlaying = false;
          this.bufferQueue = [];
        }
        this.websocketService.sendMessage(this.websocketService.createCommand('STOP', device.serialNumber),
          'screencast');
        if (this.getImageSub[device.index]) {
          this.getImageSub[device.index].unsubscribe();
        }
      }
    });
  }

  openErrorToast(err: any): void {
    let msg = 'Error response has been received from the server. Please try again later.';
    if (err.error && err.error.data && err.error.data.message) {
      msg = err.error.data.message;
    }
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['danger'],
    });
  }

  addToQueue(type: string, data): void {
    this.requestQueue.push({ type: type, data: data });
  }

  HomeCall(): void {
    const device = this.selectedDevices[this.selectedDeviceToControl];
    if (device) {
      const request = { serialNumber: device.serialNumber };
      this.addToQueue('home', request);
    }
  }

  RecentCall() {
    const device = this.selectedDevices[this.selectedDeviceToControl];
    if (device) {
      const request = { serialNumber: device.serialNumber };
      this.addToQueue('recent', request);
    }
  }

  BackCall(): void {
    const device = this.selectedDevices[this.selectedDeviceToControl];
    if (device) {
      const request = { serialNumber: device.serialNumber };
      this.addToQueue('back', request);
    }
  }

  landscapeCall() {
    let mode = 'natural';
    const device = this.selectedDevices[this.selectedDeviceToControl];
    if (device) {
      const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];

      if (!landscapeMode) {
        mode = 'right';
      }

      const request = {
        serialNumber: device.serialNumber,
        mode,
        screenShot: false
      };
      this.addToQueue('rotate', request);
    }
  }

  WifiAPI(event?: Event) {
    event.stopPropagation();
    if (this.wifiStatus === 'NA') { return; }
    this.addToQueue('wifi', this.generateControlRequest('WIFI', this.wifiStatus));
  }

  getDeviceControlsMode() {
    const deviceDetail = this.getDeviceDetail();
    this.deviceService.getDeviceControlsMode(deviceDetail.serialNumber, deviceDetail['labDomain']).subscribe(
      (res) => {
        if (res.tool_Result === 'Pass') {
          this.updateDeviceControlsMode(res.tool_Extra.controls);
          this.isAirplaneLoading = false;
          this.isVolumeLoading = false;
          this.isBluetoothLoading = false;
          this.isMobileDataLoading = false;
          this.isWiFiLoading = false;
        }
        if (res.tool_Result === 'Fail') {
          this._snackBar.open(`${res.tool_Extra}`, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
          });
          return;
        }
      });
  }

  changeDeviceControlsPanelVisibility() {
    const deviceDetail = this.getDeviceDetail();
    if (deviceDetail.oem === 'Apple') {
      this.deviceService.changeDeviceControlsPanelVisibility(deviceDetail['labDomain'], deviceDetail.serialNumber, 'CLOSE').subscribe(
        (res) => {
        });
    }
  }

  swipeOutsideImg($event, deviceIndex: number): void {
    if (this.selectedDeviceToControl !== deviceIndex) {
      if (!this.stopSwipePropagation) {
        if (this.pressTimer) {
          clearInterval(this.pressTimer);
        }
        const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];
        const rotated = landscapeMode && this.originalWidths[this.selectedDeviceToControl] < this.originalHeights[this.selectedDeviceToControl];
        const eventUpX = $event.offsetX;
        const eventUpY = rotated ? $event.clientY : $event.offsetY;
        const outsideWidth = this.imgWrapper.nativeElement.offsetWidth;
        const outsideHeight = this.imgWrapper.nativeElement.offsetHeight;
        const height = landscapeMode ? this.frameWidths[this.selectedDeviceToControl] : this.frameHeights[this.selectedDeviceToControl];
        const width = landscapeMode ? this.frameHeights[this.selectedDeviceToControl] : this.frameWidths[this.selectedDeviceToControl];
        const offsetLeft = (outsideWidth - width) / 2;
        const topOffset = (outsideHeight - height) / 2;
        const bottomOffset = topOffset + height;

        if (this.downX !== -1) {
          // calculate specific for the swipe ending outside device window
          if (eventUpX < offsetLeft) {
            // swipe to the left
            this.upX = rotated ? this.downX : 1;
            this.upY = rotated ? width : eventUpY - topOffset;
          } else if (eventUpX > width + offsetLeft) {
            // swipe to the right
            this.upX = rotated ? this.downX : width;
            this.upY = rotated ? 1 : eventUpY - topOffset;
          } else if (eventUpY < topOffset) {
            // swipe to the top
            this.upX = rotated ? 1 : eventUpX - offsetLeft;
            this.upY = rotated ? this.downY : 1;
          } else if (eventUpY > bottomOffset) {
            // swipe to the bottom
            this.upX = rotated ? height : eventUpX - offsetLeft;
            this.upY = rotated ? this.downY : height;
          }

          const requestMapSwipe = {};
          const result = this.getOriginalCoordinates();
          const deviceDetail = this.getDeviceDetail();

          requestMapSwipe['serialNumber'] = deviceDetail.serialNumber;
          requestMapSwipe['startX'] = result.modifiedDownX;
          requestMapSwipe['startY'] = result.modifiedDownY;
          requestMapSwipe['endX'] = result.modifiedUpX;
          requestMapSwipe['endY'] = result.modifiedUpY;

          this.addToQueue('swipe', requestMapSwipe);
        }
      } else {
        this.stopSwipePropagation = false;
      }
    }
  }

  getOriginalCoordinates() {
    let heightRatio: any;
    let widthRatio: any;

    const originalWidth = this.originalWidths[this.selectedDeviceToControl];
    const originalHeight = this.originalHeights[this.selectedDeviceToControl];
    const deviceDetails = this.getDeviceDetail();

    if (originalWidth > originalHeight) {
      if (deviceDetails.oem !== 'Apple') {
        const ratio = parseFloat((originalWidth / originalHeight).toFixed(2));
        heightRatio = originalHeight / this.frameWidths[this.selectedDeviceToControl];
        widthRatio = originalWidth / (this.frameWidths[this.selectedDeviceToControl] * ratio);
      } else {
        const ratio = parseFloat((originalWidth / originalHeight).toFixed(2));
        heightRatio = (originalHeight / this.frameWidths[this.selectedDeviceToControl]) * ratio;
        widthRatio = (originalWidth / (this.frameWidths[this.selectedDeviceToControl] * ratio)) * ratio;
      }
    } else {
      const ratio = parseFloat((originalHeight / originalWidth).toFixed(2));
      widthRatio = originalWidth / this.frameWidths[this.selectedDeviceToControl];
      heightRatio = originalHeight / (this.frameWidths[this.selectedDeviceToControl] * ratio);
    }

    const modifiedUpX = this.upX * widthRatio;
    const modifiedUpY = this.upY * heightRatio;
    const modifiedDownX = this.downX * widthRatio;
    const modifiedDownY = this.downY * heightRatio;
    return { modifiedUpX, modifiedUpY, modifiedDownX, modifiedDownY };
  }

  processImage($event, deviceIndex: number) {
    const prevWidth = this.originalWidths[deviceIndex];
    let eventPath;
    if ($event.path) {
      eventPath = $event.path;
    } else {
      eventPath = $event.composedPath();
    }

    const newHeight = parseInt(eventPath[0].naturalHeight);
    const newWidth = parseInt(eventPath[0].naturalWidth);
    // if image orientation changed swap original resolution
    if ((newWidth > newHeight && this.originalWidths[deviceIndex] < this.originalHeights[deviceIndex])
      || (newHeight > newWidth && this.originalHeights[deviceIndex] < this.originalWidths[deviceIndex])) {
      this.originalWidths[deviceIndex] = this.originalHeights[deviceIndex];
      this.originalHeights[deviceIndex] = prevWidth;
    }

    const deviceDetail = this.selectedDevices[deviceIndex];
    const serialNumber = deviceDetail.serialNumber;
    const landscapeMode = this.isLandscapeModes[deviceIndex];
    if (deviceDetail['deviceCommunicationStatus'] === 'remote-testing') {
      this.isLandscapeModes[deviceIndex] = this.originalWidths[deviceIndex] > this.originalHeights[deviceIndex];
    } else {
      if (landscapeMode && this.checkOrientationAfterClick) {
        this.deviceService.getOrientationMode(deviceDetail['labDomain'], serialNumber)
          .subscribe((orientationResult) => {
            this.checkOrientationAfterClick = false;
            if (orientationResult && (orientationResult.tool_Result === 'Pass')) {
              if (!(this.forcedLandscape && orientationResult.tool_Extra === 'portrait')) {
                this.isLandscapeModes[deviceIndex] = (orientationResult.tool_Extra === 'landscape');
              }
            }
            this.getImageHeight(deviceIndex);
          }, (err) => {
            this.checkOrientationAfterClick = false;
            this.openErrorToast(err);
          });
      } else {
        if (!landscapeMode && this.originalHeights[deviceIndex] < this.originalWidths[deviceIndex] && (prevWidth !== this.originalWidths[deviceIndex])) {
          this.deviceService.getOrientationMode(deviceDetail['labDomain'], serialNumber)
            .subscribe((orientationResult) => {
              if (orientationResult && (orientationResult.tool_Result === 'Pass')) {
                if (orientationResult.tool_Extra === 'portrait') {
                  this.isLandscapeModes[deviceIndex] = deviceDetail['oem'].toLowerCase() === 'apple'
                    ? true
                    : orientationResult.tool_Extra === 'landscape';
                } else {
                  this.isLandscapeModes[deviceIndex] = orientationResult.tool_Extra === 'landscape';
                }
              }
              this.getImageHeight(deviceIndex);
            }, (err) => {
              this.openErrorToast(err);
            });
        } else {
          if (this.forcedLandscape && this.originalHeights[deviceIndex] < this.originalWidths[deviceIndex]) {
            this.forcedLandscape = false;
          }
          this.getImageHeight(deviceIndex);
        }
      }
    }
  }

  getImageHeight(deviceIndex: number) {
    const landscapeMode = this.isLandscapeModes[deviceIndex];
    if (!landscapeMode) {
      const img = document.getElementById('framePort' + deviceIndex);
      if (img) {
        this.frameHeights[deviceIndex] = img.clientHeight;
        this.frameWidths[deviceIndex] = img.clientWidth;
      }
    } else {
      const img = document.getElementById('frameLand' + deviceIndex);
      if (img) {
        if (this.originalHeights[deviceIndex] > this.originalWidths[deviceIndex]) {
          this.frameHeights[deviceIndex] = img.clientHeight;
          this.frameWidths[deviceIndex] = img.clientWidth;
        } else {
          this.frameHeights[deviceIndex] = img.clientWidth;
          this.frameWidths[deviceIndex] = img.clientHeight;
        }
      }
    }
  }

  mouseDown(event, deviceIndex: number): void {
    if (this.selectedDeviceToControl === deviceIndex) {
      this.isDragAndDropMode = false;
      this.lastClickTime = event.timeStamp;
      this.startDrag = event.timeStamp;
      this.startDragAndDropTime = new Date().getTime();
      this.isFilechooseOpen = false;
      if (this.pressTimer) {
        clearInterval(this.pressTimer);
      }
      this.pressDuration = 10;
      this.pressTimer = setInterval(() => {
        this.pressDuration += 10;
      }, 10);
      this.downX = event['offsetX'];
      this.downY = event['offsetY'];
    }
  }

  mouseUp(event, deviceIndex: number) {
    if (this.downX !== -1 && this.downY !== -1 && this.selectedDeviceToControl === deviceIndex) {
      if (this.isDragAndDropMode) {
        this.holdDuration = Math.round(event.timeStamp - this.endDrag);
      }
      if (this.pressTimer) {
        clearInterval(this.pressTimer);
      }
      this.stopSwipePropagation = true;
      const deviceDetail = this.getDeviceDetail();
      if (deviceDetail.oem !== 'Apple') {
        this.prepareToAddToQueue(event);
      } else {
        const now = event.timeStamp;
        if (now - this.lastClickTime < 300 && this.clicksCount === 2) {
          this.typeEventClick = 'tripleTap';
          this.prepareToAddToQueue(event);
          this.clicksCount = 0;
        } else if (now - this.lastClickTime < 300 && this.clicksCount === 1) {
          this.clicksCount++;
          setTimeout(() => {
            if (this.clicksCount === 2) {
              this.typeEventClick = 'doubleTap';
              this.prepareToAddToQueue(event);
              this.clicksCount = 0;
            }
          }, 300);
        } else {
          this.clicksCount++;
          setTimeout(() => {
            if (this.clicksCount === 1) {
              this.clicksCount = 0;
              this.prepareToAddToQueue(event);
            }
          }, 300);
        }
      }
    }
  }

  prepareToAddToQueue(event): void {
    this.upX = event['offsetX'];
    this.upY = event['offsetY'];

    const { modifiedUpX, modifiedUpY, modifiedDownX, modifiedDownY } = this.getOriginalCoordinates();
    if (this.isFilechooseOpen) {
      return;
    }
    const deviceDetail = this.getDeviceDetail();
    const serialNumber = deviceDetail.serialNumber;
    if (Math.abs(this.downX - this.upX) <= 3 && Math.abs(this.downY - this.upY) <= 3) {
      const requestMapClick = {};
      requestMapClick['serialNumber'] = serialNumber;
      requestMapClick['duration'] = this.pressDuration === 0 ? 10 : this.pressDuration;
      requestMapClick['xAxis'] = modifiedUpX;
      requestMapClick['yAxis'] = modifiedUpY;
      this.addToQueue(this.typeEventClick, requestMapClick);
    } else {
      const requestMapSwipe = {};
      requestMapSwipe['serialNumber'] = serialNumber;
      requestMapSwipe['startX'] = modifiedDownX;
      requestMapSwipe['startY'] = modifiedDownY;
      requestMapSwipe['endX'] = modifiedUpX;
      requestMapSwipe['endY'] = modifiedUpY;

      this.typeEventClick = this.isDragAndDropMode ? 'dragNDrop' : 'swipe';
      this.addToQueue(this.typeEventClick, requestMapSwipe);
    }
    this.typeEventClick = 'click';
    this.downX = -1;
    this.downY = -1;
    this.holdDuration = 0;
  }

  mousemove(event, deviceIndex: number) {
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedDeviceToControl === deviceIndex) {
      const deviceDetail = this.getDeviceDetail();
      if (deviceDetail.oem !== 'Apple') { return; }
      if (this.pressDuration > 500) {
        this.isDragAndDropMode = true;
      }
      if (!this.isDragAndDropMode) { return; }
      this.endDrag = event.timeStamp;
      if (this.startDrag) {
        this.durationDnd = Math.round(event.timeStamp - this.startDrag);
        this.startDrag = 0;
      }
    }
  }

  onMouseWheel(event: WheelEvent, deviceIndex: number) {
    if (this.selectedDeviceToControl === deviceIndex) {
      const deviceDetail = this.getDeviceDetail();
      if (deviceDetail.oem !== 'Apple') { return; }

      this.lastScrollTime = new Date().getTime();
      const latestScrollTime = this.lastScrollTime;

      setTimeout(() => {
        const scaleChange = 0.1;
        if (event.deltaY < 0) {
          if (this.scale < 1) {
            this.scale = 1;
          }
          this.scale += scaleChange;
        } else {
          if (this.scale > 1) {
            this.scale = 1;
          }
          this.scale -= scaleChange;
        }
        if (this.lastScrollTime === latestScrollTime) {
          this.scale = Math.min(Math.max(this.scale, 0.1), 3);
          this.scale = parseFloat(this.scale.toFixed(1));

          this.upX = event.offsetX;
          this.upY = event.offsetY;
          const deviceDetail = this.getDeviceDetail();
          const serialNumber = deviceDetail.serialNumber;

          const result = this.getOriginalCoordinates();
          const request = {
            serialNumber: serialNumber,
            xAxis: result.modifiedUpX,
            yAxis: result.modifiedUpY,
            scale: this.scale,
            velocity: this.scale > 1 ? 5 : -5,
          };
          this.addToQueue('pinch', request);
        }
      }, 100);
    }
  }

  sendInputRequest(data): void {
    this.inReq = true;
    switch (data.type) {
      case 'click':
        this.clickEventCall(data);
        break;
      case 'doubleTap':
        this.doubleClickEventCall(data);
        break;
      case 'tripleTap':
        this.tripleClickEventCall(data);
        break;
      case 'swipe':
        this.swipeEventCall(data);
        break;
      case 'dragNDrop':
        this.dragNDropEventCall(data);
        break;
      case 'pinch':
        this.pinchEventCall(data);
        break;
      case 'home':
        this.homeRequest(data);
        break;
      case 'recent':
        this.recentAppRequest(data);
        break;
      case 'back':
        this.backRequest(data);
        break;
      case 'rotate':
        this.orientationRequest(data);
        break;
      case 'wifi':
        this.changeDeviceControlsMode(data, this.isWiFiLoading, 'Wifi', this.wifiStatus);
        break;
      case 'bluetooth':
        this.changeDeviceControlsMode(data, this.isBluetoothLoading, 'Bluetooth', this.bluetoothStatus);
        break;
      case 'mobile-data':
        this.changeDeviceControlsMode(data, this.isMobileDataLoading, 'Mobile Data', this.mobileDataStatus);
        break;
      case 'airplane':
        this.changeDeviceControlsMode(data, this.isAirplaneLoading, 'Airplane', this.airPlanModeStatus);
        break;
      case 'volume':
        this.changeDeviceControlsMode(data, this.isVolumeLoading, 'Volume', this.volumeStatus);
        break;
      case 'power':
        this.powerRequest(data);
        break;
    }
  }

  clickEventCall(request) {
    const deviceDetail = this.getDeviceDetail();
    const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];

    this.deviceService.clickXYCoordinate(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (landscapeMode && deviceDetail['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
        this.openErrorToast(err);
      }
    );
  }

  doubleClickEventCall(request) {
    const deviceDetail = this.getDeviceDetail();
    const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];

    this.deviceService.doubleTap(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'success' : 'error');
        }
        if (landscapeMode && deviceDetail['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('error');
        }
        this.inReq = false;
        this.openErrorToast(err);
      }
    );
  }

  tripleClickEventCall(request) {
    const deviceDetail = this.getDeviceDetail();
    const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];

    this.deviceService.tripleTap(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'success' : 'error');
        }
        if (landscapeMode && deviceDetail['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('error');
        }
        this.inReq = false;
        this.openErrorToast(err);
      }
    );
  }

  addLatestActionResult(type: string): void {
    this.latestActionResult = `${this.actionResultCount}-${type}`;
    this.actionResultCount++;
  }

  swipeEventCall(request) {
    // calculate the number of steps for the swipe
    const deviceDetail = this.getDeviceDetail();

    const pxPerStep = 30; // magic number
    const dX = Math.abs(request.data['startX'] - request.data['endX']);
    const dY = Math.abs(request.data['startY'] - request.data['endY']);
    if (((dX - dY) > 0) && (dX > pxPerStep)) {
      request.data['steps'] = Math.round(dX / pxPerStep);
    } else if (((dX - dY) < 0) && (dY > pxPerStep)) {
      request.data['steps'] = Math.round(dY / pxPerStep);
    } else {
      request.data['steps'] = 1;
    }
    request.data['duration'] = this.pressDuration === 0 ? 10 : this.pressDuration;
    this.deviceService.swipedevice(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Swipe Event';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.openErrorToast(err);
      }
    );
  }

  dragNDropEventCall(request) {
    const dX = Math.abs(request.data['startX'] - request.data['endX']);
    const dY = Math.abs(request.data['startY'] - request.data['endY']);
    const distance = Math.sqrt(dX * dX + dY * dY);
    const endTime = new Date().getTime();
    const elapsedTime = endTime - this.startDragAndDropTime;
    const velocity = Math.round(distance / (elapsedTime / 1000));
    const requestDnd = {
      serialNumber: request.data.serialNumber,
      startX: request.data.startX,
      startY: request.data.startY,
      endX: request.data.endX,
      endY: request.data.endY,
      duration: this.durationDnd / 1000,
      velocity: velocity,
      holdDuration: this.holdDuration / 1000
    };
    const deviceDetail = this.getDeviceDetail();

    this.deviceService.dragNDrop(requestDnd, deviceDetail['labDomain']).subscribe(
      (res) => {
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.openErrorToast(err);
      });
    this.isDragAndDropMode = false;
    this.durationDnd = 0;
    this.holdDuration = 0;
    this.inReq = false;
  }

  pinchEventCall(request) {
    const deviceDetail = this.getDeviceDetail();

    this.deviceService.pinch(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.openErrorToast(err);
      });
    this.inReq = false;
  }

  homeRequest(request: any): void {
    const deviceDetail = this.getDeviceDetail();
    this.deviceService.HomeCalled(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Home Event';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (deviceDetail['oem'] !== 'Apple' && this.isLandscapeModes[this.selectedDeviceToControl]) {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  recentAppRequest(request): void {
    const deviceDetail = this.getDeviceDetail();

    this.deviceService.RecentCalled(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
        this.openErrorToast(err);
      }
    );
  }

  backRequest(request): void {
    const deviceDetail = this.getDeviceDetail();
    const landscapeMode = this.isLandscapeModes[this.selectedDeviceToControl];

    this.deviceService.BackCalled(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (deviceDetail['oem'] !== 'Apple' && landscapeMode) {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
        this.openErrorToast(err);
      }
    );
  }

  orientationRequest(request): void {
    const deviceDetail = this.getDeviceDetail();

    this.deviceService.landscape(request.data, deviceDetail['labDomain']).subscribe(
      (res) => {
        if (res) {
          if (res.tool_Result === 'Pass') {
            this.isLandscapeModes[this.selectedDeviceToControl] = request.data.mode === 'right';
            // forced landscape in unused
            this.forcedLandscape = false;
            if (request.recordAndPlay) {
              this.addLatestActionResult('success');
            }
          } else if (res.tool_Result === 'Fail') {
            // the request is fine, but the current application on the device can't be rotated to the landscape mode
            if (res.tool_Extra.toLowerCase() === 'failed to rotate screen to right mode') {
              this.isLandscapeModes[this.selectedDeviceToControl] = true;
              this.forcedLandscape = true;
              if (request.recordAndPlay) {
                this.addLatestActionResult('success');
              }
            } else {
              if (request.recordAndPlay) {
                this.addLatestActionResult('Failed');
              }
            }
          }
        }
        this.inReq = false;
      },
      (err) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.openErrorToast(err);
      }
    );
  }

  changeDeviceControlsMode(request, loading: boolean, control: string, status: ControlStatus) {
    this.isLoading++;
    this.switchLoading(control, true);
    const deviceDetail = this.getDeviceDetail();
    const serialNumber = deviceDetail.serialNumber;

    this.deviceService.changeDeviceControlsMode(deviceDetail['labDomain'], serialNumber, request.data).subscribe(
      (res) => {
        this.isLoading--;
        this.switchLoading(control, false);
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (res.tool_Exception.trim() === 'In Valid Serial Number') {
          this._snackBar.open('Device is Offline', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success'],
          });
          return;
        }
        if (res.tool_Result === 'Fail') {
          this._snackBar.open(`${res.tool_Extra}`, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
          });
          return;
        }
        if (res.tool_Result === 'Pass') {
          this.updateDeviceControlsMode(res.tool_Extra.controls);
        }
        if (control === 'Wifi') {
          if (res.tool_Result === 'Fail') {
            if (deviceDetail['oem'] === 'Apple') {
              this.wifiPollingStart();
            }
          }
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.isLoading--;
        this.openErrorToast(err);
      }
    );
  }

  updateDeviceControlsMode(arr) {
    const deviceDetail = this.getDeviceDetail();

    arr.map((control) => {
      switch (control.name) {
        case 'BLUETOOTH':
          this.bluetoothStatus = control.mode;
          break;
        case 'AIRPLANE':
          this.airPlanModeStatus = control.mode;
          break;
        case 'VOLUME':
          this.volumeStatus = control.mode;
          break;
        case 'MOBILE_DATA':
          this.mobileDataStatus = control.mode;
          break;
        case 'WIFI':
          this.wifiStatus = control.mode;
          if (deviceDetail['oem'] === 'Apple') {
            if (control.mode === 'OFF' || control.mode === 'NA') {
              this.wifiConnect = 'Not Connected';
            } else {
              this.wifiConnect = 'Connected';
            }
          }
          break;
      }
    });
  }

  wifiPollingStart(): void {
    this.isLoading++;
    const wifiRequestTime = Date.now();
    const deviceDetail = this.getDeviceDetail();
    const serialNumber = deviceDetail.serialNumber;

    this.wifiPolling = interval(3000).subscribe((val) => {
      const currentTime = Date.now();
      if (this.wifiStatus !== this.previouswifiStatus) {
        const request = {
          'serialNumber': serialNumber,
          'screenShot': false
        };
        this.deviceService.getDeviceDetails(request).subscribe((res) => {
          const data = res.body.data;
          if (data['controls']) {
            data['controls'].forEach(element => {
              if (element['name'] === 'WiFi') {
                this.previouswifiStatus = element['status'];
              }
            });
          }
        }, err => {
          this.isLoading--;
          this.openErrorToast(err);
        });
        // If we do not get same status for 25 seconds then we stop the polling
        if (((currentTime - wifiRequestTime) / 1000) > 25) {
          this.isLoading--;
          this.wifiStatus = this.previouswifiStatus;
          if (this.wifiPolling) {
            this.wifiPolling.unsubscribe();
          }
        }
      } else {
        this.isLoading--;
        if (this.wifiPolling) {
          this.wifiPolling.unsubscribe();
        }
      }
    });
  }

  switchLoading(control: string, state: boolean) {
    switch (control) {
      case 'Bluetooth':
        this.isBluetoothLoading = state;
        break;
      case 'Airplane':
        this.isAirplaneLoading = state;
        break;
      case 'Volume':
        this.isVolumeLoading = state;
        break;
      case 'Mobile Data':
        this.isMobileDataLoading = state;
        break;
      case 'Wifi':
        this.isWiFiLoading = state;
        break;
    }
  }

  getImageCallWebsocket(deviceIndex: number) {
    const deviceDetails = this.selectedDevices[deviceIndex];
    const socketState = this.websocketService.isWebSocketScreencastConnected(deviceDetails.serialNumber);
    if (socketState !== 0 && socketState !== 1) {
      this.getImageSub[deviceIndex] = this.websocketService.connect(deviceDetails['labDomain'], this.createCommand('START', deviceIndex), 'screencast').subscribe(
        (res: Blob) => {
          if (res) {
            this.totalByte = res.size;
            if (this.imageContents[deviceIndex]) {
              URL.revokeObjectURL(this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.imageContents[deviceIndex]));
            }
            const url = URL.createObjectURL(res);
            this.imageContents[deviceIndex] = this.sanitizer.bypassSecurityTrustResourceUrl(url);

            this.isImageShown[deviceIndex] = true;
            this.websocketService.sendMessage({
              command: 'SEND_IMAGE',
              sn: deviceDetails.serialNumber
            }, 'screencast');
          }
        },
        (error: any) => {
          this.isImageShown[deviceIndex] = false;
          setTimeout(() => {
            this.getImageCallWebsocket(deviceIndex);
          }, 1000);
        },
        () => {
          this.isImageShown[deviceIndex] = false;
        }
      );
    }
  }

  getDeviceDetail() {
    if (this.selectedDeviceToControl > -1) {
      return this.selectedDevices[this.selectedDeviceToControl];
    }
  }

  generateControlRequest(name: string, status: ControlStatus) {
    return {
      'controls': [
        {
          'name': name,
          'mode': status === 'OFF' ? 'ON' : 'OFF',
        }
      ]
    };
  }

  BluetoothAPI(event?: Event) {
    event.stopPropagation();
    if (this.bluetoothStatus === 'NA') return;
    this.addToQueue('bluetooth', this.generateControlRequest('BLUETOOTH',
      this.bluetoothStatus
    ));
  }

  MobileDataAPI(event?: Event) {
    event.stopPropagation();
    if (this.mobileDataStatus === 'NA') return;
    this.addToQueue('mobile-data', this.generateControlRequest('MOBILE_DATA', this.mobileDataStatus));
  }

  AirPlaneModeSwitcher(event?: Event) {
    const deviceDetail = this.getDeviceDetail();

    if (deviceDetail.oem === 'Apple' && parseFloat(deviceDetail.osVersion) >= 15) {
      if (this.airPlanModeStatus === 'OFF') {
        return this.openIosAirplaneWarningModal(event);
      }
    }
    this.AirPlanModeAPI(event);
  }

  openIosAirplaneWarningModal(event?: Event) {
    this.dialogModalService.openConfirmationDialog('iosAirplaneWarning', () => this.AirPlanModeAPI(event));
  }

  AirPlanModeAPI(event?: Event) {
    event.stopPropagation();
    if (this.airPlanModeStatus === 'NA') return;
    this.addToQueue('airplane', this.generateControlRequest('AIRPLANE', this.airPlanModeStatus));
  }

  VolumenAPI(event?: Event) {
    event.stopPropagation();
    if (this.volumeStatus === 'NA') return;
    this.addToQueue('volume',
      this.generateControlRequest('VOLUME',
        this.volumeStatus)
    );
  }

  startStopAudio(event?: Event): void {
    event.stopPropagation();
    if (this.websocketService.socketAudio$) {
      this.audioContext.close();
      this.websocketService.sendMessage(this.createCommand('STOP', this.selectedDeviceToControl), 'audio');
      this.websocketService.socketAudio$ = undefined;
      this.AudioStreamingStatus = false;
      this.isPlaying = false;
      this.bufferQueue = [];
    } else {
      this.getAudio();
    }
  }

  getAudio(): void {
    this.isPlaying = false;
    this.AudioStreamingStatus = true;
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const bufferSize = 60;
    let chunks = [];
    if (!this.audioBuffer) {
      this.audioBuffer = this.audioContext.createBuffer(
        this.channels,
        this.sampleRate * 10,
        this.sampleRate,
      );
    }
    const deviceDetail = this.getDeviceDetail();

    this.websocketService.connect(deviceDetail['labDomain'],
      this.createCommand('START', this.selectedDeviceToControl), 'audio').subscribe(
        (res: any) => {
          if (this.router.url !== '/admin/telephony-new-test') {
            return;
          }
          chunks.push(res);
          if (chunks.length > bufferSize) {
            this.playAudio(chunks);
            chunks = [];
          }
        }
      );
  }

  playAudio(chunks) {
    const data = new Uint8Array(chunks.reduce((acc, chunk) => [...acc, ...new Uint8Array(chunk)], []));
    const wavFile = new ArrayBuffer(44 + data.length);
    const view = new DataView(wavFile);
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + data.length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, this.channels, true);
    view.setUint32(24, this.sampleRate, true);
    view.setUint32(28, this.sampleRate * this.channels * this.bitDepth / 8, true);
    view.setUint16(32, this.channels * this.bitDepth / 8, true);
    view.setUint16(34, this.bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, data.length, true);
    const pcmView = new DataView(data.buffer);
    for (let i = 0; i < data.length; i++) {
      view.setInt8(44 + i, pcmView.getInt8(i));
    }

    function writeString(view1, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view1.setUint8(offset + i, string.charCodeAt(i));
      }
    }
    const audioFile = new Blob([view], { type: 'audio/wav' });
    audioFile.arrayBuffer().then((fil) => {
      this.audioContext.decodeAudioData(fil).then((buffer) => {
        this.bufferQueue.push(buffer);
        if (!this.isPlaying) {
          this.playNextBuffer();
        }
      });
    });
  }

  playNextBuffer() {
    if (!this.bufferQueue.length) {
      this.isPlaying = false;
      return;
    }

    const buffer = this.bufferQueue.shift();
    const newSource = this.audioContext.createBufferSource();
    newSource.buffer = buffer;
    newSource.connect(this.audioContext.destination);
    newSource.onended = () => {
      this.playNextBuffer();
    };
    newSource.start(0);
    this.isPlaying = true;
  }

  private createCommand(action: 'START' | 'STOP', deviceIndex: number): Command {
    const device = this.selectedDevices[deviceIndex];
    return {
      command: action,
      sn: device?.serialNumber
    };
  }

  changeSelectDeviceMode(deviceIndex: number) {
    const device = this.devices.find((device) => device.index === deviceIndex);
    this.selectingDevice = device;
  }

  closeDeviceSelection() {
    this.selectingDevice = null;
  }

  selectDevice(device: any) {
    const alreadySelected = this.selectedDevices[device.index];
    const selectedPreviously = this.devices.find((d) => this.selectedDevices[d.index]?._id === device._id && d.index !== device.index);
    if (alreadySelected && alreadySelected._id !== device._id) {
      this.isLoading++;
      this.websocketService.sendMessage(this.createCommand('STOP', device.index), 'screencast');
      this.deviceHome.releaseDevice({ deviceBookingId: alreadySelected.bookingId }).subscribe((res) => {
        this.isLoading--;
        this.bookDevice(device);
      }, () => {
        this.isLoading--;
        this.closeDeviceSelection()
      });
    } else if (selectedPreviously) {
      this.websocketService.sendMessage(this.createCommand('STOP', device.index), 'screencast');
      this.selectedDevices[device.index] = device;
      delete this.selectedDevices[selectedPreviously.index];

      this.deviceSelected.emit(this.selectedDevices);
      this.closeDeviceSelection();
      this.originalHeights[device.index] = parseInt(device.screenHeight);
      this.originalWidths[device.index] = parseInt(device.screenWidth);
      this.getImageCallWebsocket(device.index);
    } else {
      this.bookDevice(device);
    }
  }

  bookDevice(device: any) {
    if (device.bookedByUserId === this.userData.userId) {
      const selected = this.selectedDevices[device.index];
      if (selected) {
        if (selected._id === device._id) {
          this.closeDeviceSelection();
          return;
        } else {
          this.websocketService.sendMessage(this.createCommand('STOP', device.index), 'screencast');
          delete this.selectedDevices[device.index];
          this.deviceSelected.emit(this.selectedDevices);
        }
      }
      this.selectedDevices[device.index] = device;
      this.deviceSelected.emit(this.selectedDevices);
      this.closeDeviceSelection();
      this.originalHeights[device.index] = parseInt(device.screenHeight);
      this.originalWidths[device.index] = parseInt(device.screenWidth);
      this.getImageCallWebsocket(device.index);
    } else {
      this.isLoading++;
      this.deviceHome.bookDeviceForMoMt([{ deviceId: device._id }], '', false).subscribe((res) => {
        this.isLoading--;
        if (res.statusCode === 200) {
          const bookingId = res.data.bookingIds[0];
          this.selectedDevices[device.index] = { ...device, bookingId };
          this.deviceSelected.emit(this.selectedDevices);
          this.closeDeviceSelection();
          this.originalHeights[device.index] = parseInt(device.screenHeight);
          this.originalWidths[device.index] = parseInt(device.screenWidth);
          this.getImageCallWebsocket(device.index);
        } else {
          this.isLoading--;
          this.closeDeviceSelection();
        }
      }, () => {
        this.isLoading--;
        this.closeDeviceSelection();
      });
    }
  }

  releaseDevice(deviceIndex: number, subject?: Subject<boolean>) {
    this.isLoading++;
    this.websocketService.sendMessage(this.createCommand('STOP', deviceIndex), 'screencast');
    if (this.websocketService.socketAudio$) {
      this.websocketService.sendMessage(this.createCommand('STOP', deviceIndex), 'audio');
    }

    this.deviceHome.releaseDevice({ deviceBookingId: this.selectedDevices[deviceIndex]?.bookingId }).subscribe(() => {
      this.isLoading--;
      delete this.selectedDevices[deviceIndex];
      this.deviceSelected.emit(this.selectedDevices);

      if (subject) {
        subject.next(true);
        if (!this.isAnyDeviceSelected()) {
          subject.complete();
        }
      }
    }, () => this.isLoading--);
  }

  isAnyDeviceSelected() {
    return JSON.stringify(this.selectedDevices) !== JSON.stringify({});
  }

  changeCustomizeMode(deviceIndex: number) {
    this.selectedDeviceToControl = deviceIndex;
    deviceIndex >= 0 && localStorage.setItem('_dID', this.selectedDevices[deviceIndex].deviceId);
    this.switchCustomizeMode.emit(deviceIndex >= 0);
  }

  powerCall(): void {
    const request = {
      'serialNumber': this.getDeviceDetail().serialNumber,
      'screenShot': false
    };
    this.addToQueue('power', request);
  }

  private powerRequest(request: any): void {
    this.deviceService.powerCall(request.data, this.getDeviceDetail()['labDomain']).subscribe(
        (res) => {
          this.inReq = false;
        },
        err => {
          this.inReq = false;
          this.openErrorToast(err);
        }
    );
  }
}
