import { Component, Input, OnInit } from "@angular/core";
import { ManualTestService } from "../manual-test.service";
import { DialogModalService } from "../../../shared/dialog-modal.service";
import { ManualTestArtifact } from "../../../shared/interfaces";
import { ArtifactStatusEnum, ArtifactTypeEnum, FeatureTypeEnum, TestStatusDisplayMap } from "../../../shared/enum";
import { TooltipService } from "../../../shared/tooltip.service";
import { CommonService } from "../../../shared/common.service";

@Component({
    selector: 'app-artifact-list',
    templateUrl: './artifact-list.component.html',
    styleUrls: ['./artifact-list.component.scss'],
})
export class ArtifactListComponent implements OnInit {
    @Input() artifacts: ManualTestArtifact[];
    @Input() testId: string;
    tooltipComponent = null;

    isCloudAvailable = false;

    constructor(
        public manualTestService: ManualTestService,
        private dialogService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    };

    ngOnInit(): void {
        this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    }

    getIconName(artifact: ManualTestArtifact): string {
        switch (artifact.content) {
            case ArtifactTypeEnum.VIDEO:
                return 'artifact-video-icon';
            case ArtifactTypeEnum.LOG:
            case ArtifactTypeEnum.USER_ACTION:
            case ArtifactTypeEnum.KPIS:
                return 'artifact-logs-icon';
            case ArtifactTypeEnum.DUMP:
                return 'artifact-icon';
            default:
                return 'artifact-icon';
        }
    }

    downloadFile(artifact: ManualTestArtifact): void {
        const directUrl = artifact.result.payload.url;
        const path = directUrl.substring(directUrl.indexOf('/users/') + 1);

        this.manualTestService.getFileDownloadApi('path=' + path).subscribe(
            (res) => {
                if (artifact.content === ArtifactTypeEnum.VIDEO) {
                    window.open(res.data.url, '_blank');
                } else {
                    const link = document.createElement('a');
                    link.href = res.data.url;
                    link.download = artifact.result.payload.name;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            },
            (err) => {
            }
        );
    }

    openConfirmDeleteArtifact(artifact: ManualTestArtifact): void {
        if (artifact.status === ArtifactStatusEnum.COMPLETED) {
            this.dialogService.openConfirmationDialog('deleteArtifactsModal', () => this.manualTestService.deleteArtifact(this.testId, artifact._id));
        }
    }

    cancelFile(artifact: ManualTestArtifact): void {
        this.dialogService.openConfirmationDialog('cancelArtifactFinalization', () => this.manualTestService.cancelArtifact(this.testId, artifact._id));
    }

    getArtifactName(artifact: ManualTestArtifact): string {
        const fullName = artifact.result?.payload?.name;
        if (!fullName) {
            switch (artifact.content) {
                case ArtifactTypeEnum.LOG:
                    return 'Device Logs';
                case ArtifactTypeEnum.USER_ACTION:
                    return 'User Actions';
                case ArtifactTypeEnum.KPIS:
                    return 'Device KPIs';
                case ArtifactTypeEnum.VIDEO:
                    return 'Video';
                default: return '';
            }
        }
        return fullName.split('/').pop();
    }

    displayArtifactStatus(status: ArtifactStatusEnum): string {
        return TestStatusDisplayMap.get(status);
    }
}

