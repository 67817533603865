import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ManualTestService} from '../../../../main/manual-test/manual-test.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommonService} from '../../../common.service';
import {UserRoleEnum} from '../../../enum';
import {DialogModalService} from '../../../dialog-modal.service';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TokenData} from '../../../interfaces';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {DeviceService} from '../../../../main/view-device/device.service';
import {interval} from 'rxjs/internal/observable/interval';

interface Property {
    name: string;
    value: string;
    category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
    editable?: boolean;
}

export enum TabInfoTypeEnum {
    GENERAL_INFO = 'GENERAL_INFO',
    CUSTOM_PROPERTIES = 'CUSTOM_PROPERTIES',
}

type StatePropertyUpdate = 'create' | 'change';

@Component({
    selector: 'app-info-tab',
    templateUrl: './info-tab.component.html',
    styleUrls: ['./info-tab.component.scss']
})

export class InfoTabComponent implements OnInit, OnDestroy {
    @Input() openReleaseModal?: () => void;
    @Input() updateIgnoreTyping?: (v: boolean) => void;
    @Input() disableNetwork = true;
    serialNumber: string;
    isLoading = 0;
    isUsbAttach = true;
    deviceInfo: any;
    isCloudAvailable = false;
    isBookingAvailable = false;
    deviceList: [];
    isTerminalOpen = false;
    userId: string;
    tooltipComponent = null;
    isAttachReq = false;
    ignoreTyping = false;
    testList = [];
    deviceBookingDetail: any;
    wifiConnect = '--';
    battery = '--';
    storage = '--';
    providerName = '--';
    showConnectivityToggle = false;
    requestQueue = [];
    ratList = [];
    titleModalProperty: string;
    titleModalButton: string;
    statePropertyUpdate: StatePropertyUpdate;
    selectedProperty: Property;
    properties: Property[];
    propertiesCash: Property[];
    nameProperty = '';
    valueProperty: string;
    category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
    modelOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
    };
    private modalRef: NgbModalRef;
    closeResult: string;
    isPropExistInProperties = false;
    isNamePristine = true;
    userData: TokenData;
    propertyWarning = `Warning: Property with this name already exists. New property won't be created, instead value of the existing one will be updated.`;
    releaseBtnTooltip = 'If you are not using this device, you can release it earlier for others to use this.';
    addCustomPropTooltip = 'Add custom property';
    options: string[];
    deviceId = '';
    deviceDetail: any;
    originalHeight = 0;
    originalWidth = 0;
    activeTab = TabInfoTypeEnum.GENERAL_INFO;
    TypeEnum = TabInfoTypeEnum;
    wifi: any;
    wifiPolling: any;
    isBluetoothOn = false;

    constructor(
        protected manualTestService: ManualTestService,
        private _snackBar: MatSnackBar,
        private deviceService: DeviceService,
        private common: CommonService,
        private dialogModalService: DialogModalService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.userData = this.common.getUser();
        this.getAllDevices();
    }
    ngOnDestroy() {
        if (this.wifiPolling) {
            this.wifiPolling.unsubscribe();
        }
    }

    getAllDevices(): void {
        this.isLoading++;
        let deviceId;
        if (this.manualTestService?.selectedManualTestDevice?.deviceId) {
            deviceId = this.manualTestService.selectedManualTestDevice.deviceId;
        } else {
            deviceId = localStorage.getItem('_dID');
        }
        const query = 'filter=current&deviceId=' + deviceId;
        this.deviceService.getActiveDevice(query).subscribe((res) => {
            this.isLoading--;
            if (res['message'] === 'Success') {
                if (res.data.deviceList.length === 0) {
                    this.deviceBookingDetail = null;
                } else {
                    res.data.deviceList[0]['minDateCal'] = new Date(new Date(res.data.deviceList[0]?.bookedUntil * 1000).getTime()
                        + (3600 * 1000 * 24));
                    this.deviceDetail = res.data.deviceList[0]['deviceData'][0];
                    this.isUsbAttach = this.deviceDetail['deviceStateCode'] !== 'DETACHED';
                    this.originalHeight = parseInt(this.deviceDetail.screenHeight);
                    this.originalWidth = parseInt(this.deviceDetail.screenWidth);
                    localStorage.setItem('labDomain', this.deviceDetail['labDomain']);
                    if (this.manualTestService?.selectedManualTestDevice?.serialNumber) {
                        this.serialNumber = this.manualTestService.selectedManualTestDevice.serialNumber;
                    } else {
                        this.serialNumber = this.deviceDetail['serialNumber'];
                    }
                    this.deviceBookingDetail = res.data.deviceList[0];
                    this.getDeviceDetails();
                    this.getDeviceDynamicFields();
                }
            }
        }, err => {
            this.isLoading--;
        });
    }
    openErrorToast(err: any): void {
        let msg = 'Error response has been received from the server. Please try again later.';
        if (err.error && err.error.data && err.error.data.message) {
            msg = err.error.data.message;
        }
        this._snackBar.open(msg, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
        });
    }
    ConnectivityAPI() {
        if (this.showConnectivityToggle === true) {
            this.showConnectivityToggle = false;
            return;
        }
        this.connectivityRequest();
    }

    addToQueue(type: string, data): void {
        this.requestQueue.push({ type: type, data: data });
    }

    selectNetwork(index) {
        this.ratList.forEach(element => {
            element['active'] = false;
        });
        this.ratList[index]['active'] = true;
        this.PostNetworkAPI();
    }

    PostNetworkAPI() {
        let queryParam = '';
        this.ratList.forEach(element => {
            if (element['active'] == true) {
                queryParam = 'ratId=' + element['id'] + '&ratName=' + element['name'];
            }
        });
        this.isLoading++;
        this.deviceService.postNetworkChange(this.deviceDetail['labDomain'], this.deviceDetail['serialNumber'], queryParam).subscribe(
            (res) => {
                this.isLoading--;
                if (res['tool_Result'] === 'Pass') {
                    this.ratList.forEach(element => {
                        if (element['active'] == true) {
                            this.deviceInfo['networkType'] = element['name'];
                        }
                    });
                    this._snackBar.open('Network Updated Successfully', '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['success'],
                    });
                } else {
                    this._snackBar.open(res['data']['tool_Exception'].trim(), '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['success'],
                    });
                }
            },
            err => {
                this.openErrorToast(err);
            }
        );
    }

    openModal(content, title?: string, button?: string, state?: StatePropertyUpdate, prop?: Property) {
        if (title) {
            this.titleModalProperty = title;
        }
        if (button) {
            this.titleModalButton = button;
        }
        if (state) {
            this.statePropertyUpdate = state;
        }
        if (prop) {
            this.selectedProperty = prop;
            this.nameProperty = prop.name;
            this.valueProperty = prop.value;
            this.category = prop.category;
        }
        this.modelOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'md',
        };
        this.modalRef = this.modalService.open(content, this.modelOptions);
        this.modalRef.result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    getDeviceDynamicFields(): void {
        if (!this.serialNumber) {
            return;
        }
        this.isLoading++;
        this.deviceService.getDeviceDynamicFields(this.serialNumber).subscribe(
            (res) => {
                this.isLoading--;
                if (res.message === 'Success') {
                    this.properties = res.data.properties;
                }
            },
            err => {
                this.isLoading--;
                this.openErrorToast(err);
            }
        );
    }

    updateProperty() {
        this.propertiesCash = [...this.properties];
        if (this.statePropertyUpdate === 'create') {
            if (this.isPropExistInProperties && !this.isNamePristine) {
                return this.changeProperty();
            } else {
                return this.createProperty();
            }
        }
        if (this.statePropertyUpdate === 'change') {
            this.changeProperty();
        }
    }

    createProperty() {
        this.propertiesCash.push({
            name: this.nameProperty,
            value: this.valueProperty,
            category: this.userData.role === UserRoleEnum.ADMIN ? 'ADMIN_FIELDS' : 'DYNAMIC_FIELDS'
        });
        return this.sendProperty();
    }

    changeProperty() {
        this.propertiesCash = this.propertiesCash.filter((prop) => prop !== this.selectedProperty);
        this.selectedProperty = null;
        this.propertiesCash.push({
            name: this.nameProperty,
            value: this.valueProperty,
            category: this.category
        });
        this.sendProperty();
    }

    openDeleteDynamicPropertyDialog(prop) {
        this.dialogModalService.openConfirmationDialog('deleteDynamicProperty', () => this.deleteProperty(prop));
    }

    deleteProperty(property) {
        this.propertiesCash = [...this.properties];
        this.propertiesCash = this.propertiesCash.filter((prop) => prop !== property);
        this.sendProperty();
    }

    sendProperty() {
        this.propertiesCash.forEach((property) => {
            delete property.editable;
        });
        const request = {
            properties: this.propertiesCash
        };
        this.deviceService.updateDeviceDynamicFields(this.serialNumber, request).subscribe().add(() => {
            this.getDeviceDynamicFields();
        });
        this.nameProperty = '';
        this.valueProperty = '';
        this.closeModal();
    }

    compareProperty() {
        this.isPropExistInProperties = this.properties.some((prop) => {
            return Object.keys(prop).some(() => {
                if (prop['name']?.toString() === this.nameProperty && this.statePropertyUpdate === 'create') {
                    this.selectedProperty = prop;
                    this.category = prop.category;
                }
                return prop['name']?.toString() === this.nameProperty;
            });
        });
    }
    closeModal(): void {
        if (this.modalRef) {
            this.modalRef.close();
            this.nameProperty = '';
            this.valueProperty = '';
            this.isNamePristine = true;
            this.isPropExistInProperties = false;
            this.selectedProperty = null;
            this.category = null;
            this.isTerminalOpen = false;
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'label',
            textField: 'label',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }
    getDeviceDetails(): void {
        if (!this.serialNumber) {
            return;
        }
        const request = {
            'serialNumber': this.serialNumber,
            'screenShot': false
        };
        this.isLoading++;
        this.deviceService.getDeviceDetails(request).subscribe(
            (res) => {
                this.isLoading--;
                if (res.body['data']['tool_Result'].toUpperCase() === 'PASS') {
                    this.deviceInfo = res.body.data.tool_Extra;
                    this.deviceInfo.controls.forEach(control => {
                        if (control.name === 'Bluetooth') {
                            this.isBluetoothOn = control.status;
                        }
                    });
                    this.wifi = this.deviceInfo?.wifi;
                    const data = res.body.data.tool_Extra;

                    // Polling wifi is stopped for now because there is a problem on the backend
                    // this.wifiPollingStart();
                    if (data.controls) {
                        data.additionalInfo.forEach(element => {
                            if (element.name === 'WIFI') {
                                if (this.manualTestService.selectedManualTestDevice?.oem !== 'Apple') {
                                    if (element.settingsInfo === '~~' || element.settingsInfo === 'No WiFi Connected~~') {
                                        this.wifiConnect = 'Not Connected';
                                    } else {
                                        if (element.settingsInfo.match('<unknown ssid>')) {
                                            this.wifiConnect = 'Connected';
                                        } else {
                                            this.wifiConnect = 'Connected';
                                        }
                                    }
                                }
                            }
                            if (element.name === 'Storage') {
                                this.storage = element.settingsInfo;
                            }
                            if (element.name === 'Battery') {
                                if (this.manualTestService.selectedManualTestDevice?.oem === 'Apple') {
                                    this.battery = element['settingsInfo'];
                                    if (element['settingsInfo'] == '1.0') {
                                        this.battery = 'Fully charged';
                                    } else if (element['settingsInfo'] == '-1.0') {
                                        this.battery = 'Charging';
                                    } else {
                                        this.battery = 'Charging';
                                    }
                                } else {
                                    this.battery = element['settingsInfo'] + '%' + ' Charged';
                                }
                            }
                            if (element['name'] === 'Network') {
                                const providerNameTemp = element['settingsInfo'];
                                // we are getting provider Name + Signal Strength separated by '~' example: "settingsInfo": "YOTA~LTE (-120 dBm)~"
                                this.providerName = providerNameTemp.split('(')[0] || '--';
                            }
                        });
                    }
                } else {
                    this.deviceInfo = '';
                }
            },
            err => {
                this.isLoading--;
                this.openErrorToast(err);
            }
        );
    }
    openTab(tab: TabInfoTypeEnum): void {
        if (this.activeTab !== tab) {
            this.activeTab = tab;
        }
    }

    // this function is called in function getDeviceDetails
    wifiPollingStart(): void {
        this.wifiPolling = interval(1000).subscribe((val) => {
                this.deviceService.getWifiStatus(this.deviceDetail['labDomain'], this.serialNumber).subscribe((res) => {
                    if (res?.tool_Result === 'Pass' && res?.tool_Extra) {
                        this.wifi = res.tool_Extra;
                    }
                }, err => {
                });
        });
    }

    connectivityRequest(): void {
        this.isLoading = 1;
        this.deviceService.getRatList(this.deviceDetail['labDomain'], this.deviceDetail['serialNumber']).subscribe(
            (res) => {
                this.ratList = res.tool_Extra ? JSON.parse(res.tool_Extra) : [];
                this.showConnectivityToggle = true;
                this.isLoading = 0;
                if (this.ratList.length === 0) {
                    this._snackBar.open('No Network Available', '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['success'],
                    });
                    return;
                }
            },
            err => {
                this.openErrorToast(err);
            }
        );
    }
}
