import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManualTest, ManualTestArtifact } from '../../../shared/interfaces';
import { ArtifactStatusEnum, ArtifactTypeEnum, FeatureTypeEnum } from '../../../shared/enum';
import { ApiResponseDto } from '../../../shared/dto';
import { ManualTestService } from '../manual-test.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { CommonService } from '../../../shared/common.service';
import { DialogModalService } from '../../../shared/dialog-modal.service';

@Component({
  selector: 'app-manual-test-record-sidepanel',
  templateUrl: './manual-test-record-sidepanel.component.html',
  styleUrls: ['./manual-test-record-sidepanel.component.scss']
})
export class ManualTestRecordSidepanelComponent implements OnInit {
  @Output() toggleSidepanelAction = new EventEmitter<boolean>();
  @Output() updateTable = new EventEmitter<boolean>();
  @Input() testInfo: any;
  descriptionToAdd = '';
  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  tooltipComponent = null;
  isCloudAvailable = false;

  constructor(private modalService: NgbModal,
    public manualTestService: ManualTestService,
    private tooltipService: TooltipService,
    private commonService: CommonService,
    private dialogService: DialogModalService,
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
  }

  toggleDrawer(): void {
    this.toggleSidepanelAction.emit(true);
  }
  closeDescriptionModal() {
    this.modalRef.close('cancel');
    this.descriptionToAdd = '';
  }

  openModal(content) {
    this.modalRef = this.modalService.open(content, this.modalOptions);
  }

  getIconName(artifact: ManualTestArtifact): string {
    switch (artifact.content) {
      case ArtifactTypeEnum.VIDEO:
        return 'artifact-video-icon';
      case ArtifactTypeEnum.LOG:
      case ArtifactTypeEnum.USER_ACTION:
      case ArtifactTypeEnum.KPIS:
        return 'artifact-logs-icon';
      case ArtifactTypeEnum.DUMP:
        return 'artifact-icon';
      default:
        return 'artifact-icon';
    }
  }

  applyDescription() {
    if (this.descriptionToAdd) {
      this.manualTestService.updateTest(this.testInfo._id, { description: this.descriptionToAdd }).subscribe((res: ApiResponseDto<ManualTest>) => {
        if (res.statusCode === 200) {
          this.manualTestService.updateTestList(res.data as ManualTest);
          this.descriptionToAdd = '';
          this.modalRef.close('accept');
        }
      });
    }
  }

  checkArtifactDownload(artifact: ManualTestArtifact): boolean {
    return this.isCloudAvailable && artifact.status === ArtifactStatusEnum.COMPLETED;
  }

  downloadFile(artifact: ManualTestArtifact): void {
    const directUrl = artifact.result.payload.url;
    const path = directUrl.substring(directUrl.indexOf('/users/') + 1);

    this.manualTestService.getFileDownloadApi('path=' + path).subscribe(
      (res) => {
        if (artifact.content === ArtifactTypeEnum.VIDEO) {
          window.open(res.data.url, '_blank');
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.download = artifact.result.payload.name;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      },
      (err) => {
      }
    );
  }
  openConfirmDeleteArtifact(artifact: ManualTestArtifact): void {
    if (artifact.status === ArtifactStatusEnum.COMPLETED) {
      this.dialogService.openConfirmationDialog('deleteArtifactsModal', () => this.manualTestService.deleteArtifact(this.testInfo._id, artifact._id));
    }
  }

  calculateTestSize(artifact: ManualTestArtifact): number {
    return artifact.result?.payload?.size;
  }

}
