import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRoleEnum } from '../../../../shared/enum';
import { Feature, TokenData } from '../../../../shared/interfaces';
import { AdminService } from '../../admin.service';
import { CommonService } from '../../../../shared/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GetSubscriptionListDto } from '../../../../shared/dto';

@Component({
    selector: 'create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
    invoiceForm: FormGroup;
    userData: TokenData;
    isLoading = false;
    enterpriseOptions: { id: string, name: string }[] = [];
    minStartDate = new Date();
    minEndDate = new Date();
    unlimited = 8640000000000;
    subscriptionOptions = [];
    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private commonService: CommonService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.initializeForm();
        this.getSubscriptions();
        this.getEnterprises();
    }

    initializeForm() {
        this.invoiceForm = this.fb.group({
            selectEnterprise: ['', [Validators.required]],
            firstNameSalesPerson: [''],
            lastNameSalesPerson: [''],
            firstNameEnterpriseSupport: [''],
            lastNameEnterpriseSupport: [''],
            labLimit: ['', [Validators.min(0)]],
            deviceLimit: ['', [Validators.min(0)]],
            cost: ['', [Validators.min(0)]],
            onGoingEngagement: [false],
            userLimit: ['', [Validators.min(0)]],
            subscriptionId: [''],
            subscriptionStart: [''],
            subscriptionEnd: [''],
            comments: ['']
        });
    }

    getEnterprises() {
        this.isLoading = true;
        if (this.userData.role === UserRoleEnum.ADMIN) {
            this.adminService.getEnterprise('').subscribe((res) => {
                this.isLoading = false;
                if (res['message'] === 'Success') {
                    this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }
    getSubscriptions() {
        this.isLoading = true;
        const query = 'type=COMMON,ENTERPRISE&status=ACTIVE';
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] === 'Success') {
                const result = res.data as GetSubscriptionListDto;
                this.subscriptionOptions = result.subscriptions.map((subscription) => ({ id: subscription._id, name: subscription.name, type: subscription.type, features: subscription.features }));
            }
        }, err => {
            this.isLoading = false;
        });
    }
    createInvoice() {
        if (this.invoiceForm.valid) {
            const {
                selectEnterprise,
                firstNameSalesPerson,
                lastNameSalesPerson,
                firstNameEnterpriseSupport,
                lastNameEnterpriseSupport,
                labLimit,
                deviceLimit,
                onGoingEngagement,
                userLimit,
                cost,
                subscriptionId,
                subscriptionStart,
                subscriptionEnd,
                comments
            } = this.invoiceForm.value;
            
            let subscription = null;
            if (subscriptionId) {
                const { id, name, features } = this.getSubscriptionData(subscriptionId);
                let type = this.route.snapshot.queryParamMap.get('type');
                const endOfDay = new Date().setHours(23, 59, 59);
                if (type === 'corporate') {
                    type = 'ENTERPRISE';
                } else {
                    type = 'PUBLIC';
                }
                subscription = {
                    id,
                    name,
                    type,
                    expiration: {
                        startAt: subscriptionStart < endOfDay ? subscriptionStart.getTime() : subscriptionStart.setHours(23, 59, 59),
                        endAt: subscriptionEnd ? subscriptionEnd.setHours(23, 59, 59) : this.unlimited,
                    },
                    features
                };
            }

            const request = {
                enterprise: {
                    id: selectEnterprise
                },
                user: {
                    id: this.userData.userId
                },
                isExtensionOutgoingEngagement: onGoingEngagement,
                subscription,
                cost: cost || 0,
                salePerson: {
                    firstName: firstNameSalesPerson,
                    lastName: lastNameSalesPerson
                },
                customerPerson: {
                    firstName: firstNameEnterpriseSupport,
                    lastName: lastNameEnterpriseSupport
                },
                labLimit: labLimit || 0,
                deviceLimit: deviceLimit || 0,
                userLimit: userLimit || 0,
                comments,
            };
            this.isLoading = true;
            this.adminService.createInvoice(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] === 'Success') {
                    this.router.navigate(['admin/invoices']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    getSubscriptionData(id: string) {
        const selectedSubscription = this.subscriptionOptions.find(subscription => subscription.id === id);

        if (selectedSubscription) {
            return {
                id: selectedSubscription.id,
                name: selectedSubscription.name,
                features: selectedSubscription.features
            };
        }
    }
    replaceLimit(prop: string) {
        if (!this.invoiceForm.value[prop]) {
            this.invoiceForm.controls[prop].setValue('');
        }
    }


    resetExpiration() {
        this.invoiceForm.controls.subscriptionEnd.setValue('');
    }
    updateExpirationMin() {
        this.minEndDate.setDate(this.invoiceForm.value.subscriptionStart.getDate() + 1);
        if (this.invoiceForm.value.subscriptionEnd < this.invoiceForm.value.subscriptionStart) {
            this.invoiceForm.controls.subscriptionEnd.setValue('');
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    onSubscriptionChange($event: Event) {
        const target = $event.target as HTMLSelectElement;
        const startField = this.invoiceForm.get('subscriptionStart');
        if (target.value) {
            startField.setValidators(Validators.required);
            startField.updateValueAndValidity();
        } else {
            startField.removeValidators(Validators.required);
            startField.setValue('');
        }
    }
}
