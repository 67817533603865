import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  HostListener,
  OnInit
} from '@angular/core';
import { CommonService } from '../../shared/common.service';


@Component({
  selector: 'app-admin-mangement',
  templateUrl: './admin-mangement.component.html',
  styleUrls: ['./admin-mangement.component.scss']
})
export class AdminMangementComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  activeIndex: any;
  message: number;
  public screenWidth: any;
  public screenHeight: any;
  isMobile = false;
  detail: any;
  Name: string;
  logo: string;
  isLoading = false;
  currentScreenWidth: any;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public common: CommonService,
              public router: Router) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    const userData = this.common.getUser();
    if (userData.role == 'USER') {
      this.router.navigate(['/signin']);
    }
    this.activeIndex = localStorage.getItem('activeIndex');
    this.currentScreenWidth = screen.width ;
    if ( this.currentScreenWidth <=  780) {
      this.isMobile = true;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isMobile = this.screenWidth <= 780;
  }
}
