import { NgModule } from "@angular/core";
import { CustomNewTestComponent } from "./custom-new-test/custom-new-test.component";
import { CustomTableViewComponent } from "./custom-table-view/custom-table-view.component";
import { CustomTestReportComponent } from "./custom-test-report/custom-test-report.component";
import { SharedModule } from "../../shared/shared.module";
import { CustomTestService } from "./custom-test.service";
import { MaterialModule } from "../../shared/material-ui.module";
import { IterationAccordionComponent } from "./components/iteration-accordion/iteration-accordion.component";
import { TippyDirective } from "@ngneat/helipopper";
import { TelephonyTestSidepanelComponent } from './components/telephony-test-sidepanel/telephony-test-sidepanel.component';
import { MultiDeviceSelectComponent } from "./components/multi-screencast/multi-device-select.component";
import { DeviceSelectionComponent } from "./components/device-selection/device-selection.component";
import { DeviceDropdownComponent } from "./components/device-dropdown/device-dropdown.component";
import { NewCustomTestCaseComponent } from "./new-custom-test-case/new-custom-test-case.component";
import { SortablejsModule } from "@maksim_m/ngx-sortablejs";
import {RouterLink} from '@angular/router';

@NgModule({
    declarations: [
        CustomNewTestComponent,
        CustomTableViewComponent,
        CustomTestReportComponent,
        IterationAccordionComponent,
        TelephonyTestSidepanelComponent,
        MultiDeviceSelectComponent,
        DeviceSelectionComponent,
        DeviceDropdownComponent,
        NewCustomTestCaseComponent,
    ],
    imports: [
        SharedModule,
        MaterialModule,
        TippyDirective,
        SortablejsModule,
        RouterLink
    ],
    providers: [CustomTestService],
    exports: [
        IterationAccordionComponent
    ]
})
export class CustomTestModule { }
