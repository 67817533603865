<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1 *ngIf="testCaseId">Edit test</h1>
<h1 *ngIf="!testCaseId">Create a new test</h1>
<div *ngIf="testNotFound" class="not_found">
  Test you are looking for does not exist
</div>
<mat-stepper *ngIf="!testNotFound" linear #stepper class="stepper_container">
  <mat-step [stepControl]="testCaseForm" style="flex-grow: 1" state="form">
    <form class="test_form" [formGroup]="testCaseForm">
      <ng-template matStepLabel>Configure test info</ng-template>
      <div class="scrollable_container">
        <div class="form_container">
          <div class="group">
            <label for="name">Test name<span>*</span></label>
            <input type="text" formControlName="name" maxlength="50" id="name" class="form-control"
              placeholder="Enter test name" />
            <div
              *ngIf="testCaseForm.controls.name.invalid && (testCaseForm.controls.name.dirty || testCaseForm.controls.name.touched)"
              class="error">
              <div *ngIf="testCaseForm.controls.name.errors.required">*Name is required</div>
            </div>
          </div>
          <div class="group">
            <label for="description">Number of devices</label>
            <div class="device_count">
              <button class="snap-button secondary" [ngClass]="{ primary: testCaseForm.value.deviceCount === 1 }"
                (click)="setDeviceCount(1)">
                1
              </button>
              <button class="snap-button secondary" [ngClass]="{ primary: testCaseForm.value.deviceCount === 2 }"
                (click)="setDeviceCount(2)">
                2
              </button>
              <button class="snap-button secondary" [ngClass]="{ primary: testCaseForm.value.deviceCount === 3 }"
                (click)="setDeviceCount(3)">
                3
              </button>
            </div>
          </div>
          <div class="group">
            <label for="description">Verify devices</label>
            <div class="device_verifications">
              <mat-slide-toggle *ngFor="let device of devices; index as i"
                (change)="changeDeviceVerification($event, i)" labelPosition="before" [checked]="device?.isVerifiable">
                Device {{i + 1}}
              </mat-slide-toggle>
            </div>
          </div>
          <div class="group">
            <label for="description">Test description</label>
            <textarea id="description" formControlName="description" rows="5"
              class="form-control input_new_test"></textarea>
          </div>
        </div>
      </div>
      <div class="form_actions">
        <button class="snap-button secondary" type="button" (click)="navigate('/snap/custom-tests')">Cancel</button>
        <button class="snap-button primary" matStepperNext [disabled]="!testCaseForm.valid">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="stepForms" [completed]="!!testCaseId" state="steps">
    <form class="test_form" [formGroup]="stepForms" (ngSubmit)="submitForm()">
      <ng-template matStepLabel>Configure test steps</ng-template>
      <div #stepList class="scrollable_container">
        <div *ngIf="stepForms.length > 0" class="headers">
          <h4>Actions</h4>
          <h4>Verifications</h4>
        </div>
        <div *ngIf="renderList" class="steps_container" [sortablejs]="stepForms"
          [sortablejsOptions]="{ handle: '.handle', component: this, onUpdate: onOrderUpdate}">
          <div *ngFor="let stepForm of stepForms.controls; index as i" class="step">
            <div class="handle"></div>
            <span class="step_order">{{i + 1}}</span>
            <div class="step_form">
              <form class="step_component limited" [formGroup]="stepForm">
                <h4>Action</h4>
                <div class="constructor_row">
                  <label *ngIf="stepForm.value.category === 'WAIT'" class="wait_label">Wait for</label>
                  <div class="group"
                    *ngFor="let controlKey of getControlKeys(stepForm.controls, stepForm.value.category)">
                    <select *ngIf="controlKey === 'type'" class="form-control" [id]="i + controlKey" required
                      [formControlName]="controlKey" (change)="onTypeChange(i)">
                      <option *ngFor="let option of stepParameters[stepForm.value.category + controlKey].options"
                        [value]="option.value">
                        {{option.name}}
                      </option>
                    </select>
                    <select *ngIf="controlKey !== 'type' && stepParameters[controlKey].type === 'select'"
                      class="form-control" [ngClass]="{ placeholder: !stepForm.value[controlKey] }"
                      [id]="i + controlKey" [formControlName]="controlKey">
                      <option
                        *ngFor="let option of getTargetOptions(controlKey, stepForm.value.sourceDevice, stepParameters[controlKey].options)"
                        [value]="option.value">
                        {{option.name}}
                      </option>
                    </select>
                    <label class="time-unit" data-unit="ms" *ngIf="stepParameters[controlKey]?.type === 'number'">
                      <input type="number" [formControlName]="controlKey"
                        [min]="stepParameters[controlKey].constraints?.min?.value"
                        [max]="stepParameters[controlKey].constraints?.max?.value" [id]="i + controlKey"
                        class="form-control" [placeholder]="stepParameters[controlKey].placeholder"
                        (change)="onInputChange(i)" />
                    </label>
                    <div
                      *ngIf="controlKey !== 'type' && stepForm.controls[controlKey].invalid && (stepForm.controls[controlKey].dirty || stepForm.controls[controlKey].touched)"
                      class="error">
                      <div *ngIf="stepForm.controls[controlKey].errors.required">
                        *{{stepParameters[controlKey]?.constraints.required?.message ||
                        stepParameters[stepForm.value.category + controlKey]?.constraints.required?.message}}
                      </div>
                      <div *ngIf="stepForm.controls[controlKey].errors.max">
                        *{{stepParameters[controlKey]?.constraints.max?.message ||
                        stepParameters[stepForm.value.category + controlKey]?.constraints.max?.message}}
                      </div>
                      <div *ngIf="stepForm.controls[controlKey].errors.min">
                        *{{stepParameters[controlKey]?.constraints.min?.message ||
                        stepParameters[stepForm.value.category + controlKey]?.constraints.min?.message}}
                      </div>
                    </div>
                    <div *ngIf="stepForm.controls[controlKey].invalid" class="error">
                      <div *ngIf="stepForm.controls[controlKey].errors.eventValidation">
                        *{{stepParameters[controlKey]?.constraints.eventValidation?.message}}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="verifications_container limited">
                <h4>Verifications</h4>
                <div class="constructor_row full_length"
                  *ngFor="let verificationForm of stepForm.controls.verifications.controls; index as j">
                  <label>Device {{deviceVerifications[j].index}}</label>
                  <form class="step_component" [formGroup]="verificationForm">
                    <div class="constructor_row">
                      <div class="group verification"
                        *ngFor="let controlKey of getControlKeys(verificationForm.controls, '')">
                        <select *ngIf="controlKey === 'type'" class="form-control" [id]="i + controlKey + j"
                          [formControlName]="controlKey" (change)="onVerificationChange(i, j)">
                          <option *ngFor="let option of stepVerificationParameters[controlKey].options"
                            [value]="option.value">
                            {{option.name}}
                          </option>
                        </select>
                        <select
                          *ngIf="stepVerificationParameters[controlKey].type === 'select' && controlKey !== 'type'"
                          class="form-control" [ngClass]="{ placeholder: !verificationForm.value[controlKey] }"
                          [id]="i + controlKey + j" [formControlName]="controlKey">
                          <option *ngFor="let option of stepVerificationParameters[controlKey].options"
                            [value]="option.value">
                            {{option.name}}
                          </option>
                        </select>
                        <label class="time-unit" data-unit="ms"
                          *ngIf="stepVerificationParameters[controlKey].type === 'number'">
                          <input type="number" [formControlName]="controlKey"
                            [min]="stepVerificationParameters[controlKey].constraints?.min?.value"
                            [max]="stepVerificationParameters[controlKey].constraints?.max?.value"
                            [id]="i+ controlKey + j" class="form-control"
                            [placeholder]="stepVerificationParameters[controlKey].placeholder" />
                        </label>
                        <div
                          *ngIf="verificationForm.controls[controlKey].invalid && (verificationForm.controls[controlKey].dirty || verificationForm.controls[controlKey].touched)"
                          class="error">
                          <div *ngIf="verificationForm.controls[controlKey].errors.required">
                            *{{stepVerificationParameters[controlKey].constraints.required?.message}}
                          </div>
                          <div *ngIf="verificationForm.controls[controlKey].errors.max">
                            *{{stepVerificationParameters[controlKey].constraints.max?.message}}
                          </div>
                          <div *ngIf="verificationForm.controls[controlKey].errors.min">
                            *{{stepVerificationParameters[controlKey].constraints.min?.message}}
                          </div>
                          <div *ngIf="verificationForm.controls[controlKey].errors.actionMax">
                            *{{stepVerificationParameters[controlKey].constraints.actionMax?.message}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="delete_button_container">
              <span class='delete_button' [tp]="tooltipComponent" tpPlacement="right"
                [tpData]="'Delete step' | tooltipData">
                <mat-icon class="delete_icon" (click)="deleteStep(i)">clear</mat-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="add_step" *ngIf="stepForms.length > 0 && renderList">
          <div class="add_button" (click)="addStep(category)" *ngFor="let category of categories">
            <mat-icon class="add_step_plus">add_circle</mat-icon>
            <span>Add {{category.description}}</span>
          </div>
        </div>
        <div class="empty_folder" *ngIf="stepForms.length === 0">
          <img src="../../../../assets/images/empty_folder.svg" alt="" />
          <span class="empty_folder_description">Configure steps of the test by adding different actions </span>
          <div class="empty_folder_actions">
            <div class="add_button" (click)="addStep(category)" *ngFor="let category of categories">
              <mat-icon class="add_step_plus">add_circle</mat-icon>
              <span>Add {{category.description}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form_actions">
        <button class="snap-button secondary" type="button" matStepperPrevious>Back</button>
        <button *ngIf="testCaseId" class="snap-button primary" type="button" [disabled]="!isFormValid()"
          (click)="openModal(saveAsModal)">Save as</button>
        <button class="snap-button primary" type="submit" [disabled]="!isFormValid()">Save</button>
      </div>
    </form>
  </mat-step>
  <ng-template matStepperIcon="form">
    <mat-icon>settings_ethernet</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="steps">
    <mat-icon>low_priority</mat-icon>
  </ng-template>
</mat-stepper>

<!--Save as modal-->
<ng-template #saveAsModal let-modal>
  <div class="modal-body">
    <div class="header-wrapper">
      <span>Save test as</span>
      <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
        <path
          d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
      </svg>
    </div>
    <div class="dialog-divider"></div>
    <div class="dialog-content">
      <div class="input-section">
        <input type="text" class="form-control" placeholder="Enter a new name" [(ngModel)]="saveAsName" />
      </div>
    </div>

    <div class="dialog-divider"></div>
    <div class="dialog-actions">
      <button class="btn cancel-btn" (click)="closeModal()" type="button">Cancel</button>
      <button class="btn control_btn_new" (click)="saveAs()" [disabled]="!saveAsName">Save</button>
    </div>
  </div>
</ng-template>