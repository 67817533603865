<section id="sec1">
    <div class="container-fluid p-0">
        <div class="hd-cd">
            <div class="col-lg-2 blks lft-txt">
                <p class="mange_profile">Release Notes</p>
            </div>
            <div class="col-lg-10 blks lft-txt pull-right" *ngIf="userData?.role !== 'ADMIN'">
              <p class="mange_profile cursor_point" routerLink="/snap/homepage"> <img style="width: 12px;margin-right: 5px;" src="../../../assets/images/NewUI/back-svgrepo-com.svg" alt=""> Back</p>
          </div>
            
        </div>
    </div>
  </section>
<div class="container-fluid prfl">
  <div class="row">
    <div class="col-md-5">
      <p class="pw-txt"> Release Notes</p>
      <p class="pb-txt">Snap Automation release notes provide information on the features and improvements in each release.</p>
    </div>
    <div class="col-md-5" style="height: 550px;overflow: auto; ">
      <div class="container">
        <div class="p-2" style="border-bottom: 1px solid;" id="{{release.id}}" *ngFor="let release of releases">
          <div class="row col-md-12 justify-content-between m-0 p-0 ">
            <p class="release_heading">{{release.name}}</p>
            <p class="release_date">{{release.date}}</p>
          </div>
          <p style="font-weight: bold;font-size:12px;">Whats new in this release:</p>
          <p class="release_text" *ngFor="let note of release.notes"> &#9679; {{note}}</p>
        </div>
        <div class="p-2" style="border-bottom: 1px solid;" id="v1.1.0">
          <div class="row col-md-12 justify-content-between m-0 p-0 ">
            <p class="release_heading">v1.1.0</p>
            <p class="release_date">February 18, 2022</p>
          </div>
          <p style="font-weight: bold;font-size:12px ;">Initial release</p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <p class="release_heading">On This Page</p>
      <ul class="ml-3">
        <li *ngFor="let anchor of releases" class="release_text cursor_point" (click)="toVersion(anchor.id)" >{{anchor.name}}</li>
        <li class="release_text cursor_point" (click)="toVersion('v1.1.0')" >v1.1.0</li>
      </ul>
    </div>
  </div>
</div>
