import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {actionIcon, ActionStatus} from '../models/record-and-play.model';
import {RecordAndPlayService} from '../record-and-play.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-test-record-sidepanel',
  templateUrl: './test-record-sidepanel.component.html',
  styleUrls: ['./test-record-sidepanel.component.scss']
})
export class TestRecordSidepanelComponent implements OnInit {
  @Output() toggleSidepanelAction = new EventEmitter<boolean>();
  @Output() updateTable = new EventEmitter<boolean>();
  @Input() testInfo: any;
  descriptionToAdd = '';
  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };

  constructor(private rnpService: RecordAndPlayService,
              private modalService: NgbModal,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void { }

  toggleDrawer(): void {
    this.toggleSidepanelAction.emit(true);
  }

  getActionIconName(type: string, status: string): string {
    return this.rnpService.getActionIconName(type, status);
  }

  calcHoursToDisplay(timestamp: number): string {
    return this.rnpService.calcHoursToDisplay(timestamp);
  }

  getActionName(type: string): string {
    return this.rnpService.getActionName(type);
  }

  displayDetails(action: any): string {
    return this.rnpService.displayDetails(action);
  }

  formatExecTime(time: number): string {
    let res = '';
    let seconds = Math.round(time / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    if (hours > 0) {
      res += `${hours} h `;
    }
    if (minutes > 0) {
      res += `${minutes} min `;
    }
    return res + `${seconds} sec`;
  }

  formatScreenResolution(screenResolution: any): string {
    let res = 'N/A';
    if (screenResolution && screenResolution.screenWidth && screenResolution.screenHeight) {
      res = screenResolution.screenWidth + 'x' + screenResolution.screenHeight;
    }
    return res;
  }

  applyDescription() {
    if (this.descriptionToAdd) {
      let data: any;
      data = {
        recordPlayId: this.testInfo.recordPlayId,
        data: this.testInfo.data,
        name: this.testInfo.name,
        description: this.descriptionToAdd
      };
      this.rnpService.renameRecordAndPlayTest(data).subscribe((res) => {
        if (res) {
          this._snackBar.open('Description added successfully!', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success']
          });
            if (res.statusCode === 200) {
              this.testInfo = res.data.recordPlay;
              this.descriptionToAdd = '';
              this.modalRef.close('accept');
              this.updateTable.emit(true);
            }
        }
      }, (err) => {
      });
    }
  }

  closeDescriptionModal() {
    this.modalRef.close('cancel');
    this.descriptionToAdd = '';
  }

  openModal(content) {
    this.modalRef = this.modalService.open(content, this.modalOptions);
  }
}
