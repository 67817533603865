import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionIconMap, ActionTypeMap, TelephonyActionType } from '../../interfaces';

@Component({
  selector: 'app-telephony-test-sidepanel',
  templateUrl: './telephony-test-sidepanel.component.html',
  styleUrls: ['./telephony-test-sidepanel.component.scss']
})
export class TelephonyTestSidepanelComponent implements OnInit {
  @Output() toggleSidepanelAction = new EventEmitter<boolean>();
  @Input() testInfo: any;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDrawer(): void {
    this.toggleSidepanelAction.emit(true);
  }

  getActionIconName(type: TelephonyActionType): string {
    return ActionIconMap.get(type);
  }

  displayActionType(type: TelephonyActionType): string {
    return ActionTypeMap.get(type);
  }
}
