<table *ngIf="!isLoading && invoicesList?.length > 0" class="invoices-table">
    <thead>
    <tr>
        <th>Name</th>
        <th>Creation date</th>
        <th>Snap sales person</th>
        <th>Customer contact</th>
        <th>Cost</th>
        <th>Lab limit</th>
        <th>Device limit</th>
        <th>User limit</th>
        <th>Subscription</th>
        <th>Subscription start date</th>
        <th>Subscription end date</th>
        <th>Ongoing engage</th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let invoice of invoicesList; index as i" >
        <td>
            {{invoice.enterprise.name}}
        </td>
        <td class="centered">
            {{invoice.createdAt * 1000 | date: "MM.dd.yyyy" }}
        </td>
        <td>
            <span *ngIf="invoice.salePerson.firstName" class="user-icon" [tp]="tooltipComponent" [tpData]="(invoice.salePerson.firstName + ' ' + invoice.salePerson.lastName) | tooltipData">
                <user-profile-icon [user]="{firstName: invoice.salePerson.firstName, lastName: invoice.salePerson.lastName}"></user-profile-icon>
            </span>
        </td>
        <td>
            <span *ngIf="invoice.customerPerson.firstName" class="user-icon" [tp]="tooltipComponent" [tpData]="(invoice.customerPerson.firstName + ' ' + invoice.customerPerson.lastName) | tooltipData">
                <user-profile-icon [user]="{firstName: invoice.customerPerson.firstName, lastName: invoice.customerPerson.lastName}"></user-profile-icon>
            </span>
        </td>
        <td class="centered">
            ${{invoice.cost}}
        </td>
        <td class="centered">
            <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="invoice.labLimit">
                {{invoice.labLimit}}
            </span>
            <span [tp]="tooltipComponent" [tpData]="'Lab limit' | tooltipData" *ngIf="!invoice.labLimit">
                <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
            </span>
        </td>
        <td class="centered">
            <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData" *ngIf="invoice.deviceLimit">
                {{invoice.deviceLimit}}
            </span>
            <span [tp]="tooltipComponent" [tpData]="'Device limit' | tooltipData" *ngIf="!invoice.deviceLimit">
                <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
            </span>
        </td>
        <td class="centered">
            <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData" *ngIf="invoice.userLimit">
                {{invoice.userLimit}}
            </span>
            <span [tp]="tooltipComponent" [tpData]="'User limit' | tooltipData" *ngIf="!invoice.userLimit">
                <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
            </span>
        </td>
        <td >
            <feature-list [features]="getFeaturesNames(invoice.id)"></feature-list>
        </td>
        <td class="centered">
            {{invoice.subscription?.expiration.startAt | date: "MM.dd.yyyy" }}
        </td>
        <td class="centered">
            <span *ngIf="invoice.subscription?.expiration.endAt > infinityDate">
                <img src="../../../../assets/images/NewUI/infinity.svg" alt="">
            </span>
            <span *ngIf="invoice.subscription?.expiration.endAt < infinityDate">
                {{invoice.subscription?.expiration.endAt | date: "MM.dd.yyyy" }}
            </span>
        </td>
        <td>
            <mat-icon class='lab-icon'>{{invoice.isExtensionOutgoingEngagement ? 'done' : 'close'}}</mat-icon>
        </td>
        <td class="menu-cell">
            <button mat-icon-button [matMenuTriggerFor]="menu">
          <span class="menu-icon">
            <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
          </span>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item class="menu_btn_list" (click)="openView(invoice.id)">
                    <span>View</span>
                </button>
                <button *ngIf="userData?.role === 'ADMIN'" mat-menu-item class="menu_btn_list" (click)="openEdit(invoice.id)">
                    <span>Edit</span>
                </button>
            </mat-menu>
        </td>
    </tr>
    </tbody>
</table>
