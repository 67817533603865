<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title">
    <h1>
        Subscriptions
    </h1>
    <div class="filters">
        <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field"
            placeholder="Search" (change)="searchChange()" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: isFiltered }" (click)="openFilters()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <select class="filter-select form-control" [(ngModel)]="searchStatus" required>
                    <option value="">Select Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="DEPRECATED">Deprecated</option>
                    <option value="DISABLED">Disabled</option>
                </select>
                <ng-multiselect-dropdown [placeholder]="'Select Features'" [settings]="getDropDownSettings(1)"
                    [data]="featureList" [(ngModel)]="searchFeatures" (onDeSelect)="getSubscriptionList()"
                    (onSelect)="getSubscriptionList()" class="multiselect">
                </ng-multiselect-dropdown>
                <div class="filter-buttons">
                    <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
                    <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
                    <button class="snap-button primary" (click)="applyFilters()">Filter</button>
                </div>
            </div>
        </div>
        <button class="snap-button primary create-new-button" routerLink="/admin/create-subscription">
            Create a new subscription
        </button>
    </div>
</div>
<div class="tabs">
    <span [ngClass]="{ active: activeTab === TypeEnum.COMMON}" (click)="openTab(TypeEnum.COMMON)">
        Common
        <div class="total-subscriptions">
            {{totalCommon || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === TypeEnum.PUBLIC}" (click)="openTab(TypeEnum.PUBLIC)">
        Public
        <div class="total-subscriptions">
            {{totalPublic || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === TypeEnum.ENTERPRISE}" (click)="openTab(TypeEnum.ENTERPRISE)">
        Corporate
        <div class="total-subscriptions">
            {{totalCorporate || 0}}
        </div>
    </span>
</div>
<div class="empty-result" *ngIf="subscriptions.length === 0 && !isLoading">
    <img alt="" src="../../../../assets/images/NewUI/subscriptions-lg.svg">
    <span class="empty-search-title">
        {{isFiltered ? 'No subscriptions were found' : 'No subscriptions were found'}}
    </span>
    <span>
        {{isFiltered
        ? 'No results matched your criteria. try adjusting your search or filter options to find what you are looking
        for'
        : 'Create a new subscription. Set up and configure the subscription at ease'}}
    </span>
    <span *ngIf="isFiltered" (click)="clearFilters()" class="empty-search-action">Clear filters</span>
    <button *ngIf="!isFiltered" class="snap-button primary create-new-button" routerLink="/admin/create-subscription">
        Create a new subscription
    </button>
</div>
<div class="subscription-list">
    <div class="subscription-card" *ngFor="let subscription of subscriptions"
        [ngClass]="{ disabled: subscription.status === 'DISABLED' }">
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <span class="menu-icon">
                <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
            </span>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item class="menu_btn_list"
                (click)="navigatePage('/admin/update-subscription', { id: subscription._id })">
                <span>Edit</span>
            </button>
            <button mat-menu-item class="menu_btn_list" (click)="openConfirmDelete(subscription._id)">
                <span>Delete</span>
            </button>
        </mat-menu>
        <div class="subscription-icon">
            <img *ngIf="subscription.icon" [src]="subscription.icon" alt="" />
            <img *ngIf="!subscription.icon" src="../../../../assets/images/placeholder-image.jpg" alt="" />
        </div>
        <div class="feature-row">
            <div class="subscription-features">
                <span *ngFor="let feature of subscription.features.slice(0, 4)" [tp]="tooltipComponent"
                    [tpData]="feature.name | tooltipData" [tpOnlyTextOverflow]="true">
                    {{feature.name}}
                </span>
            </div>
            <span class="features-more" *ngIf="subscription.features.length > 4">
                {{subscription.features.length - 4}}+
                <div class="more">
                    <span *ngFor="let feature of subscription.features.slice(5)">
                        {{feature.name}}
                    </span>
                </div>
            </span>
        </div>
        <div class="subscription-name">
            <div class="status" [ngClass]="{ active: subscription.status === 'ACTIVE' }"></div>
            <h4 [tp]="tooltipComponent" [tpData]="subscription.name | tooltipData" [tpOnlyTextOverflow]="true">
                {{subscription.name}}
            </h4>
        </div>
        <span class="description" [tp]="tooltipComponent" [tpData]="subscription.description | tooltipData"
            [tpOnlyTextOverflow]="true">
            {{subscription.description}}
        </span>
        <span *ngIf="subscription.comments" class="comments" [tp]="tooltipComponent"
            [tpData]="subscription.comments | tooltipData" [tpOnlyTextOverflow]="true">
            Comments: {{subscription.comments}}
        </span>
        <div class="subscription-footer">
            <div class="creation-date">
                <img src="../../../../assets/images/NewUI/calendar-plus.svg" alt="" />
                {{(subscription.createdAt * 1000) | date: "MM/dd/yy"}}
            </div>
            <div class="assigned-to">
                <div class="profile-icon-container" *ngFor="let user of getSubscriptionAssignees(subscription)"
                    [tp]="tooltipComponent" [tpData]="getAssigneeTooltip(user) | tooltipData">
                    <user-profile-icon [user]="user"></user-profile-icon>
                </div>
                <div class="profiles-more"
                    *ngIf="subscription.assignedEnterprises.length + subscription.assignedUsers.length > 9">
                    {{subscription.assignedEnterprises.length + subscription.assignedUsers.length - 9}} +
                    <div class="more">
                        <div class="profile-icon-container" *ngFor="let user of getSubscriptionAssigneesMore(subscription)"
                            [tp]="tooltipComponent" [tpData]="getAssigneeTooltip(user) | tooltipData">
                            <user-profile-icon [user]="user"></user-profile-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>