import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { CustomTestService } from '../custom-test.service';
import {
    CustomTestArtifact,
    CustomTestTemplate,
    CustomTestExecution,
    CustomTestExecutionStatusEnum,
    CustomTestExecutionStatusMap
} from '../interfaces';
import { ArtifactStatusEnum, DateFilterTypeEnum, FeatureTypeEnum } from '../../../shared/enum';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { Subscription } from 'rxjs';
import { TooltipService } from '../../../shared/tooltip.service';
import { CommonService } from '../../../shared/common.service';
import { RecordAndPlayTypeEnum } from '../../record-and-play/models/record-and-play.model';
import { MatDrawer } from '@angular/material/sidenav';
import { TippyDirective } from '@ngneat/helipopper';
import { GetCustomTestExecutionsDto } from '../dto';
import { interval } from 'rxjs/internal/observable/interval';
import { CustomTestTemplateGet } from '../interfaces/custom-test-template.interface';

@Component({
    selector: 'custom-table-view',
    templateUrl: './custom-table-view.component.html',
    styleUrls: ['./custom-table-view.component.scss']
})
export class CustomTableViewComponent implements OnInit, OnDestroy {
    customTestList: CustomTestExecution[];
    testCases: CustomTestTemplateGet[];
    totalTests = 0;
    totalTestCases = 0;
    pageLimit = 10;
    currentPage = 0;
    totalPage = 0;
    paginationText = '0-0 of 0';
    pageLimitCases = 10;
    currentPageCases = 0;
    totalTestCasesPage = 0;
    paginationTextCases = '0-0 of 0';
    startDate = 0;
    endDate = 0;
    startDateResults = 0;
    endDateResults = 0;
    searchValue = '';
    searchValueResults = '';
    pollingInterval: Subscription = null;
    tooltipComponent = null;
    isTelephonyAvailable = false;

    searchTimeout;
    selectedStatuses: { item_id: CustomTestExecutionStatusEnum, item_text: string }[] = [];
    dateFilter: DateFilterTypeEnum;
    dateFilterResults: DateFilterTypeEnum;
    activeTab = RecordAndPlayTypeEnum.CASE;
    TypeEnum = RecordAndPlayTypeEnum;
    FilterType = DateFilterTypeEnum;
    isFilterOpen = false;
    sidePanelData: any;
    selectedIndex: number;

    statusList = [
        { item_id: CustomTestExecutionStatusEnum.COMPLETED, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.COMPLETED) },
        { item_id: CustomTestExecutionStatusEnum.IDLE, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.IDLE) },
        { item_id: CustomTestExecutionStatusEnum.CANCELED, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.CANCELED) },
        { item_id: CustomTestExecutionStatusEnum.EXECUTING, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.EXECUTING) },
        { item_id: CustomTestExecutionStatusEnum.RUNNING, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.RUNNING) },
        { item_id: CustomTestExecutionStatusEnum.FAILED, item_text: CustomTestExecutionStatusMap.get(CustomTestExecutionStatusEnum.FAILED) },
    ];
    isLoading = 0;
    emptyActions = [];

    @ViewChild('rangePicker') rangePicker: MatDateRangePicker<unknown>;
    @ViewChild('drawer') drawer: MatDrawer;

    constructor(
        private router: Router,
        private customTestService: CustomTestService,
        private dialogModalService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.emptyActions = [{ button: 'Create a new test', onClick: () => this.router.navigate(['/snap/customize-test-case']) }];
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.isTelephonyAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        if (this.isTelephonyAvailable) {
            this.getTests();
            this.getTestCases();
        }
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }

    getTests() {
        const query = {
            page: this.currentPage,
            size: this.pageLimit,
            startDate: this.startDateResults * 1000,
            endDate: this.endDateResults * 1000,
            name: this.searchValueResults,
            statuses: this.selectedStatuses.map(item => item.item_id).join(',')
        };

        this.isLoading++;
        this.customTestService.getTestExecutions(query).subscribe((res: GetCustomTestExecutionsDto) => {
            this.isLoading--;
            if (res) {
                this.totalTests = res.totalElements;
                this.totalPage = res.totalPages;
                this.customTestList = res.content;
                this.setPage();

                const hasFinalizing = res.content?.some((test) => test.status === CustomTestExecutionStatusEnum.FINALIZING || test.status === CustomTestExecutionStatusEnum.EXECUTING);
                if (hasFinalizing) {
                    if (!this.pollingInterval || this.pollingInterval.closed) {
                        this.pollingInterval = interval(20000).subscribe(() => {
                            this.getTests();
                        });
                    }
                } else {
                    this.stopPolling();
                }
            }
        }, err => {
            this.isLoading--;
        });
    }

    getTestCases() {
        this.isLoading++;
        const query = {
            page: this.currentPageCases,
            size: this.pageLimitCases,
            startDate: this.startDate * 1000,
            endDate: this.endDate * 1000,
            name: this.searchValue,
        };
        this.customTestService.getTestTemplates(query).subscribe((res) => {
            this.isLoading--;
            if (res && res.content) {
                this.testCases = res.content;
                this.totalTestCases = res.totalElements;
                this.totalTestCasesPage = res.totalPages;
                this.setPage();
            }
        }, err => {
            this.isLoading--;
        });
    }

    searchTests(type = 0) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.currentPage = 0;
            if (type > 0) {
                this.getTestCases();
            } else {
                this.getTests();
            }
        }, 1000);
    }

    changePageLimit() {
        if (this.activeTab === RecordAndPlayTypeEnum.CASE) {
            this.currentPageCases = 0;
            this.getTestCases()
        } else {
            this.currentPage = 0;
            this.getTests();
        }
    }

    changePage(direction: number) {
        if (this.activeTab === RecordAndPlayTypeEnum.CASE) {
            this.currentPageCases += direction;
            this.getTestCases();
        } else {
            this.currentPage += direction;
            this.getTests();
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }


    setDateFilter(param: DateFilterTypeEnum): void {
        if (this.activeTab === RecordAndPlayTypeEnum.CASE) {
            if (this.dateFilter === param) {
                this.dateFilter = null;
                this.startDate = null;
                this.endDate = null;
            } else {
                this.dateFilter = param;
                switch (param) {
                    case DateFilterTypeEnum.TODAY:
                        this.getTodayTests();
                        break;
                    case DateFilterTypeEnum.LAST_WEEK:
                        this.getLastWeekTests();
                        break;
                    case DateFilterTypeEnum.CUSTOM:
                        this.rangePicker.open();
                        break;
                }
            }
        } else if (this.activeTab === RecordAndPlayTypeEnum.RESULT) {
            if (this.dateFilterResults === param) {
                this.dateFilterResults = null;
                this.startDate = null;
                this.endDate = null;
            } else {
                this.dateFilterResults = param;
                const now = new Date();
                switch (param) {
                    case DateFilterTypeEnum.TODAY:
                        this.endDateResults = now.getTime() / 1000;
                        now.setHours(0, 0, 0, 0);
                        this.startDateResults = now.getTime() / 1000;
                        break;
                    case DateFilterTypeEnum.LAST_WEEK:
                        this.endDateResults = now.getTime() / 1000;
                        now.setDate(now.getDate() - 7);
                        now.setHours(0, 0, 0, 0);
                        this.startDateResults = now.getTime() / 1000;
                        break;
                    case DateFilterTypeEnum.CUSTOM:
                        this.rangePicker.open();
                        break;
                }
            }
        }
    }

    getTodayTests() {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
    }

    getLastWeekTests() {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setDate(now.getDate() - 7);
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
    }

    updateDateFilter(type: string, $event: MatDatepickerInputEvent<Date>) {
        if (this.activeTab === RecordAndPlayTypeEnum.CASE) {
            if (type === 'start') {
                this.startDate = new Date($event.value).getTime() / 1000;
            }
            if (type === 'end') {
                this.endDate = new Date($event.value).setHours(23, 59, 59) / 1000;
            }
        } else if (this.activeTab === RecordAndPlayTypeEnum.RESULT) {
            if (type === 'start') {
                this.startDateResults = new Date($event.value).getTime() / 1000;
            }
            if (type === 'end') {
                this.endDateResults = new Date($event.value).setHours(23, 59, 59) / 1000;
            }
        }
    }

    displayTestStatus(status: CustomTestExecutionStatusEnum) {
        return CustomTestExecutionStatusMap.get(status);
    }

    openTestDetails(test: CustomTestExecution) {
        if ([CustomTestExecutionStatusEnum.IDLE, CustomTestExecutionStatusEnum.EXECUTING, CustomTestExecutionStatusEnum.RUNNING].includes(test.status)) {
            this.router.navigate(['/snap/custom-new-test'], { queryParams: { id: test.testId, executionId: test.id } });
        } else {
            this.router.navigate(['/snap/custom-test-report'], { queryParams: { id: test.id } });
        }
    }

    checkIfFiltered(type = 0): boolean {
        if (type > 0) {
            return (this.startDate > 0 && this.endDate > 0) || this.searchValue.length > 0 || this.selectedStatuses.length > 0;
        } else {
            return (this.startDateResults > 0 && this.endDateResults > 0) || this.searchValueResults.length > 0 || this.selectedStatuses.length > 0;
        }
    }

    clearFilters() {
        if (this.activeTab === this.TypeEnum.RESULT) {
            this.startDateResults = 0;
            this.endDateResults = 0;
            this.searchValueResults = '';
            this.selectedStatuses = [];
            this.currentPage = 0;
            this.getTests();
        } else if (this.activeTab === this.TypeEnum.CASE) {
            this.startDate = 0;
            this.endDate = 0;
            this.searchValue = '';
            this.dateFilter = null;
            this.currentPage = 0;
            this.getTestCases();
        }
    }

    openConfirmDelete(id: string) {
        this.dialogModalService.openConfirmationDialog('deleteCustom', () => this.deleteTest(id));
    }

    deleteTest(id: string) {
        this.isLoading++;
        this.customTestService.deleteTestExecution(id).subscribe((res) => {
            this.isLoading--;
            this.getTests();
        }, () => this.isLoading--);
    }

    getFilteredTests() {
        this.currentPage = 0;
        if (this.activeTab === this.TypeEnum.RESULT) {
            this.getTests();
        } else if (this.activeTab === this.TypeEnum.CASE) {
            this.getTestCases();
        }
    }

    checkIfArtifactsReady(artifacts: CustomTestArtifact[]): boolean {
        let isReady = artifacts.some((device) => device.artifacts?.length > 0);
        artifacts.forEach((device) => {
            device.artifacts?.forEach((artifact) => {
                if (artifact.status !== ArtifactStatusEnum.COMPLETED) {
                    isReady = false;
                }
            });
        });
        return isReady;
    }

    checkIfFailedArtifact(artifacts: CustomTestArtifact[]): boolean {
        let isFailed = false;
        artifacts.forEach((device) => {
            device.artifacts?.forEach((artifact) => {
                if (artifact.status === ArtifactStatusEnum.FAILED) {
                    isFailed = false;
                }
            });
        });
        return isFailed;
    }

    getArtifactsSize(artifacts: CustomTestArtifact[]): number {
        return artifacts.reduce((acc, val) => {
            const size = val.artifacts?.reduce((a, artifact) => {
                return artifact.size ? a + artifact.size : a;
            }, 0);
            return size ? acc + size : acc;
        }, 0);
    }

    stopPolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }

    openTab(tab: RecordAndPlayTypeEnum): void {
        if (this.activeTab !== tab) {
            this.activeTab = tab;
            this.pageLimit = 10;
            this.currentPage = 0;
            this.setPage();
        }
    }

    openFilters(): void {
        this.isFilterOpen = !this.isFilterOpen;
    }

    openTestCaseDetails(i: number) {
        this.sidePanelData = this.testCases[i];
        this.selectedIndex = i;
        this.drawer.toggle();
    }

    openNewTest(test: CustomTestTemplate) {
        this.router.navigate(['/snap/custom-new-test'], { queryParams: { id: test.id } });
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
        this.selectedIndex = null;
    }

    setPage() {
        if (this.activeTab === RecordAndPlayTypeEnum.CASE) {
            const total = this.totalTestCases;
            const max = (this.currentPageCases + 1) * this.pageLimitCases;
            this.paginationTextCases = `${(this.currentPageCases * this.pageLimitCases) + 1}-${total < max ? total : max} of ${total}`;
        } else {
            const total = this.totalTests;
            const max = (this.currentPage + 1) * this.pageLimit;
            this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
        }
    }

    rerunTest(test: CustomTestExecution) {
        this.router.navigate(['/snap/custom-new-test'], { queryParams: { id: test.testId, executionId: test.id } });
    }

    toggleTooltip(tooltip: TippyDirective) {
        if (tooltip.isVisible) {
            tooltip.hide();
        } else {
            tooltip.show();
        }
    }

    getExecutionTime(executionTime) {
        if (!isNaN(executionTime)) {
            const minutes = Math.floor(executionTime / 60000);
            const seconds = Math.floor((executionTime - (minutes * 60000)) / 1000);
            const milliseconds = Math.round((executionTime - (minutes * 60000)) % 1000);
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(milliseconds).padStart(3, '0')}`;
        } else {
            return 'NA';
        }
    }

    getIterationWidth(test: CustomTestExecution, types: string[]): string {
        const number = this.getNoOfIterationsForStatus(test, types);
        return (Math.ceil(100 * number)) + '%';
    }

    getNoOfIterationsForStatus(test: CustomTestExecution, types: string[]): number {
        const number = types.reduce((acc, type) => {
            if (test.analytics) {
                acc += test.analytics[type];
            }
            return acc;
        }, 0);
        return number;
    }

    openConfirmDeleteTestCase(id: string) {
        this.dialogModalService.openConfirmationDialog('deleteCustomTestCase', () => this.deleteTestCase(id));
    }

    deleteTestCase(id: string) {
        this.isLoading++;
        this.customTestService.deleteTestTemplate(id).subscribe((res) => {
            this.isLoading--;
            if (res) {
                this.getTestCases();
            }
        }, () => this.isLoading--);
    }

    editTest(id: string | undefined) {
        this.router.navigate(['/snap/customize-test-case/'], { queryParams: { id: id } });
    }

    checkIfArtifactsFinalizing(logs: CustomTestArtifact[]) {
        let res = 0;
        logs.forEach((l) => {
            if (l.artifacts?.length) {
                res += l.artifacts.filter(a => a.status === ArtifactStatusEnum.FINALIZING).length;
            }
        });
        return res > 0;
    }
}
