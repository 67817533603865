<div class="main-header">
    <div>{{testInfo?.name}}</div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="header-info">
        <div class="description-wrapper">{{testInfo?.description}}</div>
        <span class="add_button" *ngIf="!testInfo?.description" (click)="openModal(addDescription)">
            + Add description
        </span>
        <div class="additional-info">
            <span class="model-name">Recorded on {{testInfo?.modelName + ' '}}|{{' '+ formatScreenResolution(testInfo?.screenResolution)}}</span>
            <span class="created-timestamp">Created on {{testInfo?.insertDate * 1000 | date:'MM/dd/yy'}}</span>
            <span class="overall-duration">Duration: {{formatExecTime(testInfo?.data[testInfo?.data.length - 1].timestamp)}}</span>
        </div>
    </div>
    <table>
        <tbody>
        <tr *ngFor="let action of testInfo?.data; index as i">
            <td class="action-icon">
                <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName(action.type, '')}}.svg" alt="">
            </td>
            <td class="timestamp-col">{{calcHoursToDisplay(action.timestamp)}}{{ action.timestamp | date:'mm:ss:SSS'}}</td>
            <td class="type-col">{{getActionName(action.type)}}</td>
            <td>{{displayDetails(action)}}</td>
        </tr>
        </tbody>
    </table>
    <span class="fake-gap"></span>
</div>

<!--Add description modal-->
<ng-template #addDescription let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>Add description</span>
            <svg (click)="closeDescriptionModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                 width="24">
                <path
                        d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-content">
            <div class="input-section">
                <textarea max="150" rows="3" class="form-control" placeholder="Enter a description"
                          [(ngModel)]="descriptionToAdd"></textarea>
            </div>
        </div>

        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeDescriptionModal()" type="button">Cancel</button>
            <button class="btn control_btn_new" (click)="applyDescription()" [disabled]="!descriptionToAdd">Add</button>
        </div>
    </div>
</ng-template>
