import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from '../shared/network.service';
@Injectable({
  providedIn: 'root'
})
export class HomeServiceService {
  private pageChange = new BehaviorSubject('false');
  currentPage = this.pageChange.asObservable();

  constructor(private networkService: NetworkService,
    ) { }


    getNotifcation(queryPram:any) {
      return this.networkService.get("api/notifications/?"+queryPram, null, null, 'basic');
    }
    getNotifcationUnread(queryPram:any) {
      return this.networkService.get("api/notifications/count?"+queryPram, null, null, 'basic');
    }
    clearNotification() {
      return this.networkService.put("api/notifications/clear", null, null, 'basic');
    }
    createReport(body:any) {
      return this.networkService.post("api/contact/us",body, null,'basic');
    }
    logoutApi(body:any) {
      return this.networkService.post("api/auth/logout",body, null,'basic');
    }
   
}
