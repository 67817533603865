export enum DayOfWeek {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY'
};

export enum BookingType {
    SINGLE = 'SINGLE',
    RECURRENT = 'RECURRENT'
}

export interface CreateBookingForm {
    timeZone: string;
    deviceIdList: string[];
    type: BookingType;
    days?: DayOfWeek[];
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    paymentMethodId?: string;
    isCardAdded?: boolean;
    description?: string;
};

export interface AlreadyBookedResponse {
    message: string;
    bookings: {
        deviceId: string;
        bookedFrom: number;
        bookedUntil: number
    }[];
}