<app-spinner *ngIf="isLoading"></app-spinner>
<div class="customize-header" *ngIf="selectedDeviceToControl > -1" [ngClass]="{customize_grid: devices?.length > 2}">
    <svg (click)="changeCustomizeMode(-1)" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
        width="24">
        <path
            d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
    </svg>
    <span>Customize device</span>
</div>
<div class="side-icon-box" [ngClass]="{panel_grid: devices?.length > 2}" *ngIf="selectedDeviceToControl > -1">
    <ul class="icon_ul pt-3">
        <li (click)="HomeCall()" tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="'Go to home page of the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 10L12 3L20 10L20 20H15V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10Z"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <li (click)="RecentCall()" *ngIf="getDeviceDetail()?.oem !== 'Apple'" tpPlacement="right"
            [tp]="tooltipComponent" [tpData]="'Fetch the list of recent app used in the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="18" width="11" height="20" rx="2" transform="rotate(-90 2 18)" stroke-width="2" />
                <rect x="6" y="3" width="12" height="18" rx="2" fill="white" stroke-width="2" />
            </svg>
        </li>
        <li (click)="BackCall()" *ngIf="getDeviceDetail()?.oem !== 'Apple'" tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="'Go to previous screen of the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4L7 12L15 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <li (click)="landscapeCall()" tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="'Change screen orientation' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M18.2002 6.94416V4M18.2002 6.94416V6.99993L15.2002 7M19.9381 11C19.979 11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M6 17.2916V17H9M6 17.2916V20"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <div tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="(isManipulationAvailable ? 'Device Controls' : 'Unavailable. Your subscription plan must include Device manipulation feature.')| tooltipData">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="button device control"
                [ngClass]="{ disabled_usb: !isManipulationAvailable}" (menuOpened)="getDeviceControlsMode()"
                (menuClosed)="changeDeviceControlsPanelVisibility()" class="button_device-control">
                <svg [ngClass]="{selected: dcMenuOpened}" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11 3H13C13.5523 3 14 3.44772 14 4V4.56879C14 4.99659 14.2871 5.36825 14.6822 5.53228C15.0775 5.69638 15.5377 5.63384 15.8403 5.33123L16.2426 4.92891C16.6331 4.53838 17.2663 4.53838 17.6568 4.92891L19.071 6.34312C19.4616 6.73365 19.4615 7.36681 19.071 7.75734L18.6688 8.1596C18.3661 8.46223 18.3036 8.92247 18.4677 9.31774C18.6317 9.71287 19.0034 10 19.4313 10L20 10C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H19.4312C19.0034 14 18.6318 14.2871 18.4677 14.6822C18.3036 15.0775 18.3661 15.5377 18.6688 15.8403L19.071 16.2426C19.4616 16.6331 19.4616 17.2663 19.071 17.6568L17.6568 19.071C17.2663 19.4616 16.6331 19.4616 16.2426 19.071L15.8403 18.6688C15.5377 18.3661 15.0775 18.3036 14.6822 18.4677C14.2871 18.6318 14 19.0034 14 19.4312V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19.4313C10 19.0034 9.71287 18.6317 9.31774 18.4677C8.92247 18.3036 8.46223 18.3661 8.1596 18.6688L7.75732 19.071C7.36679 19.4616 6.73363 19.4616 6.34311 19.071L4.92889 17.6568C4.53837 17.2663 4.53837 16.6331 4.92889 16.2426L5.33123 15.8403C5.63384 15.5377 5.69638 15.0775 5.53228 14.6822C5.36825 14.2871 4.99659 14 4.56879 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10L4.56877 10C4.99658 10 5.36825 9.71288 5.53229 9.31776C5.6964 8.9225 5.63386 8.46229 5.33123 8.15966L4.92891 7.75734C4.53838 7.36681 4.53838 6.73365 4.92891 6.34313L6.34312 4.92891C6.73365 4.53839 7.36681 4.53839 7.75734 4.92891L8.15966 5.33123C8.46228 5.63386 8.9225 5.6964 9.31776 5.53229C9.71288 5.36825 10 4.99658 10 4.56876V4C10 3.44772 10.4477 3 11 3Z"
                        stroke-width="2" />
                    <path
                        d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
                        stroke-width="2" />
                </svg>
            </button>
        </div>
        <mat-menu #menu="matMenu" class="menu_buttons" hasBackdrop="true" backdropClass="cdk-overlay-dark-backdrop">

            <span class="mat-menu-text">Device Controls</span>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="WifiAPI($event)"
                [disabled]="isWiFiLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img img-fluid p-2"
                        [ngClass]="{ img_grayscle: wifiStatus === 'OFF' || wifiStatus === 'NA', img_grayscale_loading: isWiFiLoading || wifiStatus === 'NA' }"
                        src="../../../../assets/images/NewUI/Wifi_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">Press to toggle wifi of device</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                        [ngClass]="{ mat_menu_label_color: wifiStatus === 'ON', controls_loading: isWiFiLoading || wifiStatus === 'NA' }">
                        Wi-Fi</p>
                    <p
                        [ngClass]="{ mat_menu_state_on: wifiStatus === 'ON', mat_menu_state_off: wifiStatus === 'OFF' || wifiStatus === 'NA', controls_loading: isWiFiLoading || wifiStatus === 'NA' }">
                        {{wifiStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="BluetoothAPI($event)"
                [disabled]="isBluetoothLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img"
                        [ngClass]="{ img_grayscle: bluetoothStatus === 'OFF' || bluetoothStatus === 'NA', img_grayscale_loading: isBluetoothLoading || bluetoothStatus === 'NA' }"
                        src="../../../../assets/images/NewUI/bluetooth_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">Press to toggle bluetooth of device</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                        [ngClass]="{ mat_menu_label_color: bluetoothStatus === 'ON', controls_loading: isBluetoothLoading || bluetoothStatus === 'NA' }">
                        Bluetooth</p>
                    <p
                        [ngClass]="{ mat_menu_state_on: bluetoothStatus === 'ON', mat_menu_state_off: bluetoothStatus === 'OFF' || bluetoothStatus === 'NA', controls_loading: isBluetoothLoading || bluetoothStatus === 'NA' }">
                        {{bluetoothStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="MobileDataAPI($event)"
                [disabled]="isMobileDataLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img"
                        [ngClass]="{ img_grayscle: mobileDataStatus === 'OFF' || mobileDataStatus === 'NA', img_grayscale_loading: isMobileDataLoading || mobileDataStatus === 'NA'}"
                        src="../../../../assets/images/NewUI/mobile_data_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">Press to toggle mobile data of device</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                        [ngClass]="{ mat_menu_label_color: mobileDataStatus === 'ON', controls_loading: isMobileDataLoading || mobileDataStatus === 'NA'}">
                        Mobile data</p>
                    <p
                        [ngClass]="{ mat_menu_state_on: mobileDataStatus === 'ON', mat_menu_state_off: mobileDataStatus === 'OFF' || mobileDataStatus === 'NA', controls_loading: isMobileDataLoading || mobileDataStatus === 'NA'}">
                        {{mobileDataStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="AirPlaneModeSwitcher($event)"
                [disabled]="isAirplaneLoading">
                <div class="menu_icon_fix tooltip3 airplane">
                    <img class="menu-icon_img"
                        [ngClass]="{ img_grayscle: airPlanModeStatus === 'OFF' || airPlanModeStatus === 'NA', img_grayscale_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }"
                        src="../../../../assets/images/NewUI/airplan_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">Press to toggle airplane mode of device</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                        [ngClass]="{ mat_menu_label_color: airPlanModeStatus === 'ON', controls_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }">
                        Airplane</p>
                    <p
                        [ngClass]="{ mat_menu_state_on: airPlanModeStatus === 'ON', mat_menu_state_off: airPlanModeStatus === 'OFF' || airPlanModeStatus === 'NA', controls_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }">
                        {{airPlanModeStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="VolumenAPI($event)"
                [disabled]="isVolumeLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img" *ngIf="volumeStatus"
                        [ngClass]="{ img_grayscle: volumeStatus === 'OFF' || volumeStatus === 'NA', img_grayscale_loading: isVolumeLoading || volumeStatus === 'NA' }"
                        src="../../../../assets/images/NewUI/volume_loud_solo.svg" alt="" />
                    <img class="menu-icon_img" *ngIf="!volumeStatus"
                        [ngClass]="{ img_grayscle: !volumeStatus, img_grayscale_loading: isVolumeLoading || volumeStatus === 'NA' }"
                        src="../../../../assets/images/NewUI/volume_mute_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">You can change the volume of the device</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                        [ngClass]="{ mat_menu_label_color: volumeStatus === 'ON', controls_loading: isVolumeLoading || volumeStatus === 'NA' }">
                        Volume</p>
                    <p
                        [ngClass]="{ mat_menu_state_on: volumeStatus === 'ON', mat_menu_state_off: volumeStatus === 'OFF' || volumeStatus === 'NA', controls_loading: isVolumeLoading || volumeStatus === 'NA' }">
                        {{volumeStatus}}</p>
                </div>
            </button>

            <hr />
            <span class="mat-menu-text">Experience Control</span>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="startStopAudio($event)">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img" *ngIf="AudioStreamingStatus"
                        src="../../../../assets/images/NewUI/audio_streaming.svg" alt="" />
                    <img class="menu-icon_img" *ngIf="!AudioStreamingStatus"
                        [ngClass]="{ img_grayscle: !AudioStreamingStatus }"
                        src="../../../../assets/images/NewUI/audio_streaming.svg" alt="" />
                </div>
                <span class="tooltiptext">Toggle control to stream device audio to your workstation</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label" [ngClass]="{ mat_menu_label_color: AudioStreamingStatus }">Audio
                        streaming
                    </p>
                    <p *ngIf="AudioStreamingStatus" class="mat_menu_state_on">ON</p>
                    <p *ngIf="!AudioStreamingStatus" class="mat_menu_state_off">OFF</p>
                </div>
            </button>
        </mat-menu>
    </ul>
</div>

<div class="devices" [ngClass]="{grid: devices?.length > 2 && selectedDeviceToControl < 0}">
    <div class="device-container"
        [ngClass]="{not_selected: selectedDeviceToControl > -1 && selectedDeviceToControl !== device.index }"
        *ngFor="let device of devices">
        <div class="device-wrapper" *ngIf="selectedDevices[device.index]">
            <div class="device-info" *ngIf="selectedDeviceToControl < 0 && !isTestRunning">
                <span class="title">
                    Device {{device.index}}
                </span>
                <span>{{selectedDevices[device.index].modelName}}</span>
                <app-device-dropdown [deviceInfo]="selectedDevices[device.index]"
                    (customizeClick)="changeCustomizeMode($event)" (changeClick)="changeSelectDeviceMode($event)"
                    (releaseClick)="releaseDevice($event)">
                </app-device-dropdown>
            </div>
            <div class="device-info centered" *ngIf="selectedDeviceToControl < 0 && isTestRunning">
                <span class="title">
                    Device {{device.index}}
                </span>
            </div>
            <div class="device-wrapper-ins" (mouseup)="swipeOutsideImg($event, device.index)" #imgWrapper
                [ngClass]="{'force-landscape-ins': isLandscapeModes[device.index] && originalHeights[device.index] > originalWidths[device.index]}">
                <img class="border_ image_port" *ngIf="!isLandscapeModes[device.index] && isImageShown[device.index]"
                    [id]="'framePort' + device.index" draggable="false" (load)="processImage($event, device.index)"
                    (mousedown)="mouseDown($event, device.index)" (mouseup)="mouseUp($event, device.index)"
                    (mousemove)="mousemove($event, device.index)" (wheel)="onMouseWheel($event, device.index)" [src]="imageContents[device.index]"
                    alt="" />
                <img class="border_ image_land" *ngIf="isLandscapeModes[device.index] && isImageShown[device.index]" [id]="'frameLand' + device.index"
                    draggable="false" (load)="processImage($event, device.index)" (mousedown)="mouseDown($event, device.index)"
                    (mouseup)="mouseUp($event, device.index)" (mousemove)="mousemove($event, device.index)" (wheel)="onMouseWheel($event, device.index)"
                    [src]="imageContents[device.index]" alt="" [ngClass]="{
                            'force-landscape':
                              isLandscapeModes[device.index] && originalHeights[device.index] > this.originalWidths[device.index]
                          }" />
                <img class="border_ image_loading" *ngIf="!isImageShown[device.index]" [id]="'frameLoading' + device.index" draggable="false"
                    src="https://s3.us-east-2.amazonaws.com/snap-automation/Snap/1649137991083_loading.jpg" alt="" />
            </div>
        </div>
        <div class="device" *ngIf="!selectedDevices[device.index]">
            <div *ngIf="!isBookingAvailable" [tp]="tooltipComponent"
                [tpData]="'Unavailable. Your subscription plan must include Booking feature.' | tooltipData">
                <button class="snap-button primary-solid select-button" disabled>
                    Select device {{device.index}}
                </button>
            </div>
            <button *ngIf="isBookingAvailable" class="snap-button primary-solid select-button"
                (click)="changeSelectDeviceMode(device.index)">
                Select device {{device.index}}
            </button>
        </div>
    </div>
</div>

<div class="device-selection-container" *ngIf="selectingDevice">
    <app-device-selection [device]="selectingDevice" (closeSelection)="closeDeviceSelection()"
        (confirmDeviceSelection)="selectDevice($event)"></app-device-selection>
</div>