import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomTestFormStep, CustomTestValidatorTypeMap } from '../../interfaces';
import { CustomActionIconMap, CustomActionTypeMap, CustomTelephonyActionType } from '../../interfaces/custom-test-action.interface';

@Component({
  selector: 'app-telephony-test-sidepanel',
  templateUrl: './telephony-test-sidepanel.component.html',
  styleUrls: ['./telephony-test-sidepanel.component.scss']
})
export class TelephonyTestSidepanelComponent implements OnInit {
  @Output() toggleSidepanelAction = new EventEmitter<boolean>();
  @Input() testInfo: any;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDrawer(): void {
    this.toggleSidepanelAction.emit(true);
  }

  getActionIconName(type: CustomTelephonyActionType): string {
    return CustomActionIconMap.get(type);
  }

  getDeviceIndex(payload: any, type: string): number {
    const device = payload.filter((data) => data.key === type);
    return device.length ? device[0].parameters[0].value : 0;
  }

  getVerificationForWait(step: CustomTestFormStep) {
    if (step.validators.length) {
      let res = '';
      step.validators.forEach((v, i) => {
        if (step.validators.length > 1 && i > 0) {
          res += ', ';
        }
        res = res + `Device ${v.target.parameters[0].value} ${CustomTestValidatorTypeMap.get(v.category)} ${v.property.valueName}`;
      });
      return res;
    }
    return '--';
  }

  displayActionType(type: CustomTelephonyActionType): string {
    return CustomActionTypeMap.get(type);
  }
}
