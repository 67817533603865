

<div class="container-fluid">

  <div class="row mt-2 justify-content-left lb-lst">
    <span class="list-lab mt-1">Labs List</span>
    <div class=" ml-2 position-relative">
      <select
      class="select-lab"
      (change)="changeLabList()"
      [(ngModel)]="labId"
    >
      <option [value]="item.labId"  *ngFor="let item of labListAll">{{item.name}}</option>
      
    </select>
    <span class="lab_status">
      <span id="circle" *ngIf="labData?.onlineStatus == 'online'"></span>
      <span id="circle-red" *ngIf="labData?.onlineStatus !== 'online'"></span>
    </span>
    </div>
  </div>
  <div class="row mt-2 justify-content-left lb-lst">
    <span class="lab-name">
      {{labData.name}}
      <span *ngIf="labData.isSmartHubAvailable && labData.isSmartHubEnabled" [tp]="tooltipComponent" [tpData]="'Smart Hub enabled' | tooltipData">
        <img src="../../../../assets/images/NewUI/smart-hub.svg">
      </span>
    </span>
  </div>
</div>
<div class="container-fluid fst-cn">
  
  <div class="row m-rw">
    <h5 class="mb-2 ml-3 device_data">Device Data</h5>
</div>
<div class="row m-rw align-items-center">
  <div class="col-md-3 p-2">
      <div class="phn-cl">
          <div class="row">
              <div class="col-3">
                  <img class="img-fluid" src="../../../../assets/images/NewUI/iOS.svg">
              </div>
              <div class="col-9">
                  <div class="text-right">
                      <h4 class="device-nmbrs">{{ dashboardData?.totalDevices || "0" }}</h4>
                      <p class="ttl">Total Devices</p>
                      <span class="apl-dv">
                          <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.iOSDevices || "0" }}</span>
                      </span>
                      <span class="andr-dv ml-2">
                          <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.androidDevices || "0" }}</span>
                      </span>
                  </div>
              </div>
          </div>
      </div>
     

  </div>
  <div class="col-md-3 p-2">
  <div class="phn-cl1">
      <div class="row">
          <div class="col-3">
              <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
          </div>
          <div class="col-9">
              <div class="text-right">
                  <h4 class="device-nmbrs"> {{ dashboardData?.availableDevices || "0" }}</h4>
                  <p class="ttl">Available Devices</p>
                  <span class="apl-dv">
                      <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.availableIosDevices || "0" }}</span>
                  </span>
                  <span class="andr-dv ml-2">
                      <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.availableAndroidDevices || "0" }}</span>
                  </span>
              </div>
          </div>
      </div>
  </div>

  </div>
  <div class="col-md-3 p-2">
  <div class="phn-cl2">
      <div class="row">
          <div class="col-3">
              <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
          </div>
          <div class="col-9">
              <div class="text-right">
                  <h4 class="device-nmbrs">{{ dashboardData?.usedDevices || "0" }}</h4>
                  <p class="ttl">Reserved Devices</p>
                  <span class="apl-dv">
                      <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.usedIosDevices || "0" }}</span>
                  </span>
                  <span class="andr-dv ml-2">
                      <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.usedAndroidDevices || "0" }}</span>
                  </span>
              </div>
          </div>
      </div>
  </div>

  </div>
  <div class="col-md-3 p-2">
  <div class="phn-cl3">
      <div class="row">
          <div class="col-3">
              <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
          </div>
          <div class="col-9">
              <div class="text-right">
                  <h4 class="device-nmbrs">{{ dashboardData?.inactiveDevices || "0" }}</h4>
                  <p class="ttl">Offline Devices</p>
                  <span class="apl-dv">
                      <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.inactiveIosDevices || "0" }}</span>
                  </span>
                  <span class="andr-dv ml-2">
                      <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.inactiveAndroidDevices || "0" }}</span>
                  </span>
              </div>
          </div>
      </div>
  </div>

  </div>
</div>
</div>

<div class="container-fluid">
  
<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
  <div class="row m-rw">
    <div class="col-md-6 fst-cn sn-bxes">
      <h5 class="mb-2 ml-3">Devices and Status</h5>
      <div class="tables mt-5">
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Device Name</th>
              <th>Port No.</th>
              <th>Status</th>
              <th>Restart</th>
              <th>Action</th>
            </tr>
          </thead>

          <!-- <div class="col-md-3 position-relative mt-1 m-0 p-0 bold">
            <span class="modalNumber" style="margin-left: 25px;"> {{item.modelName }} </span>
            <div class="img">
              <img *ngIf="item.oem == 'Apple'" class="deviceImage_no" src="../../../../assets/images/ic_apple.svg" alt="">
             <img *ngIf="item.oem !== 'Apple'" class="deviceImage_no" src="../../../../assets/images/ic_andoird.svg" alt="">
            </div>
          </div>
          <div class="col mt-1 _noWrap1">
            <span class="portNo" *ngIf="item.hostPort !== -1">{{item.hostPort}}</span>
            <span class="portNo" *ngIf="item.hostPort == -1">NA</span>
            </div>
          <div class="col mt-1 _noWrap1">
            <span class="text-center" [ngClass]="{ icon_active: item.deviceState == 'Online' }"></span>
            <span class="text-center" [ngClass]="{ icon_inactive: item.deviceState !== 'Online' }"></span>
          </div>
          <div class="col mt-1 _noWrap1">
            <img class="img_restart" [ngClass]="{ not_allowed: item.deviceState !== 'Online' }" #AssBtn4 (click)="openModal1(restartModal, AssBtn4,item)" src="../../../../assets/images/restart_icon.svg" alt="">
          </div>
          <div class="col mt-1 _noWrap1">
            <button  *ngIf="item.deviceState == 'Online' && item.oem == 'Apple'" (click)="UnplugDevice(item)"  [disabled]="item.hostPort == -1" class="btn red_box inner_plug_font">UNPLUG</button>
            <button *ngIf="item.deviceState !== 'Online' && item.oem == 'Apple'" (click)="plugDevice(item)" [disabled]="item.hostPort == -1" class="btn green_box inner_plug_font">PLUG</button>
            <button  *ngIf="item.deviceState == 'Online' && item.oem !== 'Apple'" (click)="UnplugDeviceAndroid(item)"  [disabled]="item.hostPort == -1" class="btn red_box inner_plug_font">UNPLUG</button>
            <button *ngIf="item.deviceState !== 'Online' && item.oem !== 'Apple'" (click)="plugDeviceAndroid(item)" [disabled]="item.hostPort == -1" class="btn green_box inner_plug_font">PLUG</button> 
          </div> -->
          <tbody>
            <p class="no_device" *ngIf="deviceList.length == 0">
              No Device Available
            </p>
            <tr *ngFor="let item of deviceList">
              <td>
                <img *ngIf="item.oem == 'Apple'" class="apl-lgo" src="../../../../assets/images/NewUI/apl.png" alt="">
               <img *ngIf="item.oem !== 'Apple'" class="apl-lgo" src="../../../../assets/images/NewUI/android.svg" alt="">
              </td>
              <td>{{item.modelName }}</td>
              <td><span class="portNo" *ngIf="item.hostPort !== -1">{{item.hostPort}}</span>
                <span class="portNo" *ngIf="item.hostPort == -1">NA</span></td>
              <td>
                <!-- <span class="re-init"  *ngIf="item.deviceState !== 'Online'"><button  #AssBtn4 (click)="openReinitModal(restartModal, AssBtn4,item)" class="btn btn-re ">Re-Init</button></span> -->
                <span id="circle" *ngIf="item.deviceState == 'Online'"></span>
                <span id="circle-red" *ngIf="item.deviceState !== 'Online'"></span>
                
              </td>
              <td> <img class="restrt" [ngClass]="{ not_allowed: item.deviceState !== 'Online' }" #AssBtn4 (click)="openModal1(restartModal, AssBtn4,item)" src="../../../../assets/images/NewUI/restart.svg" alt=""></td>
              <td>
                <a class="plug-btn tooltip3" [ngClass]="{ disable_: !(item.usbHubType && item.usbHubType !== 'GENERAL') || !labData.isSmartHubEnabled }" 
                (click)="resetCambrionix(item.serialNumber,item.labDomain)"  [disabled]="!(item.usbHubType && item.usbHubType !== 'GENERAL') || !labData.isSmartHubEnabled" >
                  RESET 
                  <span class="tooltiptext">{{ResetbleBtntooltip}}</span>
                </a> 
              </td>
            </tr>
              
          </tbody>
        </table>
        <span style="font-size: 12px;color: #F74C2B;" *ngIf="!labData.isSmartHubAvailable || !labData.isSmartHubEnabled">*Services were not running.</span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="fst-cn">
        <div class="row">
          <div class="col-10 tvi">
            <h5 class="mb-2 ml-3">Network Speed Test</h5>
            <p class="lt-up ml-3">Last Updated on 
              <span class="" *ngIf="pingData?.date">{{pingData?.date *1000 | date: "MM/dd/yyyy hh:mm a"}}</span>
              <span class="" *ngIf="!pingData?.date">--</span>
            </p>
          </div>
          <div class="col-2"> <a class="rn-tst" (click)="PingData()">Run Test</a></div>
          <div class="row col-md-12 justify-content-between">
            <div class="col-md-5 downnLoad_card text-center">
              <p class="download_label ">Downloading Speed</p>
              <p class="download_value ">{{pingData?.downloadSpeedMbps?pingData.downloadSpeedMbps:'--'}} Mbps</p>
            </div>
            <div class="col-md-5    downnLoad_card text-center">
              <p class="download_label ">Uploading Speed</p>
              <p class="download_value ">{{pingData?.uploadSpeedMbps?pingData.uploadSpeedMbps:'--'}} Mbps</p>
            </div>
          </div>
          <div class="col-md-12">
            <p class="no_history" *ngIf="showGraph1 == false">No Data Available</p>
            <google-chart *ngIf="showGraph1"  style="width: 100%;"  [type]="type"  [data]="graph1Data" [columns]="graph1Column"  [options]="graph1Option">
            </google-chart>
          </div>
        </div>
      </div>
      <div class="fst-cn">
        <div class="row">
          <div class="col-6 mt-4"><h5 class="mb-2 ml-3">CPU Usage</h5></div>
          <div class="col-6">
            <mat-form-field class="mat-form" style="float: right;">
              <input
                matInput
                readonly
                (dateChange)="DateChange()"
                [matDatepicker]="picker"
                [(ngModel)]="searchStartDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          
          <div class="col-12">
        <div class="col-12 tvi">
          <p class="no_history" *ngIf="showGraph2 == false">No Data Available</p>
       <google-chart *ngIf="showGraph2" style="width: 100%;" [type]="type2"  [data]="graph2Data" [columns]="graph2Column"  [options]="graph2Option">
       </google-chart>
        </div>
      </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-rw">
    <div class="col-md-6 fst-cn">
      <div class="row">
        <div class="col-12">
        <div class="col-6 tvi">
        <h5 class="mb-2 ml-3">Team Viewer Info</h5>
      </div>
      <div class="col-6 tvi">
        <a class="adz"  *ngIf="teamViewerData.length == 0" #AssBtn4 (click)="openModa2(teamViewer, AssBtn4, 'ADD')">Add</a>
        <a class="adz"  *ngIf="teamViewerData.length > 0" #AssBtn4 (click)="openModa2(teamViewer, AssBtn4, 'EDIT')">Edit</a>
      </div>
      <div class="col-12">
        <p class="no_history"   *ngIf="teamViewerData.length == 0">No Data Available</p>
          <div class="row  p-0 m-0" *ngIf="teamViewerData.length > 0">
          <div class="col-md-12 p-0 inner_card1">
              <p [innerHTML]="teamViewerData[0].text"></p>
          </div>
       </div>
      </div>
    </div>
   
  </div>

</div>
<div class="col-md-6 fst-cn note-cl">
  <div class="row">
    <div class="col-12">
    <div class="col-6 tvi">
    <h5 class="mb-2 ml-3">Note</h5>
  </div>
  <div class="col-6 tvi">
    <a class="adz"  #AssBtn1 (click)="openModal(notes, AssBtn1, 'ADD')">Add</a>
  </div>
  <div class="col-12">
    <p class="no_history"   *ngIf="noteList.length == 0">No Note  Available</p>
      <div class="col-md-12" *ngIf="noteList.length > 0">
        <div class="row col-md-12 p-0 m-0 border-bottom" *ngFor="let item of noteList">
          <div class="col-md-10  mt-1" style="cursor: pointer;" #AssBtn3 (click)="openModal(notesView, AssBtn3,item)">
              <span class="device_data"> {{ item.heading.length > 30 ?item.heading.substring(0,30)+"..." : item.heading }}</span>
          </div>
          <div class="col-md-2 mt-1 p-0">
              <img class="note_icon" #AssBtn1 (click)="openModal(notes, AssBtn1,item)" src="../../../../assets/images/NewUI/edit-svgrepo-com.svg" alt="">
              <img class="note_icon ml-2" #AssBtn2 (click)="openModal(deletemodal, AssBtn2,item)" src="../../../../assets/images/NewUI/ic_trash_delete.svg" alt="">
          </div>
         </div>
   </div>
  </div>
</div>

</div>

</div>
</div>

</div>



<ng-template #teamViewer let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
        <h5 class="modal-title">Team Viewer Note</h5>
    
        <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
          >x</span
        >
      </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <ckeditor
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="noteText"
          #myckeditor="ngModel"
          name="myckeditor"
          required
          [config]="ckeConfig"
          debounce="500"
        >
        </ckeditor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
     <div class="row justify-content-end">
        <button type="submit" *ngIf="isNoteAdded == false" class="btn loginBtn inner_plug_font_12" (click)="saveTeamViewer()">Save</button>
        <button type="submit" *ngIf="isNoteAdded == true" class="btn loginBtn inner_plug_font_12" (click)="updateTeamViwer()">Update</button>
     </div>
    </div>
  </ng-template>
  <ng-template #notes let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
        <h5 class="modal-title">Note</h5>
    
        <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
          >x</span
        >
      </div>
    <div class="modal-body">
      <div class="container-fluid">
          <div class="row">
              <label for="">Title</label>
            <input
            type="text"
            [(ngModel)]="noteTitle"
            maxlength="100"
            class="form-control login_input position-relative"
            placeholder="Title"
          />
          
          </div>
        <div class="row mt-2">
          <label for="">Message</label>
            <textarea name="" id="" maxlength="500" [(ngModel)]="noteText" class="form-control w-100 login_input" placeholder="Note" cols="30" rows="10"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
     <div class="row justify-content-end">
        <button type="submit" *ngIf="isEdit == false" class="btn loginBtn inner_plug_font_12" (click)="saveNote()">Save</button>
        <button type="submit" *ngIf="isEdit == true" class="btn loginBtn inner_plug_font_12" (click)="updateNote()">Update</button>
     </div>
    </div>
  </ng-template>
 
  <ng-template #notesView let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header" style="border: 0 !important;">
        <h5 class="modal-title">{{noteData?.heading}}</h5>
        <!-- <h6 class="modal-title">{{noteData?.lastUpdatedAt *1000 | date: "MM/dd/yyyy hh:mm a"}}</h6> -->
    
        <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
          >x</span
        >
      </div>
    <div class="modal-body">
      <div class="container-fluid">
          
        <div class="row mt-2">
            <textarea name="" id="" disabled [(ngModel)]="noteData.note" class="form-control w-100 login_input" placeholder="Note" cols="30" rows="10"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer " style="border: 0 !important;">
        <div class="row justify-content-end">
           <button type="submit" class="btn loginBtn inner_plug_font_12" (click)="closeModal()">Close</button>
        </div>
       </div>

  </ng-template>
 
  <ng-template #deletemodal let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
      <h5 class="modal-title">Delete Note</h5>
  
      <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
      </button> -->
      <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
        >x</span
      >
    </div>
  
    <div class="modal-body">
      <div class="row justify-content-center">
        Are you sure you want to delete this Note?
      </div>
      <div class="row justify-content-end m-3">
        <button class="btn cancel_btn mr-3" (click)="deleteNote()" >Delete</button>
      </div>
     
    </div>
  </ng-template>
  <ng-template #restartModal let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
      <h5 class="modal-title">Reboot Device</h5>
  
      <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
      </button> -->
      <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
        >x</span
      >
    </div>
  
    <div class="modal-body">
      <div class="row justify-content-center">
        Are you sure you want to reboot this Device?
      </div>
      <div class="row justify-content-end m-3">
        <button class="btn cancel_btn mr-3" (click)="rebootDevice()" >Confirm</button>
      </div>
     
    </div>
  </ng-template>
  
