import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-howto-sidepanel',
  templateUrl: './howto-sidepanel.component.html',
  styleUrls: ['./howto-sidepanel.component.scss']
})
export class HowtoSidepanelComponent implements OnInit {
  statusList = [
    { name: 'ONLINE', deviceType: 'both',
      description: 'Device is online and available for use.',
      resolve: 'No further actions are required.'},
    { name: 'TESTING', deviceType: 'both',
      description: 'Device is online and in use for automation test.',
      resolve: 'No further actions are required.'},
    { name: 'INITIALIZING', deviceType: 'both',
      description: 'Device is currently in the process of installing Snap components.',
      resolve: 'Please wait for initialization to finish and device to go online.'},
    { name: 'UNINITIALIZED', deviceType: 'both',
      description: 'One of the stages of the initialization process failed.',
      resolve: 'Please click the reset button to begin the process over again. If the issue persists, please visit the “Help” tab to review the initialization process steps and identify the issue.'},
    { name: 'UNAUTHORIZED', deviceType: 'Android',
      description: 'Device required USB authorization.',
      resolve: 'Unlink and relink the device. When presented with the authorization request, please accept.'},
    { name: 'OFFLINE', deviceType: 'Android',
      description: 'ADB communication error.',
      resolve: 'Unlink and relink the device. If the issue persists, please visit the “Help” tab for further information on redeveloping a connection.'},
    { name: 'UNCONFIGURED', deviceType: 'Android, iOS16+',
      description: 'Please enable developer mode in settings.',
      resolve: 'No further actions are required.'},
    { name: 'DISCONNECTED', deviceType: 'both',
      description: 'Device has been physically removed from its connection in the lab.',
      resolve: 'Ask lab admin why device was removed, then re-install connection.'},
    { name: 'DETACHED', deviceType: 'both',
      description: 'Device was detached. Cambrionix USB port is in “Off” state.',
      resolve: 'Reattach the device through the front end “attach” feature (in bottom left corner of screen).'},
  ];

  @Output() toggleSidepanel = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleDrawer(): void {
    this.toggleSidepanel.emit(true);
  }
}
