<div class="main-header px-4 py-2 pt-4">
    
    <div class="d-flex" style="gap: 30px;">
        <span class="compHeader listHeader f_14 cursor" [ngClass]="{nonActivetab : activeTab == 'custom'}" (click)="activeTab = 'device'">Device info</span>
        <span class="compHeader listHeader f_14 cursor" [ngClass]="{nonActivetab : activeTab == 'device'}" (click)="activeTab = 'custom'">Custom Device Properties
            <span class="filterSpan backgroundTheme addButn" (click)="openModal(propertyModal, 'Create device property', 'Create', 'create')"
            *ngIf="activeTab == 'custom'"
            [tp]="tooltipComponent" tpPlacement="bottom" [tpData]="'Create property' | tooltipData">
              <img src="../../../../assets/images/NewUI/add_Icon.svg" alt="" style="width: 15px; object-fit: contain;">
            </span>
        </span>
    </div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="devices-info" *ngIf="activeTab == 'device'">
        
        <app-spinner *ngIf="showLoader == true" [type]="'normal'"></app-spinner>
        <div>
            <span class="infoName">Device Name </span>
            <span class="infoValue">{{
                deviceInfo?.deviceName || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">OEM</span>
            <span class="infoValue">{{
                deviceInfo?.oem || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple'">
            <span class="infoName">UUID</span>
            <span class="infoValue">{{
                deviceInfo?.serialNumber || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem !== 'Apple'">
            <span class="infoName">Serial No</span>
            <span class="infoValue">{{
                deviceInfo?.serialNumber || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">Model Name</span>
            <span class="infoValue">{{
                deviceInfo?.modelName || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">Screen Resolution</span>
            <span class="infoValue">{{
                deviceInfo?.screenWidth || "--"
                }}<span *ngIf="deviceInfo?.screenWidth">px</span> <span *ngIf="deviceInfo?.screenWidth"> X </span>{{
                deviceInfo?.screenHeight
                }}<span *ngIf="deviceInfo?.screenHeight">px</span></span>
        </div>
        <div *ngIf="deviceInfo?.oem !== 'Apple'">
            <span class="infoName">OS Build ID</span>
            <span class="infoValue">{{
                deviceInfo?.osBuildId || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem !== 'Apple'">
            <span class="infoName">Hardware version</span>
            <span class="infoValue">{{
                deviceInfo?.hardwareVersion || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.mdn">
            <span class="infoName">Phone Number</span>
            <span class="infoValue">{{
                deviceInfo?.mdn || "--"
                }}</span>
        </div>
        <!-- <div class="row m-0 pl-2 pr-2 pt-1 pb-1  justify-content-between">
                      <span class="infoName">Wifi</span>
                      <span class="infoValue">{{wifiConnect}}</span>
                  </div> -->

        <div>
            <span class="infoName">Model Number</span>
            <span class="infoValue">{{
                deviceInfo?.modelNumber || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">Serial Number</span>
            <span class="infoValue">{{
                deviceInfo?.serialNumber || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">Bluetooth</span>
            <span class="infoValue">{{
                deviceInfo?.bluetoothMacAddress || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple'">
            <span class="infoName">Model Firmware</span>
            <span class="infoValue">{{
                deviceInfo?.firmwareVersion || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple' && deviceInfo?.iccid">
            <span class="infoName">EID</span>
            <span class="infoValue">{{
                deviceInfo?.iccid || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.providerName">
            <span class="infoName">Carrier</span>
            <span class="infoValue">{{
                deviceInfo?.providerName || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.imei">
            <span class="infoName">IMEI</span>
            <span class="infoValue">{{
                deviceInfo?.imei || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple' && deviceInfo?.imei2">
            <span class="infoName">IMEI2</span>
            <span class="infoValue">{{
                deviceInfo?.imei2 || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple' && deviceInfo?.meid">
            <span class="infoName">MEID</span>
            <span class="infoValue">{{
                deviceInfo?.meid || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple' && deviceInfo?.iccid">
            <span class="infoName">ICCID</span>
            <span class="infoValue">{{
                deviceInfo?.iccid || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem == 'Apple' && deviceInfo?.osVersion">
            <span class="infoName">OS version</span>
            <span class="infoValue">{{
                deviceInfo?.osVersion || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem !== 'Apple' && deviceInfo?.osVersion">
            <span class="infoName">Android Version</span>
            <span class="infoValue">{{
                deviceInfo?.osVersion || "--"
                }}</span>
        </div>
        <div *ngIf="deviceInfo?.signalStrength">
            <span class="infoName">Signal Strength</span>
            <span class="infoValue">{{
                deviceInfo?.signalStrength || "--"
                }}</span>
        </div>
        <div>
            <span class="infoName">Battery Percentage</span>
            <span class="infoValue">{{ battery || "--"  }}</span>
        </div>
        <div>
            <span class="infoName">Storage</span>
            <span class="infoValue">{{ storage || "--" }}</span>
        </div>
        <div *ngIf="deviceInfo?.oem !== 'Apple' && deviceInfo?.networkType">
            <span class="infoName">Network Type</span>
            <span class="infoValue">
                <span *ngIf="deviceInfo?.networkType">{{deviceInfo?.networkType || "--"}}</span>
              </span>
        </div>
        
    </div>
    <div class="devices-info" *ngIf="activeTab == 'custom'">
        
        <div *ngFor="let property of properties">
            <span
                    class="infoName device_info_dynamic_property"
                    [tp]="tooltipComponent"
                    tpPlacement="top"
                    [tpOnlyTextOverflow]="true"
                    [tpData]="property.name | tooltipData"
                    [tpMaxWidth]="400"
                    [tpInteractive]="false"
            >
                {{property.name}}
            </span>
            <div class="property_container">
                <span
                        class="infoValue device_info_dynamic_property device_info_value_dynamic_property"
                        [tp]="tooltipComponent"
                        tpPlacement="top"
                        [tpOnlyTextOverflow]="true"
                        [tpData]="property.value | tooltipData"
                        [tpMaxWidth]="400"
                        [tpInteractive]="false"
                >
                    {{property.value}}
                </span>
                <span class="actionSpan">
                    <img (click)="openModal(propertyModal, 'Change device property', 'Change', 'change', property)"
                    [tp]="tooltipComponent" tpPlacement="top"
                    [tpData]="'Edit' | tooltipData" src="../../../../assets/images/action_icons/edit.svg" alt="" >
                    <img *ngIf="property.category === 'DYNAMIC_FIELDS' || userData.role === 'ADMIN'" (click)="openDeleteDynamicPropertyDialog(property)"
                    [tp]="tooltipComponent" tpPlacement="top"
                    [tpData]="'Delete' | tooltipData" src="../../../../assets/images/action_icons/delete.svg" alt="" >
                </span>
                <!-- <button mat-icon-button class="btn_options_property" [matMenuTriggerFor]="menu" [tp]="tooltipComponent" tpPlacement="bottom" [tpData]="'View more actions' | tooltipData">
                    <span class="menuIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="16"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="menu-btn" (click)="openModal(propertyModal, 'Change device property', 'Change', 'change', property)">
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item class="menu-btn" *ngIf="property.category === 'DYNAMIC_FIELDS' || userData.role === 'ADMIN'" (click)="openDeleteDynamicPropertyDialog(property)">
                        <span>Delete</span>
                    </button>
                </mat-menu> -->
            </div>
        </div>
    </div>
</div>

<ng-template #propertyModal let-modal>
    <div class="modal-body p-0">
        <div class="header-wrapper">
            <span>{{titleModalProperty}}</span>
            <!-- <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
            </svg> -->
            <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" fill="white"/>
            </svg>
        </div>
        <div class="dialog-content dialog_font px-3 pt-3 pb-1">
            <div class="form-group">
                <label class="m-1 float-left formLabel" for="snapSupportContact">Name <span>*</span></label>
                <input type="text" class="form-control fadeIn second inputFields" id="name-property" placeholder="Enter a name" [(ngModel)]="nameProperty"
                       (input)="isNamePristine = false" (ngModelChange)="compareProperty()" (focusin) ="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                       
                       [disabled]="userData.role !== 'ADMIN' && (selectedProperty?.category === 'ADMIN_FIELDS')"/>
                       <!-- <input type="text" formControlName="name" maxlength="50" id="name"
                      class="form-control fadeIn second inputFields" placeholder="Enter Lab Name" /> -->
                <div class="errorinput" *ngIf="!nameProperty.length && !isNamePristine" >* Property name can’t be empty</div>
                <div class="errorinput" *ngIf="isPropExistInProperties && !isNamePristine" >{{propertyWarning}}</div>
            </div>
            <div class="form-group">
                <label class="m-1 float-left formLabel" for="snapSupportContact">Value </label>
                <input type="text" class="form-control fadeIn second inputFields" id="value-property" placeholder="Enter a value" [(ngModel)]="valueProperty"
                       (focusin) ="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"/>
            </div>
        </div>
        <div class="dialog-actions px-3 pb-3 pt-1">
            <button class="filterActionBtn" (click)="closeModal()" >Cancel</button>
            <button class="filterActionBtn nameFilter" [ngClass]="{disabled: !nameProperty.length}" (click)="updateProperty()">{{titleModalButton}}</button>
        </div>
    </div>
</ng-template>
