import {
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input, NgZone,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TooltipService } from '../tooltip.service';
import { FeatureTypeEnum, MtSessionStatusEnum } from '../enum';
import { CommonService } from '../common.service';
import { WebsocketService } from '../../websocket.service';
import { DeviceService } from '../../main/view-device/device.service';
import { Command, ControlStatus } from '../../main/view-device/current-device-new/current-device-new.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs/internal/observable/interval';
import { DialogModalService } from '../dialog-modal.service';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManualTestService } from '../../main/manual-test/manual-test.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-screencast',
  templateUrl: './screencast.component.html',
  styleUrls: ['./screencast.component.scss']
})
export class ScreencastComponent implements OnInit, OnChanges, OnDestroy {
  isLoading = false;
  isPlaying = false;
  bufferQueue = [];
  audioBuffer;
  sampleRate = 44100;
  bitDepth = 16;
  channels = 2;
  source = null;
  isLandscapeMode = false;
  imageContent: SafeResourceUrl;
  showImage = false;
  originalHeight = 0;
  originalWidth = 0;
  stopSwipePropagation = false;
  tooltipComponent = null;
  isBookingAvailable = false;
  isUsbAttach: any;
  isAttachReq: boolean;
  frameWidth = 0;
  frameHeight = 0;
  downX = -1;
  downY = -1;
  upX = -1;
  upY = -1;
  forcedLandscape = false;
  checkOrientationAfterClick = false;
  isDragAndDropMode = false;
  durationDnd = 0;
  holdDuration = 0;
  startDrag = 0;
  endDrag = 0;
  scale = 1;
  lastClickTime = 0;
  clicksCount = 0;
  startDragAndDropTime = 0;
  lastScrollTime = 0;
  typeEventClick = 'click';
  pressDuration = 10;
  isFilechooseOpen = false;
  bluetoothStatus: ControlStatus = 'NA';
  wifiStatus: ControlStatus = 'NA';
  airPlanModeStatus: ControlStatus = 'NA';
  volumeStatus: ControlStatus = 'NA';
  mobileDataStatus: ControlStatus = 'NA';
  wifiConnect = '--';
  battery = '--';
  storage = '--';
  wifiPolling: any;
  previouswifiStatus: ControlStatus = 'NA';
  isWiFiLoading = true;
  isBluetoothLoading = true;
  isMobileDataLoading = true;
  isAirplaneLoading = true;
  isVolumeLoading = true;
  wifiBtntooltip = 'Press to toggle wifi of device';
  bluetoothBtntooltip = 'Press to toggle bluetooth of device';
  mobileDataBtntooltip = 'Press to toggle mobile data of device';
  airplaneBtntooltip = 'Press to toggle airplane mode of device';
  volumeBtntooltip = 'You can change the volume of the device';
  audioStreamingBtntooltip = 'Toggle control to stream device audio to your workstation';
  audioContext: AudioContext | null;
  AudioStreamingStatus = false;
  isManipulationAvailable = false;
  dcMenuOpened = false;
  latestActionResult: any;
  actionResultCount = 0;
  reqQueueWatcher: any;
  inReq = false;
  pressTimer: any;
  requestQueue = [];
  totalByte = 0;
  inputAddress: any;
  lat = 40.730610;
  lng = - 73.935242;
  private modalRef: NgbModalRef;
  isLocationChoose = false;
  selectedLocation = '';
  showSearch = true;
  code: number;
  isDeviceReboot = false;
  isScreenCastStopped = false;
  rebootTimer;
  communicationStatus = '';
  isReqAttach = false;
  communicationStatusSub: any;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  closeResult: string;
  mapOptions: google.maps.MapOptions = {
    zoom: 12
  };
  markerOptions: google.maps.MarkerOptions = { draggable: true };
  markerPosition: google.maps.LatLngLiteral = { lat: this.lat, lng: this.lng };
  mapCenter: google.maps.LatLngLiteral = { lat: this.lat, lng: this.lng };
  getImageSub: Subscription;

  @ViewChild('imgWrapper') imgWrapper: ElementRef;
  @Input() deviceInfo: any;
  @Input() isTestRunning: boolean;
  @Input() isTestRecording: boolean;
  @Input() replayInProgress: boolean;
  @Input() actionToReplay: any;
  @Input() ignoreTyping: boolean;
  @Input() isManualTest = false;
  @Output() selectDeviceUpdate = new EventEmitter<string>();
  @Output() latestAction = new EventEmitter<any>();
  @Output() actionResult = new EventEmitter<any>();

  constructor(private tooltipService: TooltipService,
    private commonService: CommonService,
    private websocketService: WebsocketService,
    private deviceService: DeviceService,
    private _snackBar: MatSnackBar,
    private dialogModalService: DialogModalService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public manualTestService: ManualTestService,
    private ngZone: NgZone) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.isBookingAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
    this.isManipulationAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.DEVICE_MANIPULATION]);
    this.checkDeviceCommStatus();

    if (this.isBookingAvailable) {
      this.reqQueueWatcher = setInterval(() => {
        if (this.requestQueue.length > 0 && !this.inReq) {
          this.sendInputRequest(this.requestQueue[0]);
          this.requestQueue.shift();
        }
      }, 100);
    }

    if (this.deviceInfo) {
      // device has been booked, start screencast
      this.originalHeight = parseInt(this.deviceInfo.screenHeight);
      this.originalWidth = parseInt(this.deviceInfo.screenWidth);
      this.getImageCallWebsocket();
      this.getDeviceLocation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deviceInfo && changes.deviceInfo.currentValue && !changes.deviceInfo.firstChange) {
      // device has been booked, start screencast
      this.originalHeight = parseInt(this.deviceInfo.screenHeight);
      this.originalWidth = parseInt(this.deviceInfo.screenWidth);
      this.getImageCallWebsocket();
      this.getDeviceLocation()
    }

    if (changes.replayInProgress && changes.replayInProgress.currentValue && !changes.replayInProgress.firstChange) {
      if (!this.replayInProgress) {
        this.latestActionResult = null;
        this.actionResultCount = 0;
      }
    }

    if (changes.actionToReplay && changes.actionToReplay.currentValue && !changes.actionToReplay.firstChange) {
      this.replayAction(changes.actionToReplay.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.deviceInfo) {
      if (this.websocketService.socketAudio$) {
        this.audioContext.close();
        this.websocketService.sendMessage(this.websocketService.createCommand('STOP', this.deviceInfo.serialNumber),
          'audio');
        this.websocketService.socketAudio$ = undefined;
        this.AudioStreamingStatus = false;
        this.isPlaying = false;
        this.bufferQueue = [];
      }
      this.websocketService.sendMessage(this.websocketService.createCommand('STOP', this.deviceInfo.serialNumber),
        'screencast');
      if (this.getImageSub) {
        this.getImageSub.unsubscribe();
      }
    }
  }

  replayAction(action: any) {
    if (action.type === 'set location') {
      this.lat = action.latitude;
      this.lng = action.longitude;
      this.geoLocationChange();
    } else if (action.type === 'reset location') {
      this.resetGeoLocation();
    } else {
      this.addToQueue(action.type, action.data);
    }
  }

  addToQueue(type: string, data): void {
    this.requestQueue.push({ type: type, data: data });
  }

  addLatestActionResult(type: string): void {
    this.actionResult.emit(`${this.actionResultCount}-${type}`);
    this.actionResultCount++;
  }

  sendInputRequest(data): void {
    if (this.isTestRecording && !this.replayInProgress) {
      this.latestAction.emit(data);
    }
    if (this.replayInProgress) {
      data.recordAndPlay = true;
    }
    this.inReq = true;
    switch (data.type) {
      case 'adbInput':
        this.adbInput(data);
        break;
      case 'adbValue':
        this.adbValue(data);
        break;
      case 'click':
        this.clickEventCall(data);
        break;
      case 'doubleTap':
        this.doubleClickEventCall(data);
        break;
      case 'tripleTap':
        this.tripleClickEventCall(data);
        break;
      case 'swipe':
        this.swipeEventCall(data);
        break;
      case 'dragNDrop':
        this.dragNDropEventCall(data);
        break;
      case 'pinch':
        this.pinchEventCall(data);
        break;
      case 'home':
        this.homeRequest(data);
        break;
      case 'recent':
        this.recentAppRequest(data);
        break;
      case 'back':
        this.backRequest(data);
        break;
      case 'rotate':
        this.orientationRequest(data);
        break;
      case 'wifi':
        this.changeDeviceControlsMode(data, this.isWiFiLoading, 'Wifi', this.wifiStatus);
        break;
      case 'bluetooth':
        this.changeDeviceControlsMode(data, this.isBluetoothLoading, 'Bluetooth', this.bluetoothStatus);
        break;
      case 'mobile-data':
        this.changeDeviceControlsMode(data, this.isMobileDataLoading, 'Mobile Data', this.mobileDataStatus);
        break;
      case 'airplane':
        this.changeDeviceControlsMode(data, this.isAirplaneLoading, 'Airplane', this.airPlanModeStatus);
        break;
      case 'volume':
        this.changeDeviceControlsMode(data, this.isVolumeLoading, 'Volume', this.volumeStatus);
        break;
      case 'reboot':
        this.rebootRequest(data);
        break;
      case 'power':
        this.powerRequest(data);
        break;
    }
  }

  adbInput(request) {
    this.deviceService.adbInputText(request.data, this.deviceInfo.labDomain).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Adb Input Text';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
      }
    );
  }

  adbValue(request) {
    this.deviceService.adbInputKeyEvent(request.data, this.deviceInfo.labDomain).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Adb Input Value';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
      }
    );
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.code >= 33 && this.code <= 126 && this.ignoreTyping === false) {
      const key = event.key;
      this.code = event.keyCode;
      // disabled key event while open adb Terminal
      const request = {
        'serialNumber': this.deviceInfo.serialNumber,
        'inputText': key
      };
      this.addToQueue('adbInput', request);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent1(event: KeyboardEvent) {
    const key = event.key;
    this.code = event.keyCode;
    let ekeycodevalue = 0;

    if (this.code === 8 || this.code === 46) {
      // backspace
      ekeycodevalue = 67;
    } else if (this.code === 13) {
      // Enter
      ekeycodevalue = 66;
    } else if (this.code === 32) {
      // space bar
      ekeycodevalue = 62;
    } else if (this.code === 37) {
      // left
      ekeycodevalue = 21;
    } else if (this.code === 38) {
      // up
      ekeycodevalue = 19;
    } else if (this.code === 39) {
      // right
      ekeycodevalue = 22;
    } else if (this.code === 40) {
      // bottom
      ekeycodevalue = 20;
    } else if (this.code === 113) {
      // volume low
      ekeycodevalue = 25;
    } else if (this.code === 114) {
      // volume up
      ekeycodevalue = 24;
    } else if (this.code === 27) {
      // power
      ekeycodevalue = 26;
    }
    if (ekeycodevalue !== 0 && this.ignoreTyping === false) {
      const request1 = {
        'serialNumber': this.deviceInfo.serialNumber,
        'value': ekeycodevalue
      };
      this.addToQueue('adbValue', request1);
    }
  }

  swipeEventCall(request) {
    // calculate the number of steps for the swipe
    const pxPerStep = 30; // magic number
    const dX = Math.abs(request.data['startX'] - request.data['endX']);
    const dY = Math.abs(request.data['startY'] - request.data['endY']);
    if (((dX - dY) > 0) && (dX > pxPerStep)) {
      request.data['steps'] = Math.round(dX / pxPerStep);
    } else if (((dX - dY) < 0) && (dY > pxPerStep)) {
      request.data['steps'] = Math.round(dY / pxPerStep);
    } else {
      request.data['steps'] = 1;
    }
    request.data['duration'] = this.pressDuration === 0 ? 10 : this.pressDuration;
    this.deviceService.swipedevice(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Swipe Event';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  dragNDropEventCall(request) {
    const dX = Math.abs(request.data['startX'] - request.data['endX']);
    const dY = Math.abs(request.data['startY'] - request.data['endY']);
    const distance = Math.sqrt(dX * dX + dY * dY);
    const endTime = new Date().getTime();
    const elapsedTime = endTime - this.startDragAndDropTime;
    const velocity = Math.round(distance / (elapsedTime / 1000));
    const requestDnd = {
      serialNumber: request.data.serialNumber,
      startX: request.data.startX,
      startY: request.data.startY,
      endX: request.data.endX,
      endY: request.data.endY,
      duration: this.durationDnd / 1000,
      velocity: velocity,
      holdDuration: this.holdDuration / 1000
    };

    this.deviceService.dragNDrop(requestDnd, this.deviceInfo['labDomain']).subscribe(
      (res) => {
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      });
    this.isDragAndDropMode = false;
    this.durationDnd = 0;
    this.holdDuration = 0;
    this.inReq = false;
  }

  pinchEventCall(request) {
    this.deviceService.pinch(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      });
    this.inReq = false;
  }

  homeRequest(request: any): void {
    this.deviceService.HomeCalled(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Home Event';
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (this.deviceInfo['oem'] !== 'Apple' && this.isLandscapeMode) {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  recentAppRequest(request): void {
    this.deviceService.RecentCalled(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
      }
    );
  }

  backRequest(request): void {
    this.deviceService.BackCalled(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (this.deviceInfo['oem'] !== 'Apple' && this.isLandscapeMode) {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
      }
    );
  }

  orientationRequest(request): void {
    this.deviceService.landscape(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        if (res) {
          if (res.tool_Result === 'Pass') {
            this.isLandscapeMode = request.data.mode === 'right';
            this.forcedLandscape = false;
            if (request.recordAndPlay) {
              this.addLatestActionResult('success');
            }
          } else if (res.tool_Result === 'Fail') {
            // the request is fine, but the current application on the device can't be rotated to the landscape mode
            if (res.tool_Extra.toLowerCase() === 'failed to rotate screen to right mode') {
              this.isLandscapeMode = true;
              this.forcedLandscape = true;
              if (request.recordAndPlay) {
                this.addLatestActionResult('success');
              }
            } else {
              if (request.recordAndPlay) {
                this.addLatestActionResult('Failed');
              }
            }
          }
        }
        this.inReq = false;
      },
      (err) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  changeDeviceControlsMode(request, loading: boolean, control: string, status: ControlStatus) {
    this.isLoading = true;
    this.switchLoading(control, true);

    this.deviceService.changeDeviceControlsMode(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber, request.data).subscribe(
      (res) => {
        this.isLoading = false;
        this.switchLoading(control, false);
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (res.tool_Exception.trim() === 'In Valid Serial Number') {
          this._snackBar.open('Device is Offline', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success'],
          });
          return;
        }
        if (res.tool_Result === 'Fail') {
          this._snackBar.open(`${res.tool_Extra}`, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
          });
          return;
        }
        if (res.tool_Result === 'Pass') {
          this.updateDeviceControlsMode(res.tool_Extra.controls);
        }
        if (control === 'Wifi') {
          if (res.tool_Result === 'Fail') {
            if (this.deviceInfo['oem'] === 'Apple') {
              this.wifiPollingStart();
            }
          }
        }
      },
      err => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.isLoading = false;
      }
    );
  }

  wifiPollingStart(): void {
    this.isLoading = true;
    const wifiRequestTime = Date.now();

    this.wifiPolling = interval(3000).subscribe((val) => {
      const currentTime = Date.now();
      if (this.wifiStatus !== this.previouswifiStatus) {
        const request = {
          'serialNumber': this.deviceInfo.serialNumber,
          'screenShot': false
        };
        this.deviceService.getDeviceDetails(request).subscribe((res) => {
          const data = res.body.data;
          if (data['controls']) {
            data['controls'].forEach(element => {
              if (element['name'] === 'WiFi') {
                this.previouswifiStatus = element['status'];
              }
            });
          }
        }, err => {
          this.isLoading = false;
        });
        // If we do not get same status for 25 seconds then we stop the polling
        if (((currentTime - wifiRequestTime) / 1000) > 25) {
          this.isLoading = false;
          this.wifiStatus = this.previouswifiStatus;
          if (this.wifiPolling) {
            this.wifiPolling.unsubscribe();
          }
        }
      } else {
        this.isLoading = false;
        if (this.wifiPolling) {
          this.wifiPolling.unsubscribe();
        }
      }
    });
  }

  switchLoading(control: string, state: boolean) {
    switch (control) {
      case 'Bluetooth':
        this.isBluetoothLoading = state;
        break;
      case 'Airplane':
        this.isAirplaneLoading = state;
        break;
      case 'Volume':
        this.isVolumeLoading = state;
        break;
      case 'Mobile Data':
        this.isMobileDataLoading = state;
        break;
      case 'Wifi':
        this.isWiFiLoading = state;
        break;
    }
  }

  swipeOutsideImg($event) {
    if (!this.stopSwipePropagation) {
      if (this.pressTimer) {
        clearInterval(this.pressTimer);
      }
      const landscapeMode = this.isLandscapeMode;
      const rotated = landscapeMode && this.originalWidth < this.originalHeight;
      const eventUpX = $event.offsetX;
      const eventUpY = rotated ? $event.clientY : $event.offsetY;
      const outsideWidth = this.imgWrapper.nativeElement.offsetWidth;
      const outsideHeight = this.imgWrapper.nativeElement.offsetHeight;
      const height = landscapeMode ? this.frameWidth : this.frameHeight;
      const width = landscapeMode ? this.frameHeight : this.frameWidth;
      const offsetLeft = (outsideWidth - width) / 2;
      const topOffset = (outsideHeight - height) / 2;
      const bottomOffset = topOffset + height;

      if (this.downX !== -1) {
        // calculate specific for the swipe ending outside device window
        if (eventUpX < offsetLeft) {
          // swipe to the left
          this.upX = rotated ? this.downX : 1;
          this.upY = rotated ? width : eventUpY - topOffset;
        } else if (eventUpX > width + offsetLeft) {
          // swipe to the right
          this.upX = rotated ? this.downX : width;
          this.upY = rotated ? 1 : eventUpY - topOffset;
        } else if (eventUpY < topOffset) {
          // swipe to the top
          this.upX = rotated ? 1 : eventUpX - offsetLeft;
          this.upY = rotated ? this.downY : 1;
        } else if (eventUpY > bottomOffset) {
          // swipe to the bottom
          this.upX = rotated ? height : eventUpX - offsetLeft;
          this.upY = rotated ? this.downY : height;
        }

        const requestMapSwipe = {};
        const result = this.getOriginalCoordinates();

        requestMapSwipe['serialNumber'] = this.deviceInfo.serialNumber;
        requestMapSwipe['startX'] = result.modifiedDownX;
        requestMapSwipe['startY'] = result.modifiedDownY;
        requestMapSwipe['endX'] = result.modifiedUpX;
        requestMapSwipe['endY'] = result.modifiedUpY;

        this.addToQueue('swipe', requestMapSwipe);
      }
    } else {
      this.stopSwipePropagation = false;
    }
  }

  clickEventCall(request) {
    this.deviceService.clickXYCoordinate(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        if (this.isLandscapeMode && this.deviceInfo['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
      }
    );
  }

  doubleClickEventCall(request) {
    this.deviceService.doubleTap(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'success' : 'error');
        }
        if (this.isLandscapeMode && this.deviceInfo['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('error');
        }
        this.inReq = false;
      }
    );
  }

  tripleClickEventCall(request) {
    this.deviceService.tripleTap(request.data, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        res['date'] = new Date();
        res['type'] = 'Click Event';
        res['cordinate'] = 'X=' + request['xAxis'] + ', Y=' + request['yAxis'];
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'success' : 'error');
        }
        if (this.isLandscapeMode && this.deviceInfo['oem'] !== 'Apple') {
          this.checkOrientationAfterClick = true;
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('error');
        }
        this.inReq = false;
      }
    );
  }

  getOriginalCoordinates() {
    let heightRatio: any;
    let widthRatio: any;

    if (this.originalWidth > this.originalHeight) {
      if (this.deviceInfo.oem !== 'Apple') {
        const ratio = parseFloat((this.originalWidth / this.originalHeight).toFixed(2));
        heightRatio = this.originalHeight / this.frameWidth;
        widthRatio = this.originalWidth / (this.frameWidth * ratio);
      } else {
        const ratio = parseFloat((this.originalWidth / this.originalHeight).toFixed(2));
        heightRatio = (this.originalHeight / this.frameWidth) * ratio;
        widthRatio = (this.originalWidth / (this.frameWidth * ratio)) * ratio;
      }
    } else {
      const ratio = parseFloat((this.originalHeight / this.originalWidth).toFixed(2));
      widthRatio = this.originalWidth / this.frameWidth;
      heightRatio = this.originalHeight / (this.frameWidth * ratio);
    }

    const modifiedUpX = this.upX * widthRatio;
    const modifiedUpY = this.upY * heightRatio;
    const modifiedDownX = this.downX * widthRatio;
    const modifiedDownY = this.downY * heightRatio;
    return { modifiedUpX, modifiedUpY, modifiedDownX, modifiedDownY };
  }

  processImage($event) {
    const prevWidth = this.originalWidth;
    let eventPath;
    if ($event.path) {
      eventPath = $event.path;
    } else {
      eventPath = $event.composedPath();
    }

    const newHeight = parseInt(eventPath[0].naturalHeight);
    const newWidth = parseInt(eventPath[0].naturalWidth);
    // if image orientation changed swap original resolution
    if ((newWidth > newHeight && this.originalWidth < this.originalHeight)
      || (newHeight > newWidth && this.originalHeight < this.originalWidth)) {
      this.originalWidth = this.originalHeight;
      this.originalHeight = prevWidth;
    }

    if (this.deviceInfo['deviceCommunicationStatus'] === 'remote-testing') {
      this.isLandscapeMode = this.originalWidth > this.originalHeight;
    } else {
      if (this.isLandscapeMode && this.checkOrientationAfterClick) {
        this.deviceService.getOrientationMode(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber)
          .subscribe((orientationResult) => {
            this.checkOrientationAfterClick = false;
            if (orientationResult && (orientationResult.tool_Result === 'Pass')) {
              if (!(this.forcedLandscape && orientationResult.tool_Extra === 'portrait')) {
                this.isLandscapeMode = (orientationResult.tool_Extra === 'landscape');
              }
            }
            this.getImageHeight();
          }, (err) => {
            this.checkOrientationAfterClick = false;
          });
      } else {
        if (!this.isLandscapeMode && this.originalHeight < this.originalWidth && (prevWidth !== this.originalWidth)) {
          this.deviceService.getOrientationMode(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber)
            .subscribe((orientationResult) => {
              if (orientationResult && (orientationResult.tool_Result === 'Pass')) {
                if (orientationResult.tool_Extra === 'portrait') {
                  if (this.deviceInfo['oem'].toLowerCase() === 'apple') {
                    this.isLandscapeMode = true;
                  } else {
                    this.isLandscapeMode = (orientationResult.tool_Extra === 'landscape');
                  }
                } else {
                  this.isLandscapeMode = (orientationResult.tool_Extra === 'landscape');
                }
              }
              this.getImageHeight();
            }, (err) => { });
        } else {
          if (this.forcedLandscape && this.originalHeight < this.originalWidth) {
            this.forcedLandscape = false;
          }
          this.getImageHeight();
        }
      }
    }
  }

  getImageHeight() {
    if (!this.isLandscapeMode) {
      const img = document.getElementById('framePort');
      if (img) {
        this.frameHeight = img.clientHeight;
        this.frameWidth = img.clientWidth;
      }
    } else {
      const img = document.getElementById('frameLand');
      if (img) {
        if (this.originalHeight > this.originalWidth) {
          this.frameHeight = img.clientHeight;
          this.frameWidth = img.clientWidth;
        } else {
          this.frameHeight = img.clientWidth;
          this.frameWidth = img.clientHeight;
        }
      }
    }
  }

  mouseDown($event) {
    this.isDragAndDropMode = false;
    this.lastClickTime = $event.timeStamp;
    this.startDrag = $event.timeStamp;
    this.startDragAndDropTime = new Date().getTime();
    this.isFilechooseOpen = false;
    if (this.pressTimer) {
      clearInterval(this.pressTimer);
    }
    this.pressDuration = 10;
    this.pressTimer = setInterval(() => {
      this.pressDuration += 10;
    }, 10);
    this.downX = $event['offsetX'];
    this.downY = $event['offsetY'];
  }

  mouseUp($event) {
    if (this.downX !== -1 && this.downY !== -1) {
      if (this.isDragAndDropMode) {
        this.holdDuration = Math.round($event.timeStamp - this.endDrag);
      }
      if (this.pressTimer) {
        clearInterval(this.pressTimer);
      }
      this.stopSwipePropagation = true;
      if (this.deviceInfo.oem !== 'Apple') {
        this.prepareToAddToQueue($event);
      } else {
        const now = $event.timeStamp;
        if (now - this.lastClickTime < 300 && this.clicksCount === 2) {
          this.typeEventClick = 'tripleTap';
          this.prepareToAddToQueue($event);
          this.clicksCount = 0;
        } else if (now - this.lastClickTime < 300 && this.clicksCount === 1) {
          this.clicksCount++;
          setTimeout(() => {
            if (this.clicksCount === 2) {
              this.typeEventClick = 'doubleTap';
              this.prepareToAddToQueue($event);
              this.clicksCount = 0;
            }
          }, 300);
        } else {
          this.clicksCount++;
          setTimeout(() => {
            if (this.clicksCount === 1) {
              this.clicksCount = 0;
              this.prepareToAddToQueue($event);
            }
          }, 300);
        }
      }
    }
  }

  prepareToAddToQueue(event): void {
    this.upX = event['offsetX'];
    this.upY = event['offsetY'];

    const { modifiedUpX, modifiedUpY, modifiedDownX, modifiedDownY } = this.getOriginalCoordinates();
    if (this.isFilechooseOpen) {
      return;
    }
    if (Math.abs(this.downX - this.upX) <= 3 && Math.abs(this.downY - this.upY) <= 3) {
      const requestMapClick = {};
      requestMapClick['serialNumber'] = this.deviceInfo.serialNumber;
      requestMapClick['duration'] = this.pressDuration === 0 ? 10 : this.pressDuration;
      requestMapClick['xAxis'] = modifiedUpX;
      requestMapClick['yAxis'] = modifiedUpY;
      this.addToQueue(this.typeEventClick, requestMapClick);
    } else {
      const requestMapSwipe = {};
      requestMapSwipe['serialNumber'] = this.deviceInfo.serialNumber;
      requestMapSwipe['startX'] = modifiedDownX;
      requestMapSwipe['startY'] = modifiedDownY;
      requestMapSwipe['endX'] = modifiedUpX;
      requestMapSwipe['endY'] = modifiedUpY;

      this.typeEventClick = this.isDragAndDropMode ? 'dragNDrop' : 'swipe';
      this.addToQueue(this.typeEventClick, requestMapSwipe);
    }
    this.typeEventClick = 'click';
    this.downX = -1;
    this.downY = -1;
    this.holdDuration = 0;
  }

  mousemove($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.deviceInfo.oem !== 'Apple') { return; }
    if (this.pressDuration > 500) {
      this.isDragAndDropMode = true;
    }
    if (!this.isDragAndDropMode) { return; }
    this.endDrag = event.timeStamp;
    if (this.startDrag) {
      this.durationDnd = Math.round(event.timeStamp - this.startDrag);
      this.startDrag = 0;
    }
  }

  onMouseWheel($event: WheelEvent) {
    if (this.deviceInfo.oem !== 'Apple') { return; }

    this.lastScrollTime = new Date().getTime();
    const latestScrollTime = this.lastScrollTime;

    setTimeout(() => {
      const scaleChange = 0.1;
      if ($event.deltaY < 0) {
        if (this.scale < 1) {
          this.scale = 1;
        }
        this.scale += scaleChange;
      } else {
        if (this.scale > 1) {
          this.scale = 1;
        }
        this.scale -= scaleChange;
      }
      if (this.lastScrollTime === latestScrollTime) {
        this.scale = Math.min(Math.max(this.scale, 0.3), 3);
        this.scale = parseFloat(this.scale.toFixed(1));

        this.upX = $event.offsetX;
        this.upY = $event.offsetY;

        const result = this.getOriginalCoordinates();
        const request = {
          serialNumber: this.deviceInfo.serialNumber,
          xAxis: result.modifiedUpX,
          yAxis: result.modifiedUpY,
          scale: this.scale,
          velocity: this.scale > 1 ? 3 : -3,
        };
        this.addToQueue('pinch', request);
      }
    }, 100);
  }

  releaseDevice() {
    this.websocketService.sendMessage(this.websocketService.createCommand('STOP',
      this.deviceInfo.serialNumber), 'screencast');
    this.selectDeviceUpdate.emit('release');
    this.deviceInfo = null;
  }

  changeSelectDeviceMode() {
    this.selectDeviceUpdate.emit('select');
  }

  HomeCall() {
    const request = {
      'serialNumber': this.deviceInfo.serialNumber
    };
    this.addToQueue('home', request);
  }

  RecentCall() {
    const request = {
      'serialNumber': this.deviceInfo.serialNumber
    };
    this.addToQueue('recent', request);
  }

  landscapeCall() {
    let mode = 'natural';

    if (this.isLandscapeMode === false) {
      mode = 'right';
    }

    const request = {
      'serialNumber': this.deviceInfo.serialNumber,
      'mode': mode,
      'screenShot': false
    };
    this.addToQueue('rotate', request);
  }

  changeDeviceControlsPanelVisibility() {
    if (this.deviceInfo.oem === 'Apple') {
      this.deviceService.changeDeviceControlsPanelVisibility(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber, 'CLOSE')
        .subscribe((res) => { });
    }
  }

  BackCall() {
    const request = {
      'serialNumber': this.deviceInfo.serialNumber
    };
    this.addToQueue('back', request);
  }

  getDeviceControlsMode() {
    this.deviceService.getDeviceControlsMode(this.deviceInfo.serialNumber, this.deviceInfo['labDomain']).subscribe(
      (res) => {
        if (res.tool_Result === 'Pass') {
          this.updateDeviceControlsMode(res.tool_Extra.controls);
          this.isAirplaneLoading = false;
          this.isVolumeLoading = false;
          this.isBluetoothLoading = false;
          this.isMobileDataLoading = false;
          this.isWiFiLoading = false;
        }
        if (res.tool_Result === 'Fail') {
          this._snackBar.open(`${res.tool_Extra}`, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
          });
          return;
        }
      });
  }

  updateDeviceControlsMode(arr) {
    arr.map((control) => {
      switch (control.name) {
        case 'BLUETOOTH':
          this.bluetoothStatus = control.mode;
          break;
        case 'AIRPLANE':
          this.airPlanModeStatus = control.mode;
          break;
        case 'VOLUME':
          this.volumeStatus = control.mode;
          break;
        case 'MOBILE_DATA':
          this.mobileDataStatus = control.mode;
          break;
        case 'WIFI':
          this.wifiStatus = control.mode;
          if (this.deviceInfo['oem'] === 'Apple') {
            if (control.mode === 'OFF' || control.mode === 'NA') {
              this.wifiConnect = 'Not Connected';
            } else {
              this.wifiConnect = 'Connected';
            }
          }
          break;
      }
    });
  }

  WifiAPI($event) {
    $event.stopPropagation();
    if (this.wifiStatus === 'NA') { return; }
    this.addToQueue('wifi', this.generateControlRequest('WIFI', this.wifiStatus));
  }

  generateControlRequest(name: string, status: ControlStatus) {
    return {
      'controls': [
        {
          'name': name,
          'mode': status === 'OFF' ? 'ON' : 'OFF',
        }
      ]
    };
  }

  BluetoothAPI($event: MouseEvent) {
    $event.stopPropagation();

    if (
      this.bluetoothStatus === 'NA') {
      return;
    }

    this.addToQueue('bluetooth', this.generateControlRequest('BLUETOOTH',
      this.bluetoothStatus
    ));
  }

  MobileDataAPI($event: MouseEvent) {
    $event.stopPropagation();
    if (this.mobileDataStatus === 'NA') { return; }
    this.addToQueue('mobile-data', this.generateControlRequest('MOBILE_DATA', this.mobileDataStatus));
  }

  AirPlaneModeSwitcher($event: MouseEvent) {
    if (this.deviceInfo.oem === 'Apple' && parseFloat(this.deviceInfo.osVersion) >= 15) {
      if (this.airPlanModeStatus === 'OFF') {
        return this.openIosAirplaneWarningModal($event);
      }
    }
    this.AirPlanModeAPI(event);
  }

  openIosAirplaneWarningModal(event?: Event) {
    this.dialogModalService.openConfirmationDialog('iosAirplaneWarning', () => this.AirPlanModeAPI(event));
  }

  AirPlanModeAPI(event?: Event) {
    event.stopPropagation();
    if (this.airPlanModeStatus === 'NA') { return; }
    this.addToQueue('airplane', this.generateControlRequest('AIRPLANE', this.airPlanModeStatus));
  }

  VolumenAPI($event: MouseEvent) {
    $event.stopPropagation();
    if (this.volumeStatus === 'NA') { return; }
    this.addToQueue('volume',
      this.generateControlRequest('VOLUME',
        this.volumeStatus)
    );
  }

  startStopAudio($event: MouseEvent) {
    $event.stopPropagation();
    if (this.websocketService.socketAudio$) {
      this.audioContext.close();
      this.websocketService.sendMessage(this.websocketService.createCommand('STOP', this.deviceInfo.serialNumber), 'audio');
      this.websocketService.socketAudio$ = undefined;
      this.AudioStreamingStatus = false;
      this.isPlaying = false;
      this.bufferQueue = [];
    } else {
      this.getAudio();
    }
  }

  getAudio(): void {
    this.isPlaying = false;
    this.AudioStreamingStatus = true;
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const bufferSize = 60;
    let chunks = [];
    if (!this.audioBuffer) {
      this.audioBuffer = this.audioContext.createBuffer(
        this.channels,
        this.sampleRate * 10,
        this.sampleRate,
      );
    }

    this.websocketService.connect(this.deviceInfo['labDomain'],
      this.websocketService.createCommand('START', this.deviceInfo.serialNumber), 'audio').subscribe(
        (res: any) => {
          if (this.router.url !== '/snap/rnp-new-test') {
            return;
          }
          chunks.push(res);
          if (chunks.length > bufferSize) {
            this.playAudio(chunks);
            chunks = [];
          }
        }
      );
  }

  playAudio(chunks) {
    const data = new Uint8Array(chunks.reduce((acc, chunk) => [...acc, ...new Uint8Array(chunk)], []));
    const wavFile = new ArrayBuffer(44 + data.length);
    const view = new DataView(wavFile);
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + data.length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, this.channels, true);
    view.setUint32(24, this.sampleRate, true);
    view.setUint32(28, this.sampleRate * this.channels * this.bitDepth / 8, true);
    view.setUint16(32, this.channels * this.bitDepth / 8, true);
    view.setUint16(34, this.bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, data.length, true);
    const pcmView = new DataView(data.buffer);
    for (let i = 0; i < data.length; i++) {
      view.setInt8(44 + i, pcmView.getInt8(i));
    }

    function writeString(view1, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view1.setUint8(offset + i, string.charCodeAt(i));
      }
    }
    const audioFile = new Blob([view], { type: 'audio/wav' });
    audioFile.arrayBuffer().then((fil) => {
      this.audioContext.decodeAudioData(fil).then((buffer) => {
        this.bufferQueue.push(buffer);
        if (!this.isPlaying) {
          this.playNextBuffer();
        }
      });
    });
  }

  playNextBuffer() {
    if (!this.bufferQueue.length) {
      this.isPlaying = false;
      return;
    }

    const buffer = this.bufferQueue.shift();
    const newSource = this.audioContext.createBufferSource();
    newSource.buffer = buffer;
    newSource.connect(this.audioContext.destination);
    newSource.onended = () => {
      this.playNextBuffer();
    };
    newSource.start(0);
    this.isPlaying = true;
  }

  getImageCallWebsocket(): void {
    const socketState = this.websocketService.isWebSocketScreencastConnected(this.deviceInfo.serialNumber);
    if (socketState !== 0 && socketState !== 1) {
      this.getImageSub = this.websocketService.connect(this.deviceInfo.labDomain,
        this.websocketService.createCommand('START', this.deviceInfo.serialNumber), 'screencast').subscribe(
          (res: Blob) => {
            if (res) {
              this.totalByte = res.size;
              if (this.imageContent) {
                URL.revokeObjectURL(this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.imageContent));
              }
              const url = URL.createObjectURL(res);
              this.imageContent = this.sanitizer.bypassSecurityTrustResourceUrl(url);

              this.showImage = true;
              this.isAttachReq = false;
              if (this.deviceInfo && this.deviceInfo.serialNumber) {
                this.websocketService.sendMessage({
                  command: 'SEND_IMAGE',
                  sn: this.deviceInfo.serialNumber
                }, 'screencast');
              }
            }
          }, (error: any) => {
            this.showImage = false;
            setTimeout(() => {
              this.getImageCallWebsocket();
            }, 1000);
          }, () => {
            this.showImage = false;
          }
        );
    }
  }

  openGeoModal(content) {
    this.isLocationChoose = false;
    this.inputAddress = '';
    const modelOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    this.showSearch = false;
    setTimeout(() => {
      /** spinner ends after 1 seconds */
      this.showSearch = true;
      this.SearchGoogle();
    }, 2000);
    this.modalRef = this.modalService.open(content, modelOptions);
    this.modalRef.result.then(
      result => { },
      reason => { }
    );
  }

  geoLocationChange(): void {
    const param = 'latitude=' + this.lat + '&longitude=' + this.lng;
    const rnpData = {
      serialNumber: this.deviceInfo.serialNumber,
      type: 'set location',
      latitude: 0,
      longitude: 0,
      recordAndPlay: false
    };
    if (this.isTestRecording) {
      rnpData.latitude = this.lat;
      rnpData.longitude = this.lng;
      if (!this.replayInProgress) {
        this.latestAction.emit(rnpData);
      }
    }
    if (this.replayInProgress) {
      rnpData.recordAndPlay = true;
    }
    this.deviceService.geoLocation(param, this.deviceInfo['labDomain'], this.deviceInfo.serialNumber).subscribe(
      (res) => {
        this.closeModal();
        if (rnpData.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        this._snackBar.open('Location Changed Successfully ', '', {
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['success'],
        });
      },
      err => {
        if (rnpData.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  resetGeoLocation(): void {
    const rnpData = {
      serialNumber: this.deviceInfo.serialNumber,
      type: 'reset location',
      recordAndPlay: false
    };
    if (this.isTestRecording && !this.replayInProgress) {
      this.latestAction.emit(rnpData);
    }
    if (this.replayInProgress) {
      rnpData.recordAndPlay = true;
    }
    this.deviceService.resetgeoLocation(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber).subscribe(
      (res) => {
        this.closeModal();
        if (rnpData.recordAndPlay) {
          this.addLatestActionResult(res.tool_Result === 'Pass' ? 'Pass' : 'Failed');
        }
        this._snackBar.open('Location Reset Successfully ', '', {
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['success'],
        });
        this.getDeviceLocation();
      },
      err => {
        if (rnpData.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
      }
    );
  }

  SearchGoogle(): void {
    const inputAddress = document.getElementById('search') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(inputAddress);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        this.markerPosition = { lat: this.lat, lng: this.lng };
        this.mapCenter = { lat: this.lat, lng: this.lng };
        this.inputAddress = place.formatted_address;
        this.isLocationChoose = true;
        this.selectedLocation = place.formatted_address;
      });
    });
  }

  updateMarker($event): void {
    this.markerPosition = JSON.parse(JSON.stringify($event.latLng));
    this.lat = this.markerPosition.lat;
    this.lng = this.markerPosition.lng;
    this.displayLocationNew($event.latLng);
    setTimeout(() => {
      this.updateAddress();
    }, 1000);
  }

  markerDragEnd($event): void {
    this.displayLocationNew($event.latLng);
    this.isLocationChoose = true;
    setTimeout(() => {
      this.updateAddress();
    }, 1000);
  }

  updateAddress(): void {
    const location = localStorage.getItem('Address');
    this.inputAddress = location;
    this.selectedLocation = location;
    this.isLocationChoose = true;
  }

  displayLocationNew(latLng): void {
    let geocoder;
    geocoder = new google.maps.Geocoder();
    this.isLocationChoose = true;
    geocoder.geocode(
      { 'latLng': latLng },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            localStorage.setItem('Address', results[0]['formatted_address']);
            return results[0]['formatted_address'];
          }
        }
      });
  }

  locationType(event: any): void {
    this.isLocationChoose = false;
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  CaptureImage() {
    if (this.showImage) {
      const audio = new Audio();
      audio.src = '/assets/sounds/camera-shutter-click-01.wav';
      audio.load();
      audio.play();
      setTimeout(() => {
        const linkSource = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.imageContent);
        const downloadLink = document.createElement('a');
        const currentdate = new Date();
        const datetime = currentdate.getDate() + '/'
          + (currentdate.getMonth() + 1) + '/'
          + currentdate.getFullYear() + '_'
          + currentdate.getHours() + ':'
          + currentdate.getMinutes() + ':'
          + currentdate.getSeconds();
        const fileName = 'screenshot_' + this.deviceInfo.serialNumber + '_' + datetime + '.png';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, 1000);
    } else {
      this._snackBar.open('Device is offline', '', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['danger'],
      });
    }
  }

  openRebootModal() {
    if (this.manualTestService.mtSessionStatus !== MtSessionStatusEnum.ONGOING) {
      this.dialogModalService.openConfirmationDialog('rebootModal', () => this.powerOff());
    }
  }

  powerOff(): void {
    const request = { 'serialNumber': this.deviceInfo.serialNumber };
    this.addToQueue('reboot', request);
  }

  rebootRequest(request): void {
    this.reboot(request.data);
  }

  reboot(request): void {
    this.isLoading = true;
    this.deviceService.reboothAnd(request).subscribe(
      (res) => {
        this.inReq = false;
        if (request.recordAndPlay) {
          this.addLatestActionResult(res.statusCode === 200 ? 'success' : 'error');
        }
        if (res.body['data']['tool_Exception'].trim() === 'In Valid Serial Number') {
          this._snackBar.open('Device is Offline', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success'],
          });
          return;
        }
        if (res.body.data.tool_Result === 'Pass') {
          this.isDeviceReboot = true;
          if (this.websocketService.socketImageMap$) {
            this.websocketService.sendMessage(this.createCommand('STOP'), 'screencast');
            this.isScreenCastStopped = true;
          }
          if (this.websocketService.socketAudio$) {
            this.websocketService.sendMessage(this.createCommand('STOP'), 'audio');
            this.websocketService.socketAudio$ = undefined;
            this.AudioStreamingStatus = false;
          }
          setTimeout(() => {
            this.rebootTimer = setInterval(() => this.checkPowerOn(), 3000);
          }, 60000);
          this._snackBar.open('Phone reboot is successful', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success'],
          });
        } else {
          this._snackBar.open(res.body['data']['tool_Extra'], '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
          });
        }
      },
      err => {
        if (request.recordAndPlay) {
          this.addLatestActionResult('Failed');
        }
        this.inReq = false;
        this.isLoading = false;
        this.openErrorToast(err);
      }
    );
  }
  private createCommand(action: 'START' | 'STOP'): Command {
    return {
      command: action,
      sn: this.deviceInfo.serialNumber
    };
  }

  checkPowerOn() {
    if (!this.deviceInfo.serialNumber) {
      return;
    }
    const request = {
      'serialNumber': this.deviceInfo.serialNumber,
      'screenShot': false
    };
    this.deviceService.getDeviceDetails(request).subscribe(
      (res) => {
        if (res.body.data.tool_Extra.deviceStateCode === 'ONLINE' && this.isDeviceReboot) {
          clearInterval(this.rebootTimer);
          this.getImageCallWebsocket();
          this.isDeviceReboot = false;
          this.isLoading = false;
        }
      });
  }

  openErrorToast(err: any): void {
    let msg = 'Error response has been received from the server. Please try again later.';
    if (err.error && err.error.data && err.error.data.message) {
      msg = err.error.data.message;
    }
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['danger'],
    });
  }

  getAttachTooltip() {
    if (!this.isManipulationAvailable) {
      return 'Unavailable. Your subscription plan must include Device manipulation feature.';
    }

    if (this.deviceService.checkSmartHubFeature(this.deviceInfo)) {
      return this.isUsbAttach ? 'Detach device' : 'Attach device';
    }

    if (this.deviceInfo?.usbHubType && this.deviceInfo?.usbHubType === 'GENERAL' && this.deviceInfo?.isSmartHubEnabled) {
      return 'Feature is not available for this device';
    }

    return 'Feature is not available in this lab';
  }

  usbDetachApi(): void {
    if (this.communicationStatus === 'READY' && !this.isReqAttach && this.manualTestService.mtSessionStatus !== 0) {
      this.isLoading = true;
      this.isReqAttach = true;
      this.deviceService.usbDetach(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber).subscribe((res) => {
        this.StopService();
        this._snackBar.open('Device has been successfully detached.', '', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['success'],
        });
        this.isLoading = false;
        this.isReqAttach = false;
        this.isUsbAttach = !this.isUsbAttach;
        this.websocketService.sendMessage(this.createCommand('STOP'), 'screencast');
        this.isScreenCastStopped = true;
        if (this.websocketService.socketAudio$) {
          this.websocketService.sendMessage(this.createCommand('STOP'), 'audio');
          this.websocketService.socketAudio$ = undefined;
          this.AudioStreamingStatus = false;
        }
      }, err => {
        this.isReqAttach = false;
        this.isLoading = false;
      });
    }
  }

  StopService() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  usbAttachApi(): void {
    if (this.communicationStatus === 'READY' || !this.isUsbAttach) {
      this.isLoading = true;
      this.isReqAttach = true;
      this.deviceService.usbAttach(this.deviceInfo['labDomain'], this.deviceInfo.serialNumber).subscribe((res) => {
        this._snackBar.open('Device has been successfully attached.', '', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['success'],
        });
        this.isLoading = false;
        this.isReqAttach = false;
        this.isAttachReq = true;
        this.isUsbAttach = !this.isUsbAttach;
        setTimeout(() => {
          this.getImageCallWebsocket();
        }, 10000);
      }, err => {
        this.isLoading = false;
        this.isReqAttach = false;
      });
    }
  }

  disableAttachDetach(): boolean {
    return !!(!this.isManipulationAvailable
      || !this.deviceService.checkSmartHubFeature(this.deviceInfo)
      || this.isAttachReq || this.isReqAttach // device is in process of attaching or detaching
      || (this.communicationStatus !== 'READY' && this.communicationStatus.length) // status has value other that ready and not empty
      || this.manualTestService.mtSessionStatus === 0); // manual test is in progress;
  }

  checkDeviceCommStatus(): void {
    if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
    this.communicationStatusSub = interval(3000).subscribe((val) => {
      if (!this.deviceInfo) {
        return;
      }
      this.isUsbAttach = this.deviceInfo.deviceStateCode !== 'DETACHED';
      this.deviceService.checkCommStatus(this.deviceInfo.labDomain, this.deviceInfo.serialNumber).subscribe((state) => {
        this.communicationStatus = state.state;
        if (state.state === 'READY' || state.state === 'FAILED') {
          if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
        }
      }, (err) => {
        if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
        this.openErrorToast(err);
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  updateIgnoreTyping(v: boolean): void {
    this.ignoreTyping = v;
  }

  getDeviceLocation() {
    if (!this.deviceInfo || !this.deviceInfo.latitude || !this.deviceInfo.latitude) {
      return;
    }
    this.lat = parseInt(this.deviceInfo.latitude);
    this.lng = parseInt(this.deviceInfo.longitude);
    this.markerPosition = { lat: this.lat, lng: this.lng };
    this.mapCenter = { lat: this.lat, lng: this.lng };
    // Temporary disabled until backend API is ready
    // this.isLoading = true;
    // this.deviceService.getDeviceLocation(this.deviceDetail.labDomain, this.serialNumber).subscribe(
    //   (res) => {
    //     if (res.latitude && res.longitude) {
    //       this.lat = parseInt(res.latitude);
    //       this.lng = parseInt(res.longitude);
    //       this.markerPosition = { lat: this.lat, lng: this.lng };
    //       this.mapCenter = { lat: this.lat, lng: this.lng };
    //     }
    //   },
    //   () => this.isLoading = false
    // );
  }

  powerCall(): void {
    const request = {
      'serialNumber': this.deviceInfo.serialNumber,
      'screenShot': false
    };
    this.addToQueue('power', request);
  }

  private powerRequest(request: any): void {
    this.deviceService.powerCall(request.data, this.deviceInfo['labDomain']).subscribe(
        (res) => {
          this.inReq = false;
          if (this.deviceInfo['oem'] !== 'Apple' && this.isLandscapeMode) {
            this.checkOrientationAfterClick = true;
          }
        },
        err => {
          this.inReq = false;
          this.openErrorToast(err);
        }
    );
  }
}
