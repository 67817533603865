import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MomtCallService} from '../momt-call.service';
import {MomtArtifact, MomtIterationStatus, MomtStatusMap, MomtTest, MomtTestIteration, MomtTestStatus} from '../interfaces';
import {GetIterationListDto} from '../dto';
import {ArtifactStatusEnum, ArtifactTypeDisplayMap, ArtifactTypeEnum, FeatureTypeEnum, TestStatusDisplayMap} from '../../../shared/enum';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import {TooltipService} from '../../../shared/tooltip.service';
import {interval, Subscription} from 'rxjs';
import {CommonService} from '../../../shared/common.service';
import {MomtTypeMap, TelephonyTestCase, TelephonyTestType} from '../interfaces/momt-test.interface';

@Component({
    selector: 'momt-test-report',
    templateUrl: './momt-test-report.component.html',
    styleUrls: ['./momt-test-report.component.scss']
})

export class MomtTestReportComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private momtCallService: MomtCallService,
        private dialogService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    pageLimit = 10;
    currentPage = 0;
    totalPage = 0;
    totalIterations = 0;
    passRate = 0;
    paginationText = '0-0 of 0';

    testInfo: MomtTest;
    iterations: MomtTestIteration[];
    testCase: TelephonyTestCase;

    activeTab = 'COMMON';
    artifactsTotal: Record<string, number> = {};
    artifactList: MomtArtifact[] = [];

    isLoading = 0;
    pollingInterval: Subscription = null;
    tooltipComponent = null;
    isTelephonyAvailable = false;
    isCloudAvailable = false;
    activeTabRight = 'iterations';
    speedTestResults = [];

    ngOnInit(): void {
        this.isTelephonyAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
        if (this.isTelephonyAvailable) {
            this.getTestInfo();
            this.getIterations();
        }
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }

    getTestInfo() {
        this.isLoading++;
        const id = this.route.snapshot.queryParamMap.get('id');
        this.momtCallService.getTestInfo(id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                this.testInfo = res.data as MomtTest;
                this.getTestCases();
                this.countArtifactsTotal();
                this.sortArtifacts();
                if (this.testInfo.status === MomtTestStatus.FINALIZING) {
                    if (!this.pollingInterval || this.pollingInterval.closed) {
                        this.pollingInterval = interval(20000).subscribe(() => {
                            this.getTestInfo();
                        });
                    }
                } else {
                    this.stopPolling();
                }
            }
        }, err => {
            this.isLoading--;
        });
    }

    getTestCases() {
        const query = `size=30&page=0`;
        this.isLoading++;

        this.momtCallService.getTestCaseList(query).subscribe((res) => {
            this.isLoading--;
            if (res && res.data) {
                const testCases = res.data.tests;
                this.testCase = testCases.find((test) => test.type === this.testInfo.type);
            }
        }, err => {
            this.isLoading--;
        });
    }

    openTab(type: string) {
        this.activeTab = type;
        this.sortArtifacts();
    }

    countArtifactsTotal() {
        const commonArtifacts = this.testInfo.artifacts.filter((artifact) => !artifact.parameters?.sn);
        this.artifactsTotal.common = commonArtifacts.length;
        this.testInfo.devices.forEach((device) => {
            const deviceArtifacts = this.testInfo.artifacts.filter((artifact) => artifact.parameters?.sn === device.sn);
            this.artifactsTotal[device.type] = deviceArtifacts.length;
        })
    }

    sortArtifacts() {
        this.artifactList = this.testInfo.artifacts.filter((artifact) => {
            if (this.activeTab === 'COMMON') {
                return !artifact.parameters?.sn;
            } else {
                const device = this.testInfo.devices.find((device) => device.type === this.activeTab);
                return artifact.parameters?.sn === device?.sn;
            }
        });
    }

    getIterations() {
        this.isLoading++;
        const id = this.route.snapshot.queryParamMap.get('id');
        this.momtCallService.getTestIterations(id, this.currentPage, this.pageLimit).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                const result = res.data as GetIterationListDto;
                this.iterations = result.tests;
                this.totalPage = result.totalPages;
                this.totalIterations = result.totalElements;
                this.passRate = result.passRate;
                this.setPage();

                if (this.iterations.length) {
                    this.iterations.filter((i) => i.status === MomtIterationStatus.PASS || i.status === MomtIterationStatus.FAILED )
                        .forEach((i) => {
                            if (i.result?.metrics?.length) {
                                this.speedTestResults.push(...i.result?.metrics.map((m) => ({...m, iteration: i.order})));
                            }
                        });
                }
            }
        }, err => {
            this.isLoading--;
        });
    }

    changePageLimit() {
        this.currentPage = 0;
        this.getIterations();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.getIterations();
    }

    navigateMoMt(path: string) {
        this.router.navigate([path]);
    }

    displayTestStatus(): string {
        return MomtStatusMap.get(this.testInfo?.status);
    }

    downloadArtifacts() {
        this.momtCallService.downloadAllArtifacts(this.testInfo._id).subscribe((res) => {
            const blob = new Blob([res.body], { type: res.headers.get('content-type') });
            const fileName = res.headers
                .get('content-disposition')
                .split('filename=')
                .at(-1);

            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    displayArtifactStatus(status: ArtifactStatusEnum): string {
        return TestStatusDisplayMap.get(status);
    }

    displayArtifactType(content: ArtifactTypeEnum): string {
        return ArtifactTypeDisplayMap.get(content);
    }

    downloadFile(artifact: MomtArtifact): void {
        const directUrl = artifact.result.payload.url;
        const path = directUrl.substring(directUrl.indexOf('/users/') + 1);

        this.momtCallService.getFileDownloadApi('path=' + path).subscribe(
            (res) => {
                const link = document.createElement('a');
                link.href = res.data.url;
                link.download = artifact.result.payload.name;
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            (err) => {
            }
        );
    }

    openConfirmDeleteArtifact(artifact: MomtArtifact): void {
        this.dialogService.openConfirmationDialog(
            'deleteArtifactsModal',
            () => this.deleteArtifact(artifact._id)
        );
    }

    deleteArtifact(id: string) {
        this.isLoading++;
        this.momtCallService.removeArtifact(id, this.testInfo._id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                this.testInfo = res.data as MomtTest;
                this.sortArtifacts();
            }
        }, () => this.isLoading--);
    }

    checkDownloadAll(): boolean {
        return this.isCloudAvailable && this.testInfo
            && this.testInfo.artifacts.some(artifact => artifact.status === ArtifactStatusEnum.COMPLETED);
    }

    cancelArtifact(artifactId: string) {
        this.isLoading++;
        this.momtCallService.cancelArtifact(artifactId, this.testInfo._id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                this.testInfo = res.data as MomtTest;
                this.sortArtifacts();
            }
        }, () => this.isLoading--);
    }

    cancelFile(artifact: MomtArtifact): void {
        this.dialogService.openConfirmationDialog('cancelArtifactFinalization', () => this.cancelArtifact(artifact._id));
    }

    stopPolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }

    getExecutionTime(): string {
        if (this.iterations && this.testInfo) {
            const executionTime = this.testInfo.executedAt - (Math.round(this.iterations[0]?.startedAt / 1000));
            const hours = Math.floor(executionTime / 3600);
            const mins = Math.floor((executionTime - (hours * 3600)) / 60);
            const seconds = Math.round(executionTime % 60);
            return `${hours ? hours + ' hour' : ''} ${mins ? mins + ' min' : ''} ${seconds} seconds`;
        }
        return '';
    }

    setPage() {
        const total = this.totalIterations;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
    }

    displayTestType(type: TelephonyTestType): string {
        return MomtTypeMap.get(type);
    }

    openTabIterations(tab: string) {
        this.activeTabRight = tab;
    }

    getTargetThroughput() {
        const param = this.testInfo.parameters.filter((p) => p.name === 'bandwidth')[0];
        return param?.value ? param.value : '--';
    }
}
