<app-spinner *ngIf="isLoading"></app-spinner>
<div class="finalize-container">
    <div class="input-section">
        <label for="test-name"><span class="required-field">*</span>Test Name</label>
        <input type="text" class="form-control" id="test-name" #testName placeholder="Enter a name"
            [value]="ongoingTest?.name" [ngClass]="{'error': !testName.value.length || uniqueNameError}"
            (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)" />
        <div *ngIf="uniqueNameError" class="input-error">{{uniqueNameError}}</div>
        <div *ngIf="!testName.value.length" class="input-error">Test name can't be empty</div>
    </div>
    <div class="input-section">
        <label for="test-description">Description</label>
        <textarea max="150" rows="3" class="form-control" id="test-description" #testDescription
            placeholder="Enter a description" (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)"
            [value]="ongoingTest?.description"></textarea>
    </div>
    <div class="artifact-list" *ngIf="ongoingTest.artifacts.length > 0">
        <span>Artifacts</span>
        <app-artifact-list [artifacts]="ongoingTest.artifacts" [testId]="ongoingTest._id"></app-artifact-list>
    </div>

    <span class="status-text">Outcome of the test</span>
    <div class="status-switch">
        <button class="passed-button" [ngClass]="{selected: testResult === testResultEnum.PASS}"
            (click)="setTestStatus(testResultEnum.PASS)" type="button">Passed</button>
        <button class="failed-button" [ngClass]="{selected: testResult === testResultEnum.FAIL}"
            (click)="setTestStatus(testResultEnum.FAIL)" type="button">Failed</button>
    </div>
    <div class="finalize-test-buttons">
        <button class="btn cancel_btn" (click)="discard()">
            Discard
        </button>
        <button class="btn control_btn_new" (click)="save()" [disabled]="!testName.value || uniqueNameError">
            Save
        </button>
    </div>
</div>