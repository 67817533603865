<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title">
    <h1>
        Users
        <div class="total-users" *ngIf="userData?.role !== 'ADMIN'">
            {{totalCorporate || 0}}
        </div>
    </h1>
    <div class="filters">
        <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field"
            placeholder="Search" (change)="searchChange()" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: isFilterOn }" (click)="openFilters()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <select class="filter-select select_input form-control" [(ngModel)]="searchStatus" required>
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
                <select *ngIf="activeTab === 'ENTERPRISE' && userData.role === 'ADMIN'"
                    class="filter-select select_input form-control" [(ngModel)]="searchEnterprise" required>
                    <option value="">Select Enterprise</option>
                    <option *ngFor="let option of enterpriseOptions" [value]="option.id">{{option.name}}</option>
                </select>
                <select *ngIf="activeTab === 'ENTERPRISE'" class="filter-select select_input form-control"
                    [(ngModel)]="searchLab" required>
                    <option value="">Select Accessibility Level</option>
                    <option *ngFor="let option of labOptions" [value]="option.id">{{option.name}}</option>
                </select>
                <select *ngIf="activeTab === 'ENTERPRISE'" class="filter-select select_input form-control"
                    [(ngModel)]="searchRole" required>
                    <option value="">Select Role</option>
                    <option *ngIf="userData?.role === 'ADMIN' || userData?.role === 'ENTERPRISE_ADMIN'"
                        value="ENTERPRISE_ADMIN">
                        Enterprise Admin
                    </option>
                    <option *ngIf="userData?.role === 'ADMIN' || userData?.role === 'ENTERPRISE_ADMIN'"
                        value="ENTERPRISE_LAB_ADMIN">
                        Lab Admin
                    </option>
                    <option *ngIf="['ADMIN', 'ENTERPRISE_ADMIN', 'ENTERPRISE_LAB_ADMIN'].includes(userData?.role)"
                        value="ENTERPRISE_ELEVATED_USER">
                        Elevated User
                    </option>
                    <option value="ENTERPRISE_USER">User</option>
                </select>
                <div class="filter-buttons">
                    <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
                    <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
                    <button class="snap-button primary" (click)="applyFilters()">Filter</button>
                </div>
            </div>
        </div>
        <button class="snap-button primary create-new-button" (click)="openCreate()">Create a new user</button>
    </div>
</div>
<div class="tabs" *ngIf="userData?.role === 'ADMIN'">
    <span [ngClass]="{ active: activeTab === 'ADMIN'}" (click)="openTab('ADMIN')">
        Support
        <div class="total-users">
            {{totalSupport || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === 'USER'}" (click)="openTab('USER')">
        Public
        <div class="total-users">
            {{totalPublic || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === 'ENTERPRISE'}" (click)="openTab('ENTERPRISE')">
        Corporate
        <div class="total-users">
            {{totalCorporate || 0}}
        </div>
    </span>
</div>
<div class="empty-result" *ngIf="isFiltered && userList?.length === 0 && !isLoading">
    <img alt="" src="../../../../../assets/images/NewUI/menu_users_large.svg">
    <span class="empty-search-title">No users were found</span>
    <span>
        No results matched your criteria. Try adjusting your search or filter options to find what you are looking
        for.
    </span>
    <span (click)="clearFilters()" class="empty-search-action">Clear filters</span>
</div>
<div class="empty-result" *ngIf="!isFiltered && userList?.length === 0 && !isLoading">
    <img alt="" src="../../../../../assets/images/NewUI/menu_users_large.svg">
    <span class="empty-search-title">No users were found</span>
    <span>
        Create a new user. Set up and configure the user at ease.
    </span>
    <button class="snap-button primary create-new-button" (click)="openCreate()">Create a new user</button>
</div>
<table *ngIf="!isLoading && activeTab === 'ADMIN' && userList?.length > 0" class="user-table admin-table">
    <thead>
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of userList">
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.firstName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.firstName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.lastName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.lastName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.email | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.email}}
                </span>
            </td>
            <td>
                <div class="user-status" [ngClass]="{ active: user.status === 'active' }" [tp]="tooltipComponent"
                    [tpData]="(user.status | titlecase) | tooltipData"></div>
                {{user.status | titlecase}}
            </td>
            <td>
                <div class="actions">
                    <span *ngIf="user.status === 'inactive'" (click)="changeUserStatus(user.userId, 'active')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Activate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_activate.svg" alt="">
                    </span>
                    <span *ngIf="user.status === 'active'" (click)="changeUserStatus(user.userId, 'inactive')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Deactivate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_deactivate.svg" alt="">
                    </span>
                    <span (click)="openEdit(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Edit' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_edit.svg" alt="">
                    </span>
                    <span (click)="resetPassword(user.email)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Reset password' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_reset_password.svg" alt="">
                    </span>
                    <span (click)="openConfirmDelete(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Delete' | tooltipData"
                        [ngClass]="{ disabled: userData?.role === 'ENTERPRISE_ELEVATED_USER'}">
                        <img alt="" src="../../../../../assets/images/trash-new.svg" />
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<table *ngIf="!isLoading && activeTab === 'USER' && userList?.length > 0" class="user-table public-table">
    <thead>
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Subscription</th>
            <th>Subscription end date</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of userList; index as i">
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.firstName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.firstName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.lastName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.lastName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.email | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.email}}
                </span>
            </td>
            <td>
                <div class="user-status" [ngClass]="{ active: getUserStatus(user) === 'active' }" [tp]="tooltipComponent"
                    [tpData]="(getUserStatus(user) | titlecase) | tooltipData"></div>
                {{getUserStatus(user) | titlecase}}
            </td>
            <td>
                <feature-list [features]="user.subscriptions[0]?.features"></feature-list>
            </td>
            <td class="centered">
                <span *ngIf="!user.subscriptions[0]?.expiration?.endAt || user.subscriptions[0].expiration.endAt >= unlimited / 1000">
                    <img src="../../../../../assets/images/NewUI/infinity.svg" alt="">
                </span>
                <span *ngIf="user.subscriptions[0]?.expiration.endAt && user.subscriptions[0].expiration.endAt < unlimited / 1000">
                    {{user.subscriptions[0].expiration.endAt * 1000 | date: "MM.dd.yyyy" }}
                </span>
            </td>
            <td>
                <div class="actions">
                    <span *ngIf="user.status === 'inactive'" (click)="changeUserStatus(user.userId, 'active')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Activate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_activate.svg" alt="">
                    </span>
                    <span *ngIf="user.status === 'active'" (click)="changeUserStatus(user.userId, 'inactive')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Deactivate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_deactivate.svg" alt="">
                    </span>
                    <span (click)="openEdit(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Edit' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_edit.svg" alt="">
                    </span>
                    <span (click)="resetPassword(user.email)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Reset password' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_reset_password.svg" alt="">
                    </span>
                    <span (click)="openConfirmDelete(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Delete' | tooltipData"
                        [ngClass]="{ disabled: userData?.role === 'ENTERPRISE_ELEVATED_USER'}">
                        <img alt="" src="../../../../../assets/images/trash-new.svg" />
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<table *ngIf="!isLoading && activeTab === 'ENTERPRISE' && userList?.length > 0" class="user-table corporate-table">
    <thead>
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Enterprise</th>
            <th>Accessibility level</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of userList">
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.firstName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.firstName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.lastName | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.lastName}}
                </span>
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.email | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.email}}
                </span>
            </td>
            <td>
                <span class="ellipsis role" [tp]="tooltipComponent" [tpData]="userRoleMap.get(user.role) | tooltipData"
                    [tpOnlyTextOverflow]="true" [ngClass]="{ admin: user.role === 'ENTERPRISE_ADMIN' || user.role === 'ENTERPRISE_LAB_ADMIN'}">
                    {{userRoleMap.get(user.role)}}
                </span>
            </td>
            <td>
                <div class="user-status" [ngClass]="{ active: getUserStatus(user) === 'active' }" [tp]="tooltipComponent"
                    [tpData]="(getUserStatus(user) | titlecase) | tooltipData"></div>
                {{getUserStatus(user) | titlecase}}
            </td>
            <td>
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="user.enterprise | tooltipData"
                    [tpOnlyTextOverflow]="true">
                    {{user.enterpriseName}}
                </span>
            </td>
            <td>
                <span *ngIf="!user.labs">
                    All Labs
                </span>
                <span class="ellipsis" *ngIf="user.labs?.length === 1" [tp]="tooltipComponent"
                    [tpData]="user.labs[0].name | tooltipData" [tpOnlyTextOverflow]="true">
                    {{user.labs[0].name}}
                </span>
                <span *ngIf="user.labs?.length > 1" class="lab-dropdown">
                    {{user.labs?.length}} Lab(s)
                    <img src="../../../../assets/images/arrow_back.svg">
                    <div class="more">
                        <span *ngFor="let lab of user.labs">
                            {{lab.name}}
                        </span>
                    </div>
                </span>
            </td>
            <td>
                <div class="actions">
                    <span *ngIf="user.status === 'inactive'" (click)="changeUserStatus(user.userId, 'active')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Activate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_activate.svg" alt="">
                    </span>
                    <span *ngIf="user.status === 'active'" (click)="changeUserStatus(user.userId, 'inactive')"
                        [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Deactivate' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_deactivate.svg" alt="">
                    </span>
                    <span (click)="openEdit(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Edit' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_edit.svg" alt="">
                    </span>
                    <span (click)="resetPassword(user.email)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Reset password' | tooltipData">
                        <img src="../../../../../assets/images/NewUI/user_reset_password.svg" alt="">
                    </span>
                    <span (click)="openConfirmDelete(user.userId)" [tp]="tooltipComponent" tpPlacement="left"
                        [tpData]="'Delete' | tooltipData"
                        [ngClass]="{ disabled: userData?.role === 'ENTERPRISE_ELEVATED_USER'}">
                        <img alt="" src="../../../../../assets/images/trash-new.svg" />
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div class="pagination-container" *ngIf="userList?.length > 0">
    <span class="limit">
        <label for="limit">Rows per page: </label>
        <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </span>
    <span>{{paginationText}}</span>
    <div class="pagination-buttons">
        <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
            <img alt="" src="../../../../assets/images/arrow_back.svg">
        </div>
        <div [ngClass]="{ disabled: currentPage + 1 >= totalPages }" class="arrow-button" (click)="changePage(1)">
            <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
        </div>
    </div>
</div>
