<mat-accordion multi>
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let iteration of iterations; index as i"
        [expanded]="expandedRows.has(i) || iteration.status === 'PROGRESS'">
        <mat-expansion-panel-header (click)="handleRowSelection(i)">
            <div class="iteration">
                <mat-panel-title class="iteration_title">
                    Iteration {{iteration.order}}
                </mat-panel-title>
                <mat-spinner diameter="24" *ngIf="iteration.status === 'PROGRESS'"></mat-spinner>
                <mat-panel-description class="iteration_item flex-grow"
                    [ngClass]="{'passed': iteration.status === 'PASS', 'failed': iteration.status === 'FAILED', 'canceled': iteration.status === 'CANCELED'}">
                    <span class="iteration_status"
                        *ngIf="iteration.status !== 'IDLE' && iteration.status !== 'PROGRESS'">{{displayIterationStatus(iteration.status)}}</span>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>
        <ul class="tab-container">
            <li class="artifact-container 'artifact_container_first" [ngClass]="{'artifact_container_first': k === 0}">
                <div class="iteration iteration_sub">
                    <div class="artifacts_icon"
                        [ngClass]="{'passed': iteration.status !== 'IDLE'}">
                        <img src="../../../../assets/images/telephony-actions/start{{iteration.status !== 'IDLE' ? '_active' : ''}}.svg" alt="">
                    </div>
                    <span>00:00:000</span>
                    <span>START</span>
                </div>
            </li>
            <li *ngFor="let action of iteration.actions; index as k" class="artifact-container">
                <div class="iteration iteration_sub">
                    <div class="artifacts_icon"
                        [ngClass]="{'passed': action.status === 'PASS', 'failed': action.status === 'FAILED'}">
                        <img src="../../../../assets/images/telephony-actions/{{getActionIconName(action.type)}}{{action.status === 'PASS' || action.status === 'FAILED' ? '_active' : ''}}.svg"
                            alt="">
                    </div>
                    <span>{{getActionTime(action, iteration) | date:'mm:ss:SSS'}} </span>
                    <span>{{displayActionType(action.type)}}</span>
                    <mat-spinner diameter="24" *ngIf="action.status === 'PROGRESS'" class="spinner"></mat-spinner>
                </div>
                <div class="action-error" *ngFor="let error of action.errors">
                    <span>ERROR</span>
                    <span class="error-message">{{error.message}}</span>
                </div>
            </li>
        </ul>
    </mat-expansion-panel>
</mat-accordion>