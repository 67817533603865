<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<section id="sec1" class="fstsc" *ngIf="isBookingAvailable">
    <div class="hd-cd">
      <div>
        <div class="device_bar d-flex align-items-center">
          <h4 class="devices">Current Devices</h4>
          <span class="mt-2 ml-3">
            <span class="apple-box mr-1" (click)="tabFilterCurrent('CurrentDeviceIos')"
              [ngClass]="{'active': CurrentDeviceIos == true}"><img class="appl"
                src="../../../../assets/images/NewUI/apple-logo.png" alt="">
              <p class="cursor_point">{{currentIos}}</p>
            </span>
            <span class="apple-box ml-1" (click)="tabFilterCurrent('CurrentDeviceAnd')"
              [ngClass]="{'active': CurrentDeviceAnd == true}"><img class="appl"
                src="../../../../assets/images/NewUI/android-logo.png" alt="">
              <p class="cursor_point">{{currentAndroid}}</p>
            </span>
          </span>
        </div>
      </div>
      <div>
        <span class="search mt-2 ml-3">
          <input type="text" class="searchTerm form-control" (input)="SearchDeviceLocal1()" (keydown.enter)="SearchDeviceLocal1();"
            [(ngModel)]="searchText1" placeholder="Search">
          <span class="search_icon"><img class="input_search" src="../../../../assets/images/NewUI/search_input.svg" alt=""></span>
        </span>
      </div>
  </div>
  <div class="container-fluid fixed_heigh1 mb-2">
    <!-- <div class="row">
      <div class="col text-right">
        <button #BTN2 routerLink="/snap/run-test-case" class="btn loginBtn p-1">Run Test Case</button>
      </div>
    </div> -->
    <p class="no_history no_history-no-padding" *ngIf="currentDeviceList.length == 0"> No Devices Allocated Yet</p>
    <div class="phone-boxes row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-1 main-device-wrapper" *ngFor="let item of currentDeviceList; index as i">
        <div class="status-strip {{getDeviceStatus(item?.deviceData[0]) | lowercase}}-bg"></div>
        <div class=" m-0 p-0" *ngIf="item.isResetting == true">
          <img class="close_icon index_" *ngIf="item.resetCount  ==5 " (click)="closeReset(i,'CURRENT')" src="../../../../assets/images/NewUI/ic_close.svg" alt="">
          <span class="reset_text" *ngIf="item.resetCount  !==5 " >Resetting ...</span>
          <span class="reset_text1" *ngIf="item.resetCount  ==5 ">Device is still not online. <br>You can try again after few minutes</span>
        </div>
        <div class="d-flex row m-0 device-card wraper_box" [ngClass]="{'resetting_box':item.isResetting == true}">
          <div class="device-info">
            <div class="device-img" [ngClass]="{'disabled': showOfflineTooltip(item?.deviceData[0]) && item?.deviceData[0].deviceState !== 'Online'}">
              <img *ngIf="item?.deviceData[0].oem == 'Apple'" src="../../../../assets/images/NewUI/ip12.jpeg" alt="">
              <img *ngIf="item?.deviceData[0].oem !== 'Apple'" src="../../../../assets/images/NewUI/android.png" alt="">
            </div>
            <div class="device-info-details">
              <div class="info-header">
                <div class="device-name">
                  <h4>{{item?.deviceData[0].modelName}}</h4>
                  <p class="ios" *ngIf="item?.deviceData[0].oem == 'Apple'">iOS {{item?.deviceData[0].osVersion}}</p>
                  <p class="ios" *ngIf="item?.deviceData[0].oem !== 'Apple'">Android {{item?.deviceData[0].osVersion}}</p>
                </div>
                <div class="tooltip3 device-tooltip">
                  <img *ngIf="item?.dayLeft < 2 " src="../../../../assets/images/expire_soon.svg" alt="">
                  <span class="tooltiptext">{{expiretooltip}}</span>
                </div>
                <div class="tooltip7 device-tooltip"
                     [ngClass]="{'wider-tt': showOfflineTooltip(item?.deviceData[0]) && item?.deviceData[0].deviceState === 'Offline', 'left-aligned': showOfflineTooltip(item?.deviceData[0]) && item?.deviceData[0].deviceState === 'Offline'}">
                  <span class="status-icon {{getDeviceStatus(item?.deviceData[0]) | lowercase}}-bg">{{showOfflineTooltip(item?.deviceData[0]) && item?.deviceData[0].deviceState === 'Offline' ? '!' : ''}}</span>
                  <span *ngIf="showOfflineTooltip(item?.deviceData[0])" class="tooltiptext">
                    <p><b>{{getDisplayedStatus(getDeviceStatus(item?.deviceData[0])) | titlecase}}</b></p>
                    <p *ngIf="showOfflineTooltip(item?.deviceData[0]) && item?.deviceData[0].deviceState === 'Offline'">{{showOfflineTooltip(item?.deviceData[0])}}</p>
                  </span>
                </div>
              </div>
              <div class="device-details">
                <p class="imei">
                  <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                  [tpData]="item?.deviceData[0].serialNumber | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                    {{item?.deviceData[0].oem == 'Apple' ? 'UUID': 'Serial No'}}: {{item?.deviceData[0].serialNumber}}
                  </span>
                </p>
                <div class="location-reserved-upto">
                  <span class="loc">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="item?.deviceData[0].labLocation | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                      {{item?.deviceData[0].labLocation}}
                    </span>
                  </span>
                  <span class="res-time for-booked">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="(item?.bookedUntil *1000 | date: 'MM/dd/yyyy') | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                      <img src="../../../../assets/images/booked_upto.svg" alt="">
                      {{item?.bookedUntil *1000 | date: "MM/dd/yyyy"}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="device-actions">
            <a (click)="openCalModal1(extendModal, item)" class="extnd tooltip2">Extend
              <span class="tooltiptext">{{ExtendBtntooltip}}</span>
            </a>
            <a #BTN (click)="openConfirmationModal(releaseModal, BTN,item)"
               class="release tooltip2">Release
              <span class="tooltiptext">{{relaseBtntooltip}}</span>
            </a>
            <a (click)="navigateDevice(item)" *ngIf="item?.deviceData[0].deviceStateCode && item?.deviceData[0].deviceStateCode !== 'UNINITIALIZED'"
               [ngClass]="{'disabled-link': item['isDisabled'] == true}" class="launch tooltip2 ml-1">Launch
              <span class="tooltiptext">{{LaunchBtntooltip}}</span>
            </a>
            <a (click)="resetCambrionix(item.deviceData[0].serialNumber, item.deviceData[0].labDomain)" *ngIf="enableResetBtn(item.deviceData[0])"
               class="reset tooltip2 ml-1" [ngClass]="{'disabled-link':item.isResetting == true }">Reset
              <span class="tooltiptext">{{ResetbleBtntooltip}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section *ngIf="isBookingAvailable">
    <div class="hd-cd align-items-center">
      <h4 class="devices">All Devices</h4>
      <span class="ml-3 mt-2">
        <span class="apple-box" (click)="tabFilterAll('AllDeviceIos')" [ngClass]="{'active': AllDeviceIos == true}">
          <img class="appl" src="../../../../assets/images/NewUI/apple-logo.png" alt="">
          <p class="cursor_point">{{availableIos}}</p>
        </span>
        <span class="apple-box ml-2" (click)="tabFilterAll('AllDeviceAnd')" [ngClass]="{'active': AllDeviceAnd == true}">
          <img class="appl" src="../../../../assets/images/NewUI/android-logo.png" alt="">
          <p class="cursor_point">{{availableAndroid}}</p>
        </span>
      </span>
      <span class="search ml-3">
          <input type="text" class="searchTermAll form-control" (input)="SearchDeviceLocal()" (keydown.enter)="SearchDeviceLocal();"
                 [(ngModel)]="searchText" placeholder="Search ">
          <span class="search_icon"><img class="input_search" src="../../../../assets/images/NewUI/search_input.svg" alt=""></span>
      </span>
      <div class="mrgnz-mob labs">
        <select name="Labs" class="form-control select_field_ field_height" (change)="SearchDeviceLocal()" [(ngModel)]="searchLab">
          <option value="">All Labs</option>
          <option [value]="item" *ngFor="let item of labListDropdown ">{{item}}</option>
        </select>
      </div>
      <div class="mrgnz-mob location">
        <select name="Location" class="form-control select_field_ field_height" (change)="SearchDeviceLocal()" [(ngModel)]="searchLocation">
          <option value="">All Locations</option>
          <option [value]="item" *ngFor="let item of locationListDropdown ">{{item}}</option>
        </select>
      </div>
      <div class="toggle_view ml-2 d-flex">
        <span class="grid" [ngClass]="{'gradient': isGridView == true}">
          <img src="../../../../assets/images/NewUI/grid_white.svg" alt="" class="" *ngIf="isGridView == true">
          <img src="../../../../assets/images/NewUI/grid-black.svg" (click)="toggleView()" alt="" class="cursor_point"
               *ngIf="isGridView == false">
        </span>
        <span class="list" [ngClass]="{'gradient-rotate': isGridView == false}">
          <img src="../../../../assets/images/NewUI/list_white.svg" alt="" class="" *ngIf="isGridView == false">
          <img src="../../../../assets/images/NewUI/list_black.svg" (click)="toggleView()" alt="" class="cursor_point"
               *ngIf="isGridView == true">
        </span>
      </div>
      <div class="request-device">
        <button #BTN2 (click)="openRequestModal(deviceRequest, BTN2)" class="btn p-1">Request new device</button>
      </div>
    </div>
  <div class="container-fluid p-0">
    <div class="row filters-wrapper">
      <div class="booked-filter">
        <span (click)="filterByBooked(false)" class="ml-3" [ngClass]="{'activeFilter': this.selectedBookedFilter === 'available'}">
            <span class="_box_filter remove_bg mr-2 available-circle"></span>
            <span style="text-transform: capitalize !important;">Not booked</span> ({{bookingFilterList['false']?.length || 0}})
          </span>
        <span (click)="filterByBooked(true)" class="ml-3" [ngClass]="{'activeFilter': this.selectedBookedFilter === 'booked'}">
            <span class="_box_filter remove_bg mr-2 booked-circle"></span>
            <span style="text-transform: capitalize !important;">Booked</span> ({{bookingFilterList['true']?.length || 0}})
          </span>
      </div>
      <div class="filter">
          <span (click)="applyFilter(item.key)" class="ml-3" [ngClass]="{'activeFilter':this.deviceState  == item.key}" *ngFor="let item of filterList | keyvalue">
            <span class="_box_filter remove_bg mr-2 {{item.key.toLowerCase()}}-bg"></span>
            <span style="text-transform: capitalize !important;">{{item.key | titlecase}}</span> ({{item.value.length}})
          </span>
      </div>
      <div class="filter-help" (click)="toggleSidepanelAction()">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
          <path d="M481.12 785.231q13.265 0 22.38-9.159 9.115-9.159 9.115-22.423 0-13.264-9.158-22.38-9.159-9.115-22.423-9.115-13.265 0-22.38 9.159-9.116 9.158-9.116 22.423 0 13.264 9.159 22.38 9.159 9.115 22.423 9.115Zm-20.659-132.462h38.616q1.538-26.077 9.808-42.384 8.269-16.308 34.038-41.616 26.769-26.769 39.846-47.961 13.077-21.193 13.077-49.059 0-47.288-33.229-75.365-33.23-28.077-78.617-28.077-43.154 0-73.269 23.462-30.116 23.462-44.116 53.923l36.77 15.231q9.615-21.846 27.5-38.615 17.884-16.77 51.577-16.77 38.923 0 56.846 21.347 17.923 21.346 17.923 46.961 0 20.769-11.231 37.115-11.231 16.347-29.231 32.885-34.769 32.077-45.538 54.385-10.77 22.307-10.77 54.538ZM480.134 936q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120 650.806 120 576.134q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194 216 479.866 216q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840 501.194 840 575.866q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806 936 480.134 936ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="container-fluid mb-4 all-devices" *ngIf="isGridView">
    <p class="no_history" *ngIf="deviceList.length == 0"> No Device Available</p>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-1 main-device-wrapper" *ngFor="let item of deviceList;index as i">
        <div class="status-strip {{getDeviceStatus(item) | lowercase}}-bg"></div>
        <div class=" m-0 p-0" *ngIf="item.isResetting == true">
          <img class="close_icon index_" *ngIf="item.resetCount  ==5 " (click)="closeReset(i,'ALL')" src="../../../../assets/images/NewUI/ic_close.svg" alt="">
          <span class="reset_text" *ngIf="item.resetCount !==5 ">Resetting ...</span>
          <span class="reset_text1" *ngIf="item.resetCount  ==5 ">Device is still not online. <br>You can try again after few minutes</span>
        </div>
        <div class="row m-0 device-card wraper_box" [ngClass]="{'resetting_box':item.isResetting == true}">
          <div class="device-info">
            <div class="device-img" [ngClass]="{'disabled': showOfflineTooltip(item) && item?.deviceState !== 'Online'}">
              <img *ngIf="item?.oem == 'Apple'" src="../../../../assets/images/NewUI/ip12.jpeg" alt="">
              <img *ngIf="item?.oem !== 'Apple'" src="../../../../assets/images/NewUI/android.png" alt="">
            </div>
            <div class="device-info-details">
              <div class="info-header">
                <div class="device-name">
                  <h4>{{item?.modelName}}</h4>
                  <p class="ios" *ngIf="item?.oem == 'Apple'">iOS {{item?.osVersion}}</p>
                  <p class="ios" *ngIf="item?.oem !== 'Apple'">Android {{item?.osVersion}}</p>
                </div>
                <div class="tooltip3 device-tooltip">
                  <img *ngIf="item.bookedByUserId === userData.userId" src="../../../../assets/images/NewUI/self-booked.svg" alt="">
                  <span class="tooltiptext">Booked by me</span>
                </div>
                <div class="tooltip7 device-tooltip"
                     [ngClass]="{'wider-tt': showOfflineTooltip(item) && item?.deviceState === 'Offline', 'left-aligned': showOfflineTooltip(item) && item?.deviceState === 'Offline'}">
                  <span class="status-icon {{getDeviceStatus(item) | lowercase}}-bg">{{showOfflineTooltip(item) && item?.deviceState === 'Offline' ? '!' : ''}}</span>
                  <span *ngIf="showOfflineTooltip(item)" class="tooltiptext">
                    <p><b>{{getDisplayedStatus(getDeviceStatus(item)) | titlecase}}</b></p>
                    <p *ngIf="showOfflineTooltip(item) && item?.deviceState === 'Offline'">{{showOfflineTooltip(item)}}</p>
                  </span>
                </div>
              </div>
              <div class="device-details">
                <p class="imei">
                  <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                  [tpData]="item?.serialNumber | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                    {{item?.oem == 'Apple' ? 'UUID': 'Serial No'}}: {{item?.serialNumber}}
                  </span>
                </p>
                <div class="location-reserved-upto">
                  <span class="loc for-all">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="item?.labLocation | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                      {{item?.labLocation}}
                    </span>
                  </span>
                  <span class="res-time for-all" *ngIf="item?.bookedUntilESTDate">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="item?.bookedUntilESTDate | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                      <img src="../../../../assets/images/booked_upto.svg" alt="">
                      {{item?.bookedUntilESTDate}}
                    </span>
                  </span>
                  <span class="res-time for-all" *ngIf="item.bookedByUserName">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="item?.bookedByUserName | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                      <img src="../../../../assets/images/booked_by.svg" alt="">
                      {{item?.bookedByUserName}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="device-actions">
            <a class="reset tooltip1" (click)="resetCambrionix(item.serialNumber, item.labDomain)" [ngClass]="{'disabled-link':item.isResetting == true || item.isDisabled }"
               *ngIf="item.isBooked == false && enableResetBtn(item)">Reset
              <span class="tooltiptext2 m-0 p-0" *ngIf="item.deviceState =='Offline'">{{ResetbleBtntooltip}}</span>
            </a>
            <a class="launch tooltip1" (click)="openCalModal1(calenderModal, item)"
               *ngIf="item.isBooked == false" [ngClass]="{'disabled-link': item.deviceState === 'Offline' || item.isDisabled }">Book
              <span class="tooltiptext">{{AvailbleBtntooltip}}</span>
            </a>
            <a class="reserved at-front in-list-btn" *ngIf="item.isBooked == true">Booked</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0" *ngIf="!isGridView">
    <div class="device_table" [ngClass]="{'without-booked': !currentDeviceList.length,'with-booked': currentDeviceList.length}">
        <table class="table text-left">
          <thead class="tableheading inner_table_head">
            <tr>
              <th scope="col" class="_noWrap">STATUS</th>
              <th scope="col" class="_noWrap">OEM</th>
              <th scope="col" class="_noWrap">MODEL</th>
              <th scope="col" class="_noWrap">IMEI</th>
              <th scope="col" class="_noWrap">UUID/SERIAL NUMBER</th>
              <th scope="col" class="_noWrap">OS VERSION</th>
              <th scope="col" class="_noWrap">LOCATION</th>
              <th scope="col" class="_noWrap">BOOKED UPTO</th>
              <th scope="col" class="_noWrap">BOOKED BY</th>
              <th scope="col" class="_noWrap">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of deviceList; index as i" [ngClass]="{'resetting_box_table':item.isResetting == true}" class="position-relative">
              <td class="_noWrap status-wrapper-list-view">
                <span class="tooltip8 device-tooltip list-view"
                      [ngClass]="{'wider-tt': showOfflineTooltip(item) && item?.deviceState === 'Offline', 'left-aligned': showOfflineTooltip(item) && item?.deviceState === 'Offline'}">
                  <span class="status-icon {{getDeviceStatus(item) | lowercase}}-bg">{{showOfflineTooltip(item) && item?.deviceState === 'Offline' ? '!' : ''}}</span>
                  <span *ngIf="showOfflineTooltip(item)" class="{{i > deviceList.length - 4 ? 'tooltiptext1' : 'tooltiptext2'}}">
                    <p><b>{{getDisplayedStatus(getDeviceStatus(item)) | titlecase}}</b></p>
                    <p *ngIf="showOfflineTooltip(item) && item?.deviceState === 'Offline'">{{showOfflineTooltip(item)}}</p>
                  </span>
                </span>
                <span class="online_txt">{{getDisplayedStatus(getDeviceStatus(item)) | uppercase}}</span>
              </td>
              <td class="_noWrap text_capital" style="text-transform: uppercase;">
                <span class="table_box" *ngIf="item.isResetting == true">
                  <img class="close_icon_table"  *ngIf="item.resetCount  ==5 " (click)="closeReset(i,'ALL')" src="../../../../assets/images/NewUI/ic_close.svg" alt="">
                  <span class="reset_text_table" *ngIf="item.resetCount !==5 ">Resetting ...</span>
                  <span class="reset_text_table1" *ngIf="item.resetCount  ==5 ">Device is still not online. <br>You can try again after few minutes</span>
                </span>
                {{item?.oem}}
              </td>
              <td class="_noWrap">{{item?.modelName}}</td>
              <td class="_noWrap">{{item?.imei}}</td>
              <td class="_noWrap">{{ item?.serialNumber }}</td>
              <td class="_noWrap">{{ item?.osVersion }}</td>
              <td class="_noWrap">{{item?.labLocation}}</td>
              <td class="_noWrap"> {{item.bookedUntilESTDate? item.bookedUntilESTDate:''}} </td>
              <td class="_noWrap"> {{item?.bookedByUserName}}</td>

              <td class="_noWrap">
                <div class="list-view-actions">
                  <a class="reset tooltip9 wider-tt" (click)="resetCambrionix(item.serialNumber, item.labDomain)" [ngClass]="{'disabled-link':item.isResetting == true || item.isDisabled }"
                     *ngIf="item.isBooked == false && enableResetBtn(item)">Reset
                    <span class="tooltiptext">{{ResetbleBtntooltip}}</span>
                  </a>
                  <a class="reserved tooltip9" *ngIf="item.isBooked == true">Booked</a>
                  <a class="launch tooltip9 wider-tt" (click)="openCalModal1(calenderModal, item)" [ngClass]="{'disabled-link': item.deviceState !== 'Online' || item.isDisabled }" *ngIf="item.isBooked == false">Book
                    <span class="tooltiptext" *ngIf="item.deviceState === 'Online' && !item.isBooked">{{AvailbleBtntooltip}}</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      <p class="no_history" *ngIf="deviceList.length == 0"> No Device Available</p>
    </div>
  </div>
</section>

<div class="no-access" *ngIf="!isBookingAvailable">
  <p>
      Device booking is not available with your current subscription plan. <br />
      To get access to devices, you must upgrade your account to a plan that includes Booking feature.
  </p>
</div>

<ng-template #releaseModal let-modal>
  <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
  <div class="modal-body p-5">
    <div class="col-md-12 p-0 m-0 text-center">
      <p class="modal-inner-text"> Are you sure you want to Release this Device?</p>
    </div>
    <div class="row col-md-12 justify-content-center mt-5 mr-0 ml-0">
      <button class="btn confirm_btn_modal mr-3" (click)="LeaveDevice()">Release</button>
      <button class="btn cancel_modal_btn" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #calenderModal let-modal>
  <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Book device for...</h5>
    <svg (click)="closeModalEnd()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" class="close_modal-window">
      <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
    </svg>
  </div>
  <div class="modal-body p-1">
    <div class="row col-md-12 m-auto justify-content-center">
      <div class="radio-button-container">
        <div
                class="reservation-options p-1"
                *ngFor="let option of optionsListForReservingDevice; let i = index"
        >
          <label
                  [for]="i"
                  class="option-reservation-label"
          >
            <input
                    name="mode"
                    [disabled]=""
                    id="{{ i }}"
                    type="radio"
                    [value]="option"
                    [(ngModel)]="currentOptionForReservingDevice"
                    (change) = "updateCurrentOption(option)"
            />
            {{  option }}

            <span *ngIf="option === 'Today' && currentOptionForReservingDevice === 'Today'" class="time-block">till {{timeTillToday}} &nbsp; 23:59</span>
            <span *ngIf="option === 'Tomorrow' && currentOptionForReservingDevice === 'Tomorrow'" class="time-block">till {{timeTillTomorrow}} &nbsp; 23:59</span>
            <span *ngIf="option === 'This week' && currentOptionForReservingDevice === 'This week'" class="time-block">till {{timeTillThisWeek}} &nbsp; 23:59</span>
            <span *ngIf="option === 'Custom'">
              <img *ngIf="currentOptionForReservingDevice !== 'Custom'" (click)="endpicker.open()" src="../../../../assets/images/NewUI/calendar_grey.png" alt="Default Icon" class="custom-icon" />
              <img *ngIf="currentOptionForReservingDevice === 'Custom'" (click)="endpicker.open()" src="../../../../assets/images/NewUI/calendar_color.png" alt="Selected Icon" class="custom-icon" />
                <input class="reservation-input" matInput [matDatepicker]="endpicker" [min]="minDateCal" [(ngModel)]="searchEndDate" >
                <mat-datepicker #endpicker [opened]="currentOptionForReservingDevice === 'Custom'"></mat-datepicker>
            </span>
            <span *ngIf="option === 'Custom' && currentOptionForReservingDevice === 'Custom' && searchEndDate" class="time-block">Now till {{formatDate(searchEndDate)}} &nbsp; 23:59</span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row justify-content-end mt-2">
        <button class="btn cancel_modal_btn  mr-3" (click)="closeModalEnd()" >Cancel</button>
        <button class="btn confirm_btn_modal " (click)="bookedDevice()">Book</button>
      </div>
    </div>
    </div>
</ng-template>

<ng-template #extendModal let-modal>
  <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Extend device booking for...</h5>
    <svg (click)="closeModalEnd()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" class="close_modal-window">
      <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
    </svg>
  </div>
  <div class="modal-body p-1">
    <div class="row col-md-12 m-auto justify-content-center">
      <div class="radio-button-container">
        <div
                class="reservation-options"
                *ngFor="let option of optionsListForExtendingDevice; let i = index"
        >
          <label
                  [for]="i"
                  class="option-reservation-label"
                  [ngClass] = "(i !== 1 || !isDeviceBookedForAWeek) ? 'option-reservation-label-padding' : ''"
          >
            <input
                    name="mode"
                    [disabled]=""
                    id="{{ i }}"
                    type="radio"
                    [value]="option"
                    [(ngModel)]="currentOptionForExtendingDevice"
                    (change) = "updateCurrentExtendOption(option)"
                    *ngIf="i !== 1 || !isDeviceBookedForAWeek"
            />
           <span *ngIf="i !== 1 || !isDeviceBookedForAWeek"> {{  option }} </span>

            <span *ngIf="option === 'Day' && currentOptionForExtendingDevice === 'Day'" class="time-block">till {{timeExtendTillDay}} &nbsp; 23:59</span>
            <span *ngIf="option === 'This week' && currentOptionForExtendingDevice === 'This week' && !isDeviceBookedForAWeek" class="time-block">till {{timeExtendTillWeek}} &nbsp; 23:59</span>
            <span *ngIf="option === 'Custom'">
              <img *ngIf="currentOptionForExtendingDevice !== 'Custom'" (click)="endpicker.open()" src="../../../../assets/images/NewUI/calendar_grey.png" alt="Default Icon" class="custom-icon" />
              <img *ngIf="currentOptionForExtendingDevice === 'Custom'" (click)="endpicker.open()" src="../../../../assets/images/NewUI/calendar_color.png" alt="Selected Icon" class="custom-icon" />
                <input class="reservation-input" matInput [matDatepicker]="endpicker" [min]="minDateCal" [(ngModel)]="searchEndDate" >
                <mat-datepicker #endpicker [opened]="currentOptionForExtendingDevice === 'Custom'"></mat-datepicker>
            </span>
            <span *ngIf="option === 'Custom' && currentOptionForExtendingDevice === 'Custom' && searchEndDate" class="time-block">Now till {{formatDate(searchEndDate)}} &nbsp; 23:59</span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row justify-content-end mt-2">
        <button class="btn cancel_modal_btn  mr-3" (click)="closeModalEnd()" >Cancel</button>
        <button class="btn confirm_btn_modal " (click)="bookedDevice()">Extend</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deviceRequest let-modal>
  <app-spinner *ngIf="isLoading1"></app-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Request New Devices</h5>
  </div>
  <div class="modal-body p-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="requestForm" class="p-2">
            <div class="inputs">
              <div class="form-group mt-3 mb-1">
                <div class="position-relative mt-3 mb-2">
                  <label class="font_14">Device Type</label>
                  <select (change)="ChangeDeviceType()" class="form-control select_input" formControlName="deviceType">
                    <option value="">Select Device Type</option>
                    <option value="apple">Apple</option>
                    <option value="android">Android</option>
                  </select>

                  <div
                    *ngIf="requestForm.controls['deviceType'].invalid && (requestForm.controls['deviceType'].dirty || requestForm.controls['deviceType'].touched)"
                    class="errorinput text-left">
                    <div *ngIf="requestForm.controls['deviceType'].errors.required">* Device Type is required.</div>
                  </div>
                </div>
                <div class="position-relative mt-3 mb-2" *ngIf="requestForm.controls['deviceType'].value == 'android'">
                  <label class="font_14">OEM</label>
                  <input type="text" formControlName="oem" maxlength="40"
                    class="form-control login_input1 position-relative"
                    placeholder="OEM " />
                  <div *ngIf="requestForm.controls['oem'].invalid && (requestForm.controls['oem'].dirty || requestForm.controls['oem'].touched)" class="errorinput text-left">
                    <div *ngIf="requestForm.controls['oem'].errors.required">*OEM is required.</div>
                  </div>
                </div>
                <div class="position-relative mt-3 mb-2">
                  <label class="font_14">Model Name</label>
                  <input type="text" formControlName="modelName" maxlength="50"
                    class="form-control login_input1 position-relative"
                    placeholder="Model Name" />
                  <div
                    *ngIf="requestForm.controls['modelName'].invalid && (requestForm.controls['modelName'].dirty || requestForm.controls['modelName'].touched)"
                    class="errorinput text-left">
                    <div *ngIf="requestForm.controls['modelName'].errors.required">*Model Name is required.</div>
                  </div>
                </div>
                <div class="position-relative mt-3 mb-2">
                  <label class="font_14">OS Version</label>
                  <input type="text" formControlName="osVersion" maxlength="10"
                    class="form-control login_input1 position-relative"
                    placeholder="OS Version"/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row justify-content-center">
      <button type="submit" class="btn cancel_modal_btn"  (click)="closeModal()">Cancel</button>
      <button type="submit" class="btn confirm_btn_modal ml-2" [disabled]="!requestForm.valid" (click)="submitRequest()">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #resetModal let-modal>
  <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Reset Device</h5>
  </div>
  <div class="modal-body p-5">
    <div class="col-md-12 p-0 m-0 text-center">
      <p class="modal-inner-text">We are unable to reset the device. Please contact admin.</p>
    </div>
    <div class="row col-md-12 justify-content-center mt-5 mr-0 ml-0">
      <button class="btn cancel_modal_btn" (click)="closeModal()">Ok</button>
    </div>
  </div>
</ng-template>

<mat-drawer #drawer mode="over" position="end">
  <app-howto-sidepanel (toggleSidepanel)="toggleSidepanelAction()"></app-howto-sidepanel>
</mat-drawer>
