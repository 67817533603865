export enum TelephonyActionType {
    DIALING = "MO_DIALING_MT",
    ANSWER = "MT_ANSWER",
    SPEAKING = "MO_MT_SPEAKING",
    END = "MT_END_CALL",
    DELAY = "ITERATION_DELAY",
    MT_START = "MT_WAIT_START",
    MT_SPEAKING = "MT_SPEAKING",
    VOICEMAIL_ACTIVATION = "MO_MT_VOICEMAIL_ACTIVATION",
    VOICEMAIL_RECORDING = "MO_VOICEMAIL_RECORDING",
    MO_END = "MO_END_CALL",
    MO_MT_RINGING = "MO_MT_RINGING",
    MT_RINGING = "MT_RINGING",
    MT_WAIT_END = "MT_WAIT_END",
    MO_DIALING = "MO_DIALING",
    RMT_ANSWER = "RMT_ANSWER",
    MO_SPEAKING = "MO_SPEAKING",
    MO_WAIT_END = "MO_WAIT_END",
    MO2_DIALING_MT = "MO2_DIALING_MT",
    MO2_MT_RINGING = "MO2_MT_RINGING",
    MO_MO2_MT_SPEAKING = "MO_MO2_MT_SPEAKING",
    MT_MERGE_CALL = "MT_MERGE_CALL",
    ANSWER2 = "MT_ANSWER2",
    MT_SWAP_CALL = "MT_SWAP_CALL",
    MO_DIALING_MT2 = "MO_DIALING_MT2",
    MO_MT2_RINGING = "MO_MT2_RINGING",
    MT2_ANSWER = "MT2_ANSWER",
    MO_MT_MT2_SPEAKING = "MO_MT_MT2_SPEAKING",
    MT2_END_CALL = "MT2_END_CALL",
    MO_MESSAGING = "MO_MESSAGING",
    MESSAGING = "MO_MESSAGING_MT",
    RECEIVE_SMS = "MT_RECEIVE_SMS",
}

export enum TelephonyActionStatus {
    IDLE = "IDLE",
    PROGRESS = "PROGRESS",
    PASS = "PASS",
    FAILED = "FAILED",
    CANCELED = "CANCELED",
}

export interface TelephonyAction {
    order?: number;
    status?: TelephonyActionStatus;
    type: TelephonyActionType;
    data: {
        time?: number;
        timestamp: number;
        finishedAt?: number;
        moDeviceIndex?: number;
        mtDeviceIndex?: number;
    };
    errors?: [{
        code: string;
        message: string;
    }];
    input?: {
        message: string;
        metrics: any[];
    };
}

export const ActionTypeMap = new Map<TelephonyActionType, string>([
    [TelephonyActionType.DIALING, 'MO dials to MT'],
    [TelephonyActionType.ANSWER, 'MT answers call'],
    [TelephonyActionType.SPEAKING, 'Verify Connected state'],
    [TelephonyActionType.END, 'MT ends up call'],
    [TelephonyActionType.DELAY, 'Delay'],
    [TelephonyActionType.MT_START, 'RMO device dials MT'],
    [TelephonyActionType.MT_SPEAKING, 'Verify Connected state'],
    [TelephonyActionType.VOICEMAIL_ACTIVATION, 'Call forwarded to Voicemail'],
    [TelephonyActionType.VOICEMAIL_RECORDING, 'Verify Connected state'],
    [TelephonyActionType.MO_END, 'MO ends up call'],
    [TelephonyActionType.MO_MT_RINGING, 'Verify Ringing state'],
    [TelephonyActionType.MT_RINGING, 'Verify Ringing state'],
    [TelephonyActionType.MT_WAIT_END, 'MT closes call'],
    [TelephonyActionType.MO_DIALING, 'MO dials to RMT'],
    [TelephonyActionType.RMT_ANSWER, 'RMT answers call'],
    [TelephonyActionType.MO_SPEAKING, 'Verify Connected state'],
    [TelephonyActionType.MO_WAIT_END, 'RMT closes call'],
    [TelephonyActionType.MO2_DIALING_MT, 'MO2 dials to MT'],
    [TelephonyActionType.MO2_MT_RINGING, 'Verify Ringing state'],
    [TelephonyActionType.MO_MO2_MT_SPEAKING, 'Verify Connected state'],
    [TelephonyActionType.MT_MERGE_CALL, 'MT performs Merge Calls'],
    [TelephonyActionType.ANSWER2, 'MT answers call'],
    [TelephonyActionType.MT_SWAP_CALL, 'MT performs Swap Call'],
    [TelephonyActionType.MO_DIALING_MT2, 'MO dials to MT2'],
    [TelephonyActionType.MO_MT2_RINGING, 'Verify Ringing state'],
    [TelephonyActionType.MT2_ANSWER, 'MT2 answers call'],
    [TelephonyActionType.MO_MT_MT2_SPEAKING, 'Verify Connected state'],
    [TelephonyActionType.MT2_END_CALL, 'MT2 ends up call'],
    [TelephonyActionType.MO_MESSAGING, 'MO sends SMS text to RMT'],
    [TelephonyActionType.MESSAGING, 'MO sends SMS text to MT'],
    [TelephonyActionType.RECEIVE_SMS, 'MT receives SMS from MO '],
]);

export const ActionIconMap = new Map<TelephonyActionType, string>([
    [TelephonyActionType.DIALING, 'dialing'],
    [TelephonyActionType.ANSWER, 'answer'],
    [TelephonyActionType.SPEAKING, 'verify'],
    [TelephonyActionType.END, 'end'],
    [TelephonyActionType.DELAY, 'delay'],
    [TelephonyActionType.MT_START, 'mt_wait'],
    [TelephonyActionType.MT_SPEAKING, 'verify'],
    [TelephonyActionType.VOICEMAIL_ACTIVATION, 'voicemail'],
    [TelephonyActionType.VOICEMAIL_RECORDING, 'voicemail_rec'],
    [TelephonyActionType.MO_END, 'end'],
    [TelephonyActionType.MO_MT_RINGING, 'verify'], 
    [TelephonyActionType.MT_RINGING, 'verify'],
    [TelephonyActionType.MT_WAIT_END, 'end'],
    [TelephonyActionType.MO_DIALING, 'dialing'],
    [TelephonyActionType.RMT_ANSWER, 'answer'],
    [TelephonyActionType.MO_SPEAKING, 'verify'],
    [TelephonyActionType.MO_WAIT_END, 'end'],
    [TelephonyActionType.MO2_DIALING_MT, 'dialing'],
    [TelephonyActionType.MO2_MT_RINGING, 'dialing'],
    [TelephonyActionType.MO_MO2_MT_SPEAKING, 'verify'],
    [TelephonyActionType.MT_MERGE_CALL, 'merge'],
    [TelephonyActionType.ANSWER2, 'answer'],
    [TelephonyActionType.MT_SWAP_CALL, 'swap'],
    [TelephonyActionType.MO_DIALING_MT2, 'dialing'],
    [TelephonyActionType.MO_MT2_RINGING, 'verify'],
    [TelephonyActionType.MT2_ANSWER, 'answer'],
    [TelephonyActionType.MO_MT_MT2_SPEAKING, 'verify'],
    [TelephonyActionType.MT2_END_CALL, 'end'],
    [TelephonyActionType.MO_MESSAGING, 'sms_send'],
    [TelephonyActionType.MESSAGING, 'sms_send'],
    [TelephonyActionType.RECEIVE_SMS, 'sms_receive'],
]);
