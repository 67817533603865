import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDrawer} from '@angular/material/sidenav';
import {DeviceService} from '../../view-device/device.service';
import { GetLabListDto } from '../../../shared/dto';
import { TokenData } from '../../../shared/interfaces';
import { CommonService } from '../../../shared/common.service';
import { UserRoleEnum } from '../../../shared/enum';
import { ActivatedRoute } from '@angular/router';

enum stateTooltip {
  online = 'Online',
  testing = 'Testing',
  initializing = 'Device initialization in progress.',
  uninitialized = 'Device initialization is failed. Try to reset the device.',
  unauthorized = 'USB authorization is required. Contact admin. Manually unplug, then plug the device and follow the instructions on the device screen.',
  offline = 'Communication error. Contact admin. Manually unplug, then plug the device and follow the instructions on the device screen. In some cases, device reboot may be needed.',
  unconfigured = 'Developer mode and/or usb debugging is disabled on the device, or device is in fastboot. Manually turn on developer mode and "usb debugging" in developer options on device.',
  appeared = 'Developer mode and/or usb debugging is disabled on the device, or device is in fastboot. Manually turn on developer mode and "usb debugging" in developer options on device.',
  removed = 'The device is physically detached from the lab. Contact admin.',
  unknown = 'The state of the device is unknown. Contact admin for further instructions.',
  blocked = '',
  detached = 'The device has been detached. Try to attach the device.',
  disconnected = 'The device is physically detached from the lab. Contact admin.',
  pending = 'Device is waiting for an available slot in the lab.'
}

interface Property {
  name: string;
  value: string;
  category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
  editable?: boolean;
}
@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit, OnDestroy {
  locationListDropdown = [];
  deviceList = [];
  selectDeviceList = [];
  cardList = [];
  isLoading = false;
  isLoading1 = false;
  searchType = '';
  searchDevice = '';
  searchLocation = '';
  searchEmei = '';
  searchStartDate: any;
  searchEndDate: any;
  labType = '';
  deviceBookingDetail: any;
  deviceId = '';
  total = 0;
  pagelimit = 10;
  totalPage: number;
  currentPage = 0;
  totalDevice = 0;
  totalprice = 0;
  totalDay = 0;
  totalAmount = 0;
  userDetail = [];
  oemList = [];
  validSearch = false;
  closeResult: any;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  private modalRef: NgbModalRef;

  minDateCal: Date;
  userData: TokenData;
  labList = [];
  data: any;
  devicePollingList = [];
  pollingEventId: any;
  filterList = [];
  deviceState = '';
  DeviceListTemp = [];
  showError = ['UNINITIALIZED', 'UNAUTHORIZED', 'OFFLINE', 'UNCONFIGURED', 'APPEARED', 'DISCONNECTED', 'BLOCKED', 'UNKNOWN'];
  selectedBookedFilter = '';
  bookingFilterList = {
    available: [],
    booked: [],
    NA: []
};
  serialNumber = '';
  deviceInfo: any;
  properties: Property[];
  wifiConnect = '--';
  storage = '--';
  battery = '--';
  providerName = '--';
  selectedIndex: number;
  statusForm: FormGroup;
  searchEnterprise = '';
  enterpriseOptions = [];

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('property') property: MatDrawer;
  constructor(private adminService: AdminService,
              private fb: FormBuilder,
              private _snackBar: MatSnackBar,
              private modalService: NgbModal,
              private deviceService: DeviceService,
              private commonService: CommonService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.userData = this.commonService.getUser();
    this.minDateCal = new Date();
    this.getRouteQuery();
    this.getOem();
    this.getLabsList();
    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.getEnterpriseList();
    }
    this.changeDeviceLocation();
    this.AvailableDeviceList();
  }

  onDeviceTypeSelectChange(): void {
    this.changeDeviceLocation();
    this.AvailableDeviceList();
    this.validateSearchChange();
  }

  changeDeviceLocation() {
    this.isLoading = true;
    let query = '';
    if (this.searchType) {
      query = query + '&oem=' + this.searchType;
    }
    if (this.searchDevice) {
      query = query + '&modelName=' + this.searchDevice;
    }
    this.adminService.getLocationAPI(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.locationListDropdown = res.data.locationList;
      }
    }, err => {
      this.isLoading = false;
    });

  }
  AvailableDeviceList() {
    this.isLoading = true;
    const query = this.queryParam();
    this.adminService.smartDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.deviceList = res.data.deviceList;
        this.deviceList.forEach((element, i) => {
          if (element['deviceCommunicationStatus'].toLowerCase() == 'remote-testing') {
              element['deviceStateCode'] = 'TESTING';
          }
          if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
            element['deviceStateCode'] = 'DISCONNECTED';
          }
          if (!element['deviceStateCode'] || (element['deviceStateCode']
              && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
            element['deviceStateCode'] = 'UNKNOWN';
          }
        });
        this.DeviceListTemp = res.data.deviceList;
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
        this.bookingFilterList = this.getFilterList(this.deviceList);
        if (res.data.deviceList.length === 0) {
          return this._snackBar.open('No device available!', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['failure']
          });
        }

        this.deviceList.forEach(element => {
          element['selectDevice'] = false;
          element['startEpoc'] = this.searchStartDate;
          element['endEpoc'] = this.searchEndDate;
          element['isEnabled'] = false;
          element['resetCount'] = 0;
        });
        if (this.deviceState !== '') {
          this.localFilter();
        }
      }
    }, err => {
      this.isLoading = false;
    });

  }

  LeaveDevice() {
    this.isLoading1 = true;
    const request = {
      deviceBookingId: this.deviceBookingDetail['bookingId']
    };
    this.adminService.releaseDevice(request).subscribe(
      (res) => {
        this.isLoading1 = false;
        this.modalRef.close();
        this.AvailableDeviceList();
      }, err => {
        this.isLoading1 = false;
      }
    );
  }

  openConfirmationModal(content, btn, data) {
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    this.deviceBookingDetail = data;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  searchDeviceList() {
    this.isLoading1 = true;
    const query = 'deviceId=' + this.deviceId + '&limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    this.userDetail = [];
    this.adminService.getDeviceHistoryListApi(query).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] === 'Success') {
        this.userDetail = res.data.userList;
        this.total = res.data.totalCount;
        this.SetPage();
      }
    }, err => {
      this.isLoading1 = false;
    });
  }

  SetPage() {
    this.totalPage = 0;
    this.totalPage = this.total / this.pagelimit;
    this.totalPage = Math.ceil(this.totalPage);
    if (this.totalPage === 1 || this.total <= this.pagelimit) {
      this.totalPage = 1;
    }
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.searchDeviceList();
  }
  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.searchDeviceList();
  }

  validatePrice() {
    this.totalDevice = 0;
    this.totalprice = 10;
    this.totalDay = 0;
    this.selectDeviceList = [];
    this.totalAmount = 0;
    this.deviceList.forEach(element => {
      if (element['selectDevice'] == true) {
        this.selectDeviceList.push(element['deviceId']);
        this.totalDevice = this.totalDevice + 1;
        // this.totalprice = this.totalprice + element['price'];
        this.totalDay = this.getNumberOfDays(this.searchStartDate, this.searchEndDate);
        if (this.totalDay === 0) {
          this.totalDay = 1;
        } else {
          this.totalDay = this.totalDay + 1;
        }
      }
    });
    this.totalAmount = this.totalprice * this.totalDay * this.totalDevice;
  }
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  }

  getOem() {
    this.adminService.getDeviceOem('').subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.oemList = res.data.oemList;
      }
    }, err => {
      this.isLoading = false;
    });
  }
  getLabsList() {
    this.adminService.getLabList('').subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        const data = res.data as GetLabListDto;
        this.labList = data.labList;
        this.total = data.labList.length;
      }
    }, err => {
      this.isLoading = false;
    });
  }

  queryParam() {
    let query = 'limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    if (this.searchStartDate && this.searchStartDate !== '') {
      // convert to epoch time
      query = query + '&startDate=' + this.getEpoc(this.searchStartDate);
    }
    if (this.searchEndDate && this.searchEndDate !== '') {
      // convert to epoch time
      const endDate = new Date(this.searchEndDate);
      endDate.setDate(endDate.getDate() + 1);
      query = query + '&endDate=' + this.getEpoc(this.searchEndDate)
    }
    if (this.searchType) {
      query = query + '&oem=' + this.searchType;
    }
    if (this.searchLocation) {
      query = query + '&location=' + this.searchLocation;
    }
    if (this.searchEmei) {
      query = query + '&imei=' + encodeURIComponent(this.searchEmei);
    }
    if (this.labType) {
      query = query + '&labId=' + this.labType;
    }
    if (this.searchEnterprise) {
      query = query + '&enterpriseId=' + this.searchEnterprise;
    }
    return query;
  }

  openModal(content, btn, data) {
    this.data = data;
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      centered: true,
    };
    this.prepareUpdateStatusForm();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  prepareUpdateStatusForm() {
    this.statusForm = this.fb.group({
      status: ['', [Validators.required]],
    });
  }
  updatePort() {
    if (this.statusForm.valid) {
      this.isLoading1 = true;
      const req = { deviceId: this.data.deviceId, hostPort: parseInt(this.statusForm.value.status) };
      this.adminService.updateDevice(req).subscribe(
        (res) => {
          this.isLoading1 = false;
          if (res['message'] === 'Success') {
            this.modalRef.close();
            this.AvailableDeviceList();
            this._snackBar.open('Port updated successfully!', '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['success'],
            });
          }
          this.isLoading1 = false;
        },
        (err) => {
          this.isLoading1 = false;
        }
      );
    }
  }
  clearFilter() {
    this.labType = '';
    this.searchType = '';
    this.searchDevice = '';
    this.searchLocation = '';
    const date = new Date();
    this.searchStartDate = date;
    this.searchEndDate = date;
    this.searchEmei = '';
    this.deviceList = [];
    this.deviceState = '';
    this.selectedBookedFilter = '';
    this.searchEnterprise = '';
    this.AvailableDeviceList();
    this.localFilter();
  }

  getEpoc(date) {
    let travelDay = date.getDate();
    if (travelDay < 10) { travelDay = '0' + travelDay; }
    let travelMonth = date.getMonth() + 1;
    if (travelMonth < 10) { travelMonth = '0' + travelMonth; }
    const travelYear = date.getFullYear();
    return travelMonth + '/' + travelDay + '/' + travelYear;
  }

  selectDevice(index) {
    this.deviceList[index]['selectDevice'] = true;
    this.validatePrice();
  }

  validateSearchChange() {
    if ( this.searchStartDate !== '' && this.searchEndDate !== '') {
      this.validSearch = true;
    } else {
      this.validSearch = false;
    }
  }

  openEditModal(content, btn) {
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    };
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  moreDetailModal(content, btn, data) {
    this.deviceId = data.deviceId;
    const query = 'deviceId=' + data.deviceId + '&limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    this.isLoading = true;
    this.adminService.getDeviceHistoryListApi(query).subscribe(
      (res) => {
        this.isLoading = false;
        if ((res['message'] === 'Success')) {
          this.modelOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'xl',
          };
          this.userDetail = res.data.userList;
          this.total = res.data.totalCount;
          this.SetPage();
          btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
          this.modalRef = this.modalService.open(content, this.modelOptions);
          this.modalRef.result.then(
            result => {
              this.closeResult = `Closed with: ${result}`;
            },
            reason => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  changePageLimit() {
    this.currentPage = 0;
    this.searchDeviceList();
  }
  closeModal() {
    this.modalRef.close();
  }

  textChange() {
    this.currentPage = 0;
  }
  usbAttachApi(deviceData: any, index: number) {
    if ( this.deviceList[index]['isEnabled'] === true) {
      return;
    }
    this.isLoading = true;
    this.deviceList[index]['isEnabled'] = true;
    this.adminService.usbAttach(deviceData['labDomain'], deviceData['serialNumber']).subscribe((res) => {
      this._snackBar.open('Device has been successfully attached. Status update might take several minutes.', "", {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['success'],
      });
      this.isLoading = false;
      this.checkdevicePulling(deviceData['serialNumber']);
    }, err => {
      this.isLoading = false;
    });
  }
  usbDetachApi(deviceData: any, index: number) {
    if ( this.deviceList[index]['isEnabled'] === true) {
      return;
    }
    this.isLoading = true;
    this.deviceList[index]['isEnabled'] = true;
    this.adminService.usbDetach(deviceData['labDomain'], deviceData['serialNumber']).subscribe((res) => {
      this._snackBar.open('Device has been successfully detached. Status update might take several minutes.', '', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['success'],
      });
      this.checkdevicePulling(deviceData['serialNumber']);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  checkdevicePulling(serialNumber: string) {
    if (this.devicePollingList.length === 0) {
       this.pollingEventId = setInterval(() => {
        this.devicePull();
      }, 10000);
    }
    const result  = this.devicePollingList.filter(x => x.serialNumber.toLowerCase() == serialNumber.toLowerCase());
    if (result.length === 0) {
      this.devicePollingList.push({serialNumber: serialNumber, count: 0});
    }
  }

  devicePull(): void {
    let deviceList = [];
    const query = this.queryParam();
    this.adminService.smartDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        deviceList = res.data.deviceList;
           // Update All device Section
           this.deviceList.map((e, i) => {
            const temp = deviceList.find(element => element.serialNumber === e.serialNumber);
            if (temp) {
              e.deviceState = temp.deviceState;
              e.deviceStateCode = temp.deviceStateCode;
              if (e.resetCount === 4) {
                e.isEnabled = false;
              }
            }
            const data =  this.devicePollingList.find(pulldevice => pulldevice.serialNumber === e.serialNumber);
            if (data) {
              e.resetCount = data.count;
            }
             this.devicePollingList = this.devicePollingList.filter(e => e.count < 5);
            if (this.devicePollingList.length === 0) {
              if (this.pollingEventId) {
                clearInterval(this.pollingEventId);
              }
            }
          });
          this.devicePollingList.forEach((resetDevice, index) => {
            this.deviceList.find(element => element.serialNumber === resetDevice.serialNumber).isEnabled = true;
                this.devicePollingList[index].count += 1;
          });
          deviceList.forEach((element, i) => {
            if (element['deviceCommunicationStatus'].toLowerCase() == 'remote-testing') {
                element['deviceStateCode'] = 'TESTING';
            }
            if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
              element['deviceStateCode'] = 'DISCONNECTED';
            }
          });
          this.filterList = this.groupBy(deviceList, 'deviceStateCode');
          this.bookingFilterList = this.getFilterList(this.deviceList);
          this.localFilter();
      }
    }, err => {
      this.isLoading = false;
    });
  }
  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.pollingEventId) {
      clearInterval(this.pollingEventId);
    }
  }

  getDisplayedStatus(status: string): string {
    let res = status;
    switch (status) {
      case 'testing':
        res = 'under test';
        break;
      case 'online':
        res = 'available';
        break;
    }
    return res;
  }

  getDeviceStatus(data: any): string {
    const state = data.deviceState;
    const stateCode = data.deviceStateCode;
    const commStatus = data.deviceCommunicationStatus;
    let displayed = 'unknown';
    if (state) {
      if (state.toLowerCase() === 'online') {
        if (stateCode.toLowerCase() === 'online') {
          if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
            displayed = 'testing';
          } else {
            displayed = 'online';
          }
        } else {
          displayed = stateCode.toLowerCase();
        }
      } else if (state.toLowerCase() === 'offline') {
        if (stateCode) {
          if (stateCode.toLowerCase() === 'removed') {
            displayed = 'disconnected';
          } else {
            displayed = stateCode.toLowerCase();
          }
        }
      } else if (state.toLowerCase() === 'blocked') {
        displayed = state.toLowerCase();
      }
    }
    return displayed;
  }

  showOfflineTooltip(data: any): string {
    const state = this.getDeviceStatus(data);
    if (state) {
      return stateTooltip[state];
    } else {
      return '';
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }
  ApplyFilter(value) {
    if (value === this.deviceState) {
      this.deviceState = '';
      this.deviceList = this.DeviceListTemp;
    } else {
      this.deviceState = value;
      this.localFilter();
    }
  }
  localFilter(): void {
    this.deviceList =  this.DeviceListTemp.filter(e => {
      if ((this.deviceState && e.deviceStateCode === this.deviceState || !this.deviceState.length) &&
          ((this.selectedBookedFilter === 'booked' && e.isBooked) ||
              (this.selectedBookedFilter === 'available' && !e.isBooked && e.deviceState === 'Online') ||
              (this.selectedBookedFilter === 'NA' && !e.isBooked && e.deviceState === 'Offline')
          || !this.selectedBookedFilter.length)) {
        return e;
      }
    });
  }
  deleteDevices() {
    this.isLoading1 = true;
    this.adminService.removedDevice(this.deviceBookingDetail['deviceId']).subscribe(
      (res) => {
        this.isLoading1 = false;
        this.modalRef.close();
        this.clearFilter();
      },

      err => {
        this.isLoading1 = false;
      }
    );
  }

  toggleSidepanelAction(): void {
    this.drawer.toggle();
  }

  togglePropertySidePanelAction(serialNumber?: string, i?: number): void {
    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.changeSelectedIndex(i);
      if (serialNumber) {
        this.serialNumber = serialNumber;
        this.getDeviceMainProperty();
        this.deviceService.getDeviceDynamicFields(serialNumber).subscribe(
            (res) => {
              if (res.message === 'Success') {
                this.properties = res.data.properties;
              }
            },
        );
      }
      this.property.toggle();
    }
  }

  getDeviceMainProperty() {
    const request = {
      'serialNumber': this.serialNumber,
      'screenShot': false
    };
    this.deviceService.getDeviceDetails(request).subscribe(
        (res) => {
          if (res.body.data.tool_Result.toUpperCase() === 'PASS') {
            this.deviceInfo = res.body.data.tool_Extra;
            if (this.deviceInfo.controls) {
              this.deviceInfo.additionalInfo.forEach(element => {
                if (element.name === 'WIFI') {
                  if (this.deviceInfo.oem !== 'Apple') {
                    if (element.settingsInfo === '~~' || element.settingsInfo === 'No WiFi Connected~~') {
                      this.wifiConnect = 'Not Connected';
                    } else {
                      if (element.settingsInfo.match('<unknown ssid>')) {
                        this.wifiConnect = 'Connected';
                      } else {
                        this.wifiConnect = 'Connected';
                      }
                    }
                  }
                }
                if (element.name === 'Storage') {
                  this.storage = element.settingsInfo;
                }
                if (element.name === 'Battery') {
                  if (this.deviceInfo.oem === 'Apple') {
                    this.battery = element.settingsInfo;
                    if (element.settingsInfo === '1.0') {
                      this.battery = 'Fully charged';
                    } else if (element.settingsInfo === '-1.0') {
                      this.battery = 'Charging';
                    } else {
                      this.battery = 'Charging';
                    }
                  } else {
                    this.battery = element.settingsInfo + '%' + ' Charged';
                  }
                }
                if (element.name === 'Network') {
                  const providerNameTemp = element.settingsInfo;
                  // we are getting provider Name + Signal Strength separated by '~' example: "settingsInfo": "YOTA~LTE (-120 dBm)~"
                  this.providerName = providerNameTemp.split('(')[0] || '--';
                }
              });
            }
          }
        });
  }

  resetCambrionix(labUrl: string, serialNumber: string, index: number) {
    this.enableResetBtn(index);
    this.adminService.resetCambrionix(serialNumber, labUrl).subscribe((res) => {
      this.checkdevicePulling(serialNumber);
      this.devicePull();
      this._snackBar.open('Device reset request sent ', '', {
        duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
         panelClass: ['success'],
       });
    }, err => { });
  }

  closeReset (index) {
      this.deviceList[index]['resetCount'] = 0;
      this.deviceList[index]['isResetting'] = false;
  }
  enableResetBtn(index) {
    this.deviceList[index]['isResetting']= true;
  }
  filterByBooked(type: 'available' | 'booked' | 'NA'): void {
    if (this.selectedBookedFilter.length
        && ((this.selectedBookedFilter === 'available' && type === 'available') || (this.selectedBookedFilter === 'booked' && type === 'booked') || (this.selectedBookedFilter === 'NA' && type === 'NA'))) {
      this.selectedBookedFilter = '';
      this.deviceList = this.DeviceListTemp;
      this.localFilter();
    } else {
      this.selectedBookedFilter = type;
      this.localFilter();
    }
  }
  getLabName(labId: any) {
    const result  = this.labList.find(x => x.labId.toLowerCase() == labId.toLowerCase());
      return result?.name || '--';
  }

  getFilterList(list) {
    const availableObjects = list.filter(device => !device.isBooked && device.deviceState === 'Online');
    const bookedObjects = list.filter(device => device.isBooked);
    const naObjects = list.filter(device => !device.isBooked && device.deviceState === 'Offline');

    return {
      available: availableObjects,
      booked: bookedObjects,
      NA: naObjects
    };
  }

  changeSelectedIndex(i: number): void {
    this.selectedIndex = i;
  }

  handleContextMenuClick($event: MouseEvent): void {
    $event.stopPropagation();
  }

  showDetachButton(device) {
    return device.usbHubType && device.usbHubType !== 'GENERAL' && device.isSmartHubEnabled
      && device.deviceStateCode == 'ONLINE'
      && this.getDeviceStatus(device).toLowerCase() !== 'testing';
  }

  getEnterpriseList() {
      this.isLoading = true;
      this.adminService.getEnterprise('').subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
        }
      }, err => {
        this.isLoading = false;
      });
  }

  getRouteQuery() {
    const enterpriseId = this.route.snapshot.queryParamMap.get('enterpriseId');
    if (enterpriseId) {
      this.searchEnterprise = enterpriseId;
    }
  }
}

