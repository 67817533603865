import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimpleApiResponse } from '../../shared/dto';
import { NetworkService } from '../../shared/network.service';
import { actionIcon, ActionStatus } from './models/record-and-play.model';

@Injectable({
  providedIn: 'root'
})
export class RecordAndPlayService {

  constructor(private networkService: NetworkService) { }

  getRecordAndPlayList(deviceId: string): Observable<any> {
    return this.networkService.get('api/recordPlay/list?', null, null, 'basic');
    //  + 'deviceId=' + deviceId
  }

  getRecordAndPlayTest(id: string): Observable<any> {
    return this.networkService.get('api/recordPlay/' + id, null, null, 'basic');
  }

  saveRecordAndPlayTest(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay', body, null, 'basic');
  }

  deleteRecordAndPlayTest(id: any): Observable<any> {
    return this.networkService.delete('api/recordPlay/' + id, null, 'basic');
  }

  renameRecordAndPlayTest(data: any): Observable<any> {
    return this.networkService.put('api/recordPlay/', data, null, 'basic');
  }

  deleteRecordApi(id): Observable<any> {
    return this.networkService.deleteDir('api/recordPlay/' + id, null, null, 'basic');
  }

  startTestReplay(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/start', body, null, 'basic');
  }

  stopTestReplay(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/stop', body, null, 'basic');
  }

  getLastAction(recordReplayId: string): Observable<any> {
    return this.networkService.get('api/recordPlay/plays/action/last?' + `recordReplayId=${recordReplayId}`, null, null, 'basic');
  }

  getAllTestReplayList(recordId: string, recordReplayId: string, deviceId: string, status: string): Observable<any> {
    let options = '';
    if (recordId.length) {
      options += `recordId=${recordId}`;
    }
    if (recordReplayId.length) {
      options = options.length ? options + `&recordReplayId=${recordReplayId}` : options + `recordReplayId=${recordReplayId}`;
    }
    if (deviceId.length) {
      options = options.length ? options + `&deviceId=${deviceId}` : options + `deviceId=${deviceId}`;
    }
    if (status.length) {
      options = options.length ? options + `&status=${status}` : options + `status=${status}`;
    }
    return this.networkService.get('api/recordPlay/plays?' + options, null, null, 'basic');
  }

  getLogsAndVideoLinksForReplay(recordReplayId: string, type: string): Observable<any> {
    return this.networkService.get('api/recordPlay/plays/download?'
      + `type=${type}&recordReplayId=${recordReplayId}`, null, null, 'basic');
  }

  handleArtifactsAfterStop(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/test-interruption/artefacts', body, null, 'basic');
  }

  deleteReplayRecord(id: string): Observable<SimpleApiResponse> {
    return this.networkService.deleteDir('api/recordPlay/plays/' + id, null, null, 'basic');
  }

  displayDate(date: number): string {
    let res = 'Select date range';
    if (date > 0) {
      const d = new Date(date);
      res = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    }
    return res;
  }

  getActionIconName(type: string, status: string): string {
    const actionName = actionIcon[type] ? actionIcon[type] : 'start';
    const specific = status.length && (status === ActionStatus.PASS || status === ActionStatus.FAILED);
    if (type === 'set location' || type === 'reset location') {
      return specific ? 'change_location' + '_' + status.toLowerCase() : 'change_location';
    } else if (type === 'mobile-data') {
      return specific ? 'mobile_network' + '_' + status.toLowerCase() : 'mobile_network';
    } else {
      return specific ? actionName + '_' + status.toLowerCase() : actionName;
    }
  }

  getActionName(type: string): string {
    if (type === 'adbInput') {
      return 'INPUT TEXT';
    } else if (type === 'adbValue') {
      return 'PRESS KEY';
    } else {
      return type.toUpperCase();
    }
  }

  calcHoursToDisplay(timestamp: number): string {
    // show hours in action timestamp if needed
    const hourInMS = 2000 * 60 * 60;
    return timestamp / hourInMS > 1 ? Math.floor(timestamp / hourInMS) + ':' : '';
  }

  displayDetails(action: any): string {
    let res = '';
    if (action.type === 'click') {
      res = `${Math.round(action.data.xAxis)}, ${Math.round(action.data.yAxis)}, duration ${action.data.duration}`;
    } else if (action.type === 'swipe' || action.type === 'dragNDrop') {
      res = `${Math.round(action.data.startX)}, ${Math.round(action.data.startY)} to ${Math.round(action.data.endX)}, ${Math.round(action.data.endY)}`;
    } else if (action.type === 'adbInput') {
      res = action.data.inputText;
    } else if (action.type === 'adbValue') {
      res = action.data.value;
    } else if (action.type === 'pinch' || action.type === 'doubleTap' || action.type === 'tripleTap') {
      res = `${Math.round(action.data.xAxis)}, ${Math.round(action.data.yAxis)}`;
    }
    return res;
  }

  formatScreenResolution(screenResolution: any): string {
    let res = 'N/A';
    if (screenResolution && screenResolution.screenWidth && screenResolution.screenHeight) {
      res = screenResolution.screenWidth + 'x' + screenResolution.screenHeight;
    }
    return res;
  }

  downloadAllArtifacts(testId: string): Observable<any> {
    return this.networkService.getZip(`api/recordPlay/${testId}/download-zip`, null, null, null);
  }
}
