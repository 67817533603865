<mat-accordion multi>
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let iteration of iterations; index as i"
        [expanded]="expandedRows.has(i) || iteration.status === 'PROGRESS'">
        <mat-expansion-panel-header (click)="handleRowSelection(i)">
            <div class="iteration">
                <mat-panel-title class="iteration_title">
                    Iteration {{iteration.order}}
                </mat-panel-title>
                <mat-spinner diameter="24" *ngIf="iteration.status === 'PROGRESS'"></mat-spinner>
                <mat-panel-description class="iteration_item flex-grow"
                    [ngClass]="{'passed': iteration.status === 'PASS', 'failed': iteration.status === 'FAILED', 'canceled': iteration.status === 'CANCELED'}">
                    <span class="iteration_status"
                        *ngIf="iteration.status !== 'IDLE' && iteration.status !== 'PROGRESS'">{{displayIterationStatus(iteration.status)}}</span>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>
        <div class="action-error" *ngFor="let error of iteration?.actions[0]?.errors">
            <span>ERROR</span>
            <span class="error-message">{{error.message}}</span>
        </div>
        <div class="tabs">
            <span [ngClass]="{ active: tabs[i]}" (click)="openTab(i)">Results</span>
            <span [ngClass]="{ active: !tabs[i]}" (click)="openTab(i)">Raw data</span>
        </div>
        <div *ngIf="tabs[i]">
            <table class="table test-list-table">
                <thead class="tableheading">
                    <tr *ngIf="iteration?.actions[0]?.input?.metrics.length > 0">
                        <th scope="col">Interval</th>
                        <th scope="col">Transfer</th>
                        <th scope="col">Bitrate</th>
                        <th scope="col" *ngIf="iteration?.actions[0]?.input?.metrics[0]?.retr">Retries</th>
                        <th scope="col" *ngIf="iteration?.actions[0]?.input?.metrics[0]?.cwnd">Congestion Window</th>
                        <th scope="col" *ngIf="iteration?.actions[0]?.input?.metrics[0]?.total">Total Datagrams</th>
                        <th scope="col" *ngIf="iteration?.actions[0]?.input?.metrics[0]?.jitter">Jitter</th>
                        <th scope="col" *ngIf="iteration?.actions[0]?.input?.metrics[0]?.lostTotal">Lost/Total Datagrams</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="position-relative" *ngFor="let data of iteration?.actions[0]?.input?.metrics">
                        <td>{{data.interval}}</td>
                        <td>{{data.transfer}}</td>
                        <td>{{data.bitrate}}</td>
                        <td *ngIf="data.retr">{{data.retr}}</td>
                        <td *ngIf="data.cwnd">{{data.cwnd}}</td>
                        <td *ngIf="data.total">{{data.total}}</td>
                        <td *ngIf="data.jitter">{{data.jitter}}</td>
                        <td *ngIf="data.lostTotal">{{data.lostTotal}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!tabs[i]">
            <div class="copy-raw-data">
                <span class="copy-icon" [tp]="tooltipComponent" [tpData]="'Copy raw data' | tooltipData"
                    tpPlacement="left" (click)="copyRawData(i)">
                    <img alt="" src="../../../../../assets/images/NewUI/copy_icon.svg">
                </span>
            </div>
            <pre class="speed-test-raw-data">
                {{'\n' + iteration.actions[0].input.message}}
            </pre>
        </div>
    </mat-expansion-panel>
</mat-accordion>