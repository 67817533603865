import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkService } from '../shared/network.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { ApiResponseDto } from '../shared/dto';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceLocal {
  private pageChange = new BehaviorSubject('false');
  currentPage = this.pageChange.asObservable();

  constructor(
    private networkService: NetworkService,
    private afAuth: AngularFireAuth
    ) { }

  Page(message: string) {
    this.pageChange.next(message);
  }
  validateLogin(req: any) {
    return this.networkService.login('api/auth/login', req, null, null);
  }
  registerUser(req: any) {
    return this.networkService.login('api/users', req, null, null);
  }
  socialLogin(body: any) {
    return this.networkService.post('api/auth/social/login', body, null, 'basic');
  }
  forgotPassword(req: any) {
    return this.networkService.post('api/auth/forgotPassword', req, null, null);
  }
  verifyEmail(body: any) {
    return this.networkService.post('api/users/verifyEmail', body, null, null);
  }
  createOTP(body: any) {
    return this.networkService.post('api/otp/create', body, null, null);
  }
  verifyOTP(body: any) {
    return this.networkService.post('api/otp/verify', body, null, null);
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      // @ts-ignore
      this.afAuth.auth.signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {

          reject(err);
        });
    });
  }
  changeOPT(req: { password: string }): Observable<ApiResponseDto<{ message: string }>> {
    return this.networkService.post('api/auth/password/otp/change/', req, null, 'basic');
  }
}
