import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    transform(value: number, point = 2): string {
        if (!value) return '-';
        if (value < 1024) return `${value.toFixed(point)} B`;
        const kb = value / 1024;
        if (kb < 1024) return `${kb.toFixed(point)} KB`;
        const mb = kb / 1024;
        if (mb < 1024) return `${mb.toFixed(point)} MB`;
        const gb = mb / 1024;
        return `${gb.toFixed(point)} GB`;
    }
}