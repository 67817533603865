import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceService} from '../device.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TooltipService} from '../../../shared/tooltip.service';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import { TokenData } from '../../../shared/interfaces';
import { UserRoleEnum } from '../../../shared/enum';

interface Property {
    name: string;
    value: string;
    category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
    editable?: boolean;
}

type StatePropertyUpdate = 'create' | 'change';

@Component({
    selector: 'property-side-panel',
    templateUrl: 'property-side-panel.component.html',
    styleUrls: ['property-side-panel.component.scss']
})

export class PropertySidePanelComponent  {
    @Output() togglePropertySidePanel = new EventEmitter<boolean>();
    @Input() serialNumber: string;
    @Input() deviceInfo: any;
    @Input() showLoader: boolean;
    @Input() properties: Property[];
    @Input() userData: TokenData;
    @Input() wifiConnect: string;
    @Input() storage: string;
    @Input() battery: string;
    @Input() providerName: string;
    activeTab = 'device'

    tooltipComponent = null;
    constructor(
        private deviceService: DeviceService,
        private modalService: NgbModal,
        private dialogModalService: DialogModalService,
        private tooltipService: TooltipService
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    private modalRef: NgbModalRef;
    nameProperty = '';
    valueProperty: string;
    category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
    titleModalProperty: string;
    titleModalButton: string;
    statePropertyUpdate: StatePropertyUpdate;
    selectedProperty: Property;
    isNamePristine = true;
    propertyWarning = `Warning: Property with this name already exists. New property won't be created, instead value of the existing one will be updated.`;
    isPropExistInProperties = false;
    modelOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
    };
    ignoreTyping = false;
    propertiesCash: Property[];

    toggleDrawer(): void {
        this.deviceInfo = null;
        this.battery = '';
        this.storage = '';
        this.togglePropertySidePanel.emit(true);
    }

    openModal(content, title?: string, button?: string, state?: StatePropertyUpdate, prop?: Property) {
        if (title) {
            this.titleModalProperty = title;
        }
        if (button) {
            this.titleModalButton = button;
        }
        if (state) {
            this.statePropertyUpdate = state;
        }
        if (prop) {
            this.selectedProperty = prop;
            this.nameProperty = prop.name;
            this.valueProperty = prop.value;
            this.category = prop.category;
        }
        this.modelOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'md',
        };
        this.modalRef = this.modalService.open(content, this.modelOptions);
    }
    closeModal() {
        if (this.modalRef) {
            this.nameProperty = '';
            this.valueProperty = '';
            this.isNamePristine = true;
            this.isPropExistInProperties = false;
            this.selectedProperty = null;
            this.category = null;
            this.modalRef.close();
        }
    }

    updateIgnoreTyping(v: boolean): void {
        this.ignoreTyping = v;
    }

    getDeviceDynamicFields(): void {
        if (!this.serialNumber) {
            return;
        }
        this.deviceService.getDeviceDynamicFields(this.serialNumber).subscribe(
            (res) => {
                if (res.message === 'Success') {
                    this.properties = res.data.properties;
                }
            },
        );
    }

    updateProperty() {
        this.propertiesCash = [...this.properties];
        if (this.statePropertyUpdate === 'create') {
            if (this.isPropExistInProperties && !this.isNamePristine) {
                return this.changeProperty();
            } else {
                return this.createProperty();
            }
        }
        if (this.statePropertyUpdate === 'change') {
            this.changeProperty();
        }
    }

    createProperty() {
        this.propertiesCash.push({
            name: this.nameProperty,
            value: this.valueProperty,
            category: this.userData.role === UserRoleEnum.ADMIN ? 'ADMIN_FIELDS' : 'DYNAMIC_FIELDS'
        });
        return this.sendProperty();
    }

    changeProperty() {
        this.propertiesCash = this.propertiesCash.filter((prop) => prop !== this.selectedProperty);
        this.selectedProperty = null;
        this.propertiesCash.push({
            name: this.nameProperty,
            value: this.valueProperty,
            category: this.category
        });
        this.sendProperty();
    }

    openDeleteDynamicPropertyDialog(prop) {
        this.dialogModalService.openConfirmationDialog('deleteDynamicProperty', () => this.deleteProperty(prop));
    }

    deleteProperty(property) {
        this.propertiesCash = [...this.properties];
        this.propertiesCash = this.propertiesCash.filter((prop) => prop !== property);
        this.sendProperty();
    }

    sendProperty() {
        this.propertiesCash.forEach((property) => {
            delete property.editable;
        });
        const request = {
            properties: this.propertiesCash
        };
        this.deviceService.updateDeviceDynamicFields(this.serialNumber, request).subscribe().add(() => {
            this.getDeviceDynamicFields();
        });
        this.nameProperty = '';
        this.valueProperty = '';
        this.closeModal();
    }

    compareProperty() {
        this.isPropExistInProperties = this.properties.some((prop) => {
            return Object.keys(prop).some(() => {
                if (prop['name']?.toString() === this.nameProperty && this.statePropertyUpdate === 'create') {
                    this.selectedProperty = prop;
                    this.category = prop.category;
                }
                return prop['name']?.toString() === this.nameProperty;
            });
        });
    }
}
