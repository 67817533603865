<app-spinner *ngIf="isLoading"></app-spinner>
<div class="right-side-tabs-container" *ngIf="!isBookingExpired" id="right-side-main-split"
     [ngClass]="{ 'adb-opened': isInfotab == TabNameEnum.ADB }">
    <div class="control-right-icon">
        <ul [ngClass]="{disabled_usb: !isUsbAttach || this.isReqAttach || isAttachReq || recordInProgress}">
            <li (click)="navigateTab(TabNameEnum.INFO)" [ngClass]="{
              active_tab: isInfotab == TabNameEnum.INFO,
              disabled_usb: manualTestService.mtSessionStatus === 1 || replayInProgress}">
                <img class="img-fluid mt-2" style="width: 16px" src="../../../../assets/images/NewUI/device_list.svg"
                     alt="" />
                <p class="device-info-value">Info</p>
            </li>
<!--            <li class="position-relative" (click)="navigateTab(TabNameEnum.ADB)"-->
<!--                [ngClass]="{active_tab: isInfotab == TabNameEnum.ADB, disabled_usb: showAppiumTestModeDetails || communicationStatus !== 'READY' || manualTestService.mtSessionStatus === 1 || replayInProgress}"-->
<!--                *ngIf="deviceDetail?.oem !== 'Apple'">-->
<!--                <img class="img-fluid mt-2" style="width: 25px" src="../../../../assets/images/NewUI/logs-kate-icon.svg"-->
<!--                     alt="" />-->
<!--                <p class="device-info-value">ADB</p>-->
<!--                <img class="Img_" *ngIf="logcatInProgress == true && isInfotab !== TabNameEnum.LOGCAT"-->
<!--                     src="../../../../assets/images/NewUI/log_cate_loader.gif" alt="" />-->
<!--            </li>-->
<!--            <li class="position-relative" (click)="navigateTab(TabNameEnum.DEBUG)"-->
<!--                [ngClass]="{active_tab: isInfotab == TabNameEnum.DEBUG, disabled_usb: showAppiumTestModeDetails || communicationStatus !== 'READY' || manualTestService.mtSessionStatus === 1 || replayInProgress}"-->
<!--                *ngIf="deviceDetail?.oem === 'Apple'">-->
<!--                <img class="img-fluid mt-2" style="width: 25px" src="../../../../assets/images/NewUI/debug.svg" alt="" />-->
<!--                <p class="device-info-value">Debug</p>-->
<!--                <img class="Img_" *ngIf="logcatInProgress == true && isInfotab !== 'LOGCAT'"-->
<!--                     src="../../../../assets/images/NewUI/log_cate_loader.gif" alt="" />-->
<!--            </li>-->
            <div class="container-with-border">
            <span (click)="[navigateTab(TabNameEnum.FILE_STRUCTURE),getNotification()]" *ngIf="notifyList.length > 0">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="NotificationButton">
                <span class="infoIcon ">
                  <img src="../../../../assets/images/notification-bing.svg" alt="" style="height: 16px; width: 16px" />
                </span>
              </button>
              <mat-menu #menu="matMenu">
                <div class="text-right p-2">
                  <button class="btn btn-small"
                          style="padding:5px; background-color: #eee; color: #5E2CED; font-size: 10px;"
                          (click)="PostNotifyRead('All')">Clear All</button>
                </div>
                <div>
                  <div class="p-2 notification" *ngFor="let item of notifyList" (click)="notify(item)">
                    {{ item?.message }} -- <b>{{item.insertDate * 1000 | date: "MM/dd/yyyy hh:mm a" }}</b>
                  </div>
                </div>
              </mat-menu>
            </span>
<!--                <li (click)="navigateTab(TabNameEnum.FILE_STRUCTURE)"-->
<!--                    [ngClass]="{ active_tab: isInfotab == TabNameEnum.FILE_STRUCTURE, disabled_usb: showAppiumTestModeDetails || communicationStatus !== 'READY' || manualTestService.mtSessionStatus === 1 || replayInProgress}">-->
<!--                    <img class="img-fluid mt-2" style="width: 25px" src="../../../../assets/images/NewUI/file-structure.svg"-->
<!--                         alt="" />-->
<!--                    <p class="device-info-value">Snap Cloud</p>-->
<!--                </li>-->
            </div>
        </ul>
    </div>
    <div class="right-side-tabs-content"
         [ngClass]="{'manual-test': isInfotab === TabNameEnum.MANUAL_TEST}">
        <div class="ul_nav_header" *ngIf="isInfotab === TabNameEnum.INFO || isInfotab === TabNameEnum.ADB">
            <ul *ngIf="isInfotab !== TabNameEnum.ADB" [ngClass]="{
              disabled_usb:
                isUsbAttach == false ||
                this.isReqAttach == true || (showAppiumTestModeDetails || communicationStatus !== 'READY')}">
                <li (click)="navigateTab(TabNameEnum.INFO)" *ngIf="isInfotab === TabNameEnum.INFO" class="title_info">
                    Info
                </li>
            </ul>
            <div class="adb-tab-header" *ngIf="isInfotab === TabNameEnum.ADB && isDebugAvailable">
                <button class="btn" (click)="updateSplitView('logcat')" [ngClass]="{ control_btn_new: showLogcat }"
                        [disabled]="!this.showTerminal || this.logcatInProgress">
                    Logcat
                </button>
                <button class="btn" (click)="updateSplitView('terminal')" [ngClass]="{ control_btn_new: showTerminal }"
                        [disabled]="!this.showLogcat || this.terminalCmdInProgress">
                    Terminal
                </button>
                <div (click)="changeSplitMode()" *ngIf="showLogcat && showTerminal" class="tooltip5">
                    <img *ngIf="splitMode" src="../../../../assets/images/splitscreen_vertical.svg" alt="" />
                    <img *ngIf="!splitMode" src="../../../../assets/images/splitscreen_horizontal.svg" alt="" />
                    <span class="tooltiptext">{{
                        splitMode ? "Split horizontally" : "Split vertically"
                        }}</span>
                </div>
            </div>
            <ul *ngIf="isInfotab === TabNameEnum.ADB && !isDebugAvailable" [ngClass]="{
            disabled_usb:
              isUsbAttach == false ||
              this.isReqAttach == true || (showAppiumTestModeDetails || communicationStatus !== 'READY')}">
                <li class="header-width-100">
                    ADB
                </li>
            </ul>
        </div>
        <div class="ul_nav_header_icon" *ngIf="
            isInfotab === TabNameEnum.MANUAL_TEST ||
            isInfotab === TabNameEnum.RECORD_AND_PLAY ||
            isInfotab === TabNameEnum.DEBUG
          ">
            <ul [ngClass]="{
              disabled_usb:
                isUsbAttach == false ||
                isAttachReq == true || (showAppiumTestModeDetails || communicationStatus !== 'READY')
            }">
                <li class="header-width-100" *ngIf="isInfotab === TabNameEnum.DEBUG">
                    DEBUG
                </li>
            </ul>
        </div>
        <app-info-tab [updateIgnoreTyping]="manualTestService.updateIgnoreTyping"></app-info-tab>

        <div class="adb-tab-wrapper"
             [ngClass]="{'h-split': !splitMode && isInfotab === TabNameEnum.ADB && showTerminal && showLogcat}"
             [style.display]="isInfotab === TabNameEnum.ADB ? 'flex' : 'none'">
            <!--ADB LOGCAT TAB-->
            <div class="adb-tab logcat-tab"
                 [style.display]="isInfotab === TabNameEnum.ADB && isDebugAvailable && showLogcat ? 'flex' : 'none'"
                 [ngClass]="{
              'splitted-v': showTerminal && showLogcat && splitMode,
              'splitted-h':
                showTerminal && showLogcat && !splitMode && !isLogModeUI
            }" id="split-logcat">
                <div class="logcat-top-controls">
                    <div class="logcat-options" *ngIf="isLogModeUI">
                        <span class="comman_fixed">logcat</span>
                        <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                               [disabled]="logcatInProgress" (input)="logcateChangeopt($event)" [(ngModel)]="commandText"
                               [ngModelOptions]="{ standalone: true }" class="common_text form-control"
                               placeholder="Enter [<option>] ... [<filter-spec>] ..." />
                    </div>
                    <div class="buffers-wrapper" *ngIf="isLogModeUI == false">
                        <span class="logMode">Log buffer:</span>
                        <ng-multiselect-dropdown [placeholder]="'Select'" [disabled]="logcatInProgress"
                                                 [settings]="getDropDownSettings(1)" [data]="logcatOptions" [(ngModel)]="bufferSelected"
                                                 (onDeSelect)="onItemSelect($event)" (onSelect)="onItemSelect($event)" class="multiselect">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="grep-wrapper" *ngIf="isLogModeUI == false">
                        <span class="comman_fixed">grep:</span>
                        <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                               [disabled]="logcatInProgress" (input)="logcateChangeopt($event)" [(ngModel)]="commandText"
                               [ngModelOptions]="{ standalone: true }" class="common_text form-control"
                               placeholder="Enter [<option>] ... [<filter-spec>] ..." />
                    </div>
                    <div class="search-text-wrapper">
                        <div class="logcat-filter">
                  <span class="search_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"
                         fill="#7D7D7D">
                      <path
                              d="M430 816q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430 756h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530 816H430ZM150 396q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 336h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 396H150Zm120 210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270 546h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690 606H270Z" />
                    </svg>
                  </span>
                            <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                                   (input)="logcatFilterChange()" [(ngModel)]="filterLogcat" [ngModelOptions]="{standalone: true}"
                                   class="form-control" placeholder="Filter">
                        </div>
                        <div class="logcat-search">
                            <span class="search_icon"><img src="../../../../assets/images/NewUI/search_input.svg" alt=""></span>
                            <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                                   (input)="logcatSearchChange()" [(ngModel)]="searchLogcatOutput"
                                   [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Search"
                                   [ngClass]="{'no-matches': !textSearchResults.length}">
                            <span *ngIf="searchLogcatOutput.length && !logcatInProgress" class="search-results">
                    {{textSearchResults.length ? currentSearchIndex + 1 + ' of ' + textSearchResults.length : '0 results'}}
                  </span>
                        </div>
                        <div [ngClass]="{disabled: !textSearchResults.length || logcatInProgress}" class="jump-btn"
                             (click)="goToPrevFind()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                                <path d="m291 720-51-51 240-240 240 240-51 51-189-189-189 189Z" />
                            </svg>
                        </div>
                        <div [ngClass]="{disabled: !textSearchResults.length || logcatInProgress}" class="jump-btn"
                             (click)="goToNextFind()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                                <path d="M480 723 240 483l51-51 189 189 189-189 51 51-240 240Z" />
                            </svg>
                        </div>
                    </div>
                    <div class="toggle-view">
                <span class="tooltip5 toggle-btn"
                      [ngClass]="{'gradient-rotate': !isLogModeUI,'cursor-pointer': isLogModeUI}"
                      (click)="toggleView(true)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                    <path
                            d="M430 816q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430 756h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530 816H430ZM150 396q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 336h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 396H150Zm120 210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270 546h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690 606H270Z" />
                  </svg>
                  <span class="tooltiptext">Switch to Filter Mode</span>
                </span>
                        <span class="tooltip5 toggle-btn" [ngClass]="{gradient: isLogModeUI,'cursor-pointer': !isLogModeUI}"
                              (click)="toggleView(false)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                    <path
                            d="M140 896q-24 0-42-18t-18-42V316q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680V400H140v436Zm160-72-42-42 103-104-104-104 43-42 146 146-146 146Zm190 4v-60h220v60H490Z" />
                  </svg>
                  <span class="tooltiptext">Switch to Console Mode</span>
                </span>
                    </div>
                </div>
                <div class="logcat-command">
              <span>
                <span class="bold">Command: </span>adb {{ displaycommand }}</span>
                </div>
                <div class="logcat-wrapper" [ngClass]="{ ongoing: logcatInProgress }">
                    <div class="terminal-box" id="logcat_id" #scrollogcat tabindex="0" (focusin)="updateIgnoreTyping(true)"
                         (focusout)="updateIgnoreTyping(false)">
                <span class="waiting-for-response-msg" *ngIf="showLogcatWaitingMessage">Waiting for response from the
                  server</span>
                        <div class="terminal-line terminal-line-logcat" *ngFor="let item of logcatOutput; index as i"
                             [innerHTML]="item" [ngClass]="{
                    'wrap-text': autoWrapLogcat,
                    'error-msg':
                      item.startsWith('ERROR:') || item.includes('>ERROR: ')
                  }"></div>
                        <span class="connection-closed-msg failed-status" *ngIf="adbConnectionStatus === 'CLOSED'">Websocket
                  connection has been closed unexpectedly.</span>
                    </div>
                    <div class="logcat-right-sidebar-actions">
                        <div (click)="sendAdbCommand('logcat')" [ngClass]="{disabled: disableLogcatBtn}"
                             class="primary tooltip4">
                            <img *ngIf="logcatInProgress" src="../../../../assets/images/stop-new.svg" alt="" />
                            <img *ngIf="!logcatInProgress" src="../../../../assets/images/play-arrow-new.svg" alt="" />
                            <span class="tooltiptext">{{logcatInProgress ? "Stop logcat" : "Start logcat"}}</span>
                        </div>
                        <div (click)="clearLogcatOutput()"
                             [ngClass]="{disabled: adbLogcatClearWait || !origLogcatOutput.length}" class="tooltip4">
                            <img src="../../../../assets/images/trash-can.svg" alt="" />
                            <span class="tooltiptext">Clear logcat</span>
                        </div>
                        <div (click)="downloadLogcatFile()" [ngClass]="{disabled: logcatInProgress || !origLogcatOutput.length}"
                             class="tooltip4">
                            <img src="../../../../assets/images/download-new.svg" alt="" />
                            <span class="tooltiptext">Download log</span>
                        </div>
                        <hr />
                        <div (click)="changeAutoWrapLogcat()" [ngClass]="{ active: autoWrapLogcat }" class="tooltip4">
                            <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M29.6 41.4L22.65 34.45L29.6 27.5L31.7 29.65L28.35 33H34.9C36.0667 33 37.0417 32.575 37.825 31.725C38.6083 30.875 39 29.8667 39 28.7C39 27.5333 38.6083 26.5333 37.825 25.7C37.0417 24.8667 36.0667 24.45 34.9 24.45H8V21.45H34.9C36.9 21.45 38.5833 22.1583 39.95 23.575C41.3167 24.9917 42 26.7 42 28.7C42 30.7 41.3167 32.4167 39.95 33.85C38.5833 35.2833 36.9 36 34.9 36H28.35L31.7 39.25L29.6 41.4ZM8 36V33H19.9V36H8ZM8 13V10H40V13H8Z"
                                        fill-rule="evenodd" />
                            </svg>
                            <input type="checkbox" [(ngModel)]="autoWrapLogcat" [ngModelOptions]="{ standalone: true }"
                                   id="logcat-opt-autowrap" style="display: none" />
                            <span class="tooltiptext">Wrap text</span>
                        </div>
                        <div (click)="changeAutoscrollLogcat()" [ngClass]="{ active: autoScroll }" class="tooltip4">
                            <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M22.9393 34.0607C23.5251 34.6464 24.4749 34.6464 25.0607 34.0607L34.6066 24.5147C35.1924 23.9289 35.1924 22.9792 34.6066 22.3934C34.0208 21.8076 33.0711 21.8076 32.4853 22.3934L24 30.8787L15.5147 22.3934C14.9289 21.8076 13.9792 21.8076 13.3934 22.3934C12.8076 22.9792 12.8076 23.9289 13.3934 24.5147L22.9393 34.0607ZM22.5 6V33H25.5V6H22.5Z"
                                        fill-rule="evenodd" />
                                <path
                                        d="M24 44C25.6569 44 27 42.6569 27 41C27 39.3431 25.6569 38 24 38C22.3431 38 21 39.3431 21 41C21 42.6569 22.3431 44 24 44Z"
                                        fill-rule="evenodd" />
                            </svg>
                            <input type="checkbox" [(ngModel)]="autoScroll" [ngModelOptions]="{ standalone: true }"
                                   id="logcat-opt-autoscroll" style="display: none" />
                            <span class="tooltiptext">Scroll to end</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--ADB TERMINAL TAB-->
            <div class="adb-tab terminal-tab"
                 [style.display]="isInfotab === TabNameEnum.ADB && isDebugAvailable && showTerminal ? 'flex' : 'none'"
                 [ngClass]="{'splitted-v': showTerminal && showLogcat && splitMode}" id="split-term">
                <div class="terminal-input-wrapper">
                    <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                           (keydown.enter)="sendAdbCommand('terminal')" (keydown.ArrowDown)="onKeydownMain($event)"
                           (keyup.ArrowUp)="onKeyupMain($event)" [(ngModel)]="terminalText"
                           placeholder="Enter adb command, press CTRL+C to stop" class="form-control terminal-input"
                           [disabled]="terminalCmdInProgress" />
                    <span class="terminal_text">Snap $></span>
                </div>

                <div class="logcat-wrapper terminal-wrapper" [ngClass]="{ ongoing: terminalCmdInProgress }">
                    <div class="terminal-box" id="div_id" tabindex="0" (focusin)="updateIgnoreTyping(true)"
                         (focusout)="updateIgnoreTyping(false)">
                <span class="waiting-for-response-msg" *ngIf="showTerminalWaitingMessage">Waiting for response from the
                  server</span>
                        <div class="terminal-line" *ngFor="let item of terminalList; index as i" [ngClass]="{
                    'wrap-text': autoWrapTerminal,
                    red: item['status'] == 'FAIL',
                    white: item['status'] == 'PASS'
                  }">Snap $> {{ item.msg }}</div>
                        <span class="connection-closed-msg failed-status" *ngIf="adbConnectionStatus === 'CLOSED'">Websocket
                  connection has been closed unexpectedly.</span>
                    </div>
                    <div class="logcat-right-sidebar-actions">
                        <div (click)="clearTerminalOutput()"
                             [ngClass]="{disabled: adbTerminalClearWait || !terminalList.length}" class="tooltip4">
                            <img src="../../../../assets/images/trash-can.svg" alt="" />
                            <span class="tooltiptext">Clear terminal</span>
                        </div>
                        <div class="tooltip4 Download_logs"
                             [ngClass]="{disabled: terminalCmdInProgress || !terminalList.length}">
                            <img class="img-fluid" (click)="downloadAdbFile()" src="../../../../assets/images/download-new.svg"
                                 alt="" />
                            <span class="tooltiptext">Download terminal log</span>
                        </div>
                        <hr />
                        <div (click)="changeAutoWrapTerminal()" [ngClass]="{ active: autoWrapTerminal }" class="tooltip4">
                            <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M29.6 41.4L22.65 34.45L29.6 27.5L31.7 29.65L28.35 33H34.9C36.0667 33 37.0417 32.575 37.825 31.725C38.6083 30.875 39 29.8667 39 28.7C39 27.5333 38.6083 26.5333 37.825 25.7C37.0417 24.8667 36.0667 24.45 34.9 24.45H8V21.45H34.9C36.9 21.45 38.5833 22.1583 39.95 23.575C41.3167 24.9917 42 26.7 42 28.7C42 30.7 41.3167 32.4167 39.95 33.85C38.5833 35.2833 36.9 36 34.9 36H28.35L31.7 39.25L29.6 41.4ZM8 36V33H19.9V36H8ZM8 13V10H40V13H8Z"
                                        fill-rule="evenodd" />
                            </svg>
                            <input type="checkbox" [(ngModel)]="autoWrapTerminal" [ngModelOptions]="{ standalone: true }"
                                   id="terminal-opt-autowrap" style="display: none" />
                            <span class="tooltiptext">Wrap text</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="no-access" *ngIf="isInfotab === TabNameEnum.ADB && !isDebugAvailable">
                <p>
                    ADB is not available with your current subscription plan.
                    To get access to ADB feature, you must upgrade your account to a plan that includes Debug feature.
                </p>
            </div>
        </div>

        <!--DEBUG TAB-->
        <div class="adb-tab logcat-tab debug-tab"
             [style.display]="isInfotab === TabNameEnum.DEBUG && showLogcat && isDebugAvailable ? 'flex' : 'none'"
             [ngClass]="{
              'splitted-v': showTerminal && showLogcat && splitMode
            }">
            <div class="logcat-top-controls search-fix-height">
                <div class="debug-options">
                    <div class="ios-debug-commands">
                        <span class="ios-debug-label">Utility</span>
                        <select id="product-type" [(ngModel)]="selectedCommand" name="selectedType"
                                (ngModelChange)="updateCommand()" class="ios-debug-select form-control"
                                [disabled]="terminalCmdInProgress">
                            <option *ngFor="let command of commands" [value]="command" [disabled]="command === 'Select utility'">
                                {{command}}</option>
                        </select>

                        <div class="ios-debug-options">

                            <div *ngIf="selectedCommand === 'idevicediagnostics'" class="ios-debug-padding ios-debug-width">
                                <span class="ios-debug-label">Command</span>
                                <select [(ngModel)]="selectedSingleOption" name="selectedProduct" [disabled]="!selectedCommand"
                                        (click)="updateOption()" class="form-control ios-debug-select" [disabled]="terminalCmdInProgress">
                                    <option *ngFor="let option of options" [value]="option" class="selected-option">
                                        {{option}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="selectedCommand === 'idevicesyslog'" class="ios-debug-padding ios-debug-width ">
                                <span class="ios-debug-label">Options 1</span>
                                <select [(ngModel)]="selectedSingleOption" name="selectedProduct" [disabled]="!selectedCommand"
                                        (click)="updateOption()" class="form-control ios-debug-select" [disabled]="terminalCmdInProgress">
                                    <option value="" selected>Select option</option>
                                    <option *ngFor="let option of options" [value]="option" class="selected-option">
                                        {{option}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="selectedCommand === 'idevicecrashreport' || selectedCommand === 'ideviceinfo'"
                                 class="ios-debug-padding ios-debug-width ios-debug-multiselect ios-debug-multiselect-div">
                                <span class="ios-debug-label">Options</span>
                                <ng-multiselect-dropdown [placeholder]="'Select option'" [disabled]="terminalCmdInProgress"
                                                         [settings]="getDropDownSettings(5)" [data]="options" [(ngModel)]="selectedMultipleOption"
                                                         (onDeSelect)="updateTerminalText()" (onSelect)="updateTerminalText()" class="multiselect">
                                </ng-multiselect-dropdown>
                            </div>

                            <div *ngIf="selectedSingleOption === 'diagnostics' || selectedSingleOption === 'ioreg'"
                                 class="ios-debug-width">
                                <span *ngIf="selectedSingleOption === 'diagnostics'" class="ios-debug-label">Type</span>
                                <span *ngIf="selectedSingleOption === 'ioreg'" class="ios-debug-label">Plane</span>
                                <select [(ngModel)]="selectedSecondOption" name="selectedProduct"
                                        [disabled]="!iDeviceDiagnosticsCommands" class="form-control ios-debug-select"
                                        (click)="updateTerminalText()" [disabled]="terminalCmdInProgress">
                                    <option *ngFor="let type of secondOption" [value]="type" class="selected-option">
                                        {{type}}
                                    </option>
                                </select>
                            </div>

                            <div class="ios-debug-width ios-debug-multiselect-div" *ngIf="selectedDomain">
                                <span class="ios-debug-label">DOMAIN_NAME</span>
                                <select [(ngModel)]="selectedSecondOption" name="selectedProduct"
                                        [disabled]="!iDeviceDiagnosticsCommands"
                                        class="form-control select_field_ field_height ios-debug-select" (click)="updateTerminalText()"
                                        [disabled]="terminalCmdInProgress">
                                    <option *ngFor="let domain of iDeviceInfoDomainName" [value]="domain" class="selected-option">
                                        {{domain}}
                                    </option>
                                </select>
                            </div>

                            <div class="ios-debug-storage" *ngIf="selectedCommand === 'idevicecrashreport'">/storage/</div>

                            <div class="ios-debug-width ios-debug-margin"
                                 *ngIf="selectedCommand === 'idevicecrashreport' || (selectedCommand === 'idevicesyslog' && selectedSingleOption) || selectedSingleOption === 'ioregentry'">
                                <span class="ios-debug-label">{{iosDebugPlaceholder}}</span>
                                <input type="text" (keydown.enter)="sendAdbCommand('terminal', true)"
                                       [(ngModel)]="iosDebugTextField" (ngModelChange)="updateTerminalText()"
                                       class="form-control terminal-input ios-debug-input-height ios-debug-select"
                                       [placeholder]=iosDebugPlaceholder [disabled]="terminalCmdInProgress" matInput />
                            </div>

                            <div *ngIf="selectedCommand === 'idevicesyslog'"
                                 class="ios-debug-width ios-debug-multiselect ios-debug-multiselect-div">
                                <span class="ios-debug-label">Options 2</span>
                                <ng-multiselect-dropdown [placeholder]="'Select option'" [disabled]="terminalCmdInProgress"
                                                         [settings]="getDropDownSettings(2)" [data]="iDeviceSysLogOptions2"
                                                         [(ngModel)]="selectedMultipleOption" (onDeSelect)="updateTerminalText()"
                                                         (onSelect)="updateTerminalText()" class="multiselect">
                                </ng-multiselect-dropdown>
                            </div>

                            <div *ngIf="selectedSingleOption === 'mobilegestalt'"
                                 class=" ios-debug-padding ios-debug-width ios-debug-multiselect ios-debug-multiselect-div">
                                <span class="ios-debug-label">MOBILEGESTALT_KEY</span>
                                <ng-multiselect-dropdown [placeholder]="'Select option'" [disabled]="terminalCmdInProgress"
                                                         [settings]="getDropDownSettings(1)" [data]="iDeviceDiagnosticsMobileGestaltKey"
                                                         [(ngModel)]="selectedMultipleOption" (onDeSelect)="updateTerminalText()"
                                                         (onSelect)="updateTerminalText()" class="multiselect">
                                </ng-multiselect-dropdown>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="search-text-wrapper">
                    <div class="logcat-filter">
                <span class="search_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18" fill="#7D7D7D">
                    <path
                            d="M430 816q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T430 756h100q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T530 816H430ZM150 396q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 336h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 396H150Zm120 210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T270 546h420q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T690 606H270Z" />
                  </svg>
                </span>
                        <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                               (input)="iosDebugFilterChange()" [(ngModel)]="filterLogcat" [ngModelOptions]="{standalone: true}"
                               class="form-control" placeholder="Filter">
                    </div>
                    <div class="logcat-search">
                        <span class="search_icon"><img src="../../../../assets/images/NewUI/search_input.svg" alt=""></span>
                        <input type="text" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                               (input)="iosDebugSearchChange()" [(ngModel)]="searchLogcatOutput"
                               [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Search"
                               [ngClass]="{'no-matches': !textSearchResults.length}">
                        <span *ngIf="searchLogcatOutput.length && !logcatInProgress" class="search-results">
                  {{textSearchResults.length ? currentSearchIndex + 1 + ' of ' + textSearchResults.length : '0 results'}}
                </span>
                    </div>
                    <div [ngClass]="{
                    disabled: !textSearchResults.length || terminalCmdInProgress
                  }" class="jump-btn" (click)="goToPrevFind()">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                            <path d="m291 720-51-51 240-240 240 240-51 51-189-189-189 189Z" />
                        </svg>
                    </div>
                    <div [ngClass]="{
                    disabled: !textSearchResults.length || terminalCmdInProgress
                  }" class="jump-btn" (click)="goToNextFind()">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                            <path d="M480 723 240 483l51-51 189 189 189-189 51 51-240 240Z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="logcat-command">
            <span><span class="bold">Command: </span>
                {{ terminalText }}
            </span>
            </div>
            <div class="logcat-wrapper debug-ios-wrapper" [ngClass]="{ ongoing: logcatInProgress }">
                <div class="terminal-box" id="ios-debug_id">
                    <div class="terminal-line" *ngFor="let item of terminalList; index as i" [innerHTML]="item.msg" [ngClass]="{
                        'wrap-text': autoWrapTerminal,
                        red: item['status'] == 'FAIL',
                        white: item['status'] == 'PASS'
                      }">
                    </div>
                    <span class="connection-closed-msg failed-status" *ngIf="adbConnectionStatus === 'CLOSED'">Websocket
                connection has been closed unexpectedly.</span>
                </div>

                <div class="logcat-right-sidebar-actions">
                    <div (click)="sendAdbCommand('terminal', true)" [ngClass]="{
                        disabled: disableIosDebugBtn
                      }" class="primary tooltip4">
                        <img *ngIf="terminalCmdInProgress" src="../../../../assets/images/stop-new.svg" alt="" />
                        <img *ngIf="!terminalCmdInProgress" src="../../../../assets/images/play-arrow-new.svg" alt="" />
                        <span class="tooltiptext">{{
                            terminalCmdInProgress ? "Stop debug" : "Start debug"
                            }}</span>
                    </div>
                    <div (click)="clearTerminalOutput()" [ngClass]="{
                        disabled: adbTerminalClearWait || !terminalList.length
                      }" class="tooltip4">
                        <img src="../../../../assets/images/trash-can.svg" alt="" />
                        <span class="tooltiptext">Clear debug</span>
                    </div>
                    <div (click)="downloadAdbFile()" [ngClass]="{
                        disabled: terminalCmdInProgress || !terminalList.length
                      }" class="tooltip4">
                        <img src="../../../../assets/images/download-new.svg" alt="" />
                        <span class="tooltiptext">Download log</span>
                    </div>
                    <hr />
                    <div (click)="changeAutoWrapTerminal()" [ngClass]="{ active: autoWrapTerminal }" class="tooltip4">
                        <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M29.6 41.4L22.65 34.45L29.6 27.5L31.7 29.65L28.35 33H34.9C36.0667 33 37.0417 32.575 37.825 31.725C38.6083 30.875 39 29.8667 39 28.7C39 27.5333 38.6083 26.5333 37.825 25.7C37.0417 24.8667 36.0667 24.45 34.9 24.45H8V21.45H34.9C36.9 21.45 38.5833 22.1583 39.95 23.575C41.3167 24.9917 42 26.7 42 28.7C42 30.7 41.3167 32.4167 39.95 33.85C38.5833 35.2833 36.9 36 34.9 36H28.35L31.7 39.25L29.6 41.4ZM8 36V33H19.9V36H8ZM8 13V10H40V13H8Z"
                                    fill-rule="evenodd" />
                        </svg>
                        <input type="checkbox" [(ngModel)]="autoWrapTerminal" [ngModelOptions]="{ standalone: true }"
                               id="ios-debug-opt-autowrap" style="display: none" />
                        <span class="tooltiptext">Wrap text</span>
                    </div>
                    <div (click)="changeAutoscrollLogcat()" [ngClass]="{ active: autoScroll }" class="tooltip4">
                        <svg width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M22.9393 34.0607C23.5251 34.6464 24.4749 34.6464 25.0607 34.0607L34.6066 24.5147C35.1924 23.9289 35.1924 22.9792 34.6066 22.3934C34.0208 21.8076 33.0711 21.8076 32.4853 22.3934L24 30.8787L15.5147 22.3934C14.9289 21.8076 13.9792 21.8076 13.3934 22.3934C12.8076 22.9792 12.8076 23.9289 13.3934 24.5147L22.9393 34.0607ZM22.5 6V33H25.5V6H22.5Z"
                                    fill-rule="evenodd" />
                            <path
                                    d="M24 44C25.6569 44 27 42.6569 27 41C27 39.3431 25.6569 38 24 38C22.3431 38 21 39.3431 21 41C21 42.6569 22.3431 44 24 44Z"
                                    fill-rule="evenodd" />
                        </svg>
                        <input type="checkbox" [(ngModel)]="autoScroll" [ngModelOptions]="{ standalone: true }"
                               id="ios-debug-opt-autoscroll" style="display: none" />
                        <span class="tooltiptext">Scroll to end</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="no-access" *ngIf="isInfotab === TabNameEnum.DEBUG && !isDebugAvailable">
            <p>
                Debug is not available with your current subscription plan.
                To get access to debug feature, you must upgrade your account to a plan that includes Debug feature.
            </p>
        </div>

        <!--FILE STRUCTURE TAB-->
<!--        <div class="adb-tab" [style.display]="isInfotab === TabNameEnum.FILE_STRUCTURE ? 'block' : 'none'">-->
<!--            <cloud-tab [rnpTestList]="testList" [isLoading]="isLoading" (ignoreTyping)="updateIgnoreTyping($event)"-->
<!--                       (changeLoading)="setLoading($event)"></cloud-tab>-->
<!--        </div>-->
    </div>