import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-device-request-list',
  templateUrl: './device-request-list.component.html',
  styleUrls: ['./device-request-list.component.scss']
})
export class DeviceRequestListComponent implements OnInit {
  locationListDropdown = [];
  deviceList = [];
  selectDeviceList = [];
  cardList = [];
  isLoading: boolean = false;
  isLoadingModal: boolean = false;
  showBilling: boolean = false;
  searchStautusType =''
  searchType = "";
  searchDevice = "";
  searchLocation = "";
  searchEmei = "";
  searchStartDate: any;
  searchEndDate: any;
  searchLocatin = "";
  totalDeviceSelected: number = 0
  stripeForm: FormGroup;

  minDateCal: Date;
  minDateCalEnd: Date;

  cardErrorMessage: any;
  lType=""
  constructor(
    private DeviceService: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.minDateCal = new Date();
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });
    // this.initialiaseDate();
    this.getDeviceList()
  }
  initialiaseDate() {
    let date = new Date();
    this.searchStartDate = date;
    this.searchEndDate = date;


  }

  getDeviceList() {
    this.isLoading = true;
    let query = this.queryParam();
    this.DeviceService.getDeviceRequest(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.deviceList = res.data.deviceRequestList;
        this.total = res.data.totalCount;
        this.SetPage();

      } else {
      }
    }, err => {
      this.isLoading = false;
    });

  }
  txnDetailList: any;
  userDetail = [];

  data: any;
  statusForm: FormGroup;
  openModal(content, btn, data) {
    this.data = data;
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true,
    };
    this.prepareUpdateStatusForm();
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  prepareUpdateStatusForm() {
    this.statusForm = this.fb.group({
      status: ["", [Validators.required]],
    });
  }
  totalDevice: number = 0;
  totalprice: number = 0;
  totalDay: number = 0;
  totalAmount: number = 0;

  queryParam() {
    let query = "limit=" + this.pagelimit + "&offset=" + this.pagelimit * this.currentPage;
    if (this.searchStartDate && this.searchStartDate != "") {
      // convert to epoch time
      query = query + "&startDate=" + this.getEpoc(this.searchStartDate)
    }
    if (this.searchEmei) {
      query = query + "&text=" + encodeURIComponent(this.searchEmei);
    }
   if( this.lType){
      query= query+"&deviceType="+this.lType;
    }
    if(this.searchStautusType){
      query= query+"&status="+this.searchStautusType; 
    }
    return query;
  }
  clearFilter() {
    this.searchStautusType = '';
    this.searchStartDate = "";
    this.searchEndDate = ""
    this.searchLocatin = "";
    this.searchEmei = "";
    this.deviceList = [];
    this.lType = ""
    this.getDeviceList();
  }
  changePageLimit() {
    this.currentPage = 0;
    this.getDeviceList()
  }
  total: number = 0;
  pagelimit: number = 10;
  totalPage: number
  currentPage: number = 0;
  SetPage() {
    this.totalPage = 0
    this.totalPage = this.total / this.pagelimit
    this.totalPage = Math.ceil(this.totalPage);
    if (this.totalPage == 1 || this.total <= this.pagelimit) {
      this.totalPage = 1;
    }
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.getDeviceList();
  }
  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.getDeviceList();
  }



  // getEpoc(date) {
  //   let travelDay = date.getDate();
  //   if (travelDay < 10) travelDay = "0" + travelDay;
  //   let travelMonth = date.getMonth() + 1;
  //   if (travelMonth < 10) travelMonth = "0" + travelMonth;
  //   let travelYear = date.getFullYear();
  //   return travelMonth + "/" + travelDay + "/" + travelYear
  // }
  getEpoc(date) {
    let epocDate = new Date(date);
    return Math.floor(epocDate.getTime() / 1000);
  }

  closeResult: any;
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  private modalRef: NgbModalRef;
  openEditModal(content, btn) {
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
      size: 'md',
    };
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  closeModal() {
    this.modalRef.close();
  }
  updateUserStatus() {
    if (this.statusForm.valid) {
      this.isLoadingModal = true;
      let req = { deviceRequestId: this.data.deviceRequestId, status: this.statusForm.value.status };
      this.DeviceService.updateDeviceRequest(req).subscribe(
        (res) => {
          this.isLoadingModal = false;
          if (res["message"] == "Success") {
            this.modalRef.close();
            this.getDeviceList();
            this._snackBar.open("Status updated successfully!", "", {
              duration: 5000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["success"],
            });
          } else {
          }
          this.isLoadingModal = false;
        },
        (err) => {
          this.isLoadingModal = false;
        }
      );
    }
  }
  setDefault(){
    this.currentPage = 0;
    this.getDeviceList();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
