
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar-comp.component';

@Component({
  selector: 'app-support-section',
  templateUrl: './support-section.component.html',
  styleUrls: ['./support-section.component.scss']
})
export class SupportSectionComponent implements OnInit {
  @ViewChild('scrollContent') scrollContent!: ElementRef;
  @ViewChild('scrollThumb') scrollThumb!: ElementRef;
  @ViewChild('scrollContent1') scrollContent1!: ElementRef;
  @ViewChild('scrollThumb1') scrollThumb1!: ElementRef;

  searchText = '';
  activeTab = '';
  hasVerticalScroll:boolean = false;
  hasVerticalScroll1:boolean = false;
  private modalRef: NgbModalRef;
  defaultVideos:any = [];
  defaultFaq:any = [];

  videos = [
  
    {
      thumbnailImg: 'path/to/image2.jpg',
      title: 'Video Title 2',
      duration: '4:30',
      description: 'Description for Video 2'
    },
    {
      thumbnailImg: 'path/to/image3.jpg',
      title: 'Video Title 3',
      duration: '2:15',
      description: 'Description for Video 3'
    },
    {
      thumbnailImg: 'path/to/image4.jpg',
      title: 'Video Title 4',
      duration: '5:00',
      description: 'Description for Video 4'
    },
    {
      thumbnailImg: 'path/to/image5.jpg',
      title: 'Video Title 5',
      duration: '3:50',
      description: 'Description for Video 5'
    },
    {
      thumbnailImg: 'path/to/image6.jpg',
      title: 'Video Title 6',
      duration: '4:20',
      description: 'Description for Video 6'
    },
    {
      thumbnailImg: 'path/to/image7.jpg',
      title: 'Video Title 7',
      duration: '3:25',
      description: 'Description for Video 7'
    },
    {
      thumbnailImg: 'path/to/image8.jpg',
      title: 'Video Title 8',
      duration: '5:10',
      description: 'Description for Video 8'
    }
  ];
  questList= [
    {
      question:'What is the orange icon in the corner of my screen used for ?',
      answere:'That is your SNAPPY HELPER, no seriously. Click on the icon and a helpful assistant is just a chat away to help you when you are in a pinch. Try it out now.'
    },
    {
      question:'What is the orange icon in the corner of my screen used for ?',
      answere:'That is your SNAPPY HELPER, no seriously. Click on the icon and a helpful assistant is just a chat away to help you when you are in a pinch. Try it out now.'
    },
    {
      question:'What is the orange icon in the corner of my screen used for ?',
      answere:'That is your SNAPPY HELPER, no seriously. Click on the icon and a helpful assistant is just a chat away to help you when you are in a pinch. Try it out now.'
    },
    {
      question:'What is the orange icon in the corner of my screen used for ?',
      answere:'That is your SNAPPY HELPER, no seriously. Click on the icon and a helpful assistant is just a chat away to help you when you are in a pinch. Try it out now.'
    }

  ];

  constructor(
    private _snackBar: MatSnackBar,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.defaultVideos = this.videos
    this.defaultFaq = this.questList
  }
 
  copyDivToClipboard() {
   let str = document.getElementById('email').innerHTML;
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this._snackBar.open("Copied to clipboard", "", {
      duration: 3000,
        horizontalPosition: "right",
        verticalPosition: "top",
       panelClass: ["success"],
     });
}
copy_data(containerid) {
  
}

navigateSearch(value){
  this.activeTab = value
  if(this.activeTab == 'videos'){
    setTimeout(() => {
      this.scrollUpdate()
   }, 1000);
  }
  else if(this.activeTab == 'faq'){
    setTimeout(() => {
      this.scrollUpdateFaq()
   }, 1000);
  }
}

scrollUpdate(){
  this.hasVerticalScroll = this.scrollContent.nativeElement.scrollHeight > this.scrollContent.nativeElement.clientHeight;
  const content = this.scrollContent.nativeElement;
  const thumb = this.scrollThumb.nativeElement;
  const track = thumb.parentElement;

  // Update thumb position based on content scroll
  content.addEventListener('scroll', () => {
    const scrollRatio = content.scrollTop / (content.scrollHeight - content.clientHeight);
    thumb.style.top = scrollRatio * (track.clientHeight - thumb.clientHeight) + 'px';
  });

  // Handle thumb dragging
  thumb.addEventListener('mousedown', (e: MouseEvent) => {
    const thumbInitialY = e.clientY;
    const thumbInitialTop = thumb.offsetTop;

    const onMouseMove = (e: MouseEvent) => {
      const deltaY = e.clientY - thumbInitialY;
      const newTop = Math.min(Math.max(thumbInitialTop + deltaY, 0), track.clientHeight - thumb.clientHeight);
      thumb.style.top = newTop + 'px';

      const scrollRatio = newTop / (track.clientHeight - thumb.clientHeight);
      content.scrollTop = scrollRatio * (content.scrollHeight - content.clientHeight);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  });
}

scrollUpdateFaq(){
  this.hasVerticalScroll1 = this.scrollContent1.nativeElement.scrollHeight > this.scrollContent1.nativeElement.clientHeight;
  const content = this.scrollContent1.nativeElement;
  const thumb = this.scrollThumb1.nativeElement;
  const track = thumb.parentElement;

  // Update thumb position based on content scroll
  content.addEventListener('scroll', () => {
    const scrollRatio = content.scrollTop / (content.scrollHeight - content.clientHeight);
    thumb.style.top = scrollRatio * (track.clientHeight - thumb.clientHeight) + 'px';
  });

  // Handle thumb dragging
  thumb.addEventListener('mousedown', (e: MouseEvent) => {
    const thumbInitialY = e.clientY;
    const thumbInitialTop = thumb.offsetTop;

    const onMouseMove = (e: MouseEvent) => {
      const deltaY = e.clientY - thumbInitialY;
      const newTop = Math.min(Math.max(thumbInitialTop + deltaY, 0), track.clientHeight - thumb.clientHeight);
      thumb.style.top = newTop + 'px';

      const scrollRatio = newTop / (track.clientHeight - thumb.clientHeight);
      content.scrollTop = scrollRatio * (content.scrollHeight - content.clientHeight);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  });
}

openVideoModal(item): void {
  this.modalRef = this.modalService.open(VideoPlayerComponent, {
    size: 'xl', // Set the size of the modal
    backdrop: 'static' // Prevent closing by clicking on the backdrop
  });
  item.media = 'https://s3.ap-south-1.amazonaws.com/s3.zimblecode.com/wang_wang/1723100348620_get-together-invitation_uXfqpR4R.mp4'
  let data = item
  this.modalRef.componentInstance.modalData = data;

  this.modalRef.result.then((result) => {
  }, (reason) => {
  });
}

searchChange(){
  if(this.searchText != ''){
    this.videos =  this.defaultVideos.filter(video => 
      Object.values(video).some(value => {
        // Check if value is a string before using includes
        return typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase());
      })
    );

    this.questList =  this.defaultFaq.filter(video => 
      Object.values(video).some(value => {
        // Check if value is a string before using includes
        return typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase());
      })
    );
  }
  else {
    this.videos = this.defaultVideos
    this.questList = this.defaultFaq
  }

  if(this.videos.length <= 0){
    this.hasVerticalScroll = false
  }else if (this.videos.length > 0 && this.activeTab == 'videos'){
    setTimeout(() => {
      this.scrollUpdate()
   }, 1000);
  }

  if(this.questList.length <= 0){
    this.hasVerticalScroll1 = false
  } else if(this.questList.length > 0 && this.activeTab == 'faq'){
    setTimeout(() => {
      this.scrollUpdateFaq()
   }, 1000);
  }
  
 
}
}
