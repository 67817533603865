
export enum BookingStatus {
    CREATED = 'CREATED',
    STARTED = 'STARTED',
    FAILED = 'FAILED',
    RELEASED = 'RELEASED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED'
}

export interface Booking {
    id: string;
    deviceId: string;
    deviceSerialNumber: string;
    reasonForPaymentFailure: string;
    status: BookingStatus;
    bookedFrom: number;
    bookedUntil: number;
    userId: string;
    timeZone: string;
    insertDate: number;
}

export interface DeviceBooking {
    deviceId: string,
    deviceSerialNumber: string;
    bookings: Booking[];
}