import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../../shared/common.service';
import {AdminService} from '../../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  @Output() totalPublic = new EventEmitter<number>();
  invoiceList = [];
  locationListDropdown = [];
  selectDeviceList = [];
  invoiceListDropdown = [];
  cardList = [];
  isLoading = false;
  isLoadingBooking = false;
  searchType = '';
  searchDevice = '';
  searchLocation = '';
  searchStartDate: any;
  searchEndDate: any;
  searchLocatin = '';
  searchEmei = '';
  totalDeviceSelected = 0;
  stripeForm: FormGroup;
  minDateCal: Date;
  minDateCalEnd: Date;
  closeResult: string;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  private modalRef: NgbModalRef;
  total = 0;
  pagelimit = 10;
  totalPage;
  currentPage = 0;
  validSearch = false;
  txnDetailList= '';
  deviceList = [];

  constructor(
    private DeviceService: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getInvoceList();
  }

  getInvoceList() {
    this.isLoading = true;
    let query = this.queryParam();
    this.DeviceService.getInvoiceTransaction(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] === 'Success') {
        this.invoiceList = res.data.transactions;
        this.total = res.data.totalCount;
        this.totalPublic.emit(res.data.totalCount);
        this.SetPage();
      } else {
      }
    }, err => {
      this.isLoading = false;
    });
  }

  SetPage() {
    this.totalPage = 0;
    this.totalPage = this.total / this.pagelimit;
    this.totalPage = Math.ceil(this.totalPage);
    if (this.totalPage === 1 || this.total <= this.pagelimit) {
      this.totalPage = 1;
    }
  }
  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.getInvoceList();
  }
  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.getInvoceList();
  }

  moreDetailModal(content, btn, data) {
    this.modelOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl',
    };
    this.txnDetailList = '';
    this.deviceList = [];
    this.txnDetailList = data;
    this.deviceList = data.devices;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  getEpoc(date) {
    let travelDay = date.getDate();
    if (travelDay < 10) { travelDay = '0' + travelDay; }
    let travelMonth = date.getMonth() + 1;
    if (travelMonth < 10) { travelMonth = '0' + travelMonth; }
    const travelYear = date.getFullYear();
    return travelMonth + '/' + travelDay + '/' + travelYear;
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    return Math.round(diffInTime / oneDay);
  }

  initialiaseDate() {
    const date = new Date();
    this.searchStartDate = date;
    this.searchEndDate = date;
  }

  queryParam() {
    let query = '&limit=' + this.pagelimit + '&offset=' + this.pagelimit * this.currentPage;
    if (this.searchStartDate && this.searchStartDate != "") {
      query = query + '&startDate=' + this.getEpoc(this.searchStartDate);
    }
    if (this.searchEndDate && this.searchEndDate != "") {
      query = query + '&endDate=' + this.getEpoc(this.searchEndDate);
    }
    if (this.searchLocation) {
      query = query + '&city=' + this.searchLocation;
    }
    if(this.searchEmei) {
      query = query + '&txnId=' + encodeURIComponent(this.searchEmei);
    }
    return query;
  }

  clearFilter() {
    this.searchStartDate = '';
    this.searchEndDate = '';
    this.searchEmei = '';
    this.getInvoceList();
  }

  changePageLimit() {
    this.currentPage = 0;
    this.getInvoceList();
  }
  textChange() {
    this.currentPage = 0;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  closeModal() {
    this.modalRef.close();
  }
}
