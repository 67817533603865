import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'user-profile-icon',
    templateUrl: './user-profile-icon.component.html',
    styleUrls: ['./user-profile-icon.component.scss']
})
export class UserProfileIconComponent implements OnInit {
    @Input() user: { name?: string, firstName?: string, lastName?: string, logo?: string, email?: string };

    initials = "";

    constructor() { }

    ngOnInit(): void {
        if (this.user?.name) {
            const nameSplit = this.user.name.split(' ');
            this.initials = nameSplit[0].charAt(0).toUpperCase();
            if (nameSplit.length > 1) {
                this.initials += nameSplit[1].charAt(0).toUpperCase();
            }
        } else if (this.user?.firstName) {
            this.initials = this.user.firstName.charAt(0).toUpperCase();
            if (this.user.lastName) {
                this.initials += this.user.lastName.charAt(0).toUpperCase();
            }
        }
        if (!this.initials && this.user) {
            this.initials = this.user.email ? this.user.email.charAt(0).toUpperCase() : "A";
        }
    }
}