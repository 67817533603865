import { CustomTestStepParameter } from './create-test-form.interface';

export enum CustomTelephonyActionType {
    DIAL = "DIAL",
    DIAL_PHONE_NUMBER = "DIAL_PHONE_NUMBER",
    ANSWER = "ANSWER",
    HANGUP = "HANGUP",
    TIME = "TIME",
    EVENT = "EVENT",
    REJECT = "REJECT",
    PUT_ON_HOLD = "PUT_ON_HOLD",
    MERGE = "MERGE",
    SWAP = "SWAP"
}

export interface CustomTestStepAction {
    order: number;
    category: 'WAIT' | 'ACT';
    startedAt?: number;
    finishedAt?: number;
    data: {
        type: CustomTelephonyActionType;
        payload: {
            key: string;
            parameters: CustomTestStepParameter[];
        }[]
    }
}
export const CustomActionIconMap = new Map<CustomTelephonyActionType, string>([
    [CustomTelephonyActionType.DIAL, 'dialing'],
    [CustomTelephonyActionType.ANSWER, 'answer'],
    [CustomTelephonyActionType.TIME, 'delay'],
    [CustomTelephonyActionType.EVENT, 'delay'],
    [CustomTelephonyActionType.REJECT, 'end'],
    [CustomTelephonyActionType.DIAL_PHONE_NUMBER, 'dialing'],
    [CustomTelephonyActionType.HANGUP, 'end'],
    [CustomTelephonyActionType.PUT_ON_HOLD, 'dialing'],
    [CustomTelephonyActionType.MERGE, 'merge'],
    [CustomTelephonyActionType.SWAP, 'swap']
]);

export const CustomActionTypeMap = new Map<CustomTelephonyActionType, string>([
    [CustomTelephonyActionType.DIAL, 'DIAL'],
    [CustomTelephonyActionType.ANSWER, 'ANSWER'],
    [CustomTelephonyActionType.TIME, 'WAIT FOR'],
    [CustomTelephonyActionType.EVENT, 'WAIT FOR'],
    [CustomTelephonyActionType.REJECT, 'REJECT'],
    [CustomTelephonyActionType.DIAL_PHONE_NUMBER, 'DIAL PHONE NUMBER'],
    [CustomTelephonyActionType.HANGUP, 'HANGUP'],
    [CustomTelephonyActionType.PUT_ON_HOLD, 'PUT ON HOLD'],
    [CustomTelephonyActionType.MERGE, 'MERGE'],
    [CustomTelephonyActionType.SWAP, 'SWAP']
]);

export interface CustomTestStepVerification {
    type: string;
    status?: string;
    timeout?: number;
}
