import { DayOfWeek, CreateBookingForm, BookingType, AlreadyBookedResponse } from './create-booking-form.interface';
import { BookingStatus, Booking, DeviceBooking } from './device-booking.interface';
import { UpdateBookingForm } from './update-booking-form.interface';
import { FindBestSlotForm, BestSlot } from './best-slot.interface';

export {
    DayOfWeek,
    CreateBookingForm,
    BookingStatus,
    Booking,
    DeviceBooking,
    BookingType,
    UpdateBookingForm,
    AlreadyBookedResponse,
    FindBestSlotForm,
    BestSlot
};