import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { GetLabListDto } from '../../../shared/dto';
import { TokenData, User } from '../../../shared/interfaces';
import { Subscription } from 'rxjs';
import { UserDataService } from '../../../shared/user-data.service';
import { UserRoleEnum } from '../../../shared/enum';
import { CommonService } from '../../../shared/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  isLoading:boolean = true;
  panelOpenState:boolean = false;
  dashboardData:any;
  userData: TokenData;
  userProfile: User;
  userDataSubscription: Subscription = null;
  labData ={
    androidOnline:0,
    androidOffline:0,
    iOSOnline:0,
    iOSOffline:0
  }
  activeMac:number =-1;
  reportList=[];
  deviceList=[];
  reportList1=[];
  activeTabData:any;
  tooltipComponent = null;
  constructor(
    private DeviceService: AdminService,
    private router: Router,
    private tooltipService: TooltipService,
    private userService: UserDataService,
    private commonService: CommonService,
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
    this.userDataSubscription = this.userService.userData$.subscribe((user) => {
      this.userProfile = user;
      const labNotification = user.notifications?.find((n) => n.type === 'LAB_ALERT');
      const deviceNotification = user.notifications?.find((n) => n.type === 'DEVICE_ALERT');
      this.deviceAlert = deviceNotification?.enabled;
      this.labAlert = labNotification?.enabled;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userData = this.commonService.getUser();
    if (this.userData.role !== UserRoleEnum.USER) {
      this.getCard();
      this.getLabs();
      this.getlogs();
      this.getlogsFull();
      this.getThreadLogs();
      this.getThreadLogsFull();
    } else {
      this.getCard1();
    }
  }
  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
    this.userDataSubscription = null;
  }
  labAlert:boolean = true;
  deviceAlert:boolean = true;
getProfile() {
    this.isLoading = true;
    this.userService.getUserData(() => this.isLoading = false)
  }

  changeAlert(){
    let request ={
      labAlerts:this.labAlert,
      deviceAlerts :this.deviceAlert
    }
    this.isLoading = true;
    this.DeviceService.updateProfile(request).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.isLoading = false;
        this.getProfile();
        // this._snackBar.open("Alert status updated successfully!", "", {
        //   duration: 5000,
        //   horizontalPosition: "right",
        //   verticalPosition: "top",
        //   panelClass: ["success"],
        // });
      
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
 
  getCard() {
    //this.isLoading = true;
    this.DeviceService.getLabDevcount("").subscribe((res) => {
      if (res['message'] == 'Success') {
        this.isLoading = false;
        this.dashboardData = res.data.deviceCounts;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getCard1() {
   // this.isLoading = true;
    this.DeviceService.getDashboardApi().subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.dashboardData = res.data;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getLabs() {
    //this.isLoading = true;
    this.DeviceService.getLabList("").subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        const data = res.data as GetLabListDto;
        this.deviceList = data.labList;
        this.activeTabData = data.labList[0];
        this.SendData(this.deviceList[0]['deviceCounts'],0, data.labList[0])
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getlogs() {
   // this.isLoading = true;
    let query="limit=50";
    this.DeviceService.getLabLogs(query).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.reportList = res.data.deviceLogs;
        this.isLoading = false;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getlogsFull() {
    // this.isLoading = true;
     this.DeviceService.getLabLogs("").subscribe((res) => {
       if (res['message'] == 'Success') {
         this.reportList = res.data.deviceLogs;
         this.isLoading = false;
       } else {
 
       }
     }, err => {
       this.isLoading = false;
     });
 
   }
  getThreadLogs() {
    let query="alert=urgentAlert&limit=50";
    this.isLoading = true;
    this.DeviceService.getThresholdAlert(query).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.isLoading = false;
        this.reportList1 = res.data.thresholdAlertLogs;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getThreadLogsFull() {
    let query="alert=urgentAlert";
    this.DeviceService.getThresholdAlert(query).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.reportList1 = res.data.thresholdAlertLogs;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  SendData(data,index,labData){
    this.activeTabData = labData;
    data.forEach(element => {
      if(element['type'] == 'androidOnline'){
        this.labData['androidOnline']=element['count'];
      }
      if(element['type'] =='androidOffline'){
        this.labData['androidOffline']=element['count'];
        
      }
      if(element['type'] == 'iOSOnline'){
        this.labData['iOSOnline']=element['count'];
        
      }
      if(element['type'] == 'iOSOffline'){
        this.labData['iOSOffline']=element['count'];
        
      }
    });

    this.activeMac = index;
  }
  navigateLab(data){
  this.router.navigate(["admin/dashboard-lab"],{ queryParams: { id: this.activeTabData['labId'], preserveQueryParams: false }});
  }
  
  changeAlerts(data){
    let request ={
      "labId":data.labId,
      "sendAlerts":data.sendAlerts
    }
    this.isLoading = true
    this.DeviceService.updateLab(request).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.activeMac = -1;
        this.getLabs();
      } else {

      }
    }, err => {
      this.isLoading = false;
    });
  }
  
}
