<app-spinner *ngIf="isLoading"></app-spinner>
        <h4 class="new-test-subheader">Configuration</h4>
        <div class="input-section">
            <label for="test-name"><span class="required-field">*</span>Test Name</label>
            <input type="text" class="form-control search-field" id="test-name" #testNameInput placeholder="Enter a test name"
                [ngClass]="{'error': (!testNameInput.value.length && !isNamePristine) || uniqueNameError}"
                (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)" autofocus (input)="onInput()" [ngModel]="manualTestService.testName" (ngModelChange)="changeSavedName($event)"/>
            <div *ngIf="uniqueNameError" class="input-error">{{uniqueNameError}}</div>
            <div *ngIf="!testNameInput.value.length && !isNamePristine" class="input-error">Test name can't be empty</div>
        </div>
        <div class="input-section">
            <label for="test-description">Description <span class="optional-tip">(optional)</span></label>
            <textarea max="150" rows="3" class="form-control" id="test-description"
                placeholder="Enter a description" (focusin)="emitIgnoreInput(true)"
                (focusout)="emitIgnoreInput(false)" [ngModel]="manualTestService.testDescription" (ngModelChange)="changeDescriptionName($event)"></textarea>
        </div>
        <div class="input-section checkbox-section" *ngIf="isCloudAvailable">
            <input type="checkbox" id="capture-logs" [checked]="manualTestService.artifactSelection['LOG']" (change)="handleArtifactSelection('LOG', $event)">
            <label for="capture-logs">Save device logs</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="!isCloudAvailable" [tp]="tooltipComponent"
            [tpData]="'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
            <input type="checkbox" id="capture-logs" disabled>
            <label for="capture-logs">Save device logs</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="isCloudAvailable">
            <input type="checkbox" id="capture-videos" [checked]="manualTestService.artifactSelection['VIDEO']" (change)="handleArtifactSelection('VIDEO', $event)">
            <label for="capture-videos">Capture video</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="!isCloudAvailable" [tp]="tooltipComponent"
            [tpData]="'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
            <input type="checkbox" id="capture-videos" disabled>
            <label for="capture-videos">Capture video</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="isCloudAvailable">
            <input type="checkbox" id="capture-user-actions" [checked]="manualTestService.artifactSelection['USER_ACTION']" (change)="handleArtifactSelection('USER_ACTION', $event)">
            <label for="capture-videos">Save user actions</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="!isCloudAvailable" [tp]="tooltipComponent"
            [tpData]="'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
            <input type="checkbox" id="capture-videos" disabled>
            <label for="capture-videos">Save user actions</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="isCloudAvailable">
            <input type="checkbox" id="capture-videos" [checked]="manualTestService.artifactSelection['KPIS']" (change)="handleArtifactSelection('KPIS', $event)">
            <label for="capture-videos">Save device KPIs</label>
        </div>
        <div class="input-section checkbox-section" *ngIf="!isCloudAvailable" [tp]="tooltipComponent"
            [tpData]="'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
            <input type="checkbox" id="capture-videos" disabled>
            <label for="capture-videos">Save device KPIs</label>
        </div>
        <div class="new-test-buttons">
            <button class="btn cancel_btn" (click)="cancel()">
                Cancel
            </button>
            <button class="btn control_btn_new" (click)="startNewTest()" [disabled]="!testNameInput.value || uniqueNameError || !manualTestService.selectedManualTestDevice">
                Start recording
            </button>
        </div>