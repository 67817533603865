import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../shared/common.service';
import { AdminService } from '../admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipService } from '../../../shared/tooltip.service';
import { GetLabListDto } from '../../../shared/dto';
@Component({
  selector: 'app-dashboard-lab',
  templateUrl: './dashboard-lab.component.html',
  styleUrls: ['./dashboard-lab.component.scss']
})
export class DashboardLabComponent implements OnInit {
  labData:any;
  labId:any;
  isLoading:boolean = false;
  isLoading1:boolean = false;
  deviceList=[];
  noteList=[];
  dashboardData:any;
  reportList=[]
  closeResult: any;
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  private modalRef: NgbModalRef;
  noteTitle="";
  noteText="";

  //graph Decclare
  showGraph1:boolean = false;
  graph1title="";
  type = "LineChart";
  graph1Data =[];
  graph1Column=[]
  graph1Option={}
  filter1="daily";

  showGraph2:boolean = false
  graph2title="";
  type2 = "LineChart";
  graph2Data =[];
  graph2Column=[]
  graph2Option={};
  filter2="daily";

  searchStartDate= new Date();

  ckeConfig: CKEDITOR.config = {
    language: 'en',
    allowedContent: true,
    width: '450px',
    toolbar: [
    ]
  };
  ResetbleBtntooltip='Click here to reinitialize the device';
  tooltipComponent = null;
  constructor(   
    private route: ActivatedRoute,
    private DeviceService: AdminService,
    private commonService:CommonService,
    private router: Router,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private tooltipService: TooltipService) {
      this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    
  labList=[
    {
      labName:"Mac Mini 1",
      status:"active",
      isPlug:true,
      iosActive:3,
      androidActive:10,
      androidInactive:4,
      iosInactive:2
    },
    {
      labName:"Mac Mini 2",
      status:"active",
      isPlug:true,
      iosActive:3,
      androidActive:10,
      androidInactive:4,
      iosInactive:2
    },
    {
      labName:"Mac Mini 3",
      status:"active",
      isPlug:false,
      iosActive:3,
      androidActive:10,
      androidInactive:4,
      iosInactive:2
    }
  ]
  ngOnInit() {
    localStorage.setItem('activeIndex','0')
    this.route.queryParams.subscribe(param => {
      this.labId = param['id'];
      if(this.labId){
        this.getCard();
        this.getLabs();
        this.getDeviceLabs();
        this.getNoteLabs();
        this.getTeamViwerLabs(); 

        this.getNetworkGraph();
        this.DateChange();
        this.getPingData();

      }
    
    });
    this.getLabsOverall();
  }
  changeLabList(){
    this.getCard();
    this.getLabs();
    this.getDeviceLabs();
    this.getNoteLabs();
    this.getTeamViwerLabs();
    this.getNetworkGraph();
    this.DateChange();
    this.getPingData();
  }
  getCard() {
    let query ="labId="+this.labId
    this.DeviceService.getLabDevcount(query).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.dashboardData = res.data.deviceCounts;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getLabs() {
    this.isLoading = true;
    let query ="labId="+this.labId
    this.DeviceService.getLabList(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        const data = res.data as GetLabListDto;
        this.labData = data.labList[0];
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  labListAll =[]
  getLabsOverall() {
    this.isLoading = true;
    this.DeviceService.getLabList("").subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        const data = res.data as GetLabListDto;
        this.labListAll = data.labList;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getDeviceLabs() {
    this.isLoading = true;
    let query ="labId="+this.labId
    this.DeviceService.smartDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.deviceList = res.data.deviceList;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getDeviceLabs1() {
    let query ="labId="+this.labId
    this.DeviceService.smartDeviceApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.deviceList = res.data.deviceList;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getNoteLabs() {
    this.isLoading = true;
    let query ="labId="+this.labId
    this.DeviceService.getNoteList(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.noteList = res.data.noteList;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getTeamViwerLabs() {
    this.isLoading = true;
    let query ="labId="+this.labId
    this.DeviceService.getTeamList(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.teamViewerData = res.data.teamviewerList;

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  getNetworkGraph() {
    this.isLoading = true;
    let query ="type=speed&labId="+this.labId;
    this.DeviceService.getGraphData(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        if(res.data.response.length == 0){
          this.showGraph1 = false
          return
        }
          this.setGraph1Data(res.data.response)

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  PingData() {
    this.isLoading = true;
    let request ={
      labId:this.labId
    }
    this.DeviceService.PostPing(request).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        setTimeout(() => {
          this.getPingData();
        },120000)
        this._snackBar.open("Data will be updated in few minutes", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
       

      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  pingData:any;
  getPingData() {
    this.isLoading = true;
    let query ="labId="+this.labId
    this.DeviceService.getPing(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.pingData = res.data;
      } else {

      }
    }, err => {
      this.isLoading = false;
    });

  }
  
  startEpoc:any;
  endEpoc:any;
  DateChange(){
    this.isLoading = true;
    this.startEpoc = new Date(this.searchStartDate).setHours(0, 0, 0, 0)/1000;
    this.endEpoc = new Date(this.searchStartDate).setHours(23, 59, 59, 0)/1000;
    
    let query ="type=cpuUsage&labId="+this.labId+"&startDate="+this.startEpoc+"&endDate="+this.endEpoc;
    this.DeviceService.getGraphData(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        if(res.data.labData.length == 0){
          this.showGraph2 = false;
          return
        }
          this.setGraph2Data(res.data.labData);
      } else {

      }
    }, err => {
      this.isLoading = false;
    });
  }

  rebootDevice(){

    let request ={ 
      "serialList":this.noteData['serialNumber']
    }
    this.modalRef.close();
    this.isLoading = false;
    this.DeviceService.PowerOff(request).subscribe(
      (res) => {
        this.isLoading = false;
        this.modalRef.close();
        this._snackBar.open("Phone reboot Successfully", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
        this.isLoading = false;
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
      }
    ); 
  }
  UnplugDevice(data){
    let request ={
      "serialNumber":data.serialNumber,
      "hostPort":parseInt(data.hostPort)
    }
    this.isLoading = true;
    this.DeviceService.unPlug(request).subscribe(
      (res) => {
        this.isLoading = false;
          setTimeout(() => {this.getDeviceLabs1() }, 15000);
        this._snackBar.open("Device unplug successfully, Status will be updated in few seconds.", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
        this.isLoading = false;
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
      }
    ); 
  }
  plugDevice(data){
    let request ={
      "serialNumber":data.serialNumber,
      "hostPort":parseInt(data.hostPort)
    }
    this.isLoading = true;
    this.DeviceService.Plug(request).subscribe(
      (res) => {
        this.isLoading = false;
        setTimeout(() => {this.getDeviceLabs1() }, 60000);
        this._snackBar.open("Device plug successfully, Status will be updated in few seconds.", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
        this.isLoading = false;
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
      }
    ); 
  }
   

  UnplugDeviceAndroid(data){
    let request ={
      "serialNumber":data.serialNumber,
      "hostPort":parseInt(data.hostPort)
    }
    this.isLoading = true;
    this.DeviceService.AndunPlug(request).subscribe(
      (res) => {
        this.isLoading = false;
          setTimeout(() => {this.getDeviceLabs1() }, 15000);
        this._snackBar.open("Device unplug successfully, Status will be updated in few seconds.", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
        this.isLoading = false;
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
      }
    ); 
  }
  plugDeviceAndroid(data){
    let request ={
      "serialNumber":data.serialNumber,
      "hostPort":parseInt(data.hostPort)
    }
    this.isLoading = true;
    this.DeviceService.AndPlug(request).subscribe(
      (res) => {
        this.isLoading = false;
        setTimeout(() => {this.getDeviceLabs1() }, 70000);
        this._snackBar.open("Device plug successfully, Status will be updated in few seconds.", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
        this.isLoading = false;
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
      }
    ); 
  }




  isEdit:boolean= false;
  noteData:any;
  openModal(content, btn, data) {
    if(data =='ADD'){
      this.isEdit = false;
      this.noteText = "";
      this.noteTitle = "";
    }else{
      this.isEdit = true;
      this.noteText = data['note'];
      this.noteTitle=data['heading']
      this.noteData = data;
    }
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true,
    };
;
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openModal1(content, btn, data) {
    if(data['deviceState'] !== 'Online'){
      return null
    }
    if(data =='ADD'){
      this.isEdit = false;
      this.noteText = "";
      this.noteTitle = "";
    }else{
      this.isEdit = true;
      this.noteText = data['note'];
      this.noteTitle=data['heading']
      this.noteData = data;
    }
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true,
    };

    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  isNoteAdded:boolean= false;
  teamViewerData=[];
  openModa2(content, btn, data) {
    if(data =='ADD'){
      this.isNoteAdded = false;
      this.noteText = "";
      this.noteTitle = "";
    }else{
      this.isNoteAdded = true;
      this.noteText = this.teamViewerData[0]['text'];
    }
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true,
    };
;
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  closeModal(){
    this.modalRef.close();
  }
  saveNote(){
    if(this.noteText == ''){
      this._snackBar.open('Note text is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
    }
    if(this.noteTitle == ''){
      this._snackBar.open('Note title is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
      
    }
    let request =
      {
        "labId": this.labId,
        "heading": this.noteTitle,
        "note": this.noteText
    }
    this.isLoading1 =true;
    this.DeviceService.saveNote(request).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] == 'Success') {
        this.modalRef.close()
        this.getNoteLabs();
      } else {

      }
    }, err => {
      this.isLoading1 = false;
    });
  }
  updateNote(){
    if(this.noteText == ""){
      this._snackBar.open('Note text is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
    }
    if(this.noteTitle == ""){
      this._snackBar.open('Note title is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
      
    }
    let request =
      {
        "noteId": this.noteData['noteId'],
        "heading": this.noteTitle,
        "note": this.noteText
    }
    this.isLoading1 =true;
    this.DeviceService.editNote(request).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] == 'Success') {
        this.modalRef.close()
        this.getNoteLabs();
      } else {

      }
    }, err => {
      this.isLoading1 = false;
    });
  }
  saveTeamViewer(){
    if(this.noteText == ''){
      this._snackBar.open('Text is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
    }
  
    let request =
      {
        "labId": this.labId,
        "text": this.noteText
    }
    this.isLoading1 =true;
    this.DeviceService.saveTeam(request).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] == 'Success') {
        this.modalRef.close()
        this.getTeamViwerLabs();
      } else {

      }
    }, err => {
      this.isLoading1 = false;
    });
  }
  updateTeamViwer(){
    if(this.noteText == ""){
      this._snackBar.open('Text is required ', "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
       return
    }
    
    let request =
      {
        "teamviewerId": this.teamViewerData[0]['teamviewerId'],
        "text": this.noteText
    }
    this.isLoading1 =true;
    this.DeviceService.updateTeam(request).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] == 'Success') {
        this.modalRef.close();
        this.getTeamViwerLabs();
      } else {

      }
    }, err => {
      this.isLoading1 = false;
    });
  }
  
  deleteNote(){
    this.isLoading1 =true
    this.DeviceService.deleteNote(this.noteData['noteId']).subscribe((res) => {
      this.isLoading1 = false;
      if (res['message'] == 'Success') {
        this.modalRef.close()
        this.getNoteLabs();
      } else {

      }
    }, err => {
      this.isLoading1 = false;
    });
  }
  setGraph1Data(response){
    this.showGraph1 = false;
    this.graph1Column =['Date','Download Speed','Upload Speed'];
    this.graph1Data = []

  response.forEach(element => {
    let tempList = []
    tempList.push(element['date'],element['downloadSpeedMbps'],element['uploadSpeedMbps'])
      this.graph1Data.push(tempList)
  });

  
    this.graph1Option={
      hAxis: {
        title: 'Date',
        textStyle: { color: '#000',bold: true,fontSize:10  }, slantedText: true, slantedTextAngle: 30,titleTextStyle: { color: '#000  ',bold: true,fontSize:10 }
      },
      vAxis: {
        title: 'Speed (Mbps)',
        textStyle: { color: '#000',bold: true ,fontSize:10 }, titleTextStyle: { color: '#000',bold: true,fontSize:10  }
      },
      colors: ['#53CB1B','#5E2CED'],
      legend: {position: 'top'},
      chartArea:{top:20,width:"80%"},

      fill:'white',
      backgroundColor: 'transparent',
      animation: {
        duration: 2000,
        easing: 'out',
        startup: true
      }
    }
    this.showGraph1 = true;
  }
  setGraph2Data(labData){
    this.showGraph2 = false;
    this.graph2Column =['Time','CPU Usage'];
    this.graph2Data = [];
    labData.forEach(element => {
      let tempList = []
      tempList.push(new Date(element['insertDate']*1000),element['value'])
        this.graph2Data.push(tempList)
    });
    this.graph2Option={
      hAxis: {
        title: 'Time',
        format: 'hh:mm a',
        textStyle: { color: '#000',bold: true,fontSize:10  }, titleTextStyle: { color: '#000',bold: true,fontSize:10  }
      },
      vAxis: {
        title: 'CPU Usage',
        textStyle: { color: '#000',bold: true,fontSize:10  }, titleTextStyle: { color: '#000' ,bold: true,fontSize:10 }
      },
      fill:'white',
      legend: {position: 'top'},
      colors: ['#5E2CED', '#53CB1B'],
      chartArea:{top:20,width:"85%"},
      
      backgroundColor: 'transparent',
      animation: {
        duration: 2000,
        easing: 'out',
        startup: true
      },
      viewWindow:{
        min:0
      }
    }
    this.showGraph2 = true;
  }

  openReinitModal(content, btn, data) {
  
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true,
    };


    //this.isLoading =true
    // this.DeviceService.checkCambrionixservice(data['labDomain']).subscribe((res) => {
    //   this.isLoading = false;
    //   if (res['message'] == 'Success') {
    //     this.modalRef.close()
    //     this.getNoteLabs();
    //   } else {

    //   }
    // }, err => {
    //   this.isLoading = false;
    // });

    
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
   this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
 
  resetCambrionix(serialNumber:any,labUrl){
    this.DeviceService.resetCambrionix(serialNumber,labUrl).subscribe((res) => {
      this._snackBar.open("Device reset Successfully", "", {
        duration: 3000,
          horizontalPosition: "right",
          verticalPosition: "top",
         panelClass: ["success"],
       });
    }, err => {
    });


  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

}
