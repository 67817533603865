<app-spinner *ngIf="isLoading"></app-spinner>
<span class="button_back">
    <div class="page_name" (click)="navigateMoMt('/admin/telephony')">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        {{displayTestType(testCase?.type) || 'Back'}}
    </div>
</span>
<div class="no-access" *ngIf="!isTelephonyAvailable">
    <p>
        MO-MT Testing is not available with your current subscription plan. <br />
        To get access to MO-MT Testing, you must upgrade your account to a plan that includes Telephony tests feature.
    </p>
</div>
<main class="main_test_container" *ngIf="isTelephonyAvailable && testInfo">
    <article class="left_test_container">
        <section class="stats">
            <div class="group">
                <span class="value">{{displayTestStatus()}}</span>
                Status
            </div>
            <div class="group">
                <span class="value">{{totalIterations}}</span>
                Iterations
            </div>
            <div class="group">
                <span class="value">{{passRate}} %</span>
                Pass rate
            </div>
            <div class="group" *ngIf="testInfo.type === 'SPEED_TEST'">
                <span class="value">{{getTargetThroughput()}}Mbits/sec</span>
                Target throughput
            </div>
            <div class="group">
                <span class="value">{{getExecutionTime()}}</span>
                Execution time
            </div>
            <div class="group">
                <span class="value">{{testInfo.artifacts.length}}</span>
                Artifacts
            </div>
        </section>
        <section class="info">
            <div *ngFor="let device of testInfo.devices">
                <b>{{device.type}} device:</b>
                {{device.name}} | {{device.os}} | Serial number: {{device.sn}}
            </div>
            <div *ngIf="testInfo.executedAt">
                <b>Executed at:</b>
                {{testInfo.executedAt * 1000 | date: "MM/dd/yyyy hh:mm a"}}
            </div>
            <div class="description">
                <b>Description</b>
                {{testCase?.description}}
            </div>
        </section>
        <section class="artifacts">
            <h4>
                Artifacts
                <span class="download_all" (click)="downloadArtifacts()" *ngIf="testInfo?.artifacts.length && checkDownloadAll()">
                    Download all
                </span>
            </h4>
            <div class="tabs" *ngIf="testInfo?.artifacts.length">
                <span [ngClass]="{ active: activeTab === 'COMMON'}" (click)="openTab('COMMON')">
                    Common
                    <div class="total-artifacts">
                        {{artifactsTotal.common || 0}}
                    </div>
                </span>
                <span *ngFor="let device of testInfo.devices" [ngClass]="{ active: activeTab === device.type}"
                    (click)="openTab(device.type)">
                    {{device.type}} Device
                    <div class="total-artifacts">
                        {{artifactsTotal[device.type] || 0}}
                    </div>
                </span>
            </div>
            <table *ngIf="artifactList?.length > 0" class="artifacts-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th class="size-cell">Size</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let artifact of artifactList">
                        <td>
                            <span class="ellipsis" [tp]="tooltipComponent"
                                [tpData]="artifact.result?.payload?.name | tooltipData" [tpOnlyTextOverflow]="true">
                                <img *ngIf="artifact.content !== 'VIDEO'"
                                    src="../../../../assets/images/NewUI/logs-icon.svg" alt="" />
                                <img *ngIf="artifact.content === 'VIDEO'"
                                    src="../../../../assets/images/NewUI/video-icon.svg" alt="" />
                                {{artifact.result?.payload?.name || displayArtifactType(artifact.content) }}
                            </span>
                        </td>
                        <td>
                            <span class="artifact-status">
                                <img src="../../../../assets/images/artifact_failed.svg" alt=""
                                    *ngIf="artifact.status === 'FAILED'" />
                                <div *ngIf="artifact.status === 'PROGRESS' || artifact.status === 'FINALIZING'">
                                    <mat-spinner diameter="16"></mat-spinner>
                                </div>
                                <img src="../../../../assets/images/telephony-actions/completed.svg" alt=""
                                    *ngIf="artifact.status === 'COMPLETED'" />
                                {{displayArtifactStatus(artifact.status)}}
                            </span>
                        </td>
                        <td class="size-cell">
                            {{artifact.result?.payload?.size || 0 | kilobytes: 3}}
                        </td>
                        <td>
                            <div class="actions">
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Cancel artifact finalization' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                                    *ngIf="artifact.status === 'FINALIZING' || artifact.status === 'PROGRESS'">
                                    <svg class='artifacts_icon clickable_icon' (click)="cancelFile(artifact)"
                                        [ngClass]="{disabled: !isCloudAvailable }" xmlns="http://www.w3.org/2000/svg"
                                        height="16" viewBox="0 96 960 960" width="16">
                                        <path
                                            d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
                                    </svg>
                                </span>
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                                    *ngIf="artifact.status !== 'FINALIZING' && artifact.status !== 'PROGRESS'">
                                    <img class="artifacts_icon clickable_icon"
                                        src="../../../../assets/images/download-new.svg" alt=""
                                        [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COMPLETED' }"
                                        (click)="downloadFile(artifact)" />
                                </span>
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                                    <img alt="" src="../../../../assets/images/telephony-actions/trash-can.svg"
                                        class="artifacts_icon trash_icon clickable_icon"
                                        [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COMPLETED' }"
                                        (click)="openConfirmDeleteArtifact(artifact)" />
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="no-artifacts" *ngIf="!testInfo?.artifacts.length">
                <img src="../../../../assets/images/empty_folder.svg" alt="" />
                <span>No artifacts are available for this test result.</span>
            </div>
        </section>
    </article>
    <article class="right_test_container">
        <div class="tabs" *ngIf="testInfo.type === 'SPEED_TEST'">
            <span [ngClass]="{ active: activeTabRight === 'iterations'}" (click)="openTabIterations('iterations')">
                Iterations
                <div class="total-iterations">
                    {{totalIterations || 0}}
                </div>
            </span>
            <span [ngClass]="{ active: activeTabRight === 'results'}" (click)="openTabIterations('results')">Results</span>
        </div>
        <h3 class="test_title" *ngIf="testInfo.type !== 'SPEED_TEST'">
            Iterations
            <div class="total-iterations">
                {{totalIterations || 0}}
            </div>
        </h3>
        <app-iteration-accordion [iterations]="iterations" *ngIf="testInfo.type !== 'SPEED_TEST'"></app-iteration-accordion>
        <app-speed-test-data [iterations]="iterations" *ngIf="activeTabRight === 'iterations' && testInfo.type === 'SPEED_TEST'"></app-speed-test-data>
        <div class="test-results-tab" *ngIf="activeTabRight === 'results' && testInfo.type === 'SPEED_TEST'">
            <table class="table test-list-table">
                <thead class="tableheading">
                <tr>
                    <th scope="col">Iteration</th>
                    <th scope="col">Interval</th>
                    <th scope="col">Transfer</th>
                    <th scope="col">Bitrate</th>
                    <th scope="col" *ngIf="speedTestResults[0]?.retr">Retries</th>
                    <th scope="col" *ngIf="speedTestResults[0]?.jitter">Jitter</th>
                    <th scope="col" *ngIf="speedTestResults[0]?.lostTotal">Lost/Total Datagrams</th>
                    <th scope="col">Source</th>
                </tr>
                </thead>
                <tbody>
                <tr class="position-relative" *ngFor="let data of speedTestResults; index as j">
                    <td>Iteration {{data.iteration}}</td>
                    <td>{{data.interval}}</td>
                    <td>{{data.transfer}}</td>
                    <td>{{data.bitrate}}</td>
                    <td *ngIf="data.retr">{{data.retr}}</td>
                    <td *ngIf="data.jitter">{{data.jitter}}</td>
                    <td *ngIf="data.lostTotal">{{data.lostTotal}}</td>
                    <td>{{data.source}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination-container" *ngIf="iterations?.length > 0">
            <span class="limit">
              <label for="limit">Rows per page: </label>
              <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </span>
            <span>{{paginationText}}</span>
            <div class="pagination-buttons">
              <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
                <img alt="" src="../../../../assets/images/arrow_back.svg">
              </div>
              <div [ngClass]="{ disabled: currentPage + 1 >= totalPage }" class="arrow-button" (click)="changePage(1)">
                <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
              </div>
            </div>
        </div>
    </article>
</main>
