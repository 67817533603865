<div class="feature-list-container" *ngIf="features" #listWrapper>
    <div class="feature-list" *ngIf="features && features.length > 0 ">
        <span [tp]="tooltipComponent" #feature
            [tpData]="featuresSeen | tooltipData" [tpOnlyTextOverflow]="true">
            {{featuresSeen}}
        </span>
    </div>
    <span *ngIf="features.length > 1" class="features-more"  [tp]="expandTooltip">
        {{(hiddenFeatures?.length < 10) ? hiddenFeatures?.length : 10}}+
    </span>
</div>

<ng-template #expandTooltip let-hide>
    <div class="expand-container">
        <span class="expand-feature" *ngFor="let hiddenFeature of hiddenFeatures">
            {{hiddenFeature}}
        </span>
    </div>
</ng-template>
