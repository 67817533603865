import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CloudFile, TokenData } from "../interfaces";
import { CommonService } from "../common.service";

@Component({
    selector: 'file-structure',
    templateUrl: './file-structure.component.html',
    styleUrls: ['./file-structure.component.scss'],
})
export class FileStructureComponent implements OnInit {
    @Input() isEditable: boolean = true;
    @Input() dirId: string;
    @Input() currentPath: string;
    @Input() folder: CloudFile[];
    @Input() pathRoute: { route: string; path: string; }[];
    @Input() isLoading: boolean;
    @Output() getFileStructure = new EventEmitter<string>();
    @Output() openDelete = new EventEmitter<CloudFile>();
    @Output() openRename = new EventEmitter<CloudFile>();
    @Output() downloadFile = new EventEmitter<CloudFile>();
    @Output() openUploadModal = new EventEmitter<CloudFile>();
    @Output() openCreateModal = new EventEmitter<CloudFile>();
    @Output() downloadFolder = new EventEmitter<CloudFile>();
    @Output() selectFile = new EventEmitter<CloudFile>();

    selectedItem: CloudFile;
    userData: TokenData;

    constructor(
        private commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
    }

    routerPath(index) {
        let path = this.dirId;
        for (let i = 0; i <= index; i++) {
            path += this.pathRoute[i].route + '/';
        }
        this.getFileStructure.emit(path);
    }

    getFileStorage(path: string) {
        this.getFileStructure.emit(path);
    }

    formatBytes(bytes: any, decimals = 2, type: any) {
        if (!+bytes) { return '0 Bytes'; }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if (type == 'free') {
            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;

        } else {
            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;

        }
    }

    checkIfFolderEditable() {
        return this.pathRoute.length > 0
            && !(this.pathRoute.length === 2 && this.pathRoute[0].route === 'automation')
            && !(this.pathRoute.length === 3 && this.pathRoute[1].route === 'Telephony' && this.pathRoute[0].route === 'automation')
            && !(this.pathRoute.length === 4 && this.pathRoute[2].route === 'pat' && this.pathRoute[1].route === 'Telephony' && this.pathRoute[0].route === 'automation');
    }

    deleteAction(item: CloudFile) {
        this.openDelete.emit(item);
    }

    renameAction(item: CloudFile) {
        this.openRename.emit(item);
    }

    downloadAction(item: CloudFile) {
        this.downloadFile.emit(item);
    }

    openUpload() {
        this.openUploadModal.emit();
    }

    openCreate() {
        this.openCreateModal.emit();
    }

    downloadFolderAction() {
        this.downloadFolder.emit();
    }

    selectItem(item: CloudFile) {
        if(!this.isEditable) {
            this.selectedItem = item;
            this.selectFile.emit(item);
        }
    }
}
