import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from './common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from './configuration.service';
import { SnackbarComponent } from './components/snackbar/snackbar-comp.component';

@Injectable()
export class NetworkService {
  private backendApiURL = '';

  constructor(private http: HttpClient,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private configService: ConfigurationService,
  ) {
    this.backendApiURL = this.configService.readConfig().backendApiURL;
  }

  labPost(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(url, param, httpOptions).pipe(
      catchError(this.handleError.bind(this)));
  }

  labPostWithoutHttpOptions(url: any, param?: any): Observable<any> {
    return this.http.post(url, param).pipe(
      catchError(this.handleError.bind(this)));
  }

  labPostMultipart(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(url, param, httpOptions).pipe(
      catchError(this.handleError.bind(this)));
  }

  labGet(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      catchError(this.handleError.bind(this)));
  }

  labGetFile(url: any): Observable<any> {
    const httpOptions: { headers: HttpHeaders, responseType: 'blob' } = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }),
      responseType: 'blob'
    };
    return this.http.get(url, httpOptions).pipe(
      catchError(this.handleError.bind(this)));
  }

  labGetWithoutAuth(url: any): Observable<any> {
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this)));
  }

  get(url = '', param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  login(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, {
      observe: 'response' as 'response'
    }).pipe(
      catchError(this.handleError.bind(this)));
  }

  localPost(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, {
      observe: 'response' as 'response'
    }).pipe(
      catchError(this.handleError.bind(this)));
  }

  post(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.post(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  delete(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.delete(this.backendApiURL + url, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }
  deleteDir(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.delete(this.backendApiURL + url, this._commonService.getTokenDlt(auth, param)).pipe(
      catchError(this.handleError.bind(this)));
  }
  put(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.put(this.backendApiURL + url, param, this._commonService.getToken(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  uploadImages(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    const token = localStorage.getItem('token');
    const options_: any = {
      headers: new HttpHeaders({
        Authorization: `${token}`
      })
    };
    return this.http.post(this.backendApiURL + url, param, options_);
  }

  uploadXml(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    const token = localStorage.getItem('token');
    const options_: any = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post(url, param, options_);
  }

  handleError(error: HttpErrorResponse) {
    let message = error.error.data?.message || error.error.data || 'Something went wrong';

    switch (error.status) {
      case 401:
        this._commonService.logOut();
        break;
      case 404:
        message = !error.error.data ? 'Error 404 Resource not found' : message;
        break;
      case 400:
        message = error.error.data?.message === '"email" must be a valid email' ? 'Enter a valid email' : message;
        break;
    }

    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message || 'Something went wrong', success: false },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar-failure']
    });
    return throwError(error);
  }

  getZip(url: any, param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(this.backendApiURL + url, this._commonService.getTokenZip(auth)).pipe(
      catchError(this.handleError.bind(this)));
  }

  postWithProgress(url: string, body: FormData): Observable<any> {
    const token = localStorage.getItem('token');
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": token
      }),
      reportProgress: true,
      observe: 'events' as 'body',
    }
    return this.http.post(this.backendApiURL + url, body, options);
  }

  getTestsApiZip(url = '', param?: any, head?: any, auth?: any): Observable<any> {
    return this.http.get(this.backendApiURL + url, { ...this._commonService.getToken(auth), responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError.bind(this)));
  }
}
