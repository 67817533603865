<div class="main-header">
    <div>{{testInfo?.name}}</div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="header-info">
        <div class="info-wrapper">
            <div class="sub-header">Description</div>
            <div class="description-wrapper">{{testInfo?.description}}</div>
        </div>
        <div class="divider"></div>
        <div class="info-wrapper">
            <div class="sub-header">Environment</div>
            <div class="info-value">
                Devices: {{testInfo?.devices?.length || '-'}}
            </div>
        </div>
        <div class="divider"></div>
        <div class="info-wrapper">
            <div class="sub-header">Features</div>
            <feature-list [features]="testInfo?.features"></feature-list>
        </div>
    </div>
    <table *ngIf="testInfo.type !== 'SPEED_TEST'">
        <tbody>
        <tr>
            <td class="action-icon">
                <img src="../../../../assets/images/telephony-actions/start.svg" alt="">
            </td>
            <td class="type-col">START</td>
        </tr>
        <tr *ngFor="let step of testInfo?.steps; index as i">
            <td class="action-icon">
                <img src="../../../../assets/images/telephony-actions/{{getActionIconName(step.action.data.type)}}.svg" alt="">
            </td>
            <td class="type-col" *ngIf="step.action.category === 'ACT'">{{'Device ' + getDeviceIndex(step.action.data.payload, 'sourceDevice')}}</td>
            <td class="type-col">
                {{displayActionType(step.action.data.type)}}
                <span *ngIf="step.action.category === 'ACT' && getDeviceIndex(step.action.data.payload, 'targetDevice') > 0">{{'Device ' + getDeviceIndex(step.action.data.payload, 'targetDevice')}}</span>
            </td>
            <td class="type-col" *ngIf="step.action.category === 'WAIT' && step.action.data.type === 'TIME'">{{step.action.data.payload[0].parameters[0].value}} ms</td>
            <td class="type-col" *ngIf="step.action.category === 'WAIT' && step.action.data.type === 'EVENT'">{{getVerificationForWait(step)}}</td>
        </tr>
        <tr>
            <td class="action-icon">
                <mat-icon class="icon">cleaning_services</mat-icon>
            </td>
            <td class="type-col">CLEAN UP</td>
        </tr>
        </tbody>
    </table>
    <span class="fake-gap"></span>
</div>

