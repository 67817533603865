import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { Feature } from '../../../shared/interfaces';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-create-subscription',
    templateUrl: './create-subscription.component.html',
    styleUrls: ['./create-subscription.component.scss']
})
export class CreateSubscriptionComponent implements OnInit {
    subscriptionForm: FormGroup;

    isLoading = false;
    featureList = [];
    isNameExists = false;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router) { }

    ngOnInit() {
        this.initializeForm();
        this.getFeatures();
    }

    initializeForm() {
        this.subscriptionForm = this.fb.group({
            type: ['', [Validators.required]],
            name: ['', [Validators.required]],
            description: [''],
            features: ['', Validators.compose([Validators.required])],
            status: ['', [Validators.required]],
            comments: [''],
            icon: [''],
        });
    }

    getFeatures() {
        this.isLoading = true;
        this.adminService.getFeatureList().subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const data = res.data as { features: Feature[] };
                this.featureList = data.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    createSubscription() {
        if (this.subscriptionForm.valid) {
            const {
                type,
                name,
                description,
                features,
                status,
                comments,
                icon
            } = this.subscriptionForm.value;

            const request = {
                name,
                type,
                description,
                features: features.map(feature => feature.item_id),
                status,
                comments,
                icon
            };

            this.isLoading = true;
            this.adminService.createSubscription(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] === 'Success') {
                    this.router.navigate(['admin/subscriptions']);
                    this.isLoading = false;
                } else if (res.data?.message === 'Subscription with this name already exists') {
                    this.isNameExists = true;
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    handleFileUpload($event) {
        if(!$event) {
            return this.subscriptionForm.controls.icon.setValue(''); 
        }
        this.isLoading = true;
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
            this.subscriptionForm.controls.icon.setValue(reader.result);
            this.isLoading = false;
        }
        reader.onerror = () => {
            this.isLoading = false;
        }
    }
}
