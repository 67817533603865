

<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="container-fluid fst-cn">
    <div class="row m-rw">
        <h5 class="mb-2 ml-3 device_data">Device Data</h5>
    </div>
    <div class="row m-rw align-items-center">
        <div class="col-md-3 p-2">
            <div class="phn-cl">
                <div class="row">
                    <div class="col-3">
                        <img class="img-fluid" src="../../../../assets/images/NewUI/iOS.svg">
                    </div>
                    <div class="col-9">
                        <div class="text-right">
                            <h4 class="device-nmbrs">{{ dashboardData?.totalDevices || "0" }}</h4>
                            <p class="ttl">Total Devices</p>
                            <span class="apl-dv">
                                <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.iOSDevices || "0" }}</span>
                            </span>
                            <span class="andr-dv ml-2">
                                <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.androidDevices || "0" }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
           
      
        </div>
        <div class="col-md-3 p-2">
        <div class="phn-cl1">
            <div class="row">
                <div class="col-3">
                    <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
                </div>
                <div class="col-9">
                    <div class="text-right">
                        <h4 class="device-nmbrs"> {{ dashboardData?.availableDevices || "0" }}</h4>
                        <p class="ttl">Available Devices</p>
                        <span class="apl-dv">
                            <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.availableIosDevices || "0" }}</span>
                        </span>
                        <span class="andr-dv ml-2">
                            <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.availableAndroidDevices || "0" }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      
        </div>
        <div class="col-md-3 p-2">
        <div class="phn-cl2">
            <div class="row">
                <div class="col-3">
                    <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
                </div>
                <div class="col-9">
                    <div class="text-right">
                        <h4 class="device-nmbrs">{{ dashboardData?.usedDevices || "0" }}</h4>
                        <p class="ttl">Reserved Devices</p>
                        <span class="apl-dv">
                            <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.usedIosDevices || "0" }}</span>
                        </span>
                        <span class="andr-dv ml-2">
                            <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.usedAndroidDevices || "0" }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      
        </div>
        <div class="col-md-3 p-2">
        <div class="phn-cl3">
            <div class="row">
                <div class="col-3">
                    <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
                </div>
                <div class="col-9">
                    <div class="text-right">
                        <h4 class="device-nmbrs">{{ dashboardData?.inactiveDevices || "0" }}</h4>
                        <p class="ttl">Offline Devices</p>
                        <span class="apl-dv">
                            <img src="../../../../assets/images/NewUI/apl.png"><span>{{ dashboardData?.inactiveIosDevices || "0" }}</span>
                        </span>
                        <span class="andr-dv ml-2">
                            <img src="../../../../assets/images/NewUI/android.svg"><span>{{ dashboardData?.inactiveAndroidDevices || "0" }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      
        </div>
    </div>
</div>
<div class="container-fluid p-0">
    <div class="row m-0 p-0">
        <div class="col-md-9">
            <div class="snap-boxes">
                    <h5 class="sns">Snap Boxes and Status</h5>
                <div class="row">
                    <div class="tabs col-6 mt-3">
                        <p class="no_device" *ngIf="deviceList.length == 0">
                            No Lab Available
                          </p>
                          <div class="lab_height">
                            <div class="col-12 mt-4" *ngFor ="let item of deviceList;index as i" >
                                <div class="card" (click)="SendData(item.deviceCounts,i,item)" [ngClass]="{ active: activeMac == i }">
                                    <div class="row align-items-center">
                                        <div class="col-7">
                                            <span class="txtx">{{item.name}}</span>
                                        </div>
                                        <div class="col-5 align-right">
                                            <span class="smart-hub-icon" *ngIf="item.isSmartHubAvailable && item.isSmartHubEnabled" [tp]="tooltipComponent" [tpData]="'Smart Hub enabled' | tooltipData">
                                                <img src="../../../../assets/images/NewUI/smart-hub.svg">
                                            </span>
                                            <span class="circle-container" *ngIf="item.onlineStatus == 'online'">
                                                <span id="circle"></span>
                                            </span>
                                            <span class="circle-container" *ngIf="item.onlineStatus !== 'online'">
                                                <span id="circle-red"></span>
                                            </span>
                                            <span class="online ml-2">{{item.onlineStatus}}</span>
                                            <span class="hm-icn ml-4"><img (click)="SendData(item.deviceCounts,i,item)" src="../../../../assets/images/NewUI/more.png"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        
                       
                    </div>
                    <div class="col-6 mt-3" >
                        <div class="result">
                            <p class="no_device" *ngIf="deviceList.length == 0">
                                No Device Available
                              </p>
                            <div class="row yobtn" *ngIf="deviceList.length >0">
                                <a class="vm-bt mt-3"(click)="navigateLab(activeTabData)">View More</a>
                            </div>
                            <div class="row" *ngIf="deviceList.length >0">
                                <div class="col-12 brdr mt-2">
                                    <div class="row align-items-center">
                                    <div class="col-2 img-cl">
                                        <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
                                    </div>
                                    <div class="col-7 cntnt">
                                        <span class="count">{{labData?.iOSOnline + labData?.iOSOffline}}</span>
                                        <span class="hdng-txt">Total iOS Devices</span>
                                        <p class="cn-txt">You can check all the details about devices</p>
                                    </div>
                                    <div class="col-3 btnz">
                                        <a class="onl" >Online {{labData?.iOSOnline}}</a>  
                                        <a class="ofl mt-3" >Offline {{labData?.iOSOffline}}</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="row mt-4" *ngIf="deviceList.length >0">
                                <div class="col-12 brdr mt-2">
                                    <div class="row align-items-center">
                                    <div class="col-2 img-cl">
                                        <img class="img-fluid" src="./../../../assets/images/NewUI/iOS.svg">
                                    </div>
                                    <div class="col-7 cntnt">
                                        <span class="count">{{labData?.androidOnline + labData?.androidOffline}}</span>
                                        <span class="hdng-txt">Total Android Devices</span>
                                        <p class="cn-txt">You can check all the details about devices</p>
                                    </div>
                                    <div class="col-3 btnz">
                                        <a class="onl" >Online {{labData?.androidOnline}}</a>
                                        <a class="ofl mt-3" >Offline {{labData?.androidOffline}}</a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 ryt-alr">
            <div class="row">
                <div class="col-12 allts">
                    <div class="alerts">
                        <span class="mng-alts">
                            Manage Alerts
                        </span>
                    </div>
                    <div class="col-md-12 border-bottom pt-1 pb-1">
                        <span class="dv-dl ">Lab Alerts <span class="device-alert"> <mat-slide-toggle [(ngModel)]="labAlert" (change)="changeAlert()"  ></mat-slide-toggle></span>  </span>
                    </div>
                    <div class="col-md-12 pt-1">
                        <span class="dv-dl">Device Alerts <span class="device-alert"> <mat-slide-toggle [(ngModel)]="deviceAlert" (change)="changeAlert()" ></mat-slide-toggle></span>  </span>
                    </div>
                   
                    
                </div>
                
            </div>
            <div class="row">
                <div class="col-12 allts">
                    <div class="alerts">
                        <span class="mng-alts">
                            Urgent Alerts
                        </span>
                    </div>
                    <p class="no_history" *ngIf="reportList1.length == 0">No Data Available</p>
                    <div class="urjent_height_no_data" [ngClass]="{ urjent_height: reportList1.length > 10}" >
                        <div class="row m-0 ml-2 p-0 logsBox border-bottom" *ngFor="let item of reportList1">
                            <div class="row col-12">
                              <div class="col-md-6">
                                  <span  class="th_label bold">Date :</span>
                                  <span class="th_value">{{item?.insertDate *1000 | date: "MM/dd/yyyy hh:mm"}}</span>
                              </div> 
                               <div class="col-md-6 p-0">
                                  <span  class="th_label bold">Type :</span>
                                  <span  class="th_value" *ngIf="item.type =='cpuUsagePercentage'">CPU Usage</span>
                                  <span  class="th_value" *ngIf="item.type =='cpuLoadAvg'">CPU Load Avg</span>
                                  <span  class="th_value" *ngIf="item.type =='diskUsedPercentage'">Disk Usage</span>
                                  <span  class="th_value" *ngIf="item.type =='pingMs'">Ping</span>
                                  <span  class="th_value" *ngIf="item.type =='labStatus'">Lab Status</span>
                               </div>
                            </div>
                            <div class="col-md-12">
                              <span class="th_label bold">Lab Name :</span>
                               <span  class="th_value">{{item?.name}}</span>
                           </div>
                        <div class="col-md-12 mb-1 logs_message semi_bold" [ngClass]="{ color_green: item.isOK == true }" ><span>{{item.message}}</span></div>
                        </div>
                    </div>
                
            </div>
        </div>
    </div>
</div>
