import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetLabListDto } from '../../../shared/dto';
import { TooltipService } from '../../../shared/tooltip.service';
import { Lab, TokenData } from '../../../shared/interfaces';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { CommonService } from '../../../shared/common.service';
import { UserRoleEnum } from '../../../shared/enum';

type LabType = "public" | "corporate";

@Component({
  selector: 'app-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.scss']
})
export class LabListComponent implements OnInit {
  searchText = "";
  searchStatus = '';
  searchEnterprise = '';
  enterpriseOptions = [];
  searchSnapSupport = '';
  snapSupportOptions = [];
  searchLabSupport = '';
  labSupportOptions = [];
  searchAppium = '';
  searchSmartHub = '';
  searchAlerts = '';
  searchEnabled = '';
  userData: TokenData;
  pageLimit = 10;
  totalPages = 0;
  currentPage = 0;
  labList: Lab[] = [];
  isLoading = 0;
  activeTab: LabType = "public";
  totalPublic = 0;
  totalCorporate = 0;
  paginationText = "0-0 of 0";
  tooltipComponent = null;
  isFiltered = false;
  isFilterOpen = false;

  constructor(
    private deviceService: AdminService,
    public router: Router,
    private tooltipService: TooltipService,
    private dialogModalService: DialogModalService,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
    this.activeTab = this.userData.role !== UserRoleEnum.ADMIN ? 'corporate' : 'public';
    this.getRouteQuery();
    this.getLabsList();
    if (this.activeTab === 'corporate') {
      this.getEnterprises();
      this.getUniqueLabSupport();
    }
    this.getUniqueSnapSupport();
  }

  setPage(data: GetLabListDto) {
    this.totalPublic = data.totalPublic;
    this.totalCorporate = data.totalCorporate;
    this.totalPages = data.totalPages;
    const total = this.activeTab === 'public' ? this.totalPublic : this.totalCorporate;
    const max = (this.currentPage + 1) * this.pageLimit;
    this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
  }

  getLabsList() {
    this.isLoading++;
    const query = this.getQueryParam();
    this.deviceService.getLabList(query).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        const data = res.data as GetLabListDto;
        this.labList = data.labList;
        this.setPage(data);
      }
    }, err => {
      this.isLoading--;
    });
  }

  getQueryParam() {
    let query = `size=${this.pageLimit}&page=${this.currentPage}&type=${this.activeTab}`;
    if (this.searchText) {
      query += `&text=${encodeURIComponent(this.searchText.trim())}`;
    }
    if (this.searchStatus) {
      query += `&status=${this.searchStatus}`;
    }
    if (this.searchEnterprise) {
      query += `&enterpriseId=${this.searchEnterprise}`;
    }
    if (this.searchSnapSupport) {
      query += `&snapSupport=${encodeURIComponent(this.searchSnapSupport)}`;
    }
    if (this.searchLabSupport) {
      query += `&labSupport=${encodeURIComponent(this.searchLabSupport)}`;
    }
    if (this.searchAppium !== '') {
      query += `&isAppium=${this.searchAppium}`;
    }
    if (this.searchSmartHub !== '') {
      query += `&isSmartHub=${this.searchSmartHub}`;
    }
    if (this.searchAlerts !== '') {
      query += `&isAlerts=${this.searchAlerts}`;
    }
    if (this.searchEnabled !== '') {
      query += `&isEnabled=${this.searchEnabled}`;
    }

    return query;
  }

  getEnterprises() {
    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.isLoading++;
      this.deviceService.getEnterprise('').subscribe((res) => {
        this.isLoading--;
        if (res['message'] == 'Success') {
          this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
        }
      }, err => {
        this.isLoading--;
      });
    }
  }

  getUniqueSnapSupport() {
    this.isLoading++;
    const query = `type=${this.activeTab}&support=SNAP`;
    this.deviceService.getUniqueSupport(query).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.snapSupportOptions = res.data.supportList;
      }
    }, err => {
      this.isLoading--;
    });
  }

  getUniqueLabSupport() {
    this.isLoading++;
    const query = `type=${this.activeTab}&support=LAB`;
    this.deviceService.getUniqueSupport(query).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.labSupportOptions = res.data.supportList;
      }
    }, err => {
      this.isLoading--;
    });
  }

  openTab(tab: LabType) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      this.currentPage = 0;
      this.resetFilters();
      this.getLabsList();
      if (tab === 'corporate') {
        this.getEnterprises();
        this.getUniqueLabSupport();
      }
      this.getUniqueSnapSupport();
    }
  }

  changePageLimit() {
    this.currentPage = 0;
    this.getLabsList();
  }

  changePage(direction: number) {
    this.currentPage += direction;
    this.getLabsList();
  }

  resetFilters() {
    this.isFiltered = false;
    this.searchText = '';
    this.searchStatus = '';
    this.searchEnterprise = '';
    this.searchSnapSupport = '';
    this.searchLabSupport = '';
    this.searchAppium = '';
    this.searchSmartHub = '';
    this.searchAlerts = '';
    this.searchEnabled = '';
  }

  clearFilters() {
    this.resetFilters();
    this.getLabsList();
  }

  navigatePage(path: string, query?: Record<string, string>) {
    this.router.navigate([path], { queryParams: query });
  }

  displaySupport(lab: Lab, supportType: 'LAB' | 'SNAP'): string {
    const contact = lab.contacts?.find((contact) => contact.type === supportType);
    if (contact) {
      return `${contact.name} ${contact.email}`;
    }
    return '';
  }

  displayDeviceLimit(lab: Lab): string {
    return `${lab.maxDeviceCount ? lab.maxDeviceCount : 'Unlimited'}`;
  }

  displayDeviceAmount(lab: Lab): number {
    return lab.deviceCounts.reduce((acc, devices) => {
      return acc += devices.count;
    }, 0);
  }

  updateAppiumSupport(value: boolean, lab: Lab) {
    const request = {
      "labId": lab.labId,
      "isAppiumSupport": value
    };

    this.isLoading++;
    this.deviceService.updateLab(request).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.getLabsList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  updateAlerts(value: boolean, lab: Lab) {
    const request = {
      "labId": lab.labId,
      "sendAlerts": value
    };

    this.isLoading++;
    this.deviceService.updateLab(request).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.getLabsList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  updateSmartHub(value: boolean, lab: Lab) {
    const request = {
      "labId": lab.labId,
      "isSmartHubEnabled": value
    };

    this.isLoading++;
    this.deviceService.updateLab(request).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.getLabsList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  openConfirmDelete(labId: string) {
    this.dialogModalService.openConfirmationDialog('deleteLab', () => this.deleteLab(labId));
  }

  deleteLab(labId: string) {
    this.isLoading++;
    this.deviceService.deleteLab(labId).subscribe((res) => {
      this.isLoading--;
      if (res.statusCode === 200) {
        this.getLabsList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  getSupportUser(lab: Lab, supportType: 'LAB' | 'SNAP'): { name: string, logo: string } {
    const contact = lab.contacts?.find((contact) => contact.type === supportType);
    if (contact) {
      return { name: contact.name, logo: contact.logo };
    }
    return { name: '', logo: '' };
  }

  searchChange() {
    this.isFiltered = this.searchText ? true : false;
    this.getLabsList();
  }

  openFilters() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  applyFilters() {
    this.isFilterOpen = false;
    this.isFiltered = this.searchText !== '' ||
      this.searchStatus !== '' ||
      this.searchEnterprise !== '' ||
      this.searchSnapSupport !== '' ||
      this.searchLabSupport !== '' ||
      this.searchAppium !== '' ||
      this.searchSmartHub !== '' ||
      this.searchAlerts !== '' ||
      this.searchEnabled !== '';
    this.getLabsList();
  }

  getRouteQuery() {
    const enterpriseId = this.route.snapshot.queryParamMap.get('enterpriseId');
    if (enterpriseId) {
      this.activeTab = 'corporate';
      this.searchEnterprise = enterpriseId;
      this.isFiltered = true;
    }
  }
}
