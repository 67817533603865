import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ManualTestService } from "../manual-test.service";
import { ManualTest, ManualTestArtifact } from "../../../shared/interfaces";
import { Subscription } from "rxjs";
import { scan } from 'rxjs/operators';
import { DeviceService } from "../../view-device/device.service";
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ArtifactStatusEnum, DateFilterTypeEnum, FeatureTypeEnum, TestResultEnum, TestStatusDisplayMap, TestStatusEnum } from "../../../shared/enum";
import { DialogModalService } from "../../../shared/dialog-modal.service";
import { ApiResponseDto, UpdateManualTestDto } from "../../../shared/dto";
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { TooltipService } from "../../../shared/tooltip.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CommonService } from "../../../shared/common.service";
import { MatDrawer } from '@angular/material/sidenav';

@Component({
    selector: 'app-manual-test-list',
    templateUrl: './manual-test-list.component.html',
    styleUrls: ['./manual-test-list.component.scss'],
})
export class ManualTestListComponent implements OnInit, OnDestroy {
    testList: ManualTest[] = [];
    testListSubscription: Subscription = null;
    dateFilter: DateFilterTypeEnum;
    FilterType = DateFilterTypeEnum;

    expandedRows = new Set();

    private modalRef: NgbModalRef;
    modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'md',
    };
    editTestForm = new FormGroup({
        editName: new FormControl('', [Validators.required]),
        editDescription: new FormControl(''),
        editResult: new FormControl(''),
    });
    selectedTest: ManualTest;
    descriptionToAdd: string;
    uploadedFile: File;
    fileUploadState = { state: 'PENDING', progress: 0 };
    searchTimeout = null;
    tooltipComponent = null;
    isCloudAvailable = false;
    selectedIndex: number;


    statusList = [
        { item_id: TestStatusEnum.COMPLETED, item_text: TestStatusDisplayMap.get(TestStatusEnum.COMPLETED) },
        { item_id: TestStatusEnum.FINALIZING, item_text: TestStatusDisplayMap.get(TestStatusEnum.FINALIZING) },
        { item_id: TestStatusEnum.CANCELED, item_text: TestStatusDisplayMap.get(TestStatusEnum.CANCELED) },
        { item_id: TestStatusEnum.PROGRESS, item_text: TestStatusDisplayMap.get(TestStatusEnum.PROGRESS) },
        { item_id: TestResultEnum.FAIL, item_text: TestStatusDisplayMap.get(TestResultEnum.FAIL) },
        { item_id: TestResultEnum.PASS, item_text: TestStatusDisplayMap.get(TestResultEnum.PASS) },
    ];

    get editName(): AbstractControl { return <AbstractControl>this.editTestForm.get('editName'); }
    get editDescription(): AbstractControl { return <AbstractControl>this.editTestForm.get('editDescription'); }
    get editResult(): AbstractControl { return <AbstractControl>this.editTestForm.get('editResult'); }

    @ViewChild('rangePicker') rangePicker: MatDateRangePicker<unknown>;
    @ViewChild('drawer') drawer: MatDrawer;

    constructor(
        public manualTestService: ManualTestService,
        private deviceService: DeviceService,
        private modalService: NgbModal,
        private dialogService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.testListSubscription = this.manualTestService.testList$.subscribe((list) => this.testList = list);
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }
    emitIgnoreInput(value: boolean): void {
        this.deviceService.ignoreInputFromRecordAndPlay(value);
    };
    ngOnInit(): void {
        this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    }

    ngOnDestroy(): void {
        this.testListSubscription.unsubscribe();
        this.testListSubscription = null;
    };

    displayTestStatus(test: ManualTest): string {
        const status = test.result?.decision ? test.result.decision : test.status;
        return TestStatusDisplayMap.get(status);
    }

    getStatusColor(test: ManualTest): string {
        const status = test.result?.decision ? test.result.decision : test.status;
        switch (status) {
            case TestResultEnum.PASS:
                return '#2BA327';
            case TestResultEnum.FAIL:
                return '#F72717';
            case TestStatusEnum.COMPLETED:
                return '#5E2CED';
            case TestStatusEnum.PROGRESS:
                return '#FA7D00';
            default:
                return '#FA7D00';
        }
    }

    filterTests(): void {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.manualTestService.getManualTests();
            this.expandedRows.clear();
        }, 1000);
    }

    getFilteredTests(): void {
        this.manualTestService.getManualTests();
        this.expandedRows.clear();
    }

    setDateFilter(param: DateFilterTypeEnum): void {
        if (this.dateFilter === param) {
            this.dateFilter = null;
            this.manualTestService.resetDateFilter();
        } else {
            this.dateFilter = param;
            switch (param) {
                case DateFilterTypeEnum.TODAY:
                    this.manualTestService.setTodayFilter();
                    break;
                case DateFilterTypeEnum.LAST_WEEK:
                    this.manualTestService.setLastWeekFilter();
                    break;
                case DateFilterTypeEnum.CUSTOM:
                    this.rangePicker.open();
                    break;
            };
        }
        this.expandedRows.clear();
    };

    updateDateFilter(type: string, $event: MatDatepickerInputEvent<Date>) {
        this.manualTestService.setCustomDateFilter(type, $event.value);
        this.expandedRows.clear();
    }

    changePage(index: number) {
        this.manualTestService.changePage(index);
        this.expandedRows.clear();
    }

    checkIfArtifactsReady(artifacts: ManualTestArtifact[]): boolean {
        return this.isCloudAvailable && artifacts?.length > 0 && !artifacts.some(artifact => {
            return artifact.status === ArtifactStatusEnum.FINALIZING || artifact.status === ArtifactStatusEnum.PROGRESS;
        });
    }

    checkArtifactDownload(artifacts: ManualTestArtifact[]): boolean {
        return this.isCloudAvailable && artifacts?.length > 0 && artifacts.some(artifact => artifact.status === ArtifactStatusEnum.COMPLETED);
    }

    checkArtifactsStatus(artifacts: ManualTestArtifact[], status: ArtifactStatusEnum): boolean {
        return artifacts.some(artifact => artifact.status === status);
    }

    downloadArtifacts(test: ManualTest) {
        if (this.checkIfArtifactsReady(test.artifacts)) {
            this.manualTestService.downloadAllArtifacts(test.name).subscribe((res) => {
                const blob = new Blob([res.body], { type: res.headers.get('content-type') });
                const fileName = res.headers
                    .get('content-disposition')
                    .split('filename=')
                    .at(-1);

                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);

                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            });
        }
    }

    openEditTest(content, test: ManualTest) {
        if (test._id !== this.manualTestService.ongoingTestId) {
            this.selectedTest = test;
            this.editTestForm.setValue({ editName: test.name, editDescription: test.description, editResult: test.result?.decision || null });
            this.modalRef = this.modalService.open(content, this.modalOptions);
        }
    }

    setEditResult(result: TestResultEnum): void {
        this.editResult.setValue(result === this.editResult.value ? null : result);
    }

    closeEditModal(): void {
        this.modalRef.close('cancel');
        this.selectedTest = null;
    }

    applyEdit(): void {
        if (!this.editTestForm.invalid) {
            const body: UpdateManualTestDto = {
                name: this.editName.value,
                description: this.editDescription.value,
                decision: this.editResult.value,
            };

            this.manualTestService.updateTest(this.selectedTest._id, body).subscribe((res: ApiResponseDto<ManualTest>) => {
                if (res.statusCode === 400) {
                    this.editTestForm.controls['editName'].setErrors({ 'uniqueError': true });
                } else {
                    this.manualTestService.updateTestList(res.data as ManualTest);
                    this.modalRef.close('accept');
                    this.selectedTest = null;
                }
            });
        }
    }

    openConfirmDelete(test: ManualTest) {
        if (test._id !== this.manualTestService.ongoingTestId) {
            this.dialogService.openConfirmationDialog('deleteTestModal', () => this.manualTestService.deleteTest(test._id));
        }
    }

    clearFilters = () => {
        this.dateFilter = null;
        this.expandedRows.clear();
        this.manualTestService.resetFilters();
    }

    openModal(content, test: ManualTest) {
        this.selectedTest = test;
        this.modalRef = this.modalService.open(content, this.modalOptions);
    }

    applyDescription() {
        if (this.descriptionToAdd) {
            this.manualTestService.updateTest(this.selectedTest._id, { description: this.descriptionToAdd }).subscribe((res: ApiResponseDto<ManualTest>) => {
                if (res.statusCode === 200) {
                    this.manualTestService.updateTestList(res.data as ManualTest);
                    this.selectedTest = null;
                    this.descriptionToAdd = '';
                    this.modalRef.close('accept');
                }
            });
        }
    }

    handleFileUpload($event: File) {
        this.uploadedFile = $event;
        const calculateProgress = (upload, event: HttpEvent<unknown>) => {
            if (this.isHttpProgressEvent(event)) {
                return {
                    progress: event.total
                        ? Math.round((100 * event.loaded) / event.total)
                        : upload.progress,
                    state: 'IN_PROGRESS',
                }
            }
            if (this.isHttpResponse(event)) {
                return {
                    progress: 100,
                    state: 'DONE',
                }
            }
            return upload
        }
        this.manualTestService.uploadArtifact(this.selectedTest._id, $event).pipe(scan(calculateProgress, this.fileUploadState)).subscribe((res) => {
            // TODO test file upload progress
        })
    }

    isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response
    }

    isHttpProgressEvent(
        event: HttpEvent<unknown>
    ): event is HttpProgressEvent {
        return (
            event.type === HttpEventType.DownloadProgress ||
            event.type === HttpEventType.UploadProgress
        )
    }

    changePageSize($event: Event) {
        const target = $event.target as HTMLSelectElement;
        this.manualTestService.changePageSize(Number(target.value));
    }

    closeDescriptionModal() {
        this.modalRef.close('cancel');
        this.selectedTest = null;
        this.descriptionToAdd = '';
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }
    showTestDetails(i: number) {
        this.selectedIndex = i;
        this.drawer.toggle();
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
        this.selectedIndex = null;
    }

    updateTable($event: any) {
        // this.getTestsList();
    }
}
