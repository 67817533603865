import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonService } from '../../../shared/common.service';
import { AuthServiceLocal } from '../../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StreamService } from '../../../shared/stream.service';
import { NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {HttpResponse} from '@angular/common/http';
import { UserDataService } from '../../../shared/user-data.service';
import { UserRoleEnum } from '../../../shared/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  resetForm: FormGroup;
  changePassForm: FormGroup;
  formSubmitted = false;
  formSubmitted1 = false;
  isLoading = false;
  show = false;
  isLogin = true;
  isForgot_text = false;
  emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  message = '';
  type = '0';
  private modalRef: NgbModalRef;
  subscribedPage: any;

  isLower = true;
  isUpper = true;
  isLength = true;
  isSpecial = true;
  isSecure = false;
  passChangeCheck = {
    lower: false,
    upper: false,
    length: false,
    special: false
  };

  activeSlider = 1;
  isRememberMe = false;
  subscribeSlider: any;
  isSnapUI = true;
  changePassword = false;
  newPassInvalidCheck = false;
  newPassConfirmCheck = false;
  userDataSub: Subscription;

  constructor(
      private fb: FormBuilder,
      private authServices: AuthServiceLocal,
      private commonService: CommonService,
      private streamService: StreamService,
      private router: Router,
      private route: ActivatedRoute,
      private cookieService: CookieService,
      private userService: UserDataService
      ) { }

  checkDomain() {
    this.isSnapUI = window.location.hostname.toLocaleLowerCase() !== 'verizon.snapautomation.com';
    this.route.queryParams.subscribe(param => {
      if (param['verizon']) {
        this.isSnapUI = false;
      }
    });
  }

  ngOnInit() {
    this.checkDomain();
    this.initialiseForms();
    this.validateToken();
    this.subscribedPage = this.streamService.getFilterSubscription().subscribe((data: string) => {
      if (data) {
        this.modalRef.close();
      }
    });
    this.subscribeSlider = setInterval(() => {
      this.navigateright();
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.subscribeSlider) {
      clearInterval(this.subscribeSlider);
    }

    if (this.userDataSub && !this.userDataSub.closed) {
      this.userDataSub.unsubscribe();
    }
  }

  EmailEnter() {
    if (this.loginForm.value.password === '' && this.loginForm.value.email === '') {
      this.formSubmitted = false;
    }
    this.formSubmitted = false;
  }

  getPassword() {
    if (this.changePassword) {
      const password = this.changePassForm.value.updatedPass;
      this.passChangeCheck.length = password.length > 7;
      this.passChangeCheck.lower = !!password.match('[a-z]');
      this.passChangeCheck.upper = !!password.match('[A-Z]');
      this.passChangeCheck.special = !!password.match('[#$^%,!&@_\.-]');
      this.newPassInvalidCheck = Object.values(this.passChangeCheck).every(item => item);

      const confirm = this.changePassForm.value.confirmUpdatedPass;
      this.newPassConfirmCheck = password === confirm;
    } else {
      this.formSubmitted1 = false;
      if (this.loginForm.value.password === '' && this.loginForm.value.email === '') {
        this.formSubmitted1 = false;
      }
      const password = this.loginForm.value.password;

      if (password.length < 2) {
        this.isUpper = true;
        this.isLower = true;
        this.isLength = true;
        this.isSpecial = true;
        return;
      }
      this.isLength = password.length > 7;
      this.isLower = !!password.match('[a-z]');
      this.isUpper = !!password.match('[A-Z]');
      this.isSpecial = !!password.match('[#$^%,!&@_\.-]');
      this.isSecure = this.isLower && this.isUpper && this.isLength && this.isSpecial;
    }
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  validateToken() {
    const token = localStorage.getItem('token');
    if (token) {
      this.userDataSub = this.userService.userData$.subscribe((user) => {
        if (user?.email) {
          if (user.isFirstLogin) {
            this.changePassword = true;
          } else {
            this.router.navigate(user.role === UserRoleEnum.ADMIN ? ['/snap/dashboard'] : ['/snap/homepage']);
          }
        }
      });
    }
  }
  navigateSign(value) {
    if (value === 'SIGN') {
      this.isLogin = true;
      this.isForgot_text = false;
    } else {
      this.isLogin = false;
    }
  }

  initialiseForms() {
    const email = this.cookieService.get('email') || '';
    const password = this.cookieService.get('password') || '';
    if (password && email) {
      this.isRememberMe = true;
      this.isSecure = true;
    }

    this.loginForm = this.fb.group({
      email: [email, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [password, [Validators.required, Validators.minLength(6)]],

    });
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    });
    this.changePassForm = this.fb.group({
      updatedPass: ['', [Validators.required, Validators.minLength(8)]],
      confirmUpdatedPass: ['', [Validators.required, Validators.minLength(8)]]
    });
    if (password && email) {
      this.isRememberMe = true;
    }
  }

  resetSubmit() {
    this.formSubmitted = true;
    if (this.resetForm.valid) {
      const req = {
        email: this.resetForm.controls['email'].value.toLowerCase(),
      };
      this.isLoading = true;
      this.authServices.forgotPassword(req).subscribe(
          (res) => {
            if ((res['message'] = 'Success')) {
              this.isLoading = false;
              this.isForgot_text = true;
              this.initialiseForms();
            } else {
            }
          },
          (err) => {
            this.isLoading = false;
          }
      );
    }
  }

  login() {
    this.formSubmitted = true;
    this.formSubmitted1 = true;
    if (this.loginForm.valid && this.isSecure === true) {
      const req = {
        email: this.loginForm.controls['email'].value.trim(),
        password: this.loginForm.controls['password'].value.trim()
      };
      this.isLoading = true;
      this.authServices.validateLogin(req).subscribe(
          (res: HttpResponse<any>) => {
            this.isLoading = false;
            if ((res.body && res.body['message'] === 'Success')) {

              if (this.isRememberMe === true) {
                this.cookieService.set('email', this.loginForm.controls['email'].value.trim());
                this.cookieService.set('password', this.loginForm.controls['password'].value.trim());
              } else {
                this.cookieService.delete('email');
                this.cookieService.delete('password');
              }
              this.commonService.setStorage('token', res.headers.get('Authorization'));

              if (res.body.data.authentication?.accessToken) {
                this.commonService.setStorage('token', res.body.data.authentication.accessToken);
              }
              if (res.body.data) {
                this.userService.setUserData(res.body['data']);
                this.userService.startProfilePolling();
                localStorage.setItem('enterpriseName', res.body['data']['enterpriseName']);
                localStorage.setItem('enterpriseLogo', res.body['data']['corporateIcon']);

                if (res.body.data.isFirstLogin) {
                  this.changePassword = true;
                } else {
                  if (res.body.data.role === UserRoleEnum.ADMIN) {
                    this.router.navigate(['/snap/dashboard']);
                  } else {
                    this.router.navigate(['/snap/homepage']);
                  }
                }

              }
              localStorage.setItem('activeIndex', '0');
            }
          },
          err => {
            this.isLoading = false;
          }
      );

    }
  }
  password() {
    this.show = !this.show;
  }

  navigateright() {
    if (this.activeSlider === 1) {
      this.activeSlider = 2;
      return;
    }
    if (this.activeSlider === 2) {
      this.activeSlider = 3;
      return;
    }
    if (this.activeSlider === 3) {
      this.activeSlider = 1;
      return;
    }
  }

  updatePassword() {
    if (this.changePassForm.valid) {
      this.isLoading = true
      this.authServices.changeOPT({ password: this.changePassForm.value.updatedPass}).subscribe((response) => {
        this.isLoading = false;
        if (response.statusCode === 200) {
          this.changePassword = false;
          const userData = this.commonService.getUser();
          this.router.navigate(userData.role === UserRoleEnum.ADMIN ? ['/admin'] : ['/snap/homepage']);
        }
      }, () => this.isLoading = false);
    }
  }
}
