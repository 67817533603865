import { Component, OnInit, ViewChild } from '@angular/core';
import { ManualTestService } from '../manual-test.service';
import { ManualTest } from '../../../shared/interfaces';
import { ApiErrorResponse, ApiResponseDto, NewManualTestDto } from '../../../shared/dto';
import { DeviceService } from '../../view-device/device.service';
import { FeatureTypeEnum } from '../../../shared/enum';
import { TooltipService } from '../../../shared/tooltip.service';
import { CommonService } from '../../../shared/common.service';
import { MatDrawer } from '@angular/material/sidenav';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { DevicehomeService } from '../../device-homepage/devicehome.service';
import { ArtifactTypeEnum } from "../../../shared/enum";

@Component({
    selector: 'app-new-manual-test-form',
    templateUrl: './new-manual-test-form.component.html',
    styleUrls: ['./new-manual-test-form.component.scss']
})
export class NewManualTestFormComponent implements OnInit {

    uniqueNameError = '';
    isNamePristine = true;
    tooltipComponent = null;
    isCloudAvailable = false;
    isLoading = false;
    searchText = '';
    deviceList = [];
    searchLab = '';
    searchLocation = '';
    selectedBookedFilter = 'available';
    bookingFilterList = [];
    filterList = [];
    locationListDropdown = [];
    currentDeviceList = [];
    deviceState = '';

    @ViewChild('drawer') drawer: MatDrawer;

    constructor(
        protected manualTestService: ManualTestService,
        private deviceService: DeviceService,
        private commonService: CommonService,
        private tooltipService: TooltipService,
        private dialogModalService: DialogModalService,
        private deviceHome: DevicehomeService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }
    ngOnInit(): void {
        this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    }

    emitIgnoreInput(value: boolean): void {
        this.deviceService.ignoreInputFromRecordAndPlay(value);
        this.updateIgnoreTyping(value);
    }

    startNewTest(): void {
        const newTestForm: NewManualTestDto = {
            name: this.manualTestService.testName,
            description: this.manualTestService.testDescription,
            artifactSelection: Object.keys(this.manualTestService.artifactSelection),
            serialNumber: this.manualTestService.selectedManualTestDevice.serialNumber
        };
        this.manualTestService.startManualTestSession(newTestForm).subscribe((res: ApiResponseDto<ManualTest>) => {
            if (res.statusCode === 400) {
                const response = res.data as ApiErrorResponse;
                this.uniqueNameError = response.message;
            } else {
                this.manualTestService.handleTestStart(res.data as ManualTest);
                setTimeout(() => this.clearInputs(), 2000);
            }
        });
    }

    cancel(): void {
        this.dialogModalService.openConfirmationDialog('discardManualTest', () => this.cancelTestCreation());
    }

    cancelTestCreation(): void {
        if (this.manualTestService.selectedManualTestDevice) {
            this.releaseDeviceAndLeave();
        } else {
            this.manualTestService.removeNewTestForm();
        }
        this.clearInputs();
    }

    releaseDeviceAndLeave(): void {
        this.isLoading = true;
        this.deviceHome.releaseDevice({ deviceBookingId: this.manualTestService.selectedManualTestDevice.bookingId }).subscribe(() => {
            this.manualTestService.selectedManualTestDevice = null;
            this.manualTestService.removeNewTestForm();
            this.isLoading = false;
        }, () => this.isLoading = false);
    }

    onInput() {
        this.isNamePristine = false;
        this.uniqueNameError = '';
    }

    clearInputs() {
        this.changeSavedName('');
        this.changeDescriptionName('');
        this.manualTestService.artifactSelection = { ...this.manualTestService.allAvailableArtifacts };
    }

    updateIgnoreTyping = (v: boolean) => {
        this.manualTestService.ignoreTyping = v;
    }

    changeSavedName = (value: string) => {
        this.manualTestService.testName = value;
    }

    changeDescriptionName = (value: string) => {
        this.manualTestService.testDescription = value;
    }

    handleArtifactSelection(type: string, event) {
        if (event?.target.checked) {
            this.manualTestService.artifactSelection[type] = event?.target.checked;
        } else {
            delete this.manualTestService.artifactSelection[type];
        }
    }
}
