<div class="" >
    
    <div class="row m-0 p-0" style="flex-direction: column;">
        <div class="p-2 px-3 formsFilters">
            <span class="formHeaders">{{modalData?.title}}</span>
            <span><img (click)="closeIt()" class="crossForm" src="../../../../assets/images/NewUI/cross.svg" alt=""></span>
            
        </div>
        <div class="p-3">
            <div class="row">
                <div class="col-md-8">
                    <video width="100%" height="400px"  controls>
                        <source [src]="modalData['media']" type="video/mp4">
                        <!-- <source src="../../../../assets/1714391965071_#1713762318798_1713762143344_1706955594173_file_example_MP4_480_1_5MG.mp4" type="video/mp4"> -->
                      </video>
                </div>
                <div class="col-md-4">
                    <div class="right-side">
                        <span class="header">Creating A New Session</span>
                        <span class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. laborum</span>
                        <span class="steps text-upercase">STEPS</span>
                        <ul class="custom-list">
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  </div>
    