<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>Update enterprise</h1>
<div class="not-found" *ngIf="showError">
    Enterprise not found
</div>
<form class="enterprise-form" [formGroup]="enterpriseForm" (ngSubmit)="onSubmit()">
    <section class="left-part">
        <div class="group">
            <label for="name">Enterprise Name <span>*</span></label>
            <input type="text" formControlName="name" maxlength="50" id="name" class="form-control"
                placeholder="Enter Enterprise Name" />
            <div *ngIf="enterpriseForm.controls.name.invalid && (enterpriseForm.controls.name.dirty || enterpriseForm.controls.name.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.name.errors.required">*Name is required</div>
            </div>
        </div>
        <div class="group">
            <label for="location">Location</label>
            <input type="text" formControlName="location" id="location" class="form-control"
                placeholder="Enter Location" />
        </div>
        <div class="group">
            <label for="snapSupportContact">Snap Support Contact <span>*</span></label>
            <select class="form-control" id="snapSupportContact" formControlName="snapSupportContact"
                [ngClass]="{ placeholder: enterpriseForm.value.snapSupportContact === ''}">
                <option value="">Select Snap Support Contact</option>
                <option *ngFor="let option of snapSupportOptions" [value]="option.email">{{option.name}}</option>
            </select>
            <div *ngIf="enterpriseForm.controls.snapSupportContact.invalid && (enterpriseForm.controls.snapSupportContact.dirty || enterpriseForm.controls.snapSupportContact.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.snapSupportContact.errors.required">
                    *Snap Support Contact is required
                </div>
            </div>
        </div>
        <div class="group">
            <label for="enterpriseSupportContact">Enterprise Support Contact</label>
            <select class="form-control" id="enterpriseSupportContact" formControlName="enterpriseSupportContact"
                [ngClass]="{ placeholder: enterpriseForm.value.enterpriseSupportContact === ''}">
                <option value="">Select Enterprise Support Contact</option>
                <option *ngFor="let option of enterpriseSupportOptions" [value]="option.email">{{option.name}}</option>
            </select>
        </div>
        <div class="group">
            <label for="labLimit">Lab Limit</label>
            <input type="number" formControlName="labLimit" id="labLimit" class="form-control" placeholder="Unlimited"
                (change)="replaceLimit('labLimit')" />
            <div *ngIf="enterpriseForm.controls.labLimit.invalid && (enterpriseForm.controls.labLimit.dirty || enterpriseForm.controls.labLimit.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.labLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="deviceLimit">Device Limit</label>
            <input type="number" formControlName="deviceLimit" id="deviceLimit" class="form-control"
                placeholder="Unlimited" (change)="replaceLimit('deviceLimit')" />
            <div *ngIf="enterpriseForm.controls.deviceLimit.invalid && (enterpriseForm.controls.deviceLimit.dirty || enterpriseForm.controls.deviceLimit.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.deviceLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="userLimit">User Limit</label>
            <input type="number" formControlName="userLimit" id="userLimit" class="form-control" placeholder="Unlimited"
                (change)="replaceLimit('userLimit')" />
            <div *ngIf="enterpriseForm.controls.userLimit.invalid && (enterpriseForm.controls.userLimit.dirty || enterpriseForm.controls.userLimit.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.userLimit.errors.min">Must not be less than 0</div>
            </div>
        </div>
        <div class="group">
            <label for="logsDeletePeriod">Logs Period Limit</label>
            <input type="number" formControlName="logsDeletePeriod" id="logsDeletePeriod" class="form-control"
                placeholder="Unlimited" (change)="replaceLimit('logsDeletePeriod')" />
            <div *ngIf="enterpriseForm.controls.logsDeletePeriod.invalid && (enterpriseForm.controls.logsDeletePeriod.dirty || enterpriseForm.controls.logsDeletePeriod.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.logsDeletePeriod.errors.min">Must not be less than 0</div>
            </div>
        </div>
    </section>
    <section class="right-part">
        <div class="group">
            <label for="subscription">Select Subscription <span>*</span></label>
            <select class="form-control" id="subscription" formControlName="subscriptionId" required>
                <option value="">Select Subscription</option>
                <option *ngFor="let option of subscriptionOptions" [value]="option.id">{{option.name}}</option>
            </select>
            <div *ngIf="enterpriseForm.controls.subscriptionId.invalid && (enterpriseForm.controls.subscriptionId.dirty || enterpriseForm.controls.subscriptionId.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.subscriptionId.errors.required">*Subscription is required.</div>
            </div>
        </div>
        <div class="group">
            <label for="subscriptionStart">Select Subscription Start Date <span>*</span></label>
            <input readonly id="subscriptionStart" placeholder="Select Subscription Start Date" class="form-control"
                formControlName="subscriptionStart" [matDatepicker]="subscriptionStartPicker" [min]="minStartDate"
                (focus)="subscriptionStartPicker.open()" (dateChange)="updateExpirationMin()"/>
            <div class="datepicker-toggle">
                <mat-datepicker-toggle [for]="subscriptionStartPicker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #subscriptionStartPicker></mat-datepicker>
            <div *ngIf="enterpriseForm.controls.subscriptionStart.invalid && (enterpriseForm.controls.subscriptionStart.dirty || enterpriseForm.controls.subscriptionStart.touched)"
                class="error">
                <div *ngIf="enterpriseForm.controls.subscriptionStart.errors.required">*Subscription Start Date is required.
                </div>
            </div>
        </div>
        <div class="group">
            <label for="subscriptionEnd">Select Subscription End Date</label>
            <input readonly id="subscriptionEnd" placeholder="Unlimited" class="form-control"
                formControlName="subscriptionEnd" [matDatepicker]="subscriptionPicker" [min]="minEndDate"
                (focus)="subscriptionPicker.open()" />
            <div class="datepicker-toggle">
                <mat-datepicker-toggle [for]="subscriptionPicker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #subscriptionPicker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel (click)="resetExpiration()">Clear</button>
                    <button mat-button matDatepickerApply>Apply</button>
                </mat-datepicker-actions>
            </mat-datepicker>
        </div>
        <div class="group">
            <label for="status">Enterprise Image</label>
            <div class="dropzone">
                <app-form-dropzone [maxSize]="3145728" [accept]="'image/jpeg,image/png'"
                    (fileSelected)="onFileChanged($event)" [description]="'JPG, PNG up to 3 MB'" [preview]="logoUrl"
                    [previewMode]="'scale-down'">
                </app-form-dropzone>
            </div>
        </div>
        <div class="form-actions">
            <button class="snap-button secondary" type="button" routerLink="/admin/enterprises">Cancel</button>
            <button class="snap-button primary" type="submit" [disabled]="!enterpriseForm.valid">Update</button>
        </div>
    </section>
</form>
