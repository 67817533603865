<div class="back-button" *ngIf="!isSelectDeviceMode && isTestRunning" (click)="navigateBack('/snap/record-and-play')">
    <img alt="" src="../../../../assets/images/arrow_back.svg">
    <span class="mo-mt-mode">Back</span>
</div>
<section class="new-test" [ngClass]="{ongoing_test_container: isTestRunning}">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="left-side-section" *ngIf="!isSelectDeviceMode && isRnpAvailable">
        <div class="ongoing-banner" *ngIf="isTestRunning">
            Record&Play test is in progress.
            <span class="stop-button" (click)="cancelTest()">
                    Stop the test.
                </span>
        </div>
        <app-screencast [deviceInfo]="deviceDetail"
                        [isTestRunning]="isTestRunning"
                        [isTestRecording]="isTestRecording"
                        [replayInProgress]="replayInProgress"
                        [actionToReplay]="actionToReplay"
                        [ignoreTyping]="ignoreTyping"
                        (selectDeviceUpdate)="updateDeviceSelect($event)"
                        (latestAction)="addLatestAction($event)"
                        (actionResult)="replayActionResult($event)"
        ></app-screencast>
    </div>

    <div class="container_new_test" *ngIf="isRnpAvailable && !isSelectDeviceMode"
         [ngClass]="{ongoing_test: isTestRunning}">
        <div class="sub_container right_container" *ngIf="!isTestRunning && !isTestRecording">
            <div class="new_test_title">Configuration</div>
            <div class="text_new_test">
                <label for="test-name"><span class="required-field">*</span>Test Name</label>
                <input type="text" id="test-name" [(ngModel)]="testName" maxlength="100" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                       class="form-control input_new_test" (input)="onNameInput()" [disabled]="!!testId" [ngClass]="{'error': (!testName.trim() && !isNamePristine) || !checkForUniqueName(testName)}"/>
                <div *ngIf="!checkForUniqueName(testName)" class="input-error">Test with this name already exists</div>
                <div *ngIf="!testName.trim() && !isNamePristine" class="input-error">Test name can't be empty</div>
            </div>
            <div class="text_new_test">
                <label for="test-description">Description</label>
                <input type="text" id="test-description" [(ngModel)]="testDescription" maxlength="100" [disabled]="!!testId"
                       class="form-control input_new_test input_description" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"/>
            </div>
            <div class="text_new_test" *ngIf="testId">
                <label for="test-iterations">Iterations</label>
                <input id="test-iterations" type="number" min="1" max="500" [(ngModel)]="testRepeatCount"
                       class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"/>
            </div>
            <div class="text_new_test" *ngIf="testId">
                <label for="test-delay">Delay</label>
                <label class="time-unit" data-unit="sec">
                    <input id="test-delay" type="number" min="1" max="3600" [(ngModel)]="iterationDelay"
                           class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"/>
                </label>
            </div>
            <div class="logs-checkbox add-home" *ngIf="!testId">
                <mat-checkbox color="primary" [checked]="shouldAddHome"
                              (change)="checkAddHome($event.checked)">
                    Add HOME BUTTON at the end of test
                </mat-checkbox>
            </div>
            <div class="text_new_test" *ngIf="testId">Artifacts</div>
            <div [tp]="tooltipComponent" class="logs-checkbox" *ngIf="testId" tpPlacement="left" [tpIsEnabled]="!isCloudAvailable"
                 [tpData]="isCloudAvailable ? '' : 'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
                <mat-checkbox color="primary" [checked]="shouldSaveLogs"
                              (change)="checkLogs($event.checked)" [disabled]="!isCloudAvailable">
                    Save device logs
                </mat-checkbox>
            </div>
            <div [tp]="tooltipComponent" class="logs-checkbox" *ngIf="testId" tpPlacement="left" [tpIsEnabled]="!isCloudAvailable"
                 [tpData]="isCloudAvailable ? '' : 'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
                <mat-checkbox color="primary" [checked]="shouldSaveVideo"
                              (change)="checkVideo($event.checked)" [disabled]="!isCloudAvailable">
                    Save video
                </mat-checkbox>
            </div>
            <div [tp]="tooltipComponent" class="logs-checkbox" *ngIf="testId" tpPlacement="left" [tpIsEnabled]="!isCloudAvailable"
                 [tpData]="isCloudAvailable ? '' : 'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
                <mat-checkbox color="primary" [checked]="shouldSaveUserActions"
                              (change)="checkUserActions($event.checked)" [disabled]="!isCloudAvailable">
                    Save user actions
                </mat-checkbox>
            </div>
            <div [tp]="tooltipComponent" class="logs-checkbox" *ngIf="testId" tpPlacement="left" [tpIsEnabled]="!isCloudAvailable"
                 [tpData]="isCloudAvailable ? '' : 'Unavailable. Your subscription plan must include Cloud feature.' | tooltipData">
                <mat-checkbox color="primary" [checked]="shouldSaveKPIs"
                              (change)="checkKPIs($event.checked)" [disabled]="!isCloudAvailable">
                    Save device KPIs
                </mat-checkbox>
            </div>
            <div class="buttons_new_test">
                <button class="btn momt_btn" (click)="openConfirmCancel()">Cancel</button>
                <button class="btn momt_btn momt_btn_start loginBtn" *ngIf="!testId?.length" (click)="startRecording()"
                        [disabled]="!checkForUniqueName(testName) || !testName.trim() || !selectedRnpDevice || isLoading">
                    Start recording
                </button>
                <button class="btn momt_btn momt_btn_start loginBtn" *ngIf="testId?.length"
                        [disabled]="!testName || !selectedRnpDevice || isLoading"
                        (click)="startReplay()">
                    Start replay
                </button>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="isTestRecording">
            <span class="play-record-controls">
                <span class="test-name-rec">{{testName}}</span>
                <span class="recording" [ngClass]="{'blinking': recordInProgress}" (click)="controlRecording()"
                      [tp]="tooltipComponent" tpPlacement="bottom" [tpData]="'Stop recording' | tooltipData">
                    <span class="rec-round"></span>
                    <span>Rec</span>
                </span>
                <span class="primary" (click)="runSimpleReplay()" [tp]="tooltipComponent" tpPlacement="bottom" [tpData]="(replayInProgress ? 'Stop replay' : 'Start replay') | tooltipData"
                      [ngClass]="{'disabled': recordInProgress}">
                    <img *ngIf="replayInProgress" alt="" src="../../../../assets/images/stop-new.svg"/>
                    <img *ngIf="!replayInProgress" alt="" src="../../../../assets/images/play-arrow-new.svg"/>
                </span>
            </span>
            <div class="description-wrapper">{{testDescription}}</div>
            <div class="steps-wrapper">
                <table class="steps-list">
                    <tbody>
                    <tr>
                        <td class="action-icon">
                            <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName('start', !recordInProgress && (replayInProgress || actionResultList.indexOf('') < 0 || runFailed || (!replayInProgress && actionResultList.indexOf('') > 0)) ? 'Pass' : '')}}.svg"
                                 alt="">
                        </td>
                        <td>00:00:000</td>
                        <td>START</td>
                        <td colspan="2"></td>
                    </tr>
                    <tr *ngFor="let action of actionList; index as i">
                        <td class="action-icon">
                            <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName(action.type, actionResultList[i])}}.svg" alt="">
                        </td>
                        <td class="timestamp-col">{{calcHoursToDisplay(action.timestamp)}}{{ action.timestamp | date:'mm:ss:SSS'}}</td>
                        <td class="type-col">{{getActionName(action.type)}}</td>
                        <td>{{displayDetails(action)}}</td>
                        <td class="pending-spinner" *ngIf="calcSpinnerShowtime(i - 1) && !recordInProgress">
                            <mat-spinner color="accent" diameter="24"></mat-spinner>
                        </td>
                    </tr>
                    <tr *ngIf="recordInProgress" class="recording-row">
                        <td colspan="5">Recording...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="buttons_new_test">
                <button class="btn momt_btn" (click)="openConfirmCancelRecording()">Cancel</button>
                <button class="btn momt_btn momt_btn_start loginBtn"
                        [disabled]="recordInProgress || !actionList.length || !selectedRnpDevice || isLoading || !testName"
                        (click)="saveTest()">
                    Save
                </button>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="isTestRunning && !isTestRecording">
            <h3 class="test-name">{{testInfo.name}}</h3>
            <span class="test-description" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                  [tpData]="testInfo.description | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false"
                  *ngIf="testInfo.description">
                Description - {{testInfo.description}}
            </span>
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let iteration of iterationsList; index as i" class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <span class="iteration-num">Iteration {{i + 1}}</span>
                        <span class="pending-spinner"
                              *ngIf="iterationsState[i] === 'NotExecuted' && iterationsState[i - 1] !== 'NotExecuted'">
                    <mat-spinner color="accent" diameter="24"></mat-spinner>
                </span>
                        <span class="iteration-success-label" *ngIf="iterationsState[i] === 'Pass'">PASSED</span>
                        <span class="iteration-failed-label" *ngIf="iterationsState[i] === 'Failed'">FAILED</span>
                    </mat-expansion-panel-header>
                    <table class="steps-list iterations-table">
                        <tbody>
                        <tr [ngClass]="{'not-started': !isIterationStarted(i)}">
                            <td class="action-icon">
                                <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName('start', isIterationStarted(i) && !showSpinnerStart(i) ? 'Pass' : '')}}.svg"
                                     alt="">
                            </td>
                            <td>00:00:000</td>
                            <td colspan="2">START</td>
                            <td class="pending-spinner" *ngIf="isIterationStarted(i) && showSpinnerStart(i)">
                                <mat-spinner color="accent" diameter="24"></mat-spinner>
                            </td>
                        </tr>
                        <tr *ngFor="let action of iteration; index as y"
                            [ngClass]="{'not-started': (!action.status.length || action.status === 'NotExecuted') && !calcSpinnerShowtimeIterations(y - 1, i)}">
                            <td class="action-icon">
                                <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName(action.type, action.status)}}.svg" alt="">
                            </td>
                            <td class="timestamp-col">{{calcHoursToDisplay(action.timestamp)}}{{action.timestamp | date:'mm:ss:SSS'}}</td>
                            <td class="type-col">{{getActionName(action.type)}}</td>
                            <td>{{displayDetails(action)}}</td>
                            <td class="pending-spinner" *ngIf="calcSpinnerShowtimeIterations(y - 1, i)">
                                <mat-spinner color="accent" diameter="24"></mat-spinner>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="no-access" *ngIf="!isRnpAvailable">
        <p>
            Record and Play is not available with your current subscription plan. <br />
            To get access to Record and Play, you must upgrade your account to a plan that includes Telephony
            tests feature.
        </p>
    </div>
</section>

<section *ngIf="isSelectDeviceMode" class="section-main">
    <div class="page_name" (click)="changeSelectDeviceMode()">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        <span class="mo-mt-mode">Select device</span>
    </div>
    <div>
        <div class="hd-cd align-items-center">
            <span class="ml-3 mt-2">
                <span class="apple-box" (click)="tabFilterAll('AllDeviceIos')"
                      [ngClass]="{'active': AllDeviceIos == true}">
                    <img class="appl" src="../../../../assets/images/NewUI/apple-logo.png" alt="">
                    <p class="cursor_point">{{availableIos}}</p>
                </span>
                <span class="apple-box ml-2" (click)="tabFilterAll('AllDeviceAnd')"
                      [ngClass]="{'active': AllDeviceAnd == true}">
                    <img class="appl" src="../../../../assets/images/NewUI/android-logo.png" alt="">
                    <p class="cursor_point">{{availableAndroid}}</p>
                </span>
            </span>
            <span class="search ml-3">
                <input type="text" class="searchTermAll form-control" (input)="SearchDeviceLocal()" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                       (keydown.enter)="SearchDeviceLocal();" [(ngModel)]="searchText" placeholder="Search ">
                <span class="search_icon"><img class="input_search"
                                               src="../../../../assets/images/NewUI/search_input.svg" alt=""></span>
            </span>
            <div class="mrgnz-mob labs">
                <select name="Labs" class="form-control select_field_ field_height" (change)="SearchDeviceLocal()"
                        [(ngModel)]="searchLab">
                    <option value="">All Labs</option>
                    <option [value]="item" *ngFor="let item of labListDropdown ">{{item}}</option>
                </select>
            </div>
            <div class="mrgnz-mob location">
                <select name="Location" class="form-control select_field_ field_height" (change)="SearchDeviceLocal()"
                        [(ngModel)]="searchLocation">
                    <option value="">All Locations</option>
                    <option [value]="item" *ngFor="let item of locationListDropdown ">{{item}}</option>
                </select>
            </div>
            <div class="toggle_view ml-2 d-flex">
                <span class="grid" [ngClass]="{'gradient': isGridView}">
                    <img src="../../../../assets/images/NewUI/grid_white.svg" alt="" class=""
                         *ngIf="isGridView == true">
                    <img src="../../../../assets/images/NewUI/grid-black.svg" (click)="toggleView()" alt=""
                         class="cursor_point" *ngIf="isGridView == false">
                </span>
                <span class="list" [ngClass]="{'gradient-rotate': !isGridView}">
                    <img src="../../../../assets/images/NewUI/list_white.svg" alt="" class=""
                         *ngIf="isGridView == false">
                    <img src="../../../../assets/images/NewUI/list_black.svg" (click)="toggleView()" alt=""
                         class="cursor_point" *ngIf="isGridView == true">
                </span>
            </div>
        </div>
        <div class="container-fluid p-0">
            <div class="row filters-wrapper">
                <div class="booked-filter">
                    <span (click)="filterByBooked(false)" class="ml-3"
                          [ngClass]="{'activeFilter': this.selectedBookedFilter === 'available'}">
                        <span class="_box_filter remove_bg mr-2 available-circle"></span>
                        <span style="text-transform: capitalize !important;">Not booked</span>
                        ({{bookingFilterList['false']?.length || 0}})
                    </span>
                    <span (click)="filterByBooked(true)" class="ml-3"
                          [ngClass]="{'activeFilter': this.selectedBookedFilter === 'booked'}">
                        <span class="_box_filter remove_bg mr-2 booked-circle"></span>
                        <span style="text-transform: capitalize !important;">Booked</span>
                        ({{bookingFilterList['true']?.length || 0}})
                    </span>
                </div>
                <div class="filter">
                    <span (click)="applyFilter(item.key)" class="ml-3"
                          [ngClass]="{'activeFilter':this.deviceState  == item.key}"
                          *ngFor="let item of filterList | keyvalue">
                        <span class="_box_filter remove_bg mr-2 {{item.key.toLowerCase()}}-bg"></span>
                        <span style="text-transform: capitalize !important;">{{item.key | titlecase}}</span>
                        ({{item.value.length}})
                    </span>
                </div>
                <div class="filter-help" (click)="toggleSidepanelAction()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
                        <path
                                d="M481.12 785.231q13.265 0 22.38-9.159 9.115-9.159 9.115-22.423 0-13.264-9.158-22.38-9.159-9.115-22.423-9.115-13.265 0-22.38 9.159-9.116 9.158-9.116 22.423 0 13.264 9.159 22.38 9.159 9.115 22.423 9.115Zm-20.659-132.462h38.616q1.538-26.077 9.808-42.384 8.269-16.308 34.038-41.616 26.769-26.769 39.846-47.961 13.077-21.193 13.077-49.059 0-47.288-33.229-75.365-33.23-28.077-78.617-28.077-43.154 0-73.269 23.462-30.116 23.462-44.116 53.923l36.77 15.231q9.615-21.846 27.5-38.615 17.884-16.77 51.577-16.77 38.923 0 56.846 21.347 17.923 21.346 17.923 46.961 0 20.769-11.231 37.115-11.231 16.347-29.231 32.885-34.769 32.077-45.538 54.385-10.77 22.307-10.77 54.538ZM480.134 936q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120 650.806 120 576.134q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194 216 479.866 216q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840 501.194 840 575.866q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806 936 480.134 936ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                    </svg>
                </div>
            </div>
        </div>
        <div class="container-fluid mb-4 all-devices"
             [ngClass]="{'without-booked': !currentDeviceList.length,'with-booked': currentDeviceList.length}"
             *ngIf="isGridView">
            <div class="no_device" *ngIf="deviceList.length == 0">
                <img src="../../../../assets/images/empty_folder.svg" alt="" />
                <span>No device available</span>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-1 main-device-wrapper"
                     (click)="selectDevice(item)" *ngFor="let item of deviceList;index as i">
                    <div class="status-strip {{getDeviceStatus(item) | lowercase}}-bg"></div>
                    <div class=" m-0 p-0" *ngIf="item.isResetting == true">
                        <img class="close_icon index_" *ngIf="item.resetCount  ==5 " (click)="closeReset(i,'ALL')"
                             src="../../../../assets/images/NewUI/ic_close.svg" alt="">
                        <span class="reset_text" *ngIf="item.resetCount !==5 ">Resetting ...</span>
                        <span class="reset_text1" *ngIf="item.resetCount  ==5 ">Device is still not online. <br>You can
                            try again after few minutes</span>
                    </div>
                    <div class="row m-0 device-card wraper_box"
                         [ngClass]="{'resetting_box':item.isResetting, selected_device: selectedDevice?._id === item._id}">
                        <div class="device-info">
                            <div class="device-img"
                                 [ngClass]="{'disabled': showOfflineTooltip(item) && item?.deviceState !== 'Online'}">
                                <img *ngIf="item?.oem == 'Apple'" src="../../../../assets/images/NewUI/ip12.jpeg"
                                     alt="">
                                <img *ngIf="item?.oem !== 'Apple'" src="../../../../assets/images/NewUI/android.png"
                                     alt="">
                            </div>
                            <div class="device-info-details">
                                <div class="info-header">
                                    <div class="device-name">
                                        <h4>{{item?.modelName}}</h4>
                                        <p class="ios" *ngIf="item?.oem == 'Apple'">iOS {{item?.osVersion}}</p>
                                        <p class="ios" *ngIf="item?.oem !== 'Apple'">Android {{item?.osVersion}}</p>
                                    </div>
                                    <span class="device-tooltip" *ngIf="checkSelfBooked(item)" [tp]="tooltipComponent"
                                          tpPlacement="bottom" [tpData]="'Booked by me' | tooltipData" [tpMaxWidth]="450"
                                          [tpInteractive]="false">
                                        <img class="device-icon" src="../../../../assets/images/NewUI/self-booked.svg"
                                             alt="">
                                    </span>
                                    <span class="tooltip7 device-tooltip"
                                          [ngClass]="{'wider-tt': showOfflineTooltip(item) && item?.deviceState === 'Offline', 'left-aligned': showOfflineTooltip(item) && item?.deviceState === 'Offline'}"
                                          [tp]="tooltipComponent" tpPlacement="bottom"
                                          [tpData]="getStatusTooltip(item) | tooltipData" [tpMaxWidth]="450"
                                          [tpInteractive]="false">
                                        <span class="status-icon {{getDeviceStatus(item) | lowercase}}-bg">
                                            {{showOfflineTooltip(item)
                                        && item?.deviceState === 'Offline' ? '!' : ''}}
                                        </span>
                                    </span>
                                </div>
                                <div class="device-details">
                                    <p class="imei">
                                        <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top"
                                              [tpOnlyTextOverflow]="true" [tpData]="item?.serialNumber | tooltipData"
                                              [tpMaxWidth]="450" [tpInteractive]="false">
                                            {{item?.oem == 'Apple' ? 'UUID': 'Serial No'}}: {{item?.serialNumber}}
                                        </span>
                                    </p>
                                    <div class="location-reserved-upto">
                                        <span class="loc for-all">
                                            <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top"
                                                  [tpOnlyTextOverflow]="true" [tpData]="item?.labLocation | tooltipData"
                                                  [tpMaxWidth]="450" [tpInteractive]="false">
                                                {{item?.labLocation}}
                                            </span>
                                        </span>
                                        <span class="res-time for-all" *ngIf="item?.bookedUntilESTDate">
                                            <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top"
                                                  [tpOnlyTextOverflow]="true"
                                                  [tpData]="item?.bookedUntilESTDate | tooltipData" [tpMaxWidth]="450"
                                                  [tpInteractive]="false">
                                                <img src="../../../../assets/images/booked_upto.svg" alt="">
                                                {{item?.bookedUntilESTDate}}
                                            </span>
                                        </span>
                                        <span class="res-time for-all" *ngIf="item.bookedByUserName">
                                            <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top"
                                                  [tpOnlyTextOverflow]="true"
                                                  [tpData]="item?.bookedByUserName | tooltipData" [tpMaxWidth]="450"
                                                  [tpInteractive]="false">
                                                <img src="../../../../assets/images/booked_by.svg" alt="">
                                                {{item?.bookedByUserName}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid" *ngIf="!isGridView">
            <div class="device_table" *ngIf="deviceList.length > 0"
                 [ngClass]="{'without-booked': !currentDeviceList.length,'with-booked': currentDeviceList.length}">
                <table class="table text-left">
                    <thead class="tableheading inner_table_head">
                    <tr>
                        <th scope="col" class="_noWrap">STATUS</th>
                        <th scope="col" class="_noWrap">OEM</th>
                        <th scope="col" class="_noWrap">MODEL</th>
                        <th scope="col" class="_noWrap">IMEI</th>
                        <th scope="col" class="_noWrap">UUID/SERIAL NUMBER</th>
                        <th scope="col" class="_noWrap">OS VERSION</th>
                        <th scope="col" class="_noWrap">LOCATION</th>
                        <th scope="col" class="_noWrap">BOOKED UPTO</th>
                        <th scope="col" class="_noWrap">BOOKED BY</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of deviceList; index as i"
                        [ngClass]="{'resetting_box_table':item.isResetting, 'selected_device-table': item?._id === selectedDevice?._id}"
                        class="position-relative device-row" (click)="selectDevice(item)">
                        <td class="_noWrap status-wrapper-list-view">
                                <span class="tooltip8 device-tooltip list-view"
                                      [ngClass]="{'wider-tt': showOfflineTooltip(item) && item?.deviceState === 'Offline', 'left-aligned': showOfflineTooltip(item) && item?.deviceState === 'Offline'}"
                                      [tp]="tooltipComponent" tpPlacement="bottom"
                                      [tpData]="getStatusTooltip(item) | tooltipData" [tpMaxWidth]="450"
                                      [tpInteractive]="false">
                                    <span class="status-icon {{getDeviceStatus(item) | lowercase}}-bg">
                                        {{showOfflineTooltip(item)
                                    && item?.deviceState === 'Offline' ? '!' : ''}}
                                    </span>
                                </span>
                            <span class="online_txt">{{getStatusTooltip(item) | uppercase}}</span>
                        </td>
                        <td class="_noWrap text_capital" style="text-transform: uppercase;">
                                <span class="table_box" *ngIf="item.isResetting == true">
                                    <img class="close_icon_table" *ngIf="item.resetCount  ==5 "
                                         (click)="closeReset(i,'ALL')" src="../../../../assets/images/NewUI/ic_close.svg"
                                         alt="">
                                    <span class="reset_text_table" *ngIf="item.resetCount !==5 ">Resetting ...</span>
                                    <span class="reset_text_table1" *ngIf="item.resetCount  ==5 ">Device is still not
                                        online. <br>You can try again after few minutes</span>
                                </span>
                            {{item?.oem}}
                        </td>
                        <td class="_noWrap">{{item?.modelName}}</td>
                        <td class="_noWrap">{{item?.imei}}</td>
                        <td class="_noWrap">{{ item?.serialNumber }}</td>
                        <td class="_noWrap">{{ item?.osVersion }}</td>
                        <td class="_noWrap">{{item?.labLocation}}</td>
                        <td class="_noWrap"> {{item.bookedUntilESTDate? item.bookedUntilESTDate:''}} </td>
                        <td class="_noWrap"> {{item?.bookedByUserName}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="no_device" *ngIf="deviceList.length == 0">
                <img src="../../../../assets/images/empty_folder.svg" alt="" />
                <span>No device available</span>
            </div>
        </div>
    </div>
    <footer>
        <div class="dialog-divider"></div>

        <div class="dialog-actions">
            <div class="dialog-devices_prop" *ngIf="!checkIfDeviceUnavailable()">
                <span [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                      [tpData]="selectedDevice?.modelName | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                    {{selectedDevice?.modelName}}
                </span>
                <span [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                      [tpData]="('UUID/SERIAL NUMBER - ' + selectedDevice?.serialNumber) | tooltipData" [tpMaxWidth]="450"
                      [tpInteractive]="false">
                    UUID/SERIAL NUMBER - {{selectedDevice?.serialNumber}}
                </span>
                <span [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                      [tpData]="('OS VERSION - ' + selectedDevice?.osVersion) | tooltipData" [tpMaxWidth]="450"
                      [tpInteractive]="false">
                    OS VERSION - {{selectedDevice?.osVersion}}
                </span>
                <span [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                      [tpData]="('LOCATION - ' + selectedDevice?.location?.city) | tooltipData" [tpMaxWidth]="450"
                      [tpInteractive]="false">
                    LOCATION - {{selectedDevice?.location?.city}}
                </span>
            </div>
            <div class="device-unavailable-warning" *ngIf="checkIfDeviceUnavailable()">
                <span class="status-icon dialog-unavailable-device-icon">{{showOfflineTooltip(selectedDevice)
                && selectedDevice?.deviceState === 'Offline' ? '!' : ''}}</span>
                <span class="dialog-unavailable-device">Selected device is unavailable for a running a test. Please
                    select another device.</span>
            </div>
            <div class="dialog-actions-buttons">
                <button class="btn cancel-btn" type="button" (click)="cancelSelectMode()">Cancel</button>
                <button class="btn control_btn_new" type="submit" (click)="selectDevice1()"
                        [disabled]="checkIfDeviceUnavailable()">Select</button>
            </div>
        </div>
    </footer>
    <mat-drawer #drawer mode="over" position="end">
        <app-howto-sidepanel (toggleSidepanel)="toggleSidepanelAction()"></app-howto-sidepanel>
    </mat-drawer>
</section>
