import {NgModule} from '@angular/core';
import {ManualTestComponent} from './manual-test.component';
import {NewManualTestFormComponent} from './new-manual-test-form/new-manual-test-form.component';
import {ManualTestService} from './manual-test.service';
import {SharedModule} from '../../shared/shared.module';
import {ManualTestListComponent} from './manual-test-list/manual-test-list.component';
import {MaterialModule} from '../../shared/material-ui.module';
import {ArtifactListComponent} from './artifact-list/artifact-list.component';
import {FinalizeManualTestComponent} from './finalize-manual-test/finalize-manual-test.component';
import {TippyDirective} from '@ngneat/helipopper';
import {TooltipService} from '../../shared/tooltip.service';
import {ManualTestViewComponent} from './manual-test-view/manual-test-view.component';
import {Tabs} from './tabs/tabs.component';
import {RouterLinkWithHref} from '@angular/router';
import {ManualTestRecordSidepanelComponent} from './manual-test-record-sidepanel/manual-test-record-sidepanel.component';

@NgModule({
    declarations: [
        ManualTestComponent,
        NewManualTestFormComponent,
        ManualTestListComponent,
        ArtifactListComponent,
        FinalizeManualTestComponent,
        ManualTestViewComponent,
        Tabs,
        ManualTestRecordSidepanelComponent
    ],
    imports: [
        SharedModule,
        MaterialModule,
        TippyDirective,
        RouterLinkWithHref,
    ],
    providers: [ManualTestService, TooltipService],
    exports: [ManualTestComponent, ManualTestViewComponent],
})
export class ManualTestModule { }
