import { NgModule } from "@angular/core";
import { MomtMainViewComponent } from "./momt-main-view/momt-main-view.component";
import { MomtNewTestComponent } from "./momt-new-test/momt-new-test.component";
import { MomtTableViewComponent } from "./momt-table-view/momt-table-view.component";
import { MomtTestReportComponent } from "./momt-test-report/momt-test-report.component";
import { SharedModule } from "../../shared/shared.module";
import { MomtCallService } from "./momt-call.service";
import { MaterialModule } from "../../shared/material-ui.module";
import { IterationAccordionComponent } from "./components/iteration-accordion/iteration-accordion.component";
import { TippyDirective } from "@ngneat/helipopper";
import { TelephonyTestSidepanelComponent } from './components/telephony-test-sidepanel/telephony-test-sidepanel.component';
import { MultiDeviceSelectComponent } from "./components/multi-screencast/multi-device-select.component";
import { DeviceSelectionComponent } from "./components/device-selection/device-selection.component";
import { DeviceDropdownComponent } from "./components/device-dropdown/device-dropdown.component";
import { SpeedTestDataComponent } from './components/speed-test-data/speed-test-data.component';

@NgModule({
    declarations: [
        MomtMainViewComponent,
        MomtNewTestComponent,
        MomtTableViewComponent,
        MomtTestReportComponent,
        IterationAccordionComponent,
        TelephonyTestSidepanelComponent,
        MultiDeviceSelectComponent,
        DeviceSelectionComponent,
        DeviceDropdownComponent,
        SpeedTestDataComponent
    ],
    imports: [
        SharedModule,
        MaterialModule,
        TippyDirective
    ],
    providers: [MomtCallService],
    exports: [
        IterationAccordionComponent
    ]
})
export class MomtCallModule { }
