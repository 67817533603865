import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: 'app-form-dropzone',
    templateUrl: './form-dropzone.component.html',
    styleUrls: ['./form-dropzone.component.scss']
})

export class FormDropzoneComponent {
    @Input() maxSize: number;
    @Input() accept: string;
    @Input() description: string;
    @Input() preview: SafeResourceUrl | string;
    @Input() previewMode: string = "cover";

    hasError: boolean;
    file: File;
    error = '';

    @Output() fileSelected = new EventEmitter();

    constructor(private sanitizer: DomSanitizer) { }

    onDrop($event) {
        $event.preventDefault();
        $event.stopPropagation();

        const files = $event.dataTransfer.files;
        if (files.length > 0) {
            this.handleFile(files[0]);
        }
    }

    onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    onDragLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    handleFileUpload($event: Event) {
        const target = $event.target as HTMLInputElement;
        if (target.files.length > 0) {
            this.handleFile(target.files[0]);
        }
    }

    handleFile(file: File) {
        if (file.size >= this.maxSize) {
            return this.error = 'File exceeds size limit';
        }
        if (!this.accept.split(',').includes(file.type)) {
            return this.error = 'Wrong file type';
        }
        this.file = file;
        const url = URL.createObjectURL(file);
        this.preview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.fileSelected.emit(file);
    }

    clearFile() {
        this.file = null;
        this.preview = null;
        this.fileSelected.emit(null);
    }

    reset() {
        this.error = '';
    }
}