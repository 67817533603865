export class Constants {
  _data: any = "data";
  basic = "basic";
  bearer = "bearer";
  loading = "loading....";
  patterns = {
    name: /^[a-zA-Z]((?!.*[-' ]{2})[a-zA-Z '-]?)+$/,
    lastName: /^[a-zA-Z_\-]+$/,
    alphaNumaric: /^[a-zA-Z0-9]*$/,
    phone: /^[0-9]+$/,
    password: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
    email: /^[A-Za-z0-9]([A-Za-z0-9._-])+@[A-Za-z0-9_-]+([\.-]?\w+)*(\.\w{1,})+$/,
    emailPhone: /(^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$)|(^[0-9]+$)/,
    alphaNumericStartWithOnlyAlphabet: /^[a-zA-Z]([a-zA-Z0-9_]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpace: /^[a-zA-Z]([a-zA-Z0-9\s]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpaceAndUnderScore: /^[a-zA-Z]([a-zA-Z0-9_\s]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpaceAndUnderScoreAndOneSCharoneTime: /^[a-zA-Z]((?!.*[-_ ]{2})[a-zA-Z0-9-_\s]?)+$/,
    alphaNumericStartWithAlphaNumericWithSpace: /^[a-zA-Z0-9]([a-zA-Z0-9\s]?)+$/,
    startWithAlphaNumeric: /^[a-zA-Z0-9]((?!.*[-_@*#&^!%$]{2})[\s\S]?)+/,
    anyThing: /^[a-zA-Z0-9]([\s\S]?)+/,
    numberOnly: /^\d+$/,
    numberWithNegative: /^[\+\-]?\d+$/,
    numberDecimalWithNegative: /^[\+\-]?\d*\.?\d{1,2}$/,
    numberDecimalWithNegativeString: "^[\\+\\-]?\\d*\\.?\\d{1,endDecimal}$",
    realComplexQuaterNumber: /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/,
    alphaNumericOnly: /^[a-zA-Z0-9]*$/,
    remarksDescription: /^[a-zA-Z0-9]((?!.*[@!#\$\^%`~_&*()+=\-\[\]\\\';,\\/\{\}\|\":<>\?]{2})(?!.*[ ]{2})(?!.*[.]{2})[a-zA-Z0-9\s\r\n@!#\$\^%`~&_*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\?]?)+$/,
    startWithAlphaOnly: /^[a-zA-Z]([\s\S]?)+/,
    alphaNumericSpaceOnly: /^[a-zA-Z0-9 ]*$/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  };
}
