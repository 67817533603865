<div class="example-header">
<!--    <button mat-icon-button (click)="previousClicked('year')">-->
<!--        <mat-icon>keyboard_double_arrow_left</mat-icon>-->
<!--    </button>-->
    <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>arrow_left</mat-icon>
    </button>
    <span class="example-header-label">{{periodLabel}}</span>
    <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>arrow_right</mat-icon>
    </button>
<!--    <button mat-icon-button (click)="nextClicked('year')">-->
<!--        <mat-icon>keyboard_double_arrow_right</mat-icon>-->
<!--    </button>-->
</div>
