<app-spinner *ngIf="isLoading"></app-spinner>
<div class="side-icon-box" *ngIf="(!isTestRunning || isManualTest) && deviceInfo">
    <ul class="icon_ul pt-3" [ngClass]="{disabled_usb: !isUsbAttach || isAttachReq}">
        <li (click)="HomeCall()" tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="'Go to home page of the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M4 10L12 3L20 10L20 20H15V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10Z"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <li (click)="RecentCall()" *ngIf="deviceInfo?.oem !== 'Apple'" tpPlacement="right"
            [tp]="tooltipComponent" [tpData]="'Fetch the list of recent app used in the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="18" width="11" height="20" rx="2" transform="rotate(-90 2 18)" stroke-width="2" />
                <rect x="6" y="3" width="12" height="18" rx="2" fill="white" stroke-width="2" />
            </svg>
        </li>
        <li (click)="BackCall()" *ngIf="deviceInfo?.oem !== 'Apple'" tpPlacement="right"
            [tp]="tooltipComponent" [tpData]="'Go to previous screen of the device' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4L7 12L15 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <li (click)="landscapeCall()" tpPlacement="right" [tp]="tooltipComponent"
            [tpData]="'Change screen orientation' | tooltipData">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M18.2002 6.94416V4M18.2002 6.94416V6.99993L15.2002 7M19.9381 11C19.979 11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M6 17.2916V17H9M6 17.2916V20"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </li>
        <div tpPlacement="right" [tp]="tooltipComponent"
             [tpData]="(isManipulationAvailable ? 'Change device location' : 'Unavailable. Your subscription plan must include Device manipulation feature.')| tooltipData">
            <li (click)="openGeoModal(locationModal)" [ngClass]="{disabled_usb: !isManipulationAvailable }">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                            d="M12 21C16.4183 19 20 15.4183 20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11C4 15.4183 7.58172 19 12 21Z"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </li>
        </div>
        <div tpPlacement="right" [tp]="tooltipComponent"
             [tpData]="(isManipulationAvailable ? 'Device Controls' : 'Unavailable. Your subscription plan must include Device manipulation feature.')| tooltipData">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="button device control"
                    [ngClass]="{ disabled_usb: !isManipulationAvailable || isTestRecording}"
                    (menuOpened)="getDeviceControlsMode()" (menuClosed)="changeDeviceControlsPanelVisibility()"
                    class="button_device-control">
                <svg [ngClass]="{selected: dcMenuOpened}" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M11 3H13C13.5523 3 14 3.44772 14 4V4.56879C14 4.99659 14.2871 5.36825 14.6822 5.53228C15.0775 5.69638 15.5377 5.63384 15.8403 5.33123L16.2426 4.92891C16.6331 4.53838 17.2663 4.53838 17.6568 4.92891L19.071 6.34312C19.4616 6.73365 19.4615 7.36681 19.071 7.75734L18.6688 8.1596C18.3661 8.46223 18.3036 8.92247 18.4677 9.31774C18.6317 9.71287 19.0034 10 19.4313 10L20 10C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H19.4312C19.0034 14 18.6318 14.2871 18.4677 14.6822C18.3036 15.0775 18.3661 15.5377 18.6688 15.8403L19.071 16.2426C19.4616 16.6331 19.4616 17.2663 19.071 17.6568L17.6568 19.071C17.2663 19.4616 16.6331 19.4616 16.2426 19.071L15.8403 18.6688C15.5377 18.3661 15.0775 18.3036 14.6822 18.4677C14.2871 18.6318 14 19.0034 14 19.4312V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19.4313C10 19.0034 9.71287 18.6317 9.31774 18.4677C8.92247 18.3036 8.46223 18.3661 8.1596 18.6688L7.75732 19.071C7.36679 19.4616 6.73363 19.4616 6.34311 19.071L4.92889 17.6568C4.53837 17.2663 4.53837 16.6331 4.92889 16.2426L5.33123 15.8403C5.63384 15.5377 5.69638 15.0775 5.53228 14.6822C5.36825 14.2871 4.99659 14 4.56879 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10L4.56877 10C4.99658 10 5.36825 9.71288 5.53229 9.31776C5.6964 8.9225 5.63386 8.46229 5.33123 8.15966L4.92891 7.75734C4.53838 7.36681 4.53838 6.73365 4.92891 6.34313L6.34312 4.92891C6.73365 4.53839 7.36681 4.53839 7.75734 4.92891L8.15966 5.33123C8.46228 5.63386 8.9225 5.6964 9.31776 5.53229C9.71288 5.36825 10 4.99658 10 4.56876V4C10 3.44772 10.4477 3 11 3Z"
                            stroke-width="2" />
                    <path
                            d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
                            stroke-width="2" />
                </svg>
            </button>
        </div>
        <mat-menu #menu="matMenu" class="menu_buttons" hasBackdrop="true" backdropClass="cdk-overlay-dark-backdrop">

            <span class="mat-menu-text">Device Controls</span>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="WifiAPI($event)"
                    [disabled]="isWiFiLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img img-fluid p-2"
                         [ngClass]="{ img_grayscle: wifiStatus === 'OFF' || wifiStatus === 'NA', img_grayscale_loading: isWiFiLoading || wifiStatus === 'NA' }"
                         src="../../../assets/images/NewUI/Wifi_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">{{wifiBtntooltip}}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                       [ngClass]="{ mat_menu_label_color: wifiStatus === 'ON', controls_loading: isWiFiLoading || wifiStatus === 'NA' }">
                        Wi-Fi</p>
                    <p
                            [ngClass]="{ mat_menu_state_on: wifiStatus === 'ON', mat_menu_state_off: wifiStatus === 'OFF' || wifiStatus === 'NA', controls_loading: isWiFiLoading || wifiStatus === 'NA' }">
                        {{wifiStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="BluetoothAPI($event)"
                    [disabled]="isBluetoothLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img"
                         [ngClass]="{ img_grayscle: bluetoothStatus === 'OFF' || bluetoothStatus === 'NA', img_grayscale_loading: isBluetoothLoading || bluetoothStatus === 'NA' }"
                         src="../../../assets/images/NewUI/bluetooth_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">{{bluetoothBtntooltip}}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                       [ngClass]="{ mat_menu_label_color: bluetoothStatus === 'ON', controls_loading: isBluetoothLoading || bluetoothStatus === 'NA' }">
                        Bluetooth</p>
                    <p
                            [ngClass]="{ mat_menu_state_on: bluetoothStatus === 'ON', mat_menu_state_off: bluetoothStatus === 'OFF' || bluetoothStatus === 'NA', controls_loading: isBluetoothLoading || bluetoothStatus === 'NA' }">
                        {{bluetoothStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="MobileDataAPI($event)"
                    [disabled]="isMobileDataLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img"
                         [ngClass]="{ img_grayscle: mobileDataStatus === 'OFF' || mobileDataStatus === 'NA', img_grayscale_loading: isMobileDataLoading || mobileDataStatus === 'NA'}"
                         src="../../../assets/images/NewUI/mobile_data_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">{{mobileDataBtntooltip}}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                       [ngClass]="{ mat_menu_label_color: mobileDataStatus === 'ON', controls_loading: isMobileDataLoading || mobileDataStatus === 'NA'}">
                        Mobile data</p>
                    <p
                            [ngClass]="{ mat_menu_state_on: mobileDataStatus === 'ON', mat_menu_state_off: mobileDataStatus === 'OFF' || mobileDataStatus === 'NA', controls_loading: isMobileDataLoading || mobileDataStatus === 'NA'}">
                        {{mobileDataStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="AirPlaneModeSwitcher($event)"
                    [disabled]="isAirplaneLoading">
                <div class="menu_icon_fix tooltip3 airplane">
                    <img class="menu-icon_img"
                         [ngClass]="{ img_grayscle: airPlanModeStatus === 'OFF' || airPlanModeStatus === 'NA', img_grayscale_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }"
                         src="../../../assets/images/NewUI/airplan_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">{{ airplaneBtntooltip }}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                       [ngClass]="{ mat_menu_label_color: airPlanModeStatus === 'ON', controls_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }">
                        Airplane</p>
                    <p
                            [ngClass]="{ mat_menu_state_on: airPlanModeStatus === 'ON', mat_menu_state_off: airPlanModeStatus === 'OFF' || airPlanModeStatus === 'NA', controls_loading: isAirplaneLoading || airPlanModeStatus === 'NA' }">
                        {{airPlanModeStatus}}</p>
                </div>
            </button>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="VolumenAPI($event)"
                    [disabled]="isVolumeLoading">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img" *ngIf="volumeStatus"
                         [ngClass]="{ img_grayscle: volumeStatus === 'OFF' || volumeStatus === 'NA', img_grayscale_loading: isVolumeLoading || volumeStatus === 'NA' }"
                         src="../../../assets/images/NewUI/volume_loud_solo.svg" alt="" />
                    <img class="menu-icon_img" *ngIf="!volumeStatus"
                         [ngClass]="{ img_grayscle: !volumeStatus, img_grayscale_loading: isVolumeLoading || volumeStatus === 'NA' }"
                         src="../../../assets/images/NewUI/volume_mute_solo.svg" alt="" />
                </div>
                <span class="tooltiptext">{{ volumeBtntooltip }}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label"
                       [ngClass]="{ mat_menu_label_color: volumeStatus === 'ON', controls_loading: isVolumeLoading || volumeStatus === 'NA' }">
                        Volume</p>
                    <p
                            [ngClass]="{ mat_menu_state_on: volumeStatus === 'ON', mat_menu_state_off: volumeStatus === 'OFF' || volumeStatus === 'NA', controls_loading: isVolumeLoading || volumeStatus === 'NA' }">
                        {{volumeStatus}}</p>
                </div>
            </button>

            <hr />
            <span class="mat-menu-text">Experience Control</span>

            <button mat-menu-item class="mat-menu-button tooltipryt" (click)="startStopAudio($event)">
                <div class="menu_icon_fix tooltip3">
                    <img class="menu-icon_img" *ngIf="AudioStreamingStatus"
                         src="../../../assets/images/NewUI/audio_streaming.svg" alt="" />
                    <img class="menu-icon_img" *ngIf="!AudioStreamingStatus"
                         [ngClass]="{ img_grayscle: !AudioStreamingStatus }"
                         src="../../../assets/images/NewUI/audio_streaming.svg" alt="" />
                </div>
                <span class="tooltiptext">{{ audioStreamingBtntooltip }}</span>
                <div class="mat-menu-block">
                    <p class="mat-menu-label" [ngClass]="{ mat_menu_label_color: AudioStreamingStatus }">Audio
                        streaming
                    </p>
                    <p *ngIf="AudioStreamingStatus" class="mat_menu_state_on">ON</p>
                    <p *ngIf="!AudioStreamingStatus" class="mat_menu_state_off">OFF</p>
                </div>
            </button>
        </mat-menu>

        <hr />

        <div tpPlacement="right" [tp]="tooltipComponent" *ngIf="isManualTest"
             [tpData]="(isManipulationAvailable ? 'Screenshot' : 'Unavailable. Your subscription plan must include Device manipulation feature.')| tooltipData">
            <li (click)="CaptureImage()" [ngClass]="{ disabled_usb: !isManipulationAvailable }">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M21 17V9C21 7.89543 20.1046 7 19 7H16.5C15.9477 7 15.5 6.55228 15.5 6C15.5 5.44772 15.0523 5 14.5 5H9.5C8.94772 5 8.5 5.44772 8.5 6C8.5 6.55228 8.05228 7 7.5 7H5C3.89543 7 3 7.89543 3 9V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17Z"
                            stroke-width="2" />
                    <path
                            d="M15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13Z"
                            stroke-width="2" />
                </svg>
            </li>
        </div>
        <div tpPlacement="right" [tp]="tooltipComponent" *ngIf="isManualTest"
             [tpData]="(isManipulationAvailable ? 'Screenshot' : 'Unavailable. Your subscription plan must include Device manipulation feature.')| tooltipData">
            <li (click)="powerCall()" tpPlacement="right" [tp]="tooltipComponent"
                [tpData]="'Turn on/off the device screen' | tooltipData">
                <svg width="24" height="24" viewBox="1 1 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.34315 6.34277C5.22433 7.46159 4.4624 8.88705 4.15372 10.4389C3.84504 11.9907 4.00346 13.5993 4.60896 15.0611C5.21447 16.5229 6.23985 17.7723 7.55544 18.6514C8.87103 19.5304 10.4178 19.9996 12 19.9996C13.5823 19.9996 15.129 19.5304 16.4446 18.6514C17.7602 17.7723 18.7855 16.5229 19.391 15.0611C19.9965 13.5993 20.155 11.9907 19.8463 10.4389C19.5376 8.88705 18.7757 7.46159 17.6569 6.34277" stroke-width="2" stroke-linecap="round"/>
                    <path d="M12 8V4" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </li>
        </div>
        <div tpPlacement="right" [tp]="tooltipComponent" *ngIf="isManualTest"
             [tpData]="(isManipulationAvailable ? 'Reboot' : 'Unavailable. Your subscription plan must include Device manipulation feature.') | tooltipData">
            <li (click)="openRebootModal()" [ngClass]="{ disabled_usb: !isManipulationAvailable || manualTestService.mtSessionStatus === 0}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M8.69306 3.48183C6.94954 4.09425 5.45289 5.255 4.4286 6.78919C3.40431 8.32338 2.90788 10.1479 3.01409 11.9878C3.12031 13.8278 3.82343 15.5835 5.0175 16.9904C6.21158 18.3973 7.83192 19.3791 9.6344 19.7881C11.4369 20.197 13.3238 20.0109 15.011 19.2577C16.6981 18.5044 18.094 17.225 18.9883 15.612C19.8827 13.9991 20.227 12.1401 19.9695 10.3151C19.5346 7.23387 17.2944 5.19816 15.3158 3M15.3158 3L21 3M15.3158 3V8.66667"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </li>
        </div>
    </ul>
    <span tpPlacement="right" [tp]="tooltipComponent" [tpData]="getAttachTooltip() | tooltipData" *ngIf="isManualTest" class="detach-btn">
        <button mat-icon-button class="{{isUsbAttach? '_usb_attach' : '_usb_detach'}}"
                (click)="isUsbAttach ? usbDetachApi() : usbAttachApi()" [ngClass]="{disabled_usb: disableAttachDetach()}">
          <svg *ngIf="isUsbAttach" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="detach-coloring" stroke-width="0"
                  d="M21.7568 1L19.4595 3.2973L19.0811 2.89189C18.2403 2.05115 17.1356 1.62162 16.027 1.62162C14.9185 1.62162 13.8137 2.05115 12.973 2.89189L12.3514 3.48649L11.5676 4.27027L10.8919 3.59459L9.64865 4.83784L12.0541 7.24324L9.64865 9.64865L10.8919 10.8919L13.2973 8.48649L15.5135 10.7027L13.1081 13.1081L14.3514 14.3514L16.7568 11.9459L19.1622 14.3514L20.4054 13.1081L19.7297 12.4324L21.1081 11.027C22.7896 9.34554 22.7896 6.60041 21.1081 4.91892L20.7027 4.54054L23 2.24324L21.7568 1ZM16.027 3.35135C16.6909 3.35135 17.3547 3.59793 17.8649 4.10811L19.8919 6.13514C20.9122 7.15548 20.9122 8.79046 19.8919 9.81081L19.6757 10L18.4865 11.1892L12.8108 5.51351L14.1892 4.10811C14.6994 3.59793 15.3632 3.35135 16.027 3.35135ZM4.83784 9.64865L3.59459 10.8919L4.27027 11.5676L2.89189 12.973C1.2104 14.6545 1.2104 17.3996 2.89189 19.0811L3.27027 19.4865L1 21.7568L2.24324 23L4.51351 20.7297L4.91892 21.1081C6.60041 22.7896 9.34554 22.7896 11.027 21.1081L12.4324 19.7297L13.1081 20.4054L14.3514 19.1622L4.83784 9.64865ZM5.51351 12.8108L11.1892 18.4865L9.81081 19.8919C8.79046 20.9122 7.15548 20.9122 6.13514 19.8919L5.94595 19.6757L4.10811 17.8649C3.08776 16.8445 3.08776 15.2095 4.10811 14.1892L5.51351 12.8108Z" />
          </svg>
          <svg *ngIf="!isUsbAttach" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="revert-coloring" stroke-width="0"
                  d="M20.5933 2L17.9939 4.59939L17.5657 4.14067C16.6144 3.18937 15.3644 2.70336 14.1101 2.70336C12.8558 2.70336 11.6057 3.18937 10.6544 4.14067L9.95107 4.81346L9.06422 5.70031L8.29969 4.93578L6.89297 6.34251L17.6575 17.107L19.0642 15.7003L18.2997 14.9358L19.8593 13.3456C21.7619 11.443 21.7619 8.33686 19.8593 6.43425L19.4006 6.00612L22 3.40673L20.5933 2ZM14.1101 4.66055C14.8612 4.66055 15.6123 4.93956 16.1896 5.51682L18.4832 7.8104C19.6377 8.96492 19.6377 10.8149 18.4832 11.9694L16.893 13.5291L10.4709 7.10703L12.0306 5.51682C12.6078 4.93956 13.359 4.66055 14.1101 4.66055ZM6.34251 6.89297L4.93578 8.29969L5.70031 9.06422L4.81346 9.95107L4.14067 10.6544C2.23807 12.557 2.23807 15.6631 4.14067 17.5657L4.56881 18.0245L2 20.5933L3.40673 22L5.97554 19.4312L6.43425 19.8593C8.33686 21.7619 11.443 21.7619 13.3456 19.8593L14.9358 18.2997L15.7003 19.0642L17.107 17.6575L6.34251 6.89297ZM7.10703 10.4709L13.5291 16.893L11.9694 18.4832C10.8149 19.6377 8.96492 19.6377 7.8104 18.4832L5.51682 16.1896C4.3623 15.0351 4.3623 13.1851 5.51682 12.0306L7.10703 10.4709Z" />
          </svg>
        </button>
      </span>
</div>
<div class="device-wrapper" *ngIf="deviceInfo" [ngClass]="{running: isTestRunning}">
    <div class="notification-wrapper" *ngIf="manualTestService">
        <div *ngIf="manualTestService.mtSessionStatus === 0" class="notification ongoing-session-banner">
            <span>Manual test in progress.</span>
            <span class="notification-action" (click)="manualTestService.stopManualTestSession()">Stop the test</span>
        </div>
    </div>
    <div class="device-wrapper-ins" (mouseup)="swipeOutsideImg($event)" #imgWrapper
         [ngClass]="{'force-landscape-ins': isLandscapeMode && originalHeight > originalWidth}">
        <app-spinner [type]="'fullScreen'" *ngIf="isLoading"></app-spinner>
        <img class="border_" *ngIf="!isLandscapeMode && showImage" id="framePort" draggable="false"
             (load)="processImage($event)" (mousedown)="mouseDown($event)"
             (mouseup)="mouseUp($event)" (mousemove)="mousemove($event)" (wheel)="onMouseWheel($event)"
             [src]="imageContent" alt="" />
        <img class="border_" *ngIf="isLandscapeMode && showImage" id="frameLand" draggable="false"
             (load)="processImage($event)" (mousedown)="mouseDown($event)"
             (mouseup)="mouseUp($event)" (mousemove)="mousemove($event)" (wheel)="onMouseWheel($event)"
             [src]="imageContent" alt="" [ngClass]="{'force-landscape':isLandscapeMode && originalHeight > this.originalWidth}" />
        <img class="border_" *ngIf="!showImage" id="frameLoading" draggable="false"
             src="https://s3.us-east-2.amazonaws.com/snap-automation/Snap/1649137991083_loading.jpg"
             alt="" />
    </div>
    <div class="device-prop" *ngIf="!isTestRunning && !isTestRecording">
        <div>{{deviceInfo.modelName}}</div>
        <div class="device-os">{{deviceInfo.os}} {{deviceInfo.osVersion}}</div>
        <div class="device-os">{{deviceInfo.serialNumber}}</div>
        <div class="button-group">
            <button class="btn momt_btn" (click)="changeSelectDeviceMode()">Change</button>
            <button class="btn momt_btn momt_btn_start" (click)="releaseDevice()">Release</button>
        </div>
    </div>
</div>
<div class="device-not-selected" *ngIf="!deviceInfo">
    <div class="device">
        <div *ngIf="!isBookingAvailable" [tp]="tooltipComponent"
             [tpData]="'Unavailable. Your subscription plan must include Booking feature.' | tooltipData">
            <button class="btn loginBtn momt_btn select_device" disabled>
                Select device
            </button>
        </div>
        <button *ngIf="isBookingAvailable" class="btn loginBtn momt_btn select_device"
                (click)="changeSelectDeviceMode()">
            Select device
        </button>
    </div>
</div>

<ng-template #locationModal let-modal>
    <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
    <div class="modal-header">
        <h5 class="modal-title">Device Location</h5>
        <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal">x</span>
    </div>
    <div class="modal-body">
        <input class="form-control mb-5" (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
               id="search" type="text" placeholder="Type Location" [(ngModel)]="inputAddress" (input)="locationType($event)" />
        <google-map [center]="mapCenter" [options]="mapOptions" [width]="465"
                    (mapClick)="updateMarker($event)">
            <map-marker [position]="markerPosition" [options]="markerOptions" (mapDragend)="markerDragEnd($event)"></map-marker>
        </google-map>
        <p class="mt-2 mb-2" *ngIf="selectedLocation">
            <span class="bold">Selected Location:</span>
            <span>{{ selectedLocation }}</span>
        </p>
        <div class="row mt-2 justify-content-center">
            <button class="btn control_btn_new" [disabled]="isLocationChoose == false" (click)="geoLocationChange()">
                Update
            </button>
            <button class="btn control_btn_new" (click)="resetGeoLocation()">
                Reset
            </button>
        </div>
    </div>
</ng-template>
