import { Component, OnInit } from '@angular/core';
import { TokenData } from '../../../../shared/interfaces';
import { AdminService } from '../../admin.service';
import { CommonService } from '../../../../shared/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'view-invoice',
    templateUrl: './view-invoice.component.html',
    styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {
    invoiceInfo: any;
    userData: TokenData;
    isLoading = false;
    unlimited = 8640000000000;
    showError = false;
    features: string[];
    constructor(
        private adminService: AdminService,
        private commonService: CommonService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.getInvoice();
    }

    getInvoice() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            this.adminService.getInvoice(id).subscribe((res) => {
                this.isLoading = false;
                if (res.statusCode === 200) {
                    this.invoiceInfo = res.data;
                    this.features = this.invoiceInfo.subscription
                        ? this.invoiceInfo.subscription.features.map((feature) => feature.name)
                        : []
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            })
        }
    }

    back() {
        this.router.navigate(['/admin/invoices']);
    }
}
