import { TelephonyAction } from './momt-action.interface';
import { MomtArtifact } from './momt-artifact.interface';
import { MomtIterationStatus, MomtTestIteration } from './momt-test-iteration.interface';

export interface MoMtDeviceInfo {
    sn: string;
    phone: string;
    os: string;
    name: string;
    type: string;
    index: number;
}

export interface TelephonyDevice {
    type: string;
    index: number;
}

export enum MomtTestStatus {
    IDLE = 'IDLE',
    PROGRESS = 'PROGRESS',
    FINALIZING = 'FINALIZING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
}

export enum TelephonyTestType {
    MOMT = 'MO_MT',
    MOMT_LONG = 'MO_MT_LONG',
    MT_ANSWERS = 'MT_ANSWERS',
    MO_DIALS = 'MO_DIALS',
    MT_VOICEMAIL = 'MT_VOICEMAIL',
    MERGE_CALL = 'MERGE_CALL',
    SWAP_CALL = 'SWAP_CALL',
    DIAL_MT2 = 'DIAL_MT2',
    MO_SMS = 'MO_SMS',
    MO_MT_SMS = 'MO_MT_SMS',
    MO_MT_CALL_SMS = 'MO_MT_CALL_SMS',
    SPEED_TEST = 'SPEED_TEST',
}

export const MomtStatusMap = new Map<MomtTestStatus, string>([
    [MomtTestStatus.IDLE, 'Not Started'],
    [MomtTestStatus.PROGRESS, 'In Progress'],
    [MomtTestStatus.FINALIZING, 'Finalizing'],
    [MomtTestStatus.COMPLETED, 'Completed'],
    [MomtTestStatus.FAILED, 'Failed'],
    [MomtTestStatus.CANCELED, 'Canceled'],
]);

export const MomtTypeMap = new Map<TelephonyTestType, string>([
    [TelephonyTestType.MOMT, 'Voice call'],
    [TelephonyTestType.MOMT_LONG, 'Long voice call'],
    [TelephonyTestType.MT_ANSWERS, 'Auto-answer'],
    [TelephonyTestType.MO_DIALS, 'MO Dialer'],
    [TelephonyTestType.MT_VOICEMAIL, 'Voicemail'],
    [TelephonyTestType.MERGE_CALL, 'Three-way conference call'],
    [TelephonyTestType.SWAP_CALL, 'Swap calls'],
    [TelephonyTestType.DIAL_MT2, 'Dial while in connected state'],
    [TelephonyTestType.MO_SMS, 'Send short SMS without validation'],
    [TelephonyTestType.MO_MT_SMS, 'Validate SMS'],
    [TelephonyTestType.MO_MT_CALL_SMS, 'Send SMS during call'],
    [TelephonyTestType.SPEED_TEST, 'Speed test'],
]);

export interface TelephonyParameter {
    displayName: string;
    name: string;
    type: string;
    constraints: {
        required?: boolean;
        min?: number;
        max?: number;
    };
    value: string;
}

export interface MomtTest {
    _id: string;
    name: string;
    description: string;
    type: TelephonyTestType;
    status: MomtTestStatus;
    repeatCount: number;
    iterationDelay: number;
    createdAt: number;
    modifiedAt: number;
    executedAt: number;
    devices: MoMtDeviceInfo[];
    user: {
        userId: string;
    };
    actions: TelephonyAction[];
    artifacts: MomtArtifact[];
    artifactSelection: string[];
    iterations?: MomtTestIteration[];
    parameters?: TelephonyParameter[]
}

export interface MomtTestSummary {
    _id: string;
    name: string;
    description: string;
    type: string;
    status: MomtTestStatus;
    repeatCount: number;
    iterationDelay: number;
    createdAt: number;
    modifiedAt: number;
    executedAt: number;
    devices: MoMtDeviceInfo[];
    user: {
        userId: string;
    };
    actions: TelephonyAction[];
    artifacts: MomtArtifact[];
    artifactSelection: string[];
    iterations: { _id: MomtIterationStatus, count: number }[];
}

export interface TelephonyTestCase {
    name: string;
    description?: string;
    requisites?: string;
    startTimeout: number;
    devices: TelephonyDevice[];
    actions: TelephonyAction[];
    type: string;
    createdAt: number;
    parameters?: {
        displayName: string,
        name: string,
        type: string,
        constraints: {
            required?: boolean,
            min?: number,
            max?: number
        },
    }[]
}
