import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { TokenData, User } from '../../../../shared/interfaces';
import { CommonService } from '../../../../shared/common.service';
import { UserRoleEnum } from '../../../../shared/enum';
import { Subscription } from 'rxjs';
import { UserDataService } from '../../../../shared/user-data.service';
import { GetLabListDto, GetUserListDto } from '../../../../shared/dto';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-update-corporate-user',
    templateUrl: './update-corporate-user.component.html',
    styleUrls: ['./update-corporate-user.component.scss']
})
export class UpdateCorporateUserComponent implements OnInit, OnDestroy {
    userForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    isLoading = false;
    labOptions = [];
    enterpriseOptions = [];
    userData: TokenData;
    userProfile: User;
    userDataSubscription: Subscription = null;
    expirationDate = 0;
    minDate = new Date();
    showError = false;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private commonService: CommonService,
        private userService: UserDataService,
        private route: ActivatedRoute
    ) {
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.userDataSubscription = this.userService.userData$.subscribe((user) => {
            this.userProfile = user;
            if (user.email && user.role !== UserRoleEnum.ADMIN && this.enterpriseOptions.length === 0) {
                this.enterpriseOptions = [{ id: user.enterpriseId, name: user.enterpriseName }];
                this.isLoading = false;
            }
        });
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.userForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            enterpriseId: ['', Validators.required],
            labIds: [''],
            role: ['', [Validators.required]],
        });

        this.getUserInfo();
        if (this.userData.role === UserRoleEnum.ADMIN) {
            this.getEnterprises();
        }
        this.getLabs();
    }

    ngOnDestroy(): void {
        this.userDataSubscription.unsubscribe();
        this.userDataSubscription = null;
    }

    getUserInfo() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            const query = `userId=${id}`;
            this.adminService.getUserListApi(query).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success' && res.data['userList']?.length > 0) {
                    const data = res.data as GetUserListDto;
                    const userInfo = data.userList[0];
                    const labs = userInfo.labs ? userInfo.labs.map((lab) => ({ id: lab._id, name: lab.name })) : [];

                    this.userForm.setValue({
                        firstName: userInfo.firstName || '',
                        lastName: userInfo.lastName || '',
                        email: userInfo.email || '',
                        enterpriseId: userInfo.enterpriseId || '',
                        labIds: labs,
                        role: userInfo.role || ''
                    });
                    
                    this.expirationDate = userInfo.expirationDate * 1000;
                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            });
        }
    }

    getEnterprises() {
        this.isLoading = true;
        if (this.userData.role === UserRoleEnum.ADMIN) {
            this.adminService.getEnterprise('').subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    getLabs() {
        this.isLoading = true;
        let query = 'type=corporate&isEnabled=true';
        if (this.userData.role === UserRoleEnum.ADMIN) {
            query += `&enterpriseId=${this.userForm.value.enterpriseId}`
        }
        this.adminService.getLabList(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const response = res.data as GetLabListDto;
                this.labOptions = response.labList.map((lab) => ({ id: lab.labId, name: lab.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    onEnterpriseChange() {
        this.userForm.controls.labIds.setValue('');
        this.getLabs();
    }

    updateUser() {
        if (this.userForm.valid) {
            const {
                firstName,
                lastName,
                email,
                enterpriseId,
                role
            } = this.userForm.value;

            let labIds = this.userForm.value.labIds;

            if (labIds?.length === 0) {
                if (this.userProfile.labIds && this.userProfile.labIds.length > 0 && this.userProfile.role !== UserRoleEnum.ADMIN) {
                    labIds = this.userProfile.labIds;
                } else {
                    labIds = null;
                }
            } else {
                labIds = labIds.map(lab => lab.id);
            }

            const request = {
                firstName,
                lastName,
                email,
                enterpriseId,
                labIds,
                role,
                expirationDate: this.expirationDate
            };

            this.isLoading = true;
            this.adminService.updateUser(request, this.route.snapshot.queryParamMap.get('id')).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/users']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: true,
        };
    }

    clearExpirationDate(event: Event) {
        event.stopPropagation();
        this.expirationDate = 0;
    }

    updateExpirationDate($event: MatDatepickerInputEvent<Date>) {
        this.expirationDate = new Date($event.value).setHours(23, 59, 59);
    }

    displayExpirationDate() {
        const date = new Date(this.expirationDate);
        return `Expiration Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
}

