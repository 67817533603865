<div class="content_out">
    <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
      <div class="filters">
        <div class="row">
          <div class="position-relative col-md-3 mt-3 mb-3">
            <input
              [(ngModel)]="searchEmei"
              type="text"
              maxlength="50"
              (keydown.enter)= "getDeviceList();"
              class="form-control login_input position-relative w-100"
              placeholder="Model name / OS Version"
            />
          </div>
          <div class="form-group col-md-2 mt-3 mb-3 pr-md-0" for="selectpicker">
            <select class="form-control select_input " (change)="setDefault()" [(ngModel)]="lType">
              <option value="">All Devices</option>
              <option value="apple">Apple</option>
              <option value="android">Android</option> 
            </select>
          </div>
          <div class="form-group col-md-2 mt-3 mb-3 pr-md-0" for="selectpicker">
            <select class="form-control select_input " (change)="setDefault()" [(ngModel)]="searchStautusType">
              <option value="">All Requests</option>
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option> 
              <option value="rejected">Rejected</option> 
            </select>
          </div>
          <div class="col-1  mt-3 mb-3">
            <button class="btn search_btn_advance search_btn no_search_btn mr-2" (click)="getDeviceList()">Search</button>
          </div>
        <div class="col-2  mt-3 mb-3">
          <button class="btn search_btn_advance clear_btn clr-labz" (click)="clearFilter()">
            Clear Filters
          </button>
        </div>
         </div>
      </div>
    <!-- GREY TABLE -->
    <div class="table_content">
      <div class="table_Content_outer">
        <div class="row align-items-center"></div>
        <div class="card-body mt-2 p-0">
          <div class="table-responsive inner_table_responsive device_table ">
            <table class="table smartTable">
              <thead class="tableheading inner_table_head">
                <tr>
                  <!-- <th scope="col">Category</th> -->
                  <th scope="col" class="_noWrap">REQUESTED DATE</th>
                  <th scope="col" class="_noWrap">REQUESTED BY</th>
                  <th scope="col" class="_noWrap">EMAIL</th>
                  <th scope="col" class="_noWrap">DEVICE TYPE</th>
                  <th scope="col" class="_noWrap">OEM</th>
                  <th scope="col" class="_noWrap">MODEL NAME</th>
                  <th scope="col" class="_noWrap">OS VERSION</th>
                  <th scope="col" class="_noWrap">REQUEST STATUS</th>
                  <th scope="col" class="_noWrap" *ngIf="searchStautusType == 'pending' || searchStautusType == ''">ACTION</th>
                </tr>
              </thead>
              <tbody>
               
                <tr class="position-relative" *ngFor="let item of deviceList; index as i">
                  <td class="_noWrap" style="text-transform: capitalize;">{{ item?.createdAt *1000 | date: "MM/dd/yyyy hh:mm a" }}</td>

                  <td class="_noWrap">{{ item?.userDetails.firstName ? item.userDetails.firstName + ' ' + item.userDetails.lastName : '--' }}</td>
                  <td class="_noWrap">{{ item?.userDetails.email? item.userDetails.email : '--' }}</td>
                  <td class="_noWrap" style="text-transform: capitalize;">{{ item.deviceType? item.deviceType : '--' }}</td>
                  
                  <td class="_noWrap" style="text-transform: capitalize;" >{{ item.oem? item.oem : '--' }}</td>
                  <td class="_noWrap" style="text-transform: capitalize;" >{{ item.modelName? item.modelName : '--' }}</td>
                  <td class="_noWrap">{{ item?.osVersion? item?.osVersion : '--' }}</td>
                  <td class="_noWrap" style="text-transform: capitalize;" >{{ item.status? item.status : '--' }}</td>
                  
                 <td class="_noWrap">
                  <i class="fas fa-edit icon_size ml-3 cursor_point" *ngIf="item.status =='pending'" #AssBtn13 (click)="openModal(Assign, AssBtn13, item)"></i>
                 </td>
                </tr>
              </tbody>
            </table>
            <div  class="row p-0 m-0 mt-2 mb-3 align-items-center"  *ngIf="deviceList.length > 0" >
              <div  class="pagLimitSet ml-4">
                <select class="pagLimitSet_selecet" (change)="changePageLimit()"  [(ngModel)]="pagelimit">
                  <option  value="5">5</option>
                  <option  value="10">10</option>
                  <option  value="20">20</option>
                  <option  value="50">50</option>
                  <option  value="100">100</option>
                </select>
              </div>
              <div class="col" style="margin-right:10px">
                <div class="pagination_custom" *ngIf="deviceList.length > 0">
                  <ul class="d-flex flex-row">
                    <li (click)="prevPage()" [ngClass]="{'disabled_pagination':currentPage  == 0}" > < </li>
                    <li class="show_number"> {{currentPage + 1}} / {{totalPage }} </li>
                    <li (click)="nextPage()" [ngClass]="{'disabled_pagination':currentPage + 1  == totalPage }" > > </li>
                </ul>
              </div>
              </div>
            </div>
            <p class="no_device" *ngIf="deviceList.length == 0">
              No Data Available
            </p>
          </div>
        </div>
       
      </div>
    </div>
    <!-- GREY END TABLE -->
  </div>
    <ng-template #Assign let-modal>
      <app-spinner *ngIf="isLoadingModal"></app-spinner>
      <div class="modal-header">
          <h5 class="modal-title">Change Request Status</h5>
      
          <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
              <span aria-hidden="true">&times;</span>
          </button> -->
          <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
            >x</span
          >
        </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <form [formGroup]="statusForm">
                <div class="row">
                  <div class="form-group col-md-12">
                      <label>Existing Status</label>
                      <div>
                        <input
                        class="form-control login_input position-relative w-100 text_capital"
                          type="text"
                          [disabled]="true"
                          value="{{ data.status }}" 
                        />
                      </div>
                    </div>
                  <div class="form-group col-md-12 mt-2 mb-3 " for="selectpicker">
                      <label>New Status</label>
                      <select class="form-control select_input mt-1" formControlName="status" >
                          <option value="">Select</option>
                          <option value="accepted">Accept</option>
                          <option value="rejected">Reject</option>
                      </select>
                      <div *ngIf="statusForm.controls['status'].invalid && (statusForm.controls['status'].dirty || statusForm.controls['status'].touched)" class="errorinput text-left mt-2">
                        <div *ngIf="statusForm.controls['status'].errors.required">
                          *Status is required.
                        </div>
                    </div>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
       <div class="row justify-content-end">
          <button type="submit" class="add_card" [disabled]="!statusForm.valid" (click)="updateUserStatus()">Save</button>
       </div>
      </div>
    </ng-template>
