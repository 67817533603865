import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManualTest} from '../../shared/interfaces';
import {ManualTestService} from './manual-test.service';
import {EmptyFolderButtonAction} from '../../shared/empty-folder/empty-folder.component';
import {Subject, Subscription} from 'rxjs';
import {FeatureTypeEnum, MtSessionStatusEnum} from '../../shared/enum';
import {CommonService} from '../../shared/common.service';
import {DevicehomeService} from '../device-homepage/devicehome.service';
import {stateTooltip} from '../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ComponentCanDeactivate} from '../../guards/pending-changes-guard';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogModalService} from '../../shared/dialog-modal.service';

@Component({
    selector: 'app-manual-test',
    templateUrl: './manual-test.component.html',
    styleUrls: ['./manual-test.component.scss']
})
export class ManualTestComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    emptyActions: EmptyFolderButtonAction[];
    testList: ManualTest[] = [];
    testListSubscription: Subscription = null;
    isMTAvailable = false;
    replayInProgress = false;
    actionToReplay: any;
    isLoading = false;
    allDeviceList = [];
    deviceList = [];
    deviceListTemp = [];
    AllDeviceIos = true;
    AllDeviceAnd = true;
    availableIos = 0;
    availableAndroid = 0;
    labListDropdown = [];
    userData: any;
    filterList = [];
    bookingFilterList = [];
    searchLab = '';
    searchLocation = '';
    selectedBookedFilter = 'available';
    searchText = '';
    ignoreTyping = false;
    constructor(
        public manualTestService: ManualTestService,
        public commonService: CommonService,
        private deviceHome: DevicehomeService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
    ) {
        this.emptyActions = [{ button: 'Record a new test', onClick: () => this.manualTestService.createNewTest() }];
        this.testListSubscription = this.manualTestService.testList$.subscribe((list) => this.testList = list);
    }
    ngOnInit(): void {
        this.isMTAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.MT]);
        if (this.isMTAvailable) {
            this.manualTestService.getManualTests();
        }
        this.userData = this.commonService.getUser();
        this.SearchDevice();
    }

    ngOnDestroy(): void {
        this.manualTestService.stopPinging();
        this.manualTestService.stopPolling();
        this.testListSubscription.unsubscribe();
        this.testListSubscription = null;
    }

    updateDeviceSelect($event: string) {
        if ($event === 'select') {
            this.manualTestService.changeSelectDeviceMode();
        }
        if ($event === 'release') {
            this.releaseDevice();
        }
    }
    canDeactivate() {
        return this.manualTestService.mtSessionStatus !== MtSessionStatusEnum.ONGOING;
    }
    showWarningModal() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                header: 'Save manual test before leaving the page',
                content: 'Leaving the page without saving will result in loosing all recorded data for manual test permanently.',
                accept: 'Save & Leave',
                decline: 'Discard & Leave',
                cancel: 'Cancel',
            },
        });
        const subject = new Subject<boolean>();

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'accept') {
                this.manualTestService.saveOngoingTest();
            } else if (result === 'decline') {
                this.manualTestService.discardOngoingTest();
            }
        });
        return subject.asObservable();
    }
    releaseDevice = () => {
        this.isLoading = true;
        this.deviceHome.releaseDevice({ deviceBookingId: this.manualTestService.selectedManualTestDevice.bookingId }).subscribe(() => {
            this.manualTestService.selectedManualTestDevice = null;
            this.SearchDevice();
            this.isLoading = false;
        }, () => this.isLoading = false);
    }

    SearchDevice(): void {
        this.deviceHome.availableDevice('showAll=true').subscribe((res) => {
            if (res['message'] === 'Success') {
                this.allDeviceList = res.data.deviceList;
                const unusedDeviceList = res.data.deviceList.filter((device) => !device.testingType
                    && device.deviceCommunicationStatus.toLowerCase() !== 'remote-testing');
                this.deviceList = unusedDeviceList;
                this.availableAndroid = 0;
                this.availableIos = 0;
                this.AllDeviceAnd = true;
                this.AllDeviceIos = true;
                this.labListDropdown = [];
                let labUrl = [];
                this.deviceList.forEach((element) => {
                    element['showId'] = false;
                    if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
                        element['deviceStateCode'] = 'TESTING';
                    }
                    if (element['labName']) {
                        this.labListDropdown.push(element['labName']);
                        labUrl.push(element['labDomain']);
                    }
                    if (element['oem'] === 'Apple') {
                        this.availableIos = this.availableIos + 1;
                    } else {
                        this.availableAndroid = this.availableAndroid + 1;
                    }
                    if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
                        element['deviceStateCode'] = 'DISCONNECTED';
                    }
                    if (!element['deviceStateCode'] || (element['deviceStateCode']
                        && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
                        element['deviceStateCode'] = 'UNKNOWN';
                    }
                    if (element['deviceState'].toLowerCase() === 'offline') {
                        element['isResetBtn'] = true;
                        if (element['isBooked'] && element['bookedByUserId'] === this.userData.userId) {
                            element['isResetBtn'] = true;
                        }
                        if (element['isBooked'] && element['bookedByUserId'] !== this.userData.userId) {
                            element['isResetBtn'] = false;
                        }
                    }
                    element['isResetting'] = false;
                    element['resetCount'] = 0;
                    element['isBook'] = false;
                    element['searchEndDate'] = '';
                    element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
                });
                localStorage.setItem('AllDevice', JSON.stringify(this.deviceList));
                this.deviceListTemp = unusedDeviceList;
                this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
                this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
                this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
                labUrl = labUrl.filter(this.onlyUnique);
                if (labUrl.length > 0) {
                    this.commonService.setStorage('_domin', labUrl[0]);
                }
                if (res.data.deviceList.length === 0) {
                    this.deviceList = [];
                    return this._snackBar.open('No device available!', '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['failure']
                    });
                }
                this.SearchDeviceLocal();
            }
        });
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    groupBy(objectArray, property, filterProperty?: string, filterValue?: string): any {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];

            if (filterProperty && filterValue) {
                if (obj[filterProperty] !== filterValue) {
                    return acc;
                }
            }

            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for the given key's value
            acc[key].push(obj);
            return acc;
        }, {});
    }

    SearchDeviceLocal(): void {
        this.searchByText(this.deviceListTemp);
        if (this.searchLab) {
            this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
        }
        if (this.searchLocation) {
            this.deviceList = this.deviceList.filter(device => device.labLocation === this.searchLocation);
        }

        if (!this.AllDeviceAnd && this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem === 'Apple';
            });
        }
        if (this.AllDeviceAnd && !this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem !== 'Apple';
            });
        }
        if (this.selectedBookedFilter === 'booked') {
            this.deviceList = this.deviceList.filter(device => {
                return device.isBooked;
            });
        }
        if (this.selectedBookedFilter === 'available') {
            this.deviceList = this.deviceList.filter(device => {
                return !device.isBooked && device.deviceStateCode === 'ONLINE';
            });
        }
        this.countAmountDevices();
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
    }

    searchByText(list) {
        const keys = ['serialNumber', 'modelName', 'osVersion'];
        const values = [];
        if (this.searchText) {
            values.push(this.searchText.toLowerCase());
        }
        const regex = new RegExp(values.join('|'));
        this.deviceList = list.filter(e => keys.some(k => regex.test(e[k]?.toLowerCase())));
    }

    updateIgnoreTyping(v: boolean): void {
        this.ignoreTyping = v;
    }

    countAmountDevices() {
        this.availableAndroid = 0;
        this.availableIos = 0;
        this.deviceList.forEach(element => {
            if (element['oem'] === 'Apple') {
                this.availableIos = this.availableIos + 1;
            } else {
                this.availableAndroid = this.availableAndroid + 1;
            }
        });
        if (this.searchText) {
            this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
        } else {
            if (this.searchLab) {
                const filteredByLabDeviceList = this.deviceListTemp.filter(device => device.labName === this.searchLab);
                this.bookingFilterList = this.groupBy(filteredByLabDeviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
            } else {
                this.bookingFilterList = this.groupBy(this.deviceListTemp, 'isBooked', 'deviceStateCode', 'ONLINE');
            }
        }
    }
}
