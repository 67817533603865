import { TestsApiErrorDto } from '../dto';
import { CustomTestFormStep } from './create-test-form.interface';

export enum CustomTestReplayStatusEnum {
    RUNNING = 'RUNNING',
    IDLE = 'IDLE',
    PASS = 'PASS',
    PASSED = 'PASSED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    CANCELLING = 'CANCELLING',
    EXECUTING = 'EXECUTING'
}

export interface CustomTestReplay extends TestsApiErrorDto {
    id: string;
    testId: string;
    executionId: string;
    startedAt: number;
    endedAt: number;
    index: number;
    status: CustomTestReplayStatusEnum;
    steps: CustomTestFormStep[];
    result?: {
        errors?: {
            code: string;
            message: string;
        }[]
    }
}

export const CustomTestReplayStatusMap = new Map<CustomTestReplayStatusEnum, string>([
    [CustomTestReplayStatusEnum.IDLE, 'Not Started'],
    [CustomTestReplayStatusEnum.RUNNING, 'In Progress'],
    [CustomTestReplayStatusEnum.EXECUTING, 'In Progress'],
    [CustomTestReplayStatusEnum.PASSED, 'Passed'],
    [CustomTestReplayStatusEnum.COMPLETED, 'Completed'],
    [CustomTestReplayStatusEnum.FAILED, 'Failed'],
    [CustomTestReplayStatusEnum.CANCELED, 'Canceled'],
    [CustomTestReplayStatusEnum.CANCELLING, 'Cancelling'],
]);
