import { Component, Input } from '@angular/core';
import {
    ActionTypeMap,
    TelephonyAction,
    TelephonyActionType,
    MomtIterationStatus,
    MomtIterationStatusMap,
    MomtTestIteration,
    ActionIconMap
} from '../../interfaces';

@Component({
    selector: 'app-iteration-accordion',
    templateUrl: './iteration-accordion.component.html',
    styleUrls: ['./iteration-accordion.component.scss']
})

export class IterationAccordionComponent {
    @Input() iterations: MomtTestIteration[];

    expandedRows = new Set();

    displayActionType(type: TelephonyActionType): string {
        return ActionTypeMap.get(type);
    }

    getActionTime(action: TelephonyAction, iteration: MomtTestIteration): number {
        if (action.data?.finishedAt && iteration.startedAt) {
            return action.data.finishedAt - iteration.startedAt;
        }
        return 0;
    }

    displayIterationStatus(status: MomtIterationStatus): string {
        return MomtIterationStatusMap.get(status);
    }

    handleRowSelection(index: number) {
        if (this.expandedRows.has(index)) {
            this.expandedRows.delete(index);
        } else {
            this.expandedRows.add(index);
        }
    }

    getActionIconName(type: TelephonyActionType): string {
        return ActionIconMap.get(type);
    }
}

