import { RxStompConfig } from '@stomp/rx-stomp';
import * as SockJS from 'sockjs-client';

export function socketFactory() {
    return new SockJS(`${localStorage.getItem('labDomain')}SnapBox/snap-websocket-sockjs-stomp?authorization=Bearer ${localStorage.getItem('token')}`);
}

export function getRxStompConfigForUser(token: string): RxStompConfig {
    return {
        webSocketFactory: socketFactory,
        connectHeaders: {
            authorization: `Bearer ${token}`
        },
        heartbeatIncoming: 20000,
        heartbeatOutgoing: 20000,
        reconnectDelay: 0,
    }
}
