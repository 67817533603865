import {Component, inject, OnInit} from '@angular/core';
import {TIPPY_REF} from '@ngneat/helipopper';
import {TooltipData} from '../tooltip.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  tippy = inject(TIPPY_REF);
  tooltipData: TooltipData = {
    text: ''
  };

  constructor() {
    if (this.tippy.data && this.tippy.data.length) {
      this.tooltipData = JSON.parse(this.tippy.data);
    }
  }

  ngOnInit(): void { }

}
