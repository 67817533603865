import { Injectable, Injector } from '@angular/core';
import { Constants } from './common.const';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { FeatureTypeEnum, UserRoleEnum } from './enum';
import { TokenData } from './interfaces';
@Injectable()
export class CommonService extends Constants {
  public httpOptions = {};
  constructor(
    private injector: Injector,
  ) {
    super();
  }
  public get router(): Router {
    return this.injector.get(Router);
  }
  getToken(endPoint: any) {
    let token = localStorage.getItem("token");
    switch (endPoint) {
      case this.basic:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Authorization": token
          })
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": token
            })
          };
        }
        break;
      case this.bearer:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json"
          })
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": token
            })
          };
        }
        break;
      default:
        break;
    }
    return this.httpOptions;
  }
  getTokenDlt(endPoint: any, param) {
    let token = localStorage.getItem("token");
    switch (endPoint) {
      case this.basic:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Authorization": token
          }),
          body: param
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": token
            }),
            body: param
          };
        }
        break;
      case this.bearer:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json"
          }),
          body: param
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": token
            }),
            body: param
          };
        }
        break;
      default:
        break;
    }
    return this.httpOptions;
  }
  setStorage(key, value): any {
    if (typeof value == 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }
  getStorage(key): any {
    const x = localStorage.getItem(key);
    try {
      const xx = JSON.parse(x);
      return xx;
    } catch (e) {
      return x;
    }
  }
  getUser(): TokenData {
    const token = this.getStorage('token');
    return jwt_decode(token);
  }
  isAuthenticated() {
    return localStorage.getItem('token') != null;
  }
  logOut() {
    localStorage.clear();
    const browser = this.getBrowserName();
    localStorage.setItem('browser', browser);
    this.router.navigate(['signin']);
  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  getTokenZip(endPoint: any) {
    const token = localStorage.getItem('token');
    if (token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Authorization': token
        })
      };
    }
    this.httpOptions['responseType'] = 'arraybuffer';
    this.httpOptions['observe'] = 'response';
    return this.httpOptions;
  }
  checkFeatureAccess(features: FeatureTypeEnum[]): boolean {
    const user = this.getUser();
    return user?.role === UserRoleEnum.ADMIN
      || features.every((feature) => user?.availableFeatures?.includes(feature));
  }
}