<section class="new-test" [ngClass]="{ongoing_test_container: isTestRunning}">
    <div class="back-button" *ngIf="isTestRunning" (click)="navigateMoMt('/admin/telephony')">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        <span class="mo-mt-mode">Back</span>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>

    <div class="container_new_test" *ngIf="isTelephonyAvailable" [ngClass]="{ongoing_test: isTestRunning}">
        <div class="sub_container left_container" [ngClass]="{ongoing_test: isTestRunning}">
            <app-multi-device-select [isTestRunning]="isTestRunning" [ignoreTyping]="ignoreTyping"
                [devices]="selectedTestCase?.devices" (deviceSelected)="selectDevices($event)"
                (switchCustomizeMode)="switchCustomizeMode($event)" [deviceListener]="setDevices">
            </app-multi-device-select>
            <div class="ongoing-banner" *ngIf="isTestRunning && testInfo.status === 'PROGRESS'">
                <mat-progress-bar mode="determinate" [value]="progressBarValue"
                 *ngIf="selectedTestCase?.type !== 'SPEED_TEST' || (selectedTestCase?.type === 'SPEED_TEST' && !!testInfo?.actions[0]?.data?.time)"></mat-progress-bar>
                <span class="remaining_time" *ngIf="selectedTestCase?.type !== 'SPEED_TEST' || (selectedTestCase?.type === 'SPEED_TEST' && !!testInfo?.actions[0]?.data?.time)">
                    Time remaining:
                    {{getEstimatedTime(selectedTestCase)}}
                </span>
                <span class="iterations-counter">
                    <span class="completed_iterations">{{completedIterations}} of </span> {{totalIterations}}
                </span>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="!isTestRunning && !isCustomizeMode">
            <h3 class="new_test_title">Test Configuration {{selectedTestCase?.name ? '- ' + selectedTestCase?.name : ''}}</h3>
            <div class="text_new_test">
                <label for="test-iterations">Iterations</label>
                <input id="test-iterations" type="number" min="1" max="500" [(ngModel)]="testRepeatCount"
                    class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)"
                    (focusout)="updateIgnoreTyping(false)" />
            </div>
            <div class="text_new_test">
                <label for="test-delay">Delay</label>
                <label class="time-unit" data-unit="sec">
                    <input id="test-delay" type="number" min="1" max="3600" [(ngModel)]="iterationDelay"
                        class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)"
                        (focusout)="updateIgnoreTyping(false)" />
                </label>
            </div>
            <h4>Artifacts</h4>
            <div class="checkboxes" *ngIf="!isCloudAvailable">
                Unavailable. Your subscription plan must include Cloud feature.
            </div>
            <div class="checkboxes" *ngIf="isCloudAvailable">
                <mat-checkbox *ngIf="selectedTestCase?.type !== 'SPEED_TEST'" [checked]="artifactSelection['CALL_LOG']" color="primary"
                    (change)="handleArtifactSelection('CALL_LOG', $event.checked)">
                    {{getArtifactLabel()}}
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1 && selectedTestCase?.type !== 'SPEED_TEST'"
                    [checked]="artifactSelection[selectedTestCase?.devices[0].type + '_LOG']" color="primary"
                    (change)="handleArtifactSelection(selectedTestCase?.devices[0].type + '_LOG', $event.checked)">
                    Save device logs
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[selectedTestCase?.devices[0].type + '_VIDEO']" color="primary"
                    (change)="handleArtifactSelection(selectedTestCase?.devices[0].type + '_VIDEO', $event.checked)">
                    Save video
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[selectedTestCase?.devices[0].type + '_USER_ACTION']" color="primary"
                    (change)="handleArtifactSelection(selectedTestCase?.devices[0].type + '_USER_ACTION', $event.checked)">
                    Save user actions
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[selectedTestCase?.devices[0].type + '_KPIS']" color="primary"
                    (change)="handleArtifactSelection(selectedTestCase?.devices[0].type + '_KPIS', $event.checked)">
                    Save device KPIs
                </mat-checkbox>
                <div *ngIf="selectedTestCase?.devices.length > 1" class="artifact-table">
                    <div class="table-row">
                        <span></span>
                        <span>Device Logs</span>
                        <span>Video</span>
                        <span>User Actions</span>
                        <span>Device KPIs</span>
                    </div>
                    <div *ngFor="let device of selectedTestCase?.devices" class="table-row">
                        <span class="bolder">{{device.type + ' device'}}</span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.type + '_LOG']" color="primary"
                                (change)="handleArtifactSelection(device.type + '_LOG', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.type + '_VIDEO']" color="primary"
                                (change)="handleArtifactSelection(device.type + '_VIDEO', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.type + '_USER_ACTION']" color="primary"
                                (change)="handleArtifactSelection(device.type + '_USER_ACTION', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.type + '_KPIS']" color="primary"
                                (change)="handleArtifactSelection(device.type + '_KPIS', $event.checked)">
                            </mat-checkbox>
                        </span>
                    </div>
                </div>
            </div>
            <form class="dynamic-form" *ngIf="paramsForm && selectedTestCase?.type !== 'SPEED_TEST'" [formGroup]="paramsForm">
                <div class="text_new_test group" *ngFor="let param of selectedTestCase.parameters">
                    <label>{{param.displayName}} <span *ngIf="param.constraints?.required">*</span></label>
                    <textarea *ngIf="param.type === 'textarea'" [formControlName]="param.name"
                        [max]="param.constraints?.max" rows="5" class="form-control input_new_test"></textarea>

                    <input *ngIf="param.type === 'text'" type="text" [formControlName]="param.name"
                        [maxlength]="param.constraints?.max" class="form-control input_new_test"
                        (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)" />
                    <div *ngIf="paramsForm.controls[param.name].invalid && (paramsForm.controls[param.name].dirty || paramsForm.controls[param.name].touched)"
                        class="error">
                        <div *ngIf="paramsForm.controls[param.name].errors.required">*{{param.displayName}} is
                            required</div>
                    </div>
                </div>
            </form>
            <form class="dynamic-form speed-test-form" *ngIf="selectedTestCase?.type === 'SPEED_TEST'" [formGroup]="speedTestForm">
                <div class="text_new_test group mbottom">
                    <mat-radio-group aria-label="Select protocol" formControlName="udp" (change)="protocolChanged()">
                        <mat-radio-button [value]="false">TCP</mat-radio-button>
                        <mat-radio-button [value]="true">UDP</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="text_new_test group">
                    <mat-radio-group aria-label="Select protocol" formControlName="limit">
                        <mat-radio-button [value]="false">Time of the test</mat-radio-button>
                        <mat-radio-button [value]="true">Number of bytes</mat-radio-button>
                    </mat-radio-group>
                    <label class="time-unit mtop" data-unit="sec" *ngIf="!limit.value">
                        <input class="form-control input_new_test number_input" type="number" formControlName="time" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </label>
                    <label class="time-unit mtop" data-unit="bytes" *ngIf="limit.value">
                        <input class="form-control input_new_test number_input" type="number" formControlName="bytes" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </label>
                </div>
                <div class="text_new_test">
                    <!-- <mat-radio-group aria-label="Select protocol" formControlName="version" (change)="filterServerList()">
                        <mat-radio-button [value]="4">IPv4</mat-radio-button>
                        <mat-radio-button [value]="6">IPv6</mat-radio-button>
                    </mat-radio-group> -->
                    <select formControlName="server" class="form-control select_input select-servers" (change)="setPort()">
                        <option value="">Select server</option>
                        <option [value]="item.host" *ngFor="let item of filteredServerList">{{item.name + ' (' + displayServerHost(item) + ')' + getPortRange(item)}}</option>
                    </select>
                </div>
                <div class="text_new_test group">
                    <label>Port</label>
                    <label class="time-unit">
                        <input class="form-control input_new_test" type="text" formControlName="port" [readonly]="!selectedServer?.portRange?.length > 0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </label>
                </div>
                <div class="text_new_test group">
                    <label>Target throughput (if test result will be below defined value, the iteration considered failed)</label>
                    <label class="time-unit" data-unit="Mbits/sec">
                        <input class="form-control input_new_test number_input" type="number" formControlName="bandwidth" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </label>
                </div>
                <div class="text_new_test group" *ngIf="!udp.value">
                    <label>TCP max segment size</label>
                    <label class="time-unit" data-unit="bytes" *ngIf="!checkMSSsupport()">
                        <input class="form-control input_new_test number_input" type="number" formControlName="mss" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </label>
                    <input class="form-control input_new_test" *ngIf="checkMSSsupport()" type="text" value="not available for iOS devices" disabled>
                </div>
                <div class="text_new_test group" *ngIf="!udp.value">
                    <mat-checkbox color="primary" formControlName="nodelay">TCP no delay</mat-checkbox>
                </div>
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <span class="advanced-header">Advanced parameters</span>
                    </mat-expansion-panel-header>
                    <mat-checkbox color="primary" formControlName="reverse">Reverse mode (Downlink/Uplink)</mat-checkbox>
                    <div class="text_new_test group">
                        <label>Buffer size</label>
                        <input class="form-control input_new_test" type="number" formControlName="length" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </div>
                    <div class="text_new_test group">
                        <label>Socket buffer sizes</label>
                        <input class="form-control input_new_test" type="number" formControlName="window" min="0"
                               (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)">
                    </div>
                </mat-expansion-panel>
            </form>
            <div class="buttons_new_test">
                <button class="btn momt_btn" (click)="openConfirmCancel()">Cancel</button>
                <button class="btn momt_btn" (click)="saveTest()">Save</button>
                <button class="btn momt_btn momt_btn_start loginBtn" [disabled]="!checkTestReady()"
                    (click)="startTest()">
                    Run test
                </button>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="isTestRunning">
            <h3 class="test-name">
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="selectedTestCase?.name | tooltipData"
                      [tpOnlyTextOverflow]="true">{{selectedTestCase?.name}}</span>
                <button class="btn momt_btn stop-test" (click)="cancelTest()">Stop the test</button>
            </h3>
            <span class="test-description" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                [tpData]="selectedTestCase?.description | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false"
                *ngIf="selectedTestCase?.description">
                Description - {{selectedTestCase?.description}}
            </span>
            <app-iteration-accordion *ngIf="selectedTestCase?.type !== 'SPEED_TEST'" [iterations]="iterations"></app-iteration-accordion>
            <app-speed-test-data *ngIf="selectedTestCase?.type === 'SPEED_TEST'" [iterations]="iterations"></app-speed-test-data>
            <div class="pagination-container" *ngIf="iterations?.length > 0">
                <span class="limit">
                    <label for="limit">Rows per page: </label>
                    <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </span>
                <span>{{paginationText}}</span>
                <div class="pagination-buttons">
                    <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
                        <img alt="" src="../../../../assets/images/arrow_back.svg">
                    </div>
                    <div [ngClass]="{ disabled: currentPage + 1 >= totalPage }" class="arrow-button"
                        (click)="changePage(1)">
                        <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="sub_container right_container info_tab" *ngIf="isCustomizeMode">
            <h3 class="info_header">Info</h3>
            <div class="info_container">
                <app-info-tab [updateIgnoreTyping]="updateIgnoreTyping"></app-info-tab>
            </div>
        </div>
    </div>
    <div class="no-access" *ngIf="!isTelephonyAvailable">
        <p>
            MO-MT Testing is not available with your current subscription plan. <br />
            To get access to MO-MT Testing, you must upgrade your account to a plan that includes Telephony
            tests feature.
        </p>
    </div>
</section>
