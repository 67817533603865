import { Component, ElementRef, HostListener, OnDestroy, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { UserDataService } from "../../../shared/user-data.service";
import { Subscription } from "rxjs";
import { User } from "../../../shared/interfaces";
import { TooltipService } from "../../../shared/tooltip.service";
import { UserRoleEnum } from "../../../shared/enum";

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class AppSideNavigationComponent implements OnDestroy {
  userDataSubscription: Subscription = null;
  userData: User;
  tooltipComponent = null;
  showHeader = false;
  showMore = false;
  hiddenItems = [];
  morePosition = '0px';
  automationPosition = '0px';
  showMoreAutomation = false;
  isHovered: boolean[] = [];

  @ViewChildren('mainHeader') headerList: QueryList<ElementRef>;
  @ViewChild('showMoreBtn') showMoreBtn: ElementRef;
  @ViewChild('automationBtn') automationBtn: ElementRef;
  @ViewChildren('secHeader') headerSecList: QueryList<ElementRef>;
  @ViewChild('headerWrapper') headerWrapper: ElementRef;

  constructor(
    public router: Router,
    private userService: UserDataService,
    private tooltipService: TooltipService,
  ) {
    this.tooltipComponent = this.tooltipService.getNavigationTooltipComponent();
    this.userDataSubscription = this.userService.userData$.subscribe((user) => {
      this.userData = user;
      this.showHeader = !!user.email;
    });
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
    this.userDataSubscription = null;
  }

  ngAfterViewInit(): void {
    let waitForUserdata: any;
    waitForUserdata = setInterval(() => {
      if (this.showHeader) {
        clearInterval(waitForUserdata);
        // setTimeout(() => { this.adjustHeaderMenu(); }, 300);
      }
    }, 50);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.headerList && this.headerList.length) {
      // setTimeout(() => { this.adjustHeaderMenu(); }, 300);
    }
  }

  adjustHeaderMenu(): void {
    this.hiddenItems = [];
    this.headerList.forEach((item) => {
      item.nativeElement?.classList.remove('hidden');
    });
    let stopHeight = this.headerList.first?.nativeElement.offsetHeight;
    const primaryHeight = this.headerWrapper?.nativeElement.offsetHeight + 2;
    this.headerList.forEach((item, i) => {
      if (primaryHeight >= stopHeight + item?.nativeElement.offsetHeight) {
        stopHeight += item?.nativeElement.offsetHeight;
      } else {
        item?.nativeElement.classList.add('hidden');
        this.hiddenItems.push(i);
      }
    });

    if (this.hiddenItems.length) {
      if (this.hiddenItems.length === 1) {
        this.hiddenItems = [];
        this.headerList.forEach((item) => {
          item.nativeElement?.classList.remove('hidden');
        });
        this.showMoreBtn.nativeElement.classList.add('hidden');
        this.showMore = false;
      } else {
        this.showMoreBtn.nativeElement.classList.remove('hidden');
        this.headerSecList.forEach((item, i) => {
          if (!this.hiddenItems.includes(i)) {
            item?.nativeElement.classList.add('hidden');
          } else {
            item?.nativeElement.classList.remove('hidden');
          }
        });
      }
    } else {
      this.showMoreBtn.nativeElement.classList.add('hidden');
      this.showMore = false;
    }
    this.morePosition = (this.showMoreBtn?.nativeElement.offsetTop + this.showMoreBtn?.nativeElement.clientHeight - 26 - (39.5 * this.hiddenItems.length)) + 'px';
  }

  toggleMore(): void {
    this.showMore = !this.showMore;
  }

  getSubscriptionMessage(): string {
    if (this.userData && this.userData.role !== UserRoleEnum.ADMIN) {
      const now = Math.round(new Date().getTime() / 1000);
      return this.userData.subscription?.expiration.startAt > now
        ? 'Your subscription has not started yet'
        : this.userData.subscription?.expiration.endAt < now ? 'Your subscription has expired' : '';
    }
    return '';
  }

  toggleMoreAutomation(): void {
    this.showMoreAutomation = !this.showMoreAutomation;
    this.automationPosition = this.automationBtn?.nativeElement.offsetTop  + 'px';
    
  }

  checkIfAutomatedTabIsActive(): boolean {
    return this.router.url.includes('/admin/telephony')
        || this.router.url === '/snap/rnp-test-report' || this.router.url === '/snap/rnp-new-test'
        || this.router.url === '/snap/record-and-play';
  }

  updateIcon(isHovered: boolean, forIndex) {
    this.isHovered[forIndex] = isHovered;
  }
}  