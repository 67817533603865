<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1 (click)="back()">
    <img alt="" src="../../../../../assets/images/arrow_back.svg">
    View invoice
</h1>
<div class="not-found" *ngIf="showError">
    Invoice not found
</div>
<div class="invoice-form" *ngIf="invoiceInfo">
    <section class="left-part">
        <div class="group">
            <label for="selectEnterprise">Enterprise</label>
            <div class="field">{{invoiceInfo.enterprise.name}}</div>
        </div>
        <div class="double-input">
            <div class="group">
                <label for="firstNameSalesPerson">First name - Snap sales person</label>
                <div class="field">{{invoiceInfo.salePerson.firstName}}</div>
            </div>
            <div class="group">
                <label for="lastNameSalesPerson">Last name - Snap sales person</label>
                <div class="field">{{invoiceInfo.salePerson.lastName}}</div>
            </div>
        </div>
        <div class="double-input">
            <div class="group">
                <label for="firstNameEnterpriseSupport">First name - Enterprise Support</label>
                <div class="field">{{invoiceInfo.customerPerson.firstName}}</div>
            </div>
            <div class="group">
                <label for="lastNameEnterpriseSupport">Last name - Enterprise Support</label>
                <div class="field">{{invoiceInfo.customerPerson.lastName}}</div>
            </div>
        </div>
        <div class="group">
            <label for="labLimit">Lab Limit</label>
            <div class="field">{{invoiceInfo.labLimit || 'Unlimited'}}</div>
        </div>
        <div class="group">
            <label for="deviceLimit">Device Limit</label>
            <div class="field">{{invoiceInfo.deviceLimit || 'Unlimited'}}</div>
        </div>
        <div class="group">
            <label for="userLimit">User Limit</label>
            <div class="field">{{invoiceInfo.userLimit || 'Unlimited'}}</div>
        </div>
        <div class="group">
            <label for="cost">Cost</label>
            <div class="field">{{invoiceInfo.cost}}</div>
        </div>
        <mat-checkbox class="checkbox" [checked]="invoiceInfo.isExtensionOutgoingEngagement">
            Ongoing engagement
        </mat-checkbox>
    </section>
    <section class="right-part">
        <div class="group">
            <label for="features">Subscription</label>
            <div class="feature-container">
                <feature-list [features]="features"></feature-list>
            </div>
        </div>
        <div class="group">
            <label>Subscription Start Date </label>
            <div class="field">{{invoiceInfo.subscription?.expiration?.startAt | date: "MM.dd.yyyy" }}</div>
        </div>
        <div class="group">
            <label for="subscriptionEnd">Subscription End Date</label>
            <div class="field" *ngIf="invoiceInfo.subscription?.expiration?.endAt >= unlimited">
                Unlimited
            </div>
            <div class="field" *ngIf="invoiceInfo.subscription?.expiration?.endAt < unlimited">
                {{invoiceInfo.subscription?.expiration?.endAt | date: "MM.dd.yyyy" }}
            </div>
        </div>
        <div class="comments-textarea">
            <label for="comments">Comments</label>
            <textarea max="150" rows="3" class="form-control" readonly [value]="invoiceInfo.comments"></textarea>
        </div>
    </section>
</div>
