import { Component, Input } from "@angular/core";

export interface EmptyFolderButtonAction {
    button: string;
    onClick: () => void;
}

@Component({
    selector: 'app-empty-folder',
    templateUrl: './empty-folder.component.html',
    styleUrls: ['./empty-folder.component.scss']
})
export class EmptyFolderComponent {
    @Input() title: string;
    @Input() description: string;
    @Input() actions: EmptyFolderButtonAction[];
}