<div class="breadcrumbs">
    <p class="position-relative pr-2">
        <span>
            <span class="cursor_point mr-2" (click)="getFileStorage('/')">
                <img class="snap_root_img img-fluid" src="../../../assets/images/NewUI/cloud.svg" alt="">
            </span>
            <span *ngFor="let i of pathRoute; index as n">
                <span *ngIf="i.route != ''" class="root_path" (click)="routerPath(n)">
                    <span [ngClass]="{active: n === pathRoute.length - 2}">{{ i.route }}</span> /
                </span>
            </span>
        </span>
    </p>
</div>

<div class="tree-container">
    <div *ngIf="isLoading" class="loading-bar">
        Loading...
        <img src="../../../assets/images/NewUI/log_cate_loader.gif" alt="" />
    </div>
    <div *ngIf="!isLoading" class="file-structure">
        <div class="file-row" *ngFor="let item of folder" [ngClass]="{'selected': item.Key === selectedItem?.Key}">
            <span *ngIf="item.Key != currentPath && item.name && item.name != userData.userId">
                <mat-icon *ngIf="item.isFolder == true" class="type-icon customIconSize verticalAlignMid"
                    [attr.aria-label]="item.type + 'icon'">
                    folder
                </mat-icon>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                    *ngIf="item.isFolder == false" class="type-icon">
                    <path
                        d="M8.66668 2H3.33334V14H12.6667V6M8.66668 2H9.33334L12.6667 5.33333V6M8.66668 2V4.66667C8.66668 5.33333 9.33334 6 10 6H12.6667"
                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div class="file-name">
                <span *ngIf="item.Key != currentPath && item.name && item.name != userData.userId && item.isFolder"
                    (click)="getFileStorage(item.Key)" class="cursor_point">
                    {{ item.name }}
                </span>
                <span class="name"
                    *ngIf="item.Key != currentPath && item.name && item.name != userData.userId && !item.isFolder"
                    (click)="selectItem(item)" [ngClass]="{'cursor_point': !isEditable}">
                    {{ item.name }}
                </span>
                <div class="file-actions">
                    <span *ngIf="item.isFolder == false">
                        <span [matMenuTriggerFor]="menu">
                            <img src="../../../assets/images/NewUI/info-icon1.svg" alt="" />
                        </span>
                        <mat-menu #menu="matMenu">
                            <div>
                                <div class="p-2"><img src="../../../assets/images/NewUI/storage.svg" alt="" />
                                    {{ formatBytes(item?.Size, 2, "total") }}</div>
                                <div class="p-2">
                                    <img src="../../../assets/images/NewUI/booking_details.svg" alt="" />
                                    {{item.LastModified | date: "MM/dd/yyyy hh:mm a"}}
                                </div>
                            </div>
                        </mat-menu>
                    </span>
                    <span *ngIf="isEditable">
                        <span *ngIf="checkIfFolderEditable()" [matMenuTriggerFor]="menu">
                            <img src="../../../assets/images/NewUI/vertical_menu.svg" alt="" />
                        </span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item class="Menubtn_" #BTN1 #BTN6 (click)="deleteAction(item)">
                                Delete
                            </button>
                            <button mat-menu-item class="Menubtn_" class="dropdown-item" #BTN7
                                (click)="renameAction(item)">
                                Rename
                            </button>
                            <button mat-menu-item class="Menubtn_" *ngIf="item.isFolder == false" class="dropdown-item"
                                #BTN7 (click)="downloadAction(item)">
                                Download
                            </button>
                        </mat-menu>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="folder-actions" *ngIf="isEditable && !isLoading">
        <div *ngIf="pathRoute.length > 0 " [ngClass]="{'d-none': !['storage', 'share'].includes(pathRoute[0]?.route) }"
            class="filedownload_img tooltip4" (click)="openUpload()">
            <img src="../../../assets/images/NewUI/file_.svg" alt="" />
            <span class="tooltiptext">Upload New File</span>
        </div>
        <div *ngIf="pathRoute.length > 0" [ngClass]="{'d-none': !['storage', 'share'].includes(pathRoute[0]?.route) }"
            class="filedownload_img tooltip4" (click)="openCreate()">
            <img src="../../../assets/images/NewUI/folder_.svg" alt="" />
            <span class="tooltiptext">Create New Folder</span>
        </div>
        <div [ngClass]="{'d-none': folder.length <= 1}" (click)="downloadFolderAction()" class="file-download tooltip4">
            <img src="../../../assets/images/download-new.svg" alt="" />
            <span class="tooltiptext">Download Folder</span>
        </div>
    </div>
</div>