import { ArtifactStatusEnum } from "./artifact-status.enum";

export enum TestStatusEnum {
    PROGRESS = 'PROGRESS',
    FINALIZING = 'FINALIZING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
}

export enum TestResultEnum {
    PASS = 'PASS',
    FAIL = 'FAIL'
}

export const TestStatusDisplayMap = new Map<TestStatusEnum | TestResultEnum | ArtifactStatusEnum, string>([
    [TestStatusEnum.PROGRESS, 'In progress'],
    [TestStatusEnum.FINALIZING, 'Finalizing'],
    [TestStatusEnum.COMPLETED, 'Completed'],
    [TestStatusEnum.FAILED, 'Failed'],
    [TestStatusEnum.CANCELED, 'Canceled'],
    [TestResultEnum.PASS, 'Passed'],
    [TestResultEnum.FAIL, 'Failed'],
    [ArtifactStatusEnum.PROGRESS, 'In progress'],
    [ArtifactStatusEnum.FINALIZING, 'Finalizing'],
    [ArtifactStatusEnum.COMPLETED, 'Completed'],
    [ArtifactStatusEnum.FAILED, 'Failed'],
    [ArtifactStatusEnum.CANCELED, 'Canceled'],
    [ArtifactStatusEnum.REMOVED, 'Removed'],
    [ArtifactStatusEnum.COLLECTED, 'Collected'],
]);
