import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MomtIterationStatus, MomtIterationStatusMap, MomtTestIteration} from '../../interfaces';
import {TooltipService} from '../../../../shared/tooltip.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-speed-test-data',
  templateUrl: './speed-test-data.component.html',
  styleUrls: ['./speed-test-data.component.scss']
})
export class SpeedTestDataComponent implements OnInit {
  @Input() iterations: MomtTestIteration[];
  expandedRows = new Set();
  tabs = [];
  tooltipComponent = null;

  constructor(private tooltipService: TooltipService,
              private _snackBar: MatSnackBar) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    if (this.iterations?.length) {
      this.tabs = this.iterations.map(() => true);
    }
  }

  displayIterationStatus(status: MomtIterationStatus): string {
    return MomtIterationStatusMap.get(status);
  }

  handleRowSelection(index: number) {
    if (this.expandedRows.has(index)) {
      this.expandedRows.delete(index);
    } else {
      this.expandedRows.add(index);
    }
  }

  openTab(index: number): void {
    this.tabs[index] = !this.tabs[index];
  }

  copyRawData(iterationIndex: number) {
    const str = this.iterations[iterationIndex].actions[0].input.message;
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this._snackBar.open('Copied to clipboard', '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['success'],
    });
  }
}
