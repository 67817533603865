<img alt="" src="../../../../../assets/images/NewUI/chevron-down.svg" class="switch" [ngClass]="{open: isOpen}"
    (click)="switchView()">
<div *ngIf="isOpen" class="info-dropdown">
    <div class="device-info">
        <div class="device-img">
            <img *ngIf="deviceInfo?.oem == 'Apple'" src="../../../../../assets/images/NewUI/ip12.jpeg"
                alt="">
            <img *ngIf="deviceInfo?.oem !== 'Apple'" src="../../../../../assets/images/NewUI/android.png"
                alt="">
        </div>
        <div class="device-info-details">
            <div class="info-header">
                <div class="device-name">
                    <h4>{{deviceInfo?.modelName}}</h4>
                    <p class="ios" *ngIf="deviceInfo?.oem == 'Apple'">iOS {{deviceInfo?.osVersion}}</p>
                    <p class="ios" *ngIf="deviceInfo?.oem !== 'Apple'">Android {{deviceInfo?.osVersion}}</p>
                </div>
            </div>
            <div class="device-details">
                <p class="imei">
                    <span class="displayed-text" [tp]="tooltipComponent" tpPlacement="top"
                        [tpOnlyTextOverflow]="true" [tpData]="deviceInfo?.serialNumber | tooltipData"
                        [tpMaxWidth]="450" [tpInteractive]="false">
                        {{deviceInfo?.oem == 'Apple' ? 'UUID': 'Serial No'}}: {{deviceInfo?.serialNumber}}
                    </span>
                </p>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button class="snap-button secondary select-button" (click)="customize()">Customize</button>
        <button class="snap-button secondary select-button" (click)="change()">Change</button>
        <button class="snap-button primary-solid select-button" (click)="release()">Release</button>
    </div>
</div>