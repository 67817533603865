<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title">
  <h1>
    Invoices
    <div class="total-labs" *ngIf="userData?.role !== 'ADMIN'">
      {{totalCorporate || 0}}
    </div>
  </h1>
  <div class="filters" *ngIf="totalCorporate > 0">
    <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field" placeholder="Search"
      (change)="searchChange()" />
    <div class="filter-icon">
      <span class="icon" [ngClass]="{ active: isFiltered }" (click)="openFilters(!isFilterOpen)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>
      <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen, corporate: userData?.role !== 'ADMIN' }">
        <select *ngIf="userData?.role === 'ADMIN'" class="filter-select form-control" [(ngModel)]="tempSearchEnterprise"
          required>
          <option value="">Select Enterprise</option>
          <option *ngFor="let option of enterpriseOptions" [value]="option.id">{{option.name}}</option>
        </select>
        <select class="filter-select form-control" [(ngModel)]="tempSearchEngagement" required>
          <option value="">Select Engagement Type</option>
          <option value="true">Ongoing</option>
          <option value="false">Intermittent</option>
        </select>
        <ng-multiselect-dropdown [placeholder]="'Select Features'" [settings]="getDropDownSettings(1)"
          [data]="featureList" [(ngModel)]="tempSearchFeatures" class="multiselect">
        </ng-multiselect-dropdown>
        <ul class="tab-container">
          <li (click)="setDateFilter(FilterType.TODAY)" [ngClass]="{'active': tempDateFilter === FilterType.TODAY }">
            Today
          </li>
          <li (click)="setDateFilter(FilterType.LAST_WEEK)"
            [ngClass]="{'active': tempDateFilter === FilterType.LAST_WEEK }">
            Last Week
          </li>
          <li (click)="setDateFilter(FilterType.CUSTOM)" [ngClass]="{'active': tempDateFilter === FilterType.CUSTOM }">
            <span>
              Custom
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17"
                height="14.3" viewBox="0 0 17.039 14.308" fill="currentColor">
                <g id="Group_1142" data-name="Group 1142" transform="translate(-7697.717 -1509.119)">
                  <path id="Path_6" data-name="Path 6"
                    d="M7709.212,1511.652a.452.452,0,0,0,.139.021h.015a.458.458,0,0,0,.14-.021.472.472,0,0,0,.333-.452v-1.608a.472.472,0,0,0-.333-.452.458.458,0,0,0-.14-.021h-.015a.452.452,0,0,0-.139.021.471.471,0,0,0-.332.452v1.608A.471.471,0,0,0,7709.212,1511.652Z" />
                  <rect id="Rectangle_13" data-name="Rectangle 13" width="0.959" height="2.554" rx="0.475"
                    transform="translate(7700.542 1509.119)" />
                  <path id="Path_7" data-name="Path 7"
                    d="M7698.63,1520.778v-7.371h13.148v3.224a3.5,3.5,0,0,1,.886.175v-4.754a1.148,1.148,0,0,0-1.145-1.146h-1.276v.294a.878.878,0,0,1-.877.877h-.015a.877.877,0,0,1-.875-.877v-.294h-6.564v.294a.88.88,0,0,1-.879.877h-.014a.876.876,0,0,1-.874-.877v-.294h-1.28a1.15,1.15,0,0,0-1.148,1.146v8.472a1.151,1.151,0,0,0,1.148,1.147h9.4a3.486,3.486,0,0,1-.268-.893Z" />
                  <path id="Path_8" data-name="Path 8"
                    d="M7701.585,1515.553a1.023,1.023,0,0,0-1.023-1.023h0a1.024,1.024,0,0,0-1.024,1.023h0a1.024,1.024,0,0,0,1.024,1.023h0a1.023,1.023,0,0,0,1.023-1.023Z" />
                  <rect id="Rectangle_14" data-name="Rectangle 14" width="2.046" height="2.046"
                    transform="translate(7702.625 1514.53)" />
                  <rect id="Rectangle_15" data-name="Rectangle 15" width="2.046" height="2.046"
                    transform="translate(7699.539 1517.607)" />
                  <rect id="Rectangle_16" data-name="Rectangle 16" width="2.046" height="2.046"
                    transform="translate(7702.625 1517.607)" />
                  <rect id="Rectangle_17" data-name="Rectangle 17" width="2.046" height="2.046"
                    transform="translate(7705.712 1517.607)" />
                  <rect id="Rectangle_18" data-name="Rectangle 18" width="2.046" height="2.046"
                    transform="translate(7705.712 1514.53)" />
                  <rect id="Rectangle_19" data-name="Rectangle 19" width="2.046" height="2.046"
                    transform="translate(7708.799 1514.53)" />
                  <path id="Path_9" data-name="Path 9"
                    d="M7711.592,1517.1a3.164,3.164,0,1,0,3.164,3.164A3.164,3.164,0,0,0,7711.592,1517.1Zm0,5.636a2.472,2.472,0,1,1,2.471-2.472A2.474,2.474,0,0,1,7711.592,1522.735Z" />
                  <path id="Path_10" data-name="Path 10"
                    d="M7713.12,1520.188h-1.382v-1.622a.247.247,0,1,0-.494,0v2.081c0,.137.076.233.358.233h1.518a.346.346,0,0,0,0-.692Z" />
                </g>
              </svg>
              <div class="range-picker-cover">
                <mat-date-range-input [rangePicker]="rangePicker">
                  <input matStartDate (dateChange)="updateDateFilter('start', $event)">
                  <input matEndDate (dateChange)="updateDateFilter('end', $event)">
                </mat-date-range-input>
              </div>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </span>
          </li>
        </ul>
        <div class="filter-buttons">
          <button class="snap-button tertiary" (click)="openFilters(false)">Cancel</button>
          <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
          <button class="snap-button primary" (click)="applyFilters()">Filter</button>
        </div>
      </div>
    </div>
    <button *ngIf="userData?.role === 'ADMIN'" class="snap-button primary create-new-button"
      (click)="openCreate()">Create a new invoice</button>
  </div>
</div>
<div class="tabs" *ngIf="userData?.role === 'ADMIN'">
  <span [ngClass]="{ active: activeTab === 'public'}" (click)="openTab('public')">
    Public
    <div class="total-labs">
      {{totalPublic || 0}}
    </div>
  </span>
  <span [ngClass]="{ active: activeTab === 'corporate'}" (click)="openTab('corporate')">
    Corporate
    <div class="total-labs">
      {{totalCorporate || 0}}
    </div>
  </span>
</div>

<div class="empty-result" *ngIf="!isFiltered && invoicesList?.length === 0 && !isLoading && activeTab === 'corporate'">
  <img src="../../../../../assets/images/NewUI/invoice-empty.svg" alt="">
  <span class="empty-search-title">No invoices were found</span>
  <span *ngIf="userData?.role === 'ADMIN'">
    Create a new invoice. Add the required information, subscription, cost, comments.
  </span>
  <button *ngIf="userData?.role === 'ADMIN'" class="snap-button primary create-new-button"
    routerLink="/admin/create-invoice">
    Create a new invoice
  </button>
</div>
<div class="empty-result" *ngIf="isFiltered && invoicesList?.length === 0 && !isLoading && activeTab === 'corporate'">
  <img alt="" src="../../../../assets/images/NewUI/menu-lab-large.svg">
  <span class="empty-search-title">No invoices were found</span>
  <span>
    No results matched your criteria. Try adjusting your search or filter options to find what you are looking
    for.
  </span>
  <span (click)="clearFilters()" class="empty-search-action">Clear filters</span>
</div>
<app-invoice-list [ngClass]="{'display-none': activeTab !== 'public'}" (totalPublic)="getTotalPublic($event)"></app-invoice-list>
<app-invoice-list-corporate *ngIf="activeTab === 'corporate'"
  [invoicesList]="invoicesList"></app-invoice-list-corporate>
<div class="pagination-container" *ngIf="invoicesList?.length > 0 && activeTab === 'corporate'">
  <span class="limit">
    <label for="limit">Rows per page: </label>
    <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </span>
  <span>{{paginationText}}</span>
  <div class="pagination-buttons">
    <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
      <img alt="" src="../../../../assets/images/arrow_back.svg">
    </div>
    <div [ngClass]="{ disabled: currentPage + 1 >= totalPages }" class="arrow-button" (click)="changePage(1)">
      <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
    </div>
  </div>
</div>
