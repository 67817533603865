import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminMangementComponent } from './admin-mangement/admin-mangement.component';
import { AlertListComponent } from './admin-mangement/alert-list/alert-list.component';
import { CreateLabComponent } from './admin-mangement/create-lab/create-lab.component';
import { DashboardLabComponent } from './admin-mangement/dashboard-lab/dashboard-lab.component';
import { DashboardComponent } from './admin-mangement/dashboard/dashboard.component';
import { DeviceListComponent } from './admin-mangement/device-list/device-list.component';
import { LabListComponent } from './admin-mangement/lab-list/lab-list.component';
import { MainComponent } from './main.component';
import { SupportSectionComponent } from './support-section/support-section.component';
import { RelaseNotesComponent } from './relase-notes/relase-notes.component';
import { MomtMainViewComponent } from './momt-call/momt-main-view/momt-main-view.component';
import { MomtNewTestComponent } from './momt-call/momt-new-test/momt-new-test.component';
import { MomtTestReportComponent } from './momt-call/momt-test-report/momt-test-report.component';
import { PendingChangesGuard } from '../guards/pending-changes-guard';
import { UpdateLabComponent } from './admin-mangement/update-lab/update-lab.component';
import { SubscriptionListComponent } from './admin-mangement/subscription-list/subscription-list.component';
import { CreateSubscriptionComponent } from './admin-mangement/create-subscription/create-subscription.component';
import { UpdateSubscriptionComponent } from './admin-mangement/update-subscription/update-subscription.component';
import { AuthGuardMain } from '../guards/auth-guard';
import { UserManagementGuard } from '../guards/user-management-guard';
import { AdminGuard } from '../guards/admin-guard';
import { CreateCorporateUserComponent } from './admin-mangement/users/create-corprorate-user/create-corporate-user.component';
import { UserManagementComponent } from './admin-mangement/users/user-management.component';
import { UserListComponent } from './admin-mangement/users/user-list/user-list.component';
import { UpdateCorporateUserComponent } from './admin-mangement/users/update-corporate-user/update-corporate-user.component';
import { CreatePublicUserComponent } from './admin-mangement/users/create-public-user/create-public-user.component';
import { CreateSupportUserComponent } from './admin-mangement/users/create-support-user/create-support-user.component';
import { UpdateSupportUserComponent } from './admin-mangement/users/update-support-user/update-support-user.component';
import { UpdatePublicUserComponent } from './admin-mangement/users/update-public-user/update-public-user.component';
import { LabManagementGuard } from '../guards/lab-management-guard';
import { RnpTableViewComponent } from './record-and-play/rnp-table-view/rnp-table-view.component';
import { RnpTestReportComponent } from './record-and-play/rnp-test-report/rnp-test-report.component';
import { RnpNewTestComponent } from './record-and-play/rnp-new-test/rnp-new-test.component';
import { EnterpriseManagementComponent } from './admin-mangement/enterprises/enterprise-management.component';
import { EnterpriseListComponent } from './admin-mangement/enterprises/enterprise-list/enterprise-list.component';
import { CreateEnterpriseComponent } from './admin-mangement/enterprises/create-enterprise/create-enterprise.component';
import { UpdateEnterpriseComponent } from './admin-mangement/enterprises/update-enterprise/update-enterprise.component';
import { EnterpriseManagementGuard } from '../guards/enterprise-admin-guard';
import { ViewEnterpriseComponent } from './admin-mangement/enterprises/view-enterprise/view-enterprise.component';
import { ManualTestComponent } from './manual-test/manual-test.component';
import { InvoiceComponent } from './admin-mangement/invoices/invoice.component';
import { CreateInvoiceComponent } from './admin-mangement/invoices/create-invoice/create-invoice.component';
import { UpdateInvoiceComponent } from './admin-mangement/invoices/update-invoice/update-invoice.component';
import { ViewInvoiceComponent } from './admin-mangement/invoices/view-invoice/view-invoice.component';
import {BookingManagerComponent} from './booking-manager/booking-manager/booking-manager.component';
import { CustomTableViewComponent } from './custom-test/custom-table-view/custom-table-view.component';
import { CustomTestReportComponent } from './custom-test/custom-test-report/custom-test-report.component';
import { CustomNewTestComponent } from './custom-test/custom-new-test/custom-new-test.component';
import { NewCustomTestCaseComponent } from './custom-test/new-custom-test-case/new-custom-test-case.component';

const routes: Routes = [
  {
    path: 'snap',
    component: MainComponent,
    // data: { state: 'user' } ,
    canActivate: [AuthGuardMain],
    children: [
      { path: 'support', component: SupportSectionComponent },
      { path: 'change-logs', component: RelaseNotesComponent },
      { path: 'record-and-play', component: RnpTableViewComponent },
      { path: 'rnp-test-report', component: RnpTestReportComponent },
      { path: 'rnp-new-test', component: RnpNewTestComponent },
      { path: 'booking-manager', component: BookingManagerComponent },
      { path: 'manual-test', component: ManualTestComponent, canDeactivate: [PendingChangesGuard] },
      {
        path: 'profile',
        loadChildren: () =>
          import('../../app/main/profile/profile.module').then((m) => m.ProfileModule),
        // data: { state: 'user' }
      },
      {
        path: 'support',
        loadChildren: () =>
          import('../../app/main/report/report.module').then((m) => m.ReportModule),
        // data: { state: 'user' }
      },
      {
        path: 'current',
        loadChildren: () =>
          import('../main/view-device/view-device.module').then((m) => m.ViewDeviceModule),
      },
      {
        path: 'homepage',
        loadChildren: () =>
          import('../../app/main/device-homepage/device-homepage.module').then((m) => m.DeviceHomepageModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('../../app/main/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../../app/main/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'enterprises',
        loadChildren: () =>
          import('../../app/main/enterprise-management/enterprise-management.module').then((m) => m.EnterpriseManagementModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('../../app/main/new-admin/new-admin.module').then((m) => m.NewAdminModule),
      },
      {
        path: 'testing',
        loadChildren: () =>
          import('../../app/main/testing-manager/testing-manager.module').then((m) => m.TestingManagerModule),
      },
      {
        path: 'cloud',
        loadChildren: () =>
          import('../../app/main/cloud-management/cloud-management.module').then((m) => m.CloudManagementModule),
      },
      { path: 'custom-tests', component: CustomTableViewComponent },
      { path: 'custom-test-report', component: CustomTestReportComponent },
      { path: 'custom-new-test', component: CustomNewTestComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'customize-test-case', component: NewCustomTestCaseComponent },
    ],
  },
  {
    path: 'admin',
    component: AdminMangementComponent,
    canActivate: [AuthGuardMain],
    data: { state: 'admin' },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'device', component: DeviceListComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboard-lab', component: DashboardLabComponent },
      { path: 'labs', component: LabListComponent, canActivate: [LabManagementGuard] },
      // {
      //   path: 'lab-management', component: LabMangementComponent,
      //   children: [
      //     { path: '', redirectTo: 'device-list', pathMatch: 'full' },
      //     { path: 'device-list', component: DeviceListComponent },
      //     { path: 'labs', component: LabListComponent },
      //     { path: 'device-request', component: DeviceRequestListComponent },
      //   ]
      // },
      {
        path: 'users', component: UserManagementComponent,
        canActivate: [UserManagementGuard],
        children: [
          { path: '', component: UserListComponent },
          { path: 'create-user', component: CreatePublicUserComponent, canActivate: [AdminGuard] },
          { path: 'update-user', component: UpdatePublicUserComponent, canActivate: [AdminGuard] },
          { path: 'create-admin', component: CreateSupportUserComponent, canActivate: [AdminGuard] },
          { path: 'update-admin', component: UpdateSupportUserComponent, canActivate: [AdminGuard] },
          { path: 'create-corporate', component: CreateCorporateUserComponent },
          { path: 'update-corporate', component: UpdateCorporateUserComponent }
        ]
      },
      { path: 'alert-list', component: AlertListComponent },
      { path: 'create-lab', component: CreateLabComponent, canActivate: [LabManagementGuard] },
      { path: 'update-lab', component: UpdateLabComponent, canActivate: [LabManagementGuard] },
      { path: 'telephony', component: MomtMainViewComponent },
      { path: 'telephony-report', component: MomtTestReportComponent },
      { path: 'telephony-new-test', component: MomtNewTestComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'subscriptions', component: SubscriptionListComponent, canActivate: [AdminGuard] },
      { path: 'create-subscription', component: CreateSubscriptionComponent, canActivate: [AdminGuard] },
      { path: 'update-subscription', component: UpdateSubscriptionComponent, canActivate: [AdminGuard] },
      {
        path: 'enterprises', component: EnterpriseManagementComponent,
        children: [
          { path: '', component: EnterpriseListComponent, canActivate: [AdminGuard] },
          { path: 'create-enterprise', component: CreateEnterpriseComponent, canActivate: [AdminGuard] },
          { path: 'update-enterprise', component: UpdateEnterpriseComponent, canActivate: [AdminGuard] },
          { path: 'view', component: ViewEnterpriseComponent, canActivate: [EnterpriseManagementGuard] },
        ]
      },
      { path: 'invoices', component: InvoiceComponent, canActivate: [EnterpriseManagementGuard] },
      { path: 'create-invoice', component: CreateInvoiceComponent, canActivate: [AdminGuard] },
      { path: 'update-invoice', component: UpdateInvoiceComponent, canActivate: [AdminGuard] },
      { path: 'view-invoice', component: ViewInvoiceComponent, canActivate: [EnterpriseManagementGuard] },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule { }
