import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface NotificationDialogProps {
  header: string;
  content: string;
  accept: string;
}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  dialogHeader = '';
  dialogText = '';
  dialogActionAccept = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationDialogProps,
    public dialogRef: MatDialogRef<NotificationDialogComponent>) { }

  ngOnInit(): void {
    this.dialogHeader = this.data.header;
    this.dialogText = this.data.content;
    this.dialogActionAccept = this.data.accept;
  }

  accept(): void {
    this.close('accept');
  }

  close(msg: string): void {
    this.dialogRef.close(msg);
  }
}
