import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipService } from '../../../../shared/tooltip.service';
import { CommonService } from '../../../../shared/common.service';
import { TokenData } from '../../../../shared/interfaces';

@Component({
    selector: 'app-invoice-list-corporate',
    templateUrl: './invoice-list-corporate.component.html',
    styleUrls: ['./invoice-list-corporate.component.scss']
})
export class InvoiceListCorporateComponent implements OnInit {
    @Input() invoicesList = [];
    tooltipComponent = null;
    isLoading = 0;
    infinityDate = 8500000000000;
    userData: TokenData;

    constructor(
        public router: Router,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
    }

    openEdit(invoiceID: string) {
        this.navigatePage('/admin/update-invoice', { id: invoiceID });
    }

    openView(invoiceID: string) {
        this.navigatePage('/admin/view-invoice', { id: invoiceID });
    }

    navigatePage(path: string, query?: Record<string, string>) {
        this.router.navigate([path], { queryParams: query });
    }

    getFeaturesNames(id: string) {
        const selectedInvoice = this.invoicesList.find(invoice => invoice.id === id);
        if (selectedInvoice) {
            if (selectedInvoice?.subscription?.features) {
                return selectedInvoice?.subscription?.features.map(el => el.name);
            }
        }
        return [];
    }
}
