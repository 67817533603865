<mat-accordion multi>
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let iteration of iterations; index as i"
        [expanded]="expandedRows.has(i) || iteration.status === 'EXECUTING'">
        <mat-expansion-panel-header (click)="handleRowSelection(i)">
            <div class="iteration">
                <mat-panel-title class="iteration_title">
                    Iteration {{iteration.index}}
                </mat-panel-title>
                <mat-spinner diameter="24" *ngIf="iteration.status === 'EXECUTING'"></mat-spinner>
                <mat-panel-description class="iteration_item flex-grow"
                    [ngClass]="{'passed': iteration.status === 'COMPLETED' || iteration.status === 'PASSED', 'failed': iteration.status === 'FAILED', 'canceled': iteration.status === 'CANCELED'}">
                    <span class="iteration_status"
                        *ngIf="iteration.status !== 'IDLE' && iteration.status !== 'EXECUTING'">{{displayIterationStatus(iteration.status)}}</span>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>

        <div class="steps_table" [style.--device-verifications]="verificationsAmount"
            [ngClass]="{expanded_view: isExpanded}">
            <thead>
                <tr>
                    <th>Action</th>
                    <th *ngIf="devices[0] && !devices[0].noVerifications">Device {{devices[0].index}}</th>
                    <th *ngIf="devices[1] && !devices[1].noVerifications">Device {{devices[1].index}}</th>
                    <th *ngIf="devices[2] && !devices[2].noVerifications">Device {{devices[2].index}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="action">
                        <div class="action_content">
                            <span class="action_icon" [ngClass]="{'passed': iteration.status !== 'IDLE'}">
                                <img class="icon"
                                    src="../../../../../assets/images/telephony-actions/start{{iteration.status !== 'IDLE' ? '_active' : ''}}.svg"
                                    alt="">
                            </span>
                            <span>00:00:000</span>
                            <div class="action_grid">
                                <span>Start</span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="devices[0] && !devices[0].noVerifications" class="no_validator"></td>
                    <td *ngIf="devices[1] && !devices[1].noVerifications" class="no_validator"></td>
                    <td *ngIf="devices[2] && !devices[2].noVerifications" class="no_validator"></td>
                </tr>
                <tr *ngFor="let step of iteration.steps">
                    <td class="action" *ngIf="step.action.category === 'ACT'">
                        <div class="action_content">
                            <span class="action_icon"
                                [ngClass]="{'passed': step.status === 'COMPLETED', 'failed': step.status === 'FAILED'}">
                                <img class="icon"
                                    src="../../../../assets/images/telephony-actions/{{getActionIconName(step.action.data.type)}}{{step.status === 'COMPLETED' || step.status === 'FAILED' ? '_active' : ''}}.svg"
                                    alt="">
                            </span>
                            <span class="time_lapsed">{{getActionTime(step, iteration) | date:'mm:ss:SSS'}}</span>
                            <div class="action_grid">
                                Device {{getActionSource(step.action)}}
                                <span class="centered_cell">
                                    {{displayActionType(step.action.data.type)}}
                                    <span *ngIf="getActionTarget(step.action)">
                                        Device {{getActionTarget(step.action)}}
                                    </span>
                                </span>
                                <span class="timeout">{{getActionTimer(step.action)}} s</span>
                            </div>
                            <mat-spinner diameter="24" *ngIf="step.status === 'EXECUTING'"
                                class="spinner"></mat-spinner>
                        </div>
                        <div class="step_error" *ngIf="step.error">
                            <span>ERROR</span>
                            <span class="error_message">{{step.error}}</span>
                        </div>
                    </td>
                    <td class="action" *ngIf="step.action.category === 'WAIT'">
                        <div class="action_content">
                            <span class="action_icon"
                                [ngClass]="{'passed': step.status === 'COMPLETED', 'failed': step.status === 'FAILED'}">
                                <mat-icon class="icon">hourglass_empty</mat-icon>
                            </span>
                            <span class="time_lapsed">{{getActionTime(step, iteration) | date:'mm:ss:SSS'}}</span>
                            <div class="action_grid">
                                <span>Wait for</span>
                                <span class="centered_cell">{{displayActionType(step.action.data.type)}}</span>
                                <span class="timeout">{{getActionTimer(step.action)}} s</span>
                            </div>
                            <mat-spinner diameter="24" *ngIf="step.status === 'EXECUTING'" class="spinner"></mat-spinner>
                        </div>
                        <div class="step_error" *ngIf="step.error">
                            <span>ERROR</span>
                            <span class="error_message">{{step.error}}</span>
                        </div>
                    </td>
                    <td *ngIf="devices[0] && !devices[0].noVerifications"
                        [ngClass]="{no_validator: !getStepValidatorStatus(step, 1)}">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(getStepValidatorStatus(step, 1))" class="verification_status"></span>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 1) === 'COMPLETED'"
                            class="verification_status verification_passed">done</mat-icon>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 1) === 'FAILED'"
                            class="verification_status verification_failed">close</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>{{displayValidatorType(step, 1)}}</span>
                            <span class="centered_cell">{{displayValidatorState(step, 1)}}</span>
                            <span class="timeout">{{displayValidatorTime(step, 1)}}</span>
                        </div>
                    </td>
                    <td *ngIf="devices[1] && !devices[1].noVerifications"
                        [ngClass]="{no_validator: !getStepValidatorStatus(step, 2)}">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(getStepValidatorStatus(step, 2))" class="verification_status"></span>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 2) === 'COMPLETED'"
                            class="verification_status verification_passed">done</mat-icon>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 2) === 'FAILED'"
                            class="verification_status verification_failed">close</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>{{displayValidatorType(step, 2)}}</span>
                            <span class="centered_cell">{{displayValidatorState(step, 2)}}</span>
                            <span class="timeout">{{displayValidatorTime(step, 2)}}</span>
                        </div>
                    </td>
                    <td *ngIf="devices[2] && !devices[2].noVerifications"
                        [ngClass]="{no_validator: !getStepValidatorStatus(step, 3)}">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(getStepValidatorStatus(step, 3))" class="verification_status"></span>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 3) === 'COMPLETED'"
                            class="verification_status verification_passed">done</mat-icon>
                        <mat-icon *ngIf="getStepValidatorStatus(step, 3) === 'FAILED'"
                            class="verification_status verification_failed">close</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>{{displayValidatorType(step, 3)}}</span>
                            <span class="centered_cell">{{displayValidatorState(step, 3)}}</span>
                            <span class="timeout">{{displayValidatorTime(step, 3)}}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="action">
                        <div class="action_content">
                            <span class="action_icon"
                                [ngClass]="{'passed': ['COMPLETED', 'FAILED'].includes(iteration.status)}">
                                <mat-icon class="icon">cleaning_services</mat-icon>
                            </span>
                            <span>{{getCleanupTime(iteration) | date:'mm:ss:SSS'}}</span>
                            <div class="action_grid">
                                <span>Clean up</span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="devices[0] && !devices[0].noVerifications">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(iteration.status)"
                            class="verification_status"></span>
                        <mat-icon class="verification_status verification_passed"
                            *ngIf="['COMPLETED', 'FAILED'].includes(iteration.status)">done</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>Changed to</span>
                            <span class="centered_cell">Idle</span>
                            <span class="timeout">5s</span>
                        </div>
                    </td>
                    <td *ngIf="devices[1] && !devices[1].noVerifications">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(iteration.status)"
                            class="verification_status"></span>
                        <mat-icon class="verification_status verification_passed"
                            *ngIf="['COMPLETED', 'FAILED'].includes(iteration.status)">done</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>Changed to</span>
                            <span class="centered_cell">Idle</span>
                            <span class="timeout">5s</span>
                        </div>
                    </td>
                    <td *ngIf="devices[2] && !devices[2].noVerifications">
                        <span *ngIf="!['COMPLETED', 'FAILED'].includes(iteration.status)"
                            class="verification_status"></span>
                        <mat-icon class="verification_status verification_passed"
                            *ngIf="['COMPLETED', 'FAILED'].includes(iteration.status)">done</mat-icon>
                        <div *ngIf="isExpanded" class="action_grid verification_grid">
                            <span>Changed to</span>
                            <span class="centered_cell">Idle</span>
                            <span class="timeout">5s</span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="iteration.index < totalIterations">
                    <td class="action">
                        <div class="action_content">
                            <span class="action_icon"
                                [ngClass]="{'passed': ['COMPLETED', 'FAILED'].includes(iteration.status) && !isDelayActive(iteration)}">
                                <img class="icon"
                                    src="../../../../../assets/images/telephony-actions/delay{{['COMPLETED', 'FAILED'].includes(iteration.status) && !isDelayActive(iteration) ? '_active' : ''}}.svg"
                                    alt="">
                            </span>
                            <span>{{getDelayTime(iteration) | date:'mm:ss:SSS'}}</span>
                            <div class="action_grid">
                                <span>Delay</span>
                            </div>
                            <mat-spinner diameter="24" *ngIf="isDelayActive(iteration)" class="spinner"></mat-spinner>
                        </div>
                    </td>
                    <td *ngIf="devices[0] && !devices[0].noVerifications" class="no_validator"></td>
                    <td *ngIf="devices[1] && !devices[1].noVerifications" class="no_validator"></td>
                    <td *ngIf="devices[2] && !devices[2].noVerifications" class="no_validator"></td>
                </tr>
            </tbody>
        </div>
    </mat-expansion-panel>
</mat-accordion>
