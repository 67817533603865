<div class="header-wrapper">
    <span>{{dialogHeader}}</span>
    <svg (click)="close('cancel')" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
        <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
    </svg>
</div>
<div mat-dialog-content class="dialog-content">
    <p>{{dialogText}}</p>
</div>
<div class="dialog-actions">
    <button class="btn control_btn_new" (click)="accept()">{{dialogActionAccept}}</button>
</div>
