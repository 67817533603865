import { Injectable } from "@angular/core";
import { NetworkService } from "../../shared/network.service";
import { GetIterationListDto, GetMomtListDto, GetTestCasesDto, NewTelephonyDto } from "./dto";
import { Observable } from "rxjs";
import { ApiResponseDto } from "../../shared/dto";
import { MomtListQuery, MomtTest } from "./interfaces";
import { stateTooltip } from "../device-homepage/device-homepage-management-new/device-homepage-management.component-new";
import { TelephonyTestCase, TelephonyTestType } from "./interfaces/momt-test.interface";
import {SpeedTestDto} from './dto/new-momt.dto';

@Injectable({
    providedIn: 'root'
})
export class MomtCallService {
    constructor(private networkService: NetworkService) { }

    saveTest(form: NewTelephonyDto): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post('api/automation/telephony/tests', form, null, 'basic');
    }

    updateTest(id: string, form: NewTelephonyDto): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.put(`api/automation/telephony/tests/${id}`, form, null, 'basic');
    }

    createAndStartTest(form: NewTelephonyDto | SpeedTestDto): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post('api/automation/telephony/tests/start', form, null, 'basic');
    }

    startTest(id: string, form: NewTelephonyDto | SpeedTestDto): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post(`api/automation/telephony/tests/${id}/start`, form, null, 'basic');
    }

    cancelTest(id: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post(`api/automation/telephony/tests/${id}/cancel`, null, null, 'basic');
    }

    deleteTest(id: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.delete(`api/automation/telephony/tests?momtIds=${id}`, null, null, 'basic');
    }

    getTestList(query: MomtListQuery): Observable<ApiResponseDto<GetMomtListDto>> {
        const { page, size, startDate, endDate, searchValue, statuses } = query;
        return this.networkService.get(
            `api/automation/telephony/tests?page=${page}&size=${size}${startDate ? `&startAt=${startDate}` : ''}${endDate ? `&endAt=${endDate}` : ''}${searchValue ? `&search=${searchValue}` : ''}${statuses.length ? `&statuses=${statuses}` : ''}`,
            null,
            null,
            'basic'
        );
    }

    getTestInfo(id: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.get(`api/automation/telephony/tests/${id}`, null, null, 'basic');
    }

    getTestIterations(id: string, page: number, size: number, sort = 'order,asc'): Observable<ApiResponseDto<GetIterationListDto>> {
        return this.networkService.get(`api/automation/telephony/tests/${id}/plays?page=${page}&size=${size}&sort=${sort}`, null, null, 'basic');
    }

    cancelArtifact(artifactId: string, testId: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post(`api/automation/telephony/tests/${testId}/artifacts/cancel?artifactId=${artifactId}`, null, null, 'basic');
    }

    removeArtifact(artifactId: string, testId: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService.post(`api/automation/telephony/tests/${testId}/artifacts/remove?artifactId=${artifactId}`, null, null, 'basic');
    }

    downloadAllArtifacts(testId: string): Observable<any> {
        return this.networkService.getZip(`api/automation/telephony/tests/${testId}/download-zip`, null, null, null);
    }

    deleteArtifactViaCloud(testName: string, artifactName: string): void {
        this.networkService
            .post(`api/automation/telephony/tests/${testName}/artifacts/remove/cloud?artifactName=${artifactName}`, null, null, 'basic')
            .subscribe();
    }

    renameArtifactViaCloud(testName: string, artifactName: string, newName: string): Observable<ApiResponseDto<MomtTest>> {
        return this.networkService
            .post(`api/automation/telephony/tests/${testName}/artifacts/rename/cloud?artifactName=${artifactName}`, { newName }, null, 'basic')
    }

    getFileDownloadApi(queryPram: any) {
        return this.networkService.get('api/file-storage/download?' + queryPram, null, null, 'basic');
    }

    getTestCaseList(query: string): Observable<GetTestCasesDto> {
        return this.networkService.get('api/automation/telephony/test-cases?' + query, null, null, 'basic');
    }

    getDeviceStatus(data: any): string {
        const state = data.deviceState;
        const stateCode = data.deviceStateCode;
        const commStatus = data.deviceCommunicationStatus;
        let displayed = 'unknown';
        if (state) {
            if (state.toLowerCase() === 'online') {
                if (stateCode.toLowerCase() === 'online') {
                    if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
                        displayed = 'testing';
                    } else {
                        displayed = 'online';
                    }
                } else {
                    if (stateTooltip[stateCode.toLowerCase()]) {
                        displayed = stateCode.toLowerCase();
                    }
                }
            } else if (state.toLowerCase() === 'offline') {
                if (stateCode && stateCode !== '') {
                    if (stateCode.toLowerCase() === 'removed') {
                        displayed = 'disconnected';
                    } else {
                        if (stateTooltip[stateCode.toLowerCase()]) {
                            displayed = stateCode.toLowerCase();
                        }
                    }
                }
            } else if (state.toLowerCase() === 'blocked') {
                displayed = state.toLowerCase();
            }
        }
        return displayed;
    }

    getTestCaseEstimate(testCase: TelephonyTestCase): number {
        if (testCase.type === TelephonyTestType.MT_ANSWERS) return 80000;
        if (testCase.type === TelephonyTestType.MO_SMS) return 5000;
        return testCase.actions?.reduce((acc, action) => {
            acc += action.data.timestamp;
            return acc;
        }, 0);
    }

    getIPerfServers(): Observable<any> {
        return this.networkService.get(`api/iperf/servers`, null, null, 'basic');
    }
}
