<div class="chat-popup" [@toggle]="state">
    <div class="chat_header position-relative" >
        <div class="chat_header_heading">
            <span>Ask Snap AI</span>
        </div>

        <div class="minimize-ChatBox" [@hideHeader]="state">
            <span (click)="messageArray=[]" class="clear-btn" [ngClass]="{'disabled' : messageArray.length == 0 || loader }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-trash3" viewBox="0 0 16 16">
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
              </svg>
            </span>
        </div>
        <div class="minimize-ChatBox">
            <p style="font-size: 22px; line-height: 22px; cursor: pointer;" (click)="toggleChatBox()">_</p>
        </div>
    </div>
    <div class="chat_body">
        <section class="chat_msgsBody">
            <div class="row m-0 mt-3" *ngIf="messageArray.length == 0">
                <p class="chat_headContent text-center">Ask Snap AI</p>
                <div class="col-md-12 my-1">
                    <div class="chat_infoDiv" (click)="send('What are the Features included in V1.9.0?')">
                        <p>What are the Features included in V1.9.0?</p>
                    </div>
                </div>
                <div class="col-md-12 my-1">
                    <div class="chat_infoDiv"   (click)="send('Are MO-MT Calls supported?')">
                        <p>Are MO-MT Calls supported?</p>
                    </div>
                </div>
                <div class="col-md-12 my-1">
                    <div class="chat_infoDiv" (click)="send('Show me examples of how Appium Integrations works in SNAP?')">
                        <p>Show me examples of how Appium Integrations works in SNAP?</p>
                    </div>
                </div>
            </div>
            <div *ngFor="let unit of messageArray; index as i" class="pe-2">
                <div class="d-flex justify-content-end" *ngIf="unit.role == 'user'">
                    <div class="chat_msgBoxSent my-2">
                        <p class="m-0">{{ unit.content}}</p>
                    </div>
                </div>
                <div class="d-flex justify-content-start" *ngIf="unit.role == 'assistant'">
                    <div class="chat_msgBoxReceived my-2">
                        <div class="d-flex justify-content-between">
                            <div>
                                <img src="../../../assets/images/chatbot/chat_gpt_icon.svg" width="30px" height="30px" alt="">
                            </div>
                            <div>
                                <i class="bi bi-lightbulb me-3"></i>
                                <i class="bi bi-clipboard-data"></i>
                            </div>
                        </div>
                        <p class="m-0" [innerHTML]="unit.content" *ngIf="i < messageArray.length-1"></p>
                        <p class="m-0" [innerHTML]="answer" *ngIf="i == messageArray.length-1"></p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-start" *ngIf="loader">
                <div class="chat_msgBoxReceived my-2" style="width: 70%">
                    <div class="d-flex justify-content-between">
                        <div>
                            <img src="../../../assets/images/chatbot/chat_gpt_icon.svg" width="30px" height="30px" alt="">
                        </div>
                        <div>
                            <i class="bi bi-lightbulb me-3"></i>
                            <i class="bi bi-clipboard-data"></i>
                        </div>
                    </div>
                    <p class="m-0" >Generating answer <span class="loadingdots"></span> </p>
                </div>
            </div>
            <div id="chat"></div>
        </section>
        <div class="chat_box position-relative">
            <textarea [placeholder]="userMessage == ''? 'Type a new question (e.g. What are the features supported in latest version.)': ''" [(ngModel)]="userMessage" (keydown.enter)="send()"></textarea>
            <form class="position-relative pe-5"></form>
            <button class="chat_sendBtn"  (click)="send()"><img src="../../../assets/images/chatbot/send-icon3.svg" width="20px" alt=""></button>
        </div>
    </div>

    <div class="chatBox_footer">
        <span>Powered by &nbsp; <img src="../../../assets/images/chatbot/Snap_logo.png" alt=""></span>
    </div>
</div>
<!--<div class="chatIcon" (mousedown)="saveMouseDownTime($event)" (mouseup)="checkForLongPress($event)">-->
<!--    <img src="../../../assets/images/chatbot/chat_gpt_icon.svg" width="30px" height="30px" alt="">-->
<!--</div>-->

