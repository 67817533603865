import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ConfigurationService} from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class ChatbotApiService {

    constructor(private httpClient: HttpClient,
                private configService: ConfigurationService) { }
    API_KEY = 'auth_setup';
    public getChat() {

        return this.httpClient.get(`${this.configService.readConfig().chatbotURL}${this.API_KEY}`,  { headers: { 'Content-Type': 'application/json',
                Accept: '*/*'} });
    }
    sendMessage(payload: any): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: '*/*',
        });
        return this.httpClient
            .post(
                this.configService.readConfig().chatbotURL + 'chat',
                JSON.stringify(payload),
                { headers: reqHeader }
            );
    }
}
