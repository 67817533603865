import {fromEvent, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Directive, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Directive({
    selector: '[appFreeDragging]',
    standalone: true
})
export class FreeDraggingDirective implements OnInit, OnDestroy {
    private element: HTMLElement;

    private subscriptions: Subscription[] = [];

    constructor(
        private elementRef: ElementRef,
        @Inject(DOCUMENT) private document: any
    ) {}

    ngOnInit(): void {
        this.element = this.elementRef.nativeElement as HTMLElement;
        this.initDrag();
    }

    initDrag(): void {
        const dragStart$ = fromEvent<MouseEvent>(this.element, 'mousedown');
        const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
        const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
            takeUntil(dragEnd$)
        );

        let initialX: number,
            initialY: number,
            currentX = 0,
            currentY = 0;

        let dragSub: Subscription;

        const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
            initialX = event.clientX - currentX;
            initialY = event.clientY - currentY;
            this.element.classList.add('free-dragging');

            dragSub = drag$.subscribe((event1: MouseEvent) => {
                event1.preventDefault();

                currentX = event1.clientX - initialX;
                currentY = event1.clientY - initialY;

                this.element.style.transform =
                    'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)';
            });
        });

        const dragEndSub = dragEnd$.subscribe(() => {
            initialX = currentX;
            initialY = currentY;
            this.element.classList.remove('free-dragging');
            if (dragSub) {
                dragSub.unsubscribe();
            }
        });

        this.subscriptions.push.apply(this.subscriptions, [
            dragStartSub,
            dragSub,
            dragEndSub,
        ]);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s?.unsubscribe());
    }
}
