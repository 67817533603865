import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { TokenData } from '../../../../shared/interfaces';
import { CommonService } from '../../../../shared/common.service';
import { GetSubscriptionListDto, GetUserListDto } from '../../../../shared/dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-update-public-user',
    templateUrl: './update-public-user.component.html',
    styleUrls: ['./update-public-user.component.scss']
})
export class UpdatePublicUserComponent implements OnInit {
    userForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    isLoading = false;
    subscriptionOptions = [];
    userData: TokenData;
    expirationDate = 0;
    minStartDate = new Date();
    minEndDate = new Date();
    showError = false;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private commonService: CommonService,
        private route: ActivatedRoute
    ) {
        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.userForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            subscriptionId: ['', [Validators.required]],
            subscriptionStart: ['', [Validators.required]],
            subscriptionEnd: ['']
        });

        this.getUserInfo();
        this.getSubscriptions();
    }

    getUserInfo() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            const query = `userId=${id}`;
            this.adminService.getUserListApi(query).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success' && res.data['userList']?.length > 0) {
                    const data = res.data as GetUserListDto;
                    const userInfo = data.userList[0];

                    let subscriptionStart = new Date();
                    if (userInfo.subscriptions[0]?.expiration?.startAt) {
                        subscriptionStart = new Date(userInfo.subscriptions[0]?.expiration?.startAt * 1000);
                        this.minEndDate.setDate(subscriptionStart.getDate() + 1);
                    }

                    this.userForm.setValue({
                        firstName: userInfo.firstName || '',
                        lastName: userInfo.lastName || '',
                        email: userInfo.email || '',
                        subscriptionId: userInfo.subscriptions[0]?.id || '',
                        subscriptionStart,
                        subscriptionEnd: new Date(userInfo.subscriptions[0]?.expiration?.endAt * 1000) || ''
                    });

                    this.expirationDate = userInfo.expirationDate * 1000;
                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            });
        }
    }

    getSubscriptions() {
        this.isLoading = true;
        const query = 'type=COMMON,PUBLIC&status=ACTIVE';
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const result = res.data as GetSubscriptionListDto;
                this.subscriptionOptions = result.subscriptions.map((subscription) => ({ id: subscription._id, name: subscription.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    updateUser() {
        if (this.userForm.valid) {
            const {
                firstName,
                lastName,
                email,
                subscriptionId,
                subscriptionStart,
                subscriptionEnd
            } = this.userForm.value;
            const endOfDay = new Date().setHours(23, 59, 59);

            const request = {
                firstName,
                lastName,
                email,
                subscriptionId,
                subscriptionStart: subscriptionStart < endOfDay ? subscriptionStart.getTime() : subscriptionStart.setHours(23, 59, 59),
                subscriptionEnd: subscriptionEnd ? subscriptionEnd.setHours(23, 59, 59) : 8640000000000,
                expirationDate: this.expirationDate
            };

            this.isLoading = true;
            this.adminService.updateUser(request, this.route.snapshot.queryParamMap.get('id')).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/users']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    clearExpirationDate(event: Event) {
        event.stopPropagation();
        this.expirationDate = 0;
    }

    updateExpirationDate($event: MatDatepickerInputEvent<Date>) {
        this.expirationDate = new Date($event.value).setHours(23, 59, 59);
    }

    resetExpiration() {
        this.userForm.controls.subscriptionEnd.setValue('');
    }

    displayExpirationDate() {
        const date = new Date(this.expirationDate);
        return `Expiration Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    updateExpirationMin() {
        this.minEndDate.setDate(this.userForm.value.subscriptionStart.getDate() + 1);
        if (this.userForm.value.subscriptionEnd < this.userForm.value.subscriptionStart) {
            this.userForm.controls.subscriptionEnd.setValue('');
        }
    }
}

