<div *ngIf="!preview && !error" class="dropzone" (drop)="onDrop($event)" (dragover)="onDragOver($event)"
(dragleave)="onDragLeave($event)">
    <input type="file" (change)="handleFileUpload($event)" [accept]="accept" />
    <img class='dropzone-icon' src="../../../../assets/images/NewUI/upload.svg" alt="" />
    <span>
        Drag file here or <span class="browse" (click)="browse()">Browse</span>
    </span>
    <span class="description">{{description}}</span>
</div>
<div *ngIf="preview && !error" class="preview">
    <svg (click)="clearFile()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
        <path d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z"/>
    </svg>
    <img [src]="preview" [style.object-fit]="previewMode"/> 
</div>
<div *ngIf="error" class="dropzone">
    <span *ngIf="error" class="description error">Error: {{error}}</span>
    <div class="filterButtons my-3">
        <button class="formActionBtn nameFilter px-3" (click)="reset()">Select another file</button>
      </div>
</div>
