import { Component, OnInit } from "@angular/core";
import { Enterprise, EnterpriseContact, TokenData } from "../../../../shared/interfaces";
import { Router } from "@angular/router";
import { AdminService } from "../../admin.service";
import { TooltipService } from "../../../../shared/tooltip.service";
import { CommonService } from "../../../../shared/common.service";

@Component({
    selector: 'app-view-enterprise',
    templateUrl: './view-enterprise.component.html',
    styleUrls: ['./view-enterprise.component.scss']
})
export class ViewEnterpriseComponent implements OnInit {
    userData: TokenData;
    isLoading = 0;
    enterprise: Enterprise;
    tooltipComponent = null;
    unlimited = 8640000000000;
    snapSupport: EnterpriseContact;
    enterpriseSupport: EnterpriseContact;
    invoiceCount = 0;

    constructor(
        private adminService: AdminService,
        public router: Router,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.getEnterprise();
    }

    getEnterprise() {
        this.isLoading++;
        this.adminService.getEnterprise('').subscribe((res) => {
            if (res['message'] == 'Success') {
                this.enterprise = res.data.enterpriseList[0];
                this.snapSupport = this.enterprise.contacts?.find((contact) => contact.type === 'SNAP');
                this.enterpriseSupport = this.enterprise.contacts?.find((contact) => contact.type === 'ENTERPRISE');

                this.adminService.getInvoices(`enterpriseId=${this.enterprise.id}`).subscribe((res) => {
                    this.isLoading--;
                    if (res['message'] === 'Success') {
                        this.invoiceCount = res.data.invoices.length;
                    }
                }, err => {
                    this.isLoading--;
                });
            }
        }, err => {
            this.isLoading--;
        });
    }

    getEnterpriseStatus(): string {
        const hasStarted = this.enterprise?.subscription?.expiration?.startAt && this.enterprise?.subscription?.expiration.startAt * 1000 <= new Date().getTime();
        const isExpired = this.enterprise?.subscription?.expiration?.endAt && this.enterprise?.subscription?.expiration.endAt * 1000 <= new Date().getTime();
        return !hasStarted ? 'INACTIVE' : isExpired ? 'EXPIRED' : (this.enterprise?.status || 'ACTIVE');
    }

    navigatePage(path: string, query?: Record<string, string>) {
        this.router.navigate([path], { queryParams: query });
    }
}