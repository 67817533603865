import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTestService } from '../custom-test.service';
import { CustomArtifactInfo, CustomArtifactTypeDisplayMap, CustomArtifactTypeEnum, CustomTestArtifact, CustomTestExecution, CustomTestExecutionStatusMap, CustomTestReplay, CustomTestTemplate } from '../interfaces';
import { ArtifactStatusEnum, FeatureTypeEnum, TestStatusDisplayMap } from '../../../shared/enum';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { interval, Subscription } from 'rxjs';
import { CommonService } from '../../../shared/common.service';

interface Artifact {
    type: CustomArtifactTypeEnum,
    bucketName: string;
    key: string;
    size: number;
    status: string;
    deviceIndex: number;
}

@Component({
    selector: 'custom-test-report',
    templateUrl: './custom-test-report.component.html',
    styleUrls: ['./custom-test-report.component.scss']
})

export class CustomTestReportComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private customTestService: CustomTestService,
        private dialogService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    pageLimit = 10;
    currentPage = 0;
    totalPage = 0;
    totalIterations = 0;
    paginationText = '0-0 of 0';

    testInfo: CustomTestExecution;
    iterations: CustomTestReplay[];
    testCase: CustomTestTemplate;

    activeTab = '1';
    artifactsTotal: Record<string, number> = {};
    artifactList: Artifact[] = [];

    isLoading = 0;
    pollingInterval: Subscription = null;
    tooltipComponent = null;
    isTelephonyAvailable = false;
    isCloudAvailable = false;
    isExpandedIterations = false

    ngOnInit(): void {
        this.isTelephonyAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
        if (this.isTelephonyAvailable) {
            this.getTestInfo();
        }
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }

    getTestInfo() {
        this.isLoading++;
        const id = this.route.snapshot.queryParamMap.get('id');
        this.customTestService.getTestExecution(id).subscribe((res) => {
            this.isLoading--;
            this.testInfo = res;
            this.totalIterations = res.replayCount;
            this.getTestCases();
            this.getIterations();

            this.countArtifactsTotal();
            this.sortArtifacts();
            if (this.checkFinalizing() && (!this.pollingInterval || this.pollingInterval.closed)) {
                this.pollingInterval = interval(20000).subscribe(() => {
                    this.getTestInfo();
                });
            } else {
                this.stopPolling();
            }
        }, err => this.isLoading--);
    }

    getTestCases() {
        this.isLoading++;
        this.customTestService.getTestTemplate(this.testInfo.testId).subscribe((res) => {
            this.isLoading--;
            this.testCase = res;
        }, err => this.isLoading--);
    }

    openTab(index: number) {
        this.activeTab = index.toString();
        this.sortArtifacts();
    }

    getIterations() {
        this.isLoading++;
        const id = this.route.snapshot.queryParamMap.get('id');
        this.customTestService.getTestReplays(id, this.currentPage, this.pageLimit).subscribe((res) => {
            this.isLoading--;
            if (res.content) {
                this.iterations = res.content.map((iteration) => {
                    const steps = iteration.steps.map((step) => {
                        let error = '';
                        if (iteration.result?.errors) {
                            const e = iteration.result?.errors.find((error) => error.message.includes(`[Step ${step.action.order}]`));
                            if (e) error = e.message.replace(`[Replay ${iteration.index}] [Step ${step.action.order}]`, '');
                        }
                        const status = error ? 'FAILED' : step.endedAt ? 'COMPLETED' : step.startedAt ? 'EXECUTING' : 'IDLE';
                        const validators = step.validators?.map((validator) => {
                            let status = 'IDLE';
                            if (error) {
                                const deviceParam = validator.target.parameters.find((param) => param.key === 'index');
                                const device = this.testInfo.devices.find((device) => device.index === deviceParam?.value);
                                if (device && error.includes(device.serialNumber)) status = 'FAILED';
                            } else if (step.endedAt) {
                                status = 'COMPLETED';
                            }
                            return { ...validator, status };
                        });
                        return { ...step, status, error, validators };
                    })
                    return { ...iteration, steps };
                });
            }
            this.totalPage = res.totalPages;
            this.totalIterations = res.totalElements;
            this.setPage();
        }, err => this.isLoading--);
    }

    changePageLimit() {
        this.currentPage = 0;
        this.getIterations();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.getIterations();
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }

    displayTestStatus() {
        return CustomTestExecutionStatusMap.get(this.testInfo?.status);
    }

    displayArtifactStatus(status: ArtifactStatusEnum): string {
        return TestStatusDisplayMap.get(status);
    }

    displayArtifactType(type: CustomArtifactTypeEnum): string {
        return CustomArtifactTypeDisplayMap.get(type);
    }

    countArtifactsTotal() {
        this.testInfo.logs.forEach((log) => {
            this.artifactsTotal[log.deviceIndex] = log.artifacts?.length || 0;
        })
    }

    sortArtifacts() {
        const deviceArtifacts = this.testInfo.logs.find((log) => log.deviceIndex.toString() === this.activeTab);
        if (deviceArtifacts) {
            this.artifactList = deviceArtifacts.artifacts?.filter((artifact) => !!artifact)
                .map((artifact) => ({ ...artifact, deviceIndex: deviceArtifacts.deviceIndex }));
        }
    }

    downloadArtifacts() {
        this.customTestService.downloadAllArtifacts(this.testInfo.id).subscribe(
            (res) => {
                const blob = new Blob([res.body], { type: res.headers.get('content-type') });

                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);

                a.href = url;
                a.download = this.testInfo.name + '_artifacts.zip';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            },
            (err) => { }
        );
    }

    downloadFile(artifact: CustomArtifactInfo): void {
        const directUrl = artifact.key;
        const path = directUrl.substring(directUrl.indexOf('/users/') + 1);

        this.customTestService.getFileDownloadApi('path=' + path).subscribe(
            (res) => {
                const link = document.createElement('a');
                link.href = res.data.url;
                link.download = artifact.key;
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            (err) => { }
        );
    }

    openConfirmDeleteArtifact(artifact: Artifact): void {
        this.dialogService.openConfirmationDialog(
            'deleteArtifactsModal',
            () => this.deleteArtifact(artifact)
        );
    }

    deleteArtifact(artifact: Artifact) {
        this.isLoading++;
        const user = this.commonService.getUser();
        this.customTestService.removeArtifactFromCloud(user.userId, this.testInfo.name, artifact.key).subscribe((res) => {
            if (res?.code) {
                this.isLoading--;
            } else {
                this.customTestService.getTestExecution(this.testInfo.id).subscribe((res) => {
                    this.isLoading--;
                    this.testInfo = res;
                    this.sortArtifacts();
                }, () => this.isLoading--);
            }
        }, () => this.isLoading--);
    }

    checkDownloadAll(): boolean {
        return this.isCloudAvailable && this.testInfo
            && this.testInfo.logs.some(log => {
                return log.artifacts?.some((artifact) => artifact.status === ArtifactStatusEnum.COLLECTED);
            });
    }

    cancelArtifact(artifact: Artifact) {
        this.isLoading++;
        this.customTestService.cancelArtifact(this.testInfo.id, artifact.deviceIndex, artifact.type).subscribe((res) => {
            if (res?.code) {
                this.isLoading--;
            } else {
                this.customTestService.getTestExecution(this.testInfo.id).subscribe((res) => {
                    this.isLoading--;
                    this.testInfo = res;
                    this.sortArtifacts();
                }, () => this.isLoading--);
            }
        }, () => this.isLoading--);
    }

    cancelFile(artifact: Artifact): void {
        this.dialogService.openConfirmationDialog('cancelArtifactFinalization', () => this.cancelArtifact(artifact));
    }

    stopPolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }

    getExecutionTime(): string {
        if (this.testInfo) {
            const executionTime = (this.testInfo.endedAt - this.testInfo.startedAt) / 1000;
            const hours = Math.floor(executionTime / 3600);
            const mins = Math.floor((executionTime - (hours * 3600)) / 60);
            const seconds = Math.round(executionTime % 60);
            return `${hours ? hours + ' hour' : ''} ${mins ? mins + ' min' : ''} ${seconds} seconds`;
        }
        return '';
    }

    setPage() {
        const total = this.totalIterations;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
    }

    getArtifactsTotal(): number {
        return this.testInfo.logs.reduce((acc, device) => {
            if (device.artifacts) {
                acc += device.artifacts.length
            }
            return acc;
        }, 0);
    }

    expandIterations() {
        this.isExpandedIterations = !this.isExpandedIterations;
    }

    checkArtifactsExist() {
        return this.testInfo.logs.some(log => log.artifacts?.length > 0);
    }

    checkFinalizing() {
        return this.testInfo.logs.some(log => log.artifacts?.some((artifact) => artifact.status === ArtifactStatusEnum.FINALIZING));
    }

    getArtifactName(key: string): string {
        if (!key) return '';
        const split = key.split('/');
        return split[split.length - 1];
    }
}
