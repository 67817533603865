import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './spinner/spinner.component';
import {AlertConfig, AlertModule} from 'ngx-bootstrap/alert';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CommonModule} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgbAlertModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

// import { NgOtpInputModule } from 'ng-otp-input';
import {MainFooterComponent} from '../main/main-footer/main-footer.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {MaterialModule} from './material-ui.module';
import {CdkTableModule} from '@angular/cdk/table';
import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {RouterModule} from '@angular/router';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {EmptyFolderComponent} from './empty-folder/empty-folder.component';
import {EmptySearchResultComponent} from './empty-search-result/empty-search-result.component';
import {FileSizePipe} from './pipes/file-size.pipe';
import {DropzoneComponent} from './dropzone/dropzone.component';
import {TextareaAutoResizeDirective} from './directives/textarea-autoresize.directive';
import {KilobytesPipe} from './pipes/kilobytes.pipe';
import {HowtoSidepanelComponent} from './howto-sidepanel/howto-sidepanel.component';
import {FileStructureComponent} from './file-structure/file-structure.component';
import {TooltipDataPipe} from './pipes/tooltip-data';
import {UserProfileIconComponent} from './components/user-profile-icon/user-profile-icon.component';
import {FormDropzoneComponent} from './form-dropzone/form-dropzone.component';
import {UserDataService} from './user-data.service';
import {ScreencastComponent} from './screencast/screencast.component';
import {FeatureListComponent} from './components/feature-list/feature-list.component';
import {InfoTabComponent} from './components/tabs/info/info-tab.component';
import {TippyDirective} from '@ngneat/helipopper';
import {GoogleMapsModule} from '@angular/google-maps';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SnackbarComponent } from './components/snackbar/snackbar-comp.component';

// import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
    declarations: [
        SpinnerComponent,
        MainFooterComponent,
        ConfirmationDialogComponent,
        TooltipComponent,
        EmptyFolderComponent,
        EmptySearchResultComponent,
        FileSizePipe,
        DropzoneComponent,
        TextareaAutoResizeDirective,
        KilobytesPipe,
        HowtoSidepanelComponent,
        FileStructureComponent,
        TooltipDataPipe,
        UserProfileIconComponent,
        FormDropzoneComponent,
        ScreencastComponent,
        FeatureListComponent,
        InfoTabComponent,
        ChatbotComponent,
        SnackbarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AlertModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        MaterialModule,
        RouterModule,
        TippyDirective,
        GoogleMapsModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        SpinnerComponent,
        AlertModule,
        NgMultiSelectDropDownModule,
        ImageCropperModule,
        CommonModule,
        NgbAlertModule,
        NgbPaginationModule,
        DragDropModule,
        // NgOtpInputModule,
        MainFooterComponent,
        CdkTableModule,
        A11yModule,
        BidiModule,
        CdkAccordionModule,
        ObserversModule,
        OverlayModule,
        PlatformModule,
        PortalModule,
        EmptyFolderComponent,
        EmptySearchResultComponent,
        FileSizePipe,
        DropzoneComponent,
        TextareaAutoResizeDirective,
        KilobytesPipe,
        HowtoSidepanelComponent,
        FileStructureComponent,
        TooltipDataPipe,
        UserProfileIconComponent,
        FormDropzoneComponent,
        ScreencastComponent,
        FeatureListComponent,
        InfoTabComponent,
        ChatbotComponent,
        MatOptionModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        SnackbarComponent
    ],
  providers: [
    AlertConfig,
    UserDataService
  ]
})
export class SharedModule { }
