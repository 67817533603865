import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { GetSubscriptionListDto } from '../../../../shared/dto';
import { GetEnterpriseListDto } from '../../../../shared/dto/get-enterprise-list.dto';

@Component({
    selector: 'app-update-enterprise',
    templateUrl: './update-enterprise.component.html',
    styleUrls: ['./update-enterprise.component.scss']
})
export class UpdateEnterpriseComponent implements OnInit {
    enterpriseForm: FormGroup;
    isLoading = 0;
    snapSupportOptions = [];
    enterpriseSupportOptions = [];
    subscriptionOptions = [];
    minStartDate = new Date();
    minEndDate = new Date();
    logoUrl = '';
    showError = false;
    unlimited = 8640000000000;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute) {
        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    }

    ngOnInit() {
        this.initializeForm();
        this.getEnterpriseInto();
        this.getSupportOptions();
        this.getSubscriptions();
    }

    getEnterpriseInto() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading++;
            this.adminService.getEnterprise(`enterpriseId=${id}`).subscribe((res) => {
                this.isLoading--;
                if (res.statusCode === 200 && res.data.enterpriseList?.length > 0) {
                    const data = res.data as GetEnterpriseListDto;
                    const enterpriseInfo = data.enterpriseList[0];
                    const snapSupport = enterpriseInfo.contacts?.find((contact) => contact.type === 'SNAP');
                    const enterpriseSupport = enterpriseInfo.contacts?.find((contact) => contact.type === 'ENTERPRISE');
                    const subscriptionEnd = enterpriseInfo.subscription?.expiration?.endAt === this.unlimited / 1000
                        ? ''
                        : new Date(enterpriseInfo.subscription?.expiration?.endAt * 1000);
                    let subscriptionStart = new Date();
                    if (enterpriseInfo.subscription?.expiration?.startAt) {
                        subscriptionStart = new Date(enterpriseInfo.subscription?.expiration?.startAt * 1000);
                        this.minEndDate.setDate(subscriptionStart.getDate() + 1);
                    }
                    this.enterpriseForm.setValue({
                        name: enterpriseInfo.name || '',
                        location: enterpriseInfo.location || '',
                        snapSupportContact: snapSupport?.email || '',
                        enterpriseSupportContact: enterpriseSupport?.email || '',
                        labLimit: enterpriseInfo.maxLabCount || '',
                        deviceLimit: enterpriseInfo.maxDeviceCount || '',
                        userLimit: enterpriseInfo.maxUserCount || '',
                        logsDeletePeriod: enterpriseInfo.logsDeletePeriod || '',
                        logo: enterpriseInfo.logo || '',
                        subscriptionId: enterpriseInfo.subscription?.id || '',
                        subscriptionStart,
                        subscriptionEnd
                    });
                    this.logoUrl = enterpriseInfo.logo;
                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading--;
                this.showError = true;
            })
        }
    }

    initializeForm() {
        this.enterpriseForm = this.fb.group({
            name: ['', [Validators.required]],
            location: [''],
            snapSupportContact: ['', [Validators.required]],
            enterpriseSupportContact: [''],
            labLimit: ['', [Validators.min(0)]],
            deviceLimit: ['', [Validators.min(0)]],
            userLimit: ['', [Validators.min(0)]],
            logsDeletePeriod: ['', [Validators.min(0)]],
            subscriptionId: ['', [Validators.required]],
            subscriptionStart: ['', [Validators.required]],
            subscriptionEnd: [''],
            logo: [''],
        });
    }

    getSupportOptions() {
        this.isLoading++;
        this.adminService.getUserListApi('roles=ADMIN&includeSelf=true').subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                this.snapSupportOptions = res.data.userList.map((user) => ({ name: user.firstName + ' ' + user.lastName, email: user.email }));
            }
        }, err => {
            this.isLoading--;
        });
        const id = this.route.snapshot.queryParamMap.get('id');
        this.isLoading++;
        this.adminService.getUserListApi(`roles=ENTERPRISE&enterpriseId=${id}&labManagement=true`).subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                this.enterpriseSupportOptions = res.data.userList.map((user) => ({ name: user.firstName + ' ' + user.lastName, email: user.email }));
            }
        }, err => {
            this.isLoading--;
        });
    }

    getSubscriptions() {
        this.isLoading++;
        const query = 'type=COMMON,ENTERPRISE&status=ACTIVE';
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                const result = res.data as GetSubscriptionListDto;
                this.subscriptionOptions = result.subscriptions.map((subscription) => ({ id: subscription._id, name: subscription.name }));
            }
        }, err => {
            this.isLoading--;
        });
    }

    onSubmit() {
        if (this.enterpriseForm.valid) {
            if (this.enterpriseForm.value.logo && !this.logoUrl) {
                this.isLoading++;
                this.adminService.uploadImage(this.enterpriseForm.value.logo).subscribe(data => {
                    if (data["message"] == "Success") {
                        this.logoUrl = data.data.url;
                        this.updateEnterprise();
                    } else {
                        this.isLoading--;
                    }
                }, () => {
                    this.isLoading--;
                });
            } else {
                this.updateEnterprise();
            }
        }
    }

    updateEnterprise() {
        if (this.enterpriseForm.valid) {
            const id = this.route.snapshot.queryParamMap.get('id');
            const {
                name,
                location,
                snapSupportContact,
                enterpriseSupportContact,
                labLimit,
                deviceLimit,
                userLimit,
                logsDeletePeriod,
                subscriptionId,
                subscriptionStart,
                subscriptionEnd
            } = this.enterpriseForm.value;
            const endOfDay = new Date().setHours(23, 59, 59);
            const snapContact = this.snapSupportOptions.find((option) => option.email === snapSupportContact);
            const enterpriseContact = this.enterpriseSupportOptions.find((option) => option.email === enterpriseSupportContact);

            const contacts = [{
                type: 'SNAP',
                name: snapContact.name,
                email: snapContact.email
            }];

            if (enterpriseContact) {
                contacts.push({
                    type: 'ENTERPRISE',
                    name: enterpriseContact.name,
                    email: enterpriseContact.email
                })
            }

            const request = {
                id,
                name,
                location,
                contacts,
                maxLabCount: labLimit || 0,
                maxDeviceCount: deviceLimit || 0,
                maxUserCount: userLimit || 0,
                logsDeletePeriod: logsDeletePeriod || 0,
                subscriptionId,
                subscriptionStart: subscriptionStart < endOfDay ? subscriptionStart.getTime() : subscriptionStart.setHours(23, 59, 59),
                subscriptionEnd: subscriptionEnd ? subscriptionEnd.setHours(23, 59, 59) : this.unlimited,
                logo: this.logoUrl
            };

            this.isLoading++;
            this.adminService.updateEnterprise(request).subscribe((res) => {
                this.isLoading--;
                if (res['message'] === 'Success') {
                    this.router.navigate(['admin/enterprises']);
                }
            }, err => {
                this.isLoading--;
            });
        }
    }

    onFileChanged(event) {
        this.logoUrl = '';
        this.enterpriseForm.controls.logo.setValue(event);
    }

    resetExpiration() {
        this.enterpriseForm.controls.subscriptionEnd.setValue('');
    }

    replaceLimit(prop: string) {
        if (!this.enterpriseForm.value[prop]) {
            this.enterpriseForm.controls[prop].setValue('');
        }
    }

    updateExpirationMin() {
        this.minEndDate.setDate(this.enterpriseForm.value.subscriptionStart.getDate() + 1);
        if (this.enterpriseForm.value.subscriptionEnd < this.enterpriseForm.value.subscriptionStart) {
            this.enterpriseForm.controls.subscriptionEnd.setValue('');
        }
    }
}
