<div class="artifacts-header artifacts-row">
    <span class="cell span-2">Artifact</span>
    <span class="cell">Status</span>
    <span class="cell size">Size</span>
</div>
<div class="artifacts-row" *ngFor="let artifact of artifacts">
    <span class="cell">
        <img alt="" class="artifact-icon" src="../../../../assets/images/NewUI/{{getIconName(artifact)}}.svg">
    </span>
    <span class="cell ellipsis" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
        [tpData]="getArtifactName(artifact) | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
        {{getArtifactName(artifact)}}
    </span>
    <span class="cell">{{displayArtifactStatus(artifact.status)}}</span>
    <span class="cell size">{{artifact.result?.payload?.size | kilobytes: 3 }}</span>
    <span class="cell artifacts">
        <mat-icon class='artifact-status' *ngIf="artifact.status === 'COMPLETED'">done</mat-icon>
        <mat-spinner color="accent" diameter="16"
            *ngIf="artifact.status === 'FINALIZING' || artifact.status === 'PROGRESS'"></mat-spinner>
        <img class='artifact-status failed-icon' src="../../../../assets/images/artifact_failed.svg" alt=""
            *ngIf="artifact.status === 'FAILED'" />
    </span>
    <span class="cell artifact-actions">
        <span [tp]="tooltipComponent" tpPlacement="left" [tpData]="(isCloudAvailable ? 'Cancel artifact finalization' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
            *ngIf="artifact.status === 'FINALIZING' || artifact.status === 'PROGRESS'">
            <svg class='action-button' (click)="cancelFile(artifact)" [ngClass]="{disabled: !isCloudAvailable}"
                xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16">
                <path
                    d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </span>
        <span [tp]="tooltipComponent" tpPlacement="left" [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
            *ngIf="artifact.status !== 'FINALIZING' && artifact.status !== 'PROGRESS'">
            <img class='action-button' src="../../../../assets/images/NewUI/download-artifact.svg" alt=""
                [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COMPLETED'}"
                (click)="downloadFile(artifact)">
        </span>
        <span [tp]="tooltipComponent" tpPlacement="left" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
            <img class='action-button' src="../../../../assets/images/NewUI/delete.svg" alt=""
                [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COMPLETED' }"
                (click)="openConfirmDeleteArtifact(artifact)">
        </span>
    </span>
    <span class="artifact-error-message" *ngIf="artifact.result?.errors">({{artifact.result.errors[0].message}})</span>
</div>
