import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { Feature } from '../../../shared/interfaces';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GetSubscriptionListDto } from '../../../shared/dto';

@Component({
    selector: 'app-update-subscription',
    templateUrl: './update-subscription.component.html',
    styleUrls: ['./update-subscription.component.scss']
})
export class UpdateSubscriptionComponent implements OnInit {
    subscriptionForm: FormGroup;

    isLoading = false;
    featureList = [];
    isNameExists = false;
    showError = false;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.initializeForm();
        this.getSubscriptionList();
        this.getFeatures();
    }

    getSubscriptionList() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            const query = `id=${id}`;
            this.adminService.getSubscriptionList(query).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success' && res.data['subscriptions']?.length > 0) {
                    const data = res.data as GetSubscriptionListDto;
                    const subscriptionInfo = data.subscriptions[0];
                    const features = subscriptionInfo.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));

                    this.subscriptionForm.setValue({
                        type: subscriptionInfo.type || '',
                        name: subscriptionInfo.name || '',
                        description: subscriptionInfo.description || '',
                        features: features || '',
                        status: subscriptionInfo.status || '',
                        comments: subscriptionInfo.comments || '',
                        icon: subscriptionInfo.icon || '',
                    });

                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            });
        }
    }

    initializeForm() {
        this.subscriptionForm = this.fb.group({
            type: ['', [Validators.required]],
            name: ['', [Validators.required]],
            description: [''],
            features: ['', Validators.compose([Validators.required])],
            status: ['', [Validators.required]],
            comments: [''],
            icon: [''],
        });
    }

    getFeatures() {
        this.isLoading = true;
        this.adminService.getFeatureList().subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const data = res.data as { features: Feature[] };
                this.featureList = data.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    updateSubscription() {
        if (this.subscriptionForm.valid) {
            const {
                type,
                name,
                description,
                features,
                status,
                comments,
                icon
            } = this.subscriptionForm.value;

            const request = {
                name,
                type,
                description,
                features: features.map(feature => feature.item_id),
                status,
                comments,
                icon
            };

            const id = this.route.snapshot.queryParamMap.get('id');
            this.isLoading = true;
            this.adminService.updateSubscription(request, id).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] === 'Success') {
                    this.router.navigate(['admin/subscriptions']);
                    this.isLoading = false;
                } else if (res.data?.message === 'Subscription with this name already exists') {
                    this.isNameExists = true;
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    handleFileUpload($event) {
        if (!$event) {
            return this.subscriptionForm.controls.icon.setValue('');
        }
        this.isLoading = true;
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
            this.subscriptionForm.controls.icon.setValue(reader.result);
            this.isLoading = false;
        }
        reader.onerror = () => {
            this.isLoading = false;
        }
    }
}
