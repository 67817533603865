<!-- <div
    [class.full-screen-loader]="type === 'fullScreen'"
    [class.screen-loader]="type === 'normal'"
    [class.screen-loader-current]="type === 'current'"
>
    <div class="justify-content-center spinner-grow">
        <div class="spinner-container">
            <div class="" role="status">
               
                <img class="w-100" src="../.../../../../assets/images/fav_icon (2).svg    " alt="">
            </div>
        </div>
    </div>
</div> -->

<div style="opacity: 0.8;" [class.full-screen-loader]="type === 'fullScreen'"
    [class.screen-loader]="type === 'normal'" [class.screen-loader-current]="type === 'current'">
    <div class="justify-content-center spinner">
        <div class="spinner-container">
            <div class="spinner-border" style="z-index: 9999;" role="status">
                <img class="w-100" src="assets/images/logo-only.svg" alt="">
            </div>
        </div>
    </div>
</div>

<!-- <div class="loader">Loading...</div> -->
