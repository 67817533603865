
<div class="modal-header justify-content-center">
  <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
    >x</span
  >
</div>
<div class="col-12 text-center pb-4">
    <p class="welcome_ mt-4">Currently does not support safari, please use chrome browser.</p>
   
   
  </div>
