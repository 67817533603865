import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import initHelpHero from 'helphero';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  screenWidth:any;
  isMobile:boolean =false;
  constructor(private router: Router){
    
   this.onResize("");
   this.getBrowserName();
  
  }
  ngOnInit() {
    let token = localStorage.getItem('token');
    let browser = this.getBrowserName();
    localStorage.setItem('browser',browser)
    // if(!token){
    //   this.router.navigate(["/signin"])  
    // }
    // const helphero = initHelpHero('EBFrzgCINQ');
    // helphero.anonymous();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if( this.screenWidth <=  780) {
      this.isMobile = true;
      localStorage.setItem('isMobile','true')
    }else {
      localStorage.setItem('isMobile','false')
      this.isMobile = false;
    }

  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  validateToken(){
    let token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/snap/profile"])
    }
  }

}
