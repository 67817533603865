// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
 production: true,
 firebase: {
  apiKey: "AIzaSyDtgJmqRLHykXE5lwHytqcAeb4hTrQKjcA",
  authDomain: "snap-automation.firebaseapp.com",
  projectId: "snap-automation",
  storageBucket: "snap-automation.appspot.com",
  messagingSenderId: "999842820138",
  appId: "1:999842820138:web:f7703e201600f8041b5353",
  measurementId: "G-0W33QBBRQF"
 }
};
