import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { MomtCallService } from '../momt-call.service';
import { MomtArtifact, MomtStatusMap, MomtTestStatus, MomtTestSummary } from '../interfaces';
import { ApiResponseDto } from '../../../shared/dto';
import { GetMomtListDto } from '../dto';
import { ArtifactStatusEnum, DateFilterTypeEnum, FeatureTypeEnum } from '../../../shared/enum';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { Subscription, interval } from 'rxjs';
import { TooltipService } from '../../../shared/tooltip.service';
import { CommonService } from '../../../shared/common.service';
import { RecordAndPlayTypeEnum } from '../../record-and-play/models/record-and-play.model';
import { MomtTypeMap, TelephonyTestCase, TelephonyTestType } from '../interfaces/momt-test.interface';
import { MatDrawer } from '@angular/material/sidenav';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
    selector: 'momt-table-view',
    templateUrl: './momt-table-view.component.html',
    styleUrls: ['./momt-table-view.component.scss']
})
export class MomtTableViewComponent implements OnInit, OnDestroy {
    private modalRef: NgbModalRef;
    momtTestList: MomtTestSummary[];
    testCases: TelephonyTestCase[];
    totalTests = 0;
    totalTestCases = 0;
    pageLimit = 20;
    currentPage = 0;
    totalPage = 0;
    totalTestCasesPage = 0;
    startDate = 0;
    endDate = 0;
    searchValue = '';
    isNamePristine = true;
    pollingInterval: Subscription = null;
    tooltipComponent = null;
    isTelephonyAvailable = false;

    searchTimeout;
    selectedStatuses: { item_id: MomtTestStatus, item_text: string }[] = [];
    dateFilter: DateFilterTypeEnum;
    activeTab = RecordAndPlayTypeEnum.CASE;
    TypeEnum = RecordAndPlayTypeEnum;
    FilterType = DateFilterTypeEnum;
    isFiltered = false;
    isFilterOpen = false;
    sidePanelData: any;
    selectedIndex: number;

    statusList = [
        { item_id: MomtTestStatus.COMPLETED, item_text: MomtStatusMap.get(MomtTestStatus.COMPLETED) },
        { item_id: MomtTestStatus.IDLE, item_text: MomtStatusMap.get(MomtTestStatus.IDLE) },
        { item_id: MomtTestStatus.CANCELED, item_text: MomtStatusMap.get(MomtTestStatus.CANCELED) },
        { item_id: MomtTestStatus.PROGRESS, item_text: MomtStatusMap.get(MomtTestStatus.PROGRESS) },
        { item_id: MomtTestStatus.FAILED, item_text: MomtStatusMap.get(MomtTestStatus.FAILED) },
    ];
    emptyActions = [];
    isLoading = 0;
    selectedTestId = '';
    paginationText = '0-0 of 0';

    @ViewChild('rangePicker') rangePicker: MatDateRangePicker<unknown>;
    @ViewChild('drawer') drawer: MatDrawer;

    constructor(
        private router: Router,
        private momtCallService: MomtCallService,
        private dialogModalService: DialogModalService,
        private tooltipService: TooltipService,
        private commonService: CommonService,
    ) {
        this.emptyActions = [{ button: 'New MO/MT call', onClick: () => this.navigateMoMt('/admin/telephony-new-test') }];
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.isTelephonyAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        if (this.isTelephonyAvailable) {
            this.getTests();
            this.getTestCases();
        }
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }

    getTests() {
        const query = {
            page: this.currentPage,
            size: this.pageLimit,
            startDate: this.startDate,
            endDate: this.endDate,
            searchValue: this.searchValue,
            statuses: this.selectedStatuses.map(item => item.item_id)
        };

        this.isLoading++;
        this.momtCallService.getTestList(query).subscribe((res: ApiResponseDto<GetMomtListDto>) => {
            if (res.statusCode === 200) {
                const response = res.data as GetMomtListDto;
                this.totalTests = response.totalElements;
                this.totalPage = response.totalPages;
                this.momtTestList = response.tests;
                this.setPage();
                this.isLoading--;

                const hasFinalizing = response.tests
                    .some((test) => test.status === MomtTestStatus.FINALIZING || test.status === MomtTestStatus.PROGRESS);
                if (hasFinalizing) {
                    if (!this.pollingInterval || this.pollingInterval.closed) {
                        this.pollingInterval = interval(20000).subscribe(() => {
                            this.getTests();
                        });
                    }
                } else {
                    this.stopPolling();
                }
            }
        }, err => {
            this.isLoading--;
        });
    }

    getTestCases() {
        const query = `size=${this.pageLimit}&page=${this.currentPage}`;
        this.isLoading++;

        this.momtCallService.getTestCaseList(query).subscribe((res) => {
            this.isLoading--;
            if (res && res.data) {
                this.testCases = res.data.tests;
                this.totalTestCases = res.data.totalElements;
                this.totalTestCasesPage = res.data.totalPages;
                this.setPage();
            }
        }, err => {
            this.isLoading--;
        });
    }

    searchTests() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.getFilteredTests();
        }, 1000);
    }

    changePageLimit() {
        this.activeTab === RecordAndPlayTypeEnum.CASE ? this.getTestCases() : this.getTests();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.activeTab === RecordAndPlayTypeEnum.CASE ? this.getTestCases() : this.getTests();
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    getIterationWidth(iterations: { _id: MomtTestStatus, count: number }[], statuses: string[]): string {
        const number = iterations.reduce((acc, group) => {
            if (statuses.includes(group._id)) {
                return acc += group.count;
            }
            return acc;
        }, 0);
        return (Math.ceil(100 * number)) + '%';
    }

    navigateMoMt(path: string) {
        this.router.navigate([path]);
    }

    setDateFilter(param: DateFilterTypeEnum): void {
        if (this.dateFilter === param) {
            this.dateFilter = null;
            this.startDate = null;
            this.endDate = null;
        } else {
            this.dateFilter = param;
            switch (param) {
                case DateFilterTypeEnum.TODAY:
                    this.getTodayTests();
                    break;
                case DateFilterTypeEnum.LAST_WEEK:
                    this.getLastWeekTests();
                    break;
                case DateFilterTypeEnum.CUSTOM:
                    this.rangePicker.open();
                    break;
            }
        }
    }

    getTodayTests() {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
    }

    getLastWeekTests() {
        const now = new Date();
        this.endDate = now.getTime() / 1000;
        now.setDate(now.getDate() - 7);
        now.setHours(0, 0, 0, 0);
        this.startDate = now.getTime() / 1000;
    }

    updateDateFilter(type: string, $event: MatDatepickerInputEvent<Date>) {
        if (type === 'start') {
            this.startDate = new Date($event.value).getTime() / 1000;
        }
        if (type === 'end') {
            this.endDate = new Date($event.value).setHours(23, 59, 59) / 1000;
        }
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
        }
    }

    displayTestStatus(status: MomtTestStatus) {
        return MomtStatusMap.get(status);
    }

    openTestDetails(test: MomtTestSummary) {
        if (test.status === MomtTestStatus.IDLE || test.status === MomtTestStatus.PROGRESS) {
            this.router.navigate(['/admin/telephony-new-test'], { queryParams: { id: test._id, type: test.type } });
        } else {
            this.router.navigate(['/admin/telephony-report'], { queryParams: { id: test._id } });
        }
    }

    checkIfFiltered(): boolean {
        return (this.startDate > 0 && this.endDate > 0) || this.searchValue.length > 0 || this.selectedStatuses.length > 0;
    }

    clearFilters() {
        this.startDate = 0;
        this.endDate = 0;
        this.searchValue = '';
        this.selectedStatuses = [];
        this.dateFilter = null;
        this.getFilteredTests();
    }

    openConfirmDelete(id: string) {
        this.dialogModalService.openConfirmationDialog('deleteMoMt', () => this.deleteTest(id));
    }

    deleteTest(id: string) {
        this.isLoading++;
        this.momtCallService.deleteTest(id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                this.getTests();
            }
        }, () => this.isLoading--);
    }

    checkIfFailedArtifact(artifacts: MomtArtifact[]): boolean {
        return artifacts.length > 0 && artifacts.some(artifact => artifact.status === ArtifactStatusEnum.FAILED);
    }

    getArtifactsSize(artifacts: MomtArtifact[]): number {
        if (!artifacts || !artifacts.length) { return 0; }
        return artifacts.reduce((acc, val) => {
            const size = val.result?.payload?.size;
            return size ? acc + size : acc;
        }, 0);
    }

    getFilteredTests() {
        this.currentPage = 0;
        this.getTests();
    }

    checkIfArtifactsReady(artifacts: MomtArtifact[]): boolean {
        return artifacts?.length > 0 && !artifacts.some(artifact => {
            return artifact.status === ArtifactStatusEnum.FINALIZING || artifact.status === ArtifactStatusEnum.PROGRESS || artifact.status === ArtifactStatusEnum.CANCELED;
        });
    }

    stopPolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }

    openTab(tab: RecordAndPlayTypeEnum): void {
        if (this.activeTab !== tab) {
            this.activeTab = tab;
            this.pageLimit = 10;
            this.currentPage = 0;
            this.setPage();
        }
    }

    openFilters(): void {
        this.isFilterOpen = !this.isFilterOpen;
    }

    openTestCaseDetails(i: number) {
        this.sidePanelData = this.testCases[i];
        this.selectedIndex = i;
        this.drawer.toggle();
    }

    openNewTest(test: TelephonyTestCase) {
        this.router.navigate(['/admin/telephony-new-test'], { queryParams: { type: test.type } });
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
        this.selectedIndex = null;
    }

    setPage() {
        const total = this.activeTab === RecordAndPlayTypeEnum.CASE ? this.totalTestCases : this.totalTests;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
    }

    getTestCaseIcon(type: string): string {
        switch (type) {
            case 'MO_MT':
                return '../../../../assets/images/main-header/menu_call_selected.svg';
            case 'MT_VOICEMAIL':
                return '../../../../assets/images/telephony-actions/voicemail.svg';
            case 'MO_SMS':
            case 'MO_MT_SMS':
                return '../../../../assets/images/telephony-actions/message.svg';
            case 'MO_MT_CALL_SMS':
                return '../../../../assets/images/telephony-actions/call_message.svg';
            case 'SPEED_TEST':
                return '../../../../assets/images/telephony-actions/speed_test.svg';
            default:
                return '../../../../assets/images/main-header/menu_call_selected.svg';
        }
    }

    displayTestType(type: TelephonyTestType): string {
        return MomtTypeMap.get(type);
    }

    rerunTest(test: MomtTestSummary) {
        this.router.navigate(['/admin/telephony-new-test'], { queryParams: { id: test._id, type: test.type } });
    }

    toggleTooltip(tooltip: TippyDirective) {
        if (tooltip.isVisible) {
            tooltip.hide();
        } else {
            tooltip.show();
        }
    }

    getExecutionTime(testCase: TelephonyTestCase) {
        const executionTime = this.momtCallService.getTestCaseEstimate(testCase);
        if (!isNaN(executionTime)) {
            const minutes = Math.floor(executionTime / 60000);
            const seconds = Math.floor((executionTime - (minutes * 60000)) / 1000);
            const milliseconds = Math.round((executionTime - (minutes * 60000)) % 1000);
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(milliseconds).padStart(3, '0')}`;
        } else {
            return 'NA';
        }
    }

    getNoOfIterationsForStatus(list: any, status: string): number {
        return list.filter((i) => i._id.toLowerCase() === status.toLowerCase())[0]?.count;
    }
}
