import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
    selector: 'app-dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss']
  })

  export class DropzoneComponent {
    @Input() file: File;
    @Input() warning: string;
    @Input() hasError: boolean;
    @Input() maxSize: number;
    @Input() isDisabled = false;

    @Input() percent = 0;

    @Output() fileSelected = new EventEmitter();

    @HostListener('dragover', ['$event']) onDragOver(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    @HostListener('drop', ['$event']) onDrop(evt) {
      evt.preventDefault();
      evt.stopPropagation();

      const files = evt.dataTransfer.files;
      if(files.length > 0 && !this.isDisabled) {
        this.handleFile(files[0]);
      }
    }


    handleFileUpload($event: Event) {
      const target = $event.target as HTMLInputElement;
      if(target.files.length > 0) {
        this.handleFile(target.files[0]);
      }
    }

    handleFile(file:File) {
      if(file.size <= this.maxSize) {
        this.file = file;
        this.fileSelected.emit(file);
      }
    }
  }