import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { TokenData } from '../../../../shared/interfaces';
import { CommonService } from '../../../../shared/common.service';
import { GetSubscriptionListDto } from '../../../../shared/dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-create-public-user',
    templateUrl: './create-public-user.component.html',
    styleUrls: ['./create-public-user.component.scss']
})
export class CreatePublicUserComponent implements OnInit {
    userForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    isPasswordShown = false;
    isLower = true;
    isUpper = true;
    isSpecial = true;
    isSecure = true;
    isLoading = false;
    userData: TokenData;
    subscriptionOptions = [];
    expirationDate = 0;
    minStartDate = new Date();
    minEndDate = new Date();

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.userForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            subscriptionId: ['', [Validators.required]],
            subscriptionStart: ['', [Validators.required]],
            subscriptionEnd: ['']
        });

        this.getSubscriptions();
    }

    getSubscriptions() {
        this.isLoading = true;
        const query = 'type=COMMON,PUBLIC&status=ACTIVE';
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const result = res.data as GetSubscriptionListDto;
                this.subscriptionOptions = result.subscriptions.map((subscription) => ({ id: subscription._id, name: subscription.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    createUser() {
        if (this.userForm.valid) {
            const {
                firstName,
                lastName,
                email,
                password,
                subscriptionId,
                subscriptionStart,
                subscriptionEnd
            } = this.userForm.value;
            const endOfDay = new Date().setHours(23, 59, 59);

            const request = {
                firstName,
                lastName,
                email,
                password,
                subscriptionId,
                subscriptionStart: subscriptionStart < endOfDay ? subscriptionStart.getTime() : subscriptionStart.setHours(23, 59, 59),
                subscriptionEnd: subscriptionEnd ? subscriptionEnd.setHours(23, 59, 59) : 8640000000000,
                expirationDate: this.expirationDate
            };

            this.isLoading = true;
            this.adminService.createPublicUser(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/users']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }


    generatePassword() {
        const length = 8;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        retVal = 'Sn_' + retVal + '12';
        this.userForm.controls['password'].setValue(retVal);
        this.isSecure = true;
    }

    showPassword() {
        this.isPasswordShown = !this.isPasswordShown;
    }

    checkPassword() {
        const password = this.userForm.value.password;
        if (password.length < 2) {
            this.isUpper = true;
            this.isLower = true;
            this.isSpecial = true;
            return;
        }
        this.isLower = !!password.match('[a-z]');
        this.isUpper = !!password.match('[A-Z]');
        this.isSpecial = !!password.match('[#$^%,!&@_\.-]');

        this.isSecure = this.isLower && this.isUpper && this.isSpecial;
    }

    clearExpirationDate(event: Event) {
        event.stopPropagation();
        this.expirationDate = 0;
    }

    updateExpirationDate($event: MatDatepickerInputEvent<Date>) {
        this.expirationDate = new Date($event.value).setHours(23, 59, 59);
    }

    resetExpiration() {
        this.userForm.controls.subscriptionEnd.setValue('');
    }

    displayExpirationDate() {
        const date = new Date(this.expirationDate);
        return `Expiration Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    updateExpirationMin() {
        this.minEndDate.setDate(this.userForm.value.subscriptionStart.getDate() + 1);
        if (this.userForm.value.subscriptionEnd < this.userForm.value.subscriptionStart) {
            this.userForm.controls.subscriptionEnd.setValue('');
        }
    }
}

