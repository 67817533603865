import { Component, OnInit, ViewChild } from '@angular/core';
import { Feature, TokenData } from '../../../shared/interfaces';
import { DateFilterTypeEnum, FeatureTypeEnum, UserRoleEnum } from '../../../shared/enum';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../shared/common.service';
import { AdminService } from '../admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

type InvoiceType = 'public' | 'corporate';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.components.scss']
})
export class InvoiceComponent implements OnInit {

    userData: TokenData;
    activeTab: InvoiceType = 'corporate';
    totalPublic = 0;
    totalCorporate = 0;
    currentPage = 0;
    isLoading = 0;
    searchText = '';
    isFiltered = false;
    isFilterOpen = false;
    pageLimit = 10;
    searchEnterprise = '';
    tempSearchEnterprise = '';
    searchEngagement = '';
    tempSearchEngagement = '';
    searchFeatures: { item_id: FeatureTypeEnum, item_text: string }[] = [];
    dateFilter: DateFilterTypeEnum;
    FilterType = DateFilterTypeEnum;
    startDate = 0;
    endDate = 0;
    tempSearchFeatures: { item_id: FeatureTypeEnum, item_text: string }[] = [];
    tempDateFilter: DateFilterTypeEnum;
    tempStartDate = 0;
    tempEndDate = 0;
    enterpriseOptions = [];
    featureList = [];
    invoicesList = [];
    totalPages = 0;
    paginationText = '0-0 of 0';
    total = 0;
    searchTimeout;

    @ViewChild('rangePicker') rangePicker: MatDateRangePicker<unknown>;

    constructor(
        public router: Router,
        private commonService: CommonService,
        private adminService: AdminService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.getRouteQuery();
        this.getInvoiceList();
        this.getEnterprises();
        this.getFeatures();
    }

    openTab(tab: InvoiceType) {
        if (this.activeTab !== tab) {
            this.activeTab = tab;
            this.currentPage = 0;
        }
    }

    getInvoiceList() {
        this.isLoading++;
        this.adminService.getInvoices(this.getQueryParam()).subscribe((res) => {
            this.isLoading--;
            if (res['message'] === 'Success') {
                this.totalCorporate = res.data?.totalElements;
                this.invoicesList = res.data.invoices;
                this.setPage(res.data);
            }
        }, err => {
            this.isLoading--;
        });
    }
    getQueryParam() {
        let query = `page=${this.currentPage}&size=${this.pageLimit}`;
        if (this.searchText) {
            query += `&text=${encodeURIComponent(this.searchText.trim())}`;
        }
        if (this.searchEnterprise) {
            query += `&enterpriseId=${this.searchEnterprise}`;
        }
        if (this.searchEngagement) {
            query += `&engagement=${this.searchEngagement}`;
        }
        if (this.searchFeatures.length > 0) {
            query += `&features=${this.searchFeatures.map((feature) => feature.item_id).join(',')}`;
        }
        if (this.startDate) {
            query += `&startDate=${this.startDate}`;
        }
        if (this.endDate) {
            query += `&endDate=${this.endDate}`;
        }
        return query;
    }

    openCreate() {
        this.navigatePage('/admin/create-invoice', { type: this.activeTab });
    }

    navigatePage(path: string, query?: Record<string, string>) {
        this.router.navigate([path], { queryParams: query });
    }

    getEnterprises() {
        if (this.userData.role === UserRoleEnum.ADMIN) {
            this.isLoading++;
            this.adminService.getEnterprise('').subscribe((res) => {
                this.isLoading--;
                if (res['message'] == 'Success') {
                    this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
                }
            }, err => {
                this.isLoading--;
            });
        }
    }

    getRouteQuery() {
        const enterpriseId = this.route.snapshot.queryParamMap.get('enterpriseId');
        if (enterpriseId) {
            this.activeTab = 'corporate';
            this.searchEnterprise = enterpriseId;
        }
    }

    setPage(data: any) {
        this.total = data.totalElements;
        this.totalPages = data.totalPages;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${this.total < max ? this.total : max} of ${this.total}`;
    }

    changePageLimit() {
        this.currentPage = 0;
        this.getInvoiceList();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.getInvoiceList();
    }

    checkIsFiltered() {
        this.isFiltered = !!this.searchText ||
            !!this.searchEnterprise ||
            !!this.searchEngagement ||
            this.searchFeatures.length > 0 ||
            !!this.startDate ||
            !!this.endDate;
    }

    openFilters(isOpen: boolean) {
        if (isOpen) {
            this.tempSearchEnterprise = this.searchEnterprise;
            this.tempSearchEngagement = this.searchEngagement;
            this.tempSearchFeatures = [...this.searchFeatures];
            this.tempStartDate = this.startDate;
            this.tempEndDate = this.endDate;
        }
        this.isFilterOpen = isOpen;
    }

    clearFilters() {
        this.resetPage();
        this.getInvoiceList();
    }

    resetPage() {
        this.isFiltered = false;
        this.searchText = '';
        this.searchEnterprise = '';
        this.searchEngagement = '';
        this.searchFeatures = [];
        this.startDate = 0;
        this.endDate = 0;
        this.isFilterOpen = false;
    }

    applyFilters() {
        this.searchEnterprise = this.tempSearchEnterprise;
        this.searchEngagement = this.tempSearchEngagement;
        this.searchFeatures = [...this.tempSearchFeatures];
        this.startDate = this.tempStartDate;
        this.endDate = this.tempEndDate;
        this.checkIsFiltered();
        this.openFilters(false);
        this.getInvoiceList();
    }

    searchChange() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.checkIsFiltered();
            this.getInvoiceList();
        }, 1000);
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    getFeatures() {
        this.isLoading++;
        this.adminService.getFeatureList().subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                const data = res.data as { features: Feature[] };
                this.featureList = data.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));
            }
        }, err => {
            this.isLoading--;
        });
    }

    setDateFilter(param: DateFilterTypeEnum): void {
        if (this.tempDateFilter === param) {
            this.tempDateFilter = null;
            this.tempStartDate = null;
            this.tempEndDate = null;
        } else {
            this.tempDateFilter = param;
            const now = new Date();
            switch (param) {
                case DateFilterTypeEnum.TODAY:
                    this.tempEndDate = now.getTime() / 1000;
                    now.setHours(0, 0, 0, 0);
                    this.tempStartDate = now.getTime() / 1000;
                    break;
                case DateFilterTypeEnum.LAST_WEEK:
                    this.tempEndDate = now.getTime() / 1000;
                    now.setDate(now.getDate() - 7);
                    now.setHours(0, 0, 0, 0);
                    this.tempStartDate = now.getTime() / 1000;
                    break;
                case DateFilterTypeEnum.CUSTOM:
                    this.rangePicker.open();
                    break;
            }
        }
    }

    updateDateFilter(type: string, $event: MatDatepickerInputEvent<Date>) {
        if (type === 'start') {
            this.tempStartDate = new Date($event.value).getTime() / 1000;
        }
        if (type === 'end') {
            this.tempEndDate = new Date($event.value).setHours(23, 59, 59) / 1000;
        }
    }

    getTotalPublic($event: number) {
        this.totalPublic = $event;
    }
}
