import { TestsApiErrorDto } from "../dto";
import { CustomTestFormStep, CustomTestDevice, CustomTestReplayStatusEnum, CustomTestArtifact } from "./";

export interface CustomDeviceInfo {
    sn: string;
    phone?: string;
    os: string;
    name: string;
    index: number;
    noVerifications?: boolean;
}

export enum CustomTestExecutionStatusEnum {
    RUNNING = 'RUNNING',
    EXECUTING = 'EXECUTING',
    FINALIZING = 'FINALIZING',
    IDLE = 'IDLE',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    CANCELLING = 'CANCELLING'
}

export interface CustomTestExecution extends TestsApiErrorDto {
    id: string;
    testId: string;
    name: string;
    replayCount: number;
    replayDelay: number;
    testExecutionTime: number | null;
    startedAt: number | null;
    endedAt: number | null;
    devices: CustomTestDevice[];
    steps: CustomTestFormStep[];
    logs: CustomTestArtifact[];
    analytics?: {
        cancelledReplayCount: number;
        cancellingReplayCount: number;
        completedReplayCount: number;
        failRate: number;
        failedReplayCount: number;
        idleIterationCount: number;
        inProgressReplayCount: number;
        passRate: number;
    };
    activeReplay?: {
        id: string;
        index: number;
        startedAt: number;
        status: CustomTestReplayStatusEnum;
    }
    status: CustomTestExecutionStatusEnum;
}

export const CustomTestExecutionStatusMap = new Map<CustomTestExecutionStatusEnum, string>([
    [CustomTestExecutionStatusEnum.IDLE, 'Not Started'],
    [CustomTestExecutionStatusEnum.EXECUTING, 'Executing'],
    [CustomTestExecutionStatusEnum.RUNNING, 'Running'],
    [CustomTestExecutionStatusEnum.FINALIZING, 'Finalizing'],
    [CustomTestExecutionStatusEnum.COMPLETED, 'Completed'],
    [CustomTestExecutionStatusEnum.FAILED, 'Failed'],
    [CustomTestExecutionStatusEnum.CANCELED, 'Canceled'],
    [CustomTestExecutionStatusEnum.CANCELLING, 'Cancelling'],
]);