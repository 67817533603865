<div class="main-header">
    <div>{{testInfo?.name}}</div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="header-info">
        <div class="info-wrapper">
            <div class="sub-header">Description</div>
            <div class="description-wrapper">{{testInfo?.description}}</div>
        </div>
        <div class="divider"></div>
        <div class="info-wrapper">
            <div class="sub-header">Environment</div>
            <div class="info-value">
                Devices: {{testInfo?.requisites?.environment?.devices || '-'}}
            </div>
            <div class="inner-divider env-element">|</div>
            <div class="info-value">
                SIM cards: {{testInfo?.requisites?.environment?.simCards || '-'}}
            </div>
        </div>
        <div class="divider"></div>
        <div class="info-wrapper">
            <div class="sub-header">Features</div>
            <feature-list [features]="testInfo?.requisites?.features"></feature-list>
        </div>
    </div>
    <table *ngIf="testInfo.type !== 'SPEED_TEST'">
        <tbody>
        <tr>
            <td class="action-icon">
                <img src="../../../../assets/images/telephony-actions/start.svg" alt="">
            </td>
            <td class="type-col">START</td>
        </tr>
        <tr *ngFor="let action of testInfo?.actions; index as i">
            <td class="action-icon">
                <img src="../../../../assets/images/telephony-actions/{{getActionIconName(action.type)}}.svg" alt="">
            </td>
            <td class="type-col">{{displayActionType(action.type) | uppercase}}</td>
        </tr>
        </tbody>
    </table>
    <span class="fake-gap"></span>
</div>

