import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
    selector: 'app-device-dropdown',
    templateUrl: './device-dropdown.component.html',
    styleUrls: ['./device-dropdown.component.scss']
})
export class DeviceDropdownComponent {
    isOpen: boolean = false;
    @Input() deviceInfo: any;

    @Output() customizeClick = new EventEmitter<number>();
    @Output() changeClick = new EventEmitter<number>();
    @Output() releaseClick = new EventEmitter<number>();

    clickedInside = false;
    @HostListener("click")
    clicked() {
        this.clickedInside = true;
    }
    @HostListener("document:click")
    clickedOutside() {
        if(!this.clickedInside) {
            this.isOpen = false;
        }
        this.clickedInside = false;
    }

    customize() {
        this.customizeClick.emit(this.deviceInfo.index);
        this.isOpen = false;
    }
    change() {
        this.changeClick.emit(this.deviceInfo.index);
        this.isOpen = false;
    }
    release() {
        this.releaseClick.emit(this.deviceInfo.index);
        this.isOpen = false;
    }

    switchView() {
        this.isOpen = !this.isOpen;
    }
}