<div class="row sub_header">
  <p>Device List</p>
</div>
<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="filters mt-3">
  <div class="row">
    <div class="form-group col-md-2  pr-md-0">
      <select class="form-control select_input " (change)="onDeviceTypeSelectChange()" [(ngModel)]="searchType">
        <option value="">All Device Types</option>
        <option [value]="item" style="text-transform: capitalize;" *ngFor="let item of oemList">{{item}}</option>
      </select>
    </div>

    <div class="form-group col-md-2  pr-md-0" *ngIf="userData?.role=='ADMIN'">
      <select class="form-control select_input " (change)="AvailableDeviceList()" [(ngModel)]="searchEnterprise">
        <option value="">All Enterprises</option>
        <option [value]="item.id" style="text-transform: capitalize;" *ngFor="let item of enterpriseOptions">
          {{item.name}}
        </option>
      </select>
    </div>
    <div class="form-group col-md-2  pr-md-0" *ngIf="userData?.role=='ADMIN'">
      <select class="form-control select_input " (change)="AvailableDeviceList()" [(ngModel)]="labType">
        <option value="">All Labs</option>
        <option [value]="item.labId" style="text-transform: capitalize;" *ngFor="let item of labList">
          {{item.name}}
        </option>
      </select>
    </div>

    <div class="form-group col-md-2  pr-md-0">
      <select class="form-control select_input" (change)="AvailableDeviceList()" [(ngModel)]="searchLocation">
        <option value="">All Locations</option>
        <option *ngFor="let item of locationListDropdown" [value]="item">
          {{ item }}
        </option>
      </select>
    </div>

    <div class="position-relative col-md-2 ">
      <input [(ngModel)]="searchEmei" type="text" maxlength="50" (input)="textChange()"
        (keydown.enter)="AvailableDeviceList();" class="form-control login_input position-relative w-100 select_input"
        placeholder="IMEI Number" />
    </div>
    <div class="col-md-1">
      <button class="btn search_btn_advance search_btn no_search_btn mr-2"
        (click)="AvailableDeviceList()">Search</button>
    </div>
    <div class="col-md-1 clear-button">
      <button class="btn search_btn_advance clear_btn" (click)="clearFilter()">
        Clear Filters
      </button>
    </div>

  </div>
  <div class="row filters-wrapper">
    <div class="booked-filter">
      <span (click)="filterByBooked('available')" class="ml-3"
        [ngClass]="{'activeFilter': this.selectedBookedFilter === 'available'}">
        <span class="_box_filter  mr-2 circle available_circle"></span>
        <span style="text-transform: capitalize !important;">Not Booked</span>
        ({{bookingFilterList['available']?.length}})
      </span>
      <span (click)="filterByBooked('booked')" class="ml-3"
        [ngClass]="{'activeFilter': this.selectedBookedFilter === 'booked'}">
        <span class="_box_filter  mr-2 circle booked_circle"></span>
        <span style="text-transform: capitalize !important;">Booked</span> ({{bookingFilterList['booked']?.length}})
      </span>
      <span (click)="filterByBooked('NA')" class="ml-3"
        [ngClass]="{'activeFilter': this.selectedBookedFilter === 'NA'}">
        <span class="_box_filter  mr-2 circle na_circle"></span>
        <span style="text-transform: capitalize !important;">NA</span> ({{bookingFilterList['NA']?.length}})
      </span>
    </div>
    <div class="filter">
      <span (click)="ApplyFilter(item.key)" class="ml-3" [ngClass]="{'activeFilter':this.deviceState  == item.key}"
        *ngFor="let item of filterList | keyvalue">
        <span class="_box_filter remove_bg mr-2"
          [ngClass]="{'testing':item.key == 'TESTING','online_bg':item.key == 'ONLINE','offline_bg':(item.key == 'UNINITIALIZED' || item.key == 'UNCONFIGURED' || item.key == 'UNAUTHORIZED' || item.key == 'OFFLINE' || item.key == 'DETACHED')}">
        </span>
        <span style="text-transform: capitalize !important;">{{item.key}}</span> ({{item.value.length}})
      </span>
      <div class="filter-help ml-2" (click)="toggleSidepanelAction()">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
          <path
            d="M481.12 785.231q13.265 0 22.38-9.159 9.115-9.159 9.115-22.423 0-13.264-9.158-22.38-9.159-9.115-22.423-9.115-13.265 0-22.38 9.159-9.116 9.158-9.116 22.423 0 13.264 9.159 22.38 9.159 9.115 22.423 9.115Zm-20.659-132.462h38.616q1.538-26.077 9.808-42.384 8.269-16.308 34.038-41.616 26.769-26.769 39.846-47.961 13.077-21.193 13.077-49.059 0-47.288-33.229-75.365-33.23-28.077-78.617-28.077-43.154 0-73.269 23.462-30.116 23.462-44.116 53.923l36.77 15.231q9.615-21.846 27.5-38.615 17.884-16.77 51.577-16.77 38.923 0 56.846 21.347 17.923 21.346 17.923 46.961 0 20.769-11.231 37.115-11.231 16.347-29.231 32.885-34.769 32.077-45.538 54.385-10.77 22.307-10.77 54.538ZM480.134 936q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120 650.806 120 576.134q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194 216 479.866 216q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840 501.194 840 575.866q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806 936 480.134 936ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
      </div>
    </div>
  </div>
  <!-- <div class="mt-2 ml-3">
          <button class="btn clear_btn form-control" (click)="clearFilter()" >Clear Filters</button>
        </div> -->
</div>
<!-- DEVICES LIST -->
<!-- GREY TABLE -->
<div class="table-responsive device_table mt-2 p-0">
  <!-- <app-no-file *ngIf="deviceList.length == 0"></app-no-file> -->
  <table class="table smartTable">
    <thead class="tableheading inner_table_head">
      <tr>
        <!-- <th scope="col">Category</th> -->
        <th scope="col" class="_noWrap">OEM</th>
        <th scope="col" class="_noWrap">MODEL NAME</th>
        <th scope="col" class="_noWrap">OS</th>
        <th scope="col" class="_noWrap">SERIAL NO/UUID</th>
        <th scope="col" class="_noWrap">LAB</th>
        <th scope="col" class="_noWrap">LOCATION</th>
        <th scope="col" class="_noWrap">DEVICE STATUS</th>
        <th scope="col" class="_noWrap">BOOK STATUS</th>
        <th scope="col" class="_noWrap">BOOKED BY</th>
        <th scope="col" class="_noWrap">CHECK IN</th>
        <th scope="col" class="_noWrap">CHECK OUT</th>
        <th scope="col" class="_noWrap">IMEI</th>
        <!--                  <th scope="col" class="_noWrap">HOST PORT</th>-->
        <th scope="col" class="_noWrap" *ngIf="userData?.role=='ADMIN'">LAB TYPE</th>
        <!-- <th scope="col" class="_noWrap" *ngIf="userData?.role=='ADMIN'">LAB TYPE</th> -->
        <!-- <th scope="col" class="_noWrap">AVAILABLE</th> -->
        <!-- <th scope="col" class="_noWrap" *ngIf="userData?.role=='ADMIN'">PRICE ($)</th> -->
        <th class="fix_th"></th>
      </tr>
    </thead>
    <tbody>
      <tr class="position-relative" *ngFor="let item of deviceList; index as i"
        [ngClass]="{'resetting_box_table':item.isResetting == true, active_prop: selectedIndex === i}"
        (click)="togglePropertySidePanelAction(item.serialNumber, i)">
        <td class="_noWrap" style="text-transform: capitalize;">{{ item.oem }}</td>
        <td class="_noWrap">{{ item.modelName }}</td>
        <td class="_noWrap">{{ item.osVersion }}</td>

        <td class="_noWrap">{{ item.serialNumber }}</td>

        <td class="_noWrap">{{ getLabName(item.labId) }}</td>
        <td class="_noWrap">{{ item.labLocation }}</td>
        <td class="_noWrap">
          <div class="" [ngClass]="{'tooltip3 airplane':showError.indexOf(item.deviceStateCode) !== -1}">
            <span class="_box_ remove_bg {{getDeviceStatus(item) | lowercase}}"
              [ngClass]="{'blurred-icon': item.isResetting == true}"> <span class="_symbol"
                *ngIf="showError.indexOf(item.deviceStateCode) !== -1">!</span> </span>
            <span *ngIf="showError.indexOf(item.deviceStateCode) !== -1"
              class="tooltiptext">{{showOfflineTooltip(item)}}</span>
            <span class="ml-2">{{getDisplayedStatus(getDeviceStatus(item)) | uppercase}}</span>
          </div>
        </td>
        <td class="_noWrap">
          <span *ngIf="!item.isBooked && item.deviceState === 'Online'"><span
              [ngClass]="{'blurred-icon': item.isResetting}" class="circle available_circle mr-2"></span> Not
            Booked</span>
          <span *ngIf="!item.isBooked && item.deviceState === 'Offline'"><span
              [ngClass]="{'blurred-icon': item.isResetting}" class="circle na_circle mr-2"></span> NA</span>
          <span *ngIf="item.isBooked"><span [ngClass]="{'blurred-icon': item.isResetting}"
              class="circle booked_circle mr-2"></span> Booked</span>
        </td>

        <td class="_noWrap">{{ item?.bookedByUserName? item?.bookedByUserName:'--' }}</td>

        <td class="_noWrap">{{ item?.bookedFromESTDate? item?.bookedFromESTDate:'--' }}</td>
        <td class="_noWrap">{{ item?.bookedUntilESTDate? item?.bookedUntilESTDate:'--' }}</td>
        <td class="_noWrap">{{ item.imei }}</td>
        <!--                  <td class="_noWrap"  >-->
        <!--                    <span class="portNo"  [ngClass]="{'blurred': item.isResetting == true}"  *ngIf="item.hostPort == -1" style="color: blue;text-decoration: underline;cursor: pointer;"  #AssBtn1 (click)="openModal(Assign, AssBtn1, item)" >NA</span>-->
        <!--                    <span style="text-decoration: underline;cursor: pointer;"  #AssBtn1 (click)="openModal(Assign, AssBtn1, item)" class="portNo primaryColr" [ngClass]="{'blurred': item.isResetting == true}" *ngIf="item.hostPort != -1">{{ item.hostPort }}</span>-->
        <!--                  </td>-->
        <td class="_noWrap" *ngIf="userData?.role=='ADMIN'" style="text-transform: capitalize;">{{ item.labType }}</td>
        <!-- <td  class="_noWrap">{{item.availableDevice -item.selectDevice}}</td> -->
        <!-- <td class="_noWrap" *ngIf="userData?.role=='ADMIN'">
                    <span *ngIf="item.labType =='public'">{{ item.price }}</span>
                    <span *ngIf="item.labType !=='public'">--</span>
                  </td> -->
        <td class="_noWrap fixAction p-0">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="handleContextMenuClick($event)">
            <span class="menuIcon">
              <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
            </span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="Menubtn_" #BTN1 (click)="moreDetailModal(txnModal, BTN1,item)">
              <span>Get book history</span>
            </button>
            <button *ngIf="item.isBooked == true" class="Menubtn_" mat-menu-item #BTN
              (click)="openConfirmationModal(deletemodal2, BTN,item)">
              <span>Release device</span>
            </button>
            <button *ngIf="showDetachButton(item)" class="Menubtn_" mat-menu-item (click)="usbDetachApi(item,i)"
              [ngClass]="{'disabled':item.isEnabled == true }">
              <span>Detach device</span>
            </button>
            <button class="Menubtn_" mat-menu-item (click)="usbAttachApi(item,i)"
              [ngClass]="{'disabled-link':item.isEnabled == true }" *ngIf="item.deviceStateCode == 'DETACHED'">
              <span>Attach device</span>
            </button>
            <button class="Menubtn_" mat-menu-item #BTN (click)="openConfirmationModal(deletemodal3, BTN,item)"
              *ngIf="item.deviceStateCode == 'REMOVED' || item.deviceStateCode == 'DISCONNECTED'">
              <span>Delete device</span>
            </button>
            <button class="Menubtn_"
              *ngIf="item.usbHubType && item.usbHubType !== 'GENERAL' && item.isSmartHubEnabled && item.deviceStateCode == 'UNINITIALIZED'"
              mat-menu-item #BTN (click)="resetCambrionix(item.labDomain, item.serialNumber, i)">
              <span>Reset device</span>
            </button>
          </mat-menu>
        </td>
        <td class="_noWrap text_capital" style="text-transform: uppercase;" *ngIf="item.isResetting == true">

          <span class="table_box">
            <img class="close_icon_table" *ngIf="item.resetCount  >3 " (click)="closeReset(i,'ALL')"
              src="../../../../assets/images/NewUI/ic_close.svg" alt="">
            <span class="reset_text_table" *ngIf="item.resetCount <4 "> Resetting ...</span>
            <span class="reset_text_table1" *ngIf="item.resetCount >3 ">Device is still not online. <br>You can try
              again after few minutes</span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="no_device" *ngIf="deviceList.length == 0">
    No Data Available
  </p>
</div>
<!-- GREY END TABLE -->

<ng-template #Assign let-modal>
  <app-spinner *ngIf="isLoading1"></app-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Change Port</h5>

    <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal">x</span>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="statusForm">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Existing Port</label>
                <div>
                  <input class="form-control login_input position-relative w-100" type="text" [disabled]="true"
                    value="{{ data.hostPort }}" />
                </div>
              </div>
              <div class="form-group col-md-12 mt-2 mb-3 " for="selectpicker">
                <!-- <label>New Status</label>
                    <select class="form-control select_input mt-1" formControlName="status" >
                        <option value="">Select</option>
                        <option value="inactive" *ngIf="data.status == 'active'">Deactivate</option>
                        <option value="active" *ngIf="data.status == 'inactive'">Active</option>
                    </select> -->
                <div>
                  <input class="form-control login_input position-relative w-100" type="text" formControlName="status"
                    value="{{ data.hostPort }}" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row justify-content-end">
      <button type="submit" class="add_card" (click)="updatePort(statusForm.value)">Save</button>
    </div>
  </div>
</ng-template>
<ng-template #txnModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Booking Details</h5>

    <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
      </button> -->
    <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal">x</span>
  </div>

  <div class="modal-body p-0">

    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="card- p-0 mt-2">
      <div class="table-responsive inner_table_responsive device_table ">

        <table class="table smartTable">
          <thead class="tableheading inner_table_head">
            <tr>
              <!-- <th scope="col">Category</th> -->
              <th scope="col" class="_noWrap">USER NAME</th>
              <th scope="col" class="_noWrap">USER EMAIL</th>
              <th scope="col" class="_noWrap">BOOKED ON</th>
              <th scope="col" class="_noWrap">CHECK IN</th>
              <th scope="col" class="_noWrap">CHECK OUT</th>
              <th scope="col" class="_noWrap" *ngIf="userData?.role=='ADMIN'">TOTAL PRICE($)</th>
            </tr>
          </thead>
          <tbody>
            <tr class="position-relative" *ngFor="let item of userDetail;index as i">
              <td class="_noWrap"><span *ngIf="item?.userData">{{item?.userData['name']?
                  item.userData['name']:'--'}}</span> <span *ngIf="!item?.userData"> --</span></td>
              <td class="_noWrap"> <span *ngIf="item?.userData"> {{item?.userData['email']?
                  item.userData['email']:'--'}}</span> <span *ngIf="!item?.userData"> --</span></td>
              <td class="_noWrap "> {{item?.estBookingDate?item.estBookingDate:'--'}}</td>
              <td class="_noWrap "> {{item?.bookedFrom *1000 | date: "MM/dd/yyyy hh:mm a"}}</td>
              <td class="_noWrap "> {{item?.bookedUntil *1000 | date: "MM/dd/yyyy hh:mm a"}}</td>
              <td class="_noWrap" *ngIf="userData?.role=='ADMIN'">
                {{item?.totalCartAmount}}
                <span></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row p-0 m-0 mt-2 mb-3 align-items-center" *ngIf="userDetail.length > 0">
          <div class="pagLimitSet ml-4">
            <select class="pagLimitSet_selecet" (change)="changePageLimit()" [(ngModel)]="pagelimit">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

          </div>
          <div class="col" style="margin-right:10px">
            <div class="pagination_custom" *ngIf="deviceList.length > 0">
              <ul class="d-flex flex-row">
                <li (click)="prevPage()" [ngClass]="{'disabled_pagination':currentPage  == 0}">
                  < </li>
                <li class="show_number"> {{currentPage + 1}} / {{totalPage }} </li>
                <li (click)="nextPage()" [ngClass]="{'disabled_pagination':currentPage + 1  == totalPage }"> > </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="no_device" *ngIf="deviceList.length == 0">Add device to preview them</p>
      </div>
      <div class="row col-md-12 justify-content-center" *ngIf="userDetail.length == 0">
        <p class="no_device text-center">No Data Available</p>
      </div>

    </div>
  </div>

</ng-template>
<ng-template #deletemodal2 let-modal>
  <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
  <!-- <div class="modal-header">
      <h5 class="modal-title">Release Device</h5>
    </div>
   -->
  <div class="modal-body p-5">
    <div class="col-md-12 p-0 m-0 text-center">
      <p class="modal-inner-text"> Are you sure you want to Release this Device?</p>
    </div>
    <div class="row col-md-12 justify-content-center mt-5 mr-0 ml-0">
      <button class="btn confirm_btn_modal mr-3" (click)="LeaveDevice()">Release</button>
      <button class="btn cancel_modal_btn" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</ng-template>
<ng-template #deletemodal3 let-modal>
  <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
  <!-- <div class="modal-header">
      <h5 class="modal-title">Release Device</h5>
    </div>
   -->
  <div class="modal-body p-5">
    <div class="col-md-12 p-0 m-0 text-center">
      <p class="modal-inner-text">Deleting the device will clear all the data associated with the device. Do you wish to
        continue?</p>
    </div>
    <div class="row col-md-12 justify-content-center mt-5 mr-0 ml-0">
      <button class="btn confirm_btn_modal mr-3" (click)="deleteDevices()">Delete</button>
      <button class="btn cancel_modal_btn" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</ng-template>

<mat-drawer #drawer mode="over" position="end">
  <app-howto-sidepanel (toggleSidepanel)="toggleSidepanelAction()"></app-howto-sidepanel>
</mat-drawer>
<mat-drawer #property mode="over" position="end">
  <property-side-panel (togglePropertySidePanel)="togglePropertySidePanelAction()" [serialNumber]="serialNumber"
    [deviceInfo]="deviceInfo" [properties]="properties" [userData]="userData" [wifiConnect]="wifiConnect"
    [storage]="storage" [battery]="battery" [providerName]="providerName"></property-side-panel>
</mat-drawer>