import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { FullComponent } from '../layouts/full/full.component';
import { AppHeaderComponent } from '../layouts/full/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { AdminMangementComponent } from './admin-mangement/admin-mangement.component';
import { DashboardComponent } from './admin-mangement/dashboard/dashboard.component';
import { DeviceListComponent } from './admin-mangement/device-list/device-list.component';
import { InvoiceListComponent } from './admin-mangement/invoices/invoice-list/invoice-list.component';
import { DashboardLabComponent } from './admin-mangement/dashboard-lab/dashboard-lab.component';

import { GoogleChartsModule, ScriptLoaderService } from 'angular-google-charts';
import { CKEditorModule } from 'ng2-ckeditor';
import { AlertListComponent } from './admin-mangement/alert-list/alert-list.component';
import { LabListComponent } from './admin-mangement/lab-list/lab-list.component';
import { CreateLabComponent } from './admin-mangement/create-lab/create-lab.component';
import { MaterialModule } from '../shared/material-ui.module';
import { DeviceRequestListComponent } from './admin-mangement/device-request-list/device-request-list.component';
import { SupportSectionComponent } from './support-section/support-section.component';
import { RelaseNotesComponent } from './relase-notes/relase-notes.component';
import { MomtCallModule } from './momt-call/momt-call.module';
import { PropertySidePanelComponent } from './view-device/property-sidepanel/property-side-panel.component';
import { DialogModalService } from '../shared/dialog-modal.service';
import { TippyDirective } from '@ngneat/helipopper';
import { UpdateLabComponent } from './admin-mangement/update-lab/update-lab.component';
import { SubscriptionListComponent } from './admin-mangement/subscription-list/subscription-list.component';
import { CreateSubscriptionComponent } from './admin-mangement/create-subscription/create-subscription.component';
import { UpdateSubscriptionComponent } from './admin-mangement/update-subscription/update-subscription.component';
import { CreateCorporateUserComponent } from './admin-mangement/users/create-corprorate-user/create-corporate-user.component';
import { UserManagementComponent } from './admin-mangement/users/user-management.component';
import { UserListComponent } from './admin-mangement/users/user-list/user-list.component';
import { UpdateCorporateUserComponent } from './admin-mangement/users/update-corporate-user/update-corporate-user.component';
import { CreatePublicUserComponent } from './admin-mangement/users/create-public-user/create-public-user.component';
import { CreateSupportUserComponent } from './admin-mangement/users/create-support-user/create-support-user.component';
import { UpdateSupportUserComponent } from './admin-mangement/users/update-support-user/update-support-user.component';
import { UpdatePublicUserComponent } from './admin-mangement/users/update-public-user/update-public-user.component';
import { EnterpriseManagementComponent } from './admin-mangement/enterprises/enterprise-management.component';
import { EnterpriseListComponent } from './admin-mangement/enterprises/enterprise-list/enterprise-list.component';
import { CreateEnterpriseComponent } from './admin-mangement/enterprises/create-enterprise/create-enterprise.component';
import { UpdateEnterpriseComponent } from './admin-mangement/enterprises/update-enterprise/update-enterprise.component';
import { ViewEnterpriseComponent } from './admin-mangement/enterprises/view-enterprise/view-enterprise.component';
import { ManualTestModule } from './manual-test/manual-test.module';
import { InvoiceComponent } from './admin-mangement/invoices/invoice.component';
import { CreateInvoiceComponent } from './admin-mangement/invoices/create-invoice/create-invoice.component';
import { InvoiceListCorporateComponent } from './admin-mangement/invoices/invoice-list-corporate/invoice-list-corporate.component';
import { UpdateInvoiceComponent } from './admin-mangement/invoices/update-invoice/update-invoice.component';
import { ViewInvoiceComponent } from './admin-mangement/invoices/view-invoice/view-invoice.component';
import {FreeDraggingDirective} from '../shared/directives/free-dragging.directive';
import { AppSideNavigationComponent } from '../layouts/full/side-navigation/side-navigation.component';
import { VideoPlayerComponent } from './support-section/video-player/video-player.component';
import { BookingManagerComponent } from './booking-manager/booking-manager/booking-manager.component';
import { BookingSchedulerComponent } from './booking-manager/booking-scheduler/booking-scheduler.component';
import { CustomCalendarHeaderComponent } from './booking-manager/components/custom-calendar-header/custom-calendar-header.component';
import { BookingModalComponent } from './booking-manager/components/create-booking-modal/booking-modal.component';
import { CustomTestModule } from './custom-test/custom-test.module';

@NgModule({
    declarations: [
        MainComponent,
        FullComponent,
        AppHeaderComponent,
        AdminMangementComponent,
        DashboardComponent,
        DeviceListComponent,
        InvoiceComponent,
        CreateInvoiceComponent,
        UpdateInvoiceComponent,
        InvoiceListComponent,
        InvoiceListCorporateComponent,
        DashboardLabComponent,
        AlertListComponent,
        LabListComponent,
        CreateLabComponent,
        DeviceRequestListComponent,
        SupportSectionComponent,
        RelaseNotesComponent,
        PropertySidePanelComponent,
        UpdateLabComponent,
        SubscriptionListComponent,
        CreateSubscriptionComponent,
        UpdateSubscriptionComponent,
        UserManagementComponent,
        UserListComponent,
        CreateCorporateUserComponent,
        CreatePublicUserComponent,
        CreateSupportUserComponent,
        UpdateCorporateUserComponent,
        UpdateSupportUserComponent,
        UpdatePublicUserComponent,
        EnterpriseManagementComponent,
        EnterpriseListComponent,
        CreateEnterpriseComponent,
        UpdateEnterpriseComponent,
        ViewEnterpriseComponent,
        ViewInvoiceComponent,
        AppSideNavigationComponent,
        VideoPlayerComponent,
        BookingManagerComponent,
        BookingSchedulerComponent,
        CustomCalendarHeaderComponent,
        BookingModalComponent
    ],
    imports: [
        CommonModule,
        MainRoutingModule,
        SharedModule,
        GoogleChartsModule,
        CKEditorModule,
        MaterialModule,
        MomtCallModule,
        TippyDirective,
        ManualTestModule,
        FreeDraggingDirective,
        CustomTestModule
    ],
    exports: [
        PropertySidePanelComponent,
        BookingSchedulerComponent
    ],
    providers: [ScriptLoaderService, DialogModalService]
})
export class MainModule { }
