<div class="row m-0 widthRow">
    <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
    <div class="card w-100">
      <div class="card-body p-0 ">
        <div class="row m-0 p-0">
          <div class="py-3 col-md-12 listFilters">
            <span class="compHeader listHeader">Alerts
                
            </span>
            <form class="form-inline my-2 my-lg-0 filtersDiv">
              
              <span class="filterSpan filterDrop" [ngClass]="{backgroundTheme:isFilterOpen == true || getFiltersValue()}">
                <span (click)="getFilterData($event)">
                  <img src="../../../../assets/images/filters/filter.svg" alt="" *ngIf="isFilterOpen == false && !getFiltersValue()">
                  <img src="../../../../assets/images/filters/filter_active.svg" alt="" *ngIf="isFilterOpen == true || getFiltersValue()">
                </span>
  
                <div *ngIf="isFilterOpen == true" class="detailDrop dropExtraWidth">
                  <div class="card w-100">
                    <div class="card-body p-2">
                     
                      <mat-form-field class="matFormSelect mt-2" >
                        <mat-select (change)="onSelectLabChange()" [(ngModel)]="labId" [ngModelOptions]="{ standalone: true }">
                          <mat-option value="">All Labs</mat-option>
                          <mat-option [value]="item.labId" style="text-transform: capitalize;" *ngFor="let item of labList">{{item.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                     
                      <mat-form-field class="matFormSelect mt-2" >
                        <mat-select (change)="setDefault()" [(ngModel)]="deviceId" [ngModelOptions]="{ standalone: true }">
                          <mat-option value="">All Devices</mat-option>
                          <mat-option [value]="item.deviceId" style="text-transform: capitalize;" *ngFor="let item of deviceList">{{item.modelName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                     
                      <mat-form-field class="matFormSelect mt-2" >
                        <mat-select (change)="setDefault()" [(ngModel)]="lType" [ngModelOptions]="{ standalone: true }">
                          <mat-option value="">All Alerts</mat-option>
                          <mat-option value="cpuUsagePercentage">Cpu Usage Percentage</mat-option>
                          <mat-option value="cpuLoadAvg">Cpu Load Avg</mat-option>
                          <mat-option value="diskUsedPercentage">Disk Used Percentage</mat-option>
                          <mat-option value="Ping">Ping</mat-option>
                          <mat-option value="labStatus">Lab Status</mat-option>
                          <mat-option value="deviceStatus">Device Status</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div class="form-group position-relative dateInputDiv mt-2">
                        <input readonly id="picker" placeholder="Start Date" class=""
                          [(ngModel)]="searchStartDate" [ngModelOptions]="{ standalone: true }" [matDatepicker]="picker" 
                          (focus)="picker.open()" (dateChange)="DateChange()" />
                        <div class="datepicker-toggle enterCompDatePicker">
                          <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                        </div>
                        <mat-datepicker #picker></mat-datepicker>
                       
                      </div>

                      <div class="form-group position-relative dateInputDiv mt-2">
                        <input readonly id="endpicker" placeholder="End Date" class=""
                        [(ngModel)]="searchEndDate" [ngModelOptions]="{ standalone: true }" [matDatepicker]="endpicker" 
                          (focus)="endpicker.open()" (dateChange)="DateChange()" />
                        <div class="datepicker-toggle enterCompDatePicker">
                          <mat-datepicker-toggle [for]="endpicker"></mat-datepicker-toggle>
                        </div>
                        <mat-datepicker #endpicker></mat-datepicker>
                       
                      </div>
                     
                      <hr class="mb-0">
                      <div class="filterButtons">
                        <button class="filterActionBtn" (click)="clearAll()">Cancel</button>
                        <button class="filterActionBtn" (click)="clearFilter()">Clear All</button>
                        <button class="filterActionBtn nameFilter" (click)="alertList()">Filter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
  
            </form>
          </div>
          <div class="col-md-12 px-3 position-relative">
            <div class="table-responsive table-container custom-scrollbar">
              <div class="custom-scrollbar-content" id="scroll-content" #scrollContent>
                <table class="table _noWrap dataTable simpleTable" 
                  [ngClass]="{w_97: hasVerticalScroll}">
                  <thead>
                    <tr>
                      <th  *ngIf=" configurations[0].isSelected == true" scope="col" class="_noWrap">CREATED ON</th>
                      <th  *ngIf=" configurations[1].isSelected == true" scope="col" class="_noWrap">ALERT TYPE</th>
                      <th  *ngIf=" configurations[2].isSelected == true" scope="col " class="_noWrap">LAB NAME</th>
                      <th  *ngIf=" configurations[3].isSelected == true" scope="col " class="_noWrap">DEVICE NAME</th>
                      <th  *ngIf=" configurations[4].isSelected == true" scope="col" class="_noWrap">STATUS</th>
                      <th  *ngIf=" configurations[5].isSelected == true" scope="col" class="_noWrap">MESSAGE</th>
                      <th class="configureTh custom-fixed-width" [ngClass]="{backgroundTheme: configureDrop == true}">

                        <span class=" cursor" (click)="changeConfigure()">
                          <span class="configureIcons" *ngIf="configureDrop == false">
                            <img src=".././../../../assets/images/filters/Group.svg" alt="">
                            <img  src="../../.././../assets/images/filters/down.svg" alt="">
                          </span>
                          <span class="configureIcons" *ngIf="configureDrop == true">
                            <img src=".././../../../assets/images/filters/Group_active.svg" alt="">
                            <img src="../../.././../assets/images/filters/down_active.svg" alt="">
                          </span>
                        </span>
    
                        <div *ngIf="configureDrop == true" class="detailDrop">
                          <div class="card" style="width: 200px;">
                            <div class="card-body p-2">
                              <div class="configurationList">
                              <div  *ngFor="let item of configurations">
                                <mat-checkbox [(ngModel)]="item.isSelected"
                                  [ngClass]="{'checked-checkbox': item.isSelected, 'unchecked-checkbox': !item.isSelected}" >{{
                                  item.name }}</mat-checkbox>
                              </div>
                            </div>
                              <hr class="mb-0">
                              <div class="restoreFilter">
                                <span> <button class="filterActionBtn nameFilter" (click)="restoreDefault()">Restore Default</button></span>
                                <span class="count_config">{{getSelectedConfig()}} Of {{configurations.length}} </span>
                              </div>
                            </div>
    
                          </div>
                        </div>
                      </th>
                    </tr>
                </thead>
                    <tbody>
                      <tr
                      *ngFor="let item of alertsList; index as i"
                        
                      >
                      <td *ngIf=" configurations[0].isSelected == true" class="_noWrap" style="text-transform: capitalize;">{{ item?.insertDate *1000 | date: "MM/dd/yyyy hh:mm a" }}</td>
                  <td *ngIf=" configurations[1].isSelected == true" class="_noWrap">
                    <span  class="th_value" *ngIf="item.type =='cpuUsagePercentage'">CPU Usage</span>
                    <span  class="th_value" *ngIf="item.type =='cpuLoadAvg'">CPU Load Avg</span>
                    <span  class="th_value" *ngIf="item.type =='diskUsedPercentage'">Disk Usage</span>
                    <span  class="th_value" *ngIf="item.type =='pingMs'">Ping</span>
                    <span  class="th_value" *ngIf="item.type =='labStatus'">Lab Status</span>
                    <span  class="th_value" *ngIf="item.type =='deviceStatus'">Device Status</span>
                  </td>
                  <td *ngIf=" configurations[2].isSelected == true" class="_noWrap">{{ item.labName? item.labName : '--' }}</td>
                  <td *ngIf=" configurations[3].isSelected == true" class="_noWrap">{{ item?.deviceData[0]?.modelName ? item?.deviceData[0]?.modelName:'--' }}</td>
                  <td *ngIf=" configurations[4].isSelected == true" class="_noWrap text-capitalize" [ngClass]="{statusErrorText: item.status == 'offline', statusOkText: item.status == 'online'}">{{ item.status ? item.status : '--' }}</td>
                  <td *ngIf=" configurations[5].isSelected == true" class="_noWrap" 
                  [tp]="tooltipComponent" tpPlacement="top"
                  [tpData]="item.message | tooltipData">
                  {{ item.message == ''? '--':item.message.length < 100 ? item.message : item.message.slice(0, 100)+'...' }}</td>
                  <!-- {{ item.message == ''? '--':item.message }} -->
                  <td class="custom-fixed-width "></td>

                      </tr>
                    </tbody>
                  </table>
  
            </div>
            <div class="custom-scrollbar-track" [ngClass]="{showNone: hasVerticalScroll == false}">
              <div class="custom-scrollbar-thumb" id="scroll-thumb" #scrollThumb></div>
            </div>
            </div>
            <div class="pagination-container" *ngIf="alertsList.length > 0">
              <span class="limit">
                <label for="limit">Rows per page: </label>
                <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pagelimit">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>
              <span>{{paginationText}}</span>
              <div class="pagination-buttons">
                <div [ngClass]="{ disabled_page: currentPage === 0 }" class="arrow-button" (click)="prevPage()">
                  <img alt="" src="../../../../assets/images/NewUI/left.svg">
                </div>
                <div [ngClass]="{ disabled_page: currentPage + 1 >= totalPages }" class="arrow-button" (click)="nextPage()">
                  <img alt="" class="arrow-next" src="../../../../assets/images/NewUI/right.svg">
                </div>
              </div>
            </div>
            <div class="empty-result" *ngIf="alertsList.length === 0 && !isLoading">
              <img alt="" src="../../../../assets/images/NewUI/noData.svg">
              <span class="empty-search-title">No data available</span>
              <span>
                  There is no data to show you right now
              </span>
          </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>