import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AlreadyBookedResponse, BestSlot, BookingType, CreateBookingForm, DayOfWeek, UpdateBookingForm } from "../../interfaces";
import { MatSelectChange } from "@angular/material/select";
import { BookingManagerService } from "../../booking-manager.service";
import { Subscription } from "rxjs";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TooltipService } from "../../../../shared/tooltip.service";

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit, OnDestroy {
  @Output() devicesBooked = new EventEmitter();
  @Output() close = new EventEmitter();

  selectedForBooking = [];
  bookingFrom: Date;
  bookingTimeFrom = { hour: '', minute: '' };
  bookingTo: Date;
  bookingTimeTo = { hour: '', minute: '' };
  endDate: Date;
  type = 'SINGLE';

  times = Array.from(Array(48)).map((_x, i) => ({ hour: Math.floor(i / 2).toString().padStart(2, '0'), minute: i % 2 === 0 ? '00' : '30' }));
  timesFrom = [];
  timesTo = [];
  startDate = new Date();
  minDate: Date;
  maxDate: Date;
  maxEndDate: Date;
  description = '';
  week = [DayOfWeek.SUNDAY, DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY, DayOfWeek.SATURDAY];
  selectedDevices = [];
  selectedDevicesSubscription: Subscription;
  selectedDate = new Date();
  selectedDateSubscription: Subscription;
  errorMessage = '';
  errors = [];
  duration = '';
  modalRef: NgbModalRef;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  tooltipComponent = null;

  bestSlot: BestSlot;

  @ViewChild('fromPicker') fromPicker: MatDatepicker<Date>;
  @ViewChild('toPicker') toPicker: MatDatepicker<Date>;
  @ViewChild('endPicker') endPicker: MatDatepicker<Date>;
  @ViewChild('bestSlotModal', { static: false }) bestSlotModal: TemplateRef<void>;

  constructor(
    public bookingService: BookingManagerService,
    private modalService: NgbModal,
    private tooltipService: TooltipService,
  ) {
    this.selectedDevicesSubscription = this.bookingService.selectedDevices$.subscribe((devices) => this.selectedDevices = devices);
    this.selectedDateSubscription = this.bookingService.selectedDate$.subscribe((date) => this.selectedDate = date);
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    if (this.bookingService.selectedTimeSlot) {
      const hour = this.bookingService.selectedTimeSlot.hour.toString().padStart(2, '0');
      const minute = this.bookingService.selectedTimeSlot.half === 1 ? '00' : '30';
      if (this.bookingService.selectedTimeSlot.booking) {
        this.selectedForBooking = this.selectedDevices.filter((d) => d._id === this.bookingService.selectedTimeSlot.booking.deviceId);
        const dateFrom = new Date(this.bookingService.selectedTimeSlot.booking.bookedFrom * 1000);
        const bookingTimeFrom = this.times.find((time) => parseInt(time.hour) === dateFrom.getHours() && parseInt(time.minute) === dateFrom.getMinutes());
        dateFrom.setHours(0, 0, 0, 0);
        const dateUntil = new Date((this.bookingService.selectedTimeSlot.booking.bookedUntil + 1) * 1000);
        const bookingTimeUtil = this.times.find((time) => parseInt(time.hour) === dateUntil.getHours() && parseInt(time.minute) === dateUntil.getMinutes());
        dateUntil.setHours(0, 0, 0, 0);
        this.bookingFrom = dateFrom;
        this.bookingTimeFrom = bookingTimeFrom;
        this.bookingTo = dateUntil;
        this.bookingTimeTo = bookingTimeUtil;
        this.duration = this.calculateDuration();
      } else {
        this.selectedForBooking = [this.selectedDevices[this.bookingService.selectedTimeSlot.device]];
        this.bookingFrom = this.selectedDate;
        this.bookingFrom.setHours(0, 0, 0, 0);
        this.bookingTimeFrom = this.times.find((t) => t.hour === hour && t.minute === minute);
      }
      this.filterTimesFrom();
    }
    this.timesTo = [...this.times];
    this.startDate.setHours(0, 0, 0, 0);

    this.minDate = this.bookingFrom || this.startDate;
    this.maxDate = this.bookingService.maxDate;
    this.maxEndDate = this.bookingService.maxDate;
  }

  ngOnDestroy(): void {
    this.selectedDevicesSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
  }

  selectForBooking($event: MatCheckboxChange, device: any) {
    if ($event.checked) {
      this.selectedForBooking.push(device);
    } else {
      this.selectedForBooking = this.selectedForBooking.filter((d) => d.serialNumber !== device.serialNumber);
    }
  }

  checkCreateReady(): boolean {
    const dailyVerification = this.type === 'DAILY' && this.bookingTo?.getTime() === this.bookingFrom?.getTime();
    const weeklyVerification = this.type === 'WEEKLY' && this.bookingTo?.getTime() - this.bookingFrom?.getTime() <= 518400000;

    return !(this.selectedForBooking.length
      && this.bookingFrom
      && this.bookingTo
      && this.bookingTimeFrom.hour
      && this.bookingTimeTo.hour
      && this.bookingTo >= this.bookingFrom
      && this.bookingTo <= this.maxDate
      && (this.type === 'SINGLE' || (this.endDate && (dailyVerification || weeklyVerification)))
    );
  }

  calculateDuration(): string {
    if (this.bookingFrom && this.bookingTimeFrom.hour && this.bookingTo && this.bookingTimeTo.hour) {
      const from = new Date(this.bookingFrom);
      const to = new Date(this.bookingTo);
      from.setHours(parseInt(this.bookingTimeFrom.hour), parseInt(this.bookingTimeFrom.minute));
      to.setHours(parseInt(this.bookingTimeTo.hour), parseInt(this.bookingTimeTo.minute));
      let difference = (to.getTime() - from.getTime()) / 1000;

      const days = Math.floor(difference / 86400);
      difference = difference - days * 86400;
      const hours = Math.floor(difference / 3600);
      difference = difference - hours * 3600;
      const minutes = Math.floor(difference / 60);
      return `${days > 0 ? days + ' days ' : ''}${hours > 0 ? hours + ' hours ' : ''}${minutes} minutes`;
    }
    return '';
  }

  applyDuration() {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const durationSplit = this.duration.split(' ');

    const dayIndex = durationSplit.findIndex((value) => value === 'days' || value === 'day');
    if (dayIndex > 0) {
      days = parseInt(durationSplit[dayIndex - 1]);
      if (isNaN(days)) {
        return this.duration = this.calculateDuration();
      }
    }

    const hourIndex = durationSplit.findIndex((value) => value === 'hours' || value === 'hour');
    if (hourIndex > 0) {
      hours = parseInt(durationSplit[hourIndex - 1]);
      if (isNaN(hours)) {
        return this.duration = this.calculateDuration();
      }
    }

    const minuteIndex = durationSplit.findIndex((value) => value === 'minutes' || value === 'minute');
    if (minuteIndex > 0) {
      minutes = parseInt(durationSplit[minuteIndex - 1]);
      if (isNaN(minutes)) {
        return this.duration = this.calculateDuration();
      }
    }
    if (this.bookingTimeFrom.hour && (days || hours || minutes)) {
      const dateFrom = new Date(this.bookingFrom);
      dateFrom.setHours(parseInt(this.bookingTimeFrom.hour), parseInt(this.bookingTimeFrom.minute));
      const minutesToMs = minutes ? minutes * 60000 : 0;
      const hoursToMs = hours ? hours * 3600000 : 0;
      const daysToMs = days ? days * 86400000 : 0;
      const dateTo = new Date(dateFrom.getTime() + daysToMs + hoursToMs + minutesToMs);
      const bookingTimeTo = this.times.find((time) => {
        const closesMinute = dateTo.getMinutes() > 15 ? 30 : 0;
        return parseInt(time.hour) === dateTo.getHours() && parseInt(time.minute) === closesMinute;
      });
      dateTo.setHours(0, 0, 0, 0);
      this.bookingTo = dateTo;
      this.bookingTimeTo = bookingTimeTo;
    }
  }

  onDurationChange($event) {
    this.duration = $event.target.value;
    this.applyDuration();
  }

  openPicker(picker: string) {
    this[picker].open();
  }

  submitForm() {
    this.bookingService.selectedTimeSlot.booking ? this.updateBooking() : this.createBooking();
  }

  createBooking() {
    this.bookingService.startLoading();
    this.errorMessage = '';
    this.errors = [];

    let endTime = `${this.bookingTimeTo.hour}:29`;
    let endDate = this.formatDate(this.bookingTo);
    
    if (this.bookingTimeTo.minute === '00') {
      if (this.bookingTimeTo.hour === '00') {
        endTime = `23:59`;
        const prevDate = new Date(this.bookingTo.getTime() - 86400000);
        endDate = this.formatDate(prevDate);
      } else {
        endTime = `${(parseInt(this.bookingTimeTo.hour) - 1).toString().padStart(2,'0')}:59`;
      }
    }

    const form: CreateBookingForm = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceIdList: this.selectedForBooking.map((device) => device._id),
      type: this.type === 'SINGLE' ? BookingType.SINGLE : BookingType.RECURRENT,
      startDate: this.formatDate(this.bookingFrom),
      endDate,
      startTime: `${this.bookingTimeFrom.hour}:${this.bookingTimeFrom.minute}`,
      endTime,
      description: this.description
    };

    if (this.type === 'DAILY') {
      form.endDate = this.formatDate(this.endDate);
      form.days = this.week;
    }

    if (this.type === 'WEEKLY') {
      const startDay = this.bookingFrom.getDay();
      const endDay = this.bookingTo.getDay();
      form.endDate = this.formatDate(this.endDate);
      form.days = startDay === endDay ? this.week : startDay < endDay ? this.week.slice(startDay, endDay + 1) : [...this.week.slice(startDay), ...this.week.slice(0, endDay + 1)];
    }

    this.bookingService.createBooking(form).subscribe((res) => {
      this.bookingService.stopLoading();

      if (res.statusCode === 200) {
        this.devicesBooked.emit();
      }
    }, (error) => {
      this.bookingService.stopLoading();
      if (error.error.data.bookings) {
        const response = error.error.data as AlreadyBookedResponse;
        this.errorMessage = response.message;
        this.errors = response.bookings.map((booking) => {
          const device = this.selectedDevices.find((d) => d._id === booking.deviceId);
          return {
            ...booking,
            deviceName: device.modelName,
            bookedFrom: booking.bookedFrom * 1000,
            bookedUntil: (booking.bookedUntil + 1) * 1000
          };
        });
      }
    });
  }

  updateBooking() {
    this.bookingService.startLoading();
    this.errorMessage = '';
    this.errors = [];

    let endTime = `${this.bookingTimeTo.hour}:29`;
    let endDate = this.formatDate(this.bookingTo);
    
    if (this.bookingTimeTo.minute === '00') {
      if (this.bookingTimeTo.hour === '00') {
        endTime = `23:59`;
        const prevDate = new Date(this.bookingTo.getTime() - 86400000);
        endDate = this.formatDate(prevDate);
      } else {
        endTime = `${(parseInt(this.bookingTimeTo.hour) - 1).toString().padStart(2,'0')}:59`;
      }
    }

    const form: UpdateBookingForm = {
      bookingId: this.bookingService.selectedTimeSlot.booking.id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate: this.formatDate(this.bookingFrom),
      endDate,
      startTime: `${this.bookingTimeFrom.hour}:${this.bookingTimeFrom.minute}`,
      endTime,
    };

    // TODO edit recurrent
    // if (this.type === 'DAILY') {
    //   form.endDate = this.formatDate(this.endDate);
    //   form.days = this.week;
    // }

    // if (this.type === 'WEEKLY') {
    //   const startDay = this.bookingFrom.getDay();
    //   const endDay = this.bookingTo.getDay();
    //   form.endDate = this.formatDate(this.endDate);
    //   form.days = startDay === endDay ? this.week : startDay < endDay ? this.week.slice(startDay, endDay + 1) : [...this.week.slice(startDay), ...this.week.slice(0, endDay + 1)];
    // }

    this.bookingService.updateBooking(form).subscribe((res) => {
      this.bookingService.stopLoading();

      if (res.statusCode === 200) {
        this.devicesBooked.emit();
      }
    }, (error) => {
      this.bookingService.stopLoading();
      if (error.error.data.bookings) {
        const response = error.error.data as AlreadyBookedResponse;
        this.errorMessage = response.message;
        this.errors = response.bookings.map((booking) => {
          const device = this.selectedDevices.find((d) => d._id === booking.deviceId);
          return {
            ...booking,
            deviceName: device.modelName,
            bookedFrom: booking.bookedFrom * 1000,
            bookedUntil: (booking.bookedUntil + 1) * 1000
          };
        });
      }
    });
  }

  formatDate(date: Date): string {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  }

  closeModal() {
    this.close.emit();
  }

  onDateChange($event: MatDatepickerInputEvent<Date>, prop: string) {
    this[prop] = $event.value;
    if (this.bookingTimeFrom.hour) {
      this.adjustTimesTo(this.bookingTimeFrom.hour, this.bookingTimeFrom.minute);
    }
    if (prop === 'bookingFrom') {
      this.minDate = this.bookingFrom;
      this.setMaxDate(this.type);
      this.filterTimesFrom();
    }
    this.duration = this.calculateDuration();
  }

  checkSameDay() {
    return this.bookingFrom && this.bookingTo && this.bookingFrom.getDate() === this.bookingTo.getDate() && this.bookingFrom.getMonth() === this.bookingTo.getMonth();
  }

  adjustTimesTo(hour: string, minute: string) {
    if (this.checkSameDay()) {
      const timeIndex = this.timesFrom.findIndex((t) => t.hour === hour && t.minute === minute);
      this.timesTo = this.timesFrom.slice(timeIndex);
      const isTimeValid = this.timesTo.find((t) => t.hour === this.bookingTimeTo.hour && t.minute === this.bookingTimeTo.minute);
      if (!isTimeValid) {
        this.bookingTimeTo = { hour: '', minute: '' };
      }
    } else {
      this.timesTo = [...this.times];
    }
  }

  onTimeSelect($event: MatSelectChange, type: 'from' | 'to') {
    if (type === 'from') {
      this.adjustTimesTo($event.value.hour, $event.value.minute);
    }
    this.duration = this.calculateDuration();
  }

  onDescriptionChange($event) {
    this.description = $event.target.value;
  }

  setMaxDate(type: string) {
    const from = this.bookingFrom.getTime();
    switch (type) {
      case 'SINGLE':
        this.maxDate = this.bookingService.maxDate;
        break;
      case 'DAILY':
        this.maxDate = this.bookingFrom;
        break;
      case 'WEEKLY':
        this.maxDate = from + 518400000 > this.bookingService.maxDate.getTime() ? this.bookingService.maxDate : new Date(from + 518400000);
        break;
    }
  }

  onTypeChange($event: MatSelectChange) {
    this.setMaxDate($event.value);
  }

  filterTimesFrom() {
    const now = new Date();
    if (this.bookingFrom.getDate() === now.getDate() && this.bookingFrom.getMonth() === now.getMonth()) {
      const hour = now.getHours();
      const minute = now.getMinutes();
      const timeIndex = this.times.findIndex((t) => parseInt(t.hour) === hour);
      this.timesFrom = minute >= 30 ? this.times.slice(timeIndex + 2) : this.times.slice(timeIndex + 1);
    } else {
      this.timesFrom = [...this.times];
    }
  }

  isDeviceSelected(device: any): boolean {
    return this.selectedForBooking.some((d) => d._id === device._id);
  }

  isEdit(): boolean {
    return !!this.bookingService.selectedTimeSlot.booking;
  }

  findBestSlot() {
    this.bestSlot = null;
    const from = new Date(this.bookingFrom);
    const to = new Date(this.bookingTo);
    from.setHours(parseInt(this.bookingTimeFrom.hour), parseInt(this.bookingTimeFrom.minute));
    to.setHours(parseInt(this.bookingTimeTo.hour), parseInt(this.bookingTimeTo.minute));
    const duration = (to.getTime() - from.getTime()) / 60000;

    const request = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceIds: this.selectedForBooking.map((d) => d._id),
      startDate: `${(this.bookingFrom.getMonth() + 1).toString().padStart(2, '0')}/${this.bookingFrom.getDate().toString().padStart(2, '0')}/${this.bookingFrom.getFullYear()}`,
      startTime: `${this.bookingTimeFrom.hour}:${this.bookingTimeFrom.minute}`,
      duration
    };

    this.bookingService.startLoading();
    this.bookingService.findBestSlot(request).subscribe((res) => {
      this.bookingService.stopLoading();
      if (res.statusCode === 200) {
        if(!res.data['message']) {
          this.bestSlot = res.data as BestSlot;
        }
        this.modalRef = this.modalService.open(this.bestSlotModal, this.modelOptions);
      }
    }, (error) => {
      this.bookingService.stopLoading();
      this.modalRef = this.modalService.open(this.bestSlotModal, this.modelOptions);
    });
  }

  closeSlotModal() {
    this.modalRef.close();
  }

  applyBestSlot() {
    const dateFrom = new Date(this.bestSlot.startDateTime * 1000);
    const bookingTimeFrom = this.times.find((time) => parseInt(time.hour) === dateFrom.getHours() && parseInt(time.minute) === dateFrom.getMinutes());
    dateFrom.setHours(0, 0, 0, 0);
    const dateUntil = new Date((this.bestSlot.endDateTime + 1) * 1000);
    const bookingTimeUtil = this.times.find((time) => parseInt(time.hour) === dateUntil.getHours() && parseInt(time.minute) === dateUntil.getMinutes());
    dateUntil.setHours(0, 0, 0, 0);
    this.bookingFrom = dateFrom;
    this.bookingTimeFrom = bookingTimeFrom;
    this.bookingTo = dateUntil;
    this.bookingTimeTo = bookingTimeUtil;
    this.duration = this.calculateDuration();
    this.modalRef.close();
  }
}