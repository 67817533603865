<app-spinner *ngIf="isLoading"></app-spinner>
<div class="info_tab_container">
<div class="device-info-box" [ngClass]="{
  disabled_usb:
    isUsbAttach == false ||
    isAttachReq == true
}">
    <div>
        <img *ngIf="deviceDetail?.oem === 'Apple'" src="../../../../assets/images/NewUI/ip12.jpeg" alt="" />
        <img *ngIf="deviceDetail?.oem !== 'Apple'" src="../../../../assets/images/NewUI/android.png" alt="" />
    </div>
    <div class="info_box">
        <div class="info_container">
            <div class="header">
                <p class="header-modal">{{ deviceDetail?.modelName || "--" }}</p>
                <p class="header-version">
                    <span *ngIf="deviceDetail?.oem === 'Apple'"> iOS</span>
                    <span *ngIf="deviceDetail?.oem !== 'Apple'">Android</span>
                    {{ deviceDetail?.osVersion || "--" }}
                </p>
            </div>
            <div class="booking_container">
                    <img class="booking_icon" src="../../../../assets/images/NewUI/new_booking_details.svg" alt="" />
                    <div class="device-info-label">
                        <div class="booking-dtz">
                            <span>
                              {{ deviceBookingDetail?.startDate || "--" }} to
                                {{ deviceBookingDetail?.endDate || "--" }}
                            </span>
                        </div>
                    </div>
            </div>
        </div>
        <div class="btn_container" *ngIf="openReleaseModal">
            <button (click)="openReleaseModal()" class="release_btn tooltip2">
                Release
                <span class="tooltiptext">{{ releaseBtnTooltip }}</span>
            </button>
        </div>
    </div>
</div>
<hr />
<div class="tabs_container">
    <div class="tabs">
        <span [ngClass]="{ active: activeTab === TypeEnum.GENERAL_INFO}" (click)="openTab(TypeEnum.GENERAL_INFO)">
            General Info
        </span>
        <span [ngClass]="{ active: activeTab === TypeEnum.CUSTOM_PROPERTIES}" (click)="openTab(TypeEnum.CUSTOM_PROPERTIES)">
            Custom properties
        </span>
    </div>
    <div>
        <span class="add_property tooltip1" *ngIf="activeTab === TypeEnum.CUSTOM_PROPERTIES" (click)="openModal(propertyModal, 'Create device property', 'Create', 'create')">
            + Add custom property
            <span class="tooltiptext">{{ addCustomPropTooltip }}</span>
        </span>
    </div>
</div>
<div class="properties_container" [ngClass]="{
  disabled_usb:
    isUsbAttach == false ||
    isAttachReq
}" *ngIf="activeTab === TypeEnum.GENERAL_INFO">
    <div class="devices-info">
        <div>
            <span class="device-info-label bold-text">Device Name</span>
            <span class="device-info-value bold-text">{{deviceInfo?.deviceName || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple'">
            <span class="device-info-label">Android Version</span>
            <span class="device-info-value">{{
                deviceInfo?.osVersion || "--"
                }}
        </span>
        </div>
        <div *ngIf="deviceDetail?.oem === 'Apple'">
            <span class="device-info-label bold-text">UUID</span>
            <span class="device-info-value bold-text">{{serialNumber || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple'">
            <span class="device-info-label bold-text">Serial No</span>
            <span class="device-info-value bold-text">{{serialNumber || "--"}}</span>
        </div>
        <div>
            <span class="device-info-label bold-text">OEM</span>
            <span class="device-info-value bold-text">{{deviceDetail?.oem || "--"}}</span>
        </div>
        <div>
            <span class="device-info-label">Screen Resolution</span>
            <span class="device-info-value">{{deviceInfo?.screenWidth || "--"}}
                <span *ngIf="deviceInfo?.screenWidth">px</span>
        <span *ngIf="deviceInfo?.screenWidth"> X </span>
                {{deviceInfo?.screenHeight}}
                <span *ngIf="deviceInfo?.screenHeight">px</span></span>
        </div>
    </div>
    <div class="devices-info">
        <div>
            <span class="device-info-label bold-text">Model Name</span>
            <span class="device-info-value bold-text">{{deviceInfo?.modelName || "--"}}</span>
        </div>
        <div>
            <span class="device-info-label">Model Number</span>
            <span class="device-info-value">{{deviceInfo?.modelNumber || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple'">
            <span class="device-info-label">OS Build ID</span>
            <span class="device-info-value">{{deviceInfo?.osBuildId || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple'">
            <span class="device-info-label">Hardware Version</span>
            <span class="device-info-value">{{deviceInfo?.hardwareVersion || "--"}}</span>
        </div>
        <div>
            <span class="device-info-label">Serial Number</span>
            <span class="device-info-value">{{deviceInfo?.serialNumber || "--"}}</span>
        </div>
    </div>
    <div class="devices-info">
        <div>
            <span class="device-info-label bold-text">WiFi</span>
            <span class="device-info-value bold-text" *ngIf="wifi?.enabled">ON</span>
            <span class="device-info-value bold-text" *ngIf="!wifi?.enabled">OFF</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">WiFi Network Name</span>
            <span class="device-info-value">{{wifi?.networkName || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Bssid</span>
            <span class="device-info-value">{{wifi?.bssid || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Ip</span>
            <span class="device-info-value">{{wifi?.ip || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Signal Strength</span>
            <span class="device-info-value">{{wifi?.signalStrength || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Link Speed</span>
            <span class="device-info-value">{{wifi?.linkSpeed || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Frequency</span>
            <span class="device-info-value">{{wifi?.frequency || "--"}}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Rx</span>
            <span class="device-info-value">{{wifi?.rx | fileSize:0 }}</span>
        </div>
        <div *ngIf="deviceDetail?.oem !== 'Apple' && wifi?.enabled">
            <span class="device-info-label">Tx</span>
            <span class="device-info-value">{{wifi?.tx | fileSize:0 }}</span>
        </div>
        <div *ngIf="deviceDetail?.oem === 'Apple' && wifi?.enabled">
            <span class="device-info-label">Mac Address</span>
            <span class="device-info-value">{{wifi?.macAddress}}</span>
        </div>
    </div>
    <div class="devices-info">
        <div>
            <span class="device-info-label bold-text">Bluetooth</span>
            <span class="device-info-value bold-text" *ngIf="isBluetoothOn">ON</span>
            <span class="device-info-value bold-text" *ngIf="!isBluetoothOn">OFF</span>
        </div>
        <div *ngIf="isBluetoothOn">
            <span class="device-info-label">Mac Address</span>
            <span class="device-info-value">{{deviceInfo?.bluetoothMacAddress || "--"}}</span>
        </div>
    </div>
    <div class="devices-info">
    <div *ngIf="deviceInfo?.mdn">
        <span class="device-info-label">Phone Number</span>
        <span class="device-info-value">{{deviceInfo?.mdn || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem == 'Apple'">
        <span class="device-info-label">Model Firmware</span>
        <span class="device-info-value">{{deviceInfo?.firmwareVersion || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem == 'Apple' && deviceInfo?.iccid">
        <span class="device-info-label">EID</span>
        <span class="device-info-value">{{deviceInfo?.iccid || "--"}}</span>
    </div>
    <div *ngIf="deviceInfo?.providerName">
        <span class="device-info-label">Carrier</span>
        <span class="device-info-value">{{deviceInfo?.providerName || "--"}}</span>
    </div>
    <div *ngIf="deviceInfo?.imei">
        <span class="device-info-label">IMEI</span>
        <span class="device-info-value">{{deviceInfo?.imei || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem === 'Apple' && deviceInfo?.imei2">
        <span class="device-info-label">IMEI2</span>
        <span class="device-info-value">{{deviceInfo?.imei2 || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem === 'Apple' && deviceInfo?.meid">
        <span class="device-info-label">MEID</span>
        <span class="device-info-value">{{deviceInfo?.meid || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem === 'Apple' && deviceInfo?.iccid">
        <span class="device-info-label">ICCID</span>
        <span class="device-info-value">{{deviceInfo?.iccid || "--"}}</span>
    </div>
    <div *ngIf="deviceDetail?.oem === 'Apple' && deviceInfo?.osVersion">
        <span class="device-info-label">OS version</span>
        <span class="device-info-value">{{deviceInfo?.osVersion || "--"}}</span>
    </div>
    <div *ngIf="deviceInfo?.signalStrength">
        <span class="device-info-label">Signal Strength</span>
        <span class="device-info-value">{{deviceInfo?.signalStrength || "--"}}</span>
    </div>
    <div>
        <span class="device-info-label">Battery Percentage</span>
        <span class="device-info-value">{{ battery }}</span>
    </div>
    <div>
        <span class="device-info-label">Storage</span>
        <span class="device-info-value">{{ storage || "--" }}</span>
    </div>
    <div *ngIf="deviceDetail?.oem !== 'Apple' && deviceInfo?.networkType">
        <span class="device-info-label">Network Type</span>
        <span class="device-info-value">
            <span *ngIf="deviceInfo?.networkType">{{deviceInfo?.networkType || "--"}}</span>
        </span>
    </div>
    <div class="outside_icon" *ngIf="deviceDetail?.oem === 'Apple'" (click)="ConnectivityAPI()" [ngClass]="{disabled_usb: disableNetwork}">
        <span class="device-info-label">Network Type</span>
        <span class="device-info-value cursor">
            <span *ngIf="deviceInfo?.networkType">{{deviceInfo?.networkType || "--"}}</span>
            <img *ngIf="showConnectivityToggle == false" class="arrow_down" src="../../../../assets/images/NewUI/drop_down.svg" alt="" />
            <img *ngIf="showConnectivityToggle == true" class="arrow_down" src="../../../../assets/images/NewUI/drop_up.svg" alt="" />
        </span>
    </div>
    <div class="network-section outside_icon" *ngIf="showConnectivityToggle == true" [ngClass]="{disabled_usb: disableNetwork}">
        <div class="outer-network pl-5 pr-5">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-center" style="font-size: 10px; padding: 10px" *ngIf="ratList.length == 0">No Network
                        Available</p>
                </div>
            </div>
            <div class="row mt-2 col-md-12 m-0 p-0 logs_list justify-content-end"
                 *ngFor="let item of ratList; index as i">
                <div class="col-md-6 device-info-label">{{ item.name }}</div>
                <div class="col-md-6">
                    <label class="container remember_label">
                        <input type="checkbox" id="remember" [disabled]="item.active" (change)="selectNetwork(i)"
                               [ngModelOptions]="{ standalone: true }" [(ngModel)]="item.active" [value]="item.active"
                               checked="checked" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="properties_container" *ngIf="activeTab === TypeEnum.CUSTOM_PROPERTIES">
    <div class="devices-info">
        <div *ngFor="let property of properties">
              <span class="device-info-label device_info_dynamic_property" [tp]="tooltipComponent" tpPlacement="top"
                    [tpOnlyTextOverflow]="true" [tpData]="property.name | tooltipData" [tpMaxWidth]="400"
                    [tpInteractive]="false">
                {{property.name}}
              </span>
            <div class="property_container">
                <span class="device-info-value device_info_dynamic_property device_info_value_dynamic_property"
                      [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                      [tpData]="property.value | tooltipData" [tpMaxWidth]="400" [tpInteractive]="false">
                  {{property.value}}
                </span>
                <div class="custom_properties_actions">
                    <span class="test-action" (click)="openModal(propertyModal, 'Change device property', 'Change', 'change', property)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Edit' | tooltipData">
                            <img alt="" src="../../../../assets/images/NewUI/edit.svg"/>
                        </span>
                    <span class="test-action" (click)="openDeleteDynamicPropertyDialog(property)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Delete' | tooltipData">
                            <img alt="" src="../../../../assets/images/trash-new.svg"/>
                        </span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #propertyModal let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>{{titleModalProperty}}</span>
            <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                 width="24">
                <path
                        d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-content dialog_font">
            <div class="input-section">
                <label for="name-property"><span class="required-field">*</span>Name</label>
                <input type="text" class="rename-input text-input" id="name-property" placeholder="Enter a name"
                       [(ngModel)]="nameProperty" (input)="isNamePristine = false" (ngModelChange)="compareProperty()"
                       (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                       [ngClass]="{'error': !nameProperty.length && !isNamePristine || isPropExistInProperties}"
                       [disabled]="userData.role !== 'ADMIN' && (selectedProperty?.category === 'ADMIN_FIELDS') && statePropertyUpdate !== 'create'" />
                <div *ngIf="!nameProperty.length && !isNamePristine" class="input-error">Property name can’t be empty
                </div>
                <div *ngIf="isPropExistInProperties && !isNamePristine" class="input-error">{{propertyWarning}}</div>
            </div>
            <div class="input-section">
                <label for="value-property">Value</label>
                <input type="text" class="rename-input text-input" id="value-property" placeholder="Enter a value"
                       [(ngModel)]="valueProperty" (focusin)="updateIgnoreTyping(true)"
                       (focusout)="updateIgnoreTyping(false)" />
            </div>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeModal()">Cancel</button>
            <button class="btn control_btn_new" [ngClass]="{disabled: !nameProperty.length}"
                    (click)="updateProperty()">{{titleModalButton}}</button>
        </div>
    </div>
</ng-template>
</div>
