<div class="main-header">
    <div>{{testInfo?.name}}</div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="header-info">
        <div class="description-wrapper">
            <span class="info-title">Device: </span> {{testInfo?.device?.name}} | {{testInfo?.device?.os}} |
            <span class="info-title">Serial number:</span> {{testInfo?.device?.sn}}
            <div class="description-wrapper">
                <span class="info-title">Created at</span> {{testInfo?.createdAt * 1000 | date:'MM/dd/yy HH:MM'}}
            </div>
            <hr />
        </div>
        <div class="description-wrapper description-title">Description</div>
        <div class="description-wrapper">{{testInfo?.description}}</div>
        <span class="add_button" *ngIf="!testInfo?.description" (click)="openModal(addDescription)">
            + Add description
        </span>
    </div>
    <hr />
    <table>
        <thead>
        <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Size</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let artifact of testInfo?.artifacts; index as i">
            <td>
                <img alt="" class="artifact-icon" src="../../../../assets/images/NewUI/{{getIconName(artifact)}}.svg">
                {{artifact.content}}</td>
            <td>{{artifact.status}}</td>
            <td>{{calculateTestSize(artifact) | kilobytes: 3}}</td>
            <td class="artifact_actions">
                <span [tp]="tooltipComponent" tpPlacement="left"
                      [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img class='action-button download-icon'
                             src="../../../../assets/images/NewUI/download-artifact.svg" alt=""
                             [ngClass]="{ disabled: !checkArtifactDownload(artifact) }"
                             (click)="downloadFile(artifact)">
                </span>
                <span [tp]="tooltipComponent" tpPlacement="left" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                    <img class='action-button' src="../../../../assets/images/NewUI/delete.svg" alt=""
                         [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COMPLETED' }"
                         (click)="openConfirmDeleteArtifact(artifact)">
                </span>
            </td>
        </tr>
        </tbody>
    </table>
    <span class="fake-gap"></span>
</div>

<!--Add description modal-->
<ng-template #addDescription let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>Add description</span>
            <svg (click)="closeDescriptionModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                 width="24">
                <path
                        d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-content">
            <div class="input-section">
                <textarea max="150" rows="3" class="form-control" placeholder="Enter a description"
                          [(ngModel)]="descriptionToAdd"></textarea>
            </div>
        </div>

        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeDescriptionModal()" type="button">Cancel</button>
            <button class="btn control_btn_new" (click)="applyDescription()" [disabled]="!descriptionToAdd">Add</button>
        </div>
    </div>
</ng-template>
