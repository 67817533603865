<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="page-title">
  <h1>
    Labs
    <div class="total-labs" *ngIf="userData?.role !== 'ADMIN'">
      {{totalCorporate || 0}}
    </div>
  </h1>
  <div class="filters">
    <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field" placeholder="Search"
      (change)="searchChange()" />
    <div class="filter-icon">
      <span class="icon" [ngClass]="{ active: isFiltered }" (click)="openFilters()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>
      <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
        <select class="filter-select select_input form-control" [(ngModel)]="searchStatus" required>
          <option value="">Select Lab Status</option>
          <option value="online">Online</option>
          <option value="offline">Offline</option>
        </select>
        <select *ngIf="activeTab === 'corporate' && userData?.role === 'ADMIN'"
          class="filter-select select_input form-control" [(ngModel)]="searchEnterprise" required>
          <option value="">Select Enterprise</option>
          <option *ngFor="let option of enterpriseOptions" [value]="option.id">{{option.name}}</option>
        </select>
        <select class="filter-select select_input form-control" [(ngModel)]="searchSnapSupport" required>
          <option value="">Select Snap Support</option>
          <option *ngFor="let option of snapSupportOptions" [value]="option.email">{{option.name}}</option>
        </select>
        <select *ngIf="activeTab === 'corporate'" class="filter-select select_input form-control"
          [(ngModel)]="searchLabSupport" required>
          <option value="">Select Lab Support</option>
          <option *ngFor="let option of labSupportOptions" [value]="option.email">{{option.name}}</option>
        </select>
        <select class="filter-select select_input form-control" [(ngModel)]="searchAppium" required>
          <option value="">Select Appium Support</option>
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
        <select class="filter-select select_input form-control" [(ngModel)]="searchSmartHub" required>
          <option value="">Select Smart-Hub Support</option>
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
        <select class="filter-select select_input form-control" [(ngModel)]="searchAlerts" required>
          <option value="">Select Alerts</option>
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
        <select class="filter-select select_input form-control" [(ngModel)]="searchEnabled" required>
          <option value="">Select Lab Availability</option>
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
        <div class="filter-buttons">
          <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
          <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
          <button class="snap-button primary" (click)="applyFilters()">Filter</button>
        </div>
      </div>
    </div>
    <button class="snap-button primary create-new-button" routerLink="/admin/create-lab">Create a new lab</button>
  </div>
</div>
<div class="tabs" *ngIf="userData?.role === 'ADMIN'">
  <span [ngClass]="{ active: activeTab === 'public'}" (click)="openTab('public')">
    Public
    <div class="total-labs">
      {{totalPublic || 0}}
    </div>
  </span>
  <span [ngClass]="{ active: activeTab === 'corporate'}" (click)="openTab('corporate')">
    Corporate
    <div class="total-labs">
      {{totalCorporate || 0}}
    </div>
  </span>
</div>
<div class="empty-result" *ngIf="isFiltered && labList?.length === 0 && !isLoading">
  <img alt="" src="../../../../assets/images/NewUI/menu-lab-large.svg">
  <span class="empty-search-title">No labs were found</span>
  <span>
    No results matched your criteria. Try adjusting your search or filter options to find what you are looking
    for.
  </span>
  <span (click)="clearFilters()" class="empty-search-action">Clear filters</span>
</div>
<div class="empty-result" *ngIf="!isFiltered && labList?.length === 0 && !isLoading">
  <img alt="" src="../../../../assets/images/NewUI/menu-lab-large.svg">
  <span class="empty-search-title">No labs were found</span>
  <span>
    Create a new lab to test various mobile device models and OS in data center. Set up and configure the lab with ease
  </span>
  <button class="snap-button primary create-new-button" routerLink="/admin/create-lab">Create a new lab</button>
</div>
<table *ngIf="!isLoading && activeTab === 'public' && labList?.length > 0" class="lab-table public-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Domain</th>
      <th>IP Address</th>
      <th>Location</th>
      <th>Snap support</th>
      <th>Added Devices</th>
      <th>Device Limit</th>
      <th>Lab Availability</th>
      <th>
        <span [tp]="tooltipComponent" [tpData]="'Automatically Delete Devices' | tooltipData">
          Automatically Delete Devices
        </span>
      </th>
      <th>
        <span [tp]="tooltipComponent" [tpData]="'Smart-hub Availability' | tooltipData">
          Smart-hub Availability
        </span>
      </th>
      <th>Appium</th>
      <th>Alerts</th>
      <th>Smart-Hub</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lab of labList" [ngClass]="{disabled: !lab.isEnabled}">
      <td>
        <div class="lab-status" [ngClass]="{ online: lab.onlineStatus === 'online' }" [tp]="tooltipComponent"
          [tpData]="(lab.onlineStatus | titlecase) | tooltipData"></div>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.name | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.name}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.domain | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.domain}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.ip | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.ip}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.location | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.location}}</span>
      </td>
      <td>
        <span class="user-icon" [tp]="tooltipComponent" [tpData]="displaySupport(lab, 'SNAP') | tooltipData">
          <user-profile-icon [user]="getSupportUser(lab, 'SNAP')"></user-profile-icon>
        </span>
      </td>
      <td>{{displayDeviceAmount(lab)}}</td>
      <td>{{displayDeviceLimit(lab)}}</td>
      <td>
        <mat-icon class='lab-icon'>{{lab.isEnabled ? 'done' : 'close'}}</mat-icon>
      </td>
      <td class="centered">
        <span *ngIf="lab.deviceDeletePeriod">
          {{lab.deviceDeletePeriod}} days
        </span>
        <img *ngIf="!lab.deviceDeletePeriod" src="../../../../assets/images/NewUI/infinity.svg" alt="">
      </td>
      <td>
        <mat-icon class='lab-icon'>{{lab.isSmartHubAvailable ? 'done' : 'close'}}</mat-icon>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.isAppiumSupport" color="primary"
          (change)="updateAppiumSupport($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.sendAlerts" color="primary"
          (change)="updateAlerts($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.isSmartHubEnabled" color="primary"
          (change)="updateSmartHub($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td class="menu-cell">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <span class="menu-icon">
            <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
          </span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="menu_btn_list" (click)="navigatePage('/admin/update-lab', { id: lab.labId })">
            <span>Edit</span>
          </button>
          <button mat-menu-item class="menu_btn_list" (click)="openConfirmDelete(lab.labId)">
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>
<table *ngIf="!isLoading && activeTab === 'corporate' && labList?.length > 0" class="lab-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Domain</th>
      <th>IP Address</th>
      <th>Location</th>
      <th>Snap support</th>
      <th>Lab support</th>
      <th>Enterprise</th>
      <th>Added Devices</th>
      <th>Device Limit</th>
      <th>Lab Availability</th>
      <th>
        <span [tp]="tooltipComponent" [tpData]="'Automatically Delete Devices' | tooltipData">
          Automatically Delete Devices
        </span>
      </th>
      <th>
        <span [tp]="tooltipComponent" [tpData]="'Smart-hub Availability' | tooltipData">
          Smart-hub Availability
        </span>
      </th>
      <th>Appium</th>
      <th>Alerts</th>
      <th>Smart-Hub</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lab of labList" [ngClass]="{disabled: !lab.isEnabled}">
      <td>
        <div class="lab-status" [ngClass]="{ online: lab.onlineStatus === 'online' }" [tp]="tooltipComponent"
          [tpData]="(lab.onlineStatus | titlecase) | tooltipData"></div>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.name | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.name}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.domain | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.domain}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.ip | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.ip}}</span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.location | tooltipData"
          [tpOnlyTextOverflow]="true">{{lab.location}}</span>
      </td>
      <td>
        <span class="user-icon" [tp]="tooltipComponent" [tpData]="displaySupport(lab, 'SNAP') | tooltipData">
          <user-profile-icon [user]="getSupportUser(lab, 'SNAP')"></user-profile-icon>
        </span>
      </td>
      <td>
        <span class="user-icon" [tp]="tooltipComponent" [tpData]="displaySupport(lab, 'LAB') | tooltipData">
          <user-profile-icon [user]="getSupportUser(lab, 'LAB')"></user-profile-icon>
        </span>
      </td>
      <td>
        <span class="ellipsis" [tp]="tooltipComponent" [tpData]="lab.enterpriseName | tooltipData"
          [tpOnlyTextOverflow]="true">
          {{lab.enterpriseName}}
        </span>
      </td>
      <td>{{displayDeviceAmount(lab)}}</td>
      <td>{{displayDeviceLimit(lab)}}</td>
      <td>
        <mat-icon class='lab-icon'>{{lab.isEnabled ? 'done' : 'close'}}</mat-icon>
      </td>
      <td class="centered">
        <span *ngIf="lab.deviceDeletePeriod">
          {{lab.deviceDeletePeriod}} days
        </span>
        <img *ngIf="!lab.deviceDeletePeriod" src="../../../../assets/images/NewUI/infinity.svg" alt="">
      </td>
      <td>
        <mat-icon class='lab-icon'>{{lab.isSmartHubAvailable ? 'done' : 'close'}}</mat-icon>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.isAppiumSupport" color="primary"
          (change)="updateAppiumSupport($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.sendAlerts" color="primary"
          (change)="updateAlerts($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox class="checkbox" [checked]="lab.isSmartHubEnabled" color="primary"
          (change)="updateSmartHub($event.checked, lab)">
        </mat-checkbox>
      </td>
      <td class="menu-cell">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <span class="menu-icon">
            <img src="../../../../assets/images/NewUI/vertical_menu.svg" alt="">
          </span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="menu_btn_list" (click)="navigatePage('/admin/update-lab', { id: lab.labId })">
            <span>Edit</span>
          </button>
          <button mat-menu-item class="menu_btn_list" (click)="openConfirmDelete(lab.labId)">
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>
<div class="pagination-container" *ngIf="labList?.length > 0">
  <span class="limit">
    <label for="limit">Rows per page: </label>
    <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </span>
  <span>{{paginationText}}</span>
  <div class="pagination-buttons">
    <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
      <img alt="" src="../../../../assets/images/arrow_back.svg">
    </div>
    <div [ngClass]="{ disabled: currentPage + 1 >= totalPages }" class="arrow-button" (click)="changePage(1)">
      <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
    </div>
  </div>
</div>