
<div class="main-container" >
  <app-header *ngIf="!router.url.includes('/snap/current')"></app-header>
  <mat-sidenav-container>
    <mat-sidenav-content class="page-wrapper"  [ngClass]="{ 'white-color':  router.url.includes('/snap/current') }" style="overflow: hidden !important;">
      <div class="page-content" [ngClass]="{ 'p-0': router.url.includes('/snap/profile') ||  router.url.includes('/snap/support')|| router.url.includes('/snap/change-logs') || router.url.includes('/snap/current'), headless: router.url.includes('/snap/current')}">
        <app-side-navigation *ngIf="!router.url.includes('/snap/current')"></app-side-navigation>
        <main [ngClass]="{ 'rmPadding': router.url.includes('/snap/current/active')}">
          <router-outlet #page="outlet"></router-outlet>
        </main>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-main-footer></app-main-footer>
    <div class="chatbot-icon" appFreeDragging [ngClass]="{disabled: chatbotState !== 'closed'}"  (mousedown)="saveMouseDownTime($event)" (mouseup)="checkForLongPress($event)">
        <img src="../../../assets/images/chatbot/chat_gpt_icon.svg" width="30px" height="30px" alt="">
    </div>
    <div class="chatbot-wrapper">
        <app-chatbot (stateEmitted)="updateState($event)" [changeState]="openChatBox"></app-chatbot>
    </div>
</div>
