<app-spinner *ngIf="isLoading"></app-spinner>
<span class="button_back">
    <div class="page_name" (click)="navigate('/snap/custom-tests')">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        {{testCase?.name || 'Back'}}
    </div>
</span>
<div class="no-access" *ngIf="!isTelephonyAvailable">
    <p>
        Telephony testing is not available with your current subscription plan. <br />
        To get access to Telephony testing, you must upgrade your account to a plan that includes Telephony tests feature.
    </p>
</div>
<main class="main_test_container" *ngIf="isTelephonyAvailable && testInfo" [ngClass]="{expanded_iterations: isExpandedIterations}">
    <article class="left_test_container">
        <section class="stats">
            <div class="group">
                <span class="value">{{displayTestStatus()}}</span>
                Status
            </div>
            <div class="group">
                <span class="value">{{totalIterations}}</span>
                Iterations
            </div>
            <div class="group">
                <span class="value">{{testInfo.analytics.passRate}} %</span>
                Pass rate
            </div>
            <div class="group">
                <span class="value">{{getExecutionTime()}}</span>
                Execution time
            </div>
            <div class="group">
                <span class="value">{{getArtifactsTotal()}}</span>
                Artifacts
            </div>
        </section>
        <section class="info">
            <div *ngFor="let device of testInfo.devices">
                <b>Device {{device.index}}:</b>
                {{device.modelName}} | {{device.osName}} | Serial number: {{device.serialNumber}}
            </div>
            <div *ngIf="testInfo.endedAt">
                <b>Executed at:</b>
                {{testInfo.endedAt | date: "MM/dd/yyyy hh:mm a"}}
            </div>
            <div class="description" *ngIf="testCase?.description">
                <b>Description</b>
                {{testCase?.description}}
            </div>
        </section>
        <section class="artifacts">
            <h4>
                Artifacts
                <span class="download_all" (click)="downloadArtifacts()" *ngIf="checkDownloadAll()">
                    Download all
                </span>
            </h4>
            <div class="tabs" *ngIf="checkArtifactsExist()">
                <span *ngFor="let device of testInfo.devices" [ngClass]="{ active: activeTab === device.index.toString()}"
                    (click)="openTab(device.index)">
                    Device {{device.index}}
                    <div class="total-artifacts">
                        {{artifactsTotal[device.index] || 0}}
                    </div>
                </span>
            </div>
            <table *ngIf="artifactList?.length > 0" class="artifacts-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th class="size-cell">Size</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let artifact of artifactList">
                        <td>
                            <span class="ellipsis" [tp]="tooltipComponent"
                                [tpData]="artifact.key | tooltipData" [tpOnlyTextOverflow]="true">
                                <img *ngIf="artifact.type !== 'VIDEO'"
                                    src="../../../../assets/images/NewUI/logs-icon.svg" alt="" />
                                <img *ngIf="artifact.type === 'VIDEO'"
                                    src="../../../../assets/images/NewUI/video-icon.svg" alt="" />
                                {{getArtifactName(artifact.key) || displayArtifactType(artifact.type) }}
                            </span>
                        </td>
                        <td>
                            <span class="artifact-status">
                                <img src="../../../../assets/images/artifact_failed.svg" alt=""
                                    *ngIf="artifact.status === 'FAILED'" />
                                <div *ngIf="artifact.status === 'PROGRESS' || artifact.status === 'FINALIZING'">
                                    <mat-spinner diameter="16"></mat-spinner>
                                </div>
                                <img src="../../../../assets/images/telephony-actions/completed.svg" alt=""
                                    *ngIf="artifact.status === 'COLLECTED'" />
                                {{displayArtifactStatus(artifact.status)}}
                            </span>
                        </td>
                        <td class="size-cell">
                            {{artifact.size || 0 | kilobytes: 2}}
                        </td>
                        <td>
                            <div class="actions">
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Cancel artifact finalization' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                                    *ngIf="artifact.status === 'FINALIZING' || artifact.status === 'PROGRESS'">
                                    <svg class='artifacts_icon clickable_icon' (click)="cancelFile(artifact)"
                                        [ngClass]="{disabled: !isCloudAvailable }" xmlns="http://www.w3.org/2000/svg"
                                        height="16" viewBox="0 96 960 960" width="16">
                                        <path
                                            d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
                                    </svg>
                                </span>
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                                    *ngIf="artifact.status !== 'FINALIZING' && artifact.status === 'COLLECTED'">
                                    <img class="artifacts_icon clickable_icon"
                                        src="../../../../assets/images/download-new.svg" alt=""
                                        [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COLLECTED' }"
                                        (click)="downloadFile(artifact)" />
                                </span>
                                <span [tp]="tooltipComponent" tpPlacement="top"
                                    [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                                    <img alt="" src="../../../../assets/images/telephony-actions/trash-can.svg"
                                        class="artifacts_icon trash_icon clickable_icon"
                                        [ngClass]="{disabled: !isCloudAvailable || artifact.status !== 'COLLECTED' }"
                                        (click)="openConfirmDeleteArtifact(artifact)" />
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="no-artifacts" *ngIf="!checkArtifactsExist()">
                <img src="../../../../assets/images/empty_folder.svg" alt="" />
                <span>No artifacts are available for this test result.</span>
            </div>
        </section>
    </article>
    <article class="right_test_container">
        <div class="expand_button" (click)="expandIterations()">
            <mat-icon class="arrow_icon" *ngIf="!isExpandedIterations">arrow_back_ios</mat-icon>
            <mat-icon class="arrow_icon collapse_icon" *ngIf="isExpandedIterations">arrow_forward_ios</mat-icon>
        </div>
        <h3 class="test_title">
            Iterations
            <div class="total-iterations">
                {{totalIterations || 0}}
            </div>
        </h3>
        <app-iteration-accordion *ngIf="testInfo && testCase" [iterations]="iterations"
            [devices]="testCase.devices"
            [totalIterations]="totalIterations"
            [isExpanded]="isExpandedIterations"
            [testInfo]="testInfo"
        ></app-iteration-accordion>
        <div class="pagination-container" *ngIf="iterations?.length > 0">
            <span class="limit">
              <label for="limit">Rows per page: </label>
              <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </span>
            <span>{{paginationText}}</span>
            <div class="pagination-buttons">
              <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
                <img alt="" src="../../../../assets/images/arrow_back.svg">
              </div>
              <div [ngClass]="{ disabled: currentPage + 1 >= totalPage }" class="arrow-button" (click)="changePage(1)">
                <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
              </div>
            </div>
        </div>
    </article>
</main>
