import { Injectable } from '@angular/core';
import { TooltipComponent } from './tooltip/tooltip.component';
import { NavigationTooltipComponent } from './tooltip/navigation-tooltip/navigation-tooltip.component';

export interface TooltipData {
  header?: string;
  text: string;
  link?: string;
  linkName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  tooltipComponent = TooltipComponent;
  navTooltipComponent = NavigationTooltipComponent;

  constructor() { }

  setTooltipData(text: string, header = ''): TooltipData {
    return {
      header: header,
      text: text
    };
  }

  getTooltipComponent(): any {
    return this.tooltipComponent;
  }

  getNavigationTooltipComponent(): any {
    return this.navTooltipComponent;
  }
}
