<div class="main-container">
  <app-header></app-header>
  <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <mat-sidenav-content class="page-wrapper" style="overflow: hidden !important;">
      <div class="page-content">
        <app-side-navigation></app-side-navigation>
        <main>
          <router-outlet #page="outlet"></router-outlet>
        </main>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-main-footer></app-main-footer>
</div>