import {Component} from '@angular/core';

@Component({
    selector: 'momt-main-view',
    templateUrl: './momt-main-view.component.html',
    styleUrls: ['./momt-main-view.component.scss']
})

export class MomtMainViewComponent {
}
