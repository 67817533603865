import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ManualTestService} from '../manual-test.service';
import {RxStompService} from '../../../rx-stomp.service';
import {RxStompState} from '@stomp/rx-stomp';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DeviceService} from '../../view-device/device.service';
import {getRxStompConfigForUser} from '../../../rx-stomp.config';
import {CommonService} from '../../../shared/common.service';
import {FeatureTypeEnum, UserRoleEnum} from '../../../shared/enum';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TokenData} from '../../../shared/interfaces';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {interval} from 'rxjs';
import Split from 'split.js';

enum TabName {
    INFO = 'INFO',
    MANUAL_TEST = 'MANUAL_INFO',
    APPIUM = 'APPIUM',
    ADB = 'ADB',
    DEBUG = 'DEBUG',
    RECORD_AND_PLAY = 'RECORD_AND_PLAY',
    FILE_STRUCTURE = 'FILE_STRUCTURE',
    HOW_IT_WORK = 'HOW_IT_WORK',
    LOGCAT = 'LOGCAT',
    TERMINAL = 'TERMINAL'
}
@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})

export class Tabs implements OnInit, OnDestroy {

    @Input() releaseDevice: () => void;
    isLoading = true;
    TabNameEnum = TabName;
    isInfotab = TabName.INFO;
    mainResizableSplit: any;
    isUsbAttach = true;
    isReqAttach = false;
    textSearchResults = [];
    currentSearchIndex = 0;
    searchLogcatOutput = '';
    filterLogcat = '';
    deviceInfo: any;
    terminalSuggestion = [];
    currentSuggestionIndex = -1;
    terminalSuggestionIosDebug = [];
    currentSuggestionIndexIosDebug = -1;
    showAppiumTestModeDetails = false;
    currentDeviceMode = '';
    autoScroll = true;
    autoWrapLogcat = true;
    autoWrapTerminal = true;
    isLogModeUI = false;
    commandText = '';
    adbPullStatus = '';
    adbPullStatusSub: any;
    adbPullOperationId: any;
    displaycommand = 'logcat';
    bufferSelected = [];
    isStringMatch = false;
    adbLogcatResponseSub: any;
    adbTerminalResponseSub: any;
    adbConnectionStatus: string;
    adbLogcatSessionId: string;
    adbLogcatOperationId = 0;
    adbTerminalSessionId: string;
    adbTerminalOperationId = 0;
    terminalCmdInProgress = false;
    lastMsgIndexLogcat = 0;
    lastMsgIndexTerminal = 0;
    adbLogcatTimer: any;
    adbTerminalTimer: any;
    adbLogcatClearWait = false;
    adbTerminalClearWait = false;
    adbLogcatLastLineToClear = '';
    adbTerminalLastLineToClear = '';
    disableLogcatBtn = false;
    logcatBufferSize = 1000;
    terminalBufferSize = 1000;
    showLogcatWaitingMessage = false;
    showTerminalWaitingMessage = false;
    splitMode = true;
    showLogcat = true;
    showTerminal = false;
    isDebugAvailable = false;
    isCloudAvailable = false;
    isBookingAvailable = false;
    logcatOptions = [
        { value: false, label: 'all' },
        { value: false, label: 'main' },
        { value: false, label: 'system' },
        { value: false, label: 'crash' },
        { value: false, label: 'kernel' },
        { value: false, label: 'radio' },
        { value: false, label: 'events' },
        { value: false, label: 'default' },
    ];
    logcatInProgress = false;
    logcatOutput = [];
    origLogcatOutput = [];
    origIosDebugOutput = [];
    terminalText = '';
    deviceList: [];
    terminalList = [];
    userId: string;
    disableIosDebugBtn = false;
    isBookingExpired = false;
    tooltipComponent = null;
    isAttachReq = false;
    recordInProgress = false;
    replayInProgress = false;
    communicationStatus = '';
    communicationStatusSub: any;
    ignoreTyping = false;
    testList = [];
    notifyList: any = [];
    wifiConnect = '--';
    battery = '--';
    storage = '--';
    providerName = '--';
    category: 'DYNAMIC_FIELDS' | 'DEVICE_TAGS' | 'ADMIN_FIELDS';
    modelOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
    };
    closeResult: string;
    isNamePristine = true;
    userData: TokenData;
    commands: string[] = ['Select utility', 'idevicecrashreport', 'idevicediagnostics', 'ideviceinfo', 'idevicesyslog'];
    selectedCommand = 'Select utility';
    selectedSingleOption: string;
    selectedSecondOption = '';
    secondOption = [];
    iDeviceCrashReportOptions = ['--extract', '--keep'];
    iDeviceDiagnosticsCommands = ['diagnostics', 'mobilegestalt', 'ioreg', 'ioregentry', 'restart'];
    iDeviceDiagnosticsType = ['All', 'WiFi', 'GasGauge', 'NAND'];
    iDeviceDiagnosticsMobileGestaltKey = ['DieId', 'SerialNumber', 'UniqueChipID', 'WifiAddress', 'CPUArchitecture', 'BluetoothAddress',
        'EthernetMacAddress', 'FirmwareVersion', 'MLBSerialNumber', 'ModelNumber', 'RegionInfo', 'RegionCode',
        'DeviceClass', 'ProductType', 'DeviceName', 'UserAssignedDeviceName', 'HWModelStr', 'SigningFuse',
        'SoftwareBehavior', 'SupportedKeyboards', 'BuildVersion', 'ProductVersion', 'ReleaseType', 'InternalBuild',
        'CarrierInstallCapability', 'IsUIBuild', 'InternationalMobileEquipmentIdentity', 'MobileEquipmentIdentifier',
        'DeviceColor', 'HasBaseband', 'SupportedDeviceFamilies', 'SoftwareBundleVersion', 'SDIOManufacturerTuple',
        'SDIOProductInfo', 'UniqueDeviceID', 'InverseDeviceID', 'ChipID', 'PartitionType', 'ProximitySensorCalibration',
        'CompassCalibration', 'WirelessBoardSnum', 'BasebandBoardSnum', 'HardwarePlatform', 'RequiredBatteryLevelForSoftwareUpdate',
        'IsThereEnoughBatteryLevelForSoftwareUpdate', 'BasebandRegionSKU', 'encrypted-data-partition', 'SysCfg', 'DiagData',
        'SIMTrayStatus', 'CarrierBundleInfoArray', 'AllDeviceCapabilities', 'wi-fi', 'SBAllowSensitiveUI', 'green-tea',
        'not-green-tea', 'AllowYouTube', 'AllowYouTubePlugin', 'SBCanForceDebuggingInfo', 'AppleInternalInstallCapability',
        'HasAllFeaturesCapability', 'ScreenDimensions', 'IsSimulator', 'BasebandSerialNumber', 'BasebandChipId', 'BasebandCertId',
        'BasebandSkeyId', 'BasebandFirmwareVersion', 'cellular-data', 'contains-cellular-radio', 'RegionalBehaviorGoogleMail',
        'RegionalBehaviorVolumeLimit', 'RegionalBehaviorShutterClick', 'RegionalBehaviorNTSC', 'RegionalBehaviorNoWiFi',
        'RegionalBehaviorChinaBrick', 'RegionalBehaviorNoVOIP', 'RegionalBehaviorAll', 'ApNonce'];
    iDeviceDiagnosticsIoregPlane = ['IODeviceTree', 'IOPower', 'IOService'];
    iDeviceInfoOptions = ['--simple', '--domain', '--xml'];
    iDeviceInfoDomainName = ['com.apple.disk_usage', 'com.apple.disk_usage.factory', 'com.apple.mobile.battery', 'com.apple.mobile.debug',
        'com.apple.iqagent',
        'com.apple.purplebuddy',
        'com.apple.PurpleBuddy',
        'com.apple.mobile.chaperone',
        'com.apple.mobile.third_party_termination',
        'com.apple.mobile.lockdownd',
        'com.apple.mobile.lockdown_cache',
        'com.apple.xcode.developerdomain',
        'com.apple.international',
        'com.apple.mobile.data_sync',
        'com.apple.mobile.tethered_sync',
        'com.apple.mobile.mobile_application_usage',
        'com.apple.mobile.backup',
        'com.apple.mobile.nikita',
        'com.apple.mobile.restriction',
        'com.apple.mobile.user_preferences',
        'com.apple.mobile.sync_data_class',
        'com.apple.mobile.software_behavior',
        'com.apple.mobile.iTunes.SQLMusicLibraryPostProcessCommands',
        'com.apple.mobile.iTunes.accessories',
        'com.apple.mobile.internal',
        'com.apple.mobile.wireless_lockdown',
        'com.apple.fairplay',
        'com.apple.iTunes',
        'com.apple.mobile.iTunes.store',
        'com.apple.mobile.iTunes'];
    iDeviceSysLogOptions1 = ['--match', '--trigger', '--untrigger', '--process', '--exclude'];
    iDeviceSysLogOptions2 = ['--quiet', '--quiet-list', '--kernel', '--no-kernel'];
    options: string[];
    selectedMultipleOption: string[];
    showCheckboxes = false;
    displayableRequest = 'Choose command';
    idevicecrashreportUsage = 'idevicecrashreport [OPTIONS] PATH';
    idevicediagnosticsUsage = 'idevicediagnostics COMMAND';
    ideviceinfoUsage = 'ideviceinfo [OPTIONS]';
    idevicesyslogUsage = 'idevicesyslog [OPTIONS1] [OPTIONS2]';
    iosDebugTextField = '';
    iosDebugPlaceholder = '';
    selectedDomain = false;
    deviceId = '';
    resizableSplit: any;
    splitRatio = [30, 70];
    constructor(
        protected manualTestService: ManualTestService,
        private rxStompService: RxStompService,
        private _snackBar: MatSnackBar,
        private deviceService: DeviceService,
        private common: CommonService,
    ) {}

    ngOnInit(): void {
        this.userData = this.common.getUser();
        this.getDeviceDetails();
        this.checkDeviceCommStatus();
        this.isDebugAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.DEBUG]);
    }

    ngOnDestroy(): void {
        this.releaseDevice();
    }

    navigateTab(value: TabName): void {
        this.isInfotab = value;
        if (value === TabName.HOW_IT_WORK) {
            localStorage.setItem('_appMode', this.currentDeviceMode);
        }
        if (this.mainResizableSplit && value !== TabName.ADB && value !== TabName.DEBUG) {
            this.mainResizableSplit.destroy();
            this.mainResizableSplit = null;
        } else if (this.isDebugAvailable && (value === TabName.ADB || value === TabName.DEBUG)) {
            this.configResizableSplit();
        }
    }

    changeSplitMode(): void {
        if (this.showLogcat && this.showTerminal) {
            this.splitMode = !this.splitMode;
        }
        this.configResizableSplit();
    }

    updateSplitView(item: string): void {
        if (item === 'logcat' && this.showTerminal && !this.logcatInProgress) {
            this.showLogcat = !this.showLogcat;
        } else if (item === 'terminal' && this.showLogcat && !this.terminalCmdInProgress) {
            this.showTerminal = !this.showTerminal;
        }
        this.configResizableSplit();
    }

    toggleView(fromManual: boolean) {
        if ((!fromManual && !this.isLogModeUI) || fromManual && this.isLogModeUI) {
            this.isLogModeUI = !this.isLogModeUI;
            if (!this.logcatInProgress) {
                this.commandText = '';
                this.displaycommand = 'logcat ';
                this.bufferSelected = [];
            }
        }
    }

    handleCommandResponse(type: string, response: any, isIosDebug?: boolean): void {
        if (response && response.body) {
            const data = JSON.parse(response.body);
            if (data.type === 'cmd') {
                if (data.result) {
                    if (type === 'logcat') {
                        this.showLogcatWaitingMessage = false;
                        if (data.result.message) {
                            // display skipped line message if needed
                            if (data.result.message.skipped > 0) {
                                this.logcatOutput.push(`Skipped ${data.result.message.skipped} lines`);
                                this.origLogcatOutput.push(`Skipped ${data.result.message.skipped} lines`);
                            }
                            // display actual log lines
                            if (data.result.message.lines && data.result.message.lines.length) {
                                data.result.message.lines.forEach((msg) => {
                                    this.logcatOutput.push(msg);
                                    this.origLogcatOutput.push(msg);
                                    if (this.logcatOutput.length > this.logcatBufferSize) {
                                        this.logcatOutput.shift();
                                        this.origLogcatOutput.shift();
                                    }
                                });
                                this.lastMsgIndexLogcat = data.result.message.index + data.result.message.lines.length - 1;
                            }
                        }
                        // display errors if any
                        if (data.result.errors && data.result.errors.length) {
                            data.result.errors.forEach((err) => {
                                this.logcatOutput.push('ERROR: ' + err.message);
                                this.origLogcatOutput.push('ERROR: ' + err.message);

                                if (data.result.isLast && err.code === 'TERMINAL_CMD_ERROR_MESSAGE_WAIT_TIMEOUT') {
                                    // discard sessionId if session has been closed by timeout
                                    this.adbLogcatSessionId = '';
                                }
                            });
                        }
                        if (data.result.isLast) {
                            this.logcatInProgress = false;
                            this.adbLogcatOperationId++;
                            this.disableLogcatBtn = false;
                            this.logcatSearchChange();
                        }
                        if (this.autoScroll) {
                            this.scrollTop('logcat_id');
                            this.scrollTop('ios-debug_id');
                        }
                        // indicate to server that next message can be sent and current message has been successfully handled
                        this.respondToMessage(this.adbLogcatSessionId, data.result.message.messageId, data.operationId);
                        this.logcatFilterChange();
                        this.logcatSearchChange();
                    } else if (type === 'terminal') {
                        if (data.result.message) {
                            this.showTerminalWaitingMessage = false;
                            // display skipped line message if needed
                            if (data.result.message.skipped > 0) {
                                this.terminalList.push({ status: 'PASS', msg: `Skipped ${data.result.message.skipped} lines` });
                                this.origIosDebugOutput.push(`Skipped ${data.result.message.skipped} lines`);
                            }
                            // display actual log lines
                            if (data.result.message.lines && data.result.message.lines.length) {
                                data.result.message.lines.forEach((msg) => {
                                    let strMatch;
                                    if (!this.isStringMatch) {
                                        strMatch = msg.toLowerCase().startsWith('list of devices attached');
                                        this.isStringMatch = strMatch;
                                    }
                                    if (this.isStringMatch && strMatch) {
                                        this.terminalList.push({ status: 'PASS', msg: 'List of devices attached\n' });
                                        this.origIosDebugOutput.push(`List of devices attached\n`);
                                        this.terminalList.push({ status: 'PASS', msg: this.manualTestService.selectedManualTestDevice.serialNumber + '\tdevice\n' });
                                        this.origIosDebugOutput.push(`${this.manualTestService.selectedManualTestDevice.serialNumber + '\tdevice\n'}`);
                                    }
                                    if (!this.isStringMatch) {
                                        this.terminalList.push({ status: 'PASS', msg: msg });
                                        this.origIosDebugOutput.push(msg);
                                    }
                                    if (this.terminalList.length > this.terminalBufferSize) {
                                        this.terminalList.shift();
                                        this.origIosDebugOutput.shift();
                                    }
                                    this.scrollTop('div_id');
                                });
                                this.lastMsgIndexTerminal = data.result.message.index + data.result.message.lines.length - 1;
                            }
                        }
                        if (data.result.errors && data.result.errors.length) {
                            data.result.errors.forEach((err) => {
                                this.terminalList.push('ERROR: ' + err.message);

                                if (data.result.isLast && err.code === 'TERMINAL_CMD_ERROR_MESSAGE_WAIT_TIMEOUT') {
                                    // discard sessionId if session has been closed by timeout
                                    this.adbTerminalSessionId = '';
                                }
                            });
                        }
                        if (data.result.isLast) {
                            this.terminalCmdInProgress = false;
                            this.disableIosDebugBtn = false;
                            if (this.isStringMatch) {
                                this.isStringMatch = false;
                            }
                            this.adbTerminalOperationId++;
                        }
                        if (this.autoScroll) {
                            this.scrollTop('ios-debug_id');
                        }
                        // indicate to server that next message can be sent and current message has been successfully handled
                        this.respondToMessage(this.adbTerminalSessionId, data.result.message.messageId, data.operationId);
                    }
                }
            }

            if (data.type === 'action' && data.value === 'clear') {
                if (type === 'logcat') {
                    this.adbLogcatClearWait = false;
                    // clear search data
                    this.textSearchResults = [];
                    this.searchLogcatOutput = '';
                    if (this.origLogcatOutput.indexOf(this.adbLogcatLastLineToClear) >= 0) {
                        if (this.logcatInProgress) {
                            this.origLogcatOutput = this.origLogcatOutput
                                .slice(this.origLogcatOutput.indexOf(this.adbLogcatLastLineToClear) + 1, this.origLogcatOutput.length - 1);
                            this.logcatOutput = this.origLogcatOutput.map((i) => i);
                        } else {
                            this.logcatOutput = [];
                            this.origLogcatOutput = [];
                        }
                        if (this.filterLogcat) {
                            this.logcatFilterChange();
                        }
                    }
                    this.adbLogcatLastLineToClear = '';
                } else if (type === 'terminal') {
                    this.adbTerminalClearWait = false;
                    if (this.terminalList.indexOf(this.adbTerminalLastLineToClear) >= 0) {
                        this.terminalList = this.terminalList
                            .slice(this.terminalList.indexOf(this.adbTerminalLastLineToClear) + 1, this.terminalList.length - 1);
                        this.origIosDebugOutput = this.origIosDebugOutput
                            .slice(this.origIosDebugOutput.indexOf(this.adbTerminalLastLineToClear) + 1, this.origIosDebugOutput.length - 1);
                    }
                    if (isIosDebug) {
                        this.searchLogcatOutput = '';
                    } else {
                        this.terminalText = '';
                    }
                }
            }
        }
    }

    stopAdbCmd(): void {
        if (this.adbTerminalSessionId) {
            this.adbTerminalOperationId++;
            this.rxStompService.publish({
                destination: `/terminals/sessions/${this.adbTerminalSessionId}/commands/request`,
                body: JSON.stringify({ type: 'action', value: 'stop', operationId: this.adbTerminalOperationId })
            });
            this.disableIosDebugBtn = true;
        }
    }

    sendAdbCommand(type: string, isIosDebug?: boolean): void {
        if (this.rxStompService.connectionState$.value === 3) {
            this.rxStompService.deactivate();
            this.establishStompConnection();
        }
        if (type === 'logcat') {
            this.showLogcatWaitingMessage = true;
            if (this.adbLogcatSessionId && this.adbLogcatSessionId.length) {
                if (this.logcatInProgress) {
                    // stop current logcat command
                    this.adbLogcatOperationId++;
                    this.rxStompService.publish({
                        destination: `/terminals/sessions/${this.adbLogcatSessionId}/commands/request`,
                        body: JSON.stringify({ type: 'action', value: 'stop', operationId: this.adbLogcatOperationId })
                    });
                    this.disableLogcatBtn = true;
                } else {
                    this.logcatOutput = [];
                    this.origLogcatOutput = [];
                    const body = { type: 'cmd', value: this.displaycommand, operationId: this.adbLogcatOperationId };
                    this.rxStompService.publish({
                        destination: `/terminals/sessions/${this.adbLogcatSessionId}/commands/request`,
                        body: JSON.stringify(body)
                    });
                    this.logcatInProgress = true;
                }
            } else {
                this.startAdbSession('logcat');
            }
        }
        if (type === 'terminal' && this.terminalText && this.terminalText.length) {
            this.showTerminalWaitingMessage = true;
            if (this.adbTerminalSessionId && this.adbTerminalSessionId.length) {
                if (!this.terminalCmdInProgress) {
                    if (this.terminalText === 'clear' || this.terminalText === 'clrscr') {
                        this.updateTerminalList(this.terminalText);
                        this.clearTerminalOutput();
                    } else {
                        if (this.terminalText !== '') {
                            if (!isIosDebug) {
                                this.updateTerminalList(this.terminalText);
                            } else {
                                this.updateTerminalListIosDebug(this.terminalText);
                            }
                        }
                        if (this.terminalText.startsWith('adb')) {
                            this.terminalText = this.terminalText.substring(4);
                        }
                        this.scrollTop('div_id');
                        this.adbTerminalOperationId++;
                        const body = { type: 'cmd', value: this.terminalText, operationId: this.adbTerminalOperationId };
                        this.rxStompService.publish({
                            destination: `/terminals/sessions/${this.adbTerminalSessionId}/commands/request`,
                            body: JSON.stringify(body)
                        });
                        this.terminalCmdInProgress = true;
                    }
                    this.currentSuggestionIndex = -1;
                    this.currentSuggestionIndexIosDebug = -1;
                    if (!isIosDebug) {
                        this.terminalText = '';
                    }
                    this.origIosDebugOutput = [];
                } else {
                    this.stopAdbCmd();
                }
            } else {
                this.startAdbSession('terminal', isIosDebug);
            }
        }
    }

    respondToMessage(sessionId: string, messageId, operationId: number): void {
        this.rxStompService.publish({
            destination: `/terminals/sessions/${sessionId}/commands/messages/response`,
            body: JSON.stringify({ operationId: operationId, messageId: messageId })
        });
    }

    logcatSearchChange(): void {
        this.textSearchResults = [];
        const results = [];
        if (!this.filterLogcat || this.filterLogcat.length === 0) {
            this.logcatOutput = this.origLogcatOutput.map((x) => x);
        } else {
            const filteredResults = this.origLogcatOutput.filter(item => {
                return (item.toLowerCase().includes(this.filterLogcat.toLowerCase()));
            });
            this.logcatOutput = filteredResults.map((x) => x);
        }
        this.currentSearchIndex = 0;
        if (this.searchLogcatOutput.length) {
            this.logcatOutput = this.logcatOutput.map((x, index) => {
                if (x.toLowerCase().includes(this.searchLogcatOutput.toLowerCase())) {
                    this.textSearchResults.push(index);
                }
                const re = new RegExp(this.searchLogcatOutput, 'gi');
                return x.replace(re, function (match) {
                    results.push(index + '-' + Math.round(Math.random() * 10000));
                    return `<mark class="${results[results.length - 1]}">` + match + '</mark>';
                });
            });
            this.textSearchResults = results;
            if (!this.logcatInProgress) {
                if (this.textSearchResults.length) {
                    setTimeout(() => {
                        const curr = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex])[0] as HTMLElement;
                        if (curr) {
                            curr.style.backgroundColor = '#5E2CED';
                            curr.style.color = '#ffffff';
                        }
                    }, 500);
                }
            }
        }
    }

    iosDebugSearchChange(): void {
        this.textSearchResults = [];
        const results = [];
        if (!this.filterLogcat || this.filterLogcat.length === 0) {
            this.terminalList = this.origIosDebugOutput.map((x) => {
                return { msg: x };
            });
        } else {
            const filteredResults = this.origIosDebugOutput.filter(item => {
                return (item.toLowerCase().includes(this.filterLogcat.toLowerCase()));
            });
            this.terminalList = filteredResults.map((x) => {
                return { msg: x };
            });
        }
        this.currentSearchIndex = 0;
        if (this.searchLogcatOutput.length) {
            this.terminalList = this.terminalList.map((x, index) => {
                if (x.msg.toLowerCase().includes(this.searchLogcatOutput.toLowerCase())) {
                    this.textSearchResults.push(index);
                }
                const re = new RegExp(this.searchLogcatOutput, 'gi');
                return {
                    msg: x.msg.replace(re, function (match) {
                        results.push(index + '-' + Math.round(Math.random() * 10000));
                        return `<mark class="${results[results.length - 1]}">` + match + '</mark>';
                    })
                };
            });
            this.textSearchResults = results;
            if (!this.logcatInProgress) {
                if (this.textSearchResults.length) {
                    setTimeout(() => {
                        const curr = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex])[0] as HTMLElement;
                        if (curr) {
                            curr.style.backgroundColor = '#5E2CED';
                            curr.style.color = '#ffffff';
                        }
                    }, 500);
                }
            }
        }
    }

    goToPrevFind(): void {
        if (this.textSearchResults.length && !this.logcatInProgress) {
            setTimeout(() => {
                const curr = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex])[0] as HTMLElement;
                if (this.currentSearchIndex === 0) {
                    curr.scrollIntoView();
                } else {
                    curr.style.backgroundColor = '#fcf8e3';
                    curr.style.color = '#000000';
                    const next = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex - 1])[0] as HTMLElement;
                    if (next) {
                        next.style.backgroundColor = '#5E2CED';
                        next.style.color = '#ffffff';
                        next.scrollIntoView();
                        if (this.currentSearchIndex > 0) {
                            this.currentSearchIndex--;
                        }
                    }
                }
            }, 300);
        }
    }

    goToNextFind(): void {
        if (this.textSearchResults.length && !this.logcatInProgress) {
            setTimeout(() => {
                const curr = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex])[0] as HTMLElement;
                if (this.currentSearchIndex === this.textSearchResults.length - 1) {
                    curr.scrollIntoView();
                } else {
                    curr.style.backgroundColor = '#fcf8e3';
                    curr.style.color = '#000000';
                    const next = document.getElementsByClassName(this.textSearchResults[this.currentSearchIndex + 1])[0] as HTMLElement;
                    if (next) {
                        next.style.backgroundColor = '#5E2CED';
                        next.style.color = '#ffffff';
                        next.scrollIntoView();
                        if (this.currentSearchIndex < this.textSearchResults.length - 1) {
                            this.currentSearchIndex++;
                        }
                    }
                }
            }, 300);
        }
    }

    scrollTop(element: 'div_id' | 'logcat_id' | 'ios-debug_id'): void {
        setTimeout(() => {
            const objDiv = document.getElementById(element);
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }, 2);
    }

    startAdbSession(type: string, isIosDebug?: boolean): void {
        this.deviceService.adbSessionStart(this.manualTestService.selectedManualTestDevice['labDomain'], this.manualTestService.selectedManualTestDevice.serialNumber, this.userId, type).subscribe((result) => {
            if (result && result.sessionId) {
                if (type === 'logcat') {
                    this.adbLogcatSessionId = result.sessionId;
                    if (this.adbLogcatResponseSub) { this.adbLogcatResponseSub.unsubscribe(); }
                    this.adbLogcatResponseSub = this.rxStompService
                        .watch(`/terminals/sessions/${this.adbLogcatSessionId}/commands/response`)
                        .subscribe((message: any) => {
                            this.handleCommandResponse('logcat', message);
                        });
                    clearInterval(this.adbLogcatTimer);
                    this.adbLogcatTimer = setInterval(() => {
                        this.rxStompService.publish({
                            destination: `/terminals/sessions/${this.adbLogcatSessionId}/commands/request`,
                            body: JSON.stringify({ type: 'ping', operationId: new Date().getTime() })
                        });
                    }, 30000);
                    this.sendAdbCommand('logcat');
                    this.rxStompService.connectionState$.subscribe((state) => {
                        if (state === RxStompState.CLOSED) {
                            this.reloadConnectionRxStompService();
                        }
                    });
                } else if (type === 'terminal') {
                    this.adbTerminalSessionId = result.sessionId;
                    if (this.adbTerminalResponseSub) { this.adbTerminalResponseSub.unsubscribe(); }
                    this.adbTerminalResponseSub = this.rxStompService.watch(`/terminals/sessions/${this.adbTerminalSessionId}/commands/response`)
                        .subscribe((message: any) => {
                            this.handleCommandResponse('terminal', message, isIosDebug);
                        });
                    clearInterval(this.adbTerminalTimer);
                    this.adbTerminalTimer = setInterval(() => {
                        this.rxStompService.publish({
                            destination: `/terminals/sessions/${this.adbTerminalSessionId}/commands/request`,
                            body: JSON.stringify({ type: 'ping', operationId: new Date().getTime() })
                        });
                    }, 30000);
                    this.sendAdbCommand('terminal', isIosDebug);
                    this.rxStompService.connectionState$.subscribe((state) => {
                        if (state === RxStompState.CLOSED) {
                            this.reloadConnectionRxStompService();
                        }
                    });
                }
            } else {
                this._snackBar.open('Session Id is missing from the backend response', '', {
                    duration: 3000, horizontalPosition: 'right',
                    verticalPosition: 'top', panelClass: ['danger'],
                });
            }
        }, err => {
            this.openErrorToast(err);
        });
    }
    openErrorToast(err: any): void {
        let msg = 'Error response has been received from the server. Please try again later.';
        if (err.error && err.error.data && err.error.data.message) {
            msg = err.error.data.message;
        }
        this._snackBar.open(msg, '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['danger'],
        });
    }
    reloadConnectionRxStompService(): void {
        this.logcatInProgress = false;
        this.adbLogcatSessionId = '';
        this.adbTerminalSessionId = '';
        this.terminalCmdInProgress = false;
        this.scrollTop('div_id');
    }

    clearTerminalOutput(): void {
        if (this.adbTerminalSessionId && this.lastMsgIndexTerminal > 0 && !this.adbTerminalClearWait) {
            this.adbTerminalLastLineToClear = this.terminalList[this.terminalList.length - 1];
            this.rxStompService.publish({
                destination: `/terminals/sessions/${this.adbTerminalSessionId}/commands/request`,
                body: JSON.stringify({ type: 'action', value: 'clear', lastIndex: this.lastMsgIndexTerminal })
            });
            this.adbTerminalClearWait = true;
        }
    }

    updateTerminalList(value) {
        this.terminalList.push({ status: 'PASS', msg: value });
        if (value !== this.terminalSuggestion[0]) {
            this.terminalSuggestion.unshift(value);
        }
        localStorage.setItem('adbSuggestion', JSON.stringify(this.terminalSuggestion));
    }

    updateTerminalListIosDebug(value) {
        this.terminalList.push({ status: 'PASS', msg: value });
        if (value !== this.terminalSuggestionIosDebug[0]) {
            this.terminalSuggestionIosDebug.unshift(value);
        }
        localStorage.setItem('iosDebugSuggestion', JSON.stringify(this.terminalSuggestionIosDebug));
    }

    establishStompConnection(): void {
        const config = getRxStompConfigForUser(this.common.getStorage('token'));
        this.rxStompService.configure(config);
        setTimeout(() => {
            this.rxStompService.activate();
            this.rxStompService.connectionState$.subscribe(state => {
                if (RxStompState[state] === 'CLOSED') {
                    this.scrollTop('logcat_id');
                    if (this.adbLogcatResponseSub) { this.adbLogcatResponseSub.unsubscribe(); }
                    if (this.adbTerminalResponseSub) { this.adbTerminalResponseSub.unsubscribe(); }
                }
                this.adbConnectionStatus = RxStompState[state];
            });
        }, 200);
    }
    logcatFilterChange(): void {
        const filteredResults = this.origLogcatOutput.filter(item => {
            return (item.toLowerCase().includes(this.filterLogcat.toLowerCase()));
        });
        this.logcatOutput = filteredResults.map((x) => x);
        if (this.searchLogcatOutput && this.searchLogcatOutput.length) {
            this.logcatSearchChange();
        }
    }

    updateIgnoreTyping = (v: boolean): void => {
        this.ignoreTyping = v;
    }

    getNotification() {
        const query = '';
        this.isLoading = false;
        this.deviceService.getDirNotificationsApi(query).subscribe(
            (res) => {
                if (res['message'] === 'Success') {
                    this.notifyList = res.data.notifications;
                } else {
                }
            },
            err => {
                this.isLoading = false;
                this.openErrorToast(err);
            }
        );
    }

    PostNotifyRead(id: any) {
        const request = [];
        if (id === 'All') {
            this.notifyList.forEach(element => {
                request.push(element.notificationId);
            });
        } else {
            request.push(id);
        }
        const data = {
            'notificationIds': request
        };

        this.isLoading = true;
        this.deviceService.PostreadNotificationApi(data).subscribe(
            (res) => {
                this.isLoading = false;

            },
            err => {
                this.openErrorToast(err);
            }
        );
    }
    notify(item) {
        this.PostNotifyRead(item.notificationId);
    }
    logcateChangeopt(event: any) {
        this.displaycommand = 'logcat ';
        const commandSelected = [];
        let commandStr = '';
        if (!this.isLogModeUI) {
            this.bufferSelected.forEach(element => {
                commandSelected.push(element['label']);
            });

            if (commandSelected.length > 0) {
                commandStr = commandSelected.toString();
                this.displaycommand = this.displaycommand + '-b ' + commandStr.replace(/\s+/g, ' ').trim();
            }
            if (this.commandText !== '') {
                this.displaycommand = this.displaycommand + ' | grep ' + this.commandText.replace(/\s+/g, ' ').trim();
            }
        } else {
            if (this.commandText !== '') {
                this.displaycommand = this.displaycommand + this.commandText.replace(/\s+/g, ' ').trim();
            }
        }
    }

    onItemSelect(item: any) {
        this.logcateChangeopt('');
    }

    changeAutoscrollLogcat(): void {
        this.autoScroll = !this.autoScroll;
    }

    changeAutoWrapLogcat(): void {
        this.autoWrapLogcat = !this.autoWrapLogcat;
    }

    changeAutoWrapTerminal(): void {
        this.autoWrapTerminal = !this.autoWrapTerminal;
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'label',
            textField: 'label',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }
    clearLogcatOutput(): void {
        if (this.adbLogcatSessionId && this.lastMsgIndexLogcat > 0 && !this.adbLogcatClearWait) {
            const length = this.origLogcatOutput.length - 2;
            this.adbLogcatLastLineToClear = this.origLogcatOutput[length];
            this.rxStompService.publish({
                destination: `/terminals/sessions/${this.adbLogcatSessionId}/commands/request`,
                body: JSON.stringify({ type: 'action', value: 'clear', lastIndex: this.lastMsgIndexLogcat })
            });
            this.adbLogcatClearWait = true;
        }
    }

    downloadLogcatFile(): void {
        const fileUrl = this.manualTestService.selectedManualTestDevice['labDomain'] + 'SnapBox/api/v1/terminals/sessions/' + this.adbLogcatSessionId + '/output';
        if (this.origLogcatOutput.length && !this.logcatInProgress) {
            this.deviceService.getFileFromLab(fileUrl).subscribe((res) => {
                if (res) {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(new Blob([res]));

                    a.href = url;
                    a.download = 'Log';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            });
        }
    }

    downloadAdbFile(): void {
        const fileUrl = this.manualTestService.selectedManualTestDevice['labDomain'] + 'SnapBox/api/v1/terminals/sessions/' + this.adbTerminalSessionId + '/output';
        if (this.terminalList.length && !this.terminalCmdInProgress) {
            this.deviceService.getFileFromLab(fileUrl).subscribe((res) => {
                if (res) {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(new Blob([res]));

                    a.href = url;
                    a.download = 'Log';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            });
        }
    }
    onKeyupMain(event: any): void {
        if (this.terminalSuggestion.length > 0 && (this.currentSuggestionIndex < this.terminalSuggestion.length - 1)) {
            this.currentSuggestionIndex++;
            this.terminalText = this.terminalSuggestion[this.currentSuggestionIndex];
        }
    }

    onKeydownMain(event: any): void {
        if (this.terminalSuggestion.length > 0 && this.currentSuggestionIndex >= -1) {
            this.currentSuggestionIndex--;
            this.terminalText = this.terminalSuggestion[this.currentSuggestionIndex];
        }
    }
    updateCommand() {
        this.options = [];
        this.terminalText = this.selectedCommand;
        this.selectedSingleOption = '';
        this.selectedMultipleOption = [];
        this.selectedSecondOption = '';
        this.iosDebugPlaceholder = '';
        this.iosDebugTextField = '';
        this.selectedDomain = false;
        switch (this.selectedCommand) {
            case 'Select utulity':
                break;
            case 'idevicecrashreport':
                this.options = this.iDeviceCrashReportOptions;
                this.displayableRequest = this.idevicecrashreportUsage;
                this.iosDebugPlaceholder = 'PATH';
                this.showCheckboxes = true;
                break;
            case 'idevicediagnostics':
                this.options = this.iDeviceDiagnosticsCommands;
                this.selectedSingleOption = this.iDeviceDiagnosticsCommands[0];
                this.secondOption = this.iDeviceDiagnosticsType;
                this.selectedSecondOption = this.iDeviceDiagnosticsType[0];
                this.displayableRequest = this.idevicediagnosticsUsage;
                this.showCheckboxes = false;
                break;
            case 'ideviceinfo':
                this.options = this.iDeviceInfoOptions;
                this.displayableRequest = this.ideviceinfoUsage;
                this.showCheckboxes = true;
                break;
            case 'idevicesyslog':
                this.options = this.iDeviceSysLogOptions1;
                this.displayableRequest = this.idevicesyslogUsage;
                this.iosDebugPlaceholder = 'STRING OR PROCESS';
                this.showCheckboxes = false;
                break;
        }
        this.updateTerminalText();
    }

    updateOption() {
        this.selectedSecondOption = '';
        this.iosDebugTextField = '';
        this.selectedDomain = false;
        switch (this.selectedSingleOption) {
            case 'diagnostics':
                this.secondOption = this.iDeviceDiagnosticsType;
                this.selectedSecondOption = this.iDeviceDiagnosticsType[0];
                break;
            case 'mobilegestalt':
                this.secondOption = this.iDeviceDiagnosticsMobileGestaltKey;
                break;
            case 'ioreg':
                this.secondOption = this.iDeviceDiagnosticsIoregPlane;
                this.selectedSecondOption = this.iDeviceDiagnosticsIoregPlane[0];
                break;
            case 'ioregentry':
                this.iosDebugPlaceholder = 'STRING';
                break;
            case '--match':
                this.iosDebugPlaceholder = 'STRING';
                break;
            case '--trigger':
                this.iosDebugPlaceholder = 'STRING';
                break;
            case '--untrigger':
                this.iosDebugPlaceholder = 'STRING';
                break;
            case '--process':
                this.iosDebugPlaceholder = 'PROCESS';
                break;
            case '--exclude':
                this.iosDebugPlaceholder = 'PROCESS';
                break;
        }
        this.updateTerminalText();
    }

    updateTerminalText() {
        if (this.selectedCommand === 'ideviceinfo' && !this.selectedMultipleOption.includes('--domain')) {
            this.selectedSecondOption = '';
        }
        this.selectedDomain = this.selectedMultipleOption.includes('--domain');
        let result = this.selectedCommand;
        if (this.selectedSingleOption) {
            result += ` ${this.selectedSingleOption}`;
        }
        if (this.selectedCommand === 'idevicecrashreport' || this.selectedSingleOption === 'mobilegestalt') {
            if (this.selectedMultipleOption.length) {
                result += ` ${this.selectedMultipleOption.join(' ')}`;
            }
        }
        if (this.selectedCommand === 'ideviceinfo') {
            const buffer = [...this.selectedMultipleOption];
            const index = buffer.indexOf('--domain');
            if (index !== -1) {
                buffer.splice(index, 1);
                buffer.push('--domain');
            }
            result += ` ${buffer.join(' ')}`;
        }
        if (this.selectedSecondOption) {
            result += ` ${this.selectedSecondOption}`;
        }
        if (this.selectedCommand === 'idevicecrashreport') {
            result += ` /storage/${this.iosDebugTextField}`;
        } else {
            if (this.iosDebugTextField) {
                result += ` ${this.iosDebugTextField}`;
            }
        }
        if (this.selectedCommand === 'idevicesyslog') {
            if (this.selectedMultipleOption.length) {
                result += ` ${this.selectedMultipleOption.join(' ')}`;
            }
        }
        this.terminalText = result;
    }
    iosDebugFilterChange(): void {
        const filteredResults = this.origIosDebugOutput.filter(item => {
            return (item.toLowerCase().includes(this.filterLogcat.toLowerCase()));
        });
        this.terminalList = filteredResults.map((x) => {
            return { msg: x };
        });
        if (this.searchLogcatOutput && this.searchLogcatOutput.length) {
            this.iosDebugSearchChange();
        }
    }
    getDeviceDetails(): void {
        if (!this.manualTestService.selectedManualTestDevice.serialNumber) {
            return;
        }
        const request = {
            'serialNumber': this.manualTestService.selectedManualTestDevice.serialNumber,
            'screenShot': false
        };
        this.isLoading = true;
        this.deviceService.getDeviceDetails(request).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.body['data']['tool_Result'].toUpperCase() === 'PASS') {
                    this.deviceInfo = res.body.data.tool_Extra;

                    const data = res.body.data.tool_Extra;
                    if (data.controls) {
                        data.additionalInfo.forEach(element => {
                            if (element.name === 'WIFI') {
                                if (this.manualTestService.selectedManualTestDevice?.oem !== 'Apple') {
                                    if (element.settingsInfo === '~~' || element.settingsInfo === 'No WiFi Connected~~') {
                                        this.wifiConnect = 'Not Connected';
                                    } else {
                                        if (element.settingsInfo.match('<unknown ssid>')) {
                                            this.wifiConnect = 'Connected';
                                        } else {
                                            this.wifiConnect = 'Connected';
                                        }
                                    }
                                }
                            }
                            if (element.name === 'Storage') {
                                this.storage = element.settingsInfo;
                            }
                            if (element.name === 'Battery') {
                                if (this.manualTestService.selectedManualTestDevice?.oem === 'Apple') {
                                    this.battery = element['settingsInfo'];
                                    if (element['settingsInfo'] == '1.0') {
                                        this.battery = 'Fully charged';
                                    } else if (element['settingsInfo'] == '-1.0') {
                                        this.battery = 'Charging';
                                    } else {
                                        this.battery = 'Charging';
                                    }
                                } else {
                                    this.battery = element['settingsInfo'] + '%' + ' Charged';
                                }
                            }
                            if (element['name'] === 'Network') {
                                const providerNameTemp = element['settingsInfo'];
                                // we are getting provider Name + Signal Strength separated by '~' example: "settingsInfo": "YOTA~LTE (-120 dBm)~"
                                this.providerName = providerNameTemp.split('(')[0] || '--';
                            }
                        });
                    }
                } else {
                    this.deviceInfo = '';
                }
            },
            err => {
                this.isLoading = false;
                this.openErrorToast(err);
            }
        );
    }

    checkDeviceCommStatus(): void {
        if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
        this.communicationStatusSub = interval(3000).subscribe((val) => {
            this.deviceService.checkCommStatus(this.manualTestService.selectedManualTestDevice['labDomain'], this.manualTestService.selectedManualTestDevice.serialNumber).subscribe((state) => {
                this.communicationStatus = state.state;
                if (state.state === 'READY' || state.state === 'FAILED') {
                    if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
                    this.getDeviceDetails();
                }
            }, (err) => {
                if (this.communicationStatusSub) { this.communicationStatusSub.unsubscribe(); }
                this.openErrorToast(err);
            });
        });
    }
    configResizableSplit = () => {
        if (this.resizableSplit) { this.resizableSplit.destroy(); this.resizableSplit = null; }
        if (this.mainResizableSplit) { this.mainResizableSplit.destroy(); this.resizableSplit = null; }
        if (this.splitMode && this.showLogcat && this.showTerminal) {
            this.resizableSplit = Split(['#split-logcat', '#split-term'], { direction: 'vertical' });
        }
        this.mainResizableSplit = Split(['#left-side-main-split', '#right-side-main-split'],
            {
                direction: 'horizontal', sizes: this.splitRatio,
                onDragEnd: (sizes: number[]) => this.updateSizes(sizes)
            });
        const mainGutters = document.getElementsByClassName('gutter');
        for (let i = 0; i < mainGutters.length; i++) {
            const mg = mainGutters[i] as HTMLElement;
            mg.style.backgroundRepeat = 'no-repeat';
            mg.style.backgroundPosition = '50%';
            mg.style.backgroundColor = '#eeeeee';
        }
        let vGutter: any;
        let hGutter: any;
        if (this.splitMode && this.showLogcat && this.showTerminal) {
            vGutter = document.getElementsByClassName('gutter-vertical')[0] as HTMLElement;
            vGutter.style.backgroundImage = `url("/assets/images/drag-handle-horizontal.svg")`;
            vGutter.style.height = '8px';
        }
        hGutter = document.getElementsByClassName('gutter-horizontal')[0] as HTMLElement;
        hGutter.style.backgroundImage = `url("/assets/images/drag-handle-vertical.svg")`;
        hGutter.style.width = '8px';
        hGutter.style.minWidth = '8px';
    }
    updateSizes(sizes: number[]): void {
        this.splitRatio = sizes;
    }
 }
