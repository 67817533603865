import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { GetSubscriptionListDto } from '../../../../shared/dto';

@Component({
    selector: 'app-create-enterprise',
    templateUrl: './create-enterprise.component.html',
    styleUrls: ['./create-enterprise.component.scss']
})
export class CreateEnterpriseComponent implements OnInit {
    enterpriseForm: FormGroup;
    isLoading = false;
    snapSupportOptions = [];
    subscriptionOptions = [];
    minStartDate = new Date();
    minEndDate = new Date();
    logoUrl = '';
    unlimited = 8640000000000;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router) {
        this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    }

    ngOnInit() {
        this.initializeForm();
        this.getSupportOptions();
        this.getSubscriptions();
    }

    initializeForm() {
        this.enterpriseForm = this.fb.group({
            name: ['', [Validators.required]],
            location: [''],
            snapSupportContact: ['', [Validators.required]],
            labLimit: ['', [Validators.min(0)]],
            deviceLimit: ['', [Validators.min(0)]],
            userLimit: ['', [Validators.min(0)]],
            logsDeletePeriod: ['', [Validators.min(0)]],
            subscriptionId: ['', [Validators.required]],
            subscriptionStart: ['', [Validators.required]],
            subscriptionEnd: [''],
            logo: [''],
        });
    }

    getSupportOptions() {
        this.isLoading = true;
        const query = 'roles=ADMIN&includeSelf=true';
        this.adminService.getUserListApi(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                this.snapSupportOptions = res.data.userList.map((user) => ({ name: user.firstName + ' ' + user.lastName, email: user.email }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    getSubscriptions() {
        this.isLoading = true;
        const query = 'type=COMMON,ENTERPRISE&status=ACTIVE';
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
                const result = res.data as GetSubscriptionListDto;
                this.subscriptionOptions = result.subscriptions.map((subscription) => ({ id: subscription._id, name: subscription.name }));
            }
        }, err => {
            this.isLoading = false;
        });
    }

    onSubmit() {
        if (this.enterpriseForm.valid) {
            if (this.enterpriseForm.value.logo) {
                this.isLoading = true;
                this.adminService.uploadImage(this.enterpriseForm.value.logo).subscribe(data => {
                    if (data["message"] == "Success") {
                        this.logoUrl = data.data.url;
                        this.createEnterprise();
                    } else {
                        this.isLoading = false;
                    }
                }, () => {
                    this.isLoading = false;
                });
            } else {
                this.createEnterprise();
            }
        }
    }

    createEnterprise() {
        if (this.enterpriseForm.valid) {
            const {
                name,
                location,
                snapSupportContact,
                labLimit,
                deviceLimit,
                userLimit,
                logsDeletePeriod,
                subscriptionId,
                subscriptionStart,
                subscriptionEnd
            } = this.enterpriseForm.value;
            const endOfDay = new Date().setHours(23, 59, 59);
            const contact = this.snapSupportOptions.find((option) => option.email === snapSupportContact);

            const request = {
                name,
                location,
                contacts: [{
                    type: 'SNAP',
                    name: contact.name,
                    email: contact.email
                }],
                maxLabCount: labLimit || 0,
                maxDeviceCount: deviceLimit || 0,
                maxUserCount: userLimit || 0,
                logsDeletePeriod: logsDeletePeriod || 0,
                subscriptionId,
                subscriptionStart: subscriptionStart < endOfDay ? subscriptionStart.getTime() : subscriptionStart.setHours(23, 59, 59),
                subscriptionEnd: subscriptionEnd ? subscriptionEnd.setHours(23, 59, 59) : this.unlimited,
                logo: this.logoUrl
            };

            this.isLoading = true;
            this.adminService.createEnterprise(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] === 'Success') {
                    this.router.navigate(['admin/enterprises']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    onFileChanged(event) {
        this.logoUrl = '';
        this.enterpriseForm.controls.logo.setValue(event);
    }

    resetExpiration() {
        this.enterpriseForm.controls.subscriptionEnd.setValue('');
    }

    replaceLimit(prop: string) {
        if (!this.enterpriseForm.value[prop]) {
            this.enterpriseForm.controls[prop].setValue('');
        }
    }

    updateExpirationMin() {
        this.minEndDate.setDate(this.enterpriseForm.value.subscriptionStart.getDate() + 1);
        if(this.enterpriseForm.value.subscriptionEnd < this.enterpriseForm.value.subscriptionStart) {
            this.enterpriseForm.controls.subscriptionEnd.setValue('');
        }
    }
}
