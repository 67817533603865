import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRoleEnum } from '../shared/enum';
import { CommonService } from '../shared/common.service';


@Injectable({ providedIn: 'root' })
export class LabManagementGuard implements CanActivate {
    constructor(private router: Router, private commonService: CommonService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const tokenData = this.commonService.getUser();
        if (!tokenData || ![UserRoleEnum.ADMIN, UserRoleEnum.ENTERPRISE_ADMIN, UserRoleEnum.ENTERPRISE_LAB_ADMIN].includes(tokenData.role)) {
            this.router.navigate(['/signin']);
            return false;
        }
        return true;
    }
}
