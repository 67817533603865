import { TelephonyAction } from './momt-action.interface';

export enum MomtIterationStatus {
    IDLE = 'IDLE',
    PROGRESS = 'PROGRESS',
    PASS = 'PASS',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
}

export interface SpeedTestResultMetric {
    bitrate: string;
    interval: string;
    retr: string;
    source: string;
    transfer: string;
    _id: string;
}

export interface MomtTestIteration {
    order: number;
    status: MomtIterationStatus;
    createdAt: number;
    modifiedAt: number;
    startedAt: number;
    actions: TelephonyAction[];
    result?: {
        metrics: SpeedTestResultMetric[];
    };
}

export const MomtIterationStatusMap = new Map<MomtIterationStatus, string>([
    [MomtIterationStatus.IDLE, 'Not Started'],
    [MomtIterationStatus.PROGRESS, 'In Progress'],
    [MomtIterationStatus.PASS, 'Passed'],
    [MomtIterationStatus.FAILED, 'Failed'],
    [MomtIterationStatus.CANCELED, 'Canceled'],
]);
