import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RecordAndPlayTypeEnum, ReplayStatus} from '../models/record-and-play.model';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {RecordAndPlayService} from '../record-and-play.service';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TooltipService} from '../../../shared/tooltip.service';
import {FeatureTypeEnum} from '../../../shared/enum';
import {Subscription} from 'rxjs';
import {interval} from 'rxjs/internal/observable/interval';
import {MatDatepickerInputEvent, MatDateRangePicker} from '@angular/material/datepicker';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {MatDrawer} from '@angular/material/sidenav';
import {CommonService} from '../../../shared/common.service';

@Component({
  selector: 'app-rnp-table-view',
  templateUrl: './rnp-table-view.component.html',
  styleUrls: ['./rnp-table-view.component.scss']
})
export class RnpTableViewComponent implements OnInit, OnDestroy {
  isLoading = false;
  activeTab = RecordAndPlayTypeEnum.CASE;
  TypeEnum = RecordAndPlayTypeEnum;
  tests = [];
  backupTests = [];
  results = [];
  backupResults = [];
  tooltipComponent = null;
  isRnpAvailable = false;
  isCloudAvailable = false;
  startDateFilter = 0;
  endDateFilter = 0;
  startDate: any = '';
  endDate: any = '';
  pollResultsSub: Subscription;
  statusList = [];
  selectedIndex: number;
  selectedStatus: any;
  selectedTest: any;
  newTestName = '';
  testListSearch = '';
  resultListSearch = '';
  sidePanelData: any;
  private modalRef: NgbModalRef;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };

  @ViewChild('filterpicker') filterPicker: MatDateRangePicker<any>;
  @ViewChild('drawer') drawer: MatDrawer;

  constructor(private rnpService: RecordAndPlayService,
              private dialogModalService: DialogModalService,
              private modalService: NgbModal,
              private _snackBar: MatSnackBar,
              private tooltipService: TooltipService,
              private commonService: CommonService) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.isRnpAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.RNP]);
    this.isCloudAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
    if (this.isRnpAvailable) {
      this.isLoading = true;
      this.getTestsList();
      this.getResultsList();
    }
  }

  ngOnDestroy(): void { }

  openTab(tab: RecordAndPlayTypeEnum): void {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
    }
  }

  getResultsList(): void {
    this.rnpService.getAllTestReplayList('', '', '', '').subscribe((result) => {
      if (result && result.data && result.data.recordReplaytList.length) {
        const runningReplays = result.data.recordReplaytList.filter((replay) => replay.status === ReplayStatus.RUNNING);
        if (runningReplays.length) {
          // poll results to check for updates on running replays
          if (this.pollResultsSub) {
            this.pollResultsSub.unsubscribe();
          }
          this.pollResultsSub = interval(10000).subscribe(() => {
            this.rnpService.getAllTestReplayList('', '', '', '').subscribe((list) => {
              if (list && list.data && list.data.recordReplaytList.length) {
                const running = list.data.recordReplaytList.filter((replay) => replay.status === ReplayStatus.RUNNING);
                if (!running.length) {
                  this.pollResultsSub.unsubscribe();
                  this.getTestsList();
                }
                this.results = list.data.recordReplaytList;
                this.backupResults = list.data.recordReplaytList;
                this.filterList();
              }
            }, (error) => {
              this.pollResultsSub.unsubscribe();
            });
          });
        }
      } else {
        if (this.pollResultsSub) {
          this.pollResultsSub.unsubscribe();
        }
      }
      this.results = result.data.recordReplaytList;
      this.backupResults = result.data.recordReplaytList;
      this.statusList = Array.from(new Set(this.results.map((r) => r.status)));
      this.filterList();
    }, (error) => {
      if (this.pollResultsSub) {
        this.pollResultsSub.unsubscribe();
      }
    });
  }

  getTestsList(): void {
    this.rnpService.getRecordAndPlayList('').subscribe((res) => {
      this.isLoading = false;
      if (res.data) {
        this.tests = res.data.recordPlaytList;
        this.tests.forEach((test, i) => {
          this.tests[i].running = '';
        });
        this.backupTests = res.data.recordPlaytList;

        this.rnpService.getAllTestReplayList('', '', '', ReplayStatus.RUNNING).subscribe((result) => {
          if (result && result.data && result.data.recordReplaytList.length) {
            // mark test in the list as the one with replay running
            result.data.recordReplaytList.forEach((run) => {
              this.tests.forEach((test, i) => {
                if (test.recordPlayId === run.recordDetails.recordId && run.status === ReplayStatus.RUNNING) {
                  this.tests[i].running = run.recordReplayId;
                }
              });
            });
          }
        }, (error) => { this.isLoading = false; });
      }
    });
  }

  filterList(): void {
    let res = this.backupResults.map((r) => r);
    // check date range filter
    if (this.startDateFilter > 0 && this.endDateFilter > 0) {
      res = this.backupResults.filter((result) => (result.insertDate * 1000) > this.startDateFilter
          && (result.insertDate * 1000) < (this.endDateFilter + 86400000));
    }

    // check search input
    if (this.resultListSearch.length) {
      res = res.filter((result) =>
          result.recordDetails.name ? result.recordDetails.name.includes(this.resultListSearch) : false);
    }

    // check status dropdown
    if (this.selectedStatus && this.selectedStatus.length) {
      res = res.filter((result) => {
        let add = false;
        this.selectedStatus.forEach((s) => {
          if (s === result.status) {
            add = true;
            return;
          }
        });
        return add;
      });
    }
    this.results = res;
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.startDateFilter = 0;
    this.endDateFilter = 0;
    this.filterPicker?.close();
    this.filterList();
  }

  clearFiltersForTests(): void {
    this.testListSearch = '';
    this.testListSearchChange();
  }

  clearFiltersForResults(): void {
    this.resultListSearch = '';
    this.selectedStatus = [];
    this.clearDateFilter();
    this.filterList();
  }

  openRenameTest(content: any, index: number): void {
    this.selectedTest = this.tests[index];
    this.newTestName = this.selectedTest.name;
    this.modalRef = this.modalService.open(content, this.modalOptions);
    this.modalRef.shown.subscribe(() => {
      document.getElementById('rename-test').focus();
    });
    this.modalRef.result.then(result => {
      this.getTestsList();
      this.getResultsList();
      this.selectedTest = null;
      this.newTestName = '';
    }, reason => { });
  }

  testListSearchChange(): void {
    this.tests = this.backupTests.filter((test) =>
        test.name.includes(this.testListSearch) || test.description.includes(this.testListSearch));
  }

  formatScreenResolution(screenResolution: any): string {
    let res = 'N/A';
    if (screenResolution && screenResolution.screenWidth && screenResolution.screenHeight) {
      res = screenResolution.screenWidth + 'x' + screenResolution.screenHeight;
    }
    return res;
  }

  saveNewTestName(): void {
    if (this.newTestName.length
        && this.checkForUniqueName(this.newTestName)
        && this.selectedTest.name !== this.newTestName) {
      let data: any;
      data = {
        recordPlayId: this.selectedTest.recordPlayId,
        data: this.selectedTest.data,
        name: this.newTestName,
      };
      if (this.selectedTest.description) {
        data.description = this.selectedTest.description;
      }
      this.rnpService.renameRecordAndPlayTest(data).subscribe((res) => {
        if (res) {
          this._snackBar.open('Test renamed successfully!', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['failure']
          });
          this.modalRef.close();
        }
      }, (err) => {
      });
    }
  }

  checkForUniqueName(newTestName: string): boolean {
    let res = true;
    if (newTestName.length) {
      this.tests.forEach((t) => {
        if (((this.selectedTest && this.selectedTest.recordPlayId !== t.recordPlayId))
            && t.name === newTestName) {
          res = false;
        }
      });
    }
    return res;
  }

  showTestDetails(i: number) {
    this.sidePanelData = this.tests[i];
    this.selectedIndex = i;
    this.drawer.toggle();
  }

  deleteTest(id: any): void {
      this.dialogModalService.openConfirmationDialog('deleteTestModal', () => {
        this.rnpService.deleteRecordAndPlayTest(id).subscribe((res) => {
          this.getTestsList();
        });
      });
  }

  getDropDownSettings(countShowElements: number): IDropdownSettings {
    return {
      singleSelection: false,
      idField: 'label',
      textField: 'label',
      itemsShowLimit: countShowElements,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
  }

  navigateToOriginalTest(result: any): void {
    this.tests.forEach((t, i) => {
      if (t.recordPlayId === result.recordDetails.recordId) {
        this.sidePanelData = t;
        return;
      }
    });
    this.drawer.toggle();
  }

  displayDate(date: number): string {
    return this.rnpService.displayDate(date);
  }

  updateDisplayedDate(type: string, $event: MatDatepickerInputEvent<Date>) {
    if (type === 'start') {
      this.startDateFilter = new Date($event.value).getTime();
    }
    if (type === 'end') {
      this.endDateFilter = new Date($event.value).getTime();
    }
    if (this.startDateFilter > 0 && this.endDateFilter > 0) {
      this.filterList();
    }
  }

  checkIfTestExists(result: any): boolean {
    if (result.recordDetails && result.recordDetails.recordId) {
      const found = this.tests.filter((t) => t.recordPlayId === result.recordDetails.recordId);
      return !!found.length;
    } else {
      return false;
    }
  }

  showStatusBar(status: string): boolean {
    return status === ReplayStatus.COMPLETED || status === ReplayStatus.RUNNING
        || status === ReplayStatus.STOPPED || status === ReplayStatus.FINALIZING || status === ReplayStatus.FAILED;
  }

  getNoOfIterationsForStatus(list: any, status: string): number {
    return list.filter((i) => i.status === status).length;
  }

  getIterationWidth(list: any, status: string): string {
    const typed = this.getNoOfIterationsForStatus(list, status);
    return (Math.ceil(100 / list.length)) * typed + '%';
  }

  deleteRecord(id: string) {
    this.dialogModalService.openConfirmationDialog('deleteTestReplayModal', () => {
      this.rnpService.deleteReplayRecord(id).subscribe((res) => {
        this.getResultsList();
      }, (error) => { });
    });
  }

  toggleSidepanelAction(): void {
    this.drawer.toggle();
    this.selectedIndex = null;
  }

  updateTable($event: any) {
    this.getTestsList();
  }

  closeConfigModal(): void {
    this.modalRef.close('cancel');
  }
}
