import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkService } from '../../shared/network.service';
import { ApiResponseDto, DeleteDirectoryDto, GetLabListDto, SimpleApiResponse } from '../../shared/dto';
import {stateTooltip} from '../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private pageChange = new BehaviorSubject('false');
  currentPage = this.pageChange.asObservable();
  isTerminalFocus = false;

  constructor(private networkService: NetworkService) { }

  bluetooth(body: any) {
    return this.networkService.localPost('api/device/controller/services/bluetooth', body, null, 'basic');
  }

  call(body: any) {
    return this.networkService.localPost('api/device/controller/services/voiceCallTestAPI', body, null, 'basic');
  }

  wifi(body: any) {
    return this.networkService.localPost('api/device/controller/services/wifi', body, null, 'basic');
  }

  volume(body: any) {
    return this.networkService.localPost('api/device/controller/services/volume', body, null, 'basic');
  }

  reboothAnd(body: any) {
    return this.networkService.localPost('api/device/controller/services/rebootDevice', body, null, 'basic');
  }

  Email(body: any) {
    return this.networkService.localPost('api/device/controller/services/emailTest', body, null, 'basic');
  }

  getDeviceDetails(body: any) {
    return this.networkService.localPost('api/device/controller/services/getDeviceDetailsWithControls', body, null, 'basic');
  }

  getDeviceControlsMode(sn: string, url: string) {
    return this.networkService.labGet(url + `SnapBox/api/devices/${sn}/controls/mode?names=AIRPLANE,WIFI,MOBILE_DATA,BLUETOOTH,VOLUME`, null, null, 'basic');
  }

  changeDeviceControlsMode(url: string, sn: string, body: any) {
    return this.networkService.labPost(url + `SnapBox/api/devices/${sn}/controls/mode`, body);
  }

  changeDeviceControlsPanelVisibility(url: string, sn: string, action: 'OPEN' | 'CLOSE') {
    return this.networkService.labPost(url + `SnapBox/api/devices/${sn}/controls?action=${action}`);
  }

  getActiveDevice(queryPram: any) {
    return this.networkService.get('api/devicebooking/booked?' + queryPram, null, null, 'basic');
  }

  getDeviceDynamicFields(sn: string) {
    return this.networkService.get(`api/devices/${sn}/dynamic-fields`, null, null, 'basic');
  }

  updateDeviceDynamicFields(sn: string, body: any) {
    return this.networkService.post(`api/devices/${sn}/dynamic-fields`, body, null, 'basic');
  }

  getAgentLogs(queryPram: any) {
    return this.networkService.get('api/devices/snapbox/logs?' + queryPram, null, null, 'basic');
  }

  releaseDevice(body: any) {
    return this.networkService.post('api/devicebooking/release/device', body, null, 'basic');
  }

  getCardApi() {
    return this.networkService.get('api/cards', null, null, 'basic');
  }

  clickXYCoordinate(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/clickByXandYCoordinate', body, null, 'basic');
  }

  doubleTap(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/doubleTap', body, null, 'basic');
  }

  tripleTap(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/tripleTap', body, null, 'basic');
  }

  swipedevice(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/swipe', body, null, 'basic');
  }

  dragNDrop(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/dragNDrop', body, null, 'basic');
  }

  pinch(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/pinch', body, null, 'basic');
  }

  HomeCalled(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/home', body, null, 'basic');
  }

  BackCalled(body: any, url: any) {
    return this.networkService.labPost(url + 'SnapBox/api/back', body, null, 'basic');
  }

  powerCall(body: any, url: any) {
    return this.networkService.labPost(url + 'SnapBox/api/powerButton', body, null, 'basic');
  }

  RecentCalled(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/recentApps', body, null, 'basic');
  }

  adbInputText(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/adbInputText', body, null, 'basic');
  }

  adbInputKeyEvent(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/adbInputKeyEvent', body, null, 'basic');
  }

  landscape(body: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/orientationMode', body, null, 'basic');
  }

  getOrientationMode(url: any, serialNumber) {
    return this.networkService.labGet(url + '/SnapBox/api/devices/' + serialNumber + '/orientation', null, null, 'basic');
  }

  geoLocation(queryParam: any, url: any, serialNumber: any) {
    return this.networkService.labPost(url + '/SnapBox/api/devices/' + serialNumber + '/geolocation/change?' + queryParam, null, null, 'basic');
  }

  resetgeoLocation(url: any, serialNumber: any) {
    return this.networkService.labPost(url + '/SnapBox/api/devices/' + serialNumber + '/geolocation/reset', null, null, 'basic');
  }

  getRatList(url: any, serialNumber: any) {
    return this.networkService.labGet(url + '/SnapBox/api/devices/' + serialNumber + '/rats', null, null, 'basic');
  }

  postNetworkChange(url: any, serialNumber: any, queryParam: any) {
    return this.networkService.labPost(url + '/SnapBox/api/devices/' + serialNumber + '/rats/change?' + queryParam,
      null, null, 'basic');
  }

  uploadAPK(url: any, serialNumber: any, body: any) {
    return this.networkService.labPostMultipart(url + '/SnapBox/api/v1/devices/' + serialNumber + '/app-install', body);
  }

  adbPush(url: any, serialNumber: any, body: any) {
    return this.networkService.labPostMultipart(url + 'SnapBox/api/v1/devices/' + serialNumber + '/file/push', body);
  }

  getApkUploadStatus(url: any, serialNumber: any) {
    return this.networkService.labGet(url + '/SnapBox/api/v1/devices/' + serialNumber + '/app-install/status', null, null, 'basic');
  }

  appiumRemoteStatus(serialNumber: any) {
    return this.networkService.get('api/devices/communication/remote-status?serialNumber=' + serialNumber, null, null, 'basic');
  }

  switchTestMode(body: any) {
    return this.networkService.post('api/devices/communication/remote-testing', body, null, 'basic');
  }

  switchRemoteMode(body: any) {
    return this.networkService.post('api/devices/communication/remote-management', body, null, 'basic');
  }

  getAppiumLogs(query: any) {
    return this.networkService.get('api/logs/links/v1?' + query, null, null, 'basic');
  }

  adbPushStatus(url: any, serialNumber: any) {
    return this.networkService.labGet(url + 'SnapBox/api/v1/devices/' + serialNumber + '/file/push/status', null, null, 'basic');
  }

  checkCommStatus(url: any, serialNumber: any) {
    return this.networkService.labGet(url + 'SnapBox/api/v1/devices/' + serialNumber + '/communication/state', null, null, 'basic');
  }

  adbPull(body: any) {
    return this.networkService.post('api/devices/adb/v1/pull/start', body, null, 'basic');
  }

  adbPullState(serialNumber: any, operationId: any) {
    return this.networkService.get('api/devices/adb/v1/pull/state?serialNumber=' + serialNumber + '&operationId=' + operationId);
  }

  getPulledFiles(serialNumber: any, operationId: any) {
    return this.networkService.get('api/devices/adb/v1/pulled/files?serialNumber=' + serialNumber + '&operationId=' + operationId);
  }

  adbPullStop(body: any) {
    return this.networkService.post('api/devices/adb/v1/pull/cancel', body, null, 'basic');
  }

  adbPushStop(url: any, serialNumber: any) {
    return this.networkService.labPost(url + 'SnapBox/api/v1/devices/' + serialNumber + '/file/push/cancel', null, null, 'basic');
  }

  adbSessionStart(url: any, serialNumber: string, userId: string, type: string) {
    const body = {
      userId: userId,
      sn: serialNumber,
      type: type
    };
    return this.networkService.labPost(url + 'SnapBox/api/v1/terminals' + '/sessions/start', body, null, 'basic');
  }
  checkCambrionixservice(domain: any) {
    return this.networkService.labGet(domain + 'SnapBox/api/v1/config', null, null, 'basic');
  }
  usbAttach(url: any, serialNumber: any) {
    return this.networkService.labPost(url + 'SnapBox/api/v1/devices/' + serialNumber + '/attach', null, null, 'basic');
  }
  usbDetach(url: any, serialNumber: any) {
    return this.networkService.labPost(url + 'SnapBox/api/v1/devices/' + serialNumber + '/detach', null, null, 'basic');
  }
  getFileStorageApi(queryPram: any) {
    return this.networkService.get('api/file-storage?' + queryPram, null, null, 'basic');
  }
  PostFileStorageApi(body: any) {
    return this.networkService.post('api/file-storage/create', body, null, 'basic');
  }
  updateFolderNameApi(body: any) {
    return this.networkService.post('api/file-storage/rename', body, null, 'basic');
  }
  updateFileNameApi(body: any) {
    return this.networkService.post('api/file-storage/renameFile', body, null, 'basic');
  }
  deleteFolderApi(body: DeleteDirectoryDto) {
    return this.networkService.deleteDir('api/file-storage/delete', body, null, 'basic');
  }
  getFileZip(body) {
    return this.networkService.getZip('api/file-storage/multiple-download?' + body, null, null, null);
  }
  getFileDownloadApi(queryPram: any) {
    return this.networkService.get('api/file-storage/download?' + queryPram, null, null, 'basic');
  }
  uploadImage(image: any, path: any) {
    const formData = new FormData();
    formData.append('files', image);
    formData.append('path', path);
    return this.networkService.uploadImages('api/file-storage/upload', formData, null, 'basic');
  }
  getDirNotificationsApi(queryPram: any) {
    return this.networkService.get('api/file-storage/notifications?' + queryPram, null, null, 'basic');
  }
  PostreadNotificationApi(body: any) {
    return this.networkService.post('api/file-storage/notification', body, null, 'basic');
  }

  ExtendReservationApiV3(body) {
    return this.networkService.post('api/v3/devicebooking/extend', body, null, 'basic');
  }

  // Record and Play APIs

  getRecordAndPlayList(deviceId: string): Observable<any> {
    return this.networkService.get('api/recordPlay/list?', null, null, 'basic');
    //  + 'deviceId=' + deviceId
  }

  saveRecordAndPlayTest(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay', body, null, 'basic');
  }

  deleteRecordAndPlayTest(id: any): Observable<any> {
    return this.networkService.delete('api/recordPlay/' + id, null, 'basic');
  }

  renameRecordAndPlayTest(data: any): Observable<any> {
    return this.networkService.put('api/recordPlay/', data, null, 'basic');
  }

  deleteRecordApi(id): Observable<any> {
    return this.networkService.deleteDir('api/recordPlay/' + id, null, null, 'basic');
  }

  startTestReplay(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/start', body, null, 'basic');
  }

  stopTestReplay(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/stop', body, null, 'basic');
  }

  getLastAction(recordReplayId: string): Observable<any> {
    return this.networkService.get('api/recordPlay/plays/action/last?' + `recordReplayId=${recordReplayId}`, null, null, 'basic');
  }

  getAllTestReplayList(recordId: string, recordReplayId: string, deviceId: string, status: string): Observable<any> {
    let options = '';
    if (recordId.length) {
      options += `recordId=${recordId}`;
    }
    if (recordReplayId.length) {
      options = options.length ? options + `&recordReplayId=${recordReplayId}` : options + `recordReplayId=${recordReplayId}`;
    }
    if (deviceId.length) {
      options = options.length ? options + `&deviceId=${deviceId}` : options + `deviceId=${deviceId}`;
    }
    if (status.length) {
      options = options.length ? options + `&status=${status}` : options + `status=${status}`;
    }
    return this.networkService.get('api/recordPlay/plays?' + options, null, null, 'basic');
  }

  getLogsAndVideoLinksForReplay(recordReplayId: string, type: string): Observable<any> {
    return this.networkService.get('api/recordPlay/plays/download?'
      + `type=${type}&recordReplayId=${recordReplayId}`, null, null, 'basic');
  }

  handleArtifactsAfterStop(body: any): Observable<any> {
    return this.networkService.post('api/recordPlay/plays/test-interruption/artefacts', body, null, 'basic');
  }

  deleteReplayRecord(id: string): Observable<SimpleApiResponse> {
    return this.networkService.deleteDir('api/recordPlay/plays/' + id, null, null, 'basic');
  }

  ignoreInputFromRecordAndPlay($event: any) {
    this.isTerminalFocus = $event;
  }

  checkSmartHubFeature(device) {
    return device?.usbHubType && device?.usbHubType !== 'GENERAL' && device?.isSmartHubEnabled;
  }

  getFileFromLab(url) {
    return this.networkService.labGetFile(url);
  }

  getDeviceStatus(data: any): string {
    const state = data.deviceState;
    const stateCode = data.deviceStateCode;
    const commStatus = data.deviceCommunicationStatus;
    let displayed = 'unknown';
    if (state) {
      if (state.toLowerCase() === 'online') {
        if (stateCode.toLowerCase() === 'online') {
          if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
            displayed = 'testing';
          } else {
            displayed = 'online';
          }
        } else {
          if (stateTooltip[stateCode.toLowerCase()]) {
            displayed = stateCode.toLowerCase();
          }
        }
      } else if (state.toLowerCase() === 'offline') {
        if (stateCode && stateCode !== '') {
          if (stateCode.toLowerCase() === 'removed') {
            displayed = 'disconnected';
          } else {
            if (stateTooltip[stateCode.toLowerCase()]) {
              displayed = stateCode.toLowerCase();
            }
          }
        }
      } else if (state.toLowerCase() === 'blocked') {
        displayed = state.toLowerCase();
      }
    }
    return displayed;
  }

  showOfflineTooltip(data: any): string {
    const state = this.getDeviceStatus(data);
    if (state) {
      return stateTooltip[state] ? stateTooltip[state] : '';
    } else {
      return '';
    }
  }
  getWifiStatus(url: any, serialNumber: any) {
    return this.networkService.labGet(url + '/SnapBox/api/devices/' + serialNumber + '/wifi/status', null, null, 'basic');
  }

  getDeviceLocation(url: string, serialNumber: string) {
    return this.networkService.labGet(url + 'SnapBox/api/v1/devices/' + serialNumber + '/location', null, null, 'basic');
  }
}
