<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>Update user</h1>
<div class="not-found" *ngIf="showError">
    User not found
</div>
<form class="user-form" [formGroup]="userForm" (ngSubmit)="updateUser()">
    <section class="left-part">
        <div class="double-input">
            <div class="group">
                <label for="name">First Name <span>*</span></label>
                <input type="text" formControlName="firstName" maxlength="125" id="firstName" class="form-control"
                    placeholder="Enter First Name" />
                <div *ngIf="userForm.controls.firstName.invalid && (userForm.controls.firstName.dirty || userForm.controls.firstName.touched)"
                    class="error">
                    <div *ngIf="userForm.controls.firstName.errors.required">*First Name is required</div>
                </div>
            </div>
            <div class="group">
                <label for="lastName">Last Name <span>*</span></label>
                <input type="text" formControlName="lastName" maxlength="125" id="lastName" class="form-control"
                    placeholder="Enter Name" />
                <div *ngIf="userForm.controls.lastName.invalid && (userForm.controls.lastName.dirty || userForm.controls.lastName.touched)"
                    class="error">
                    <div *ngIf="userForm.controls.lastName.errors.required">*Last Name is required</div>
                </div>
            </div>
        </div>
        <div class="group">
            <label for="email">Email <span>*</span></label>
            <input type="text" formControlName="email" id="email" class="form-control" placeholder="Enter Email" />
            <div *ngIf="userForm.controls.email.invalid && (userForm.controls.email.dirty || userForm.controls.email.touched)"
                class="error">
                <div *ngIf="userForm.controls.email.errors.required">*Email is required</div>
                <div *ngIf="userForm.controls.email.errors.pattern">*Invalid email</div>
            </div>
        </div>
        <div class="group">
            <span class="expiration-empty" *ngIf="!expirationDate" (click)="picker.open()">
              + Add expiration date
            </span>
            <input class="datepicker" readonly matInput [matDatepicker]="picker"
              (dateChange)="updateExpirationDate($event)" [min]="minDate" />
            <mat-datepicker #picker></mat-datepicker>
            <span class="expiration-selected" *ngIf="expirationDate" (click)="picker.open()">
              <img src="../../../../assets/images/NewUI/expiration-calendar.svg" alt="" />
              {{displayExpirationDate()}}
              <svg (click)="clearExpirationDate($event)" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960"
                width="20">
                <path
                  d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
              </svg>
            </span>
          </div>
          <div class="form-actions">
              <button class="snap-button secondary" type="button" routerLink="/admin/users">Cancel</button>
              <button class="snap-button primary" type="submit" [disabled]="!userForm.valid">Update</button>
          </div>
    </section>
</form>