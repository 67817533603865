import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTestService } from '../custom-test.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { CommonService } from '../../../shared/common.service';
import { FeatureTypeEnum } from '../../../shared/enum';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { CreateCustomTestForm, CustomTestTemplateCategory, CustomTestTemplateValidator } from '../interfaces';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

const PARAMETER_ORDER = {
    sourceDevice: 0,
    WAITtype: 0,
    ACTtype: 1,
    targetDevice: 2,
    timer: 3,
    type: 0,
    CALL_STATUS: 1,
    maxTimeout: 2
}

@Component({
    selector: 'new-custom-test-case',
    templateUrl: './new-custom-test-case.component.html',
    styleUrls: ['./new-custom-test-case.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ]
})
export class NewCustomTestCaseComponent implements OnInit {
    tooltipComponent = null;
    isTelephonyAvailable = false;
    testCaseForm: FormGroup;
    stepForms: FormArray<FormGroup<any>>;

    isLoading = 0;
    testCaseId = '';
    testNotFound = false;
    devices = [{ isVerifiable: true, index: 1 }];
    deviceVerifications = [{ isVerifiable: true, index: 1 }];

    deviceOptions = [1];
    renderList = true;

    categories: CustomTestTemplateCategory[] = [];
    validators: CustomTestTemplateValidator[] = [];
    stepParameters: Record<string, any> = {};
    stepVerificationParameters: Record<string, any> = {};
    private modalRef: NgbModalRef;
    saveAsName = '';
    modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'md',
    };

    @ViewChild('stepList') stepList: ElementRef<HTMLElement>;

    constructor(
        private router: Router,
        private customTestService: CustomTestService,
        private tooltipService: TooltipService,
        private commonService: CommonService,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private modalService: NgbModal,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.isTelephonyAvailable = this.commonService.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        this.testCaseId = this.route.snapshot.queryParamMap.get('id');

        if (this.isTelephonyAvailable) {
            this.getTemplateInfo();
        }

        this.testCaseForm = this.fb.group({
            name: ['', [Validators.required]],
            description: [''],
            deviceCount: [1, [Validators.required, Validators.min(1), Validators.max(3)]]
        });

        this.stepForms = this.fb.array([].map(this.createStepForm));
    }

    getTestInfo() {
        this.isLoading++;
        this.customTestService.getTestTemplate(this.testCaseId).subscribe((response) => {
            this.isLoading--;
            // fill page 1
            this.testCaseForm.setValue({
                name: response.name,
                description: response.description || "",
                deviceCount: response.devices.length
            });
            this.devices = response.devices.map((device) => {
                return {
                    isVerifiable: !device.noVerifications,
                    index: device.index
                };
            });
            this.updateDeviceOptions();
            // fill page 2
            response.steps.forEach((step, index) => {
                const category = this.categories.find(category => category.category === step.action.category);
                const action = category?.actions.find((action) => action.type === step.action.data.type);
                if (category && action) {
                    const verifications = this.fb.array(this.deviceVerifications.map((device) => {
                        const deviceVerification = step.validators?.find((validator) => {
                            return validator.target.type === 'DEVICE' && validator.target.parameters.some((p) => p.value === device.index);
                        });
                        if (deviceVerification) {
                            const group = { type: [deviceVerification.category] };
                            const validator = this.validators.find((v) => v.type === deviceVerification.category);
                            if (validator) {
                                validator.parameters.forEach((param) => {
                                    param.properties.forEach((prop) => {
                                        const value = deviceVerification.property.type === prop.type ? deviceVerification.property.value : '';
                                        group[prop.type] = [value, Validators.required];
                                    });
                                });
                                group['maxTimeout'] = [deviceVerification.maxTimeout || '', [Validators.min(0), this.createVerificationTimeoutValidator(index)]]
                            }

                            return this.fb.group(group);
                        }
                        return this.fb.group({
                            type: [''],
                        });
                    }));

                    const group: any = {
                        category: [step.action.category, Validators.required],
                        type: [step.action.data.type, Validators.required],
                        verifications
                    };
                    step.action.data.payload.forEach((payload) => {
                        const parameter = payload.parameters.find((param) => param.initPlace === 'CREATE_TEST');
                        if (parameter) {
                            const validation = [Validators.required];
                            if (parameter.constraints) {
                                parameter.constraints.forEach((constraint) => {
                                    switch (constraint.name) {
                                        case 'MAX':
                                            validation.push(Validators.max(constraint.value));
                                            break;
                                        case 'MIN':
                                            validation.push(Validators.min(constraint.value));
                                            break;
                                    }
                                })
                            }
                            group[payload.key] = [parameter.value || parameter.defaultValue || '', validation];
                        }
                    });
                    this.stepForms.push(this.fb.group(group));
                }
            });
        }, () => {
            this.testNotFound = true;
            this.isLoading--;
        });
    }

    getTemplateInfo() {
        this.isLoading++;
        this.customTestService.formTemplateCategories().subscribe((categories) => {
            this.categories = categories.sort((a, b) => a.category < b.category ? -1 : 1);
        }, () => this.isLoading--, () => {
            this.isLoading--;
            this.createStepFormParameters();
            if (this.testCaseId && !this.isLoading) {
                this.getTestInfo();
            }
        });

        this.isLoading++;
        this.customTestService.formTemplateValidators().subscribe((validators) => {
            this.validators = validators.sort((a, b) => a.type < b.type ? -1 : 1);
        }, () => this.isLoading--, () => {
            this.isLoading--;
            this.createVerificationFormParameters();
            if (this.testCaseId && !this.isLoading) {
                this.getTestInfo();
            }
        });
    }


    prepareForm(): CreateCustomTestForm {
        const testForm = this.testCaseForm.value;
        const steps = this.stepForms.value.map((step, i) => {
            const category = this.categories.find((category) => category.category === step.category);
            const action = category?.actions.find((action) => action.type === step.type);
            const validators = [];
            step.verifications.forEach((verification, i) => {
                if (verification.type) {
                    // change here if multiple possible properties appear
                    const propertyName = 'CALL_STATUS';
                    const validator: any = {
                        category: verification.type,
                        target: {
                            type: 'DEVICE',
                            parameters: [{
                                key: 'index',
                                value: this.deviceVerifications[i].index
                            }]
                        }
                    };
                    const templateValidator = this.validators.find((v) => v.type === verification.type);
                    const templateParameter = templateValidator?.parameters.find((param) => param.type === 'DEVICE');
                    const templateProperty = templateParameter?.properties.find((prop) => prop.type === propertyName);
                    const templateValue = templateProperty?.values.find((value) => value.value === verification[propertyName]);
                    validator.property = {
                        type: propertyName,
                        value: verification[propertyName],
                        typeName: templateProperty?.typeName,
                        valueName: templateValue?.valueName
                    },
                        validator.maxTimeout = verification.maxTimeout
                    validators.push(validator);
                }
            });
            return {
                action: {
                    order: i + 1,
                    category: step.category,
                    data: {
                        type: step.type,
                        payload: action.payload.map((payload) => {
                            return {
                                ...payload,
                                parameters: payload.parameters.map((param) => {
                                    let value = param.value;
                                    if (param.initPlace === 'CREATE_TEST') {
                                        value = param.type === 'number' ? parseInt(step[payload.key]) : step[payload.key];
                                    }
                                    return {
                                        ...param,
                                        value
                                    };
                                })
                            };
                        })
                    }
                },
                validators
            };
        });
        return {
            name: testForm.name,
            description: testForm.description,
            devices: this.devices.map((device) => ({ index: device.index, noVerifications: !device.isVerifiable })),
            steps
        }
    }

    submitForm() {
        this.isLoading++;
        const form = this.prepareForm();
        if (this.testCaseId) {
            const updateForm = { ...form, id: this.testCaseId };
            this.customTestService.updateTestTemplate(updateForm).subscribe(() => {
                this.isLoading--;
                this.router.navigate(['/snap/custom-tests']);
            }, () => this.isLoading--);
        } else {
            this.customTestService.createTestTemplate(form).subscribe(() => {
                this.isLoading--;
                this.router.navigate(['/snap/custom-tests']);
            }, () => this.isLoading--);
        }
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }

    createStepFormParameters() {
        this.categories.forEach((category) => {
            this.stepParameters[category.category + 'type'] = {
                type: 'select',
                name: category.category + 'type',
                options: [
                    {
                        value: '',
                        name: category.category === 'WAIT' ? 'Specify condition' : 'Select Device Action Type'
                    },
                    ...category.actions.map((action) => {
                        return {
                            name: action.name,
                            value: action.type
                        };
                    })
                ],
                constraints: {
                    required: {
                        message: category.category === 'WAIT' ? 'Wait condition is required' : 'Action type is required',
                    },
                    eventValidation: {
                        message: 'At least one device validation is required'
                    }
                }
            };

            category.actions.forEach((action) => {
                action.payload.forEach((payload) => {
                    const param = payload.parameters.find((p) => p.initPlace === 'CREATE_TEST');
                    if (!this.stepParameters[payload.key] && param) {
                        const constraints = {
                            required: {
                                message: param.name + ' is required'
                            }
                        };

                        if (category.category === 'WAIT' && action.type === 'EVENT') {
                            constraints['eventValidation'] = { message: 'At least one "Change to" device verification required' }
                        }

                        param.constraints?.forEach((constraint) => {
                            constraints[constraint.name.toLocaleLowerCase()] = {
                                message: constraint.description,
                                value: constraint.value
                            }
                        });
                        let parameter = {};
                        if (payload.key.toLocaleLowerCase().includes('device')) {
                            parameter = {
                                type: 'select',
                                name: payload.key,
                                options: [
                                    { value: '', name: param.name || 'Select device' },
                                    ...this.deviceOptions.map((device) => ({ value: device, name: `Device ${device}` }))
                                ],
                                constraints
                            };
                        } else {
                            parameter = {
                                type: param.type,
                                name: payload.key,
                                placeholder: param.description,
                                constraints
                            };
                        }
                        this.stepParameters[payload.key] = parameter;
                    }
                });
            });
        });
    }

    createVerificationFormParameters() {
        this.stepVerificationParameters.type = {
            type: 'select',
            name: 'type',
            options: [
                {
                    value: '',
                    name: 'No verification'
                },
                ...this.validators.map((validator) => ({
                    value: validator.type,
                    name: validator.name
                }))
            ]
        };
        this.stepVerificationParameters.maxTimeout = {
            type: 'number',
            name: 'maxTimeout',
            placeholder: 'Set timeout',
            constraints: {
                min: {
                    message: 'Timeout can not be less than 0',
                    value: 0
                },
                actionMax: {
                    message: 'Verification timeout can not exceed action timeout',
                }
            }
        };

        this.validators.forEach((validator) => {
            validator.parameters.forEach((param) => {
                param.properties.forEach((prop) => {
                    if (!this.stepVerificationParameters[prop.type]) {
                        if (typeof prop.values === 'object') {
                            this.stepVerificationParameters[prop.type] = {
                                type: 'select',
                                name: prop.type,
                                options: [
                                    {
                                        value: '',
                                        name: 'Select ' + prop.typeName
                                    },
                                    ...prop.values.map((value) => {
                                        return {
                                            value: value.value,
                                            name: value.valueName
                                        }
                                    })
                                ],
                                constraints: {
                                    required: { message: prop.typeName + ' is required' }
                                }
                            }
                        }
                    }
                });
            });
        });
    }

    createStepForm(category: CustomTestTemplateCategory): FormGroup<any> {
        const group: any = {
            category: [category.category, Validators.required],
            type: ['', Validators.required],
            timer: ['', Validators.min(0)],
            verifications: this.fb.array(this.deviceVerifications.map(() => {
                return this.fb.group({
                    type: [''],
                })
            })),
        };
        if (category.category === 'ACT') {
            group.sourceDevice = ['', Validators.required];
        }
        return this.fb.group(group);
    }

    addStep(category: CustomTestTemplateCategory) {
        this.stepForms.push(this.createStepForm(category));
        setTimeout(() => {
            this.stepList.nativeElement.scrollTop = this.stepList.nativeElement.clientHeight;
        }, 100);
    }

    deleteStep(index: number) {
        this.stepForms.removeAt(index);
    }

    setDeviceCount(count: number) {
        const previousCount = this.devices.length;
        this.testCaseForm.controls.deviceCount.setValue(count);
        this.devices = Array.from(Array(count)).map((_device, i) => {
            return {
                isVerifiable: this.devices[i] ? this.devices[i].isVerifiable : true,
                index: i + 1
            };
        });
        if (previousCount !== count) {
            this.updateDeviceOptions();
        }
    }

    changeDeviceVerification(e: MatSlideToggleChange, index: number) {
        this.devices[index].isVerifiable = e.checked;
        this.updateDeviceOptions();
    }

    updateDeviceOptions() {
        this.deviceOptions = this.devices.map((device) => device.index);
        this.deviceVerifications = this.devices.filter((device) => device.isVerifiable);

        this.stepParameters.sourceDevice.options = [
            { value: '', name: 'Select device' },
            ...this.deviceOptions.map((device) => ({ value: device, name: `Device ${device}` }))
        ];

        this.stepParameters.targetDevice.options = [
            { value: '', name: 'Select device' },
            ...this.deviceOptions.map((device) => ({ value: device, name: `Device ${device}` }))
        ];

        this.stepForms.controls.forEach((form) => {
            setTimeout(() => {
                if (form.controls.sourceDevice) {
                    const device = this.deviceOptions.find((d) => d == form.controls.sourceDevice.value);
                    form.controls.sourceDevice.setValue(device || '');
                }
                if (form.controls.targetDevice) {
                    const device = this.deviceOptions.find((d) => d == form.controls.targetDevice.value);
                    form.controls.targetDevice.setValue(device || '');
                }
            }, 100);
            form.setControl('verifications', this.fb.array(this.deviceVerifications.map(() => {
                return this.fb.group({
                    type: [''],
                })
            })));
        });
    }

    onTypeChange(index: number) {
        const form = this.stepForms.controls[index];
        const category = this.categories.find((category) => category.category === form.value.category);
        if (category) {
            const action = category.actions.find((action) => action.type === form.value.type);
            if (action) {
                const group: any = {
                    category: [category.category, Validators.required],
                    type: [form.value.type, Validators.required],
                    verifications: form.controls.verifications
                };

                if (category.category === 'WAIT' && form.value.type === 'EVENT') {
                    group.type = [form.value.type, [Validators.required, this.createWaitEventValidation(index)]];
                }
                action.payload.forEach((payload) => {
                    const parameter = payload.parameters.find((param) => param.initPlace === 'CREATE_TEST');
                    if (parameter) {
                        const validation = [Validators.required];
                        if (parameter.constraints) {
                            parameter.constraints.forEach((constraint) => {
                                switch (constraint.name) {
                                    case 'MAX':
                                        validation.push(Validators.max(constraint.value));
                                        break;
                                    case 'MIN':
                                        validation.push(Validators.min(constraint.value));
                                        break;
                                }
                            })
                        }
                        group[payload.key] = [form.value[payload.key] || parameter.defaultValue || '', validation];
                    }
                });
                this.stepForms.controls[index] = this.fb.group(group);
            }
        }
    }

    onVerificationChange(formIndex: number, index: number) {
        const verifications = this.stepForms.at(formIndex).controls.verifications as FormArray<FormGroup>;
        const form = verifications.at(index);
        const group = { type: [form.value.type] };
        const validator = this.validators.find((v) => v.type === form.value.type);
        if (validator) {
            validator.parameters.forEach((param) => {
                param.properties.forEach((prop) => {
                    // handle possible constraints from BE here
                    group[prop.type] = [form.value[prop.type] || '', Validators.required]
                });
            });
            group['maxTimeout'] = [form.value.maxTimeout || '', [Validators.min(0), this.createVerificationTimeoutValidator(formIndex)]]
        }
        verifications.setControl(index, this.fb.group(group));

        this.stepForms.at(formIndex).controls.type.updateValueAndValidity();
    }

    isFormValid() {
        return this.stepForms.controls.length > 0 && this.stepForms.controls.every((control) => control.valid);
    }

    openModal(content) {
        this.modalRef = this.modalService.open(content, this.modalOptions);
    }

    closeModal() {
        this.modalRef.close('cancel');
        this.saveAsName = '';
    }

    saveAs() {
        this.modalRef.close('accept');
        this.isLoading++;
        const form = this.prepareForm();

        this.customTestService.createTestTemplate({ ...form, name: this.saveAsName }).subscribe(() => {
            this.isLoading--;
            this.router.navigate(['/snap/custom-tests']);
        }, () => this.isLoading--);
    }

    createVerificationTimeoutValidator(stepIndex: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const actionValue = this.stepForms.at(stepIndex)?.value.timer;
            return actionValue && value > actionValue ? { actionMax: true } : null;
        }
    }

    createWaitEventValidation(stepIndex: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const verifications = this.stepForms.at(stepIndex)?.value.verifications;
            if (!verifications || !verifications.find((verification) => verification.type === 'CHANGE_TO')) {
                return { eventValidation: true };
            }
            return null;
        }
    }

    onInputChange(stepIndex: number) {
        const verifications = this.stepForms.at(stepIndex).controls.verifications;
        verifications['controls'].forEach((form) => {
            form.controls.maxTimeout?.updateValueAndValidity();
        });
    }

    getControlKeys(group: FormGroup, category: string): string[] {
        return Object.keys(group).filter((key) => key !== 'category' && key !== 'verifications').sort((a, b) => {
            const keyA = a === 'type' ? category + a : a;
            const keyB = b === 'type' ? category + b : b;
            return PARAMETER_ORDER[keyA] > PARAMETER_ORDER[keyB] ? 1 : -1;
        });
    }

    getTargetOptions(key: string, sourceDevice: string, options: { value: string, name: string }[]): { value: string, name: string }[] {
        if (key === 'targetDevice' && sourceDevice) {
            return options.filter((option) => option.value != sourceDevice);
        }
        return options;
    }

    onOrderUpdate() {
        // Workaround for sortablejs bug, list needs to be rerendered after sorting
        this['component'].renderList = false;
        setTimeout(() => {
            this['component'].renderList = true;
        });
    }
}