<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<div class="modal-header justify-content-center">
  <h6 class="planning">
    Verify Your Account
  </h6>
  <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
    >x</span
  >
</div>
<div class="col-12 text-center pb-4">
    <p class="welcome_ mt-4">Please enter OTP sent on your registered Email.</p>
    <p class="mt-4">
      <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4,isPasswordInput:true}"></ng-otp-input>
    </p>
    <!--  -->
  </div>
  <div class="text-center">
    <button class="btn mb-4 loginBtnSmall" [disabled]="otp.length < 4" (click)="verifyOTP()">
     VERIFY
    </button>
    <button class="btn mb-4 ml-3 loginBtnSmall" (click)="resendOTP()">
      RESEND
     </button>
  </div>
