import { Injectable } from "@angular/core";
import { NetworkService } from "../../shared/network.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
    GetCustomTestExecutionsDto,
    GetCustomTestReplaysDto,
    GetCustomTestTemplatesDto,
    GetTemplateActionCategoriesDto,
    GetTemplateActionsDto,
    GetTemplateDevicesDto,
    GetTemplateValidatorParametersDto,
    GetTemplateValidatorsDto,
    TestsApiErrorDto
} from "./dto";
import { CreateCustomTestForm, CustomTestExecution, CustomTestReplay, CustomTestTemplate, CustomTestTemplateCategory, CustomTestTemplateValidator, RunTestExecutionForm } from "./interfaces";
import { stateTooltip } from "../device-homepage/device-homepage-management-new/device-homepage-management.component-new";

@Injectable({
    providedIn: 'root'
})
export class CustomTestService {
    constructor(private networkService: NetworkService) { }

    categories: number;
    validators: number;
    templateCategories: CustomTestTemplateCategory[] = [];
    templateValidators: CustomTestTemplateValidator[] = [];

    formTemplateCategories(): Observable<CustomTestTemplateCategory[]> {
        const subject = new BehaviorSubject<CustomTestTemplateCategory[]>(this.templateCategories);

        if (this.templateCategories.length === this.categories) {
            setTimeout(() => {
                subject.complete();
            });
        } else {
            this.getTemplateActionCategories().subscribe((categories) => {
                if (categories.length) {
                    this.categories = categories.length;
                    categories.forEach((category) => {
                        this.getTemplateActions(category.category).subscribe((actions) => {
                            if (actions.length) {
                                this.templateCategories.push({
                                    ...category,
                                    actions
                                });
                                if (this.templateCategories.length === this.categories) {
                                    subject.next(this.templateCategories);
                                    setTimeout(() => {
                                        subject.complete();
                                    })
                                }
                            } else {
                                subject.error('');
                            }
                        }, (err) => { subject.error(err) });
                    });
                } else {
                    subject.error('');
                }
            }, (err) => { subject.error(err) });
        }

        return subject.asObservable();
    }

    formTemplateValidators() {
        const subject = new BehaviorSubject<CustomTestTemplateValidator[]>(this.templateValidators);

        if (this.templateValidators.length === this.validators) {
            setTimeout(() => {
                subject.complete();
            });
        } else {
            this.getTemplateValidators().subscribe((validators) => {
                if (validators.length) {
                    this.validators = validators.length;
                    validators.forEach((validator) => {
                        this.getTemplateValidatorParameters(validator.type).subscribe((parameters) => {
                            if (parameters.length) {
                                this.templateValidators.push({
                                    ...validator,
                                    parameters
                                });
                                if (this.templateValidators.length === this.validators) {
                                    subject.next(this.templateValidators);
                                    setTimeout(() => {
                                        subject.complete();
                                    });
                                }
                            } else {
                                subject.error('');
                            }
                        }, (err) => { subject.error(err) });
                    });
                } else {
                    subject.error('');
                }
            }, (err) => { subject.error(err) });
        }

        return subject.asObservable();
    }

    getTemplateDevices(): Observable<GetTemplateDevicesDto[]> {
        return this.networkService.get('tests-api/automation/templates/tests/devices', null, null, 'bearer');
    }

    getTemplateActionCategories(): Observable<GetTemplateActionCategoriesDto[]> {
        return this.networkService.get('tests-api/automation/templates/tests/actions/categories', null, null, 'bearer');
    }

    getTemplateActions(type: string): Observable<GetTemplateActionsDto[]> {
        return this.networkService.get(`tests-api/automation/templates/tests/categories/${type}/actions`, null, null, 'bearer');
    }

    getTemplateValidators(): Observable<GetTemplateValidatorsDto[]> {
        return this.networkService.get('tests-api/automation/templates/tests/validators', null, null, 'bearer');
    }

    getTemplateValidatorParameters(type: string): Observable<GetTemplateValidatorParametersDto[]> {
        return this.networkService.get(`tests-api/automation/templates/tests/validators/${type}/targets`, null, null, 'bearer');
    }

    createTestTemplate(form: CreateCustomTestForm): Observable<CustomTestTemplate> {
        return this.networkService.post(`tests-api/automation/tests`, form, null, 'bearer');
    }

    updateTestTemplate(test: CustomTestTemplate): Observable<CustomTestTemplate> {
        return this.networkService.put(`tests-api/automation/tests`, test, null, 'bearer');
    }

    getTestTemplates(query: any): Observable<GetCustomTestTemplatesDto> {
        const { page, size, name, startDate, endDate} = query;
        return this.networkService.get(`tests-api/automation/tests?identityTypes=SYSTEM&page=${page}&size=${size}&sort=createdAt,desc${startDate ? `&startAt=${startDate}` : ''}${endDate ? `&endAt=${endDate}` : ''}${name ? `&name=${name}` : ''}`, null, null, 'bearer');
    }

    getTestTemplate(id: string): Observable<CustomTestTemplate> {
        return this.networkService.get(`tests-api/automation/tests/${id}`, null, null, 'bearer');
    }

    deleteTestTemplate(id: string): Observable<CustomTestTemplate> {
        return this.networkService.delete(`tests-api/automation/tests/${id}`, null, null, 'bearer');
    }

    runTestExecution(form: any, delayed = false): Observable<CustomTestExecution> {
        return this.networkService.post(`tests-api/automation/executions/run?delayed=${delayed}`, form, null, 'bearer');
    }

    updateTestExecution(form: RunTestExecutionForm, delayed = false): Observable<CustomTestExecution> {
        return this.networkService.put(`tests-api/automation/executions/run?delayed=${delayed}`, form, null, 'bearer');
    }

    stopTestExecution(id: string): Observable<CustomTestExecution> {
        return this.networkService.post(`tests-api/automation/executions/${id}/stop`, null, null, 'bearer');
    }

    getTestExecutions(query: any): Observable<GetCustomTestExecutionsDto> {
        const { page, size, name, startDate, endDate, statuses } = query;
        return this.networkService.get(`tests-api/automation/executions?page=${page}&size=${size}&sort=createdAt,desc${startDate ? `&startAt=${startDate}` : ''}${endDate ? `&endAt=${endDate}` : ''}${name ? `&name=${name}` : ''}${statuses ? `&statuses=${statuses}` : ''}`, null, null, 'bearer');
    }

    getTestExecution(id: string): Observable<CustomTestExecution> {
        return this.networkService.get(`tests-api/automation/executions/${id}`, null, null, 'bearer');
    }

    deleteTestExecution(id: string): Observable<CustomTestExecution> {
        return this.networkService.delete(`tests-api/automation/executions/${id}`, null, null, 'bearer');
    }

    getTestReplays(id: string, page: number, size: number): Observable<GetCustomTestReplaysDto> {
        return this.networkService.get(`tests-api/automation/replays?executionId=${id}&page=${page}&size=${size}`, null, null, 'bearer');
    }

    getTestReplay(id: string): Observable<CustomTestReplay> {
        return this.networkService.get(`tests-api/automation/replays/${id}`, null, null, 'bearer');
    }

    updateArtifact(testId: string, deviceIndex: number, body: { type: string, path: string }): Observable<TestsApiErrorDto> {
        return this.networkService.put(`tests-api/automation/artifacts?executionId=${testId}&deviceIndex=${deviceIndex}`, body, null, 'bearer');
    }

    deleteArtifact(testId: string, deviceIndex: number, type: string): Observable<any> {
        return this.networkService.delete(`tests-api/automation/artifacts?executionId=${testId}&deviceIndex=${deviceIndex}&artifactTypes=${type}&cleanCloud=true`, null, null, 'bearer');
    }

    cancelArtifact(testId: string, deviceIndex: number, type: string): Observable<any> {
        return this.networkService.post(`tests-api/automation/artifacts/cancel?executionId=${testId}&deviceIndex=${deviceIndex}&type=${type}`, null, null, 'bearer');
    }

    deleteAllArtifacts(testId: string): Observable<TestsApiErrorDto> {
        return this.networkService.post(`tests-api/automation/artifacts?executionId=${testId}&cleanCloud=true`, null, null, 'bearer');
    }

    downloadAllArtifacts(testId: string): Observable<any> {
        return this.networkService.getTestsApiZip(`tests-api/automation/artifacts/download-zip?executionId=${testId}`, null, null, 'bearer');
    }

    renameArtifactFromCloud(userId: string, testName: string, artifactKey: string, newArtifactKey: string): Observable<TestsApiErrorDto> {
        return this.networkService.post(`tests-api/automation/artifacts/rename?userId=${userId}&testName=${testName}&artifactKey=${artifactKey}&newArtifactKey=${newArtifactKey}`, null, null, 'bearer');
    }

    removeArtifactFromCloud(userId: string, testName: string, artifactKey: string): Observable<TestsApiErrorDto> {
        return this.networkService.post(`tests-api/automation/artifacts/remove?userId=${userId}&testName=${testName}&artifactKey=${artifactKey}`, null, null, 'bearer');
    }

    getDeviceStatus(data: any): string {
        const state = data.deviceState;
        const stateCode = data.deviceStateCode;
        const commStatus = data.deviceCommunicationStatus;
        let displayed = 'unknown';
        if (state) {
            if (state.toLowerCase() === 'online') {
                if (stateCode.toLowerCase() === 'online') {
                    if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
                        displayed = 'testing';
                    } else {
                        displayed = 'online';
                    }
                } else {
                    if (stateTooltip[stateCode.toLowerCase()]) {
                        displayed = stateCode.toLowerCase();
                    }
                }
            } else if (state.toLowerCase() === 'offline') {
                if (stateCode && stateCode !== '') {
                    if (stateCode.toLowerCase() === 'removed') {
                        displayed = 'disconnected';
                    } else {
                        if (stateTooltip[stateCode.toLowerCase()]) {
                            displayed = stateCode.toLowerCase();
                        }
                    }
                }
            } else if (state.toLowerCase() === 'blocked') {
                displayed = state.toLowerCase();
            }
        }
        return displayed;
    }

    getFileDownloadApi(queryPram: any) {
        return this.networkService.get('api/file-storage/download?' + queryPram, null, null, 'basic');
    }
}
