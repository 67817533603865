import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { UserRoleEnum } from '../shared/enum';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private commonService: CommonService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const tokenData = this.commonService.getUser();
        if (tokenData && tokenData.role === UserRoleEnum.ADMIN) {
                return true;
        }
        this.router.navigate(['/signin']);
        return false;
    }
}
