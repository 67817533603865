import { Component, inject } from '@angular/core';
import { TIPPY_REF } from '@ngneat/helipopper';
import { TooltipData } from '../../tooltip.service';

@Component({
  selector: 'app-navigation-tooltip',
  templateUrl: './navigation-tooltip.component.html',
  styleUrls: ['./navigation-tooltip.component.scss']
})
export class NavigationTooltipComponent {
  tippy = inject(TIPPY_REF);
  tooltipData: TooltipData = {
    text: ''
  };

  constructor() {
    if (this.tippy.data && this.tippy.data.length) {
      this.tooltipData = JSON.parse(this.tippy.data);
    }
  }
}
