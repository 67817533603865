import { Injectable } from '@angular/core';
import { NetworkService } from '../../shared/network.service';
import { ApiResponseDto, DeleteResponseDto, GetEnterpriseInfoDto, GetLabListDto, GetSubscriptionListDto, SimpleApiResponse } from '../../shared/dto';
import { Observable } from 'rxjs';
import { Feature, Lab } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private networkService: NetworkService) { }

  getLocationAPI(queryParam: any) {
    return this.networkService.get('api/devices/unique/device/locations?' + queryParam, null, null, 'basic');
  }
  smartDeviceApi(queryParam: any) {
    return this.networkService.get('api/v2/devicebooking/available?showAll=true&' + queryParam, null, null, 'basic');
  }
  AlertApi(queryParam: any) {
    return this.networkService.get('monitor/api/thresholdAlert/logs?' + queryParam, null, null, 'basic');
  }
  getDashboardApi() {
    return this.networkService.get('api/dashboard/counts', null, null, 'basic');
  }
  getInvoiceTransaction(query) {
    return this.networkService.get('api/devicebooking/transaction/history?showall=true&' + query, null, null, 'basic');
  }
  getUserListApi(query: any) {
    return this.networkService.get('api/users/userList?' + query, null, null, 'basic');
  }
  getDeviceHistoryListApi(query: any) {
    return this.networkService.get('api/devicebooking/users/list?' + query, null, null, 'basic');
  }
  updateDevice(body: any) {
    return this.networkService.put('api/devices/update', body, null, 'basic');
  }
  updateUser(body: any, userId: string) {
    return this.networkService.put(`api/users/${userId}`, body, null, 'basic');
  }
  updateProfile(body: any) {
    return this.networkService.put(`api/users`, body, null, 'basic');
  }
  createLab(body: any) {
    return this.networkService.post('api/labs/create', body, null, 'basic');
  }
  forgotPassword(req: any) {
    return this.networkService.post('api/auth/forgotPassword', req, null, null);
  }
  updateLab(body: any): Observable<ApiResponseDto<Lab>> {
    return this.networkService.put('api/labs', body, null, 'basic');
  }
  getDeviceOem(query: any) {
    return this.networkService.get('api/devices/unique/oems?' + query, null, null, 'basic');
  }
  uploadImage(image: any) {
    const formData = new FormData();
    formData.append('file', image);
    return this.networkService.uploadImages('api/s3upload/media-upload', formData, null, 'basic');
  }
  releaseDevice(body: any) {
    return this.networkService.post('api/devicebooking/release/device', body, null, 'basic');
  }
  getLabDevcount(query: any) {
    return this.networkService.get('api/devices/counts?' + query, null, null, 'basic');
  }
  getLabLogs(query: any) {
    return this.networkService.get('api/devices/snapbox/logs?sendAll=true&' + query, null, null, 'basic');
  }
  getLabList(query: string): Observable<ApiResponseDto<GetLabListDto>> {
    return this.networkService.get('api/labs/list?' + query, null, null, 'basic');
  }
  getThresholdAlert(query: any) {
    return this.networkService.get('monitor/api/thresholdAlert/logs?' + query, null, null, 'basic');
  }
  getNoteList(query: any) {
    return this.networkService.get('api/labs/note/list?' + query, null, null, 'basic');
  }
  saveNote(body: any) {
    return this.networkService.post('api/labs/note/create', body, null, 'basic');
  }
  editNote(body: any) {
    return this.networkService.put('api/labs/note/update', body, null, 'basic');
  }
  getTeamList(query: any) {
    return this.networkService.get('api/labs/teamviewer/list?' + query, null, null, 'basic');
  }
  saveTeam(body: any) {
    return this.networkService.post('api/labs/teamviewer/create', body, null, 'basic');
  }
  updateTeam(body: any) {
    return this.networkService.put('api/labs/teamviewer/update', body, null, 'basic');
  }
  deleteNote(id: any) {
    return this.networkService.delete('api/labs/note/delete/' + id, null, null, 'basic');
  }
  getGraphData(query: any) {
    return this.networkService.get('monitor/api/lab/labGraph?' + query, null, null, 'basic');
  }
  PostPing(body: any) {
    return this.networkService.post('monitor/api/lab/labTest', body, null, 'basic');
  }
  getPing(query: any) {
    return this.networkService.get('monitor/api/lab/labTest?' + query, null, null, 'basic');
  }
  getEnterprise(query: any) {
    return this.networkService.get('api/enterprise?' + query, null, null, 'basic');
  }
  createEnterprise(body: any) {
    return this.networkService.post('api/enterprise', body, null, 'basic');
  }
  updateEnterprise(body: any) {
    return this.networkService.put('api/enterprise', body, null, 'basic');
  }
  PowerOff(body: any) {
    return this.networkService.localPost('api/device/controller/services/rebootDevices', body, null, 'basic');
  }
  unPlug(body: any) {
    return this.networkService.localPost('api/device/controller/services/deviceUnplugIOS', body, null, 'basic');
  }
  Plug(body: any) {
    return this.networkService.localPost('api/device/controller/services/devicePlugIOS', body, null, 'basic');
  }

  AndunPlug(body: any) {
    return this.networkService.localPost('api/device/controller/services/deviceUnplugAndroid', body, null, 'basic');
  }
  AndPlug(body: any) {
    return this.networkService.localPost('api/device/controller/services/devicePlugAndroid', body, null, 'basic');
  }
  getDeviceRequest(query: any) {
    return this.networkService.get('api/requestDevice/list?' + query, null, null, 'basic');
  }
  updateDeviceRequest(body: any) {
    return this.networkService.put('api/requestDevice?', body, null, 'basic');
  }
  resetCambrionix(serialNumber: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/v1/devices/' + serialNumber + '/reset', null, null, 'basic');
  }
  usbAttach(url: any, serialNumber: any) {
    return this.networkService.labPost(url + 'SnapBox/api/v1/devices/' + serialNumber + '/attach', null, null, 'basic');
  }
  usbDetach(url: any, serialNumber: any) {
    return this.networkService.labPost(url + 'SnapBox/api/v1/devices/' + serialNumber + '/detach', null, null, 'basic');
  }
  removedDevice(id: any) {
    return this.networkService.delete('api/devices/' + id, null, null, 'basic');
  }

  deleteLab(query: string): Observable<ApiResponseDto<DeleteResponseDto>> {
    return this.networkService.delete('api/labs?labIds=' + query, null, null, 'basic');
  }

  getUniqueSupport(query: string) {
    return this.networkService.get('api/labs/unique/support?' + query, null, null, 'basic');
  }

  getSubscriptionList(query: string): Observable<ApiResponseDto<GetSubscriptionListDto>> {
    return this.networkService.get('api/subscriptions?' + query, null, null, 'basic');
  }

  getFeatureList(): Observable<ApiResponseDto<{ features: Feature[] }>> {
    return this.networkService.get('api/features', null, null, 'basic');
  }

  createSubscription(body) {
    return this.networkService.post('api/subscriptions', body, null, 'basic');
  }

  deleteSubscription(id: string) {
    return this.networkService.delete(`api/subscriptions/${id}`, null, null, 'basic');
  }

  updateSubscription(body, id: string) {
    return this.networkService.put(`api/subscriptions/${id}`, body, null, 'basic');
  }

  createCorporateUser(body) {
    return this.networkService.post('api/users/corporate', body, null, 'basic');
  }

  createPublicUser(body) {
    return this.networkService.post('api/users/public', body, null, 'basic');
  }

  createSupportUser(body) {
    return this.networkService.post('api/users/support', body, null, 'basic');
  }

  getUserEnterprise(): Observable<ApiResponseDto<GetEnterpriseInfoDto>> {
    return this.networkService.get('api/enterprise/info', null, null, 'basic');
  }

  deleteEnterprise(enterpriseId: string): Observable<ApiResponseDto<SimpleApiResponse>> {
    return this.networkService.delete(`api/enterprise/${enterpriseId}`, null, null, 'basic');
  }

  getUniqueEnterpriseSupport(query: string) {
    return this.networkService.get('api/enterprise/unique/support?' + query, null, null, 'basic');
  }

  deleteUser(userId: string) {
    return this.networkService.delete(`api/users/${userId}`, null, null, 'basic');
  }

  getInvoice(query: any) {
    return this.networkService.get('api/invoices/' + query, null, null, 'basic');
  }
  getInvoices(query: any) {
    return this.networkService.get('api/invoices?' + query, null, null, 'basic');
  }
  createInvoice(body: any) {
    return this.networkService.post('api/invoices', body, null, 'basic');
  }
  updateInvoice(body: any) {
    return this.networkService.put('api/invoices', body, null, 'basic');
  }
}
