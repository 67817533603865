import { Component, OnInit } from '@angular/core';
import { Enterprise, TokenData, User } from '../../../../shared/interfaces';
import { UserRoleEnum, UserStatusEnum } from '../../../../shared/enum';
import { AdminService } from '../../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TooltipService } from '../../../../shared/tooltip.service';
import { DialogModalService } from '../../../../shared/dialog-modal.service';
import { CommonService } from '../../../../shared/common.service';
import { GetLabListDto, GetUserListDto } from '../../../../shared/dto';
import { FullUser } from '../../../../shared/dto/get-user-list.dto';
import { MatSnackBar } from '@angular/material/snack-bar';

type UserTabs = 'ADMIN' | 'USER' | 'ENTERPRISE';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  searchText = "";
  userData: TokenData;
  pageLimit = 10;
  totalPages = 0;
  currentPage = 0;
  userList: FullUser[] = [];
  isLoading = false;
  activeTab: UserTabs = 'ADMIN';
  totalSupport = 0;
  totalPublic = 0;
  totalCorporate = 0;
  paginationText = "0-0 of 0";
  tooltipComponent = null;
  isFiltered = false;
  isFilterOpen = false;
  isFilterOn = false;
  searchStatus = '';
  searchEnterprise = '';
  enterpriseOptions = [];
  enterpriseList: Enterprise[];
  searchLab = '';
  labOptions = [{ id: null, name: 'All Labs' }];
  unlimited = 8640000000000;
  searchRole = '';

  userRoleMap = new Map<UserRoleEnum, string>([
    [UserRoleEnum.ENTERPRISE_ADMIN, 'Enterprise Admin'],
    [UserRoleEnum.ENTERPRISE_LAB_ADMIN, 'Lab Admin'],
    [UserRoleEnum.ENTERPRISE_ELEVATED_USER, 'Elevated User'],
    [UserRoleEnum.ENTERPRISE_USER, 'User']
  ]);

  constructor(
    private adminService: AdminService,
    public router: Router,
    private tooltipService: TooltipService,
    private dialogModalService: DialogModalService,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
    this.activeTab = this.userData.role !== UserRoleEnum.ADMIN ? 'ENTERPRISE' : 'ADMIN';
    this.getRouteQuery();
    this.getUserList();

    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.getEnterprises();
    }
    this.getLabOptions();
  }

  getEnterprises() {
    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.isLoading = true;
      this.adminService.getEnterprise('').subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
          this.enterpriseList = res.data.enterpriseList;
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  getLabOptions() {
    this.isLoading = true;
    this.adminService.getLabList('').subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        const result = res.data as GetLabListDto;
        const labs = result.labList.map((lab) => ({ id: lab.labId, name: lab.name }));
        this.labOptions = [...this.labOptions, ...labs];
      }
    }, err => {
      this.isLoading = false;
    });
  }

  setPage(data: GetUserListDto) {
    this.totalSupport = data.totalAdmin;
    this.totalPublic = data.totalPublic;
    this.totalCorporate = data.totalCorporate;
    this.totalPages = data.totalPages;
    const total = data.totalCount;
    const max = (this.currentPage + 1) * this.pageLimit;
    this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
  }

  getUserList() {
    this.isLoading = true;
    const query = this.getQueryParam();
    this.adminService.getUserListApi(query).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        const data = res.data as GetUserListDto;
        this.userList = data.userList;
        this.setPage(data);
      }
    }, err => {
      this.isLoading = false;
    });
  }

  getQueryParam() {
    let roles = [this.activeTab as string];
    if (this.activeTab === 'ENTERPRISE') {
      switch (this.userData?.role) {
        case UserRoleEnum.ADMIN:
        case UserRoleEnum.ENTERPRISE_ADMIN:
          roles = [UserRoleEnum.ENTERPRISE_ADMIN, UserRoleEnum.ENTERPRISE_ELEVATED_USER, UserRoleEnum.ENTERPRISE_LAB_ADMIN, UserRoleEnum.ENTERPRISE_USER];
          break;
        case UserRoleEnum.ENTERPRISE_LAB_ADMIN:
          roles = [UserRoleEnum.ENTERPRISE_ELEVATED_USER, UserRoleEnum.ENTERPRISE_USER];
          break;
        case UserRoleEnum.ENTERPRISE_ELEVATED_USER:
        default:
          roles = [UserRoleEnum.ENTERPRISE_USER];
      }
    }
    if (this.searchRole) {
      roles = roles.filter((role) => role === this.searchRole);
    }

    let query = `size=${this.pageLimit}&page=${this.currentPage}&roles=${roles}`;
    if (this.searchText) {
      query += `&text=${encodeURIComponent(this.searchText.trim())}`;
    }
    if (this.searchStatus) {
      query += `&status=${this.searchStatus}`;
    }
    if (this.searchEnterprise) {
      query += `&enterpriseId=${this.searchEnterprise}`;
    }
    if (this.searchLab !== '') {
      query += `&labId=${this.searchLab}`;
    }

    return query;
  }

  openTab(tab: UserTabs) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      this.currentPage = 0;
      this.resetPage();
      this.getUserList();
    }
  }

  changePageLimit() {
    this.currentPage = 0;
    this.getUserList();
  }

  changePage(direction: number) {
    this.currentPage += direction;
    this.getUserList();
  }

  resetPage() {
    this.isFiltered = false;
    this.isFilterOn = false;
    this.searchText = '';
    this.searchStatus = '';
    this.searchEnterprise = '';
    this.searchLab = '';
    this.searchRole = '';
  }

  clearFilters() {
    this.resetPage();
    this.getUserList();
  }

  updateManagement(value: boolean, user: User, arg: string) {
    const request = {
      [arg]: value
    };

    this.isLoading = true;
    this.adminService.updateUser(request, user.userId).subscribe((res) => {
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.getUserList();
      }
    }, err => {
      this.isLoading = false;
    });
  }

  openConfirmDelete(userId: string) {
    this.dialogModalService.openConfirmationDialog('deleteUser', () => this.deleteUser(userId));
  }

  searchChange() {
    this.isFiltered = this.searchText ? true : false;
    this.getUserList();
  }

  openFilters() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  applyFilters() {
    this.isFilterOpen = false;
    this.isFilterOn = this.searchStatus !== '' ||
      this.searchEnterprise !== '' ||
      this.searchLab !== '' ||
      this.searchRole !== '';

    this.isFiltered = this.searchText !== '' || this.isFilterOn;
    this.getUserList();
  }

  getUserManagementPage(): string {
    let type = 'admin';
    switch (this.activeTab) {
      case 'ENTERPRISE':
        type = 'corporate';
        break;
      case 'USER':
        type = 'user';
        break;
    }
    return type;
  }

  openCreate() {
    this.router.navigate([`/admin/users/create-${this.getUserManagementPage()}`]);
  }

  openEdit(userId: string) {
    this.router.navigate([`/admin/users/update-${this.getUserManagementPage()}`], { queryParams: { id: userId } });
  }

  changeUserStatus(userId: string, status: UserStatusEnum) {
    this.isLoading = true;
    this.adminService.updateUser({ status }, userId).subscribe((res) => {
      this.isLoading = false;
      if (res.statusCode === 200) {
        this.getUserList();
      }
    }, err => {
      this.isLoading = false;
    });
  }

  deleteUser(userId: string) {
    this.isLoading = true;
    this.adminService.deleteUser(userId).subscribe((res) => {
      this.isLoading = false;
      if (res.statusCode === 200) {
        this.getUserList();
      }
    }, err => {
      this.isLoading = false;
    });
  }

  resetPassword(email: string) {
    this.isLoading = true;
    this.adminService.forgotPassword({ email }).subscribe(
      (res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.getUserList();
          this._snackBar.open('Password reset successfully!', '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['success'],
          });
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  getRouteQuery() {
    const enterpriseId = this.route.snapshot.queryParamMap.get('enterpriseId');
    if (enterpriseId) {
      this.activeTab = 'ENTERPRISE';
      this.searchEnterprise = enterpriseId;
      this.isFiltered = true;
    }
  }
  getUserStatus(user: FullUser) {
    const now = new Date().getTime() / 1000;
    if (user.status === 'inactive') return user.status;
    if(
      (user.expirationDate && user.expirationDate < now)
      || (user.subscriptions[0]?.expiration?.endAt && user.subscriptions[0].expiration.endAt < now)
    ) {
      return 'expired';
    }

    if(user.enterpriseId && this.enterpriseList) {
      const enterprise = this.enterpriseList.find((enterprise) => enterprise.id === user.enterpriseId);
      if (enterprise && enterprise.subscription?.expiration.endAt < now) {
        return 'expired';
      }
    }
    return user.status;
  }
}
