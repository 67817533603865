import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/common.service';
import { StreamService } from '../../../shared/stream.service';
import { AuthServiceLocal } from '../../auth.service';
import { UserDataService } from '../../../shared/user-data.service';
import { User } from '../../../shared/interfaces';

@Component({
  selector: 'app-opt-modal',
  templateUrl: './opt-modal.component.html',
  styleUrls: ['./opt-modal.component.scss']
})
export class OptModalComponent implements OnInit {
  @Input('userData') userData: User;
  otp="";
  otpToken=""
  isLoading:boolean = false;
  constructor(private _snackBar: MatSnackBar,
    private authServices: AuthServiceLocal,
    private commonService: CommonService,
    private streamService: StreamService,
    private router: Router,
    private userService: UserDataService) { }

  ngOnInit() {
  }
  onOtpChange(event): void {
    this.otp = event;
  }
  public inputValidator(event: any) {
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z]/g, "");
      // invalid character, prevent input

    }
  }
  verifyOTP(){
    let request= {
      otp:parseInt(this.otp),
      email:this.userData['email'],
      "type": "UR"
    }
    this.isLoading = true;
    this.authServices.verifyOTP(request).subscribe(
      (res) => {
        this.isLoading = false;
        this.otpToken = res.data.token;
        this.SignUpUser();
      },
      err => {
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
        this.isLoading = false;
      }
    );
  }
  SignUpUser(){
    let finalRequest = {
      email: this.userData["email"].trim(),
      name: this.userData["name"].trim(),
      password: this.userData["password"].trim(),
      role:'USER',
      otpToken:this.otpToken
    };
    this.isLoading = true;
    this.authServices.registerUser(finalRequest).subscribe(
      (res: HttpResponse<any>) => {
        this.isLoading = false;
        this.closeModal();
        if ((res.body["message"] == "Success")) {
          localStorage.setItem('isCard','false');
          this.commonService.setStorage("token",res.headers.get('Authorization'));
          if(res.body.data) {
            this.userService.setUserData(res.body['data']);
            this.userService.startProfilePolling();
           }
          if(res.body.data.authentication?.accessToken) {
            this.commonService.setStorage(
              "token",
              res.body.data.authentication.accessToken
            );
          }
          localStorage.setItem('activeIndex','1')
          this.router.navigate(["/snap/profile"]) 
        } else {
        }
      },
      err => {
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
        this.isLoading = false;
      }
    );
  }
  resendOTP() {
    let request= {
      email:this.userData["email"].trim(),
      "type": "UR"
    }
    this.isLoading = true;
    this.authServices.createOTP(request).subscribe(
      (res) => {
        this.isLoading = false;
        this._snackBar.open("OTP sent on your registered email", "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ["success"],
         });
      },
      err => {
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
        this.isLoading = false;
      }
    );

   
  }
  closeModal(){
    this.streamService.streamFilterMessage('Close');
  }

}
