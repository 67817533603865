import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../shared/common.service';
import { AuthServiceLocal } from '../../auth.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { StreamService } from '../../../shared/stream.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OptModalComponent } from '../opt-modal/opt-modal.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { ConfigurationService } from '../../../shared/configuration.service';
import { UserDataService } from '../../../shared/user-data.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(50%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
      ]
    )
  ],
})
export class SignupComponent implements OnInit, OnDestroy {
  resetForm:FormGroup;
  formSubmitted:boolean = false;
  isLoading:boolean = false;
  show: boolean = false;
  isLogin:boolean = true;
  emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  message: string = "";
  type:string = "0";
  stepChanged:boolean = false;
  isLower: Boolean = true;
  isUpper: boolean = true;
  isLength:boolean = true;
  isSpecial:boolean = true;
  isSecure:boolean =false;
  signupForm: FormGroup;

  activeSlider:number=1;
  subscribeSlider:any;
  constructor(  
      private fb: FormBuilder,
    private authServices: AuthServiceLocal,
    private commonService: CommonService,
    private streamService: StreamService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private modalService: NgbModal,
    private configService: ConfigurationService,
    private userService: UserDataService) { }

  ngOnInit() {
    this.ReadDomin();
    this.subscribedPage = this.streamService.getFilterSubscription().subscribe((data: string) => {
      if (data) {
        this.modalRef.close();
      }
    })
    this.initialiseForms();
    this.validateToken();
    this.subscribeSlider = setInterval(() => {
      this.navigateright();
    }, 5000);
   // this.OpenVerifyMOdal();
  }
  ngOnDestroy(): void {
    if (this.subscribeSlider) {
      clearInterval(this.subscribeSlider);
    }
  }
  ReadDomin() {
    if (window.location.hostname.toLocaleLowerCase() == 'verizon.snapautomation.com') {
       this.router.navigate(["signin"]);
    }
  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  validateToken(){
    let token = localStorage.getItem('token');
    if(token){
      this.router.navigate(["/snap/profile"])
    }else {
      localStorage.removeItem('token');
    }
  }
  initialiseForms() {
    this.signupForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  getPassword() {
    let password = this.signupForm.value.password;

    if(password.length < 1) {
      this.isUpper = true;
      this.isLower = true;
      this.isLength = true;
      this.isSpecial = true;
      return
    } 
    if(password.length > 7) {
      this.isLength = true;
    }else {
      this.isLength = false;
    }

    if (password.match("[a-z]")) {
      this.isLower = true;
    } else {
      this.isLower = false;
    }
    if (password.match("[a-z]")) {
      this.isLower = true;
    } else {
      this.isLower = false;
    }
    if (password.match("[A-Z]")) {
      this.isUpper = true;

    }
    else {
      this.isUpper = false;
    }
    if(password.match("[#$^%,!&@_\.-]")){
      this.isSpecial = true;
    }else {
      this.isSpecial = false;
    }
    
    if (this.isLower == true && this.isUpper == true && this.isLength == true && this.isSpecial == true) {
      this.isSecure = true;

    } else {
      this.isSecure = false;
    }

  }
  finalRequest:any;
  login() {
        this.formSubmitted = true;
    if(this.signupForm.valid){

      if(this.isTab1Selected == true){
        this.finalRequest = {
          email: this.signupForm.controls["email"].value.trim(),
          name: this.signupForm.controls["name"].value.trim(),
          password: this.signupForm.controls["password"].value.trim(),
          role:'USER'
        };
      }
      if(this.isTab1Selected == false){
        this.finalRequest = {
          email: this.signupForm.controls["email"].value.trim(),
          companyName: this.signupForm.controls["name"].value.trim(),
          password: this.signupForm.controls["password"].value.trim(),
          role:'teamLeader',
        };

      }
      this.isLoading = true;
      this.authServices.registerUser(this.finalRequest).subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          if ((res.body["message"] == "Success")) {
            localStorage.setItem('isCard','false');
            this.commonService.setStorage("token",res.headers.get('Authorization'));
            if(res.body.data) {
              this.userService.setUserData(res.body['data']);
              this.userService.startProfilePolling();
             }
            if(res.body.data.authentication?.accessToken) {
              this.commonService.setStorage(
                "token",
                res.body.data.authentication.accessToken
              );
            }
            localStorage.setItem('activeIndex','1')
            this.router.navigate(["/snap/homepage"]) 
          } else {
          }
        },
        err => {
         this._snackBar.open(err.error.data.message, "", {
            duration: 3000,
              horizontalPosition: "right",
              verticalPosition: "top",
             panelClass: ["danger"],
           });
          this.isLoading = false;
        }
      );
    }
  }
  
  closeResult: string;
  subscribedPage: any;
  private modalRef: NgbModalRef;
  OpenVerifyMOdal() {
    let request= {
      email:this.signupForm.controls["email"].value.trim(),
      "type": "UR"
    }
    this.isLoading = true;
    this.authServices.createOTP(request).subscribe(
      (res: HttpResponse<any>) => {
        this.isLoading = false;
        this.modalRef = this.modalService.open(OptModalComponent, { size: 'md', centered: true});
        this.modalRef.componentInstance.userData = this.signupForm.value;
        // modalRef.componentInstance.reviews = this.reviews;
        this.modalRef.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      },
      err => {
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
        this.isLoading = false;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

    password() {
      this.show = !this.show;
    }
    naviageSignIn(value){
      if(value == 'IN'){
        this.router.navigate(["/signin"]) 
      }
      if(value == 'UP'){
        this.router.navigate(["/signup"]) 
      }
    }
    colorFilled:boolean = true;
    isStepChange() {
      if(this.isTab2Selected == true){
        window.open("http://snapautomation.com/contact/",'_self')
        return
      }
      if(this.stepChanged == false) {
        this.stepChanged = true;
        this.colorFilled = true
      } else {
        this.stepChanged = false
      }
    }
  
    // Tabs select Function
    isTab1Selected:boolean = true;
    isTab2Selected:boolean = false;
    tabSelectFn(val) {
      if(val == '1') {
        this.isTab1Selected = true;
        this.isTab2Selected = false;
      } if(val == '2') {
        this.isTab1Selected = false;
        this.isTab2Selected = true;
        // window.open("http://snapautomation.com/contact/",'_self')
      }
      this.signupForm.controls.firstName.setValue('');
      this.signupForm.controls.lastName.setValue('');
    }

  generatePassword(): void {
      const length = 8;
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let retVal = '';
      for (let i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      retVal = 'Sn_' + retVal + '12';
      this.signupForm.controls['password'].setValue(retVal);
      this.getPassword();
  }

   // social login 
   SociluserData:any;
   tryGoogleLogin() {
    if (this.SociluserData) {
      this.fetchGmaildata(this.SociluserData)
      return
    }
    this.authServices.doGoogleLogin()
      .then(res => {
        this.SociluserData = res.additionalUserInfo.profile;
        this.fetchGmaildata(res.additionalUserInfo.profile)
        // this.router.navigate(['/user']);
      })
  }
  fetchGmaildata(user: any) {
    let socialReq = {
      "name":user.name,
      "email": user.email,
      "authType": 'google',
      "googleId": user.id,
      role:'USER',
      "logo":user.picture
    };
    this.isLoading = true;
    this.authServices.socialLogin(socialReq).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["message"] == "Success") {
          localStorage.setItem('isCard','false');
          if(res['data']['user']) {
            this.userService.setUserData(res['data']['user']);
            this.userService.stopProfilePolling();
           }
          if(res.data.user.authentication?.accessToken) {
            this.commonService.setStorage(
              "token",
              res.data.user.authentication.accessToken
            );
          }
          this.router.navigate(["/snap/homepage"]) 
        } else {
        }
      },
      err => {
       this._snackBar.open(err.error.data.message, "", {
          duration: 3000,
            horizontalPosition: "right",
            verticalPosition: "top",
           panelClass: ['danger']
         });
        this.isLoading = false;
      }
    );
  }

  navigateright(){
    if(this.activeSlider == 1){
      this.activeSlider =2;
      return
    }
    if(this.activeSlider == 2){
      this.activeSlider =3;
      return
    }
    if(this.activeSlider == 3){
      this.activeSlider =1;
      return
    }

  }
  openwebView() {
    let value = this.configService.readConfig().backendApiURL +'extapi-docs' 
    let index =value.indexOf('http');
    let url  = '';
    if (index == -1) {
         url = 'http://' + value ;
    }
    else 
    {
        url = value ;
    }
    window.open(url, "_blank");
  }
  }






