import { Injectable } from '@angular/core';
import { NetworkService } from '../../shared/network.service';

@Injectable({
  providedIn: 'root'
})
export class DevicehomeService {

  constructor(private networkService: NetworkService) { }


  getDeviceApi(queryParam) {
    return this.networkService.get('api/devicebooking/booked?' + queryParam, null, null, 'basic');
  }

  getCardApi() {
    return this.networkService.get('api/cards', null, null, 'basic');
  }

  ExtendReservationApi(body) {
    return this.networkService.post('api/v2/devicebooking/extend', body, null, 'basic');
  }

  ExtendReservationApiV3(body) {
    return this.networkService.post('api/v3/devicebooking/extend', body, null, 'basic');
  }

  releaseDevice(body: any) {
    return this.networkService.post('api/devicebooking/release/device', body, null, 'basic');
  }

  getLocationAPI(queryParam: any) {
    return this.networkService.get('api/devices/unique/device/locations?' + queryParam, null, null, 'basic');
  }

  getLabsAll(query: any) {
    return this.networkService.get('api/labs/list' + query, null, null, 'basic');
  }

  availableDevice(queryParam: any) {
    return this.networkService.get('api/v2/devicebooking/available?' + queryParam, null, null, 'basic');
  }

  bookedDeviceAPi(body: any) {
    return this.networkService.post('api/v2/devicebooking/book', body, null, 'basic');
  }

  createReservationApiV3(body: any) {
    return this.networkService.post('api/v3/devicebooking/book', body, null, 'basic');
  }

  requestNewDevice(body: any) {
    return this.networkService.post('api/requestDevice', body, null, 'basic');
  }

  checkCambrionixservice(domain: any) {
    return this.networkService.labGet(domain + 'SnapBox/api/v1/config', null, null, 'basic');
  }

  resetCambrionix(serialNumber: any, url: any) {
    return this.networkService.labPost(url + '/SnapBox/api/v1/devices/' + serialNumber + '/reset' , null, null, 'basic');
  }

  bookDeviceForMoMt(deviceIds: { deviceId: string }[], cardId: string, isCardAdded: boolean) {
    const request = {
      deviceList: deviceIds,
      'paymentMethodId': cardId,
      'isCardAdded': isCardAdded,
      'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return this.networkService.post('api/v3/devicebooking/book/momt', request, null, 'basic');
  }

  bookDeviceForTesting(deviceIds: { deviceId: string }[], cardId: string, isCardAdded: boolean, type: string) {
    const request = {
      deviceList: deviceIds,
      'paymentMethodId': cardId,
      'isCardAdded': isCardAdded,
      'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return this.networkService.post(`api/v3/devicebooking/book/${type}`, request, null, 'basic');
  }

  getDeviceDynamicFields(sn: string) {
    return this.networkService.get(`api/devices/${sn}/dynamic-fields`, null, null, 'basic');
  }

  availableDeviceMomt() {
    return this.networkService.get('api/v3/devicebooking/available/momt', null, null, 'basic');
  }
}
