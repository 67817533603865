<app-spinner *ngIf="isLoading"></app-spinner>
<span class="button_back">
    <div class="page_name" routerLink="/snap/record-and-play">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        {{testInfo?.name || 'Back'}}
    </div>
</span>
<div class="no-access" *ngIf="!isRnpAvailable">
    <p>
        Record and Play is not available with your current subscription plan. <br />
        To get access to Record and Play, you must upgrade your account to a plan that includes Telephony tests feature.
    </p>
</div>
<main class="main_test_container" *ngIf="isRnpAvailable && !isLoading">
    <article class="left_test_container">
        <h3 class="test_title">{{testInfo.recordDetails.name}}</h3>
        <section class="description_container" *ngIf="testInfo?.recordDetails?.description">
            {{testInfo?.recordDetails?.description}}
        </section>
        <section class="devices_time">
            <div class="devices">
                <div class="model_devices_container">
                    <span class="model_device">
                        <span class="name">Device:</span>
                        <span>{{testInfo?.modelName}}</span>
                        <span class="model_device_grey" [tp]="tooltipComponent" tpPlacement="top"
                              [tpOnlyTextOverflow]="true" [tpData]="testInfo?.serialNumber | tooltipData"
                              [tpMaxWidth]="450" [tpInteractive]="false">
                            {{testInfo?.serialNumber}}
                        </span>
                    </span>
                </div>
                <span class="model_device">
                    <span class="name">Executed at:</span>
                    {{testInfo?.insertDate * 1000 | date:'MM/dd/yy'}}
                    <span class="model_device model_device_grey">
                        {{testInfo?.executedAt * 1000 | date: 'mediumTime'}}
                    </span>
                    <span class="name">Delay between iterations:</span>
                    {{testInfo?.delay + ' sec'}}
                </span>
            </div>
        </section>
        <section class="result_container">
            <div class="result_item_bold result_item">{{testInfo?.status}}</div>
            <div class="result_item_bold result_item">{{testInfo?.repeat}}</div>
            <div class="result_item_bold result_item">{{testResults()}}</div>
            <div class="result_item_bold result_item">{{calcPassRate(testInfo)}}</div>
            <div class="result_item">Status</div>
            <div class="result_item">Iterations</div>
            <div class="result_item">Test results</div>
            <div class="result_item">Pass rate</div>
        </section>
        <div class="artifacts_title">
            <h3 class="test_title">Test results</h3>
            <button class="btn search_btn_advance clear_btn" (click)="downloadAllArtifacts()" *ngIf="checkDownloadAll()">
                Download All
            </button>
        </div>
        <div class="no-artifacts" *ngIf="!testInfo?.isLogsCollecting && !testInfo?.isVideoCollecting && !testInfo?.isUserActionCollecting && !testInfo?.isKPIsCollecting">
            <img src="./assets/images/empty_folder.svg" alt="" />
            <span>No artifacts are available for this test result.</span>
        </div>
        <div *ngIf="testInfo?.isLogsCollecting || testInfo?.isVideoCollecting || testInfo?.isUserActionCollecting || testInfo?.isKPIsCollecting">
            <div class="artifacts_container" *ngIf="testInfo?.isLogsCollecting && !testInfo.artifacts.log.isDeleted">
                <div class="artifact_first">
                    <img class="artifacts_icon" src="../../../../assets/images/logs.svg" alt="">
                </div>
                <div class="artifact">Device logs</div>
                <div class="artifact">
                    <span [tp]="tooltipComponent" tpPlacement="top" [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                          *ngIf="testInfo.artifacts.log.link">
                        <img class="artifacts_icon clickable_icon" src="../../../../assets/images/download-new.svg"
                             alt="" [ngClass]="{disabled: !testInfo.artifacts.log.link}" (click)="downloadArtifact('logs')" />
                    </span>
                    <span class="regular right_icon" [tp]="tooltipComponent"
                          tpPlacement="top" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img alt="" src="../../../../assets/images/trash-can.svg"
                             class="artifacts_icon trash_icon clickable_icon"
                             [ngClass]="{disabled: !isCloudAvailable || !testInfo.artifacts.log.link }"
                             (click)="openConfirmDeleteArtifact('logs')" />
                    </span>
                </div>
            </div>
            <div class="artifacts_container" *ngIf="testInfo?.isUserActionCollecting && !testInfo.artifacts.userAction.isDeleted">
                <div class="artifact_first">
                    <img class="artifacts_icon" src="../../../../assets/images/logs.svg" alt="">
                </div>
                <div class="artifact">User actions</div>
                <div class="artifact">
                    <span [tp]="tooltipComponent" tpPlacement="top" [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                          *ngIf="testInfo.artifacts.userAction.link">
                        <img class="artifacts_icon clickable_icon" src="../../../../assets/images/download-new.svg"
                             alt="" [ngClass]="{disabled: !testInfo.artifacts.userAction.link}" (click)="downloadArtifact('userAction')" />
                    </span>
                    <span class="regular right_icon" [tp]="tooltipComponent"
                          tpPlacement="top" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img alt="" src="../../../../assets/images/trash-can.svg"
                             class="artifacts_icon trash_icon clickable_icon"
                             [ngClass]="{disabled: !isCloudAvailable || !testInfo.artifacts.userAction.link }"
                             (click)="openConfirmDeleteArtifact('userAction')" />
                    </span>
                </div>
            </div>
            <div class="artifacts_container" *ngIf="testInfo?.isVideoCollecting && !testInfo.artifacts.video.isDeleted">
                <div class="artifact_first">
                    <img class="artifacts_icon" src="../../../../assets/images/video-footage.svg" alt="">
                </div>
                <div class="artifact">Video</div>
                <div class="artifact">
                    <span [tp]="tooltipComponent" tpPlacement="top" [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                          *ngIf="testInfo.artifacts.video.link">
                        <img class="artifacts_icon clickable_icon" src="../../../../assets/images/download-new.svg"
                             alt="" [ngClass]="{disabled: !testInfo.artifacts.video.link}" (click)="downloadArtifact('video')" />
                    </span>
                    <span class="regular right_icon" [tp]="tooltipComponent"
                          tpPlacement="top" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img alt="" src="../../../../assets/images/trash-can.svg"
                             class="artifacts_icon trash_icon clickable_icon"
                             [ngClass]="{disabled: !isCloudAvailable || !testInfo.artifacts.video.link }"
                             (click)="openConfirmDeleteArtifact('video')" />
                    </span>
                </div>
            </div>
            <div class="artifacts_container" *ngIf="testInfo?.isKPIsCollecting && !testInfo.artifacts.kpis.isDeleted">
                <div class="artifact_first">
                    <img class="artifacts_icon" src="../../../../assets/images/logs.svg" alt="">
                </div>
                <div class="artifact">Device KPIs</div>
                <div class="artifact">
                    <span [tp]="tooltipComponent" tpPlacement="top" [tpData]="(isCloudAvailable ? 'Download artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData"
                          *ngIf="testInfo.artifacts.kpis.link">
                        <img class="artifacts_icon clickable_icon" src="../../../../assets/images/download-new.svg"
                             alt="" [ngClass]="{disabled: !testInfo.artifacts.kpis.link}" (click)="downloadArtifact('kpis')" />
                    </span>
                    <span class="regular right_icon" [tp]="tooltipComponent"
                          tpPlacement="top" [tpData]="(isCloudAvailable ? 'Delete artifact' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img alt="" src="../../../../assets/images/trash-can.svg"
                             class="artifacts_icon trash_icon clickable_icon"
                             [ngClass]="{disabled: !isCloudAvailable || !testInfo.artifacts.kpis.link }"
                             (click)="openConfirmDeleteArtifact('kpis')" />
                    </span>
                </div>
            </div>
        </div>
    </article>
    <article class="right_test_container">
        <h3 class="test_title">Iterations</h3>
        <mat-accordion multi>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let iteration of testInfo?.iterations; index as i">
                <mat-expansion-panel-header>
                    <span class="iteration-num">Iteration {{iteration.index + 1}}</span>
                    <span class="iteration-success-label" *ngIf="iteration.status === 'Pass'">PASSED</span>
                    <span class="iteration-failed-label" *ngIf="iteration.status === 'Failed'">FAILED</span>
                </mat-expansion-panel-header>
                <table class="iterations-table">
                    <tbody>
                    <tr>
                        <td class="action-icon">
                            <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName('start', 'Pass')}}.svg"
                                 alt="">
                        </td>
                        <td>00:00:000</td>
                        <td>START</td>
                    </tr>
                    <tr *ngFor="let action of iteration.actions; index as y">
                        <td class="action-icon">
                            <img src="../../../../assets/images/record-and-play-actions/{{getActionIconName(action.type, action.status)}}.svg" alt="">
                        </td>
                        <td class="timestamp-col">{{calcHoursToDisplay(action.timestamp)}}{{action.timestamp | date:'mm:ss:SSS'}}</td>
                        <td class="type-col">{{getActionName(action.type)}}</td>
                        <td>{{displayDetails(action)}}</td>
                    </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </article>
</main>
