import { Component, Input, OnInit } from '@angular/core';
import {
    CustomTestReplay,
    CustomTestReplayStatusEnum,
    CustomTestReplayStatusMap,
    CustomTestDevice,
    CustomTestStepAction,
    CustomTestFormStep,
    CustomTestValidatorTypeMap,
    CustomTestExecution,
    CustomTelephonyActionType,
    CustomActionTypeMap,
    CustomActionIconMap
} from '../../interfaces';

@Component({
    selector: 'app-iteration-accordion',
    templateUrl: './iteration-accordion.component.html',
    styleUrls: ['./iteration-accordion.component.scss']
})

export class IterationAccordionComponent implements OnInit {
    @Input() iterations: CustomTestReplay[];
    @Input() devices: CustomTestDevice[];
    @Input() isExpanded: boolean;
    @Input() totalIterations: number;
    @Input() testInfo: CustomTestExecution;

    expandedRows = new Set();
    verificationsAmount = 1;

    ngOnInit(): void {
        this.verificationsAmount = this.devices.filter((device) => !device.noVerifications).length;
    }

    displayActionType(type: CustomTelephonyActionType): string {
        return CustomActionTypeMap.get(type);
    }

    getActionTime(step: CustomTestFormStep, iteration: CustomTestReplay): number {
        if (step.endedAt) {
            return step.endedAt - iteration.startedAt;
        }
        return 0;
    }

    getCleanupTime(iteration: CustomTestReplay): number {
        if (iteration.endedAt) {
            return iteration.endedAt - iteration.startedAt;
        }
        return 0;
    }

    displayIterationStatus(status: CustomTestReplayStatusEnum): string {
        return CustomTestReplayStatusMap.get(status);
    }

    handleRowSelection(index: number) {
        if (this.expandedRows.has(index)) {
            this.expandedRows.delete(index);
        } else {
            this.expandedRows.add(index);
        }
    }

    getActionIconName(type: CustomTelephonyActionType): string {
        return CustomActionIconMap.get(type);
    }

    getActionSource(action: CustomTestStepAction): string {
        const sourcePayload = action.data.payload.find((p) => p.key === 'sourceDevice');
        const sourceParameter = sourcePayload?.parameters.find((param) => param.key === 'index');
        return `${sourceParameter?.value}`;
    }

    getActionTarget(action: CustomTestStepAction): string {
        const sourcePayload = action.data.payload.find((p) => p.key === 'targetDevice');
        const sourceParameter = sourcePayload?.parameters.find((param) => param.key === 'index');
        return `${sourceParameter?.value || ''}`;
    }

    getActionTimer(action: CustomTestStepAction): string {
        const sourcePayload = action.data.payload.find((p) => p.key === 'timer');
        const sourceParameter = sourcePayload?.parameters.find((param) => param.key === 'maxTimeout');
        return sourceParameter?.value ? `${Math.round(Number(sourceParameter.value) / 100)  / 10 }` : '';
    }

    getStepValidatorStatus(step: CustomTestFormStep, deviceIndex: number): string {
        if(step.validators) {
            const validator = step.validators.find((validator) => {
                const parameter = validator.target.parameters.find((param) => param.key === 'index');
                return validator.target.type === 'DEVICE' && parameter.value === deviceIndex;
            });
            return validator?.status;
        }
        return '';
    }

    displayValidatorType(step: CustomTestFormStep, deviceIndex: number): string {
        if(step.validators) {
            const validator = step.validators.find((validator) => {
                const parameter = validator.target.parameters.find((param) => param.key === 'index');
                return validator.target.type === 'DEVICE' && parameter.value === deviceIndex;
            });
            return validator ? CustomTestValidatorTypeMap.get(validator.category) : 'No verification';
        }
        return 'No verification'
    }

    displayValidatorState(step: CustomTestFormStep, deviceIndex: number): string {
        if(step.validators) {
            const validator = step.validators.find((validator) => {
                const parameter = validator.target.parameters.find((param) => param.key === 'index');
                return validator.target.type === 'DEVICE' && parameter.value === deviceIndex;
            });
            return validator ? validator.property.valueName : '';
        }
        return ''
    }

    displayValidatorTime(step: CustomTestFormStep, deviceIndex: number): string {
        if(step.validators) {
            const validator = step.validators.find((validator) => {
                const parameter = validator.target.parameters.find((param) => param.key === 'index');
                return validator.target.type === 'DEVICE' && parameter.value === deviceIndex;
            });
            if (validator?.maxTimeout) {
                return `${Math.round(validator.maxTimeout / 100) / 10 }s`
            } else {
                const actionTimer = step.action.data.payload.find((payload) => payload.key === 'timer');
                const defaultTimeout = actionTimer.parameters?.find((param) => param.key);
                return defaultTimeout ? `${Math.round(Number(defaultTimeout.value) / 100) / 10 }s` : '';
            }
        }
        return '';
    }

    isDelayActive(iteration: CustomTestReplay): boolean {
        const nextIteration = this.iterations.find((i) => i.index === iteration.index + 1);
        return !nextIteration
            || (nextIteration.status === CustomTestReplayStatusEnum.IDLE
                && [CustomTestReplayStatusEnum.FAILED, CustomTestReplayStatusEnum.PASSED, CustomTestReplayStatusEnum.COMPLETED].includes(iteration.status));
    }

    getDelayTime(iteration: CustomTestReplay): number {
        if (iteration.endedAt && !this.isDelayActive(iteration)) {
            return this.testInfo.replayDelay + iteration.endedAt - iteration.startedAt;
        }
        return 0;
    }
}

