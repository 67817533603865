<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>Update subscription</h1>
<div class="not-found" *ngIf="showError">
    Subscription not found
</div>
<form class="subscription-form" [formGroup]="subscriptionForm" (ngSubmit)="updateSubscription()" *ngIf="!showError">
    <section class="left-part">
        <div class="group">
            <label for="type">Select subscription type <span>*</span></label>
            <select class="form-control" id="type" formControlName="type" required>
                <option value="">Select subscription type</option>
                <option value="COMMON">Common</option>
                <option value="PUBLIC">Public</option>
                <option value="ENTERPRISE">Corporate</option>
            </select>
            <div *ngIf="subscriptionForm.controls.type.invalid && (subscriptionForm.controls.type.dirty || subscriptionForm.controls.type.touched)"
                class="error">
                <div *ngIf="subscriptionForm.controls.type.errors.required">*Type is required</div>
            </div>
        </div>
        <div class="group">
            <label for="name">Subscription Name <span>*</span></label>
            <input type="text" formControlName="name" maxlength="50" id="name" class="form-control"
                placeholder="Enter Subscription Name" />
            <div *ngIf="subscriptionForm.controls.name.invalid && (subscriptionForm.controls.name.dirty || subscriptionForm.controls.name.touched)"
                class="error">
                <div *ngIf="subscriptionForm.controls.name.errors.required">*Name is required</div>
            </div>
            <div *ngIf="isNameExists" class="error">*Subscription already exists</div>
        </div>
        <div class="group">
            <label for="description">Description</label>
            <input type="text" formControlName="description" id="description" class="form-control"
                placeholder="Enter Description" />
        </div>
        <div class="group">
            <label for="features">Select features <span>*</span></label>
            <ng-multiselect-dropdown [placeholder]="'Select features'" [settings]="getDropDownSettings(1)"
                [data]="featureList" id="features" formControlName="features" class="multiselect">
            </ng-multiselect-dropdown>
            <div *ngIf="subscriptionForm.controls.features.invalid && (subscriptionForm.controls.features.dirty || subscriptionForm.controls.features.touched)"
                class="error">
                <div *ngIf="subscriptionForm.controls.features.errors.required">*At least one feature required.</div>
            </div>
        </div>
        <div class="group">
            <label for="status">Select status <span>*</span></label>
            <select class="form-control" id="status" formControlName="status" required>
                <option value="">Select status</option>
                <option value="ACTIVE">Active</option>
                <option value="DEPRECATED">Deprecated</option>
                <option value="DISABLED">Disabled</option>
            </select>
            <div *ngIf="subscriptionForm.controls.status.invalid && (subscriptionForm.controls.status.dirty || subscriptionForm.controls.status.touched)"
                class="error">
                <div *ngIf="subscriptionForm.controls.status.errors.required">*Status is required</div>
            </div>
        </div>
        <div class="group">
            <label for="comments">Comments</label>
            <input type="text" formControlName="comments" id="comments" class="form-control"
                placeholder="Enter Comments" />
        </div>
    </section>
    <section class="right-part">
        <div class="group">
            <label for="status">Subscription image</label>
            <div class="dropzone">
                <app-form-dropzone [maxSize]="3145728" [accept]="'image/jpeg,image/png'"
                    (fileSelected)="handleFileUpload($event)"
                    [description]="'JPG, PNG up to 3 MB. Recommended resolution 380x64 (px)'"
                    [preview]="subscriptionForm.value.icon"></app-form-dropzone>
            </div>
        </div>
        <div class="form-actions">
            <button class="snap-button secondary" type="button" routerLink="/admin/subscriptions">Cancel</button>
            <button class="snap-button primary" type="submit" [disabled]="!subscriptionForm.valid">Update</button>
        </div>
    </section>
</form>