import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
  // {
  //   path: "auth",
  //   component: AuthComponent,
  //   children: [
  //     { path: "", redirectTo: "/auth/user/", pathMatch: "full" },
  //     { path: "auth/user", loadChildren:"./login/login.module#LoginModule"},
  //   ]
  // }
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
