export enum RecordAndPlayTypeEnum {
    CASE = 'TEST_CASE',
    RESULT = 'TEST_RESULT',
}

export enum actionIcon {
    start = 'start',
    click = 'click',
    swipe = 'swipe',
    rotate = 'change_rotation',
    back = 'back',
    recent = 'recent_app',
    home = 'home',
    adbInput = 'input_text',
    adbValue = 'press_key',
    reboot = 'reboot',
    wifi = 'wifi',
    bluetooth = 'bluetooth',
    airplane = 'airplane',
    volume = 'volume',
    pinch = 'pinch',
    doubleTap = 'doubleTap',
    tripleTap = 'tripleTap',
    dragNDrop = 'dragNDrop'
}

export enum ReplayStatus {
    SCHEDULED = 'Scheduled',
    REQUESTED = 'Requested',
    RUNNING = 'Running',
    COMPLETED = 'Completed',
    FAILED = 'Failed',
    STOPPED = 'Stopped',
    FINALIZING = 'Finalizing'
}

export enum IterationStatus {
    NOT_EXECUTED = 'NotExecuted',
    PASS = 'Pass',
    FAILED = 'Failed',
    IN_PROGRESS = 'InProgress'
}

export enum ActionStatus {
    NOT_EXECUTED = 'NotExecuted',
    PASS = 'Pass',
    FAILED = 'Failed',
    IN_PROGRESS = 'InProgress'
}

export interface PlaySettings {
    logs: boolean;
    video: boolean;
    iterations: number;
    delay: number;
}
