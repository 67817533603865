<div class="content_out">
    <app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
    <div class="filters mt-3">
      <div class="row">
        <div class="position-relative col-md-5 mt-3 mb-3">
          <input
            [(ngModel)]="searchEmei"
            type="text"
            maxlength="50"
            (input)="textChange()"
            class="form-control login_input input_admin_color position-relative w-100"
            placeholder="Txn Id"
            (keydown.enter)= "getInvoceList();"
          />
        </div>
        <div class="col-md-1 mt-3">
          <button
          class="btn search_btn_advance search_btn no_search_btn mr-2"
          (click)="getInvoceList()"
        >
          Search
        </button>
      </div>
      <div class="col-md-2 mt-3">
          <button class="btn search_btn_advance clear_btn" (click)="clearFilter()">
              Clear Filters
            </button>
      </div>
      </div> 
      <!-- <div class="mt-2 ml-3">
          <button class="btn clear_btn form-control" (click)="clearFilter()" >Clear Filters</button>
        </div> -->
    </div>
  
    <!-- DEVICES LIST -->
  
    <!-- GREY TABLE -->
    <div class="table_content">
      <div class="table_Content_outer">
        <div class="row align-items-center"></div>
        <div class="card-body mt-2 p-0">
          <div class="table-responsive inner_table_responsive device_table ">
            <!-- <app-no-file *ngIf="invoiceList.length == 0"></app-no-file> -->
            <table class="table smartTable">
              <thead class="tableheading inner_table_head">
                <tr>
                  <!-- <th scope="col">Category</th> -->
                  <th scope="col" class="_noWrap">TXN DATE</th>
                  <th scope="col" class="_noWrap">TXN ID</th>
                  <th scope="col" class="_noWrap">TOTAL AMOUNT($)</th>
                  <th scope="col" class="_noWrap">TOTAL Device</th>
                  <th scope="col" class="_noWrap">STATUS</th> 
                </tr>
              </thead>
              <tbody>
                <tr
                  class="position-relative"
                  *ngFor="let item of invoiceList; index as i"
                >
                <td  class="_noWrap "> {{item?.estBookingDate}}</td>
               
                  <td class="_noWrap">{{ item.stripeChargeId?item.stripeChargeId:'--' }}</td>
                  <td class="_noWrap">{{ item.totalCartAmount?item.totalCartAmount:'--' }}</td>
                  <td  class="primaryColr" ><span class="primaryColr" style="cursor: pointer;" #BTN1
                    (click)="moreDetailModal(txnModal, BTN1,item)">{{item['devices']['length']}}</span></td>
                  <td class="_noWrap" style="text-transform: capitalize;">
                    <span class="green" [ngClass]="{ red: item?.status == 'failure'}" *ngIf="item?.status !== 'released'">{{ item.status }}</span>
                    <span class="green" *ngIf="item?.status == 'released'">success</span>
                  </td>
             
                </tr>
              
              </tbody>
            </table>
            <p class="no_device" *ngIf="invoiceList.length == 0">
             No Data Available
            </p>
          </div>
          <div  class="row p-0 m-0 mt-2 mb-3 align-items-center"  *ngIf="invoiceList.length > 0" >
            <div  class="pagLimitSet ml-4">
              <select class="pagLimitSet_selecet" (change)="changePageLimit()"  [(ngModel)]="pagelimit">
                <option  value="5">5</option>
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="50">50</option>
                <option  value="100">100</option>
              </select>
             
            </div>
            <div class="col" style="margin-right:10px">
              <div class="pagination_custom" *ngIf="invoiceList.length > 0">
                <ul class="d-flex flex-row">
                  <li (click)="prevPage()" [ngClass]="{'disabled_pagination':currentPage  == 0}" > < </li>
                  <li class="show_number"> {{currentPage + 1}} / {{totalPage }} </li>
                  <li (click)="nextPage()" [ngClass]="{'disabled_pagination':currentPage + 1  == totalPage }" > > </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
        <!-- <div  class="row p-0 m-0 mt-2 mb-3 align-items-center"  *ngIf="invoiceList.length > 0" >
                <div  class="pagLimitSet ml-4">
                  <select class="pagLimitSet_selecet" (change)="changePageLimit()"  [(ngModel)]="pagelimit">
                    <option  value="5">5</option>
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="50">50</option>
                    <option  value="100">100</option>
                  </select>
                 
                </div>
                <div class="col" style="margin-right:10px">
                  <div class="pagination_custom" *ngIf="invoiceList.length > 0">
                    <ul class="d-flex flex-row">
                      <li (click)="prevPage()" [ngClass]="{'disabled_pagination':currentPage  == 0}" > < </li>
                      <li class="show_number"> {{currentPage + 1}} / {{totalPage }} </li>
                      <li (click)="nextPage()" [ngClass]="{'disabled_pagination':currentPage + 1  == totalPage }" > > </li>
                  </ul>
                </div>
                </div>
              </div> -->
      </div>
    </div>
    <!-- GREY END TABLE -->
  </div>
  
  <ng-template #deletemodal let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
      <h5 class="modal-title">Cancel booking</h5>
  
      <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
      </button> -->
      <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
        >x</span
      >
    </div>
  
    <div class="modal-body">
      <div class="row justify-content-center">
        Are you sure you want to cancel this payment?
      </div>
      <div class="row justify-content-end m-3">
        <button class="btn cancel_btn mr-3" (click)="canelBooking()" >Cancel</button>
        <button class="btn add_card" (click)="closeModal()" >Back</button>
      </div>
     
    </div>
  </ng-template>
  <ng-template #txnModal let-modal>
    <app-spinner *ngIf="isLoading1" [type]="'normal'"></app-spinner>
    <div class="modal-header">
      <h5 class="modal-title">Booking History</h5>
  
      <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
      </button> -->
      <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
        >x</span
      >
    </div>
  
    <div class="modal-body p-0" style="height: 400px;overflow: auto;">
      <div class="card- p-0 mt-2">
        <div class="table-responsive inner_table_responsive device_table ">
          <!-- <app-no-file *ngIf="managerList.length == 0"></app-no-file> -->
          <table class="table smartTable">
            <thead class="tableheading inner_table_head">
              <tr>
                <!-- <th scope="col">Category</th> -->
                <th scope="col " class="_noWrap">TXN DATE</th>
                <th scope="col" class="_noWrap">TXN ID</th>
                <th scope="col " class="_noWrap">CHECK IN</th>
                <th scope="col " class="_noWrap">CHECK OUT</th>
                <th scope="col" class="_noWrap">PRICE($)</th>
                <th scope="col " class="_noWrap">OEM</th>
                <th scope="col" class="_noWrap">MODEL NAME</th>
                <th scope="col" class="_noWrap">LOCATION</th>
              </tr>
            </thead>
            <tbody>

                <tr class="position-relative" *ngFor="let item of deviceList;index as i">
                  <td  class="_noWrap "> {{item.estBookingDate }}</td>
                  <td  class="_noWrap">{{item?.stripeChargeId ? item?.stripeChargeId : "--"}}</td>
                  <td  class="_noWrap "> {{item?.bookedFrom *1000 | date: "MM/dd/yyyy hh:mm a"}}</td>
                  <td  class="_noWrap "> {{item?.bookedUntil *1000 | date: "MM/dd/yyyy hh:mm a"}}</td>
                  <td  class="_noWrap">{{item?.amountPaid}}</td>
                  <td  class="_noWrap">{{item?.deviceData.oem}}</td>
                  <td  class="_noWrap">{{item?.deviceData.modelName}}</td>
                  <td  class="_noWrap">{{item?.deviceData.labLocation}}</td>
              </tr>
  
           
            </tbody>
          </table>
          <p class="no_device" *ngIf="invoiceList.length == 0">Add device to preview them</p>
        </div>
        
      </div>
    </div>
  </ng-template>
  <ng-template #Assign let-modal>
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="modal-header">
        <h5 class="modal-title">Change Status</h5>
    
        <!-- <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <span (click)="closeModal()" style="cursor: pointer" class="cancel_btn_modal"
          >x</span
        >
      </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="statusForm">
              <div class="row">
                <div class="form-group col-md-12">
                    <label>Existing Status</label>
                    <div>
                      <input
                      class="form-control login_input position-relative w-100"
                        type="text"
                        [disabled]="true"
                        value="{{ data.status }}" 
                      />
                    </div>
                  </div>
                <div class="form-group col-md-12 mt-2 mb-3 " for="selectpicker">
                    <label>New Status</label>
                    <select class="form-control select_input mt-1" formControlName="status" >
                        <option value="">Select</option>
                        <option value="inactive" *ngIf="data.status == 'active'">Inactive</option>
                        <option value="active" *ngIf="data.status == 'inactive'">Active</option>
                    </select>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
     <div class="row justify-content-end">
        <button type="submit" class="add_card" (click)="updateUserStatus(statusForm.value)">Save</button>
     </div>
    </div>
  </ng-template>
