import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthServiceLocal } from '../auth.service';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { OptModalComponent } from './opt-modal/opt-modal.component';
import { ChromeComponent } from './chrome/chrome.component';

const router: Routes = [
    { path: '', component: LoginComponent,
        children: [
            { path: '', redirectTo: 'signin', pathMatch: 'full' },
            { path: 'signin', component: SigninComponent, data: { state: 'signin' } },
            { path: 'signup', component: SignupComponent, data: { state: 'signin' } },
        ],
    },
];

@NgModule({
    exports: [],
    imports: [CommonModule, SharedModule, RouterModule.forChild(router), FormsModule, ReactiveFormsModule, HttpClientModule,
        NgOtpInputModule
    ],
    declarations: [LoginComponent, SigninComponent, SignupComponent, OptModalComponent, ChromeComponent],
    providers: [AuthServiceLocal]
})

export class LoginModule { }
