<div class="list-controls">
    <div class="page-title">
        <h1>
            Manual test
            <div class="total-count">
                {{manualTestService.totalElements || 0}}
            </div>
        </h1>
        <div class="filters">
                    <input [(ngModel)]="manualTestService.searchValue" type="text" maxlength="50" class="form-control search-field" placeholder="Search"
                           (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)" (input)="filterTests()"/>
            <button class="btn control_btn_new create-new-button" (click)="manualTestService.createNewTest()"
                    [disabled]="manualTestService.mtSessionStatus === 0">
                Record a new test
            </button>
        </div>
    </div>
</div>
<div class="container-select">
    <ng-multiselect-dropdown [placeholder]="'Select status'" [settings]="getDropDownSettings(1)" [data]="statusList"
                             [(ngModel)]="manualTestService.selectedStatuses" (onDeSelect)="getFilteredTests()" (onSelect)="getFilteredTests()"
                             class="status-filter">
    </ng-multiselect-dropdown>
    <div class="button-group">
        <button class="btn" (click)="setDateFilter(FilterType.TODAY)"
                [ngClass]="{ selected: dateFilter === FilterType.TODAY }">
            Today
        </button>
        <button class="btn" (click)="setDateFilter(FilterType.LAST_WEEK)"
                [ngClass]="{ selected: dateFilter === FilterType.LAST_WEEK }">
            Last Week
        </button>
        <button class="btn" (click)="setDateFilter(FilterType.CUSTOM)"
                [ngClass]="{ selected: dateFilter === FilterType.CUSTOM }">
            Custom
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="14.3"
                 viewBox="0 0 17.039 14.308" fill="currentColor">
                <g id="Group_1142" data-name="Group 1142" transform="translate(-7697.717 -1509.119)">
                    <path id="Path_6" data-name="Path 6"
                          d="M7709.212,1511.652a.452.452,0,0,0,.139.021h.015a.458.458,0,0,0,.14-.021.472.472,0,0,0,.333-.452v-1.608a.472.472,0,0,0-.333-.452.458.458,0,0,0-.14-.021h-.015a.452.452,0,0,0-.139.021.471.471,0,0,0-.332.452v1.608A.471.471,0,0,0,7709.212,1511.652Z" />
                    <rect id="Rectangle_13" data-name="Rectangle 13" width="0.959" height="2.554" rx="0.475"
                          transform="translate(7700.542 1509.119)" />
                    <path id="Path_7" data-name="Path 7"
                          d="M7698.63,1520.778v-7.371h13.148v3.224a3.5,3.5,0,0,1,.886.175v-4.754a1.148,1.148,0,0,0-1.145-1.146h-1.276v.294a.878.878,0,0,1-.877.877h-.015a.877.877,0,0,1-.875-.877v-.294h-6.564v.294a.88.88,0,0,1-.879.877h-.014a.876.876,0,0,1-.874-.877v-.294h-1.28a1.15,1.15,0,0,0-1.148,1.146v8.472a1.151,1.151,0,0,0,1.148,1.147h9.4a3.486,3.486,0,0,1-.268-.893Z" />
                    <path id="Path_8" data-name="Path 8"
                          d="M7701.585,1515.553a1.023,1.023,0,0,0-1.023-1.023h0a1.024,1.024,0,0,0-1.024,1.023h0a1.024,1.024,0,0,0,1.024,1.023h0a1.023,1.023,0,0,0,1.023-1.023Z" />
                    <rect id="Rectangle_14" data-name="Rectangle 14" width="2.046" height="2.046"
                          transform="translate(7702.625 1514.53)" />
                    <rect id="Rectangle_15" data-name="Rectangle 15" width="2.046" height="2.046"
                          transform="translate(7699.539 1517.607)" />
                    <rect id="Rectangle_16" data-name="Rectangle 16" width="2.046" height="2.046"
                          transform="translate(7702.625 1517.607)" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width="2.046" height="2.046"
                          transform="translate(7705.712 1517.607)" />
                    <rect id="Rectangle_18" data-name="Rectangle 18" width="2.046" height="2.046"
                          transform="translate(7705.712 1514.53)" />
                    <rect id="Rectangle_19" data-name="Rectangle 19" width="2.046" height="2.046"
                          transform="translate(7708.799 1514.53)" />
                    <path id="Path_9" data-name="Path 9"
                          d="M7711.592,1517.1a3.164,3.164,0,1,0,3.164,3.164A3.164,3.164,0,0,0,7711.592,1517.1Zm0,5.636a2.472,2.472,0,1,1,2.471-2.472A2.474,2.474,0,0,1,7711.592,1522.735Z" />
                    <path id="Path_10" data-name="Path 10"
                          d="M7713.12,1520.188h-1.382v-1.622a.247.247,0,1,0-.494,0v2.081c0,.137.076.233.358.233h1.518a.346.346,0,0,0,0-.692Z" />
                </g>
            </svg>
            <div class="range-picker-cover">
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate (dateChange)="updateDateFilter('start', $event)">
                    <input matEndDate (dateChange)="updateDateFilter('end', $event)">
                </mat-date-range-input>
            </div>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>
        </button>
    </div>
</div>

<mat-drawer #drawer mode="over" position="end">
    <app-manual-test-record-sidepanel (toggleSidepanelAction)="toggleSidepanelAction()"
                               [testInfo]="testList[selectedIndex]" (updateTable)="updateTable($event)"></app-manual-test-record-sidepanel>
</mat-drawer>

<table class="test-list" multi *ngIf="testList.length !== 0">
    <thead class="list-header">
        <tr class="list-row">
            <th class="cell">Test Name</th>
            <th class="cell">Created At</th>
            <th class="cell">Device Model</th>
            <th class="cell">Device OS</th>
            <th class="cell">Status</th>
            <th class="cell">Progress</th>
            <th class="cell"></th>
        </tr>
    </thead>
    <tbody *ngFor="let test of testList; index as i" class="accordion-row">
        <div class="accordion-header" (click)="showTestDetails(i)">
            <tr class="list-row">
                <td class="cell ellipsis" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                    [tpData]="test.name | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false">
                    {{test.name}}
                </td>
                <td class="cell">{{test.createdAt * 1000 | date: 'shortDate'}}</td>
                <td class="cell">{{test.device.name}}</td>
                <td class="cell">{{test.device.os}}</td>
                <td class="cell status">
                    <div class="status-indicator" [style.backgroundColor]="getStatusColor(test)"></div>
                    {{displayTestStatus(test)}}
                </td>
                <td class="cell artifacts">
                    <mat-icon class='artifact-status' *ngIf="checkIfArtifactsReady(test.artifacts)">done</mat-icon>
                    <mat-spinner color="accent" diameter="16"
                        *ngIf="checkArtifactsStatus(test.artifacts, 'FINALIZING') || checkArtifactsStatus(test.artifacts, 'PROGRESS')"></mat-spinner>
                    <img class='artifact-status failed-icon' src="../../../../assets/images/artifact_failed.svg" alt=""
                        *ngIf="checkArtifactsStatus(test.artifacts, 'FAILED')" />
                </td>
                <td class="cell actions" (click)="$event.stopPropagation();">
                    <span [tp]="tooltipComponent" tpPlacement="left" class="test-action"
                        [tpData]="(isCloudAvailable ? 'Download all artifacts' : 'Unavailable. Your subscription plan must include Cloud feature.') | tooltipData">
                        <img class='action-button download-icon'
                            src="../../../../assets/images/NewUI/download-artifact.svg" alt=""
                            [ngClass]="{ disabled: !checkArtifactDownload(test.artifacts) }"
                            (click)="downloadArtifacts(test)">
                    </span>
                    <span class="test-action" (click)="openEditTest(editTest, test)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Edit' | tooltipData">
                            <img alt="" src="../../../../assets/images/edit-new.svg"/>
                        </span>
                    <span class="test-action" (click)="openConfirmDelete(test)" [tp]="tooltipComponent" tpPlacement="left" [tpData]="'Delete' | tooltipData">
                            <img alt="" src="../../../../assets/images/trash-new.svg"/>
                        </span>
                </td>
            </tr>
        </div>
    </tbody>
</table>
<div class="pagination" *ngIf="testList.length !== 0">
    <select class="page-size" (change)="changePageSize($event)">
        <option value="5">5</option>
        <option value="10" selected>10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
    <div class="page-container">
        <span (click)="changePage(-1)" [ngClass]="{'disabled':manualTestService.currentPage === 0}">&#60;</span>
        <span>{{manualTestService.currentPage + 1}} / {{manualTestService.totalPages}}</span>
        <span (click)="changePage(1)" [ngClass]="{'disabled':manualTestService.currentPage + 1 === manualTestService.totalPages }">&#62;</span>
    </div>
</div>
<div class="no-results" *ngIf="testList.length === 0">
    <app-empty-search-result [title]="'No Manual Tests were found'"
        [description]="'No results matched your criteria. Try adjusting your search or filter options to find what you are looking for.'"
        [action]="clearFilters"></app-empty-search-result>
</div>

<!--Edit test modal-->
<ng-template #editTest let-modal>
    <form [formGroup]="editTestForm" (ngSubmit)="applyEdit()">
        <div class="modal-body">
            <div class="header-wrapper">
                <span>Edit test</span>
                <svg (click)="closeEditModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                    width="24">
                    <path
                        d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
                </svg>
            </div>
            <div class="dialog-divider"></div>
            <div class="dialog-content">
                <div class="input-section">
                    <label for="test-name"><span class="required-field">*</span>Test Name</label>
                    <input type="text" class="form-control" id="test-name" #testName placeholder="Enter a name"
                        (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)" formControlName="editName"
                        [ngClass]="{'error': editName.invalid}" />
                    <div *ngIf="editName.errors?.uniqueError" class="input-error">Test name must be unique</div>
                    <div *ngIf="editName.errors?.required" class="input-error">Test name can't be empty</div>
                </div>
                <div class="input-section">
                    <label for="test-description">Description</label>
                    <textarea max="150" rows="3" id="test-description" #testDescription class="form-control"
                        placeholder="Enter a description" (focusin)="emitIgnoreInput(true)"
                        (focusout)="emitIgnoreInput(false)" formControlName="editDescription"></textarea>
                </div>
                <span class="status-text">Outcome of the test</span>
                <div class="status-switch">
                    <button class="passed-button" [ngClass]="{selected: editResult.value === 'PASS'}"
                        (click)="setEditResult('PASS')" type="button">Passed</button>
                    <button class="failed-button" [ngClass]="{selected: editResult.value === 'FAIL'}"
                        (click)="setEditResult('FAIL')" type="button">Failed</button>
                </div>
            </div>

            <div class="dialog-divider"></div>
            <div class="dialog-actions">
                <button class="btn cancel-btn" (click)="closeEditModal()" type="button">Cancel</button>
                <button class="btn control_btn_new" type="submit" [disabled]="!editTestForm.valid">Save</button>
            </div>
        </div>
    </form>
</ng-template>

<!--Add description modal-->
<ng-template #addDescription let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>Add description</span>
            <svg (click)="closeDescriptionModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                width="24">
                <path
                    d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dialog-content">
            <div class="input-section">
                <textarea max="150" rows="3" class="form-control" placeholder="Enter a description"
                    (focusin)="emitIgnoreInput(true)" (focusout)="emitIgnoreInput(false)"
                    [(ngModel)]="descriptionToAdd"></textarea>
            </div>
        </div>

        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeDescriptionModal()" type="button">Cancel</button>
            <button class="btn control_btn_new" (click)="applyDescription()" [disabled]="!descriptionToAdd">Add</button>
        </div>
    </div>
</ng-template>

<!--Add artifacts modal-->
<!-- <ng-template #addArtifact let-modal>
    <div class="modal-body">
        <div class="header-wrapper">
            <span>Upload New Artifact</span>
            <svg (click)="closeArtifactModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960"
                width="24">
                <path
                    d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
            </svg>
        </div>
        <div class="dialog-divider"></div>
        <div class="dropzone">
            <app-dropzone [warning]="fileUploadWarning" [hasError]="fileUploadError" [maxSize]="314572800"
                [isDisabled]="!!uploadedFile" (fileSelected)="handleFileUpload($event)" [file]="uploadedFile"
                [percent]="fileUploadProgress"></app-dropzone>
        </div>

        <div class="dialog-divider"></div>
        <div class="dialog-actions">
            <button class="btn cancel-btn" (click)="closeArtifactModal()" type="button">Cancel</button>
            <button class="btn control_btn_new" (click)="artifactModalConfirm()"
                *ngIf="!!uploadedFile">{{fileUploadWarning ? 'Replace' : 'Upload new'}}</button>
        </div>
    </div>
</ng-template> -->
