<section class="new-test" [ngClass]="{ongoing_test_container: isTestRunning}">
    <div class="back-button" *ngIf="isTestRunning" (click)="navigate('/snap/custom-tests')">
        <img alt="" src="../../../../assets/images/arrow_back.svg">
        <span class="mo-mt-mode">Back</span>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>

    <div class="container_new_test" *ngIf="isTelephonyAvailable" [ngClass]="{ongoing_test: isTestRunning}">
        <div class="sub_container left_container" [ngClass]="{ongoing_test: isTestRunning, left_container_hidden: isExpandedIterations}">
            <app-multi-device-select [isTestRunning]="isTestRunning" [ignoreTyping]="ignoreTyping"
                [devices]="selectedTestCase?.devices" (deviceSelected)="updateSelectedDevices($event)"
                (switchCustomizeMode)="switchCustomizeMode($event)" [deviceListener]="setDevices">
            </app-multi-device-select>
            <div class="ongoing-banner" *ngIf="isTestRunning && (testInfo.status === 'RUNNING' || testInfo.status === 'EXECUTING')">
                <mat-progress-bar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
                <span class="remaining_time" *ngIf="selectedTestCase?.type !== 'SPEED_TEST'">
                    Time remaining:
                    {{getEstimatedTime(selectedTestCase?.type)}}
                </span>
                <span class="iterations-counter">
                    <span class="completed_iterations">{{completedIterations}} of </span> {{totalIterations}}
                </span>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="!isTestRunning && !isCustomizeMode">
            <h3 class="new_test_title">Test Configuration {{selectedTestCase?.name ? '- ' + selectedTestCase?.name : ''}}</h3>
            <div class="text_new_test">
                <label for="test-iterations">Iterations</label>
                <input id="test-iterations" type="number" min="1" max="500" [(ngModel)]="testRepeatCount"
                    class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)"
                    (focusout)="updateIgnoreTyping(false)" />
            </div>
            <div class="text_new_test">
                <label for="test-delay">Delay</label>
                <label class="time-unit" data-unit="sec">
                    <input id="test-delay" type="number" min="1" max="3600" [(ngModel)]="iterationDelay"
                        class="form-control input_new_test number_input" (focusin)="updateIgnoreTyping(true)"
                        (focusout)="updateIgnoreTyping(false)" />
                </label>
            </div>
            <h4>Artifacts</h4>
            <div class="checkboxes" *ngIf="!isCloudAvailable">
                Unavailable. Your subscription plan must include Cloud feature.
            </div>
            <div class="checkboxes" *ngIf="isCloudAvailable">
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[1]['DEVICE_LOGS']" color="primary"
                    (change)="handleArtifactSelection(1, 'DEVICE_LOGS', $event.checked)">
                    Save device logs
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[1]['DEVICE_VIDEO']" color="primary"
                    (change)="handleArtifactSelection(1, 'DEVICE_VIDEO', $event.checked)">
                    Save video
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[1]['DEVICE_USER_ACTIONS']" color="primary"
                    (change)="handleArtifactSelection(1, 'DEVICE_USER_ACTIONS', $event.checked)">
                    Save user actions
                </mat-checkbox>
                <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[1]['DEVICE_KPIS']" color="primary"
                    (change)="handleArtifactSelection(1, 'DEVICE_KPIS', $event.checked)">
                    Save device KPIs
                </mat-checkbox>
                <!-- <mat-checkbox *ngIf="selectedTestCase?.devices.length === 1"
                    [checked]="artifactSelection[1]['DEVICE_AUDIO']" color="primary"
                    (change)="handleArtifactSelection(1, 'DEVICE_AUDIO', $event.checked)">
                    Save device audio
                </mat-checkbox> -->
                <div *ngIf="selectedTestCase?.devices.length > 1" class="artifact-table">
                    <div class="table-row">
                        <span></span>
                        <span>Device Logs</span>
                        <span>Video</span>
                        <span>User Actions</span>
                        <span>Device KPIs</span>
<!--                        <span>Device Audio</span>-->
                    </div>
                    <div *ngFor="let device of selectedTestCase?.devices" class="table-row">
                        <span class="bolder">{{'Device ' + device.index}}</span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.index][DEVICE_LOGS]" color="primary"
                                (change)="handleArtifactSelection(device.index, 'DEVICE_LOGS', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.index][DEVICE_VIDEO]" color="primary"
                                (change)="handleArtifactSelection(device.index, 'DEVICE_VIDEO', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.index][DEVICE_USER_ACTIONS]"
                                color="primary"
                                (change)="handleArtifactSelection(device.index, 'DEVICE_USER_ACTIONS', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <span>
                            <mat-checkbox [checked]="artifactSelection[device.index][DEVICE_KPIS]" color="primary"
                                (change)="handleArtifactSelection(device.index, 'DEVICE_KPIS', $event.checked)">
                            </mat-checkbox>
                        </span>
                        <!-- <span>
                            <mat-checkbox [checked]="artifactSelection[device.index][DEVICE_AUDIO]" color="primary"
                                (change)="handleArtifactSelection(device.index, 'DEVICE_AUDIO', $event.checked)">
                            </mat-checkbox>
                        </span> -->
                    </div>
                </div>
            </div>
            <form class="dynamic-form" *ngIf="paramsForm" [formGroup]="paramsForm">
                <div class="text_new_test group" *ngFor="let param of templateParameters">
                    <label>{{param.label}} <span *ngIf="param.constraints?.required">*</span></label>
                    <select *ngIf="param.type === 'select'" class="form-control"
                        [ngClass]="{ placeholder: !paramsForm.value[param.name] }" [id]="param.name"
                        [formControlName]="param.name">
                        <option *ngFor="let option of param.options" [value]="option.value">{{option.name}}</option>
                    </select>
                    <label class="time-unit" [data-unit]="param.units || ''" *ngIf="param.type === 'number'">
                        <input type="number" [formControlName]="param.name" [min]="param.constraints?.min?.value"
                            [max]="param.constraints?.max?.value" [id]="param.name" class="form-control input_new_test"
                            (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                            [placeholder]="param.placeholder" />
                    </label>
                    <input *ngIf="param.type === 'text'" type="text" [formControlName]="param.name" [id]="param.name"
                        [maxlength]="param.constraints?.max?.value" [minlength]="param.constraints?.min?.value"
                        class="form-control input_new_test" [placeholder]="param.placeholder" (focusin)="updateIgnoreTyping(true)"
                        (focusout)="updateIgnoreTyping(false)" />
                    <textarea *ngIf="param.type === 'textarea'" [formControlName]="param.name"
                        (focusin)="updateIgnoreTyping(true)" (focusout)="updateIgnoreTyping(false)"
                        [max]="param.constraints?.max" rows="5" class="form-control input_new_test">
                    </textarea>
                    <div *ngIf="paramsForm.controls[param.name] && paramsForm.controls[param.name].invalid && (paramsForm.controls[param.name].dirty || paramsForm.controls[param.name].touched)"
                        class="error">
                        <div *ngIf="paramsForm.controls[param.name].errors.required">
                            *{{param.constraints.required?.message}}
                        </div>
                        <div *ngIf="paramsForm.controls[param.name].errors.max">
                            *{{param.constraints.max?.message}}
                        </div>
                        <div *ngIf="paramsForm.controls[param.name].errors.min">
                            *{{param.constraints.min?.message}}
                        </div>
                    </div>
                </div>
            </form>
            <div class="buttons_new_test">
                <button class="btn momt_btn" (click)="openConfirmCancel()">Cancel</button>
                <button class="btn momt_btn" [disabled]="!checkTestReady()" (click)="saveTest()">Save</button>
                <button class="btn momt_btn momt_btn_start loginBtn" [disabled]="!checkTestReady()"
                    (click)="startTest()">
                    Run test
                </button>
            </div>
        </div>
        <div class="sub_container right_container" *ngIf="isTestRunning">
            <div class="expand_button" (click)="expandIterations()">
                <mat-icon class="arrow_icon" *ngIf="!isExpandedIterations">arrow_back_ios</mat-icon>
                <mat-icon class="arrow_icon collapse_icon" *ngIf="isExpandedIterations">arrow_forward_ios</mat-icon>
            </div>
            <h3 class="test-name">
                <span class="ellipsis" [tp]="tooltipComponent" [tpData]="selectedTestCase?.name | tooltipData"
                      [tpOnlyTextOverflow]="true">{{selectedTestCase?.name}}</span>
                <button class="btn momt_btn stop-test" (click)="cancelTest()">Stop the test</button>
            </h3>
            <span class="test-description" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                [tpData]="selectedTestCase?.description | tooltipData" [tpMaxWidth]="450" [tpInteractive]="false"
                *ngIf="selectedTestCase?.description">
                Description - {{selectedTestCase?.description}}
            </span>
            <app-iteration-accordion [iterations]="iterations"
                [devices]="selectedTestCase?.devices"
                [isExpanded]="isExpandedIterations"
                [totalIterations]="totalIterations"
                [testInfo]="testInfo"
            ></app-iteration-accordion>
            <div class="pagination-container" *ngIf="iterations?.length > 0">
                <span class="limit">
                    <label for="limit">Rows per page: </label>
                    <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </span>
                <span>{{paginationText}}</span>
                <div class="pagination-buttons">
                    <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
                        <img alt="" src="../../../../assets/images/arrow_back.svg">
                    </div>
                    <div [ngClass]="{ disabled: currentPage + 1 >= totalPage }" class="arrow-button"
                        (click)="changePage(1)">
                        <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="sub_container right_container info_tab" *ngIf="isCustomizeMode">
            <h3 class="info_header">Info</h3>
            <div class="info_container">
                <app-info-tab [updateIgnoreTyping]="updateIgnoreTyping"></app-info-tab>
            </div>
        </div>
    </div>
    <div class="no-access" *ngIf="!isTelephonyAvailable">
        <p>
            MO-MT Testing is not available with your current subscription plan. <br />
            To get access to MO-MT Testing, you must upgrade your account to a plan that includes Telephony
            tests feature.
        </p>
    </div>
</section>
