import { Component, OnInit } from "@angular/core";
import { AdminService } from "../admin.service";
import { Router } from "@angular/router";
import { TooltipService } from "../../../shared/tooltip.service";
import { DialogModalService } from "../../../shared/dialog-modal.service";
import { GetSubscriptionListDto } from "../../../shared/dto";
import { Feature, Subscription } from "../../../shared/interfaces";
import { FeatureTypeEnum, SubscriptionTypeEnum } from "../../../shared/enum";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
    selector: 'app-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
    searchText = '';
    tooltipComponent = null;
    isLoading = 0;
    isFilterOpen = false;
    isFiltered = false;
    subscriptions: Subscription[] = [];
    totalCommon = 0;
    totalPublic = 0;
    totalCorporate = 0;
    activeTab = SubscriptionTypeEnum.COMMON;
    searchStatus = '';
    TypeEnum = SubscriptionTypeEnum;
    featureList = [];
    searchFeatures: { item_id: FeatureTypeEnum, item_text: string }[] = [];

    constructor(
        private adminService: AdminService,
        public router: Router,
        private tooltipService: TooltipService,
        private dialogModalService: DialogModalService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit() {
        this.getSubscriptionList();
        this.getFeatures();
    }

    getSubscriptionList() {
        this.isLoading++;
        const query = this.getQueryParam();
        this.adminService.getSubscriptionList(query).subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                const data = res.data as GetSubscriptionListDto;
                this.subscriptions = data.subscriptions;
                this.totalCommon = data.totalCommon;
                this.totalPublic = data.totalPublic;
                this.totalCorporate = data.totalCorporate;
            }
        }, err => {
            this.isLoading--;
        });
    }

    getFeatures() {
        this.isLoading++;
        this.adminService.getFeatureList().subscribe((res) => {
            this.isLoading--;
            if (res['message'] == 'Success') {
                const data = res.data as { features: Feature[] };
                this.featureList = data.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));
            }
        }, err => {
            this.isLoading--;
        });
    }

    getQueryParam() {
        let query = `type=${this.activeTab}`;
        if (this.searchStatus) {
            query += `&status=${this.searchStatus}`;
        }
        if (this.searchText) {
            query += `&text=${encodeURIComponent(this.searchText.trim())}`;
        }
        if (this.searchFeatures.length > 0) {
            query += `&features=${this.searchFeatures.map((feature) => feature.item_id).join(',')}`;
        }

        return query;
    }

    searchChange() {
        this.isFiltered = this.searchText ? true : false;
        this.getSubscriptionList();
    }

    openFilters() {
        this.isFilterOpen = !this.isFilterOpen;
    }

    clearFilters() {
        this.isFiltered = false;
        this.searchText = '';
        this.searchStatus = '';
        this.searchFeatures = [];
        this.getSubscriptionList();
    }

    applyFilters() {
        this.isFilterOpen = false;
        this.isFiltered = this.searchText !== '' ||
            this.searchStatus !== '' ||
            this.searchFeatures.length > 0;
        this.getSubscriptionList();
    }

    openTab(tab: SubscriptionTypeEnum) {
        if (this.activeTab !== tab) {
            this.activeTab = tab;
            this.getSubscriptionList();
        }
    }

    getDropDownSettings(countShowElements: number): IDropdownSettings {
        return {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: countShowElements,
            allowSearchFilter: false,
            enableCheckAll: false,
        };
    }

    navigatePage(path: string, query?: Record<string, string>) {
        this.router.navigate([path], { queryParams: query });
    }

    openConfirmDelete(id: string) {
        this.dialogModalService.openConfirmationDialog('deleteSubscription', () => this.deleteSubscription(id));
    }

    deleteSubscription(id: string) {
        this.isLoading++;
        this.adminService.deleteSubscription(id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                this.getSubscriptionList();
            }
        }, err => {
            this.isLoading--;
        });
    }

    getSubscriptionAssignees(subscription: Subscription) {
        const assignees = [...subscription.assignedUsers, ...subscription.assignedEnterprises];
        return assignees.slice(0, 9);
    }

    getSubscriptionAssigneesMore(subscription: Subscription) {
        const assignees = [...subscription.assignedUsers, ...subscription.assignedEnterprises];
        return assignees.slice(9);
    }

    getAssigneeTooltip(user: Record<string, string>) {
        if(user.email) {
            return `${user.firstName} ${user.lastName} ${user.email}`;
        }
        return user.name
    }
}