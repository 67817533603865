import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { TokenData } from '../../../../shared/interfaces';
import { CommonService } from '../../../../shared/common.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-create-support-user',
    templateUrl: './create-support-user.component.html',
    styleUrls: ['./create-support-user.component.scss']
})
export class CreateSupportUserComponent implements OnInit {
    userForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    isPasswordShown = false;
    isLower = true;
    isUpper = true;
    isSpecial = true;
    isSecure = true;
    isLoading = false;
    userData: TokenData;
    expirationDate = 0;
    minDate = new Date();

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.minDate.setDate(this.minDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.userForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }

    createUser() {
        if (this.userForm.valid) {
            const {
                firstName,
                lastName,
                email,
                password,
            } = this.userForm.value;

            const request = {
                firstName,
                lastName,
                email,
                password,
                expirationDate: this.expirationDate
            };

            this.isLoading = true;
            this.adminService.createSupportUser(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/users']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }


    generatePassword() {
        const length = 8;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        retVal = 'Sn_' + retVal + '12';
        this.userForm.controls['password'].setValue(retVal);
        this.isSecure = true;
    }

    showPassword() {
        this.isPasswordShown = !this.isPasswordShown;
    }

    checkPassword() {
        const password = this.userForm.value.password;
        if (password.length < 2) {
            this.isUpper = true;
            this.isLower = true;
            this.isSpecial = true;
            return;
        }
        this.isLower = !!password.match('[a-z]');
        this.isUpper = !!password.match('[A-Z]');
        this.isSpecial = !!password.match('[#$^%,!&@_\.-]');

        this.isSecure = this.isLower && this.isUpper && this.isSpecial;
    }

    clearExpirationDate(event: Event) {
        event.stopPropagation();
        this.expirationDate = 0;
    }

    updateExpirationDate($event: MatDatepickerInputEvent<Date>) {
        this.expirationDate = new Date($event.value).setHours(23, 59, 59);
    }

    displayExpirationDate() {
        const date = new Date(this.expirationDate);
        return `Expiration Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
}

