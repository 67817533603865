import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { User } from "./interfaces";
import { UserRoleEnum, UserStatusEnum } from "./enum";
import { NetworkService } from "./network.service";
import { ApiResponseDto } from "./dto";
import { CommonService } from "./common.service";

const defaultUser = {
    firstName: '',
    lastName: '',
    logo: '',
    billingAddress: '',
    authType: '',
    status: UserStatusEnum.ACTIVE,
    role: UserRoleEnum.USER,
    phoneNumber: '',
    labIds: [],
    corporateIcon: '',
    enterpriseId: '',
    notifications: [],
    createdAt: 0,
    email: '',
    expirationDate: 0,
    expirationEmailDate: 0,
    availableFeatures: [],
    userId: '',
    enterpriseName: '',
    accessibility: null,
    subscription: null,
    isFirstLogin: false
}

@Injectable()
export class UserDataService {
    constructor(
        private networkService: NetworkService,
        private commonService: CommonService,
    ) {
        this.startProfilePolling();
    }

    private userData = new BehaviorSubject<User>(defaultUser);
    userData$ = this.userData.asObservable();

    pollingInterval: Subscription = null;

    setUserData(value: User) {
        this.userData.next(value);
    }

    getUserData(callback?: () => void): void {
        if (this.commonService.isAuthenticated()) {
            this.networkService.get('api/users/profile', null, null, 'basic').subscribe((res: ApiResponseDto<User>) => {
                if (res.statusCode === 200) {
                    const response = res.data as User;
                    this.setUserData(response);
                    callback && callback(); 
                }
            });
        } else {
            this.stopProfilePolling();
        }
    }

    startProfilePolling(): void {
        if (this.commonService.isAuthenticated() && (!this.pollingInterval || this.pollingInterval.closed)) {
            this.pollingInterval = timer(0, 60000).subscribe(() => {
                this.getUserData();
            });
        }
    }

    stopProfilePolling(): void {
        if (this.pollingInterval && !this.pollingInterval.closed) {
            this.pollingInterval.unsubscribe();
        }
    }
}