<div class="main-header">
    <div>Help</div>
    <span (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
            <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
    </span>
</div>
<div class="main-body">
    <div class="subheader">Device Status</div>
    <div class="subheader-info">Currently, The Snap has different device statuses.</div>
    <div class="status-diagram">
        <img src="../../../assets/images/diagram.svg" alt=""/>
    </div>
    <div class="subheader-info">Status details:</div>
    <div class="status-table">
        <table>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Android/iPhone</th>
                    <th>Description</th>
                    <th>How to resolve</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let status of statusList">
                    <td class="status-name">{{status.name}}</td>
                    <td>{{status.deviceType}}</td>
                    <td>{{status.description}}</td>
                    <td>{{status.resolve}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="spacer"></div>
</div>

