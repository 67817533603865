import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ManualTest } from '../../../shared/interfaces';
import { Subscription } from 'rxjs';
import { ManualTestService } from '../manual-test.service';
import { DeviceService } from '../../view-device/device.service';
import { TestResultEnum } from '../../../shared/enum';
import { ApiErrorResponse, ApiResponseDto, UpdateManualTestDto } from '../../../shared/dto';

@Component({
    selector: 'app-finalize-manual-test',
    templateUrl: './finalize-manual-test.component.html',
    styleUrls: ['./finalize-manual-test.component.scss'],
})
export class FinalizeManualTestComponent {
    @Input() releaseDevice: () => void;
    @ViewChild('testName') testName: ElementRef;
    @ViewChild('testDescription') testDescription: ElementRef;

    testList: ManualTest[] = [];
    testListSubscription: Subscription = null;
    uniqueNameError = '';
    testResult: TestResultEnum;
    testResultEnum = TestResultEnum;
    ongoingTest: ManualTest;
    isLoading = false;
    constructor(private manualTestService: ManualTestService, private deviceService: DeviceService) {
        this.testListSubscription = this.manualTestService.testList$.subscribe((list) => {
            this.testList = list;
            if (this.manualTestService.ongoingTestId) {
                const testFound = list.find((test) => test._id === this.manualTestService.ongoingTestId);
                if (testFound) this.ongoingTest = testFound;
            }
            this.ongoingTest = list.find((test) => test._id === this.manualTestService.ongoingTestId);
        });
    }

    emitIgnoreInput(value: boolean): void {
        this.deviceService.ignoreInputFromRecordAndPlay(value);
    }


    discard(): void {
        this.manualTestService.discardOngoingTest();
    }

    save(): void {
        this.isLoading = true;
        const form: UpdateManualTestDto = {
            name: this.testName.nativeElement.value,
            description: this.testDescription.nativeElement.value,
            decision: this.testResult,
        };
        this.manualTestService.updateTest(this.ongoingTest._id, form).subscribe((res: ApiResponseDto<ManualTest>) => {
            if (res.statusCode === 400) {
                const response = res.data as ApiErrorResponse;
                this.uniqueNameError = response.message;
            } else {
                this.manualTestService.updateTestList(res.data as ManualTest);
                this.uniqueNameError = '';
                this.manualTestService.completeTest();
            }
            this.isLoading = false;
        });
    }

    setTestStatus(status: TestResultEnum) {
        this.testResult = this.testResult === status ? null : status;
    }

    ngOnDestroy(): void {
        this.testListSubscription.unsubscribe();
        this.testListSubscription = null;
    }
}