import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { TokenData } from '../../../shared/interfaces';
import { CommonService } from '../../../shared/common.service';
import { UserRoleEnum } from '../../../shared/enum';
import { GetEnterpriseInfoDto } from '../../../shared/dto';

@Component({
  selector: 'app-create-lab',
  templateUrl: './create-lab.component.html',
  styleUrls: ['./create-lab.component.scss']
})
export class CreateLabComponent implements OnInit {
  labForm: FormGroup;
  httpPattern = '((http[s]?):\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.{2,}|www\.[a-zA-Z0-9]+\.{2,})';
  ipPattern =
    '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  isLoading = false;
  enterpriseOptions = [];
  labSupportOptions = [];
  userData: TokenData;

  constructor(private fb: FormBuilder,
    private adminService: AdminService,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
    this.labForm = this.fb.group({
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      domain: ['', [Validators.required, Validators.pattern(this.httpPattern)]],
      ip: ['', [Validators.required, Validators.pattern(this.ipPattern)]],
      location: [''],
      deviceLimit: ['', [Validators.min(0)]],
      deviceDeletePeriod: ['60', [Validators.min(0)]],
      snapSupportContact: [''],
      snapSupportEmail: [''],
      enterpriseId: [''],
      labSupportContact: [''],
      isAppiumSupport: [false],
      isSmartHubEnabled: [false],
      sendAlerts: [false],
      isEnabled: [false],
    });

    if (this.userData.role !== UserRoleEnum.ADMIN) {
      this.labForm.controls.type.setValue('corporate');
    }
    this.labForm.get('type').valueChanges.subscribe(val => {
      if (val === 'corporate') {
        this.labForm.get('enterpriseId').setValidators(Validators.required);
      }
      if (val === 'public') {
        this.labForm.get('enterpriseId').clearValidators();
      }
      this.labForm.get('enterpriseId').updateValueAndValidity();
    });

    this.getEnterprises();
  }

  getEnterprises() {
    this.isLoading = true;
    if (this.userData.role === UserRoleEnum.ADMIN) {
      this.adminService.getEnterprise('').subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
        }
      }, err => {
        this.isLoading = false;
      });
    } else {
      this.adminService.getUserEnterprise().subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          const result = res.data as GetEnterpriseInfoDto;
          this.enterpriseOptions = [{ id: result.enterpriseInfo.id, name: result.enterpriseInfo.name }];

          const snapSupport = result.enterpriseInfo.contacts?.find((support) => support.type === 'SNAP');
          this.labForm.controls.snapSupportContact.setValue(snapSupport ? snapSupport.name : 'Snap Support');
          this.labForm.controls.snapSupportEmail.setValue(snapSupport ? snapSupport.name : 'support@snapautomation.com');
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  getSupportOptions() {
    const enterpriseId = this.labForm.value.enterpriseId;
    if (enterpriseId) {
      this.isLoading = true;
      const query = `enterpriseId=${enterpriseId}&includeSelf=true`;
      this.adminService.getUserListApi(query).subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.labSupportOptions = res.data.userList.map((user) => ({ name: user.firstName + ' ' + user.lastName, email: user.email }));
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  createLab() {
    if (this.labForm.valid) {
      if (!this.labForm.value.domain.match('/$')) {
        this.labForm.value.domain = this.labForm.value.domain + '/';
      }

      const {
        type,
        domain,
        ip,
        name,
        location,
        deviceLimit,
        deviceDeletePeriod,
        snapSupportContact,
        snapSupportEmail,
        enterpriseId,
        labSupportContact,
        isAppiumSupport,
        isSmartHubEnabled,
        sendAlerts,
        isEnabled
      } = this.labForm.value;

      const contacts = [];

      if (snapSupportContact && snapSupportEmail) {
        contacts.push({
          type: 'SNAP',
          name: snapSupportContact,
          email: snapSupportEmail
        });
      }

      if (labSupportContact) {
        const contact = this.labSupportOptions.find((c) => c.email === labSupportContact);
        contacts.push({
          type: 'LAB',
          name: contact.name,
          email: contact.email
        });
      }

      const request = {
        name,
        location,
        contacts,
        maxDeviceCount: deviceLimit || 0,
        deviceDeletePeriod: deviceDeletePeriod || 0,
        domain,
        ip,
        type,
        enterpriseId: type === 'corporate' ? enterpriseId : undefined,
        isAppiumSupport,
        isSmartHubEnabled,
        isEnabled,
        sendAlerts,
      };

      this.isLoading = true;
      this.adminService.createLab(request).subscribe((res) => {
        this.isLoading = false;
        if (res['message'] == 'Success') {
          this.router.navigate(['admin/labs']);
        }
      }, err => {
        this.isLoading = false;
      });
    }
  }

  replaceLimit(prop: string) {
    if (!this.labForm.value[prop]) {
      this.labForm.controls[prop].setValue('');
    }
  }
}

