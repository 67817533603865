import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { GetEnterpriseInfoDto, GetLabListDto } from '../../../shared/dto';
import { TokenData } from '../../../shared/interfaces';
import { UserRoleEnum } from '../../../shared/enum';
import { CommonService } from '../../../shared/common.service';

@Component({
    selector: 'app-update-lab',
    templateUrl: './update-lab.component.html',
    styleUrls: ['./update-lab.component.scss']
})
export class UpdateLabComponent implements OnInit {
    labForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    httpPattern = '((http[s]?):\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.{2,}|www\.[a-zA-Z0-9]+\.{2,})';
    ipPattern =
        '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
    isLoading = false;
    enterpriseOptions = [];
    labSupportOptions = [];
    showError = false;
    userData: TokenData;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.getLabInfo();
        this.initializeForms();
        this.getEnterprises();  
    }

    initializeForms() {
        this.labForm = this.fb.group({
            type: ['', [Validators.required]],
            name: ['', [Validators.required]],
            domain: ['', [Validators.required, Validators.pattern(this.httpPattern)]],
            ip: ['', [Validators.required, Validators.pattern(this.ipPattern)]],
            location: [''],
            deviceLimit: ['', [Validators.min(0)]],
            deviceDeletePeriod: ['60', [Validators.min(0)]],
            snapSupportContact: [''],
            snapSupportEmail: [''],
            enterpriseId: [''],
            labSupportContact: [''],
            isAppiumSupport: [false],
            isSmartHubEnabled: [false],
            sendAlerts: [false],
            isEnabled: [false],
        });

        if (this.userData.role !== UserRoleEnum.ADMIN) {
            this.labForm.controls.type.setValue('corporate');

        }
        this.labForm.get('type').valueChanges.subscribe(val => {
            if (val === 'corporate') {
                this.labForm.get('enterpriseId').setValidators(Validators.required);
            }
            if (val === 'public') {
                this.labForm.get('enterpriseId').clearValidators();
            }
            this.labForm.get('enterpriseId').updateValueAndValidity();
        });
    }

    getLabInfo() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            this.adminService.getLabList(`labId=${id}`).subscribe((res) => {
                this.isLoading = false;
                if (res.statusCode === 200 && res.data['labList']?.length > 0) {
                    const data = res.data as GetLabListDto;
                    const labInfo = data.labList[0];
                    const snapSupport = labInfo.contacts?.find((contact) => contact.type === 'SNAP');
                    const labSupport = labInfo.contacts?.find((contact) => contact.type === 'LAB');
                    this.labForm.setValue({
                        type: labInfo.type || '',
                        name: labInfo.name || '',
                        domain: labInfo.domain || '',
                        ip: labInfo.ip || '',
                        location: labInfo.location || '',
                        deviceLimit: labInfo.maxDeviceCount || '',
                        deviceDeletePeriod: labInfo.deviceDeletePeriod || '',
                        snapSupportContact: snapSupport?.name || '',
                        snapSupportEmail: snapSupport?.email || '',
                        enterpriseId: labInfo.enterpriseId || '',
                        labSupportContact: labSupport?.email || '',
                        isAppiumSupport: labInfo.isAppiumSupport || false,
                        isSmartHubEnabled: labInfo.isSmartHubEnabled || false,
                        sendAlerts: labInfo.sendAlerts || false,
                        isEnabled: labInfo.isEnabled || false,
                    });
                    this.getSupportOptions();
                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            })
        }
    }

    getEnterprises() {
        this.isLoading = true;
        if (this.userData.role === UserRoleEnum.ADMIN) {
          this.adminService.getEnterprise('').subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
              this.enterpriseOptions = res.data.enterpriseList.map((enterprise) => ({ id: enterprise.id, name: enterprise.name }));
            }
          }, err => {
            this.isLoading = false;
          });
        } else {
          this.adminService.getUserEnterprise().subscribe((res) => {
            this.isLoading = false;
            if (res['message'] == 'Success') {
              const result = res.data as GetEnterpriseInfoDto;
              this.enterpriseOptions = [{ id: result.enterpriseInfo.id, name: result.enterpriseInfo.name }];
              this.labForm.controls.enterpriseId.setValue(result.enterpriseInfo.id);
    
              const snapSupport = result.enterpriseInfo.contacts?.find((support) => support.type === 'SNAP');
              this.labForm.controls.snapSupportContact.setValue(snapSupport ? snapSupport.name : 'Snap Support');
              this.labForm.controls.snapSupportEmail.setValue(snapSupport ? snapSupport.name : 'support@snapautomation.com');
            }
          }, err => {
            this.isLoading = false;
          });
        }
      }

    getSupportOptions() {
        const enterpriseId = this.labForm.value.enterpriseId;
        if (enterpriseId) {
            this.isLoading = true;
            const query = `enterpriseId=${enterpriseId}&includeSelf=true`;
            this.adminService.getUserListApi(query).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.labSupportOptions = res.data.userList.map((user) => ({ name: user.firstName + ' ' + user.lastName, email: user.email }));
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    updateLab() {
        if (this.labForm.valid) {
            if (!this.labForm.value.domain.match('/$')) {
                this.labForm.value.domain = this.labForm.value.domain + '/';
            }

            const {
                type,
                domain,
                ip,
                name,
                location,
                deviceLimit,
                deviceDeletePeriod,
                snapSupportContact,
                snapSupportEmail,
                enterpriseId,
                labSupportContact,
                isAppiumSupport,
                isSmartHubEnabled,
                sendAlerts,
                isEnabled
            } = this.labForm.value;

            const contacts = [];

            if (snapSupportContact && snapSupportEmail) {
                contacts.push({
                    type: 'SNAP',
                    name: snapSupportContact,
                    email: snapSupportEmail
                });
            }

            if (labSupportContact && type === 'corporate') {
                const contact = this.labSupportOptions.find((c) => c.email === labSupportContact);
                contacts.push({
                    type: 'LAB',
                    name: contact.name,
                    email: contact.email
                });
            }

            const request = {
                labId: this.route.snapshot.queryParamMap.get('id'),
                name,
                location,
                contacts,
                maxDeviceCount: deviceLimit || 0,
                deviceDeletePeriod: deviceDeletePeriod || 0,
                domain,
                ip,
                type,
                enterpriseId: type === 'corporate' ? enterpriseId : undefined,
                isAppiumSupport,
                isSmartHubEnabled,
                isEnabled,
                sendAlerts,
            };

            this.isLoading = true;
            this.adminService.updateLab(request).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/labs']);
                    this.isLoading = false;
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    replaceLimit(prop: string) {
        if (!this.labForm.value[prop]) {
          this.labForm.controls[prop].setValue('');
        }
      }
}
