import { Component, OnInit } from '@angular/core';
import { Enterprise, Feature, TokenData } from '../../../../shared/interfaces';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../../../shared/tooltip.service';
import { DialogModalService } from '../../../../shared/dialog-modal.service';
import { CommonService } from '../../../../shared/common.service';
import { GetEnterpriseListDto } from '../../../../shared/dto/get-enterprise-list.dto';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FeatureTypeEnum } from '../../../../shared/enum';

@Component({
  selector: 'app-enterprise-list',
  templateUrl: './enterprise-list.component.html',
  styleUrls: ['./enterprise-list.component.scss']
})
export class EnterpriseListComponent implements OnInit {
  searchText = "";
  userData: TokenData;
  pageLimit = 10;
  totalPages = 0;
  currentPage = 0;
  enterpriseList: Enterprise[] = [];
  isLoading = 0;
  total = 0;
  paginationText = "0-0 of 0";
  tooltipComponent = null;
  isFiltered = false;
  isFilterOpen = false;
  isFilterOn = false;
  searchStatus = '';
  featureList = [];
  searchFeatures: { item_id: FeatureTypeEnum, item_text: string }[] = [];
  searchSnapSupport = '';
  snapSupportOptions = [];
  searchEnterpriseSupport = '';
  enterpriseSupportOptions = [];
  unlimited = 8640000000000;

  constructor(
    private adminService: AdminService,
    public router: Router,
    private tooltipService: TooltipService,
    private dialogModalService: DialogModalService,
    private commonService: CommonService,
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
    this.getEnterpriseList();
    this.getFeatures();
    this.getUniqueEnterpriseSupport();
    this.getUniqueSnapSupport();
  }

  getEnterpriseList() {
    this.isLoading++;
    this.adminService.getEnterprise(this.getQueryParam()).subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.enterpriseList = res.data.enterpriseList;
        this.setPage(res.data);
      }
    }, err => {
      this.isLoading--;
    });
  }


  setPage(data: GetEnterpriseListDto) {
    this.total = data.total;
    this.totalPages = data.totalPages;
    const max = (this.currentPage + 1) * this.pageLimit;
    this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${this.total < max ? this.total : max} of ${this.total}`;
  }

  getQueryParam() {
    let query = `size=${this.pageLimit}&page=${this.currentPage}`;
    if (this.searchText) {
      query += `&text=${encodeURIComponent(this.searchText.trim())}`;
    }
    if (this.searchStatus) {
      query += `&status=${this.searchStatus}`;
    }
    if (this.searchFeatures.length > 0) {
      query += `&features=${this.searchFeatures.map((feature) => feature.item_id).join(',')}`;
    }
    if (this.searchSnapSupport) {
      query += `&snapSupport=${encodeURIComponent(this.searchSnapSupport)}`;
    }
    if (this.searchEnterpriseSupport) {
      query += `&enterpriseSupport=${encodeURIComponent(this.searchEnterpriseSupport)}`;
    }
    return query;
  }

  getFeatures() {
    this.isLoading++;
    this.adminService.getFeatureList().subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        const data = res.data as { features: Feature[] };
        this.featureList = data.features.map((feature) => ({ item_id: feature.id, item_text: feature.name }));
      }
    }, err => {
      this.isLoading--;
    });
  }

  getDropDownSettings(countShowElements: number): IDropdownSettings {
    return {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: countShowElements,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
  }

  getUniqueSnapSupport() {
    this.isLoading++;
    this.adminService.getUniqueEnterpriseSupport('support=SNAP').subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.snapSupportOptions = res.data.supportList;
      }
    }, err => {
      this.isLoading--;
    });
  }

  getUniqueEnterpriseSupport() {
    this.isLoading++;
    this.adminService.getUniqueEnterpriseSupport('support=ENTERPRISE').subscribe((res) => {
      this.isLoading--;
      if (res['message'] == 'Success') {
        this.enterpriseSupportOptions = res.data.supportList;
      }
    }, err => {
      this.isLoading--;
    });
  }

  changePageLimit() {
    this.currentPage = 0;
    this.getEnterpriseList();
  }

  changePage(direction: number) {
    this.currentPage += direction;
    this.getEnterpriseList();
  }

  resetPage() {
    this.isFiltered = false;
    this.isFilterOn = false;
    this.searchText = '';
    this.searchStatus = '';
    this.searchFeatures = [];
    this.searchEnterpriseSupport = '';
    this.searchSnapSupport = '';
  }

  clearFilters() {
    this.resetPage();
    this.getEnterpriseList();
  }

  openConfirmDelete(enterpriseId: string) {
    this.dialogModalService.openConfirmationDialog('deleteEnterprise', () => this.deleteEnterprise(enterpriseId));
  }

  searchChange() {
    this.isFiltered = this.searchText ? true : false;
    this.getEnterpriseList();
  }

  openFilters() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  applyFilters() {
    this.isFilterOpen = false;
    this.isFilterOn = this.searchStatus !== '' ||
      this.searchFeatures.length > 0 ||
      this.searchEnterpriseSupport !== '' ||
      this.searchSnapSupport !== '';
    this.isFiltered = this.searchText !== '' || this.isFilterOn;
    this.getEnterpriseList();
  }

  openEdit(enterpriseId: string) {
    this.navigatePage('/admin/enterprises/update-enterprise', { id: enterpriseId });
  }

  navigatePage(path: string, query?: Record<string, string>) {
    this.router.navigate([path], { queryParams: query });
  }

  changeEnterpriseStatus(enterprise: Enterprise) {
    this.isLoading++;
    const body = {
      name: enterprise.name,
      status: enterprise.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
      id: enterprise.id
    };
    this.adminService.updateEnterprise(body).subscribe((res) => {
      this.isLoading--;
      if (res.statusCode === 200) {
        this.getEnterpriseList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  getEnterpriseStatus(enterprise: Enterprise): string {
    if (enterprise.status === 'DISABLED') return enterprise.status;
    
    const hasStarted = enterprise.subscription?.expiration?.startAt && enterprise.subscription?.expiration.startAt * 1000 <= new Date().getTime();
    const isExpired = enterprise.subscription?.expiration?.endAt && enterprise.subscription?.expiration.endAt * 1000 <= new Date().getTime();
    return !hasStarted ? 'INACTIVE' : isExpired ? 'EXPIRED' : (enterprise.status || 'ACTIVE');
  }

  deleteEnterprise(enterpriseId: string) {
    this.isLoading++;
    this.adminService.deleteEnterprise(enterpriseId).subscribe((res) => {
      this.isLoading--;
      if (res.statusCode === 200) {
        this.getEnterpriseList();
      }
    }, err => {
      this.isLoading--;
    });
  }

  displaySupport(enterprise: Enterprise, supportType: 'ENTERPRISE' | 'SNAP'): string {
    const contact = enterprise.contacts?.find((contact) => contact.type === supportType);
    if (contact) {
      return `${contact.name} ${contact.email}`;
    }
    return '';
  }

  getSupportUser(enterprise: Enterprise, supportType: 'ENTERPRISE' | 'SNAP'): { name: string, logo: string } {
    const contact = enterprise.contacts?.find((contact) => contact.type === supportType);
    if (contact) {
      return { name: contact.name, logo: contact.logo };
    }
    return { name: '', logo: '' };
  }
}
