import { Component, ElementRef, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../../shared/common.service';
import { EventEmitter } from '@angular/core';
import { TooltipService } from '../../../shared/tooltip.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Booking, DeviceBooking } from '../interfaces';
import { BookingManagerService } from '../booking-manager.service';
import { Subscription } from 'rxjs';
import { DialogModalService } from '../../../shared/dialog-modal.service';

@Component({
    selector: 'app-booking-scheduler',
    templateUrl: './booking-scheduler.component.html',
    styleUrls: ['./booking-scheduler.component.scss']
})
export class BookingSchedulerComponent implements OnInit, OnDestroy {
    tooltipComponent = null;
    bookings: DeviceBooking[] = [];
    bookingSubscription: Subscription;
    selectedDevices = [];
    selectedDevicesSubscription: Subscription;
    selectedDate = new Date();
    selectedDateSubscription: Subscription;

    constructor(
        private commonService: CommonService,
        private tooltipService: TooltipService,
        public bookingService: BookingManagerService,
        private dialogModalService: DialogModalService
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
        this.bookingSubscription = this.bookingService.bookings$.subscribe((list) => this.bookings = list);
        this.selectedDevicesSubscription = this.bookingService.selectedDevices$.subscribe((devices) => {
            this.selectedDevices = devices;
            this.scrollToClosest();
        });
        this.selectedDateSubscription = this.bookingService.selectedDate$.subscribe((date) => {
            this.selectedDate = date;
            this.scrollToClosest();
        });
    }

    @Output() selectSlot = new EventEmitter();
    @ViewChild('scheduler') scheduler: ElementRef<HTMLElement>;
    @ViewChild('drawer') drawer: MatDrawer;

    isDeviceInfoShown = [];
    hours = Array.from(Array(24)).map((_x, i) => i);

    ngOnInit(): void {
        this.isDeviceInfoShown = this.selectedDevices.map((_d) => false);
    }

    ngOnDestroy(): void {
        this.bookingSubscription.unsubscribe();
        this.selectedDevicesSubscription.unsubscribe();
        this.selectedDateSubscription.unsubscribe();
    }

    scrollToClosest() {
        if (this.checkToday()) {
            const hour = this.bookingService.currentDate.getHours();
            setTimeout(() => {
                const closestSlot = document.getElementById(`${hour - 1}-${this.selectedDevices[0]?._id}`);
                closestSlot?.scrollIntoView();
            }, 100);
        } else {
            setTimeout(() => {
                const bookedSlots = document.getElementsByClassName('self_booked');
                this.scheduler.nativeElement.scrollTo(0, bookedSlots.length > 0 ? bookedSlots.item(0)['offsetTop'] - 170 : 0);
            }, 100);
        }
    }

    checkToday(): boolean {
        return this.selectedDate.getDate() === this.bookingService.currentDate.getDate()
            && this.selectedDate.getMonth() === this.bookingService.currentDate.getMonth()
            && this.selectedDate.getFullYear() === this.bookingService.currentDate.getFullYear();
    }

    checkMaxDate(): boolean {
        return this.selectedDate.getDate() === this.bookingService.maxDate.getDate()
            && this.selectedDate.getMonth() === this.bookingService.maxDate.getMonth()
            && this.selectedDate.getFullYear() === this.bookingService.maxDate.getFullYear();
    }

    changeDay(direction: number) {
        this.bookingService.setSelectedDate(new Date(this.selectedDate.getTime() + 86400000 * direction));
        this.bookingService.updateBookingList();
    }

    displayDay(): string {
        return this.selectedDate.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' }).toUpperCase();
    }

    removeDevice(index: number) {
        this.selectedDevices.splice(index, 1);
        this.isDeviceInfoShown = this.selectedDevices.map((_d) => false);
    }

    showDeviceInfo(index: number) {
        this.isDeviceInfoShown[index] = !this.isDeviceInfoShown[index];
    }

    checkPartialHour(hour: number, device: any): boolean {
        const firstBooking = this.findBooking(hour, device, 1);
        const secondBooking = this.findBooking(hour, device, 2);
        if (firstBooking && firstBooking?.id === secondBooking?.id) return false;
        return true;
    }

    selectTimeSlot(hour: number, deviceIndex: number, half: number) {
        const booking = this.findBooking(hour, this.selectedDevices[deviceIndex], half);
        this.bookingService.selectedTimeSlot = { hour, device: deviceIndex, half, booking };
        if (!booking) {
            this.selectSlot.emit();
        }
    }

    findBooking(hour: number, device: any, half: number): Booking {
        const date = new Date(this.selectedDate.getTime());
        date.setHours(hour);
        date.setMinutes(half > 1 ? 30 : 0);
        date.setSeconds(0);
        const time = Math.round(date.getTime() / 1000);
        const deviceBooking = this.bookings.find((d) => d.deviceId === device._id);
        return deviceBooking?.bookings.find((booking) => {
            return booking.bookedFrom <= time && booking.bookedUntil > time + 59;
        });
    }

    checkSelfBooked(hour: number, device: any, half: number): boolean {
        const booking = this.findBooking(hour, device, half);
        const token = this.commonService.getUser();
        return booking?.userId === token.userId;
    }

    checkOtherBooked(hour: number, device: any, half: number): boolean {
        const booking = this.findBooking(hour, device, half);
        const token = this.commonService.getUser();
        return booking && booking.userId !== token.userId;
    }

    displayBookingDuration(booking: Booking): string {
        const duration = booking.bookedUntil - booking.bookedFrom;
        const hours = Math.floor(duration / 3600);
        const mins = Math.floor((duration - (hours * 3600)) / 60);
        return `${hours}h ${mins}m`;
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
    }

    toggleOwnBooking($event) {
        this.bookingService.toggleOwnBooking($event.target.checked);
    }

    editBooking($event, hour: number, deviceIndex: number, half: number) {
        $event.stopPropagation();
        const booking = this.findBooking(hour, this.selectedDevices[deviceIndex], half);
        this.bookingService.selectedTimeSlot = { hour, device: deviceIndex, half, booking };
        if (booking) {
            this.selectSlot.emit();
        }
    }

    openDeleteModal($event, hour: number, device: any, half: number) {
        $event.stopPropagation();
        const booking = this.findBooking(hour, device, half);
        if (booking) {
            this.dialogModalService.openConfirmationDialog('deleteBooking', () => this.deleteBooking(booking));
        }
    }

    deleteBooking(booking: Booking) {
        this.bookingService.startLoading();
        this.bookingService.deleteBookings([booking.id]).subscribe((res) => {
            this.bookingService.stopLoading();
            if (res.statusCode === 200) {
                this.bookingService.updateBookingList();
            }
        }, () => this.bookingService.stopLoading());
    }
}
