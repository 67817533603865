export enum FeatureTypeEnum {
    SNAP_API = 'SNAP_API',
    TELEPHONY_TEST = 'TELEPHONY_TEST',
    APPIUM = 'APPIUM',
    RNP = 'RNP',
    MT = 'MT',
    CLOUD = 'CLOUD',
    BOOKING = 'BOOKING',
    DEBUG = 'DEBUG',
    DEVICE_MANIPULATION = 'DEVICE_MANIPULATION'
}