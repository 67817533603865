import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DeviceService} from '../../view-device/device.service';
import {CommonService} from '../../../shared/common.service';
import {TooltipService} from '../../../shared/tooltip.service';
import {DevicehomeService} from '../../device-homepage/devicehome.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {stateTooltip} from '../../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
import {MatDrawer} from '@angular/material/sidenav';
import {ManualTestService} from '../manual-test.service';


@Component({
    selector: 'app-manual-test-view',
    templateUrl: './manual-test-view.component.html',
    styleUrls: ['./manual-test-view.component.scss'],
})
export class ManualTestViewComponent implements OnInit {

    isSelectDeviceMode: boolean;
    deviceDetail: any;
    ignoreTyping = false;
    isLoading = false;
    AllDeviceIos = true;
    AllDeviceAnd = true;
    availableIos = 0;
    availableAndroid = 0;
    searchText = '';
    deviceList = [];
    deviceListTemp = [];
    searchLab = '';
    searchLocation = '';
    selectedBookedFilter = 'available';
    bookingFilterList = [];
    filterList = [];
    labListDropdown = [];
    locationListDropdown = [];
    isGridView = true;
    searchText1 = '';
    CurrentDeviceIos = true;
    CurrentDeviceAnd = true;
    currentIos = 0;
    currentAndroid = 0;
    currentDeviceList = [];
    currentDeviceListTemp = [];
    deviceState = '';
    selectedDevice: any;
    testId = '';
    shouldAdaptCoords = false;
    allDeviceList = [];
    userData: any;
    serialNumber: string;
    tooltipComponent = null;

    @ViewChild('drawer') drawer: MatDrawer;
    constructor(
        private deviceService: DeviceService,
        private commonService: CommonService,
        private tooltipService: TooltipService,
        private deviceHome: DevicehomeService,
        private _snackBar: MatSnackBar,
        protected manualTestService: ManualTestService,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.SearchDevice();
    }

    SearchDevice(): void {
        this.deviceHome.availableDevice('showAll=true').subscribe((res) => {
            if (res['message'] === 'Success') {
                this.allDeviceList = res.data.deviceList;
                const unusedDeviceList = res.data.deviceList.filter((device) => !device.testingType
                    && device.deviceCommunicationStatus.toLowerCase() !== 'remote-testing');
                this.deviceList = unusedDeviceList;
                this.availableAndroid = 0;
                this.availableIos = 0;
                this.AllDeviceAnd = true;
                this.AllDeviceIos = true;
                this.labListDropdown = [];
                let labUrl = [];
                this.deviceList.forEach((element) => {
                    element['showId'] = false;
                    if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
                        element['deviceStateCode'] = 'TESTING';
                    }
                    if (element['labName']) {
                        this.labListDropdown.push(element['labName']);
                        labUrl.push(element['labDomain']);
                    }
                    if (element['oem'] === 'Apple') {
                        this.availableIos = this.availableIos + 1;
                    } else {
                        this.availableAndroid = this.availableAndroid + 1;
                    }
                    if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
                        element['deviceStateCode'] = 'DISCONNECTED';
                    }
                    if (!element['deviceStateCode'] || (element['deviceStateCode']
                        && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
                        element['deviceStateCode'] = 'UNKNOWN';
                    }
                    if (element['deviceState'].toLowerCase() === 'offline') {
                        element['isResetBtn'] = true;
                        if (element['isBooked'] && element['bookedByUserId'] === this.userData.userId) {
                            element['isResetBtn'] = true;
                        }
                        if (element['isBooked'] && element['bookedByUserId'] !== this.userData.userId) {
                            element['isResetBtn'] = false;
                        }
                    }
                    element['isResetting'] = false;
                    element['resetCount'] = 0;
                    element['isBook'] = false;
                    element['searchEndDate'] = '';
                    element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
                });
                localStorage.setItem('AllDevice', JSON.stringify(this.deviceList));
                this.deviceListTemp = unusedDeviceList;
                this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
                this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
                this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
                labUrl = labUrl.filter(this.onlyUnique);
                if (labUrl.length > 0) {
                    this.commonService.setStorage('_domin', labUrl[0]);
                }
                if (res.data.deviceList.length === 0) {
                    this.deviceList = [];
                    return this._snackBar.open('No device available!', '', {
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        panelClass: ['failure']
                    });
                }
                this.SearchDeviceLocal();
            }
        });
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    releaseDevice() {
        this.isLoading = true;
        this.deviceHome.releaseDevice({ deviceBookingId: this.manualTestService.selectedManualTestDevice.bookingId }).subscribe(() => {
            this.manualTestService.selectedManualTestDevice = null;
            this.SearchDevice();
            this.isLoading = false;
        }, () => this.isLoading = false);
    }

    tabFilterAll(value) {
        if (value === 'AllDeviceIos') {
            this.AllDeviceIos = !this.AllDeviceIos;
            if (!this.AllDeviceIos && !this.AllDeviceAnd) {
                this.AllDeviceAnd = true;
                this.AllDeviceIos = false;
            }
        }
        if (value === 'AllDeviceAnd') {
            this.AllDeviceAnd = !this.AllDeviceAnd;
            if (!this.AllDeviceIos && !this.AllDeviceAnd) {
                this.AllDeviceAnd = false;
                this.AllDeviceIos = true;
            }
        }
        this.SearchDeviceLocal();
    }

    groupBy(objectArray, property, filterProperty?: string, filterValue?: string): any {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];

            if (filterProperty && filterValue) {
                if (obj[filterProperty] !== filterValue) {
                    return acc;
                }
            }

            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for the given key's value
            acc[key].push(obj);
            return acc;
        }, {});
    }

    countAmountDevices() {
        this.availableAndroid = 0;
        this.availableIos = 0;
        this.deviceList.forEach(element => {
            if (element['oem'] === 'Apple') {
                this.availableIos = this.availableIos + 1;
            } else {
                this.availableAndroid = this.availableAndroid + 1;
            }
        });
        if (this.searchText) {
            this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
        } else {
            if (this.searchLab) {
                const filteredByLabDeviceList = this.deviceListTemp.filter(device => device.labName === this.searchLab);
                this.bookingFilterList = this.groupBy(filteredByLabDeviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
            } else {
                this.bookingFilterList = this.groupBy(this.deviceListTemp, 'isBooked', 'deviceStateCode', 'ONLINE');
            }
        }
    }

    searchByText(list) {
        const keys = ['serialNumber', 'modelName', 'osVersion'];
        const values = [];
        if (this.searchText) {
            values.push(this.searchText.toLowerCase());
        }
        const regex = new RegExp(values.join('|'));
        this.deviceList = list.filter(e => keys.some(k => regex.test(e[k]?.toLowerCase())));
    }

    SearchDeviceLocal(): void {
        this.searchByText(this.deviceListTemp);
        if (this.searchLab) {
            this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
        }
        if (this.searchLocation) {
            this.deviceList = this.deviceList.filter(device => device.labLocation === this.searchLocation);
        }

        if (!this.AllDeviceAnd && this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem === 'Apple';
            });
        }
        if (this.AllDeviceAnd && !this.AllDeviceIos) {
            this.deviceList = this.deviceList.filter(element => {
                return element.oem !== 'Apple';
            });
        }
        if (this.selectedBookedFilter === 'booked') {
            this.deviceList = this.deviceList.filter(device => {
                return device.isBooked;
            });
        }
        if (this.selectedBookedFilter === 'available') {
            this.deviceList = this.deviceList.filter(device => {
                return !device.isBooked && device.deviceStateCode === 'ONLINE';
            });
        }
        this.countAmountDevices();
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
    }

    toggleView() {
        this.isGridView = !this.isGridView;
    }

    SearchDeviceLocal1(): void {
        const values = [];
        if (this.searchText1) {
            values.push(this.searchText1.toLowerCase());
        }
        const keys = ['serialNumber', 'modelName1', 'osVersion1', 'oem1'];
        const regex = new RegExp(values.join('|'));
        this.currentDeviceList = this.currentDeviceListTemp.filter(e => keys.some(k => regex.test(e[k].toLowerCase())));

        if (!this.CurrentDeviceAnd && this.CurrentDeviceIos) {
            this.currentDeviceList = this.currentDeviceList.filter(element => {
                return element.oem1 === 'Apple';
            });
        }
        if (this.CurrentDeviceAnd && !this.CurrentDeviceIos) {
            this.currentDeviceList = this.currentDeviceList.filter(element => {
                return element.oem1 !== 'Apple';
            });
        }
        this.currentAndroid = 0;
        this.currentIos = 0;
        this.currentDeviceList.forEach(element => {
            if (element['oem1'] === 'Apple') {
                this.currentIos = this.currentIos + 1;
            } else {
                this.currentAndroid = this.currentAndroid + 1;
            }
        });
        this.filterList = this.groupBy(this.deviceList, 'deviceStateCode');
        this.bookingFilterList = this.groupBy(this.deviceList, 'isBooked', 'deviceStateCode', 'ONLINE');
    }

    filterByBooked(type: boolean): void {
        if (this.selectedBookedFilter.length
            && ((this.selectedBookedFilter === 'available' && !type) || (this.selectedBookedFilter === 'booked' && type))) {
            this.selectedBookedFilter = '';
            if (this.searchLab.length || this.searchLocation.length) {
                this.SearchDeviceLocal();
            } else if (this.searchText.length) {
                this.SearchDeviceLocal1();
            } else {
                this.deviceList = this.deviceListTemp;
                this.localFilter();
            }
        } else {
            this.selectedBookedFilter = type ? 'booked' : 'available';
            this.localFilter();
        }
    }

    localFilter(): void {
        this.deviceList = this.deviceListTemp.filter(device => {
            if ((this.deviceState && device.deviceStateCode === this.deviceState || !this.deviceState.length) &&
                ((this.selectedBookedFilter === 'booked' && device.isBooked)
                    ||
                    (this.selectedBookedFilter === 'available' && !device.isBooked && device.deviceStateCode === 'ONLINE')
                    || !this.selectedBookedFilter.length)) {
                return device;
            }
        });
        if (this.searchText) {
            this.searchByText(this.deviceList);
        }
        if (this.searchLab) {
            this.deviceList = this.deviceList.filter(device => device.labName === this.searchLab);
        }
        this.countAmountDevices();
    }

    applyFilter(value): void {
        if (value === this.deviceState) {
            this.deviceState = '';
            if (this.searchLab.length || this.searchLocation.length) {
                this.SearchDeviceLocal();
            } else if (this.searchText.length) {
                this.SearchDeviceLocal1();
            } else {
                this.deviceList = this.deviceListTemp;
                this.localFilter();
            }
        } else {
            this.deviceState = value;
            this.localFilter();
        }
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
    }

    concatScreenResolution(device): string {
        return device.screenWidth + 'x' + device.screenHeight;
    }

    checkForAdaptCoords(): void {
        this.shouldAdaptCoords = false;
    }

    selectDevice(item) {
        this.selectedDevice = item;
        if (this.testId) {
            this.checkForAdaptCoords();
        }
    }

    getDeviceStatus(data: any): string {
        const state = data.deviceState;
        const stateCode = data.deviceStateCode;
        const commStatus = data.deviceCommunicationStatus;
        let displayed = 'unknown';
        if (state) {
            if (state.toLowerCase() === 'online') {
                if (stateCode.toLowerCase() === 'online') {
                    if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
                        displayed = 'testing';
                    } else {
                        displayed = 'online';
                    }
                } else {
                    if (stateTooltip[stateCode.toLowerCase()]) {
                        displayed = stateCode.toLowerCase();
                    }
                }
            } else if (state.toLowerCase() === 'offline') {
                if (stateCode && stateCode !== '') {
                    if (stateCode.toLowerCase() === 'removed') {
                        displayed = 'disconnected';
                    } else {
                        if (stateTooltip[stateCode.toLowerCase()]) {
                            displayed = stateCode.toLowerCase();
                        }
                    }
                }
            } else if (state.toLowerCase() === 'blocked') {
                displayed = state.toLowerCase();
            }
        }
        return displayed;
    }

    closeReset(index, type) {
        if (type === 'ALL') {
            this.deviceList[index]['resetCount'] = 0;
            this.deviceList[index]['isResetting'] = false;
        } else {
            this.currentDeviceList[index]['resetCount'] = 0;
            this.currentDeviceList[index]['isResetting'] = false;
        }
    }

    showOfflineTooltip(selectedDevice: any) {
        return this.deviceService.showOfflineTooltip(selectedDevice);
    }

    checkSelfBooked(item): boolean {
        return item.isBooked && item.bookedByUserId === this.userData.userId;
    }

    getStatusTooltip(data): string {
        let status = this.getDeviceStatus(data);
        if (status === 'online') {
            status = 'available';
        }
        if (status === 'testing') {
            status = 'under test';
        }
        return status.charAt(0).toUpperCase() + status.slice(1);
    }

    checkIfDeviceUnavailable() {
        if (!this.selectedDevice) {
            return false;
        }
        const status = this.deviceService.getDeviceStatus(this.selectedDevice);
        const notOnline = status !== 'online' && status !== 'testing';
        const isBooked = this.selectedDevice.isBooked && this.selectedDevice.bookedByUserId !== this.userData.userId;

        return notOnline || isBooked;
    }

    selectDevice1() {
        this.manualTestService.changeSelectDeviceMode();
        if (this.manualTestService.selectedManualTestDevice) {
            this.isLoading = true;
            this.deviceHome.releaseDevice({ deviceBookingId: this.manualTestService.selectedManualTestDevice.bookingId }).subscribe((res) => {
                this.isLoading = false;
                this.bookDevice();
            }, () => {
                this.isLoading = false;
                this.manualTestService.changeSelectDeviceMode();
                this.selectedDevice = null;
            });
            return;
        }
        this.bookDevice();
    }

    bookDevice() {
        if (this.selectedDevice.bookedByUserId === this.userData.userId
            && this.deviceService.getDeviceStatus(this.selectedDevice) === 'testing') {
        } else {
            this.isLoading = true;
            this.deviceHome.bookDeviceForTesting([{ deviceId: this.selectedDevice._id }], '', false, 'mt').subscribe((res) => {
                this.isLoading = false;
                if (res.statusCode === 200) {
                    const bookingId = res.data.bookingIds[0];
                    this.manualTestService.selectedManualTestDevice = this.selectedDevice;
                    this.manualTestService.selectedManualTestDevice.bookingId = bookingId;
                    this.manualTestService.changeDeviceDetail(this.manualTestService.selectedManualTestDevice);
                    this.serialNumber = this.manualTestService.selectedManualTestDevice.serialNumber;
                    this.selectedDevice = null;
                    this.getAllDevices(this.manualTestService.selectedManualTestDevice.serialNumber);
                    this.SearchDevice();
                    this.isSelectDeviceMode = false;
                } else {
                    this.isLoading = false;
                    this.isSelectDeviceMode = false;
                }
            }, () => {
                this.isLoading = false;
                this.isSelectDeviceMode = false;
                this.selectedDevice = null;
            });
        }
    }

    getAllDevices(sn: string): void {
        this.isLoading = true;
        const request = {
            'serialNumber': sn,
            'screenShot': false
        };
        this.deviceService.getDeviceDetails(request).subscribe((res) => {
            this.isLoading = false;
            if (res.body['data']['tool_Result'].toUpperCase() === 'PASS') {
                const detail = res.body.data.tool_Extra;
                if (this.manualTestService.selectedManualTestDevice) {
                    if (this.manualTestService.selectedManualTestDevice.labDomain) {
                        detail.labDomain = this.manualTestService.selectedManualTestDevice.labDomain;
                    }
                    if (this.manualTestService.selectedManualTestDevice.serialNumber) {
                        detail.serialNumber = this.manualTestService.selectedManualTestDevice.serialNumber;
                    }
                    if (this.manualTestService.selectedManualTestDevice.oem) {
                        detail.oem = this.manualTestService.selectedManualTestDevice.oem;
                    }
                }
                // value assignment is transferred to screencast component
                this.deviceDetail = detail;
            }
        }, err => {
            this.isLoading = false;
        });
    }

    updateIgnoreTyping(v: boolean): void {
        this.ignoreTyping = v;
    }
}
