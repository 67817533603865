import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { TokenData } from '../../../../shared/interfaces';
import { CommonService } from '../../../../shared/common.service';
import { GetUserListDto } from '../../../../shared/dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-update-support-user',
    templateUrl: './update-support-user.component.html',
    styleUrls: ['./update-support-user.component.scss']
})
export class UpdateSupportUserComponent implements OnInit {
    userForm: FormGroup;
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    isLoading = false;
    userData: TokenData;
    expirationDate = 0;
    minDate = new Date();
    showError = false;

    constructor(private fb: FormBuilder,
        private adminService: AdminService,
        private router: Router,
        private commonService: CommonService,
        private route: ActivatedRoute
    ) {
        this.minDate.setDate(this.minDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.userData = this.commonService.getUser();
        this.userForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
        });

        this.getUserInfo();
    }

    getUserInfo() {
        const id = this.route.snapshot.queryParamMap.get('id');
        if (!id) {
            this.showError = true;
        } else {
            this.isLoading = true;
            const query = `userId=${id}`;
            this.adminService.getUserListApi(query).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success' && res.data['userList']?.length > 0) {
                    const data = res.data as GetUserListDto;
                    const userInfo = data.userList[0];

                    this.userForm.setValue({
                        firstName: userInfo.firstName || '',
                        lastName: userInfo.lastName || '',
                        email: userInfo.email || '',
                    });

                    this.expirationDate = userInfo.expirationDate * 1000;
                } else {
                    this.showError = true;
                }
            }, err => {
                this.isLoading = false;
                this.showError = true;
            });
        }
    }

    updateUser() {
        if (this.userForm.valid) {
            const {
                firstName,
                lastName,
                email
            } = this.userForm.value;

            const request = {
                firstName,
                lastName,
                email,
                expirationDate: this.expirationDate
            };

            this.isLoading = true;
            this.adminService.updateUser(request, this.route.snapshot.queryParamMap.get('id')).subscribe((res) => {
                this.isLoading = false;
                if (res['message'] == 'Success') {
                    this.router.navigate(['admin/users']);
                }
            }, err => {
                this.isLoading = false;
            });
        }
    }

    clearExpirationDate(event: Event) {
        event.stopPropagation();
        this.expirationDate = 0;
    }

    updateExpirationDate($event: MatDatepickerInputEvent<Date>) {
        this.expirationDate = new Date($event.value).setHours(23, 59, 59);
    }

    displayExpirationDate() {
        const date = new Date(this.expirationDate);
        return `Expiration Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
}

