import { MomtCallService } from '../momt-call.service';
import { GetIterationListDto, NewTelephonyDto } from '../dto';
import {MomtTest, MomtTestIteration, MomtTestStatus, TelephonyActionStatus} from '../interfaces';
import { ApiResponseDto } from '../../../shared/dto';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/common.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { interval } from 'rxjs/internal/observable/interval';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from '../../../guards/pending-changes-guard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { FeatureTypeEnum } from '../../../shared/enum';
import { MoMtDeviceInfo, TelephonyTestCase, TelephonyTestType } from '../interfaces/momt-test.interface';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SpeedTestAction, SpeedTestDto} from '../dto/new-momt.dto';

interface DeviceSelectionCommand {
    shouldDiscard?: boolean;
    shouldBook?: boolean;
    devices?: MoMtDeviceInfo[];
    subject?: Subject<boolean>;
}

@Component({
    selector: 'momt-new-test',
    templateUrl: './momt-new-test.component.html',
    styleUrls: ['./momt-new-test.component.scss']
})

export class MomtNewTestComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    testRepeatCount = '1';
    iterationDelay = '3';
    artifactSelection = {};
    tests = [];
    isLoading = 0;

    selectedDevices: Record<string, any> = {};

    isTestRunning = false;
    testInfo: MomtTest;
    iterations: MomtTestIteration[] = [];
    pageLimit = 10;
    currentPage = 0;
    totalPage = 0;

    pollingInterval: Subscription;
    tooltipComponent = null;

    isTelephonyAvailable = false;
    isCloudAvailable = false;
    selectedTestCase: TelephonyTestCase;
    ignoreTyping = false;
    paginationText = '0-0 of 0';
    totalIterations = 0;
    setDevices: Subject<DeviceSelectionCommand> = new Subject();
    isCustomizeMode = false;
    paramsForm: FormGroup;
    speedTestForm: FormGroup;
    currentPlayStart = 0;
    completedIterations = 0;
    serversList = [];
    filteredServerList = [];
    selectedServer = null;
    progressBarValue = 0;

    constructor(public common: CommonService,
        private momtCallService: MomtCallService,
        private tooltipService: TooltipService,
        private dialogModalService: DialogModalService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private fb: FormBuilder,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit() {
        this.isTelephonyAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        this.isCloudAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.CLOUD]);

        if (this.isTelephonyAvailable) {
            this.getTestCases();

            const type = this.route.snapshot.queryParamMap.get('type');
            if (type === TelephonyTestType.SPEED_TEST) {
                this.momtCallService.getIPerfServers().subscribe((result) => {
                    if (result.data?.servers?.length) {
                        this.serversList = result.data.servers;
                        this.filterServerList();
                    }
                });
            }

            this.speedTestForm = this.fb.group({
                udp: [false, [Validators.required]],
                time: [0],
                bytes: [0],
                length: [128, [Validators.required]],
                reverse: [false, [Validators.required]],
                mss: [1448],
                nodelay: [false],
                version: [4, [Validators.required]],
                server: ['', [Validators.required]],
                port: [''],
                limit: [false, [Validators.required]],
                window: [17520],
                bandwidth: [0, [Validators.required, Validators.min(0)]]
            });
        }
    }

    get udp(): AbstractControl { return <AbstractControl>this.speedTestForm.get('udp'); }
    get limit(): AbstractControl { return <AbstractControl>this.speedTestForm.get('limit'); }
    get window(): AbstractControl { return <AbstractControl>this.speedTestForm.get('window'); }
    get length(): AbstractControl { return <AbstractControl>this.speedTestForm.get('length'); }
    get time(): AbstractControl { return <AbstractControl>this.speedTestForm.get('time'); }
    get bytes(): AbstractControl { return <AbstractControl>this.speedTestForm.get('bytes'); }
    get version(): AbstractControl { return <AbstractControl>this.speedTestForm.get('version'); }
    get bandwidth(): AbstractControl { return <AbstractControl>this.speedTestForm.get('bandwidth'); }
    get server(): AbstractControl { return <AbstractControl>this.speedTestForm.get('server'); }
    get port(): AbstractControl { return <AbstractControl>this.speedTestForm.get('port'); }

    ngOnDestroy(): void {
        if (this.pollingInterval) {
            this.pollingInterval.unsubscribe();
        }
    }

    canDeactivate() {
        return this.isTestRunning || !this.isAnyDeviceSelected();
    }

    showWarningModal() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                header: 'Save or discard MO/MT call test',
                content: 'In case test is discarded, record will be deleted permanently with all associated artifacts. This operation can\'t be canceled',
                accept: 'Save',
                decline: 'Discard',
                cancel: 'Cancel',
            },
        });
        const subject = new Subject<boolean>();

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'accept') {
                const newTestForm = this.prepareNewTestDto();

                this.isLoading++;
                const id = this.route.snapshot.queryParamMap.get('id');

                if (id) {
                    this.momtCallService.updateTest(id, newTestForm).subscribe((res) => {
                        this.isLoading--;
                        if (res.statusCode === 200) {
                            this.releaseDevices(subject);
                        }
                    }, () => this.isLoading--);
                } else {
                    this.momtCallService.saveTest(newTestForm).subscribe((res) => {
                        this.isLoading--;
                        if (res.statusCode === 200) {
                            this.releaseDevices(subject);
                        }
                    }, () => this.isLoading--);
                }
            } else if (result === 'decline') {
                if (!this.isAnyDeviceSelected()) {
                    subject.next(true);
                    subject.complete();
                }
                this.releaseDevices(subject);
            }
        });
        return subject.asObservable();
    }

    releaseDevices(subject?: Subject<boolean>) {
        const command = { shouldDiscard: true, subject };
        this.setDevices.next(command);
    }

    prepareNewTestDto(): NewTelephonyDto {
        const repeat = parseInt(this.testRepeatCount);
        const delay = parseInt(this.iterationDelay);

        const devices = Object.values(this.selectedDevices).map((device) => {
            const testCaseDevice = this.selectedTestCase.devices.find((d) => d.index === device.index);
            const phoneProp = device.deviceProperties
                ? device.deviceProperties.find((prop) => prop.name === 'phone')
                : '';
            return {
                ...testCaseDevice,
                sn: device.serialNumber,
                phone: phoneProp.value
            };
        });
        const parameters = this.selectedTestCase.parameters?.map((param) => {
            return { ...param, value: this.paramsForm.value[param.name] };
        });

        return {
            repeatCount: isNaN(repeat) ? 1 : repeat,
            iterationDelay: isNaN(delay) ? 0 : delay,
            startTimeout: this.selectedTestCase.startTimeout,
            devices: devices,
            type: this.selectedTestCase.type,
            actions: this.selectedTestCase.actions,
            artifactSelection: Object.keys(this.artifactSelection),
            parameters
        };
    }

    saveTest(): void {
        const newTestForm = this.prepareNewTestDto();

        this.isLoading++;
        const id = this.route.snapshot.queryParamMap.get('id');
        this.selectedDevices = {};

        if (id) {
            this.momtCallService.updateTest(id, newTestForm).subscribe((res) => {
                this.isLoading--;
                if (res.statusCode === 200) {
                    this.releaseDevices();
                    this.navigateMoMt('/admin/telephony');
                }
            }, () => this.isLoading--);
        } else {
            this.momtCallService.saveTest(newTestForm).subscribe((res) => {
                this.isLoading--;
                if (res.statusCode === 200) {
                    this.releaseDevices();
                    this.navigateMoMt('/admin/telephony');
                }
            }, () => this.isLoading--);
        }
    }

    prepareSpeedTestDTO(): SpeedTestDto {
        const repeat = parseInt(this.testRepeatCount);
        const delay = parseInt(this.iterationDelay);

        const devices = Object.values(this.selectedDevices).map((device) => {
            const testCaseDevice = this.selectedTestCase.devices.find((d) => d.index === device.index);
            const phoneProp = device.deviceProperties
                ? device.deviceProperties.find((prop) => prop.name === 'phone')
                : '';
            return {
                ...testCaseDevice,
                sn: device.serialNumber,
                phone: phoneProp.value
            };
        });
        const parameters = this.selectedTestCase.parameters?.map((param) => {
            let value;
            if (param.name === 'iperf-host') {
                const port = this.port.value ? `:${this.port.value}` : '';
                value = this.server.value + port;
            }
            if (param.name === 'bandwidth') {
                value = this.speedTestForm.value['bandwidth'].toString();
            }
            return { ...param, value };
        });

        const action: SpeedTestAction = {
            order: 0,
            type: TelephonyTestType.SPEED_TEST,
            data: {
                timestamp: 100000,
                length: this.length.value + 'K',
                udp: this.udp.value,
                reverse: this.speedTestForm.value['reverse'],
                version: this.speedTestForm.value['version'],
                window: this.window.value
            }
        };
        if (this.limit.value) {
            action.data.bytes = this.speedTestForm.value['bytes'];
        } else {
            action.data.time = this.speedTestForm.value['time'];
        }

        if (!this.udp.value) {
            action.data.mss = this.speedTestForm.value['mss'];
            action.data.nodelay = this.speedTestForm.value['nodelay'];
        }

        return {
            repeatCount: isNaN(repeat) ? 1 : repeat,
            iterationDelay: isNaN(delay) ? 0 : delay,
            startTimeout: this.selectedTestCase.startTimeout,
            devices: devices,
            type: this.selectedTestCase.type,
            actions: [action],
            artifactSelection: Object.keys(this.artifactSelection),
            parameters
        };
    }

    startTest(): void {
        const newTestForm =
            this.selectedTestCase.type === TelephonyTestType.SPEED_TEST ? this.prepareSpeedTestDTO() : this.prepareNewTestDto();
        const id = this.route.snapshot.queryParamMap.get('id');
        this.isLoading++;
        if (id) {
            this.momtCallService.startTest(id, newTestForm).subscribe((res: ApiResponseDto<MomtTest>) => {
                this.isLoading--;
                if (res.statusCode === 200) {
                    this.testInfo = res.data as MomtTest;
                    this.iterations = this.testInfo.iterations;
                    this.isTestRunning = true;
                    this.startPolling();
                }
            }, () => this.isLoading--);
        } else {
            this.momtCallService.createAndStartTest(newTestForm).subscribe((res: ApiResponseDto<MomtTest>) => {
                this.isLoading--;
                if (res.statusCode === 200) {
                    this.testInfo = res.data as MomtTest;
                    this.iterations = this.testInfo.iterations;
                    this.isTestRunning = true;
                    this.startPolling();
                }
            }, () => this.isLoading--);
        }
    }

    startPolling() {
        this.pollingInterval = interval(2000).subscribe(() => {
            if (this.testInfo.status !== MomtTestStatus.PROGRESS) {
                this.pollingInterval.unsubscribe();
                this.isTestRunning = false;
                this.releaseDevices();
                this.navigateMoMt('/admin/telephony-report', this.testInfo._id);
                this.selectedDevices = {};
            } else {
                this.getTestInfo();
                this.getIterations();
            }
        });
    }

    getTestInfo() {
        if (this.testInfo.status === MomtTestStatus.PROGRESS) {
            this.momtCallService.getTestInfo(this.testInfo._id).subscribe((res) => {
                if (res.statusCode === 200) {
                    this.testInfo = res.data as MomtTest;
                }
            });
        } else {
            this.pollingInterval?.unsubscribe();
        }
    }

    navigateMoMt(path: string, id?: string) {
        if (id) {
            this.router.navigate(['/admin/telephony-report'], { queryParams: { id: this.testInfo._id } });
        } else {
            this.router.navigate([path]);
        }
    }

    cancelTestCreation() {
        this.releaseDevices();
        this.navigateMoMt('/admin/telephony');
        this.selectedDevices = {};
    }

    getInitialTestInfo(id: string) {
        this.isLoading++;
        this.momtCallService.getTestInfo(id).subscribe((res) => {
            this.isLoading--;
            if (res.statusCode === 200) {
                const response = res.data as MomtTest;
                this.testInfo = response;

                if (response.status === MomtTestStatus.PROGRESS) {
                    this.iterations = this.testInfo.iterations;
                    this.isTestRunning = true;
                    this.startPolling();
                } else {
                    this.iterationDelay = `${response.iterationDelay}`;
                    this.testRepeatCount = `${response.repeatCount}`;
                    response.artifactSelection.forEach((artifact) => this.artifactSelection[artifact] = true);
                    response.parameters?.forEach((param) => {
                        this.paramsForm.controls[param.name].setValue(param.value);
                    });
                    this.router.navigate([], { queryParams: { 'id': null }, queryParamsHandling: 'merge' });
                }
                if (response.devices?.length) {
                    const command = {
                        shouldBook: response.status !== MomtTestStatus.PROGRESS,
                        devices: response.devices
                    };
                    this.setDevices.next(command);
                }
            }
        }, () => this.isLoading--);
    }

    handleArtifactSelection(type: string, checked: boolean) {
        if (checked) {
            this.artifactSelection[type] = checked;
        } else {
            delete this.artifactSelection[type];
        }
    }

    openConfirmCancel() {
        this.dialogModalService.openConfirmationDialog('discardMoMt', () => this.cancelTestCreation());
    }

    cancelTest() {
        this.isLoading++;
        this.momtCallService.cancelTest(this.testInfo._id).subscribe((res) => {
            if (res.statusCode === 200) {
                this.releaseDevices();
                this.navigateMoMt('/admin/telephony-report', this.testInfo._id);
            }
        }, () => this.isLoading--);
    }

    changePageLimit() {
        this.currentPage = 0;
        this.getIterations();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.getIterations();
    }

    getIterations() {
        if (this.testInfo.status === MomtTestStatus.PROGRESS) {
            this.momtCallService.getTestIterations(this.testInfo._id, this.currentPage, this.pageLimit).subscribe((res) => {
                if (res.statusCode === 200) {
                    const result = res.data as GetIterationListDto;
                    this.iterations = result.tests;
                    this.totalPage = result.totalPages;
                    this.totalIterations = result.totalElements;
                    this.completedIterations = Math.round((result.passRate * result.totalElements) / 100) + Math.round((result.failRate * result.totalElements) / 100) + 1;
                    if (this.completedIterations > result.totalElements) {
                        this.completedIterations = result.totalElements;
                    }

                    this.currentPlayStart = result.currentStartTime;
                    this.setPage();
                }
            });
        }
    }

    getTestCases() {
        this.isLoading++;
        this.momtCallService.getTestCaseList('size=20').subscribe((res) => {
            this.isLoading--;
            if (res && res.data) {
                const type = this.route.snapshot.queryParamMap.get('type');
                this.selectedTestCase = res.data.tests.find((testCase) => testCase.type === type);
                if (this.selectedTestCase.parameters) {
                    const formObject = {};

                    this.selectedTestCase.parameters.forEach((parameter) => {
                        const validators = [];
                        if (parameter.constraints?.required) {
                            validators.push(Validators.required);
                        }
                        if (parameter.constraints?.min) {
                            validators.push(Validators.min(parameter.constraints.min));
                        }
                        if (parameter.constraints?.max) {
                            validators.push(Validators.min(parameter.constraints.max));
                        }
                        formObject[parameter.name] = ['', validators];
                    });

                    this.paramsForm = this.fb.group(formObject);
                }
                const id = this.route.snapshot.queryParamMap.get('id');
                if (id) {
                    this.getInitialTestInfo(id);
                }
            }
        }, err => {
            this.isLoading--;
        });
    }

    updateIgnoreTyping(v: boolean): void {
        this.ignoreTyping = v;
    }

    setPage() {
        const total = this.totalIterations;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
    }

    selectDevices(devices: any) {
        this.selectedDevices = devices;
    }

    switchCustomizeMode(mode: boolean) {
        this.isCustomizeMode = mode;
    }

    isAnyDeviceSelected() {
        return JSON.stringify(this.selectedDevices) !== JSON.stringify({});
    }

    checkTestReady() {
        if (this.selectedTestCase?.type === TelephonyTestType.SPEED_TEST) {
            const isPortValid = !this.selectedServer?.portRange?.length || (this.port.value
            && this.port.value >= Math.min(...this.selectedServer.portRange) && this.port.value <= Math.max(...this.selectedServer.portRange));
            
            return this.selectedTestCase?.devices.every((device) => this.selectedDevices[device.index])
                && (!this.speedTestForm?.invalid) && this.bandwidth.value > 0 && isPortValid
                && ((this.time.value > 0 && !this.limit.value) || (this.bytes.value > 0 && this.limit.value));
        } else {
            return this.selectedTestCase?.devices.every((device) => this.selectedDevices[device.index])
                && (!this.paramsForm?.invalid);
        }
    }

    getArtifactLabel(): string {
        if (this.selectedTestCase?.type
            && (this.selectedTestCase?.type === 'MO_SMS' || this.selectedTestCase?.type === 'MO_MT_SMS')) {
            return 'Save SMS logs';
        }
        return 'Save call logs';
    }

    getEstimatedTime(testCase: TelephonyTestCase): string {
        if (this.iterations) {
            const estimate = this.momtCallService.getTestCaseEstimate(testCase) / 1000;
            const remainingIterations = this.totalIterations - this.completedIterations;
            let remainingTime = 0;
            let currentTime = 0;
            let totalTime = 0;
            if (this.selectedTestCase?.type === TelephonyTestType.SPEED_TEST) {
                if (this.testInfo?.actions[0]?.data?.time && this.testInfo.actions[0].data.time > 0) {
                    remainingTime = remainingIterations * (this.testInfo.actions[0].data.time + this.testInfo.iterationDelay);
                    currentTime = this.currentPlayStart ? this.testInfo.actions[0].data.time - ((new Date().getTime() - this.currentPlayStart) / 1000) : 0;
                }
            } else {
                remainingTime = remainingIterations * (estimate + this.testInfo.iterationDelay);
                currentTime = this.currentPlayStart ? estimate - ((new Date().getTime() - this.currentPlayStart) / 1000) : 0;
            }

            const totalRemaining = currentTime < 0
                ? Math.abs(Math.round(remainingTime + currentTime))
                : Math.round(remainingTime + currentTime);

            if (this.selectedTestCase?.type === TelephonyTestType.SPEED_TEST) {
                totalTime = Math.round(this.totalIterations * (this.testInfo.actions[0].data.time + this.testInfo.iterationDelay));
            } else {
                totalTime = Math.round(this.totalIterations * (estimate + this.testInfo.iterationDelay));
            }

            if (totalRemaining > 0) {
                if (currentTime < 0 && remainingIterations === 0) {
                    this.progressBarValue = 100;
                } else {
                    this.progressBarValue = 100 - (totalRemaining / totalTime) * 100;
                }
            } else {
                if (totalRemaining === 0) {
                    this.progressBarValue = totalRemaining;
                }
            }

            const hours = Math.floor(totalRemaining / 3600);
            const mins = Math.floor((totalRemaining - (hours * 3600)) / 60);
            const seconds = Math.round(totalRemaining % 60);

            return `${currentTime < 0 && remainingIterations === 0 ? '-' : ''}${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
    }

    protocolChanged(): void {
        this.length.setValue(this.udp.value ? 1480 : 128);
        this.window.setValue(this.udp.value ? 218112 : 17520);
    }

    filterServerList(): void {
        const selectedServer = this.serversList.find((s) => s.host === this.speedTestForm.getRawValue().server);
        this.filteredServerList = this.serversList.filter((server) => server.type.includes(this.version.value)
            || server.type.toLowerCase().includes('fqdn'));
        if (selectedServer?.type.toLowerCase().includes('fqdn')) {
            this.speedTestForm.get('server').setValue(selectedServer.host);
        }
    }

    checkMSSsupport(): boolean {
        return this.isAnyDeviceSelected() ? this.selectedDevices[0]?.oem?.toLowerCase() === 'apple' : false;
    }

    getPortRange(server: any): string {
        if (server.portRange?.length > 0) {
            return ` port range: [${Math.min(...server.portRange)}-${Math.max(...server.portRange)}]`;
        }
        return '';
    }

    setPort(): void {
        const server = this.serversList.find((s) => s.host === this.server.value);
        this.selectedServer = server;
        if (server.portRange?.length > 0) {
            this.speedTestForm.controls.port.setValue(Math.min(...server.portRange));
        } else {
            this.speedTestForm.controls.port.setValue('');
        }
    }

    displayServerHost(server: any): string {
        if(server.portRange?.length > 0 && this.port.value && this.selectedServer?.host === server.host) {
            return `${server.host}:${this.port.value}`;
        }
        return server.host
    }
}
