<app-spinner *ngIf="isLoading" [type]="'normal'"></app-spinner>
<h1>Create a new lab</h1>
<form class="lab-form" [formGroup]="labForm" (ngSubmit)="createLab()">
  <section class="left-part">
    <h2>Enter lab info</h2>
    <div class="group" *ngIf="userData.role === 'ADMIN'">
      <label for="type">Select Lab type <span>*</span></label>
      <select class="form-control" id="type" formControlName="type" required>
        <option value="">Select Lab Type</option>
        <option value="public">Public</option>
        <option value="corporate">Corporate</option>
      </select>
      <div
        *ngIf="labForm.controls.type.invalid && (labForm.controls.type.dirty || labForm.controls.type.touched)"
        class="error">
        <div *ngIf="labForm.controls.type.errors.required">*Type is required</div>
      </div>
    </div>
    <div class="group">
      <label for="name">Lab Name <span>*</span></label>
      <input type="text" formControlName="name" maxlength="50" id="name" class="form-control"
        placeholder="Enter Lab Name" />
      <div
        *ngIf="labForm.controls.name.invalid && (labForm.controls.name.dirty || labForm.controls.name.touched)"
        class="error">
        <div *ngIf="labForm.controls.name.errors.required">*Name is required</div>
      </div>
    </div>
    <div class="group">
      <label for="domain">Domain <span>*</span></label>
      <input type="text" formControlName="domain" id="domain" class="form-control" placeholder="Enter Domain" />
      <div
        *ngIf="labForm.controls.domain.invalid && (labForm.controls.domain.dirty || labForm.controls.domain.touched)"
        class="error">
        <div *ngIf="labForm.controls.domain.errors.required">*Domain is required</div>
        <div *ngIf="labForm.controls.domain.errors.pattern">*Invalid Domain</div>
      </div>
    </div>
    <div class="group">
      <label for="ip">IP Address <span>*</span></label>
      <input type="text" formControlName="ip" id="ip" class="form-control" placeholder="Enter IP Address" />
      <div *ngIf="labForm.controls.ip.invalid && (labForm.controls.ip.dirty || labForm.controls.ip.touched)"
        class="error">
        <div *ngIf="labForm.controls.ip.errors.required">*IP Address is required.</div>
        <div *ngIf="labForm.controls.ip.errors.pattern">*Invalid IP Address.</div>
      </div>
    </div>
    <div class="group">
      <label for="location">Location</label>
      <input type="text" formControlName="location" id="location" class="form-control" placeholder="Enter Location" />
    </div>
    <div class="group">
      <label for="deviceLimit">Device Limit</label>
      <input type="number" formControlName="deviceLimit" id="deviceLimit" class="form-control" placeholder="Unlimited"
        (change)="replaceLimit('deviceLimit')" />
      <div
        *ngIf="labForm.controls.deviceLimit.invalid && (labForm.controls.deviceLimit.dirty || labForm.controls.deviceLimit.touched)"
        class="error">
        <div *ngIf="labForm.controls.deviceLimit.errors.min">Must not be less than 0</div>
      </div>
    </div>
    <div class="group">
      <label for="deviceDeletePeriod">Automatically Delete Devices (days)</label>
      <input type="number" formControlName="deviceDeletePeriod" id="deviceDeletePeriod" class="form-control" placeholder="Unlimited"
        (change)="replaceLimit('deviceDeletePeriod')" />
      <div
        *ngIf="labForm.controls.deviceDeletePeriod.invalid && (labForm.controls.deviceDeletePeriod.dirty || labForm.controls.deviceDeletePeriod.touched)"
        class="error">
        <div *ngIf="labForm.controls.deviceDeletePeriod.errors.min">Must not be less than 0</div>
      </div>
    </div>
    <div class="double-input">
      <div class="group">
        <label for="snapSupportContact">Snap Support Contact</label>
        <input type="text" formControlName="snapSupportContact" id="snapSupportContact" class="form-control"
          placeholder="Enter Snap Support Contact" [readonly]="userData.role !== 'ADMIN'" />
      </div>
      <div class="group">
        <label for="snapSupportEmail">Snap Support Email</label>
        <input type="text" formControlName="snapSupportEmail" id="snapSupportEmail" class="form-control"
          placeholder="Enter Snap Support Email" [readonly]="userData.role !== 'ADMIN'" />
      </div>
    </div>
  </section>
  <section class="right-part">
    <div *ngIf="labForm.value.type === 'corporate'" class="lab-specific">
      <h2>Enter specific lab info</h2>
      <div class="group">
        <label for="enterprise">Select Enterprise <span>*</span></label>
        <select class="form-control" id="enterprise" formControlName="enterpriseId" required
          (change)="getSupportOptions()">
          <option value="">Select Enterprise</option>
          <option *ngFor="let option of enterpriseOptions" [value]="option.id">{{option.name}}</option>
        </select>
        <div
          *ngIf="labForm.controls.enterpriseId.invalid && (labForm.controls.enterpriseId.dirty || labForm.controls.enterpriseId.touched)"
          class="error">
          <div *ngIf="labForm.controls.enterpriseId.errors.required">*Enterprise is required.</div>
        </div>
      </div>
      <div class="group">
        <label for="labSupportContact">Lab Support Contact</label>
        <select class="form-control" id="labSupportContact" formControlName="labSupportContact"
          [ngClass]="{ placeholder: labForm.value.labSupportContact === ''}">
          <option value="">Select Lab Support Contact</option>
          <option *ngFor="let option of labSupportOptions" [value]="option.email">{{option.name}}</option>
        </select>
      </div>
    </div>
    <div class="lab-settings">
      <h2>Enter lab settings</h2>
      <mat-checkbox class="checkbox" formControlName="isAppiumSupport">
        Appium Support
      </mat-checkbox>
      <mat-checkbox class="checkbox" formControlName="isSmartHubEnabled">
        Smart-Hub Support
      </mat-checkbox>
      <mat-checkbox class="checkbox" formControlName="sendAlerts">
        Alerts
      </mat-checkbox>
      <mat-checkbox class="checkbox" formControlName="isEnabled">
        Lab is enabled
      </mat-checkbox>
    </div>
    <div class="form-actions">
      <button class="snap-button secondary" type="button" routerLink="/admin/labs">Cancel</button>
      <button class="snap-button primary" type="submit" [disabled]="!labForm.valid">Create</button>
    </div>
  </section>
</form>